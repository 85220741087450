import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { AvaliacaoFormularioDinamicoAPI } from 'src/APIs/ProntuarioAPI/AvaliacaoFormularioDinamicoAPI/AvaliacaoFormularioDinamicoAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

type ContextProps = {
  prontuario: IProntuario | null;
  setProntuario: (value: IProntuario) => void;
  aplicacaoformularios: IAplicacaoFormularioDinamicoItem[];
  setAplicacaoFormularios: (value: IAplicacaoFormularioDinamicoItem[]) => void;
  isLoadingFormulario: boolean;
  setSelectedAplicacaoFormulario: (
    value: IAplicacaoFormularioDinamicoItem,
  ) => void;
  selectedAplicacaoFormulario: IAplicacaoFormularioDinamicoItem | null;
  handleRefetch: () => void;
  historicoAvaliacoes: IAvaliacaoFormularioDinamicoItem[];
  setHistoricoAvaliacoes: (value: IAvaliacaoFormularioDinamicoItem[]) => void;
  copyFormData: IAvaliacaoFormularioDinamicoItem | null;
  setCopyFormData: (value: IAvaliacaoFormularioDinamicoItem | null) => void;
  avaliacaoEdit: IAvaliacaoFormularioDinamicoItem | null;
  setAvaliacaoEdit: (value: IAvaliacaoFormularioDinamicoItem | null) => void;
  handleChangeForm: () => void;
};

const FormularioContext = createContext({} as ContextProps);

export const FormularioProvider = ({
  children,
  isAtendimentoRunning,
}: {
  children: React.ReactNode;
  isAtendimentoRunning?: boolean;
}) => {
  const [prontuario, setProntuario] = useState<IProntuario | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [aplicacaoformularios, setAplicacaoFormularios] = useState<
    IAplicacaoFormularioDinamicoItem[]
  >([]);
  const [selectedAplicacaoFormulario, setSelectedAplicacaoFormulario] =
    useState<IAplicacaoFormularioDinamicoItem | null>(null);

  const [historicoAvaliacoes, setHistoricoAvaliacoes] = useState<
    IAvaliacaoFormularioDinamicoItem[]
  >([]);
  const [avaliacaoEdit, setAvaliacaoEdit] =
    useState<IAvaliacaoFormularioDinamicoItem | null>(null);

  const [copyFormData, setCopyFormData] =
    useState<IAvaliacaoFormularioDinamicoItem | null>(null);

  const dispatch = useDispatch();

  const {
    state: { idAtendimento },
  } = useLocation();

  const handleChangeForm = useCallback(() => {
    setAvaliacaoEdit(null);
  }, []);

  const fetchHistorico = useCallback(async () => {
    if (!selectedAplicacaoFormulario || !prontuario) return;

    try {
      const response =
        await AvaliacaoFormularioDinamicoAPI.loadHistoricoFormularioDinamico({
          idFormularioDinamico:
            selectedAplicacaoFormulario.formularioDinamico.id,
          idPaciente: prontuario.atendimento.paciente.id,
          sortDirection: 'DESC',
          sortBy: 'dataInclusao',
          pageSize: 5,
          page: 0,
        });

      const historicoFiltered = response.list.filter(
        hist => hist.idAtendimento !== prontuario.atendimento.id,
      );
      const avaliacaoEdit = response.list.find(
        hist => hist.idAtendimento === prontuario.atendimento.id,
      );

      avaliacaoEdit && setAvaliacaoEdit(avaliacaoEdit);
      setHistoricoAvaliacoes(historicoFiltered);
    } catch {
      setHistoricoAvaliacoes([]);
    }
  }, [
    prontuario,
    selectedAplicacaoFormulario,
    setAvaliacaoEdit,
    setHistoricoAvaliacoes,
  ]);

  useEffect(() => {
    fetchHistorico();
  }, [fetchHistorico]);

  useEffect(() => {
    if (!idAtendimento || !isAtendimentoRunning) {
      return;
    }

    setIsLoading(true);

    UtilsAPI.getProntuario(Number(idAtendimento), {
      throwError: true,
    })
      .then(response => setProntuario(response))
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [idAtendimento, isAtendimentoRunning]);

  const handleRefetch = () => {
    dispatch(
      setInvalidateQuery({
        invalidateFormulario: true,
      }),
    );
    fetchHistorico();
  };

  return (
    <FormularioContext.Provider
      value={{
        prontuario,
        setProntuario,
        aplicacaoformularios,
        setAplicacaoFormularios,
        isLoadingFormulario: isLoading,
        setSelectedAplicacaoFormulario,
        selectedAplicacaoFormulario,
        handleRefetch,
        historicoAvaliacoes,
        setHistoricoAvaliacoes,
        copyFormData,
        setCopyFormData,
        avaliacaoEdit,
        setAvaliacaoEdit,
        handleChangeForm,
      }}
    >
      {children}
    </FormularioContext.Provider>
  );
};

export const useFormulario = () => {
  const context = useContext(FormularioContext);
  return context;
};
