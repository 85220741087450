import { Outlet, useLocation } from 'react-router';

import Page from 'src/components/Basics/Page/Page';

import { MonitoramentoMenu } from './MonitoramentoMenu';

export const Monitoramento = () => {
  const { pathname } = useLocation();
  const isMenu = pathname === '/monitoramento';

  return <Page>{isMenu ? <MonitoramentoMenu /> : <Outlet />}</Page>;
};
