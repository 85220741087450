import { useMemo } from 'react';

import { Edge, Node } from '@xyflow/react';

import { CustomNodeDataProps } from '../_components/nodes';

type CustomNodeGestation = {
  id: string;
  idFamiliar: string[];
  idMae: string;
  idPai: string;
};

export const useInitialEdges = (
  initialNodes: Node<CustomNodeDataProps>[],
): Edge[] => {
  const nodes: any[] = initialNodes.filter(node => node.type === 'gestation');
  const twins = initialNodes.filter(node => !!node.data?.idIrmaoGemeo);

  const gestations = nodes as Node<CustomNodeGestation>[];
  
  const edges = useMemo(() => {
    const createTwinEdges = () => {
      return twins.map(twin => {
        const { data } = twin;
        const { idIrmaoGemeo, idFamiliar } = data;

        // Creating edge between twins
        return {
          id: `${idIrmaoGemeo}-twins`,
          source: idFamiliar,
          target: idIrmaoGemeo!,
          sourceHandle: 'right',
          targetHandle: 'left',
          style: { stroke: '#8f9299' },
          type: 'smoothstep',
        };
      });
    };

    const createGestationEdges = () => {
      return gestations.flatMap((gestation, idxGestation) => {
        const { data } = gestation;
        const { idMae, idPai, idFamiliar } = data;

        const sources: { id: string; source: string | string[] }[] = [
          { id: 'idMae', source: idMae || '' },
          { id: 'idPai', source: idPai || '' },
          { id: 'idFamiliar', source: idFamiliar },
        ];

        const result = sources.flatMap(({ source, id: idSource }): Edge[] => {
          const id = `${source}-gestation-${idxGestation}`;

          const targetHandle: { [key: string]: string } = {
            idMae: 'right',
            idPai: 'left',
          };

          //* Assuming it is a descendant, as idFamiliar will always be an array in gestation
          if (Array.isArray(source)) {
            return source.map(id => {
              // Check if the descendant is adopted
              const isAdopted = initialNodes
                .find(item => item.id === id)
                ?.data?.filiacao?.includes('ADOTADO');

              return {
                id: `${id}-gestation-${idxGestation}`,
                source: id,
                target: gestation.id,
                sourceHandle: 'top',
                targetHandle: 'bottom',
                style: {
                  stroke: '#8f9299',
                  strokeDasharray: isAdopted ? '4 2' : '0',
                },
                type: 'smoothstep',
              };
            });
          }

          return [
            {
              id,
              source,
              target: gestation.id,
              sourceHandle: 'bottom',
              targetHandle: targetHandle[idSource]!,
              style: {
                stroke: '#000',
                strokeDasharray: '0',
              },
              type: 'smoothstep',
            },
          ];
        }) as Edge[];

        return result;
      });
    };

    return [...createTwinEdges(), ...createGestationEdges()];
  }, [gestations, initialNodes, twins]);

  return edges;
};
