import dayjs from 'dayjs';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import AlertBox from 'src/components/AlertBox/AlertBox';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import { useAssistenciaVirtual } from './_hooks/useAssistenciaVirtual';
import { DashboardProvider } from './_hooks/useDashboard';
import { DashboardContentAdm } from './DashboardContent/DashboardContentAdm';
import { DashboardContentMaster } from './DashboardContent/DashboardContentMaster';
import { DashboardContentProfissionalSaude } from './DashboardContent/DashboardContentProfissionalSaude';
import { DashboardContentSecretariado } from './DashboardContent/DashboardContentSecretariado';

export const Dashboard = () => {
  return (
    <DashboardProvider>
      <Home />
    </DashboardProvider>
  );
};

const Home = () => {
  const {
    agenda: { profissionalAtivo },
    user,
  } = useAppSelector((state: RootState) => state);

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual();

  const userDashboard: { [key: string]: JSX.Element } = {
    MASTER: <DashboardContentMaster />,
    ADMINISTRADOR: <DashboardContentAdm />,
    PROFISSIONAL_SAUDE: <DashboardContentProfissionalSaude />,
    SECRETARIO: <DashboardContentSecretariado />,
  };

  return (
    <Page
      content
      className="Home p-d-flex p-w-100 p-gap-2 p-flex-column p-pb-2"
    >
      <div className="p-d-flex p-flex-column p-gap-2 p-w-100 ">
        <SimpleText fontSize={FONT_SIZE.MD} bold>
          Bom te ver novamente
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          {profissionalAtivo?.nome || ''}
        </SimpleText>

        <SimpleText className="p-mt-4" style={{ fontSize: '1.2rem' }} bold>
          {dayjs().format('DD [de] MMM[.] [de] YYYY')}
        </SimpleText>

        {!user.idEmpresa && (
          <AlertBox
            visible
            text="Selecione uma empresa para ver os dados no Dashboard"
            className="p-col-12"
          />
        )}

        {user.idEmpresa && userDashboard[user.tipoUsuario]}

        {assistenteVirtualDialog.isOpen && (
          <AssistenteVirtualDialog
            onClose={handleClose}
            canHide
            {...assistenteVirtualDialog}
          />
        )}
      </div>
    </Page>
  );
};

export default Dashboard;
