import { useState, useMemo } from 'react';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import ConfirmarRemarcacao from './ConfirmarRemarcacao/ConfirmarRemarcacao';
import EscolhaNovoHorario from './EscolhaNovoHorario/EscolhaNovoHorario';

import './RemarcarAgendamento.scss';

enum RemarcarAgendamentoStep {
  CONFIRMAR = 'CONFIRMAR',
  ESCOLHA_NOVO_HORARIO = 'ESCOLHA_NOVO_HORARIO',
}

function RemarcarAgendamento({ isOpen, state, close }: DisclosureType) {
  const [remarcarAgendamentoStep, setRemarcarAgendamentoStep] =
    useState<RemarcarAgendamentoStep>(RemarcarAgendamentoStep.CONFIRMAR);

  function onHideEscolhaNovoHorario() {
    setRemarcarAgendamentoStep(RemarcarAgendamentoStep.CONFIRMAR);
    close();
  }

  const editAgendamento = useMemo(
    () => ({
      ...state,
    }),
    [state],
  );

  if (
    remarcarAgendamentoStep === RemarcarAgendamentoStep.ESCOLHA_NOVO_HORARIO
  ) {
    return (
      <EscolhaNovoHorario
        visible={isOpen}
        editAgendamento={editAgendamento}
        onHide={onHideEscolhaNovoHorario}
      />
    );
  }

  return (
    <ConfirmarRemarcacao
      visible={isOpen}
      onConfirm={() =>
        setRemarcarAgendamentoStep(RemarcarAgendamentoStep.ESCOLHA_NOVO_HORARIO)
      }
      onHide={close}
    />
  );
}

export default RemarcarAgendamento;
