import { useMemo, useRef } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';

import { useAppSelector } from 'src/core/redux/hooks';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter, checkAuth } from 'src/utils/utils';

import { DialogPontuacao } from '../_components/DialogPontuacao/DialogPontuacao';
import { DialogVinculos } from './_components/DialogVinculos/DialogVinculos';
import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { useFormularioDinamicoAplicacaoFormulario } from './useFormularioDinamicoAplicacaoFormulario';

const statusTypes: { [key: string]: string } = {
  ATIVO: 'Ativo',
  FINALIZADO_POR_DATA: 'Finalizado por data',
  CANCELADO: 'Cancelado',
};

export const FormularioDinamicoAplicacaoFormularioRow = ({
  item,
  handleView,
  handleEdit,
  handleCancel,
}: {
  item: IAplicacaoFormularioDinamicoItem;
  handleView: (id: number) => void;
  handleEdit: (id: number) => void;
  handleCancel: (item: IAplicacaoFormularioDinamicoItem) => void;
}) => {
  const {
    user: { authorities },
  } = useAppSelector(state => state);
  const { consultorios, convenios, especialidades } =
    useFormularioDinamicoAplicacaoFormulario();

  const contentRow = useDisclosure({ opened: false });
  const dialogPontuacao = useDisclosure({ opened: false });
  const dialogVinculos = useDisclosure({ opened: false });

  const menu = useRef<TieredMenu>(null);

  const canEdit =
    item.situacao === 'ATIVO' &&
    !item.existeAvaliacao &&
    checkAuth(authorities, 'ADM_MANTER_APLICACAO_FORMULARIO_DINAMICO_ALTERAR');

  const canView = checkAuth(
    authorities,
    'ADM_MANTER_APLICACAO_FORMULARIO_DINAMICO_CONSULTAR',
  );

  const canCancel =
    item.situacao === 'ATIVO' &&
    checkAuth(authorities, 'ADM_MANTER_APLICACAO_FORMULARIO_DINAMICO_ALTERAR');

  const itemsOptions: MenuItem[] = [
    {
      label: 'Visualizar',
      command: () => handleView(item.id),
      disabled: !canView,
      className: canView ? '' : 'p-d-none',
    },
    {
      label: 'Editar',
      command: () => handleEdit(item.id),
      disabled: !canEdit,
      className: canEdit ? '' : 'p-d-none',
    },
    {
      label: 'Cancelar',
      command: () => handleCancel(item),
      disabled: !canCancel,
      className: `error ${canCancel ? '' : 'p-d-none'}`,
    },
  ];

  const statusColor: { [key: string]: string } = {
    ATIVO: 'tag-success',
    FINALIZADO_POR_DATA: 'tag-blue',
    CANCELADO: 'tag-danger',
  };

  const pontuacoes = item.formularioDinamico.pontuacoes || [];

  const vinculosLength = useMemo(() => {
    const vinculosConsultorios = item.consultorios?.length
      ? item.consultorios
      : consultorios;
    const vinculosEspecialidades = item.especialidades?.length
      ? item.especialidades
      : especialidades;
    const vinculosConvenios = item.convenios?.length
      ? item.convenios
      : convenios;

    const length =
      vinculosConsultorios.length +
      vinculosEspecialidades.length +
      vinculosConvenios.length;

    return length > 99 ? '+99' : length.toString();
  }, [item, consultorios, especialidades, convenios]);

  return (
    <div className="p-col-12 p-flex-wrap content-row row p-mb-2">
      <div className="p-col-6 p-md-3 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Nome da aplicação
        </SimpleText>
        <SimpleText>{item.nome}</SimpleText>
      </div>
      <div className="p-col-4 p-md-2 p-lg-3 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Nome do formulário
        </SimpleText>
        <SimpleText>{item.formularioDinamico.nome}</SimpleText>
      </div>
      <div className="p-xl-3 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          data de vigência
        </SimpleText>
        <div
          className="p-d-flex p-gap-2"
          style={{
            width: '205px',
          }}
        >
          <SimpleText fontColor="color_60" fontSize="xxxs">
            inicio{' '}
            <SimpleText>
              {item.dataInicioVigencia
                ? dayjs(item.dataInicioVigencia).format('DD/MM/YYYY')
                : '-'}
            </SimpleText>
          </SimpleText>
          <SimpleText fontColor="color_60" fontSize="xxxs">
            fim{' '}
            <SimpleText>
              {item.dataFimVigencia
                ? dayjs(item.dataFimVigencia).format('DD/MM/YYYY')
                : '-'}
            </SimpleText>
          </SimpleText>
        </div>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-2 p-ai-end">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Situação
        </SimpleText>
        <span className={`${statusColor[item.situacao]} p-text-truncate`}>
          {statusTypes[item.situacao]}
        </span>
      </div>
      <div className="p-col p-xl-1 p-d-flex p-gap-2 p-ai-center p-jc-end">
        <Divider layout="vertical" className="p-p-0 p-m-0" />

        <Button
          icon="fas fa-ellipsis"
          btnType="gray"
          onClick={e => menu.current?.toggle(e)}
        />
        <Button
          icon={contentRow.isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="gray"
          onClick={() => contentRow.toggle()}
        />

        <TieredMenu
          className="tiered-menu"
          style={{
            width: 'fit-content',
            minWidth: '110px',
          }}
          model={itemsOptions}
          popup
          ref={menu}
        />
      </div>

      {contentRow.isOpen && (
        <div className="p-col-12 row content-row white">
          <div className="p-md-3 p-lg-2 p-xl-1 p-d-flex p-flex-column p-gap-2">
            <SimpleText fontSize="xxs" fontColor="color_60">
              Público aplicação
            </SimpleText>
            <SimpleText>{capitalizeFirstLetter(item.publico)}</SimpleText>
          </div>
          <div
            className="p-md-3 p-lg-2 p-xl-1 p-d-flex p-flex-column p-gap-2 cursor-pointer"
            onClick={() => dialogVinculos.open()}
          >
            <SimpleText
              className="pointer-events-none"
              fontSize="xxs"
              fontColor="color_60"
            >
              Vínculos
            </SimpleText>
            <SimpleText className="pointer-events-none" fontColor="primary">
              {vinculosLength} vinculos
            </SimpleText>
          </div>
          <div
            className="p-md-3 p-lg-2 p-xl-1 p-d-flex p-flex-column p-gap-2 cursor-pointer"
            onClick={() => dialogPontuacao.open()}
          >
            <SimpleText
              className="pointer-events-none"
              fontSize="xxs"
              fontColor="color_60"
            >
              Pontuação
            </SimpleText>
            <SimpleText className="pointer-events-none" fontColor="primary">
              {pontuacoes.length
                ? `${pontuacoes.length} intervalo(s)`
                : 'Sem pontuação'}
            </SimpleText>
          </div>
          <div className="p-md-3 p-lg-2 p-d-flex p-flex-column p-gap-2">
            <SimpleText fontSize="xxs" fontColor="color_60">
              Ultima alteração
            </SimpleText>
            <SimpleText>
              {item.dataAtualizacao
                ? dayjs(item.dataAtualizacao).format('DD/MM/YYYY HH:mm')
                : ''}
            </SimpleText>
          </div>
        </div>
      )}

      {dialogPontuacao.isOpen && (
        <DialogPontuacao {...dialogPontuacao} state={item.formularioDinamico} />
      )}
      {dialogVinculos.isOpen && (
        <DialogVinculos {...dialogVinculos} state={item} />
      )}
    </div>
  );
};
