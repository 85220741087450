import { useCallback, useEffect } from 'react';

import { FeedbackAPI } from 'src/APIs/AdminAPI/FeedbackAPI/FeedbackAPI';

import { AssinaturaProvider } from 'src/core/containers/Assinatura/AssinaturaProvider';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Feedback } from './components/Feedback/Feedback';
import Page from 'src/components/Basics/Page/Page';

import MainMenu from 'src/pages/Emed/LoggedRoot/MainMenu/MainMenu';

import './LoggedRoot.scss';

const LoggedRoot = () => {
  const { user } = useAppSelector((state: RootState) => state);

  const feedbackDialog = useDisclosure({ opened: false });

  useEffect(() => {
    const checkFeedback = async () => {
      if (!user?.idUsuario) return;
      try {
        await FeedbackAPI.checkUserFeedback(user.idUsuario).then(response => {
          if (response === true) {
            feedbackDialog.open();
          }
        });
      } catch (error) {
        console.error(error);
      }
    };
    checkFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={'LoggedRoot'}>
      <AssinaturaProvider>
        <MainMenu />
        {feedbackDialog.isOpen && <Feedback {...feedbackDialog} />}
      </AssinaturaProvider>
    </Page>
  );
};

export default LoggedRoot;
