import { Checkbox } from 'src/components/_UI/Checkbox';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

import { useSelectedContaPagar } from '../../useSelectedContaPagar';

const CheckboxCounter = () => {
  const { selectedContaPagar, setSelectedContaPagar } = useSelectedContaPagar();

  const { listItems } = useSearchList();
  return (
    <Checkbox
      label={
        selectedContaPagar?.length === listItems.length
          ? 'Todos marcados'
          : `${selectedContaPagar?.length || 'Nenhum'} marcado${
              selectedContaPagar?.length > 1 ? 's' : ''
            }`
      }
      checked={selectedContaPagar?.length > 0}
      onChange={() => {
        if (selectedContaPagar?.length > 0) {
          setSelectedContaPagar([]);
        }
      }}
      disabled={selectedContaPagar?.length === 0}
    />
  );
};

export default CheckboxCounter;
