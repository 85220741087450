import { useContext } from 'react';

import {
  SelectButton as PrimeSelectButton,
  SelectButtonProps as PrimeSelectButtonProps,
} from 'primereact/selectbutton';

import ThemeContext from 'src/core/themes/ThemeContext';

import SimpleText, { FONT_SIZE } from '../Basics/SimpleText/SimpleText';
import './SelectButton.scss';
export interface SelectButtonProps
  extends Omit<PrimeSelectButtonProps, 'disabled'> {
  name?: string;
  options?: any;
  label?: string | undefined;
  responsive?: boolean;
  disabled?: boolean | undefined;
  errorMsg?: string | any;
}

const SelectButton = ({
  name,
  className,
  label,
  responsive,
  disabled,
  errorMsg,
  ...props
}: SelectButtonProps) => {
  const theme = useContext(ThemeContext);
  return (
    <div
      className={`SelectButton ${theme?.theme || ''} ${
        className || ''
      } p-d-flex p-flex-column p-gap-2`}
      key={name}
    >
      {label || label === '' ? <strong>{label}</strong> : <></>}
      <PrimeSelectButton {...props} disabled={disabled ? true : false} />
      <div>
        {errorMsg && (
          <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
            {errorMsg}
          </SimpleText>
        )}
      </div>
    </div>
  );
};

export default SelectButton;
