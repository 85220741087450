import { Control, Controller } from 'react-hook-form';

import TextInput, { TextInputProps } from '../TextInput/TextInput';

export interface TextInputControlledProps extends TextInputProps {
  control: Control<any>;
  name: string;
  defaultValue?: string | number;
}

const TextInputControlled = ({
  name,
  control,
  defaultValue,
  ...rest
}: TextInputControlledProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }: any) => (
        <TextInput
          {...rest}
          value={defaultValue || value}
          onBlur={onBlur}
          onChange={e => [onChange(e), rest?.onChange?.(e)]}
          ref={ref}
        />
      )}
    />
  );
};

export default TextInputControlled;
