import { useContext, useEffect, useMemo } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { useAppDispatch } from 'src/core/redux/hooks';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';
import ThemeContext from 'src/core/themes/ThemeContext';

import { formularioAuthorities } from './FormularioDinamico/utils';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Tab from 'src/components/Tab/Tab';

import { AssistenteVirtual } from './AssistenteVirtual/AssistenteVirtual';
import CentralComunicado from './Comunicado/CentralComunicado';
import { CentralComunicadosPacientes } from './ComunicadosPacientes/CentralComunicadosPacientes';
import CentralConsultorio from './Consultorio/CentralConsultorio';
import CentralConvenio from './Convenio/CentralConvenio';
import CentralEmpresa from './Empresa/CentralEmpresa';
import CentralEspecialidade from './Especialidade/CentralEspecialidade';
import { FormularioDinamico } from './FormularioDinamico';
import CentralHospital from './Hospital/CentralHospital';
import { CentralMarketingMedico } from './MarketingMedico/CentralMarketingMedico';
import { CentralProtocoloAtendimento } from './ProtocoloAtendimento/CentralProtocoloAtendimento';
import CentralUsuario from './Usuario/CentralUsuario';

import './Cadastros.scss';

const cadastroOptions = [
  {
    label: 'Usuários',
    permission: 'ADM_USUARIO_CONSULTAR',
    path: '/cadastros/usuario',
    content: <CentralUsuario />,
  },
  {
    label: 'Consultórios',
    permission: 'ADM_CONSULTORIO_CONSULTAR',
    path: '/cadastros/consultorio',
    content: <CentralConsultorio />,
  },
  {
    label: 'Hospitais',
    permission: 'ADM_HOSPITAL_CONSULTAR',
    path: '/cadastros/hospital',
    content: <CentralHospital />,
  },
  {
    label: 'Comunicados',
    permission: 'ADM_COMUNICADO_CONSULTAR',
    path: '/cadastros/comunicado',
    content: <CentralComunicado />,
  },
  {
    label: 'Comunicados Pacientes',
    permission: 'ADM_COMUNICADO_CONSULTAR',
    path: '/cadastros/comunicadosPacientes',
    content: <CentralComunicadosPacientes />,
  },
  {
    label: 'Marketing médico',
    permission: 'ADM_MARKETING_MEDICO_CONSULTAR',
    path: '/cadastros/marketing-medico',
    content: <CentralMarketingMedico />,
  },
  {
    label: 'Especialidades',
    permission: 'ADM_ESPECIALIDADE_CONSULTAR',
    path: '/cadastros/especialidade',
    content: <CentralEspecialidade />,
  },
  {
    label: 'Clientes',
    permission: 'ADM_EMPRESA_CONSULTAR',
    path: '/cadastros/cliente',
    content: <CentralEmpresa />,
  },
  {
    label: 'Convênios',
    permission: 'ADM_CONVENIO_CONSULTAR',
    path: '/cadastros/convenio',
    content: <CentralConvenio />,
  },
  {
    label: 'Assistente Virtual',
    permission: 'ADM_ASSISTENTE_VIRTUAL_CADASTRO_CONSULTAR',
    path: '/cadastros/assistente-virtual',
    content: <AssistenteVirtual />,
  },
  {
    label: 'Formulário dinâmico',
    permission: formularioAuthorities,
    path: '/cadastros/formulario-dinamico',
    content: <FormularioDinamico />,
  },
  {
    label: 'Protocolo de atendimento',
    permission: 'ADM_PROTOCOLO_ATENDIMENTO_CONSULTAR',
    path: '/cadastros/protocolo-atendimento',
    content: <CentralProtocoloAtendimento />,
  },
];

const Cadastros = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const dispatch = useAppDispatch();

  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(UsuarioAPI.loadUtils());
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname === '/cadastros') {
      navigate('/cadastros/usuario');
    }
  }, [location.pathname, navigate]);

  const tabs = useMemo((): any[] => {
    return cadastroOptions.filter(({ permission }) => {
      if (Array.isArray(permission)) {
        return permission.some(p => authorities?.includes(p));
      }
      return authorities?.includes(permission);
    });
  }, [authorities]);

  const initialTabIndex = useMemo(
    () => tabs.findIndex(tab => tab.path === location.pathname),
    [location.pathname, tabs],
  );

  const isFormularioDinamico = location.pathname.includes(
    'formulario-dinamico',
  );

  return (
    <Page className={`Cadastros ${theme?.theme || ''}`}>
      <>
        <PageHeader
          title={'O que vamos cadastrar hoje?'}
          subtitle={'Gerencie os cadastros do sistema eMed'}
        />
        <Page
          className="page-content "
          content
          white={isFormularioDinamico ? false : true}
        >
          <Tab
            clean={isFormularioDinamico ? false : true}
            values={tabs}
            setActiveIndex={initialTabIndex}
            initialTabIndex={initialTabIndex}
            mode="path"
          />
        </Page>
      </>
    </Page>
  );
};

export default Cadastros;
