import React, { useCallback, useState } from 'react';

import { useParams } from 'react-router';

import TermoConsentimentoPacienteAPI from 'src/APIs/PacienteAPI/TermoConsentimentoPacienteAPI/TermoConsentimentoPacienteAPI';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Separator from 'src/components/Separator/Separator';

import AnexarConsentimento from './AnexarConsentimento/AnexarConsentimento';
import Row from './Row/Row';

interface DadosConsentimentoProps {
  setNovoTermo: (termo: ITermoConsentimentoItem) => void;
  nextStep: () => void;
  reloadList: () => void;
  reload: number;
  formConsentimento: DisclosureType;
}

const DadosConsentimento = ({
  setNovoTermo,
  nextStep,
  reloadList,
  reload,
  formConsentimento,
}: DadosConsentimentoProps) => {
  const { idPaciente } = useParams();

  const fetchApi = useCallback(async () => {
    try {
      const response =
        await TermoConsentimentoPacienteAPI.listTermoConsentimento(
          Number(idPaciente),
        );

      return response;
    } catch (error) {
      console.error(error);
    }
    return;
  }, [idPaciente]);

  const renderRow = (termo: ITermoConsentimentoItem) => {
    return (
      <Row
        termo={termo}
        reloadList={reloadList}
        setNovoTermo={setNovoTermo}
        {...formConsentimento}
      />
    );
  };

  return (
    <>
      {!formConsentimento.isOpen ? (
        <Button
          label="Adicionar termo de consentimento"
          btnType="tonal"
          icon="fas fa-plus"
          iconPos="left"
          stretch
          onClick={() => formConsentimento.open()}
        />
      ) : (
        <AnexarConsentimento
          {...formConsentimento}
          setNovoTermo={setNovoTermo}
          nextStep={nextStep}
          reloadList={reloadList}
        />
      )}

      <Separator layout="horizontal" />
      <SimpleText medium fontSize="sm">
        Lista de termos de consentimento
      </SimpleText>
      <SearchList.Root fetchApi={fetchApi}>
        <SearchList.InfiniteScroll
          reload={reload}
          renderRow={renderRow}
          customEmptyText="Nenhum termo de consentimento registrado para este paciente."
        />
      </SearchList.Root>
    </>
  );
};

export default DadosConsentimento;
