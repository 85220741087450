import { useState } from 'react';

import { useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import { DialogFormularioPlanoCuidado } from './Dialogs/DialogFormulario';

import './PlanoCuidadoItem.scss';

type IProps = {
  handleDelete: () => void;
  onEdit: () => void;
  idx: number;
  isNotAtendimentoCorrente: boolean;
  item: any;
  handleUpdate: (idx: number, item: any) => void;
};

export const PlanoCuidadoFormularioDinamicoItem = ({
  handleDelete,
  onEdit,
  item,
  isNotAtendimentoCorrente,
  ...props
}: IProps) => {
  const dialogAgendamento = useDisclosure({ opened: false });
  const form = useFormContext();

  const [isOpen, setIsOpen] = useState(false);

  const isRealizado =
    item?.formularios?.some(
      (itemConsulta: any) => itemConsulta.statusRealizacao === 'REALIZADO',
    ) ?? false;

  const hasError = !!form.formState.submitCount && !item.formularios?.length;

  return (
    <div
      className={`content-item ${!isOpen ? 'closed' : ''} ${
        hasError ? 'acc-error' : ''
      }`}
    >
      <div className="content-item-header p-d-flex p-ai-center p-jc-between">
        <div className="p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Atividade
          </SimpleText>
          <SimpleText medium>Formulário dinâmico</SimpleText>
        </div>

        <div className="p-d-flex p-gap-2">
          <Button
            icon="fas fa-calendar-plus"
            btnType="ghost"
            onClick={() => dialogAgendamento.open()}
            disabled={isNotAtendimentoCorrente}
          />
          <Button
            icon="fas fa-edit"
            btnType="ghost"
            type="button"
            onClick={onEdit}
            disabled={isNotAtendimentoCorrente || isRealizado}
          />
          <Button
            icon="fas fa-trash"
            btnType="ghost"
            type="button"
            onClick={() => handleDelete()}
            disabled={isNotAtendimentoCorrente}
          />
          <Button
            icon={isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
            onClick={() => setIsOpen(old => !old)}
            btnType="ghost"
            type="button"
          />
        </div>
      </div>

      <div className="p-grid content-item-body" aria-expanded={!isOpen}>
        <div className="p-col-6 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Nome do formulário
          </SimpleText>
          <SimpleText>{item.formularioDinamico.nome}</SimpleText>
        </div>
        <div className="p-col-6 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Recorrência
          </SimpleText>
          <SimpleText>{item.recorrencia} dias</SimpleText>
        </div>
        <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Execução
          </SimpleText>
          <SimpleText>{item.execucao}</SimpleText>

          <Divider className="p-col-12" layout="horizontal" />

          {!!item?.formularios && (
            <SimpleText className="p-col-12" medium>
              Data programada
            </SimpleText>
          )}

          {!!item?.formularios && (
            <div className="p-col-12 p-d-flex" style={{ overflowX: 'scroll' }}>
              {item.formularios.map((value: any, index: number) => (
                <div key={index} className="p-col-3 p-d-flex p-gap-3">
                  <div className="data-programada p-d-flex p-flex-column p-gap-2 p-jc-center">
                    <SimpleText>
                      <i className="fas fa-calendar p-mr-2" />
                      {dayjs(value.data).format('DD/MM/YYYY')}
                    </SimpleText>
                    <SimpleText>
                      <i className="fas fa-clock p-mr-2" />
                      {dayjs(value.hora).format('HH:mm')}
                    </SimpleText>
                  </div>

                  <Divider className="p-mx-2" layout="vertical" />
                </div>
              ))}
            </div>
          )}

          {!!item?.formularios && (
            <Divider className="p-col-12" layout="horizontal" />
          )}
        </div>
      </div>

      {dialogAgendamento.isOpen && (
        <DialogFormularioPlanoCuidado {...props} {...dialogAgendamento} />
      )}
    </div>
  );
};
