import { useMemo } from 'react';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';

interface PanelHeaderProps {
  isCollapsed: boolean;
  carteira: CarteiraAPSDTO;
  onToggle: () => void;
  onEdit: () => void;
  searchType: string;
}

const PanelHeader = ({
  isCollapsed,
  carteira,
  onEdit,
  onToggle,
  searchType,
}: PanelHeaderProps) => {
  const { isTablet } = useSize();
  const { consultorios } = useAppSelector((state: RootState) => state);

  const isEquipeCuiadora =
    consultorios?.ativo?.tipoMonitoramentoCarteiraAps === 'EQUIPE';

  const profissionalSaude =
    carteira.equipeCuidadora?.profissionalResposavel ??
    carteira.profissionalCuidador;

  const medicoLabel = useMemo((): string => {
    const value =
      profissionalSaude.tipoProfissionalSaude +
      ' • ' +
      profissionalSaude.codigoConselho +
      '/' +
      profissionalSaude.ufConselho +
      profissionalSaude.numeroRegistro;

    return value;
  }, [profissionalSaude]);

  const profissionalLabel = isEquipeCuiadora
    ? 'Profissional responsável'
    : 'Profissional';

  return (
    <div className="p-grid p-d-flex p-ai-center">
      <div className="p-col p-d-flex p-flex-column p-order-1">
        {isTablet && (
          <SimpleText
            className="p-mb-2"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            {profissionalLabel}
          </SimpleText>
        )}

        <SimpleText
          bold
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_16}
        >
          {profissionalSaude?.nome}
        </SimpleText>

        <SimpleText
          className="p-mt-2"
          fontSize={FONT_SIZE.XXXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          {medicoLabel}
        </SimpleText>
      </div>

      <>
        {isEquipeCuiadora && (
          <div className="p-col-12 p-md-3 p-d-flex p-flex-column p-px-2 p-px-md-0 p-order-2">
            {isTablet && (
              <SimpleText
                className="p-mb-2"
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XXS}
              >
                Equipe
              </SimpleText>
            )}

            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              {carteira.equipeCuidadora?.nome}
            </SimpleText>
          </div>
        )}
      </>

      <div className="p-col-12 p-md-3 p-d-flex p-flex-column p-px-2 p-px-md-0 p-order-2">
        {isTablet && (
          <SimpleText
            className="p-mb-2"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Quantidade de pacientes
          </SimpleText>
        )}

        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
          {carteira.totalPacientes}
        </SimpleText>
      </div>

      <div className="p-col-12 p-md-2 p-d-flex p-flex-column p-px-2 p-px-md-0 p-order-3">
        {isTablet && (
          <SimpleText
            className="p-mb-2"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Pontuação
          </SimpleText>
        )}
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_40}>
          {carteira.totalPontos}
        </SimpleText>
      </div>

      <div className="p-col-fixed p-d-flex p-ai-center p-jc-end movimentacao-actions-col p-order-1 p-order-md-4">
        {!isTablet && (
          <Can checkPermission="ADM_CARTEIRA_APS_MOVIMETACAO_ALTERAR">
            <Divider layout="vertical" />
          </Can>
        )}

        <Button
          className="p-mr-1"
          btnType="gray"
          icon="fas fa-edit"
          onClick={onEdit}
          checkPermission="ADM_CARTEIRA_APS_MOVIMETACAO_ALTERAR"
        />
        {searchType === 'PACIENTES' && (
          <Button
            btnType="ghost"
            icon={isCollapsed ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}
            onClick={onToggle}
          />
        )}
      </div>
    </div>
  );
};

export default PanelHeader;
