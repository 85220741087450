import React from 'react';

import { PanelToggleParams } from 'primereact/panel';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface PanelHeaderProps {
  data: IPendenciaAuditoriaDTO;
  isExpanded: boolean;
  onToggle: () => void;
}

const PanelHeader = ({ data, isExpanded, onToggle }: PanelHeaderProps) => {
  return (
    <div className="p-grid p-p-1 detalhes-guia">
      <div className="p-col-12 p-d-flex p-gap-1 p-flex-column">
        <SimpleText fontSize='xs' fontColor='color_60'>
          Paciente
        </SimpleText>
        <SimpleText
          fontSize='sm'
          fontColor='color_16'
          medium
        >
          {data.nomePaciente}
        </SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-gap-1 p-flex-column">
        <SimpleText fontSize='xs' fontColor='color_60'>
          Número da carteirinha
        </SimpleText>
        <SimpleText
          fontSize='sm'
          fontColor='color_40'
          medium
        >
          {data.numeroCartao}
        </SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-gap-1 p-flex-column">
        <SimpleText fontSize='xs' fontColor='color_60'>
          Número da guia
        </SimpleText>
        <SimpleText
          fontSize='sm'
          fontColor='color_40'
          medium
        >
          {data.numeroGuia}
        </SimpleText>
      </div>
      {!isExpanded && (
        <div className="p-d-flex p-jc-center p-flex-1">
          <Button
            icon="fas fa-chevron-down"
            btnType="ghost"
            onClick={() => onToggle()}
          />
        </div>
      )}
    </div>
  );
};

export default PanelHeader;
