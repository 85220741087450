import { useCallback, useEffect, useState } from 'react';

import { Pie } from 'react-chartjs-2';

import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DashboardAPI } from 'src/APIs/AdminAPI/DashboardAPI/DashboardAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { cn } from 'src/utils/cn';
import { getCssVariable } from 'src/utils/utils';

import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

Chart.register(ChartDataLabels);

export const FinanceiroChart = ({ className }: { className?: string }) => {
  const { tipoProfissionalSaude = '', tipoUsuario } = useAppSelector(
    state => state.user,
  );
  const [data, setData] = useState<IDashboardGraficoFinanceiroResponse>({
    despesa: 0,
    receita: 0,
  });

  const chartColors = {
    receitas: getCssVariable('--primary-color-medium'),
    despesas: getCssVariable('--primary-color'),
  };

  const fetchFinanceiro = useCallback(async () => {
    if (
      tipoUsuario === 'PROFISSIONAL_SAUDE' &&
      tipoProfissionalSaude !== 'MEDICO'
    ) {
      return;
    }

    try {
      const res = await DashboardAPI.loadGraficoFinanceiro({
        throwError: true,
      });

      setData(res);
    } catch {}

    return;
  }, [tipoProfissionalSaude, tipoUsuario]);

  useEffect(() => {
    fetchFinanceiro();
  }, [fetchFinanceiro]);

  return (
    <div
      className={cn('row content-row white p-gap-2', className)}
      style={{
        height: '280px',
        border: '1px solid var(--neutral-color-grayscale-88)',
      }}
    >
      <div
        className="p-d-flex p-flex-column p-gap-2 p-m-3"
        style={{
          width: '160px',
        }}
      >
        <div className="p-d-flex p-flex-column p-gap-2">
          <SimpleText medium fontSize="xs" fontColor="color_40">
            Receitas
          </SimpleText>
          <SimpleText medium fontSize="md">
            {data.receita?.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </SimpleText>
        </div>
        <Divider layout="horizontal" className="p-p-0 p-m-0" />
        <div className="p-d-flex p-flex-column p-gap-2">
          <SimpleText medium fontSize="xs" fontColor="color_40">
            Despesas
          </SimpleText>
          <SimpleText medium fontSize="md">
            {data.despesa?.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </SimpleText>
        </div>
      </div>

      <div className="p-col-6 p-px-0 p-d-flex p-jc-end">
        <Pie
          data={{
            labels: ['Despesas', 'Receitas'],
            datasets: [
              {
                data: [data.despesa, data.receita],
                backgroundColor: [chartColors.despesas, chartColors.receitas],
                spacing: 0,
                borderWidth: 0,
                hoverBackgroundColor: [
                  chartColors.despesas,
                  chartColors.receitas,
                ],
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
              datalabels: {
                display: true,
                color: 'white',
                textAlign: 'center',
                font: {
                  weight: 'bold',
                  lineHeight: 1.5,
                },
                formatter: (value, context) => {
                  if (!value || value === 0) return '';
                  return `${
                    context.chart.data?.labels?.[context.dataIndex] || ''
                  } \n ${value?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}`;
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};
