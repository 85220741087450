import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useCallback,
  useEffect,
} from 'react';

import { useDispatch } from 'react-redux';

import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

interface BarraLateralMenuAgendaContextProps {
  reagendamentos: AtendimentoAgendamento[];
  isLoadingReagendamentos: boolean;
  setIsLoadingReagendamentos: (v: boolean) => void;
  handleDeleteReagendamento: (itemId: number) => void;
  barraLateralDisclosure: DisclosureType<{
    to?: 'REAGENDAMENTOS' | 'LISTA_ESPERA';
  }>;
}

const BarraLateralMenuAgendaContext = createContext<
  BarraLateralMenuAgendaContextProps | undefined
>(undefined);

const BarraLateralMenuAgendaProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [reagendamentos, setReagendamentos] = useState<
    AtendimentoAgendamento[]
  >([]);
  const [isLoadingReagendamentos, setIsLoadingReagendamentos] = useState(false);

  const {
    agenda,
    consultorios,
    query: { invalidateReagendamentos },
  } = useAppSelector(state => state);

  const barraLateralDisclosure = useDisclosure({
    opened: false,
  });

  const dispatch = useDispatch();

  const fetchReagendamentos = useCallback(async () => {
    if (!agenda.profissionalAtivo?.id || !consultorios.ativo?.id) return;
    try {
      setIsLoadingReagendamentos(true);
      const response = await AgendamentosAPI.getReagendamentos(
        {
          idProfissionalSaude: agenda.profissionalAtivo.id,
          idConsultorio: consultorios.ativo.id,
        },
        { throwError: true },
      );
      setReagendamentos(response);
    } catch {
    } finally {
      setIsLoadingReagendamentos(false);
    }
  }, [agenda.profissionalAtivo?.id, consultorios.ativo?.id]);

  const handleDeleteReagendamento = async (itemId: number) => {
    try {
      setIsLoadingReagendamentos(true);
      await AgendamentosAPI.deleteReagemento(itemId);

      fetchReagendamentos();
    } catch {
    } finally {
      setIsLoadingReagendamentos(false);
    }
  };

  useEffect(() => {
    fetchReagendamentos();
  }, [fetchReagendamentos]);

  useEffect(() => {
    if (invalidateReagendamentos) {
      fetchReagendamentos();
      dispatch(resetInvalidateQuery);
    }
  }, [dispatch, fetchReagendamentos, invalidateReagendamentos]);

  return (
    <BarraLateralMenuAgendaContext.Provider
      value={{
        reagendamentos,
        isLoadingReagendamentos,
        barraLateralDisclosure,
        setIsLoadingReagendamentos,
        handleDeleteReagendamento,
      }}
    >
      {children}
    </BarraLateralMenuAgendaContext.Provider>
  );
};

const useBarraLateralMenuAgenda = (): BarraLateralMenuAgendaContextProps => {
  const context = useContext(BarraLateralMenuAgendaContext);
  if (context === undefined) {
    throw new Error(
      'useBarraLateralMenuAgenda deve ser usado dentro de um BarraLateralMenuAgendaProvider',
    );
  }
  return context;
};

export { BarraLateralMenuAgendaProvider, useBarraLateralMenuAgenda };
