import { useState, useEffect, useCallback } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import FornecedorTussAPI from 'src/APIs/AdminAPI/FornecedorTussAPI/FornecedorTussAPI';
import CentroCustoAPI from 'src/APIs/FinanceiroAPI/CentroCustoAPI/CentroCustoAPI';
import { ContasPagarAPI } from 'src/APIs/FinanceiroAPI/ContasPagarAPI/ContasPagarAPI';
import TipoContaPagarAPI from 'src/APIs/FinanceiroAPI/TipoContaPagarAPI/TipoContaPagarAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { convertCurrencyToNumber } from 'src/utils/utils';

import ConsultorioDropdown from '../../components/ConsultorioDropdown';
import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { InputNumber } from 'src/components/Basics/Input';
import RadioButtonControlled from 'src/components/Basics/RadioButtonControlled/RadioButtonControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import FormInput from 'src/components/FormInput/FormInput';
import GridListLoading from 'src/components/GridList/GridListLoading';

import validationSchema from './validationSchema';

type FormData = {
  dataVencimento: Date;
  dataPagamento?: Date;
  idConsultorio: any;
  idCentroCusto: number;
  idTipoContaPagar: number;
  idFornecedor: number | null;
  descricao: string;
  valor: number | string;
  valorPago: number | string;
  status: 'A_PAGAR' | 'PAGA' | 'PARCIALMENTE_PAGA';
};

const ContasPagarCreateForm = () => {
  const [loading, setLoading] = useState(true);
  const [editContaPagar, setEditContaPagar] = useState<any>();
  const [optionsCentroCusto, setOptionsCentroCusto] = useState<any[]>([]);
  const [tipoContaOptions, setTipoContaOptions] = useState<any[]>([]);
  const [valor, setValor] = useState<number>(0);
  const [valorPago, setvalorPago] = useState<number>(0);
  const [optionsFornecedores, setoptionsFornecedores] = useState<
    { label: string; value: number }[]
  >([]);
  //Gambi para aplicar regra. No futuro, fazer um currency input controlled para isso. Prazo curto para fazer agora.
  const [hasError, setHasError] = useState(false);

  const { consultorios } = useAppSelector((state: RootState) => state);

  const params = useParams();
  const navigate = useNavigate();

  const useFormMethods = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      idConsultorio: consultorios?.ativo?.id,
      valor: 0,
      valorPago: 0,
    },
  });

  const {
    control,
    watch,
    formState: { isSubmitting, isDirty, errors },
    handleSubmit,
    setValue,
    reset,
    setError,
    clearErrors,
  } = useFormMethods;

  const watchConsultorios = watch('idConsultorio');
  const watchValor = watch('valor');
  const watchDataPagamento = watch('dataPagamento');
  const watchDataVencimento = watch('dataVencimento');
  const watchStatus = watch('status');

  useEffectSkipFirst(() => {
    if (
      watchDataPagamento &&
      watchDataVencimento &&
      watchDataPagamento <= watchDataVencimento &&
      valor < valorPago
    ) {
      setError('valorPago', {
        type: 'max',
        message:
          'O valor pago não pode ser maior que o valor a não ser que a data de pagamento seja maior que a data de vencimento',
      });
      return setHasError(true);
    }
    clearErrors('valorPago');
    return setHasError(false);
  }, [
    watchDataPagamento,
    watchDataVencimento,
    valor,
    valorPago,
    setError,
    clearErrors,
  ]);

  useEffect(() => {
    if (watchDataPagamento) {
      if (
        editContaPagar &&
        new Date(watchDataPagamento).getTime() !==
          new Date(editContaPagar.dataPagamento).getTime()
      ) {
        setValue('status', 'PAGA');
      } else if (!editContaPagar) {
        setValue('status', 'PAGA');
      }
    }
  }, [watchDataPagamento, setValue, editContaPagar]);

  useEffect(() => {
    if (valor && valorPago < valor && valorPago > 0) {
      setValue('status', 'PARCIALMENTE_PAGA');
    }
  }, [setValue, valor, valorPago]);

  useEffect(() => {
    if (watchStatus === 'PAGA') {
      if (!watchDataPagamento) {
        setValue('dataPagamento', new Date());
      }
      setValue('valorPago', watchValor);
      setvalorPago(Number(watchValor) || 0);
    }
  }, [watchStatus, watchDataPagamento, setValue, watchValor]);

  useEffect(() => {
    if (watchStatus === 'PARCIALMENTE_PAGA' && valorPago === valor) {
      toast(<Toast />, {
        data: {
          type: 'warning',
          message:
            'Para a opção "Parcialmente pago", o valor do campo "Valor pago" deve ser menor que o valor do campo "Valor".',
          title: 'Atenção!',
        },
      });
    }
  }, [valor, valorPago, watchStatus]);

  useEffect(() => {
    if (watchStatus === 'A_PAGAR') {
      setValue('dataPagamento', undefined);
      setValue('valorPago', 0);
      setvalorPago(0);
    }
  }, [watchStatus, setValue]);
  
  const loadPage = useCallback(async () => {
    setLoading(true);
    try {
      const response = await ContasPagarAPI.getContaPagarById(
        Number(params.id),
      );
      setEditContaPagar(response);
      setValor(response.valor || 0);
      setvalorPago(response.valorPago || 0);
      const {
        consultorio,
        centroCusto,
        tipoContaPagar,
        dataPagamento,
        dataVencimento,
        status,
        fornecedor,
        ...rest
      } = response;
      reset({
        ...rest,
        idFornecedor: fornecedor?.id || null,
        idConsultorio: consultorio?.id,
        idCentroCusto: centroCusto?.id,
        idTipoContaPagar: tipoContaPagar?.id,
        status: status as 'A_PAGAR' | 'PAGA' | 'PARCIALMENTE_PAGA',
        ...(dataPagamento && { dataPagamento: new Date(dataPagamento) }),
        ...(dataVencimento && { dataVencimento: new Date(dataVencimento) }),
      });
    } catch (error) {
      console.error(error);
      navigate('/faturamento/contas-pagar');
    } finally {
      setLoading(false);
    }
  }, [navigate, params.id, reset]);

  const fetchCentroCusto = useCallback(async () => {
    if (!watchConsultorios) {
      return setOptionsCentroCusto([]);
    }
    try {
      const response: any = await CentroCustoAPI.getCentroCusto({
        consultoriosIds: watchConsultorios,
        ativo: true,
      });
      setOptionsCentroCusto(response.list);
    } catch (error) {
      console.error('Error fetching CentroCusto:', error);
      setOptionsCentroCusto([]);
    }
  }, [watchConsultorios]);

  const fetchTipoConta = useCallback(async () => {
    try {
      const response = await TipoContaPagarAPI.getTipoContaPagar({
        idsConsultorios: watchConsultorios,
        ativo: true,
      });

      setTipoContaOptions(response.list);
    } catch (error) {
      console.error(error);
      setTipoContaOptions([]);
    }
  }, [watchConsultorios]);

  const fetchFornecedores = useCallback(async () => {
    try {
      const response = await FornecedorTussAPI.loadFornecedorTuss({});
      const formatedData = response?.map((f: any) => ({
        label: f.nome,
        value: f.id,
      }));
      setoptionsFornecedores(formatedData);
    } catch (error) {
      setoptionsFornecedores([]);
    }
  }, []);

  const onSubmit = async (data: any) => {
    const { dataPagamento, dataVencimento, ...rest } = data;

    const payload = {
      ...rest,

      ...(dataPagamento && {
        dataPagamento: dataPagamento.toISOString(),
      }),
      ...(dataVencimento && { dataVencimento: dataVencimento.toISOString() }),
    };

    try {
      if (editContaPagar) {
        await ContasPagarAPI.putContaPagar(payload);
      } else {
        await ContasPagarAPI.postContaPagar(payload);
      }
      return navigate('/faturamento/contas-pagar');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCentroCusto();
  }, [fetchCentroCusto]);

  useEffect(() => {
    fetchTipoConta();
  }, [fetchTipoConta]);

  useEffect(() => {
    if (params.id) {
      loadPage();
    } else {
      setLoading(false);
    }
  }, [loadPage, params.id]);

  useEffect(() => {
    fetchFornecedores();
  }, [fetchFornecedores]);

  if (loading) return <GridListLoading />;

  return (
    <FormProvider {...useFormMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="form-tipo-conta-pagar"
        className="p-d-grid p-flex-column p-col-12 p-md-7 p-lg-6 p-xl-5"
      >
        <div className=" p-d-flex p-gap-2  p-w-100 p-my-2">
          <CalendarInputControlled
            className=" p-w-100"
            control={control}
            name="dataVencimento"
            label="Data do vencimento"
            showIcon
          />
          <CalendarInputControlled
            className=" p-w-100"
            control={control}
            name="dataPagamento"
            label="Data do pagamento"
            showIcon
          />
        </div>

        <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
          <DropdownControlled
            className="p-w-100"
            control={control}
            name="idFornecedor"
            label="Fornecedor"
            options={optionsFornecedores}
          />
        </div>

        <div className="p-py-2">
          <ConsultorioDropdown
            className=" p-w-100"
            idConsultorioEdit={editContaPagar?.idConsultorio}
          />
        </div>

        <div className="p-py-2">
          <DropdownControlled
            control={control}
            name="idCentroCusto"
            label="Centro de custo"
            options={optionsCentroCusto || []}
            optionLabel="nome"
            optionValue="id"
            className=" p-w-100"
            disabled={!watchConsultorios || !optionsCentroCusto}
            panelClassName="p-text-truncate"
          />
        </div>

        <div className="p-py-2">
          <DropdownControlled
            control={control}
            name="idTipoContaPagar"
            label="Tipo de conta a pagar"
            options={tipoContaOptions || []}
            optionLabel="tipo"
            optionValue="id"
            className=" p-w-100"
            disabled={!watchConsultorios || !tipoContaOptions}
            panelClassName="p-text-truncate"
          />
        </div>

        <div className="p-py-2">
          <FormInput
            name="descricao"
            label="Descrição"
            className=" p-w-100"
            maxLength={200}
          />
        </div>

        <div className=" p-d-flex p-gap-2  p-w-100 p-py-2">
          <div className="p-d-flex p-flex-column p-gap-2 p-w-100">
            <SimpleText fontSize="xs" medium>
              Valor
            </SimpleText>
            <InputNumber
              mode="currency"
              locale="pt-BR"
              placeholder="R$"
              format={true}
              allowEmpty={false}
              max={9999999.99}
              value={valor}
              onBlur={e => {
                setValue('valor', convertCurrencyToNumber(e.target.value));
              }}
              onChange={e => setValor(e.target.value)}
              errorMsg={errors.valor?.message}
            />
          </div>
          <div className="p-d-flex p-flex-column p-gap-2 p-w-100">
            <SimpleText fontSize="xs" medium>
              Valor pago
            </SimpleText>
            <InputNumber
              placeholder="R$"
              mode="currency"
              locale="pt-BR"
              format={true}
              allowEmpty={false}
              max={9999999.99}
              value={valorPago}
              onBlur={e => {
                setValue('valorPago', convertCurrencyToNumber(e.target.value));
              }}
              onChange={e => setvalorPago(e.target.value)}
              errorMsg={errors.valorPago?.message}
            />
          </div>
        </div>

        <div className="p-mt-1 p-mb-4">
          <SimpleText className="p-col-12 p-px-0 p-py-2" medium>
            Status
          </SimpleText>
          {errors.status?.message && (
            <SimpleText className={'error p-col-12'} fontSize="xxxs">
              {errors.status?.message}
            </SimpleText>
          )}
          <div className="p-my-2 p-d-flex p-gap-2">
            <RadioButtonControlled
              control={control}
              name="status"
              value="A_PAGAR"
              label="A pagar"
            />
            <RadioButtonControlled
              control={control}
              name="status"
              value="PAGA"
              label="Paga"
            />
            <RadioButtonControlled
              control={control}
              name="status"
              value="PARCIALMENTE_PAGA"
              label="Parcialmente pago"
            />
          </div>
        </div>

        <div className="p-col-12 p-md-5 p-lg-4 p-my-2">
          <Button
            type="submit"
            label="Salvar"
            disabled={!isDirty || hasError}
            loading={isSubmitting}
            stretch
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default ContasPagarCreateForm;
