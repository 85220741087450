import React, { useState } from 'react';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import '../RemarcarAgendamento.scss';

interface ConfirmarRemarcacaoProps {
  visible: boolean;
  onConfirm: () => void;
  onHide: () => void;
}

function ConfirmarRemarcacao({
  visible,
  onConfirm,
  onHide,
}: ConfirmarRemarcacaoProps) {

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        className="p-col-align-center"
        onClick={onHide}
        stretch
      />
      <Button label="Sim, liberar" onClick={onConfirm} stretch />
    </div>
  );

  return (
    <Dialog
      className="remarcar-modal"
      visible={visible}
      header="Tornar horário livre"
      onHide={onHide}
      footer={renderFooter}
    >
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>
          Você confirma esta ação?
        </SimpleText>
      </div>
    </Dialog>
  );
}

export default ConfirmarRemarcacao;
