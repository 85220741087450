import { useMemo } from 'react';

import { useNavigate } from 'react-router';

import { useAppSelector } from 'src/core/redux/hooks';
import { userRoutes } from 'src/core/routes/Emed/RouterMap';

import { checkAuth } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import Page from 'src/components/Basics/Page/Page';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import PageHeader from 'src/components/PageHeader/PageHeader';

export const MonitoramentoMenu = () => {
  const {
    user: { authorities },
  } = useAppSelector(state => state);
  const navigate = useNavigate();
  const pacientesItems = useMemo(() => {
    const pacientes = userRoutes.find(route => route.label === 'Monitoramento');
    return pacientes?.items?.filter(item => item.hideFromMenu !== true);
  }, []);

  const menuItensFiltered = pacientesItems?.filter(item => {
    return item?.checkPermission
      ? checkAuth(authorities, item?.checkPermission)
      : true;
  });

  return (
    <Page>
      <PageHeader title="Monitoramento" subtitle="" />
      <div
        className="menu-card p-d-flex p-flex-column p-gap-3 p-mx-5 p-p-3"
        style={{ maxWidth: '375px' }}
      >
        <div className="p-d-flex p-gap-3 p-ai-center">
          <Button btnType="gray" icon="fa-solid fa-bars-staggered" />
          <SimpleText fontSize="md">Menu Monitoramento</SimpleText>
        </div>
        <SimpleText fontSize="xs">
          Acesse as funcionalidades de monitoramento
        </SimpleText>
        <div>
          {menuItensFiltered?.map((item, idx) => (
            <Button
              className="p-pl-0"
              key={idx}
              label={item.label}
              btnType="green-link"
              onClick={() => navigate(item.route)}
            />
          ))}
        </div>
      </div>
    </Page>
  );
};
