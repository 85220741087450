import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import AtendimentoAPI from 'src/APIs/AgendaAPI/Atendimento/AtendimentoAPI';
import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';
import ProntuarioAPI from 'src/APIs/ProntuarioAPI/ProntuarioAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { AtendimentoState } from 'src/core/redux/slices/atendimento/AtendimentoSlice';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';
import { TempoAtendimentoProps } from 'src/utils/hooks/useTimer';

import { Timer } from './utils/timer';
import { formatarData } from 'src/utils/date';

import { DialogFinalizarExecucao } from '../FichaPaciente/PrescricaoMedicamentos/PanelContent/components/DialogEncaminharExecucao/DialogEncaminharExecucao';
import { Button } from 'src/components/_UI/Button';
import Avatar from 'src/components/Avatar/Avatar';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import Dialog from 'src/components/Dialog/Dialog';
import Skeleton from 'src/components/Skeleton/Skeleton';
import Tooltip from 'src/components/Tooltip/Tooltip';

import MenuConfirmarConsulta from '../Atendimento/IniciarAtendimento/TipoConsulta/MenuConfirmarConsulta';

import { ModalAdicionarFamiliares } from './ModalAdicionarFamiliares/ModalAdicionarFamiliares';
import ModalHistoricoExportacaoProntuario from './ModalHistoricoExportacaoProntuario/ModalHistoricoExportacaoProntuario';
import ModalListaAlergias from './ModalListaAlergias/ModalListaAlergias';
import ModalListaComorbidades from './ModalListaComorbidades/ModalListaComorbidades';
import ModalPersonalizarProntuario from './ModalPersonalizarProntuario/ModalPersonalizarProntuario';
import ModalRegistroObito from './ModalRegistroObito/ModalRegistroObito';

import './Cabecalho.scss';

interface CabecalhoProps {
  dadosCabecalho: Prontuario | undefined;
  dadosRegistroObito: GetRegistroObito | null;
  carregandoDados: boolean;
  onSuccessRegistroObito: (novosDadosRegistroObito: GetRegistroObito) => void;
  onGoSolicitacaoExamesTab: () => void;
}

export interface HandlePausarProps {
  route?: string;
}

function Cabecalho({
  dadosCabecalho,
  dadosRegistroObito,
  carregandoDados,
  onSuccessRegistroObito,
  onGoSolicitacaoExamesTab,
}: CabecalhoProps) {
  const { paciente } = dadosCabecalho || {};

  const dispatch = useDispatch();
  const { query, user } = useAppSelector((state: RootState) => state);
  const atendimento: AtendimentoState = useAppSelector(
    (state: RootState) => state.atendimento,
  );

  const tempoAtendimento: React.MutableRefObject<TempoAtendimentoProps | null> =
    useRef(null);

  const {
    atendimentoStatus,
    finalizarTriagem,
    sairPEPDialog,
    pausarAtendimento,
    requiredJustificativa,
  } = useAtendimento();

  const { state } = useLocation();
  const idAtendimento = state?.idAtendimento;

  const { windowInnerWidth, isMobile } = useSize();

  const menuProntuario = useRef<any>(null);
  const navigate = useNavigate();

  const confirmarFinalizacaoConsulta = useDisclosure({ opened: false });
  const modalAddFamiliares = useDisclosure({ opened: false });
  const dialogFinalizarPrescricao = useDisclosure({ opened: false });
  const modalHistoricoExportacaoProntuario = useDisclosure({ opened: false });
  const confirmPendenciasAtendimento = useDisclosure({ opened: false });

  const { setValue } = useLocalStorage(`tempoAtendimento/${idAtendimento}`);

  const [modalRegistroObitoVisivel, setModalRegistroObitoVisivel] =
    useState<boolean>(false);
  const [modalPersonalizarVisivel, setModalPersonalizarVisivel] =
    useState<boolean>(false);
  const [modalListaAlergias, setModalListaAlergias] = useState<any>([]);
  const [modalListaComorbidades, setModalListaComorbidades] = useState<
    Comorbidade[]
  >([]);
  const [alergias, setAlergias] = useState<any>([]);
  const [loadingPendencias, setLoadingPendencias] = useState(false);

  const { invalidateMemed } = query;

  useEffect(() => {
    setAlergias(dadosCabecalho?.pacienteAlergias.list || []);
  }, [dadosCabecalho?.pacienteAlergias]);

  function voltarParaAgenda() {
    if (
      atendimentoStatus === 'ATENDENDO' ||
      atendimentoStatus === 'PRESCRICAO_EXECUCAO'
    ) {
      sairPEPDialog.open({
        state: {
          ...sairPEPDialog.state,
          route: '/agenda',
        },
      });
      return;
    }
    navigate('/agenda', { replace: true });
  }

  function handleHideModalRegistroObito() {
    setModalRegistroObitoVisivel(false);
  }

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  useEffectSkipFirst(() => {
    if (invalidateMemed) {
      ProntuarioAPI.getCabecalhoProntuario(idAtendimento, { throwError: true })
        .then(data => setAlergias(data.pacienteAlergias.list))
        .catch(() => {})
        .finally(() => dispatch(resetInvalidateQuery()));
    }
  }, [invalidateMemed]);

  const pacienteSexo = useMemo(
    () => capitalizeFirstLetter(paciente?.sexoDescricao || ''),
    [paciente],
  );

  const pacienteDataNascimento = useMemo(
    () => formatarData(paciente?.dataNascimento || ''),
    [paciente],
  );

  const handlePausarAtendimento = async ({
    route = '/agenda',
  }: HandlePausarProps): Promise<void> => {
    if (atendimentoStatus === 'PRESCRICAO_EXECUCAO') {
      await PrescricaoAPI.pausarPrescricao(idAtendimento, user.idUsuario);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      pausarAtendimento(atendimento, tempoAtendimento.current!);
    }

    setValue(tempoAtendimento.current?.time);
    setTimeout(() => {
      navigate(route, { replace: true });
    }, 400);
  };

  const handleFinalizarTriagem = useCallback(async () => {
    await finalizarTriagem(Number(idAtendimento));

    navigate('/agenda');
  }, [finalizarTriagem, idAtendimento, navigate]);

  const handleFinalizarAtendimento = async () => {
    try {
      if (atendimentoStatus === 'ATENDENDO') {
        setLoadingPendencias(true);
        const response = await AtendimentoAPI.getPendenciasAtendimento(
          idAtendimento,
          { throwError: true },
        );
        if (response?.existePendencias) {
          return confirmPendenciasAtendimento.open();
        }
      }
      if (requiredJustificativa) {
        return toast(<Toast />, {
          data: {
            title: 'Dados incompletos!',
            message:
              'Por favor, informe a justificativa para o atendimento retroativo.',
            type: 'warning',
          },
        });
      }

      if (atendimentoStatus === 'ATENDENDO_TRIAGEM') {
        return handleFinalizarTriagem();
      }

      if (atendimentoStatus === 'PRESCRICAO_EXECUCAO') {
        return dialogFinalizarPrescricao.open();
      }

      confirmarFinalizacaoConsulta.open();
    } catch (error) {
    } finally {
      setLoadingPendencias(false);
    }

    return;
  };

  const menuProntuarioItems = useMemo(
    () => [
      {
        label: 'Sigilo',
        disabled: true,
      },
      {
        label: 'Personalizar prontuário',
        command: () => setModalPersonalizarVisivel(true),
      },
      {
        separator: true,
      },
      {
        label: 'Registrar óbito',
        command: () => setModalRegistroObitoVisivel(true),
      },
      {
        label: 'Histórico exportação prontuário',
        command: () => modalHistoricoExportacaoProntuario.open(),
      },
    ],
    [],
  );

  const comorbidadesCids = useMemo(
    () =>
      (dadosCabecalho?.comorbidades?.list || []).filter(
        (comorbidade: Comorbidade) => !!comorbidade?.cid?.[0]?.cid,
      ),
    [dadosCabecalho?.comorbidades.list],
  );

  const tresPrimeirasComorbidades = useMemo(
    () => comorbidadesCids.slice(0, 3),
    [comorbidadesCids],
  );

  const outrasComorbidades = useMemo(
    () => comorbidadesCids.slice(3),
    [comorbidadesCids],
  );

  const showBtns = useMemo(() => {
    switch (atendimentoStatus) {
      case 'PRESCRICAO_EXECUCAO':
        return true;

      case 'CONTINUAR':
        return true;

      case 'ATENDENDO':
        return true;

      case 'ATENDENDO_TRIAGEM':
        return true;

      default:
        return false;
    }
  }, [atendimentoStatus]);

  const buttonLabel = useMemo(() => {
    if (atendimentoStatus === 'ATENDENDO_TRIAGEM') return 'Finalizar Triagem';
    return 'Finalizar Atendimento';
  }, [atendimentoStatus]);

  const btnVoltarAgendaFullSize = useMemo(() => {
    const isAtendimentoPausado = atendimentoStatus.includes('CONTINUAR');

    if (atendimentoStatus === 'ATENDENDO_TRIAGEM') return true;
    if (isAtendimentoPausado || !showBtns || windowInnerWidth > 1065)
      return true;

    return false;
  }, [atendimentoStatus, showBtns, windowInnerWidth]);

  const showFinalizarButton = useMemo(() => {
    if (
      atendimentoStatus !== 'CONTINUAR' &&
      atendimentoStatus !== 'PRESCRICAO_PAUSADA'
    ) {
      return true;
    }
    return false;
  }, [atendimentoStatus]);

  const handleConfirmShowPendencias = () => {
    confirmPendenciasAtendimento.close();
    onGoSolicitacaoExamesTab();
  };

  return (
    <Page id="cabecalho-prontuario" content white>
      <>
        <ConfirmDialog
          header="Deseja corrigir a pendência e finalizar esse atendimento?"
          text={
            <SimpleText color={FONT_COLOR.COLOR_40}>
              Para finalizar, primeiro corrija as pendências que existem nesse
              atendimento! <br /> Siga ate a tela de{' '}
              <b>solicitação de exames.</b>
            </SimpleText>
          }
          visible={confirmPendenciasAtendimento.isOpen}
          onHide={() => confirmPendenciasAtendimento.close()}
          onConfirm={handleConfirmShowPendencias}
          confirmText="Sim, ver a pendência"
          cancelText="Não"
        />
        {modalRegistroObitoVisivel && (
          <ModalRegistroObito
            dadosRegistroObito={dadosRegistroObito}
            idPaciente={paciente?.id as number}
            dataNascimento={
              new Date(paciente?.dataNascimento.replace('-', '/') as string)
            }
            visivel={modalRegistroObitoVisivel}
            onSuccess={onSuccessRegistroObito}
            onHide={handleHideModalRegistroObito}
          />
        )}
        {modalPersonalizarVisivel && (
          <ModalPersonalizarProntuario
            visivel={modalPersonalizarVisivel}
            onHide={() => setModalPersonalizarVisivel(false)}
          />
        )}
        {modalListaAlergias && (
          <ModalListaAlergias
            modalLista={modalListaAlergias}
            onHide={() => setModalListaAlergias([])}
          />
        )}

        {modalListaComorbidades && (
          <ModalListaComorbidades
            modalLista={modalListaComorbidades}
            onHide={() => setModalListaComorbidades([])}
          />
        )}

        <div className="pep-header-content">
          <Avatar
            className={`avatar ${dadosRegistroObito && 'obito'}`}
            label={paciente?.nome || ''}
            image={paciente?.fotoUrl || ''}
          />
          <div className="info-paciente">
            <Skeleton
              loading={carregandoDados}
              width="60%"
              height="14px"
              borderRadius="8px"
              className="p-mb-2"
            >
              <SimpleText
                id={dadosRegistroObito ? 'nome-paciente-prontuario-obito' : ''}
                className="espacamento-texto"
                bold
                fontSize={FONT_SIZE.MD}
                fontColor={FONT_COLOR.COLOR_16}
                onClick={() =>
                  dadosRegistroObito && setModalRegistroObitoVisivel(true)
                }
              >
                {paciente?.nome}
                {dadosCabecalho?.atendimento?.encaminhamento && (
                  <span className="p-mx-2 tag-cabecalho-prontuario tag-e">
                    Encaminhamento
                  </span>
                )}

                {dadosRegistroObito && (
                  <span className="p-ml-2">
                    (em lembrança)
                    <i className="fas fa-chevron-right" />
                  </span>
                )}
              </SimpleText>
            </Skeleton>

            <Skeleton
              loading={carregandoDados}
              width="60%"
              height="14px"
              borderRadius="8px"
              className="p-mb-2"
            >
              <SimpleText
                className="espacamento-texto"
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                {`${paciente?.idadeAnos} anos, ${paciente?.idadeMeses} meses e ${paciente?.idadeDias} dias`}
                <span className="p-mx-2">•</span>
                {pacienteSexo}
                {paciente?.cpf && (
                  <>
                    <span className="p-mx-2">•</span>
                    {paciente.cpf}
                  </>
                )}
              </SimpleText>
            </Skeleton>

            <Skeleton
              loading={carregandoDados}
              width="60%"
              height="14px"
              borderRadius="8px"
              className="p-mb-2"
            >
              <SimpleText
                className="espacamento-texto"
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                ({paciente?.id})<span className="p-mx-2">•</span>
                {pacienteDataNascimento}
                {dadosCabecalho?.atendimento?.cartaoPlano && (
                  <>
                    <span className="p-mx-2">•</span>
                    {dadosCabecalho?.atendimento?.nomeConvenio}
                    <span className="p-mx-2">•</span>
                    {dadosCabecalho?.atendimento?.cartaoPlano}
                  </>
                )}
              </SimpleText>
            </Skeleton>
          </div>
          <div className="header-btns p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            {btnVoltarAgendaFullSize ? (
              <Button
                label="Voltar para agenda"
                btnType="gray"
                icon="fas fa-arrow-left"
                onClick={voltarParaAgenda}
              />
            ) : (
              <Button
                btnType="gray"
                aria-label="Voltar para agenda"
                className="btn-header-radius"
                icon="fa fa-arrow-left"
                iconPos="left"
                onClick={voltarParaAgenda}
              />
            )}

            {showBtns && (
              <>
                {(atendimentoStatus === 'ATENDENDO' ||
                  atendimentoStatus === 'PRESCRICAO_EXECUCAO') && (
                  <>
                    {isMobile ? (
                      <Button
                        aria-label="Pausar atendimento"
                        className="btn-header-radius"
                        onClick={() => handlePausarAtendimento({})}
                        btnType="warn"
                        icon="fa-solid fa-pause"
                      />
                    ) : (
                      <Button
                        label="Pausar atendimento"
                        btnType="warn"
                        onClick={() => handlePausarAtendimento({})}
                      />
                    )}
                  </>
                )}

                {showFinalizarButton && (
                  <div style={{ position: 'relative' }}>
                    <Button
                      label={buttonLabel}
                      btnType="danger"
                      onClick={() => handleFinalizarAtendimento()}
                      loading={loadingPendencias}
                    />

                    <Timer
                      tempoAtendimento={tempoAtendimento}
                      handlePausarAtendimento={handlePausarAtendimento}
                    />
                  </div>
                )}
              </>
            )}

            <TieredMenu
              id="opcoes-menu"
              model={menuProntuarioItems}
              popup
              ref={menuProntuario}
            />
            {!showFinalizarButton ? (
              <div style={{ position: 'relative' }}>
                <Button
                  icon="fas fa-ellipsis-h"
                  btnType="gray"
                  btnSize="medium"
                  onClick={event => menuProntuario?.current?.toggle(event)}
                  aria-controls="opcoes-menu"
                  aria-haspopup
                />
                <Timer
                  tempoAtendimento={tempoAtendimento}
                  handlePausarAtendimento={handlePausarAtendimento}
                  className="timer-width"
                />
              </div>
            ) : (
              <Button
                icon="fas fa-ellipsis-h"
                btnType="gray"
                btnSize="medium"
                onClick={event => menuProntuario?.current?.toggle(event)}
                aria-controls="opcoes-menu"
                aria-haspopup
              />
            )}
          </div>
        </div>

        <div className={isMobile ? 'p-mt-4' : 'p-mt-2'}>
          <Skeleton
            loading={carregandoDados}
            width="40%"
            height="14px"
            borderRadius="8px"
          >
            <>
              {tresPrimeirasComorbidades.map((comorbidade: Comorbidade) => (
                <>
                  <Tooltip target={`.${comorbidade?.cid?.[0]?.cid?.codigo}`} />
                  <SimpleText
                    className={`comorbidades-ativas arritmia ${comorbidade?.cid?.[0]?.cid?.codigo}`}
                    data-pr-tooltip={comorbidade?.cid?.[0]?.cid?.nome}
                    data-pr-position="top"
                  >
                    {comorbidade?.cid?.[0]?.cid?.codigo}
                  </SimpleText>
                </>
              ))}
              <SimpleText
                id="outras-comorbidades"
                className="espacamento-texto"
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_40}
              >
                {!!tresPrimeirasComorbidades.length && !outrasComorbidades.length &&
                  <span
                    className="p-mr-1 text-green"
                    onClick={() => setModalListaComorbidades(comorbidadesCids)}
                  >problema{outrasComorbidades.length > 1 && 's'}</span>}
                  
                {!!outrasComorbidades.length && (
                  <span
                    className="p-mx-1 text-green"
                    onClick={() => setModalListaComorbidades(comorbidadesCids)}
                  >
                    {outrasComorbidades.length} problema{outrasComorbidades.length > 1 && 's'}
                  </span>
                )}
                {!!alergias.length && (
                  <>
                    {!!outrasComorbidades.length && 'e '}
                    {!!tresPrimeirasComorbidades.length && !outrasComorbidades.length && 'e '}
                    <span
                      className="text-green"
                      onClick={() => setModalListaAlergias(alergias)}
                    >
                      {alergias.length} alergia{alergias.length > 1 && 's'}
                    </span>
                  </>
                )}
              </SimpleText>
            </>
          </Skeleton>
        </div>

        {confirmarFinalizacaoConsulta.isOpen && (
          <MenuConfirmarConsulta
            visible={confirmarFinalizacaoConsulta.isOpen}
            onHide={() => confirmarFinalizacaoConsulta.close()}
            idAtendimento={idAtendimento}
          />
        )}

        {sairPEPDialog.isOpen && <SairPEPDialog {...sairPEPDialog} />}

        {modalAddFamiliares.isOpen && (
          <ModalAdicionarFamiliares {...modalAddFamiliares} />
        )}

        {dialogFinalizarPrescricao.isOpen && (
          <DialogFinalizarExecucao {...dialogFinalizarPrescricao} />
        )}

        {modalHistoricoExportacaoProntuario.isOpen && (
          <ModalHistoricoExportacaoProntuario
            {...modalHistoricoExportacaoProntuario}
            paciente={paciente}
          />
        )}
      </>
    </Page>
  );
}

const SairPEPDialog = ({ close, isOpen, state }: DisclosureType) => {
  const { atendimentoStatus } = useAtendimento();

  const pausarAtendimentoType = atendimentoStatus.includes('TRIAGEM')
    ? 'PAUSADO_TRIAGEM'
    : 'PAUSADO';

  const dialogText =
    atendimentoStatus === 'PRESCRICAO_EXECUCAO'
      ? 'Existe uma prescrição não executada ou dados não salvos, tem certeza que deseja sair?'
      : 'Você está saindo de um atendimento em andamento.';

  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      closable={false}
      header="Sair do atendimento"
      className="dialog-sair-atendimento"
    >
      <div className="p-d-flex p-flex-column p-gap-4">
        <div>
          <SimpleText>{dialogText}</SimpleText>
        </div>
        <div className="p-col-12 p-d-flex p-flex-column p-flex-sm-row p-gap-1 p-ai-center p-mt-1">
          <Button
            label="Cancelar"
            btnType="ghost"
            onClick={() => close()}
            stretch
          />
          <Button
            label="Sair sem salvar"
            btnType="danger"
            onClick={() => close({ state: { ...state, type: 'DESCARTADO' } })}
            stretch
          />
          <Button
            label="Continuar depois"
            onClick={() =>
              close({ state: { ...state, type: pausarAtendimentoType } })
            }
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
};

export default Cabecalho;
