import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

export const DialogPendenciasReagendamento = ({
  close,
  state,
}: DisclosureType<{ handleReagendar: () => void }>) => {
  return (
    <Dialog
      onHide={close}
      visible
      header="Deseja reagendar os pacientes com horário indisponíveis?"
      style={{
        width: '420px',
      }}
      closable={false}
    >
      <div className="p-w-100 p-d-flex p-flex-column p-gap-3">
        <SimpleText className="p-line-height-2">
          Horário de atendimento ou compromisso alterado. É necessário reagendar
          os pacientes.
        </SimpleText>
        <div className="p-d-flex p-gap-1">
          <Button
            label="Não, fechar"
            btnType="ghost"
            onClick={() => close()}
            stretch
          />
          <Button
            label="Sim, reagendar"
            onClick={() => [state?.handleReagendar(), close()]}
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
};
