import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';

import dayjs from 'dayjs';
import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';
import { DashboardAPI } from 'src/APIs/AdminAPI/DashboardAPI/DashboardAPI';

import { useAppSelector } from 'src/core/redux/hooks';

type Filters = {
  periodo: {
    dataInicial: string;
    dataFinal: string;
  };
  idConsultorio?: number;
  idProfissional?: number;
};

interface DashboardContextData {
  usuariosPendentes: IDashboardGraficosMesQuantidadeResponse[];
  transacoesTiss: IDashboardGraficosMesQuantidadeResponse[];
  consultorios: GetConsultorioDTO[];
  filters: Filters;
  setFilters: (filters: Filters) => void;
  isLoading: boolean;
}

const DashboardContext = createContext<DashboardContextData | undefined>(
  undefined,
);

export const DashboardProvider: React.FC = ({ children }) => {
  const [usuariosPendentes, setUsuariosPendentes] = useState<
    IDashboardGraficosMesQuantidadeResponse[]
  >([]);
  const [transacoesTiss, setTransacoesTiss] = useState<
    IDashboardGraficosMesQuantidadeResponse[]
  >([]);
  const [consultorios, setConsultorios] = useState<GetConsultorioDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState<Filters>({
    periodo: {
      dataInicial: dayjs().format('DD-MM-YYYY'),
      dataFinal: dayjs().format('DD-MM-YYYY'),
    },
  });

  const { user } = useAppSelector(state => state);

  const fetchUsariosPendentes = useCallback(async () => {
    try {
      const response = await DashboardAPI.loadGraficoUsuarios({
        throwError: true,
      });
      return response;
    } catch {
      return [];
    }
  }, []);

  const fetchTransacoesTissRealizadas = useCallback(async () => {
    try {
      const response = await DashboardAPI.loadGraficoTissRealizadas({
        throwError: true,
      });
      return response;
    } catch {
      return [];
    }
  }, []);

  const fetchConsultorios = useCallback(async () => {
    try {
      const response = await ConsultorioAPI.loadConsultorios({
        throwError: true,
        pageSize: 99,
      });
      return response.list;
    } catch {
      return [];
    }
  }, []);

  useEffect(() => {
    if (!user.idEmpresa) return;

    setIsLoading(true);

    Promise.allSettled([
      fetchUsariosPendentes(),
      fetchTransacoesTissRealizadas(),
      fetchConsultorios(),
    ])
      .then(([usuariosPendentes, transacoesTiss, consultorios]) => {
        if (usuariosPendentes.status === 'fulfilled') {
          setUsuariosPendentes(usuariosPendentes.value);
        }
        if (transacoesTiss.status === 'fulfilled') {
          setTransacoesTiss(transacoesTiss.value);
        }
        if (consultorios.status === 'fulfilled') {
          setConsultorios(consultorios.value);
        }
      })
      .finally(() => setIsLoading(false));
  }, [
    fetchConsultorios,
    fetchTransacoesTissRealizadas,
    fetchUsariosPendentes,
    user.idEmpresa,
  ]);

  return (
    <DashboardContext.Provider
      value={{
        usuariosPendentes,
        transacoesTiss,
        consultorios,
        isLoading,
        filters,
        setFilters,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = (): DashboardContextData => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};
