import { useCallback, useEffect, useState } from 'react';

import { DashboardAPI } from 'src/APIs/AdminAPI/DashboardAPI/DashboardAPI';

import { FinanceiroChart } from '../_components/FinanceiroChart';
import { IndicadoresLineChart } from '../_components/IndicadoresLineChart';
import { IndicadoresTotais } from '../_components/IndicadoresTotais';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { DashboardFilter } from './DashboardFilter';

export const DashboardContentSecretariado = ({}) => {
  const [atendimentos, setAtendimentos] = useState<
    IDashboardGraficosMesQuantidadeResponse[]
  >([]);

  const fetchAtendimentos = useCallback(async () => {
    try {
      const response = await DashboardAPI.loadGraficoAtendimentos({
        throwError: true,
      });

      setAtendimentos(response);
    } catch {}
  }, []);

  useEffect(() => {
    fetchAtendimentos();
  }, [fetchAtendimentos]);

  return (
    <div className="p-w-100 p-d-flex p-flex-wrap p-ai-end p-mt-2">
      <div
        className="p-col-12 p-md-6 p-d-flex p-flex-column p-gap-2"
        style={{
          height: '317px',
        }}
      >
        <SimpleText fontSize="xs" fontColor="color_40" medium>
          Indicadores totais
        </SimpleText>
        <IndicadoresTotais />
      </div>

      <div className="p-col-12 p-md-6 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xs" fontColor="color_40" medium>
          Atendimentos realizados por mês
        </SimpleText>
        <IndicadoresLineChart
          data={{
            labels: atendimentos.map(({ mes }) => mes).reverse(),
            values: atendimentos.map(({ quantidade }) => quantidade).reverse(),
          }}
        />
      </div>

      <div className="p-col-12 p-md-6 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xs" fontColor="color_40" medium>
          Financeiro dos últimos 30 dias
        </SimpleText>
        <FinanceiroChart className="p-w-100" />
      </div>

      <DashboardFilter />
    </div>
  );
};
