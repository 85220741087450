import { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useSubscription } from 'react-stomp-hooks';
import { toast } from 'react-toastify';

import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { setProfissionalAtivo } from 'src/core/redux/slices/agenda/AgendaSlice';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { PRESENCA_TISS_TOAST_MESSAGES } from '../utils';

import Toast from 'src/components/Basics/Toast/Toast';

import Sigilo from 'src/pages/Emed/Agenda/Sigilo/Sigilo';

import { useAgenda } from '../AgendaContext';

import AgendaDia from './AgendaDia/AgendaDia';
import ProximosPacientes from './ProximosPacientes/ProximosPacientes';

import './AgendaProfissionalSaude.scss';

function AgendaProfissionalSaude() {
  const dispatch = useDispatch();
  const tipoProfissional = useRef('');

  const { user, consultorios, query, agenda } = useAppSelector(
    (state: RootState) => state,
  );

  const {
    agendaDiaItems,
    loadingAgendamentosDia,
    dialogImprimirEtiqueta,
    obrigaPresencaCuidado,
    refetchAgenda,
  } = useAgenda();

  useEffect(() => {
    if (!agenda.profissionaisDisponiveis?.length) return;

    const profissional = agenda.profissionaisDisponiveis.find(
      (profissional: any) => profissional.id === user?.idUsuario,
    );

    if (profissional) {
      dispatch(setProfissionalAtivo(profissional));
    }
  }, [agenda.profissionaisDisponiveis, dispatch, user?.idUsuario]);

  const [dataAgenda, setDataAgenda] = useState<Date>(new Date());

  const { invalidateAgenda, invalidateAgendaList, state } = query;

  useSubscription('/user/topic/notificacao', (message: any) => {
    const { tipoNotificacao, funcionalidade, dadosComplementares } = JSON.parse(
      message.body,
    );

    const isTiss = tipoNotificacao === 'TISS' && funcionalidade === 'AGENDA';
    const transacao =
      dadosComplementares?.transacao === 'SADT' ||
      dadosComplementares?.transacao === 'STATUS_AUTORIZACAO';

    if (isTiss && transacao) {
      const agendamentoIsVisible = agendaDiaItems.some(
        agendamento => agendamento.id == dadosComplementares.idAtendimento,
      );

      if (agendamentoIsVisible) refetchAgenda();

      let toastData =
        dadosComplementares.statusAtendimento === 'PRESENTE'
          ? PRESENCA_TISS_TOAST_MESSAGES.autorizado
          : PRESENCA_TISS_TOAST_MESSAGES.negado_operadora;

      if (dadosComplementares.sucesso === 'false')
        toastData = PRESENCA_TISS_TOAST_MESSAGES.error;

      if (!!dadosComplementares?.statusAtendimento)
        toast(<Toast />, { data: toastData });
    }
  });

  useEffect(() => {
    const getUsuario = async () => {
      const usuario = await UsuarioAPI.loadUsuarioById(user?.idUsuario);

      tipoProfissional.current =
        usuario?.profissionalSaude?.conselhoTissProfissional?.profissional;
    };
    getUsuario();
  }, [user?.idUsuario]);

  useEffect(() => {
    if (invalidateAgenda) {
      refetchAgenda();
      dispatch(resetInvalidateQuery());
      return;
    }
    if (invalidateAgendaList) {
      state?.novaData ? setDataAgenda(state.novaData) : refetchAgenda();
      dispatch(resetInvalidateQuery());
      return;
    }
  }, [
    dispatch,
    invalidateAgenda,
    invalidateAgendaList,
    refetchAgenda,
    state?.novaData,
  ]);

  const renderSigilo = useCallback(
    (atendimento: AtendimentoAgendamento): JSX.Element => (
      <Sigilo atendimento={atendimento} />
    ),
    [],
  );

  return (
    <div id="agenda-page-container">
      <ProximosPacientes
        items={agendaDiaItems}
        isLoading={loadingAgendamentosDia}
        renderSigilo={renderSigilo}
        tipoProfissional={tipoProfissional.current}
        obrigaPresencaCuidado={obrigaPresencaCuidado}
      />

      <AgendaDia
        items={agendaDiaItems}
        isLoading={loadingAgendamentosDia}
        dataAgenda={dataAgenda}
        renderSigilo={renderSigilo}
      />
    </div>
  );
}

export default AgendaProfissionalSaude;
