import { Outlet, Route, Navigate } from 'react-router-dom';

import { useAppSelector } from 'src/core/redux/hooks';
import { userRoutes } from 'src/core/routes/Emed/RouterMap';

import { AuthEnforcer, generateRoutes } from './utils';

import BiometriaOK from 'src/pages/Emed/Agenda/MenuConfirmarPresenca/BiometriaFacial/BiometriaOK';
import LoggedRoot from 'src/pages/Emed/LoggedRoot/LoggedRoot';
import { BiometriaFacialAuth } from 'src/pages/Emed/Login/BiometriaFacialAuth';
import { Login } from 'src/pages/Emed/Login/Login';
import { RecuperacaoSenha } from 'src/pages/Emed/Login/RecuperacaoSenha';
import { RedefinirSenha } from 'src/pages/Emed/Login/RedefinirSenha';
import { ValidacaoCodigo } from 'src/pages/Emed/Login/ValidacaoCodigo';

export default function EmedRoutes() {
  const { authorities } = useAppSelector(state => state.user);

  return (
    <Route element={<Outlet />}>
      <Route path={'/'} element={<AuthEnforcer />}>
        <Route path={''} element={<LoggedRoot />}>
          {generateRoutes(userRoutes, authorities)}
        </Route>
      </Route>
      <Route path={'/login'} element={<Login />} />
      <Route path={'/redefine'} element={<RedefinirSenha />} />
      <Route path={'/forgot-password'} element={<RecuperacaoSenha />} />
      <Route
        path={'/recover-password/confirmar'}
        element={<ValidacaoCodigo />}
      />
      <Route path={'/biometric-auth'} element={<BiometriaFacialAuth />} />
      {/* <Route path={'*'} element={<NotFound />} /> */}
      <Route path="/biometria" element={<BiometriaOK />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  );
}
