import { memo } from 'react';

import './GridList.scss';
import Skeleton from '../Skeleton/Skeleton';

const GridListLoading = memo(() => {
  return (
    <div className="GridList p-w-100">
      <Skeleton height="40px" borderRadius="8px" loading />
      <Skeleton height="40px" borderRadius="8px" loading />
      <Skeleton height="40px" borderRadius="8px" loading />
      <Skeleton height="40px" borderRadius="8px" loading />
    </div>
  );
});

export default memo(GridListLoading);
