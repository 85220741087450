import { useCallback, useEffect, useMemo, useState } from 'react';

import { Outlet } from 'react-router';
import { toast } from 'react-toastify';

import { AssinaturaHsmAPI } from 'src/APIs/AdminAPI/AssinaturaHsm/AssinaturaHsmAPI';
import { AssinaturaAPI } from 'src/APIs/AgendaAPI/AssinaturaAPI/AssinaturaAPI';
import ParametroAPI from 'src/APIs/ConfigAPI/Parametro/ParametroAPI';

import { AssinaturaContext } from 'src/core/contexts/Assinatura/Assinatura';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import Toast from 'src/components/Basics/Toast/Toast';

export function AssinaturaProvider({ children }: any) {
  const { user } = useAppSelector((state: RootState) => state);
  const [webSigner, setWebSigner] = useState<any>({
    handleSign: () => {},
    isSuccess: false,
    log: '',
    isLoaded: false,
    evalWebSignerRef: null,
    hsmValidDate: false,
    hsmPIN: null,
  });
  const [hsmValidDate, setHsmValidDate] = useState(false);
  const [hsmPIN, setHsmPIN] = useState(null);
  const [modeloAssinatura, setModeloAssinatura] = useState<any>();

  const dialogAssinarDigitalmente = useDisclosure({ opened: false });

  useEffect(() => {
    const getModeloAssinatura = async () => {
      if (!user.idEmpresa) return;
      try {
        const responseModeloAssinatura =
          await ParametroAPI.loadParametrosByCodigo(
            {
              codigo: 'MODELO_ASSINATURA_ELETRONICA',
            },
            { throwError: true },
          );
        if (!responseModeloAssinatura.valor) return;
        setModeloAssinatura(responseModeloAssinatura.valor);
      } catch {}
    };

    getModeloAssinatura();
  }, [user.idEmpresa]);

  const getPin = useCallback(async () => {
    const response = await AssinaturaHsmAPI.getPinHSM(user.idUsuario);
    if (new Date(response?.data?.hsmDataVencimento) > new Date()) {
      setHsmPIN(response?.data?.hsmSenha);
      setHsmValidDate(true);
    } else {
      setHsmValidDate(false);
    }
  }, [user.idUsuario]);

  useEffect(() => {
    if (user?.tipoUsuario === 'PROFISSIONAL_SAUDE') {
      getPin();
    }
  }, [getPin, user?.tipoUsuario]);

  const canSignHSM = useMemo(() => {
    return hsmValidDate && hsmPIN && modeloAssinatura === 'HSM';
  }, [hsmValidDate, hsmPIN, modeloAssinatura]);

  const promptForPIN = useCallback(
    (base64: any) => {
      return new Promise((resolve, reject) => {
        dialogAssinarDigitalmente.open({
          state: {
            base64,
            onSubmit: () => resolve(base64),
            onClose: () =>
              reject(new Error('Erro ao salvar a senha PIN da assinatura')),
          },
        });
      });
    },
    [dialogAssinarDigitalmente],
  );

  const signDocument = useCallback(
    async (idAtendimento: number, assinatura: string, hideToast?: boolean) => {
      await AssinaturaAPI.putAssinarDocumento(idAtendimento, { assinatura });
      if (!hideToast) {
        toast(<Toast />, {
          data: {
            type: 'success',
            message: 'Prontuário assinado com sucesso',
            title: 'Sucesso',
          },
        });
      }
      if (!hsmValidDate) {
        setHsmPIN(null);
      }
    },
    [hsmValidDate],
  );

  const signDocumentHSM = useCallback(
    async (item: any, hideToast?: boolean) => {
      try {
        const response = await AssinaturaHsmAPI.assinarDocumentoHSM(
          item.prontuarioBase64.prontuarioBase64,
        );
        if (response?.data?.documents?.length) {
          await signDocument(
            item.atendimentoId,
            response.data.documents[0].signatures[0].value,
            hideToast,
          );
        }
      } catch (error) {
        console.error('Erro ao assinar documento:', error);
        throw error;
      }
    },
    [signDocument],
  );

  const handleSignHSM = useCallback(
    async (
      base64: any,
      options?: { idAtendimento?: number; skipPIN?: boolean },
    ) => {
      const { idAtendimento, skipPIN } = options || {};

      try {
        if (!canSignHSM && !skipPIN) {
          await promptForPIN(base64);
        }

        const response = await AssinaturaHsmAPI.assinarDocumentoHSM(base64);

        if (idAtendimento && response?.data?.documents?.length) {
          await signDocument(
            idAtendimento,
            response.data.documents[0].signatures[0].value,
          );
          return;
        }

        return response;
      } catch (error) {
        console.error('Erro ao assinar doumento:', error);
        throw error;
      }
    },
    [canSignHSM, promptForPIN, signDocument],
  );

  const handleSignBlocoHSM = useCallback(
    async (listaBase64: any) => {
      try {
        if (!canSignHSM) {
          await promptForPIN(listaBase64);
        }

        for (const item of listaBase64) {
          await signDocumentHSM(item, true);
        }

        toast(<Toast />, {
          data: {
            type: 'success',
            message: 'Prontuários assinados com sucesso',
            title: 'Sucesso',
          },
        });

        if (!hsmValidDate) {
          setHsmPIN(null);
        }
      } catch (error) {
        console.error('Erro ao assinar documento:', error);
        throw new Error('Erro ao assinar prontuários');
      }
    },
    [canSignHSM, hsmValidDate, promptForPIN, signDocumentHSM],
  );

  const isHsm = useMemo(() => modeloAssinatura === 'HSM', [modeloAssinatura]);

  return (
    <AssinaturaContext.Provider
      value={{
        webSigner,
        setWebSigner,
        getPin,
        hsmValidDate,
        hsmPIN,
        setHsmPIN,
        modeloAssinatura,
        dialogAssinarDigitalmente,
        canSignHSM,
        handleSignHSM,
        isHsm,
        handleSignBlocoHSM,
      }}
    >
      {children}
      <Outlet />
    </AssinaturaContext.Provider>
  );
}
