import { useCallback, useEffect, useState } from 'react';

import { Button } from 'src/components/_UI/Button';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalVisualizarLaudo.scss';

export const ModalVisualizarLaudo = (props: any) => {
  const [pdf, setPdf] = useState('');

  const {
    isOpen,
    close,
    state: { laudoArquivo },
  } = props;

  const isPDF = laudoArquivo?.includes('.pdf');

  const fetchPDF = useCallback(async () => {
    const response = await fetch(laudoArquivo);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    return url;
  }, [laudoArquivo]);

  useEffect(() => {
    if (isPDF) {
      fetchPDF().then(url => setPdf(`${url}#toolbar=0`));
    }
  }, [fetchPDF, isPDF]);

  return (
    <Dialog
      id="modal-visualizar-laudo"
      header="Visualizar laudo"
      visible={isOpen}
      onHide={close}
      maximizedTabletSize
    >
      <div style={{ height: '75vh' }} className="p-grid">
        {isPDF ? (
          <embed src={pdf} width="100%" height="90%" />
        ) : (
          <div className="p-col-12">
            <img className="p-col-align-center" src={laudoArquivo} />
          </div>
        )}

        <div className="p-col-12">
          <Button
            type="button"
            btnType="ghost"
            label="Fechar"
            onClick={close}
          />
        </div>
      </div>
    </Dialog>
  );
};
