import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import SplitButton, {
  SPLIT_BTN_TYPE,
} from 'src/components/SplitButton/SplitButton';

import './DialogErrorTiss.scss';

type IProps = DisclosureType & {
  customMensage?: string | undefined;
  onRetry: () => void;
  onFinish: () => void;
  onCancel: () => void;
};

export const DialogErrorTiss = ({
  customMensage,
  close,
  onRetry,
  onFinish,
  onCancel,
}: IProps) => {
  return (
    <Dialog
      id="DialogErrorTiss"
      visible
      onHide={close}
      header="Falha na comunicação"
      closable={false}
    >
      <div className="p-d-flex p-flex-column p-gap-3">
        <SimpleText className="p-col-12 p-px-0 p-line-height-2">
          {customMensage ??
            `A comunicação com a operadora não obteve resposta do servidor. Você
          pode clicar em tentar novamente ou concluir em segundo plano.`}
        </SimpleText>

        <div className="p-d-flex p-gap-2">
          <Button
            label="Cancelar"
            btnType="ghost"
            onClick={() => [close(), onCancel()]}
            stretch
          />

          <SplitButton
            label="Tentar novamente"
            model={[
              {
                label: 'Concluir em segundo plano',
                command: () => [close(), onFinish()],
              },
            ]}
            onClick={() => [close(), onRetry()]}
            className="p-w-100"
            btnType={SPLIT_BTN_TYPE.PRIMARY}
          />
        </div>
      </div>
    </Dialog>
  );
};
