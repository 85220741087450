import { useContext } from 'react';

import { useLocation } from 'react-router';

import ThemeContext from 'src/core/themes/ThemeContext';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Tab, { TabItem } from 'src/components/Tab/Tab';

import { MonitoramentoTiss } from './MonitoramentoTiss/MonitoramentoTiss';
import { PendenciasTiss } from './PendenciasTiss/PendenciasTiss';

const TransacoesTiss = () => {
  const { state } = useLocation();
  const theme = useContext(ThemeContext);

  const tabs: TabItem[] = [
    {
      label: 'Monitoramento',
      content: <MonitoramentoTiss />,
      checkPermission: 'ADM_MONITORAMENTO_TISS',
    },
    {
      label: 'Pendencias TISS',
      content: <PendenciasTiss />,
      checkPermission: 'ADM_PENDENCIAS_TISS',
    },
  ];

  return (
    <Page className={`Monitoramento ${theme || ''}`}>
      <>
        <PageHeader
          preventBreadcrumbClick
          title="Transações TISS"
          subtitle={''}
        ></PageHeader>
        <Page className="page-content " content white>
          <Tab clean values={tabs} initialTabIndex={state?.tab || 0} />
        </Page>
      </>
    </Page>
  );
};

export default TransacoesTiss;
