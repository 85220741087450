import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/documento-consentimento';

const listTermoConsentimento = (
    idPaciente: number,
    options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<ITermoConsentimentoItem[]>> =>
    Http.get({
        service: 'adm',
        url: `${basePath}/paciente/${idPaciente}`,
        throwError: true,
        hideToast: true,
        ...options,
    });


const createTermoConsentimento = (
    data: ITermoConsentimentoPayload,
    options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<ITermoConsentimentoItem> =>
    Http.post({
        service: 'adm',
        url: basePath,
        data,
        throwError: true,
        ...options,
    });

const updateTermoConsentimento = (
    data: ITermoConsentimentoPayload,
    options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<ITermoConsentimentoItem> =>
    Http.put({
        service: 'adm',
        url: `${basePath}/${data.id}`,
        data,
        throwError: true,
        ...options,
    });

const revogarTermoConsentimento = (
    id: number,
    options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<void> =>
    Http.put({
        service: 'adm',
        url: `${basePath}/revogar/${id}`,
        throwError: true,
        ...options,
    });

const TermoConsentimentoPacienteAPI = {
    listTermoConsentimento,
    createTermoConsentimento,
    updateTermoConsentimento,
    revogarTermoConsentimento,
};

export default TermoConsentimentoPacienteAPI;

