import { MouseEvent } from 'react';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Switch from 'src/components/Switch/Switch';

type Props = {
  onAction(e: MouseEvent<HTMLDivElement>): void;
  icon: string;
  label: string;
  disabled?: boolean;
  badge?: string;
  isChecked?: boolean;
  description?: string;
  status?: boolean;
};

export const NavBarItemMenu = ({
  onAction,
  icon,
  label,
  disabled,
  badge = '',
  status = false,
  isChecked = false,
  description,
}: Props) => {
  return (
    <div
      className="p-d-flex p-gap-2 p-ai-center container-btn-menu p-py-1 relative"
      onClick={onAction}
    >
      <Button
        id="btn-exit"
        btnType="gray"
        icon={icon}
        disabled={!!disabled}
        style={{
          minWidth: '2rem',
          position: 'static',
        }}
        {...(badge && {
          badge,
          badgeClassName: 'badge-nav-notification',
          badgePos: 'right',
          badgeVariant: 'warn',
        })}
      />
      <div className="p-d-flex p-flex-column p-gap-1">
        <SimpleText medium>{label}</SimpleText>
        {!!description && (
          <SimpleText fontColor="color_60" fontSize="xxs">
            {description}
          </SimpleText>
        )}
      </div>

      {status && <Switch className="p-ml-4" checked={isChecked} />}
    </div>
  );
};
