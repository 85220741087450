import { useMemo } from 'react';

import { Background, Controls, Panel, ReactFlow } from '@xyflow/react';

import { NodeConnection, NodePaciente } from './_components/nodes';
import { PanelLegend } from './_components/panel/panel';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import { GenogramaProvider, useGenograma } from './_hooks/useGenograma';
import { useInitialEdges } from './_hooks/useInitialEdges';
import { useInitialNodes } from './_hooks/useInitialNodes';

import '@xyflow/react/dist/style.css';
import './Genograma.scss';

const GenogramaContent = ({ opened }: { opened: boolean }) => {
  const { panelLegend, isLoading } = useGenograma();

  const initialNodes = useInitialNodes();
  const initialEdges = useInitialEdges(initialNodes);

  const nodeTypes = useMemo(
    () => ({ customNode: NodePaciente, gestation: NodeConnection }),
    [],
  );

  return (
    <div id="genograma-container" data-opened={opened}>
      {!isLoading && (
        <ReactFlow
          nodes={initialNodes}
          nodeTypes={nodeTypes}
          edges={initialEdges}
          className="br-8px"
          defaultViewport={{
            zoom: 0.6,
            x: 150,
            y: -100,
          }}
        >
          <Controls />
          <Panel
            position="bottom-right"
            style={{
              width: panelLegend.isOpen ? '85%' : '135px',
              transition: 'all 0.3s cubic-bezier(0.12, 1.01, 0.89, 0.93)',
            }}
          >
            <PanelLegend />
          </Panel>
          <Background bgColor="#fff" />
        </ReactFlow>
      )}

      {isLoading && (
        <div className="loading-genograma">
          <SpinnerLoading size="md"></SpinnerLoading>
          <p>Atualizando genograma familiar...</p>
        </div>
      )}
    </div>
  );
};

export const Genograma = ({
  idPaciente,
  ...props
}: {
  idPaciente: number;
  opened: boolean;
}) => {
  return (
    <GenogramaProvider idPaciente={idPaciente}>
      <GenogramaContent {...props} />
    </GenogramaProvider>
  );
};
