import { useCallback } from 'react';

import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';

import { generateUniqueId } from 'src/utils/utils';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import SplitButton from 'src/components/SplitButton/SplitButton';

import ChipAgendamento from 'src/pages/Emed/Agenda/MenuAgenda/ChipAgendamento/ChipAgendamento';

import { useAgenda } from '../../../AgendaContext';
import ModalCardPaciente from '../../ModalCardPaciente/ModalCardPaciente';
import Status from '../../Status/Status';

import './CardAgendamentoSemana.scss';
import Tooltip from 'src/components/Tooltip/Tooltip';
interface CardAgendamentoSemanaProps {
  agendamento: AtendimentoAgendamento;
  selectedDate: Date;
  sigilo: JSX.Element;
  renderActions?: any;
}
const CardAgendamentoSemana = ({
  agendamento,
  selectedDate,
  sigilo,
  renderActions,
}: CardAgendamentoSemanaProps) => {
  const { dialogEditarPaciente, dialogDetalhesAgendamento } = useAgenda();

  const uid = generateUniqueId();

  const isAgendamentoGrupo = useCallback((): boolean => {
    if (agendamento.idGrupoAtendimento && !agendamento.idAtendimentoEmGrupo)
      return true;
    return false;
  }, [agendamento.idAtendimentoEmGrupo, agendamento.idGrupoAtendimento]);

  const renderChips = useCallback(() => {
    return (
      <div className="chipssemana">
        {agendamento?.flagsAtivas?.map(item => (
          <ChipAgendamento key={item} type={item} />
        ))}
      </div>
    );
  }, [agendamento]);

  const renderChipsInfo = useCallback(() => {
    return (
      <>
        <div className="upper-middle">{renderChips()}</div>

        <div className="bottom-middle">
          {isAgendamentoGrupo() && (
            <>
              <SimpleText
                bold
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.PRIMARY}
              >
                {agendamento.apelidoGrupo}
              </SimpleText>

              <SimpleText
                className="p-mt-2"
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                • {agendamento.quantidadePaciente} pacientes
              </SimpleText>
            </>
          )}

          {agendamento?.paciente?.nome ? (
            <SplitButton
              label={agendamento?.paciente?.nome || ''}
              className="modal-card-paciente"
              model={[
                {
                  template: (
                    <ModalCardPaciente
                      paciente={agendamento?.paciente}
                      convenio={agendamento?.convenio}
                      setExibirModal={() =>
                        dialogEditarPaciente.open({
                          state: agendamento?.paciente,
                        })
                      }
                    />
                  ),
                },
              ]}
              menuClassName="menu-card-paciente"
            />
          ) : (
            <div className="bottom-middle">
              <SimpleText fontColor={FONT_COLOR.PRIMARY} medium>
                {agendamento?.agendamentoRapidoNome}
              </SimpleText>
            </div>
          )}

          <SimpleText
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_40}
            style={{ alignSelf: 'flex-start' }}
          >
            {agendamento?.convenio?.nomeFantasia ||
              agendamento?.convenio?.razaoSocial}
          </SimpleText>

          {!!agendamento?.observacao?.trim() && (
            <div className="p-d-flex observacao p-p-1">
              <SimpleText
                className="p-mr-2"
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_79}
              >
                Observação:
              </SimpleText>
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
                className={`observacao-agendamento-${uid} cursor-pointer`}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <Tooltip
                  target={`.observacao-agendamento-${uid}`}
                  className="p-w-100"
                  autoHide={false}
                  position="top"
                >
                  {agendamento.observacao}
                </Tooltip>
                {agendamento.observacao}
              </SimpleText>
            </div>
          )}
        </div>
      </>
    );
  }, [
    renderChips,
    isAgendamentoGrupo,
    agendamento.apelidoGrupo,
    agendamento.quantidadePaciente,
    agendamento?.paciente,
    agendamento?.convenio?.nomeFantasia,
    agendamento?.convenio?.razaoSocial,
    agendamento.observacao,
    agendamento?.agendamentoRapidoNome,
    uid,
    dialogEditarPaciente,
  ]);

  return (
    <div
      className={`CardAgendamentoSemana animationFadeRight ${
        agendamento?.status === 'BLOQUEADO' ? 'card-bloqueado' : ''
      }`}
    >
      <div className="top">
        <SimpleText fontSize={FONT_SIZE.SM} fontColor={FONT_COLOR.COLOR_40}>
          {agendamento?.dataAgendamento
            ? dayjs(agendamento?.dataAgendamento).format('HH:mm')
            : ''}
        </SimpleText>

        {renderActions}
      </div>

      <div className="middle p-flex-1">
        <Status agendamento={agendamento} selectedDate={selectedDate} />

        {renderChipsInfo()}

        {agendamento?.status === 'PRESENTE' && (
          <div className="sigilo">{sigilo}</div>
        )}
      </div>

      <div className="bottom">
        {agendamento?.status !== 'BLOQUEADO' &&
          agendamento?.status !== 'LIVRE' && (
            <div
              className="bottom"
              onClick={() =>
                dialogDetalhesAgendamento.open({ state: agendamento })
              }
            >
              <SimpleText
                fontSize={FONT_SIZE.XS}
                fontColor={FONT_COLOR.COLOR_16}
                bold
              >
                Detalhes
              </SimpleText>
              <i className="fas fa-chevron-right" style={{ marginLeft: 15 }} />
            </div>
          )}
      </div>
    </div>
  );
};

export default CardAgendamentoSemana;
