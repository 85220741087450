import React, { useState } from 'react';

import { PendenciasTissAPI } from 'src/APIs/ProntuarioAPI/PendenciasTissAPI/PendenciasTissAPI';

import useSize from 'src/core/hooks/useSize';

import {
  MonitoramentoRowMobile,
  MonitoramentoTissRow,
} from './MonitoramentoTissRow';

interface RowProps {
  item: any;
}

const Row = ({ item }: RowProps) => {
  const { isTablet } = useSize();
  const [loadingEnvio, setLoadingEnvio] = useState(false);

  const onReenviar = async (id: number | string) => {
    try {
      setLoadingEnvio(true);
      await PendenciasTissAPI.reenviarLoteGuia(id);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEnvio(false);
    }
  };
  return (
    <>
      {isTablet ? (
        <MonitoramentoRowMobile
          item={item}
          loadingEnvio={loadingEnvio}
          onReenviar={onReenviar}
        />
      ) : (
        <MonitoramentoTissRow
          item={item}
          loadingEnvio={loadingEnvio}
          onReenviar={onReenviar}
        />
      )}
    </>
  );
};

export default Row;
