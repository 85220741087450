import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { IFamiliarPaciente } from 'src/APIs/ProntuarioAPI/FamiliarAPI/Familiar';
import { FamiliarAPI } from 'src/APIs/ProntuarioAPI/FamiliarAPI/FamiliarAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import {
  resetInvalidateQuery,
  setInvalidateQuery,
} from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import DialogFamiliar from './components/DialogFamiliar/DialogFamiliar';
import RelacionamentoDialog from './components/RelacionamentoDialog';
import SearchPaciente from './components/SearchPaciente';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Separator from 'src/components/Separator/Separator';

import Row from './Row/Row';

import './ManterFamiliar.scss';

interface ManterFamiliarProps {
  manterFamiliarDialog: DisclosureType;
  idPaciente: number | null;
  opened: boolean;
}

const ManterFamiliar = ({
  manterFamiliarDialog,
  idPaciente,
  opened,
}: ManterFamiliarProps) => {
  const [showAlert, setShowAlert] = useState(true);
  const [reload, setReload] = useState(0);

  const { consultorios } = useAppSelector((state: RootState) => state);
  const { invalidateManterFamiliar } = useAppSelector(state => state.query);

  const dispatch = useDispatch();
  const relacionamentoDialog = useDisclosure({ opened: false });

  const reloadList = () => {
    setReload(prev => prev + 1);
  };

  const handleRefetchGenograma = () => {
    dispatch(
      setInvalidateQuery({
        invalidateGenograma: true,
      }),
    );
  };

  const consultorioAtivo = consultorios?.ativo?.id;

  const fetchApi = async () => {
    if (!idPaciente) return [];
    try {
      const response = await FamiliarAPI.loadPacienteFamiliares(
        Number(idPaciente),
        { sortBy: 'nome', sortDirection: 'ASC' },
        {
          throwError: true,
        },
      );
      return response;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  const onEdit = (familiar: IFamiliarPaciente) => {
    manterFamiliarDialog.open({ state: familiar });
  };

  const handleRelacionamentos = (familiar: IFamiliarPaciente) => {
    relacionamentoDialog.open({ state: familiar });
  };

  useEffect(() => {
    if (invalidateManterFamiliar) {
      reloadList();
      dispatch(resetInvalidateQuery());
    }
  }, [dispatch, invalidateManterFamiliar]);

  const renderRow = (familiar: IFamiliarPaciente) => {
    return (
      <Row
        familiar={familiar}
        onEdit={onEdit}
        handleRelacionamentos={handleRelacionamentos}
        reloadList={() => [reloadList(), handleRefetchGenograma()]}
      />
    );
  };

  return (
    <div id="manter-familiar-container" data-opened={opened}>
      <SearchList.Root fetchApi={fetchApi}>
        <SearchPaciente
          idPaciente={idPaciente}
          label="Pesquisar pacientes"
          idConsultorio={consultorioAtivo}
          paramsBusca={{ obito: false, status: true }}
          manterFamiliarDialog={manterFamiliarDialog}
        />

        <Separator spaceOnly />
        <div className="p-grid">
          <SimpleText medium className="p-col-12 p-px-0">
            Lista de familiares
          </SimpleText>
          <SearchList.NonPageableScrollArea
            reload={reload}
            renderRow={renderRow}
            emptyResultLayout={() => (
              <div
                className="p-w-100 p-p-5 bg-96  p-d-flex p-flex-column p-jc-center p-ai-center p-gap-3 "
                style={{ height: '300px', borderRadius: '5px' }}
              >
                <SimpleText className="p-text-center" fontColor="color_60">
                  Os familiares adicionados serão exibidos aqui.
                  <br />
                  Para incluir um novo familiar, basta pesquisar o nome do
                  paciente e clicar no registro correspondente.
                  <br />
                  Você também pode adicionar um paciente externo ao sistema
                  utilizando o botão <b>“Novo familiar”</b>.
                </SimpleText>
              </div>
            )}
          />

          <AlertBox
            text="Edite o familiar selecionando um grau de parentesco e demais informações."
            visible={showAlert}
            type="WARN"
            onHide={() => setShowAlert(false)}
          />

          {relacionamentoDialog.isOpen && (
            <RelacionamentoDialog {...relacionamentoDialog} />
          )}
          {manterFamiliarDialog.isOpen && (
            <DialogFamiliar {...manterFamiliarDialog} idPaciente={idPaciente} />
          )}
        </div>
      </SearchList.Root>
    </div>
  );
};

export default ManterFamiliar;
