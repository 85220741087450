import { useNavigate } from 'react-router';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

export const Card = ({
  quantidade,
  route,
  state,
  title,
  label = 'Acessar',
  isLoading = false,
}: {
  quantidade: number | null;
  route?: string;
  state?: any;
  title: string;
  label?: string;
  isLoading?: boolean;
}) => {
  const navigate = useNavigate();

  const style = {
    width: '160px',
    minWidth: '160px',
    minHeight: '220px',
  };

  if (quantidade === null && !isLoading) {
    return null;
  }

  return (
    <div
      className="card-agendamento p-d-flex p-flex-column p-gap-3 p-p-3 br-8px bg-96"
      style={style}
    >
      {isLoading ? (
        <Skeleton loading height="30px" width="50%" />
      ) : (
        <SimpleText fontSize="xl" fontColor="color_40">
          {quantidade || 0}
        </SimpleText>
      )}
      {isLoading ? (
        <Skeleton loading height="26px" width="100%" />
      ) : (
        <SimpleText style={{ lineHeight: '26px' }} fontSize="sm" bold>
          {title}
        </SimpleText>
      )}

      {route && !isLoading && (
        <Button
          label={label}
          btnType="tonal"
          stretch
          className="p-mt-auto"
          onClick={() =>
            navigate(route, {
              state,
            })
          }
        />
      )}
    </div>
  );
};
