import { useCallback, useEffect, useMemo, useState } from 'react';

import CidAPI from 'src/APIs/ProntuarioAPI/CidAPI/CidAPI';
import ComorbidadeAPI from 'src/APIs/ProntuarioAPI/ComorbidadeAPI/ComorbidadeAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import { Checkbox } from 'src/components/_UI/Checkbox';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import Dialog from 'src/components/Dialog/Dialog';
import './ModalBuscaAvancada.scss';
import SplitButton, {
  SPLIT_BTN_TYPE,
} from 'src/components/SplitButton/SplitButton';

import { MenuItem } from 'primereact/menuitem';

import { capitalizeFirstLetter } from 'src/utils/utils';

import useSize from 'src/core/hooks/useSize';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

interface ModalBuscaAvancadaProps extends DisclosureType {
  listaComorbidades: any[];
  getCids: (idAtendimento: any) => void;
  idAtendimento: any;
  getListaProblemas: () => void;
  adicionarComorbidade: (cid: any) => void;
  removerComorbidade: (cid: any, idComorbidade: any) => void;
}

const diagnosticos: any = {
  SUSPEITO: 'Suspeito',
  CONFIRMADO: 'Confirmado',
};

const labelTypeDiagnosticos: Record<string, SPLIT_BTN_TYPE> = {
  SUSPEITO: SPLIT_BTN_TYPE.YELLOW,
  CONFIRMADO: SPLIT_BTN_TYPE.SUCCESS_LIGHT,
};

const nivelDiagnosticos: any = {
  PRINCIPAL: 'Principal',
  SECUNDARIO: 'Secundário',
};

const labelTypeNivelDiagnosticos: Record<string, SPLIT_BTN_TYPE> = {
  PRINCIPAL: SPLIT_BTN_TYPE.YELLOW,
  SECUNDARIO: SPLIT_BTN_TYPE.SUCCESS_LIGHT,
};

const statusCid: any = {
  true: 'Ativo',
  false: 'Inativo',
};

const labelTypeStatusCid: Record<string, SPLIT_BTN_TYPE> = {
  true: SPLIT_BTN_TYPE.GREEN,
  false: SPLIT_BTN_TYPE.YELLOW,
};

function ModalBuscaAvancada({
  close,
  isOpen,
  listaComorbidades,
  getCids,
  getListaProblemas,
  idAtendimento,
  adicionarComorbidade,
  removerComorbidade,
}: ModalBuscaAvancadaProps) {
  const { agenda } = useAppSelector((state: RootState) => state);
  const { modalAgravo } = useAtendimento();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [cids, setCids] = useState<any>();
  const [gruposCids, setGruposCids] = useState<any>();
  const [favoritos, setFavoritos] = useState<any[]>([]);
  const [cidsSelecionadas, setCidsSelecionadas] = useState<any[]>([]);
  const [versoesCids, setVersoesCids] = useState<any[]>([]);
  const [versaoCidSelecionada, setVersaoCidSelecionada] =
    useState<any>('CID10');
  const [selectedCids, setSelectedCids] = useState<any[]>([]);

  const closeModal = () => {
    setSearchQuery('');
    setCidsSelecionadas([]);
    setCids(undefined);
    setGruposCids(undefined);
    setFavoritos([]);
    close();
  };

  const loadFavoritos = useCallback(async () => {
    const response = await ComorbidadeAPI.getFavoritos(
      agenda?.profissionalAtivo?.id,
    );

    if (Array.isArray(response) && !!response.length) setFavoritos(response);
  }, [agenda?.profissionalAtivo?.id]);

  useEffect(() => {
    const getVersoesCid = async () => {
      const response = await CidAPI.getVersoesCids();
      setVersoesCids(response.data);
    };
    loadFavoritos();
    getVersoesCid();
    getListaProblemas();
  }, [getListaProblemas, idAtendimento, loadFavoritos]);

  useEffect(() => {
    cidsSelecionadas.forEach((cid: any) => {
      if (
        listaComorbidades?.some(
          (comorbidade: any) =>
            comorbidade.cid[0]?.idCid === cid.id &&
            !selectedCids?.some(cid => cid.id === comorbidade.cid[0]?.idCid),
        )
      ) {
        setSelectedCids([...selectedCids, cid]);
      }
    });
  }, [cidsSelecionadas, listaComorbidades, selectedCids]);

  function getUniqueListBy(arr: any, key: string) {
    return [...new Map(arr?.map((item: any) => [item[key], item])).values()];
  }

  const pesquisarCids = useCallback(
    async (params: any) => {
      getListaProblemas();
      const response = await CidAPI.buscaCids({
        chave: params,
        versao: versaoCidSelecionada,
        ...params,
      });
      setCids(response.data.list);
      setGruposCids(getUniqueListBy(response.data.list, 'codigoGrupo'));
    },
    [getListaProblemas, versaoCidSelecionada],
  );

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const filtrarCids = useCallback(
    (grupo: any) => {
      setSearchQuery(grupo);
      pesquisarCids(grupo);
    },
    [pesquisarCids],
  );

  const resultadoGrupos = useMemo(() => {
    return (
      <div className="p-d-flex p-flex-column p-col-12 p-md-6">
        <SimpleText
          className="p-mb-2"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          Grupo
        </SimpleText>
        <div className="grupo p-p-2">
          {gruposCids?.map((grupo: any, idx: number) => (
            <div
              key={idx}
              className="p-d-flex p-py-2 p-my-1 grupo-item"
              onClick={() => {
                filtrarCids(grupo.codigoGrupo);
              }}
            >
              <h4 className="p-mr-3 codigo-grupo">{grupo.codigoGrupo}</h4>
              <h4 className="nome-grupo">
                {capitalizeFirstLetter(grupo.nome.toLowerCase())}
              </h4>
            </div>
          ))}
        </div>
      </div>
    );
  }, [filtrarCids, gruposCids]);

  const cidsFavoritos = useMemo(
    () =>
      favoritos
        .filter(favorito => !!favorito?.idCid)
        .map(favorito => favorito.cid.nome),
    [favoritos],
  );

  const resultadoCids = useMemo(() => {
    const onSelectValue = (cid: any) => {
      if (!cidsSelecionadas.some(c => c.id === cid.id)) {
        cid.estadoDiagnosticoCid = 'SUSPEITO';
        cid.nivelDiagnosticoCid = 'PRINCIPAL';
        setCidsSelecionadas([...cidsSelecionadas, cid]);
      }
    };

    const handleFavoritar = async (cid: any) => {
      const response = await ComorbidadeAPI.favoritarComorbidade(
        agenda?.profissionalAtivo?.id,
        { idCid: cid.id },
      );

      if (response?.status === 200) loadFavoritos();
    };

    return (
      <div className="p-d-flex p-flex-column p-col-12 p-md-6">
        <SimpleText
          className="p-mb-1"
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          Resultado para: {searchQuery}
        </SimpleText>
        <div className="lista-cid">
          {cids?.map((cid: any, idx: number) => (
            <div key={idx} className="cid-item p-grid p-col-12 p-my-1">
              <div
                className="p-col-11 p-grid"
                onClick={() => onSelectValue(cid)}
              >
                <SimpleText
                  className="p-col-12"
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_40}
                  medium
                >
                  {cid.codigo} - {cid.codigoGrupo} -{' '}
                  {capitalizeFirstLetter(cid.nome.toLowerCase())}
                </SimpleText>
              </div>
              <div
                className="p-col-1 p-d-flex p-jc-center p-ai-center"
                onClick={() => handleFavoritar(cid)}
              >
                <i
                  className={`fas fa-star favoritar-button ${
                    cidsFavoritos.includes(cid.nome) ? 'favoritado' : ''
                  }`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }, [
    agenda?.profissionalAtivo?.id,
    cids,
    cidsFavoritos,
    cidsSelecionadas,
    loadFavoritos,
    searchQuery,
  ]);

  const addCids = async (idAtendimento: any) => {
    const payload = cidsSelecionadas.map(cid => ({
      idCid: cid.id,
      estadoDiagnosticoCid: cid.estadoDiagnosticoCid,
      nivelDiagnosticoCid: cid.nivelDiagnosticoCid,
      ativo: cid.ativo,
    }));

    const response = await UtilsAPI.postAddCid(idAtendimento, payload);
    if (response?.data?.[0]?.existeAgravo) {
      modalAgravo.open();
    }
    if (response?.status === 201) {
      cidsSelecionadas.forEach((cid: any) => {
        if (
          selectedCids.some((item: any) => item.id === cid.id) &&
          !listaComorbidades.some(
            (comorbidade: any) => comorbidade.cid[0]?.idCid === cid.id,
          )
        ) {
          adicionarComorbidade(cid);
        } else if (
          listaComorbidades?.some(
            (comorbidade: any) => comorbidade.cid[0]?.idCid === cid.id,
          ) &&
          !selectedCids.some((item: any) => item.id === cid.id)
        ) {
          removerComorbidade(
            cid,
            listaComorbidades?.filter(
              comorbidade => comorbidade.cid[0]?.idCid === cid.id,
            )[0].id,
          );
        }
      });
      getCids(String(idAtendimento));
      closeModal();
    }
  };

  return (
    <Dialog
      header="Busca avançada"
      visible={isOpen}
      onHide={closeModal}
      maximizedMobileSize
    >
      <div id="busca-avancada" className="p-grid">
        <div className="busca p-grid p-col-12">
          <div className="p-col-7">
            <TextInputSearch
              name="pesquisa"
              placeholder="Pesquisar por descrição ou códico"
              value={searchQuery}
              onChange={setSearchQuery}
              onKeyPress={e => {
                if (e.key === 'Enter') pesquisarCids(searchQuery);
              }}
            />
          </div>
          <div className="p-col-3">
            <Dropdown
              options={versoesCids}
              value={versaoCidSelecionada}
              onChange={e => setVersaoCidSelecionada(e.value)}
            />
          </div>
          <div className="p-col-2">
            <Button
              label="Pesquisar"
              className="pesquisar"
              onClick={() => {
                pesquisarCids(searchQuery);
              }}
              disabled={!searchQuery}
              stretch
            />
          </div>
        </div>
        {gruposCids && (
          <div className="p-col-12 p-grid ">
            {resultadoGrupos}
            {resultadoCids}
          </div>
        )}
        {!gruposCids && (
          <div className="p-col-12 p-d-flex p-ai-center p-jc-center p-mt-4">
            <p>Faça a busca utilizando os campos acima.</p>
          </div>
        )}
        <div className="p-col-12 lista-adicionados">
          {listaCids(
            cidsSelecionadas,
            setCidsSelecionadas,
            selectedCids,
            setSelectedCids,
            listaComorbidades,
            idAtendimento,
          )}
        </div>
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            className="p-as-center"
            label="Cancelar"
            btnType="ghost"
            onClick={() => closeModal()}
            stretch
          />
          <Button
            label="Salvar"
            onClick={() => addCids(idAtendimento)}
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
}

export default ModalBuscaAvancada;

const listaCids = (
  cidsSelecionadas: any,
  setCidsSelecionadas: any,
  selectedCids: any,
  setSelectedCids: any,
  listaComorbidades: any,
  idAtendimento: any,
) => {
  const onRemoveCid = (cid: any) => {
    setCidsSelecionadas(cidsSelecionadas.filter((c: any) => c.id !== cid.id));
  };

  const onCidChange = (e: { value: any; checked: boolean }) => {
    const _selectedCids = [...selectedCids];

    if (e.checked) {
      _selectedCids.push(e.value);
    } else {
      for (let i = 0; i < _selectedCids.length; i++) {
        const selectedCid = _selectedCids[i];

        if (selectedCid.id === e.value.id) {
          _selectedCids.splice(i, 1);
          break;
        }
      }
    }

    setSelectedCids(_selectedCids);
  };

  return (
    <>
      {cidsSelecionadas?.map((cid: any) => {
        if (!cid) return;
        if (!('ativo' in cid)) {
          cid.ativo = true;
        }
        const estadoDiagnosticoCid = cid.estadoDiagnosticoCid || 'SUSPEITO';
        const nivelDiagnosticoCid = cid.nivelDiagnosticoCid || 'PRINCIPAL';
        const ativo = cid.ativo;

        const splitBtnType = labelTypeStatusCid[
          cid.ativo ? 'true' : 'false'
        ] as SPLIT_BTN_TYPE;

        const splitBtnTypeDiagnosticos = labelTypeDiagnosticos[
          estadoDiagnosticoCid
        ] as SPLIT_BTN_TYPE;

        const splitBtnTypeNivelDiagnosticos = labelTypeNivelDiagnosticos[
          cid.nivelDiagnosticoCid || 'PRINCIPAL'
        ] as SPLIT_BTN_TYPE;

        const splitButtonItems = () => {
          const items: MenuItem[] = [];
          const ativo = cid.ativo || true;

          for (const status in statusCid) {
            if (status != ativo) {
              items.push({
                label: statusCid[status],
                command: () => {
                  const updatedCids = cidsSelecionadas.map((item: any) => {
                    if (item.id === cid.id) {
                      return { ...item, ativo: status == 'true' };
                    }
                    return item;
                  });
                  setCidsSelecionadas(updatedCids);
                },
              });
            }
          }

          return items;
        };

        const splitButtonItemsDiagnosticos = () => {
          const items: MenuItem[] = [];
          const estadoDiagnosticoCid = '';

          for (const diagnostico in diagnosticos) {
            if (diagnostico !== estadoDiagnosticoCid) {
              items.push({
                label: diagnosticos[diagnostico],
                command: () => {
                  const updatedCids = cidsSelecionadas.map((item: any) => {
                    if (item.id === cid.id) {
                      return { ...item, estadoDiagnosticoCid: diagnostico };
                    }
                    return item;
                  });

                  setCidsSelecionadas(updatedCids);
                },
              });
            }
          }

          return items;
        };

        const splitButtonItemsNivelDiagnosticos = () => {
          const items: MenuItem[] = [];
          const nivelDiagnosticoCid = '';

          for (const nivel in nivelDiagnosticos) {
            if (nivel !== nivelDiagnosticoCid) {
              items.push({
                label: nivelDiagnosticos[nivel],
                command: () => {
                  const updatedCids = cidsSelecionadas.map((item: any) => {
                    if (item.id === cid.id) {
                      return { ...item, nivelDiagnosticoCid: nivel };
                    }
                    return item;
                  });

                  setCidsSelecionadas(updatedCids);
                },
              });
            }
          }

          return items;
        };

        return (
          <div key={cid.id} className="p-col-12 cid-item p-my-2">
            <div className="p-d-flex p-flex-column w-100 p-gap-2">
              <div className="p-d-flex p-flex-column">
                <SimpleText
                  fontSize={FONT_SIZE.XS}
                  fontColor={FONT_COLOR.COLOR_16}
                  medium
                >
                  ({cid.codigo}) {capitalizeFirstLetter(cid.nome.toLowerCase())}
                </SimpleText>
              </div>

              <div className="p-grid p-jc-between white-bg">
                <Checkbox
                  className="p-col-12 p-lg-4"
                  name={cid.id}
                  label="Adicionar na lista de problemas"
                  value={cid}
                  onChange={onCidChange}
                  checked={selectedCids.some((item: any) => item.id === cid.id)}
                  disabled={listaComorbidades?.some(
                    (comorbidade: any) =>
                      comorbidade.cid[0]?.idCid === cid.id &&
                      comorbidade.idAtendimento !== Number(idAtendimento),
                  )}
                />
                <div className="p-d-flex p-gap-3 p-col-12 p-lg-8 p-jc-end">
                  <div className="p-d-flex p-gap-1">
                    <SplitButton
                      menuClassName="menu-comorbidade-splitbutton"
                      label={
                        diagnosticos[estadoDiagnosticoCid] || 'Diagnóstico'
                      }
                      btnType={splitBtnTypeDiagnosticos}
                      model={splitButtonItemsDiagnosticos()}
                    />

                    <SplitButton
                      menuClassName="menu-comorbidade-splitbutton"
                      btnType={splitBtnTypeNivelDiagnosticos}
                      label={nivelDiagnosticos[nivelDiagnosticoCid] || 'Nível'}
                      model={splitButtonItemsNivelDiagnosticos()}
                    />

                    <SplitButton
                      menuClassName="menu-comorbidade-splitbutton"
                      btnType={splitBtnType}
                      label={statusCid[String(ativo)] || 'Status'}
                      model={splitButtonItems()}
                    />
                  </div>

                  <Button
                    icon="fas fa-trash"
                    btnType="gray"
                    onClick={() => {
                      onRemoveCid(cid);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
