import PanelContent from './components/PanelContent';
import PanelHeader from './components/PanelHeader';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import './DesktopRow.scss';

interface DesktopRowProps {
  item: IAgravoNotificacao;
  selectedAgravo: IAgravoNotificacao[];
  setSelectedAgravo: (selectedAgravo: any) => void;
  onChangeStatus: () => void;
  loadingStatus: boolean;
}

const DesktopRow = ({
  item,
  selectedAgravo,
  setSelectedAgravo,
  onChangeStatus,
  loadingStatus,
}: DesktopRowProps) => {
  return (
    <div className="p-my-1 panel-monitoramento-agravo">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            item={item}
            selectedAgravo={selectedAgravo}
            setSelectedAgravo={setSelectedAgravo}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            onChangeStatus={onChangeStatus}
            loadingStatus={loadingStatus}
          />
        )}
      >
        <PanelContent item={item} />
      </Panel>
    </div>
  );
};

export default DesktopRow;
