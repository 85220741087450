import React, { useState } from 'react';

import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Switch from 'src/components/Switch/Switch';

interface DadosAcessoProps {
  usuario: GetUsuarioDTO | undefined;
  reloadList: () => void;
}

const DadosAcesso = ({ usuario, reloadList }: DadosAcessoProps) => {
  const [bloqueado, setBloqueado] = useState<boolean>(
    usuario?.bloqueado || false,
  );
  const [loadingBloqueio, setLoadingBloqueio] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(usuario?.status || false);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [alterarSenha, setAlterarSenha] = useState<boolean>(false);
  const [loadingAlterarSenha, setLoadingAlterarSenha] =
    useState<boolean>(false);

  const handleBlock = async () => {
    const route = usuario?.bloqueado ? 'desbloquear' : 'bloquear';
    try {
      setLoadingBloqueio(true);
      await UsuarioAPI.handleBlockChange(usuario?.id, route, {
        throwError: true,
      });
      setBloqueado(prev => !prev);
      reloadList();
    } catch (error) {
      console.error('Erro ao bloquear/desbloquear usuario', error);
    } finally {
      setLoadingBloqueio(false);
    }
  };

  const handleChangeStatus = async () => {
    const route = usuario?.status === true ? 'inativar' : 'ativar';
    try {
      setLoadingStatus(true);
      await UsuarioAPI.handleStatusChange(usuario?.id, route, {
        throwError: true,
      });
      setStatus(prev => !prev);
      reloadList();
    } catch (error) {
      console.error('Erro ao alterar status do usuario', error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleAlterarSenha = async () => {
    try {
      setLoadingAlterarSenha(true);
      //   await UsuarioAPI.handleAlterarSenha(usuario?.id, {
      //     throwError: true,
      //   });
      setAlterarSenha(prev => !prev);
      reloadList();
    } catch (error) {
      console.error('Erro ao alterar senha do usuario', error);
    } finally {
      setLoadingAlterarSenha(false);
    }
  };

  return (
    <div className="p-w-100">
      <SimpleText medium fontColor="color_40">
        Dados de acesso
      </SimpleText>
      <div className="menu-card p-w-100 p-p-2 p-gap-3 p-mt-2">
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText fontColor="color_60">
            Login: <strong className="color_black">{usuario?.login}</strong>
          </SimpleText>
          <div className="p-d-flex p-ai-center p-gap-4">
            <SimpleText fontColor="color_60">Status</SimpleText>
            <Switch
              checked={!bloqueado}
              onChange={() => handleBlock()}
              showStatus
              checkPermission="ADM_USUARIO_ALTERAR"
              uncheckedLabel="Bloqueado"
              checkedLabel="Ativo"
              loading={loadingBloqueio}
            />
          </div>
        </div>
        <div className="menu-card p-px-2 p-py-3 p-d-flex p-ai-center p-jc-between p-mt-2">
          <div className="p-d-flex p-flex-column p-gap-3">
            <SimpleText medium>Usuário</SimpleText>
            <SimpleText fontColor="color_60">
              Caso seja desativado, o usuário não poderá mais acessar o eMed.
            </SimpleText>
          </div>
          <Switch
            checked={status}
            onChange={() => handleChangeStatus()}
            showStatus
            checkPermission="ADM_USUARIO_ALTERAR"
            uncheckedLabel="Desativado"
            checkedLabel="Ativado"
            loading={loadingStatus}
          />
        </div>

        {/* <div className="menu-card p-p-2 p-d-flex p-ai-center p-jc-between p-mt-4">
          <div className="p-d-flex p-flex-column p-gap-3">
            <SimpleText medium>Alterar senha</SimpleText>
            <SimpleText fontColor="color_60">
              Solicitar alteração de senha no próximo login do usuário.
            </SimpleText>
          </div>
          <Switch
            checked={alterarSenha}
            onChange={() => handleAlterarSenha()}
            showStatus
            checkPermission="ADM_USUARIO_ALTERAR"
            uncheckedLabel="Desativado"
            checkedLabel="Ativado"
            loading={loadingAlterarSenha}
          />
        </div> */}
      </div>
    </div>
  );
};

export default DadosAcesso;
