import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

interface PanelContentProps {
  item: IContaReceberItem;
}

const PanelContent = ({ item }: PanelContentProps) => {
  return (
    <div className="p-grid p-mt-1">
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Descrição
        </SimpleText>
        <SimpleText fontSize="xs" fontColor="color_16" className="p-js-center">
          {item.descricao}
        </SimpleText>
      </div>
    </div>
  );
};

export default PanelContent;
