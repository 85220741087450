import { Skeleton } from 'primereact/skeleton';
import { useFormContext } from 'react-hook-form';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

interface Props {
  name: string;
  label?: string;
  className?: string;
  loading: boolean;
  options: any[];
  isMedico: boolean;
  multiple?: boolean;
}

const FieldProfissionais = (props: Props) => {
  const {
    label,
    className,
    loading,
    options,
    isMedico,
    name,
    multiple = true,
  } = props;

  const { control } = useFormContext();

  if (loading) {
    return (
      <div className={className}>
        <SimpleText fontColor={FONT_COLOR.COLOR_16} medium>
          {label}
        </SimpleText>
        <Skeleton className="p-mt-2" height="33px" borderRadius="8px" />
      </div>
    );
  }

  if (!multiple)
    return (
      <DropdownControlled
        control={control}
        name={name || 'profissional'}
        label={label}
        options={options}
        optionLabel="nome"
        optionValue="id"
        placeholder="Selecione um profissional"
        disabled={isMedico}
      />
    );

  return (
    <div className={className}>
      <MultiSelectControlled
        control={control}
        name={name || 'profissionais'}
        label={label}
        options={options}
        optionLabel="nome"
        optionValue="id"
        placeholder="Selecione um ou mais profissionais"
        disabled={isMedico}
        showSelectAll
        display="chip"
        filter
      />
    </div>
  );
};

export default FieldProfissionais;
