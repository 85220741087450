import Http, { HttpParams } from 'src/core/http/Http';

const getProtocoloAtendimentos = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: '/protocolo-atendimento',
    params,
    hideToast: true,
    throwError: true,

    ...options,
  });
};

const getProtocoloAtendimentoById = (
  id: string | number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `/protocolo-atendimento/${id}`,
    hideToast: true,
    throwError: true,

    ...options,
  });
};

const getProtocoloAtendimentoVinculos = (
  id: string | number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `/protocolo-atendimento/vinculos/${id}`,
    hideToast: true,
    throwError: true,
    ...options,
  });
}

const postProtocoloAtendimento = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: '/protocolo-atendimento',
    data,
    throwError: true,
    ...options,
  });
};

const updateProtocoloStatus = (
  params: { id: number; ativo: boolean },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `/protocolo-atendimento/${params.id}/ativo/${params.ativo}`,
    throwError: true,
    ...options,
  });
};

const updateProtocoloAtendimento = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `/protocolo-atendimento`,
    data,
    throwError: true,
    ...options,
  });
};

const deleteProtocoloAtendimento = (
  idProtocoloAtendimento: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `/protocolo-atendimento/${idProtocoloAtendimento}`,
    throwError: true,

    ...options,
  });
};

const aplicarProtocoloAtendimento = (
  idAtendimento: number,
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `/protocolo-atendimento/aplicar/${idAtendimento}`,
    data,
    throwError: true,
    ...options,
  });
};

export const ProtocoloAtendimentoAPI = {
  getProtocoloAtendimentos,
  getProtocoloAtendimentoById,
  getProtocoloAtendimentoVinculos,
  postProtocoloAtendimento,
  updateProtocoloStatus,
  updateProtocoloAtendimento,
  deleteProtocoloAtendimento,
  aplicarProtocoloAtendimento,
};
