import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { BasicPanelRef } from 'src/components/Panel/BasicPanel/BasicPanel';

type ContextProps = {
  planoSelected: any;
  paciente: any;
  timeOutElegibilidade: number;
  isTimerRunning: boolean;
  isSubmitting: boolean;
  elegibilidadeObservacao: string;
  profissionalAtivo: any;
  consultorioAtivo: any;
  servicoTussSelected: any;
  setServicoTussSelected: React.Dispatch<React.SetStateAction<any>>;
  modalOutrosDisclosure: DisclosureType;
  setPlanoSelected: React.Dispatch<React.SetStateAction<any>>;
  setPaciente: React.Dispatch<React.SetStateAction<any>>;
  setTimeOutElegibilidade: React.Dispatch<React.SetStateAction<any>>;
  setIsTimerRunning: React.Dispatch<React.SetStateAction<any>>;
  setIsSubmitting: React.Dispatch<React.SetStateAction<any>>;
  handleElegibilidadeObservacao: (observacao?: string) => void;
  handleDeleteReagendamento: (itemId: number) => void;
  resetElegibilidade: () => void;
  step1Ref: React.RefObject<BasicPanelRef>;
  step2Ref: React.RefObject<BasicPanelRef>;
  step3Ref: React.RefObject<BasicPanelRef>;
  step4Ref: React.RefObject<BasicPanelRef>;
  agendamentoRapidoNome: string | undefined;
  setAgendamentoRapidoNome: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
};

const NovoAgendamentoContext = createContext({} as ContextProps);

export function NovoAgendamentoProvider({ children }: any) {
  const { agenda, consultorios } = useAppSelector((state: RootState) => state);

  const [planoSelected, setPlanoSelected] = useState(null);
  const [servicoTussSelected, setServicoTussSelected] = useState(null);
  const [paciente, setPaciente] = useState(null);

  const [timeOutElegibilidade, setTimeOutElegibilidade] = useState(30);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [elegibilidadeObservacao, setElegibilidadeObservacao] = useState('');
  const [agendamentoRapidoNome, setAgendamentoRapidoNome] = useState<string>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const step1Ref = useRef<BasicPanelRef>(null);
  const step2Ref = useRef<BasicPanelRef>(null);
  const step3Ref = useRef<BasicPanelRef>(null);
  const step4Ref = useRef<BasicPanelRef>(null);

  const profissionalAtivo = agenda?.profissionalAtivo?.id;
  const consultorioAtivo = consultorios?.ativo?.id;
  const modalOutrosDisclosure = useDisclosure({ opened: false });

  const handleElegibilidadeObservacao = useCallback(
    (observacao: string | undefined) => {
      if (!observacao)
        return setElegibilidadeObservacao(
          'O tempo excendeu para validação da Elegibilidade do paciente e não houve resposta do servidor',
        );
      return setElegibilidadeObservacao(observacao);
    },
    [],
  );

  const resetElegibilidade = () => setElegibilidadeObservacao('');

  const handleDeleteReagendamento = async (itemId: number) => {
    try {
      await AgendamentosAPI.deleteReagemento(itemId, {
        hideToast: true,
      });
    } catch {
    } finally {
    }
  };

  return (
    <NovoAgendamentoContext.Provider
      value={{
        planoSelected,
        paciente,
        setPlanoSelected,
        setPaciente,
        setTimeOutElegibilidade,
        timeOutElegibilidade,
        isTimerRunning,
        setIsTimerRunning,
        isSubmitting,
        setIsSubmitting,
        handleElegibilidadeObservacao,
        elegibilidadeObservacao,
        resetElegibilidade,
        profissionalAtivo,
        consultorioAtivo,
        servicoTussSelected,
        setServicoTussSelected,
        step1Ref,
        step2Ref,
        step3Ref,
        step4Ref,
        modalOutrosDisclosure,
        agendamentoRapidoNome,
        setAgendamentoRapidoNome,
        handleDeleteReagendamento,
      }}
    >
      {children}
    </NovoAgendamentoContext.Provider>
  );
}

export const useNovoAgendamento = () => useContext(NovoAgendamentoContext);
