import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import Switch from 'src/components/Switch/Switch';
import Tooltip from 'src/components/Tooltip/Tooltip';

interface DesktopRowProps {
  usuario: any;
  vinculosNames: string[];
  userStatus: string;
  onBlock: (usuario: any) => void;
  onEdit: (usuario: any) => void;
  onDelete: (usuario: any) => void;
  onChangeStatus: (usuario: any) => void;
  onVisualizar: (usuario: any) => void;
}

export const DesktopRow = (props: DesktopRowProps) => {
  const { user } = useAppSelector((state: RootState) => state);
  const {
    usuario,
    onBlock,
    onEdit,
    onDelete,
    onVisualizar,
    onChangeStatus,
    vinculosNames,
    userStatus,
  } = props;
  const menu = useRef<any>(null);

  const itemsOptions = [
    {
      label: 'Editar',
      command: () => onEdit(usuario),
    },
    {
      label: 'Visualizar',
      command: () => onVisualizar(usuario),
      checkPermission: 'ADM_USUARIO_CONSULTAR',
    },
    {
      label: `${usuario?.status === true ? 'Inativar' : 'Ativar'}`,
      command: () => onChangeStatus(usuario),
      disabled: usuario?.status
        ? user.tipoUsuario !== 'MASTER' && user.tipoUsuario !== 'ADMINISTRADOR'
        : user.tipoUsuario !== 'MASTER',
    },
    {
      label: 'Excluir',
      command: () => onDelete(usuario),
      checkPermission: 'ADM_USUARIO_EXCLUIR',
      className: 'error',
    },
  ];

  return (
    <div className="p-grid p-col-12 p-px-0 p-ai-center ">
      <div className="p-col-3 p-xl-4">
        <div className="column">
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {usuario?.name}
          </SimpleText>

          <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
            {usuario?.user_type}
          </SimpleText>
        </div>
      </div>

      <div className="p-col-3 p-lg-4">
        <SimpleText
          id={`vinculo-${usuario.id}`}
          fontColor={FONT_COLOR.PRIMARY}
          medium
        >
          {vinculosNames.length > 1
            ? vinculosNames.slice(0, 2).join(' • ') + '...'
            : vinculosNames || ''}
        </SimpleText>
        <Tooltip target={`#vinculo-${usuario.id}`} position="top">
          {vinculosNames.join(' • ') || ''}
        </Tooltip>
      </div>

      <div className="p-col p-d-flex p-jc-between p-ai-center p-gap-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {usuario?.login}
        </SimpleText>

        <div className={`status-${userStatus}`}>
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={userStatus ? FONT_COLOR.COLOR_16 : FONT_COLOR.COLOR_0}
            medium
          >
            {userStatus}
          </SimpleText>
        </div>
      </div>

      <div className="p-col p-d-flex p-ai-center p-jc-end">
        <Switch
          checked={!usuario?.statusValue}
          onChange={() => onBlock(usuario)}
          showStatus
          checkPermission="ADM_USUARIO_ALTERAR"
          uncheckedLabel="Bloqueado"
          checkedLabel="Ativo"
        />
        <Separator layout="vertical" />
        <>
          <Button
            className="p-mr-1"
            btnType="gray"
            icon="fas fa-ellipsis-h"
            onClick={event => menu?.current?.toggle(event)}
          />

          <TieredMenu
            className="tiered-menu"
            model={itemsOptions}
            popup
            style={{
              width: 'fit-content !important',
            }}
            ref={menu}
          />
        </>
      </div>
    </div>
  );
};
