import React, { CSSProperties, useEffect, useMemo } from 'react';

import { TIPO_USUARIO } from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import { setProfissionalAtivo } from 'src/core/redux/slices/agenda/AgendaSlice';
import { RootState } from 'src/core/redux/store';

import AlertBox from 'src/components/AlertBox/AlertBox';
import Avatar from 'src/components/Avatar/Avatar';
import Button from 'src/components/Basics/Button/Buttons';
import IconButton, {
  IconBtnSizes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Tooltip from 'src/components/Tooltip/Tooltip';

import ProfissionalSelect from 'src/pages/Emed/Agenda/UserHeader/ProfissionalSelect/ProfissionalSelect';

import './UserHeader.scss';

interface UserHeaderProps {
  rightSide?: any;
  backButton?: any;
  style?: CSSProperties;
  isConfigAgenda?: boolean;
  reagendamentos?: AtendimentoAgendamento[];
}
const UserHeader = ({
  rightSide,
  backButton,
  style,
  isConfigAgenda,
  reagendamentos,
}: UserHeaderProps) => {
  const { user, agenda } = useAppSelector((state: RootState) => state);

  const { isTablet } = useSize();
  const dispatch = useAppDispatch();

  const isConfig = isConfigAgenda ? 'isConfig' : '';

  const shouldShowIconButton =
    user.tipoUsuario !== TIPO_USUARIO.PROFISSIONAL_SAUDE ||
    (user.tipoProfissionalSaude === 'ENFERMEIRO' && !isConfigAgenda);

  useEffect(() => {
    if (!(TIPO_USUARIO.SECRETARIO === user.tipoUsuario)) return;

    if (!agenda?.profissionaisDisponiveis?.length) {
      dispatch(setProfissionalAtivo(undefined));
      return;
    }

    const profissionalSaudeAtivo = agenda.profissionaisDisponiveis.find(
      (profissionalSaude: GetUsuarioDTO) =>
        user.tipoProfissionalSaude === 'ENFERMEIRO'
          ? profissionalSaude.id === user.idUsuario
          : profissionalSaude.id === agenda?.profissionalAtivo?.id,
    );

    dispatch(
      setProfissionalAtivo(
        profissionalSaudeAtivo || agenda.profissionaisDisponiveis[0],
      ),
    );
  }, [
    agenda.profissionaisDisponiveis,
    agenda?.profissionalAtivo?.id,
    user.tipoUsuario,
    dispatch,
    user.tipoProfissionalSaude,
    user.idUsuario,
  ]);

  const profissinalSaudeEspecialidades = useMemo(() => {
    const especialidades =
      agenda?.profissionalAtivo?.profissionalSaude?.especialidades;

    if (!(Array.isArray(especialidades) && especialidades.length)) return '';

    return especialidades
      .slice(0, 2)
      .map((especialidade: any) => especialidade.nome)
      .toString()
      .replace(',', ' • ');
  }, [agenda?.profissionalAtivo?.profissionalSaude?.especialidades]);

  const profissionalSaudeTodasEspecialidades = useMemo(
    () =>
      (agenda?.profissionalAtivo?.profissionalSaude?.especialidades || []).map(
        (especialidade: any) => especialidade.nome,
      ),
    [agenda?.profissionalAtivo?.profissionalSaude?.especialidades],
  );

  const reagendamentoLength = useMemo(() => {
    return !!reagendamentos && reagendamentos.length;
  }, [reagendamentos]);

  return (
    <div className={`UserHeader ${isConfig}`} style={style}>
      {backButton && (
        <Button
          icon="pi pi-arrow-left"
          btnType="green-link"
          label="Voltar"
          onClick={backButton}
        />
      )}
      <div className="infos p-flex-wrap">
        <Avatar
          size="large"
          label={agenda.profissionalAtivo?.nome}
          icon="fas fa-user"
          image=""
        />
        <div className="icon-aligner p-gap-2">
          <div className="infos">
            <SimpleText fontSize={FONT_SIZE.MD} bold>
              {agenda.profissionalAtivo?.nome || '-'}
            </SimpleText>

            {shouldShowIconButton && (
              <IconButton
                icon={'pi pi-chevron-down'}
                btnSize={IconBtnSizes.SMALL}
                overlay={<ProfissionalSelect />}
                type="button"
              />
            )}
          </div>

          <Tooltip target="#especialidades-agenda" />
          <SimpleText
            id="especialidades-agenda"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXXS}
            data-pr-tooltip={profissionalSaudeTodasEspecialidades.toString()}
            data-pr-position="top"
          >
            {profissinalSaudeEspecialidades}{' '}
            {profissionalSaudeTodasEspecialidades.length > 2 && ' e outras.'}
          </SimpleText>
        </div>

        {!!reagendamentoLength && (
          <div
            style={{
              width: '337px',
              order: isTablet ? 3 : 'unset',
            }}
          >
            <AlertBox
              visible
              text={`Horário de atendimento ou compromisso alterado! \n É necessário reagendar os pacientes.`}
            />
          </div>
        )}

        <div className="rightside">{rightSide}</div>
      </div>
    </div>
  );
};

export default React.memo(UserHeader);
