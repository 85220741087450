import { useCallback, useEffect, useState } from 'react';

import PendenciasAuditoriaAPI from 'src/APIs/AdminAPI/PendenciasAuditoriaAPI/PendenciasAuditoriaAPI';

import useSize from 'src/core/hooks/useSize';

import Separator from 'src/components/Separator/Separator';

import InputChat from './InputChat';
import MensagensChat from './MensagensChat';

interface ChatProps {
  data: IPendenciaAuditoriaDTO;
}

const Chat = ({ data }: ChatProps) => {
  const [mensagens, setMensagens] = useState<IMsgPendenciaAuditoriaDTO[]>([]);
  const [loading, setLoading] = useState(false);

  const { isMobile } = useSize();

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const getMensagens = useCallback(async () => {
    if (!data.numeroGuia) return;
    setLoading(true);
    try {
      const response = await PendenciasAuditoriaAPI.getMensagensPendencia({
        numeroGuia: data.numeroGuia,
        anoGuia: new Date(data.dataEnvio).getFullYear().toString(),
      });
      setMensagens(response);
    } catch (error) {
      setMensagens([]);
    } finally {
      setLoading(false);
    }
  }, [data.dataEnvio, data.numeroGuia]);

  useEffect(() => {
    getMensagens();
  }, [getMensagens]);

  return (
    <>
      <Separator className="p-mt-2" />
      <div
        className={`Chat p-d-flex p-flex-column ${
          isMobile ? 'mobile-full' : ''
        }`}
      >
        <MensagensChat mensagens={mensagens} loading={loading} />
        <InputChat data={data} getMensagens={getMensagens} setMensagens={setMensagens} />
      </div>
    </>
  );
};

export default Chat;
