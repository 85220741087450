import { useState } from 'react';

import { Button } from 'src/components/_UI';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

interface FilterProps {
  handleModalAddFornecedorTuss: () => void;
}

const Filter = ({ handleModalAddFornecedorTuss }: FilterProps) => {
  const [filterModulo, setFilterModulo] = useState<string>('');

  const { setFilter } = useSearchList();
  return (
    <div className="p-grid p-jc-between p-ai-center p-flex-wrap">
      <div className="p-grid p-ai-end p-col-12 p-lg-9 p-xl-8">
        <SearchList.SearchInput
          placeholder="Pesquisar por nome fornecedor"
          className="p-col-12 p-md-6"
        />

        <Dropdown
          className="p-col-10 p-md-4 p-mb-1"
          label="Módulo"
          value={filterModulo}
          onChange={e => setFilterModulo(e.target.value)}
          options={[
            { label: 'Financeiro', value: 'FINANCEIRO' },
            { label: 'Configuração TUSS', value: 'CONFIGURACAO_TUSS' },
          ]}
          showClear
          filter={false}
        />

        <Button
          className="p-col-2 p-as-center p-mt-3"
          btnType="tonal"
          label="Filtrar"
          onClick={() =>
            setFilter({
              modulo: filterModulo,
            })
          }
        />
      </div>

      <Button
        btnType="pill"
        className="p-mb-2 p-col-6 p-sm-3 p-md-2"
        label="Novo Fornecedor"
        icon="fas fa-plus"
        onClick={handleModalAddFornecedorTuss}
        checkPermission="ADM_FORNECEDOR_TUSS_CADASTRAR"
      />
    </div>
  );
};

export default Filter;
