import { useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import ServicoTussCRUDAPI from 'src/APIs/AdminAPI/ServicoTussCRUDAPI/ServicoTussCRUDAPI';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import { FieldServicoTuss } from 'src/components/Fields/FieldServicoTuss/FieldServicoTuss';
import FormInput from 'src/components/FormInput/FormInput';

export const FormContentExames = ({
  isEditing,
  convenio,
}: {
  isEditing: boolean;
  convenio: ConvenioDTO;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const form = useFormContext();

  const servicoTussId = form.watch('servicoTuss')?.id;

  useEffectSkipFirst(() => {
    if (servicoTussId && !isEditing) {
      setIsLoading(true);

      ServicoTussCRUDAPI.loadServicoTussById(servicoTussId, {
        throwError: true,
      })
        .then(d =>
          form.setValue('quantidade', d.quantidadePadrao || 1, {
            shouldValidate: true,
          }),
        )
        .finally(() => setIsLoading(false));
    }
  }, [servicoTussId, isEditing]);

  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-p-2">
      <FieldServicoTuss
        name="servicoTuss"
        label="Nome do exame"
        returnType="all"
        className="p-w-100"
        params={useMemo(() => ({ idConvenio: convenio?.id }), [convenio?.id])}
      />

      <FormInput
        name="quantidade"
        label="Quantidade"
        type="number"
        hideTextErrorSpace
        className="p-w-100"
        disabled={isLoading}
        icon={isLoading ? 'pi pi-spin pi-spinner' : ''}
      />
      <FormInput
        name="recorrencia"
        label="Recorrência"
        sublabel="(dias)"
        type="number"
        min={1}
        hideTextErrorSpace
      />
      <TextareaInputControlled
        control={form.control}
        name="execucao"
        label="Execução"
        maxLengthSpan
        maxLength={2500}
      />
    </div>
  );
};
