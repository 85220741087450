import { InputSwitch, InputSwitchProps } from 'primereact/inputswitch';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from '../Basics/SimpleText/SimpleText';
import Can from '../Can/Can';
import './Switch.scss';

export interface SwitchProps extends InputSwitchProps {
  checkPermission?: string | string[];
  showStatus?: boolean;
  label?: string;
  uncheckedLabel?: string;
  checkedLabel?: string;
  loading?: boolean;
}

const Switch = ({
  showStatus,
  className,
  label,
  checkPermission = '',
  disabled = false,
  checkedLabel,
  loading,
  ...props
}: SwitchProps) => {
  const uncheckedLabelType = props.uncheckedLabel || 'Desativado';
  const disableSwitch = loading || disabled;

  return (
    <Can checkPermission={checkPermission}>
      {can => (
        <div className={`Switch ${className || ''}`}>
          {showStatus && !label && (
            <SimpleText
              className="p-mr-2 switch-label"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              {props.checked ? checkedLabel || 'Ativado' : uncheckedLabelType}
            </SimpleText>
          )}
          <InputSwitch {...props} disabled={!can || !!disabled || !!loading} />
          {!!label && (
            <SimpleText
              className="p-ml-2 switch-label"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_40}
            >
              {label}
            </SimpleText>
          )}
        </div>
      )}
    </Can>
  );
};

export default Switch;
