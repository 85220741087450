import { useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { useNavigate } from 'react-router';

import dayjs from 'dayjs';
import ProntuarioAPI from 'src/APIs/ProntuarioAPI/ProntuarioAPI';

import { cn } from 'src/utils/cn';
import { capitalizeFirstLetter, formatCPF } from 'src/utils/utils';

import Avatar from 'src/components/Avatar/Avatar';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

export const ProntuariosSearch = ({ className }: { className?: string }) => {
  const ref = useRef<OverlayPanel>(null);
  const navigate = useNavigate();

  const fetchProntuarios = async ({ query }: any) => {
    try {
      const res = ProntuarioAPI.loadBuscaAvancada({
        paciente: query,
        ordenacao: 'DATA_DE_ATENDIMENTO',
      });

      return res;
    } catch {
      return;
    }
  };

  return (
    <div
      className={cn(
        'row content-row p-flex-column white p-p-3 p-jc-between p-gap-3',
        className,
      )}
      style={{
        border: '1px solid var(--neutral-color-grayscale-88)',
      }}
    >
      <SearchList.Root fetchApi={fetchProntuarios}>
        <SimpleText className="p-py-2">
          Pesquise rapidamente um paciente
        </SimpleText>

        <SearchList.SearchInput
          placeholder="Digite o nome do paciente"
          onClick={e => ref.current?.show(e, e.target)}
          onChangeCapture={e => [ref.current?.show(e, e.target)]}
          onBlur={() => ref.current?.hide()}
          className="p-w-100"
        />

        <OverlayPanel
          ref={ref}
          style={{
            width: '640px',
          }}
        >
          <SearchList.InfiniteScroll
            height={320}
            renderRow={(row: any) => (
              <div
                className="row content-row p-w-100 p-my-2 p-ai-center hover"
                onClick={() =>
                  navigate('/prontuario', {
                    state: {
                      idAtendimento: row.idAtendimento,
                    },
                  })
                }
              >
                <div className="p-col-2 pointer-events-none">
                  <Avatar label={row.paciente} />
                </div>
                <div className="p-col-5 p-d-flex p-flex-column p-gap-1 pointer-events-none">
                  <SimpleText fontColor="color_60" fontSize="xxs">
                    {capitalizeFirstLetter(row?.sexo || '')}
                  </SimpleText>
                  <SimpleText>{row.paciente}</SimpleText>
                  <SimpleText fontSize="xs" fontColor="color_60">
                    {row?.idade || '-'} anos • {formatCPF(row.cpf)}
                  </SimpleText>
                </div>
                <div className="p-col-5 p-d-flex p-flex-column p-gap-1 pointer-events-none">
                  <SimpleText fontColor="color_60" fontSize="xxs">
                    Último atendimento
                  </SimpleText>
                  <SimpleText>
                    {dayjs(row.dataAgendamento).format('DD/MM/YYYY')}
                  </SimpleText>
                </div>
              </div>
            )}
          />
        </OverlayPanel>
      </SearchList.Root>
    </div>
  );
};
