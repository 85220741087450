import { Checkbox } from 'src/components/_UI/Checkbox';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

import { useSelectedContaReceber } from '../../useSelectedContaReceber';

const CheckboxCounter = () => {
  const { selectedContaReceber, setSelectedContaReceber } =
    useSelectedContaReceber();

  const { listItems } = useSearchList();
  return (
    <Checkbox
      label={
        selectedContaReceber?.length === listItems.length
          ? 'Todos marcados'
          : `${selectedContaReceber?.length || 'Nenhum'} marcado${
              selectedContaReceber?.length > 1 ? 's' : ''
            }`
      }
      checked={selectedContaReceber?.length > 0}
      onChange={() => {
        if (selectedContaReceber?.length > 0) {
          setSelectedContaReceber([]);
        }
      }}
      disabled={selectedContaReceber?.length === 0}
    />
  );
};

export default CheckboxCounter;
