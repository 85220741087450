import { useFormContext } from 'react-hook-form';

import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import FormInput from 'src/components/FormInput/FormInput';
import { FieldFormulariosDinamico } from 'src/pages/Emed/Admin/FormularioDinamico/AplicacaoFormulario/_components/FieldFormulariosDinamico';

export const FormContentFormularioDinamico = () => {
  const form = useFormContext();

  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-p-2">
      <FieldFormulariosDinamico
        name="formularioDinamico"
        label="Nome do formulário"
        ativos
        className="p-px-0"
        hideTextErrorSpace
      />

      <FormInput
        name="recorrencia"
        type="number"
        label="Recorrência (dias)"
        hideTextErrorSpace
      />

      <TextareaInputControlled
        control={form.control}
        name="execucao"
        label="Execução"
      />
    </div>
  );
};
