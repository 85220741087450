import { useFormContext } from 'react-hook-form';

import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import { FieldEspecialidade } from 'src/components/Fields/FieldEspecialidade/FieldEspecialidade';
import FormInput from 'src/components/FormInput/FormInput';

export const FormContentConsulta = () => {
  const form = useFormContext();

  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-p-2">
      <FieldEspecialidade
        name="especialidade"
        label="Especialidade"
        placeholder="Selecione uma especialidade"
        className="p-p-0"
        hideTextErrorSpace
      />
      <FormInput
        name="recorrencia"
        label="Recorrência"
        sublabel="(dias)"
        type="number"
        min={1}
        hideTextErrorSpace
      />
      <TextareaInputControlled
        control={form.control}
        name="execucao"
        label="Execução"
        maxLengthSpan
        maxLength={2500}
      />
      <TextareaInputControlled
        control={form.control}
        name="resumoClinico"
        label="Resumo clínico/Resultado de exame (s) e procedimentos realizados"
        maxLengthSpan
        maxLength={4000}
      />
      <TextareaInputControlled
        control={form.control}
        name="hipoteseDiagnostica"
        label="Hipótese diagnóstica"
        maxLengthSpan
        maxLength={4000}
      />
      <TextareaInputControlled
        control={form.control}
        name="questionamento"
        label="Questionamento"
        maxLengthSpan
        maxLength={4000}
      />
    </div>
  );
};
