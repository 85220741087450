import dayjs from 'dayjs';

export const verifyIfShowMessageDate = (
  item: IChatMessageItem,
  index: number,
  list: IChatMessageItem[],
) => {
  // Caso seja o primeiro item da lista, sempre exibir a data
  if (list.length - 1 === index) return true;

  const nextMessage = list[index + 1];

  if (
    nextMessage &&
    nextMessage.dataInclusao &&
    // Caso a proxima msg seja de um usuário diferente, exibir a data
    nextMessage.idUsuarioEnvio !== item.idUsuarioEnvio
  ) {
    return true;
  }

  // Caso a dif entre a data da msg atual e a prox seja maior que 5 min, exibir a data
  return (
    dayjs(item.dataInclusao).diff(dayjs(nextMessage?.dataInclusao), 'minute') >
    5
  );
};

export type ChatUserStatus = 'online' | 'busy' | 'idle' | 'offline';

export const chatUserStatus: { [key: string]: ChatUserStatus } = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  OCUPADO: 'busy',
  AUSENTE: 'idle',
};

export const chatUserStatusRequest: { [key: string]: IChatMessageStatus } = {
  online: 'ONLINE',
  offline: 'OFFLINE',
  busy: 'OCUPADO',
  idle: 'AUSENTE',
};
