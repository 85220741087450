import React, { useCallback, useEffect, useState } from 'react';

import { ProtocoloAtendimentoAPI } from 'src/APIs/AdminAPI/ProtocoloAtendimentoAPI/ProtocoloAtendimentoAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Skeleton from 'src/components/Skeleton/Skeleton';
import Tab from 'src/components/Tab/Tab';

import './ModalVinculos.scss';

interface ModalVinculosProps extends DisclosureType {
  idProtocolo: number;
}

const ModalVinculos = ({ close, idProtocolo }: ModalVinculosProps) => {
  const [consultorios, setConsultorios] = useState<any[]>([]);
  const [convenios, setConvenios] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchVinculos = useCallback(async () => {
    try {
      setLoading(true);
      const response =
        await ProtocoloAtendimentoAPI.getProtocoloAtendimentoVinculos(
          idProtocolo,
        );
      setConsultorios(response.consultorios || []);
      setConvenios(response.convenios || []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [idProtocolo]);

  useEffect(() => {
    fetchVinculos();
  }, [fetchVinculos]);

  return (
    <Dialog
      header={'Vínculos'}
      visible={true}
      onHide={close}
      style={{ width: '500px' }}
      id="modal-vinculos"
      maximizedMobileSize
    >
      <>
        {loading ? (
          <Skeleton height="400px" loading />
        ) : (
          <Tab
            fullHeight
            clean
            noPanel
            headerColor={false}
            className="p-w-100"
            values={[
              {
                label: 'Consultórios',
                content: <ListaConsiltorios consultorios={consultorios} />,
              },
              {
                label: 'Convênios',
                content: <ListaConvenios convenios={convenios} />,
              },
            ]}
          />
        )}

        <Button
          label="Fechar"
          btnType="outline"
          onClick={() => close()}
          className="p-px-4 p-mt-4"
          stretch
        />
      </>
    </Dialog>
  );
};

export default ModalVinculos;

const ListaConsiltorios = ({ consultorios }: any) => {
  return (
    <div className="lista-vinculos p-p-3">
      <SimpleText fontColor="color_60">Nome do consultório</SimpleText>
      {consultorios?.map((item: any) => (
        <div className="row-component p-p-2 p-my-1" key={item.id}>
          <SimpleText medium>{item.nome}</SimpleText>
        </div>
      ))}
    </div>
  );
};

const ListaConvenios = ({ convenios }: any) => {
  return (
    <div className="lista-vinculos p-p-3">
      <SimpleText fontColor="color_60">Nome do convênio</SimpleText>
      {convenios?.map((item: any) => (
        <div className="row-component p-p-2 p-my-1" key={item.id}>
          <SimpleText medium>{item.nome}</SimpleText>
        </div>
      ))}
    </div>
  );
};
