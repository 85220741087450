import React, { useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { useController } from 'react-hook-form';

import CidAPI from 'src/APIs/ProntuarioAPI/CidAPI/CidAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter } from 'src/utils/utils';

import DropdownCid from './components/DropdownCid';
import ModalFavoritos from './components/ModalFavoritos';
import { Button } from 'src/components/_UI';

import './FieldArrayCidSelect.scss';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

interface FieldArrayCidSelectProps {
  label?: string;
  onSelectCid: (cid: any) => void;
  selected: any[];
}

const FieldArrayCidSelect = ({
  label,
  onSelectCid,
  selected,
}: FieldArrayCidSelectProps) => {
  const favoritosRef = useRef<OverlayPanel>(null);
  const modalDisclosure = useDisclosure({ opened: false });

  return (
    <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
      <SimpleText medium>{label || 'CID'}</SimpleText>
      <div id="field-cid-select" className="p-grid p-ai-baseline p-w-100">
        <div className="favoritos p-col-4 p-pr-0 p-mb-1 p-py-0">
          <Button
            btnType="ghost"
            label="Meus favoritos"
            iconPos="right"
            icon="pi pi-chevron-down"
            className="favoritos-btn"
            onClick={e => favoritosRef.current?.toggle(e)}
          />

          <OverlayPanel ref={favoritosRef}>
            <ModalFavoritos
              onSelectCid={onSelectCid}
              {...modalDisclosure}
              selected={selected}
            />
          </OverlayPanel>
        </div>
        <div className="busca p-col-8 p-grid p-pl-0 p-py-0">
          <DropdownCid onSelectCid={onSelectCid} />
        </div>
      </div>
    </div>
  );
};

export default FieldArrayCidSelect;
