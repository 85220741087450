import { useCallback, useRef, useState } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useDispatch } from 'react-redux';

import { PlanoCuidadoPacienteAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoPacienteAPI/PlanoCuidadoPacienteAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import PanelFiltros from './components/PanelFiltros/PanelFiltros';
import { PlanosRow } from './components/PlanosRow/PlanosRow';
import * as SearchList from './components/SearchList/SearchListRoot';
import { Button } from 'src/components/_UI';
import { ButtonMenu } from 'src/components/Basics/ButtonMenu/ButtonMenu';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import Separator from 'src/components/Separator/Separator';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import {
  PlanosPacientesProvider,
  usePlanosPacientes,
} from './hooks/PlanosPacientesContext';
import { PlanosPacientesFilters } from './PlanosPacientesFilters';

import './PlanosPacientes.scss';
const items: any = {
  dataInclusao: 'Data Inclusão',
  dataFimVigencia: 'Data Fim Vigência',
  'planoCuidado.nome': 'Ordem Alfábetica',
};

export const PlanosPacientes = () => (
  <PlanosPacientesProvider>
    <PlanosPacientesComp />
  </PlanosPacientesProvider>
);

const PlanosPacientesComp = () => {
  const { ativo } = useAppSelector(state => state.consultorios);
  const { invalidatePlanosList } = useAppSelector(state => state.query);
  const { filters, reload, selectedPaciente } = usePlanosPacientes();
  const { atendimentoStatus } = useAtendimento();

  const autoHeight = atendimentoStatus?.includes('ATENDENDO');

  const filtersRef = useRef<OverlayPanel>(null);
  const dispatch = useDispatch();

  const [sortBy, setSortBy] = useState('dataInclusao');
  const [nome, setNome] = useState('');

  const itemsOrdenation: MenuItem[] = [
    { label: 'data de inclusão', command: () => setSortBy('dataInclusao') },
    { label: 'data de vigência', command: () => setSortBy('dataFimVigencia') },
    {
      label: 'ordem alfabética',
      command: () => setSortBy('planoCuidado.nome'),
    },
  ];

  const fetchApi = useCallback(
    async (params: any) => {
      try {
        if (!selectedPaciente) return;
        const data = await PlanoCuidadoPacienteAPI.loadPlanoCuidadoPaciente({
          idPaciente: selectedPaciente,
          idConsultorio: ativo.id,
          sortBy,
          ...filters,
          nome,
          ...params,
        });

        return data;
      } catch {
      } finally {
        if (!!invalidatePlanosList) dispatch(resetInvalidateQuery());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reload],
  );

  const handleNome = useThrottle((v: string) => setNome(v), 750);

  return (
    <div id="planos-pacientes">
      <PanelFiltros />
      <Separator spaceOnly className="p-mt-4" />
      <Panel
        defaultExpanded
        header={(isCollapsed, ontoggle) => (
          <div className="p-d-flex p-jc-between p-ai-center p-py-1 p-px-3">
            <SimpleText fontSize={FONT_SIZE.SM} medium>
              Plano de cuidado e atividades
            </SimpleText>
            <Button
              icon={`fas fa-chevron-${isCollapsed ? 'up' : 'down'}`}
              type="button"
              btnType="gray"
              onClick={ontoggle}
            />
          </div>
        )}
      >
        <>
          <div className="p-grid p-d-flex p-flex-column p-w-100">
            <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
              Pesquise pelo planos de cuidado e suas ativadades
            </SimpleText>

            <div className="p-grid p-col-12 p-px-0 p-ai-end p-jc-center p-gap-2">
              <div className="p-d-flex p-col-12 p-md-10">
                <TextInputSearch
                  placeholder="Pesquise pelo nome do plano"
                  onChange={v => handleNome(v)}
                />
              </div>

              <Button
                label="Pesquisa avançada"
                icon="fas fa-chevron-down"
                type="button"
                btnType="tonal"
                iconPos="right"
                className="p-col"
                onClick={e => filtersRef.current?.toggle(e)}
              />
            </div>

            <div className="p-d-flex p-jc-end p-ai-center p-gap-2">
              <SimpleText>Ordenar por:</SimpleText>
              <ButtonMenu
                label={items[sortBy]}
                btnType="green-link"
                model={itemsOrdenation}
              />
            </div>
          </div>

          <div className="content-planos p-mb-4">
            <div className="p-d-flex p-px-1" style={{ width: '97%' }}>
              <SimpleText
                className="p-col-5 p-md-7 p-lg-10"
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Nome do plano
              </SimpleText>
              <SimpleText
                className="p-col-2 p-md-2 p-lg-1"
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Status
              </SimpleText>
              <SimpleText
                className="p-col p-text-right"
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Ações
              </SimpleText>
            </div>

            <SearchList.Root fetchApi={fetchApi}>
              <div className="p-col-12 p-pt-0 p-d-flex p-flex-column p-gap-2">
                <SearchList.InfiniteScroll
                  autoHeight={autoHeight}
                  renderRow={item => <PlanosRow item={item} type="GESTAO" />}
                />
              </div>
            </SearchList.Root>
          </div>

          <PlanosPacientesFilters ref={filtersRef} />
        </>
      </Panel>
    </div>
  );
};
