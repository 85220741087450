import Http, { HttpParams } from 'src/core/http/Http';

const loadIndicadorTissPendentes = async (
  params: {
    dataInicial?: string;
    dataFinal?: string;
    idProfissionalSaude?: number;
    idConsultorio?: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<number> =>
  Http.get({
    service: 'adm',
    url: `/dashboard/tiss/pendentes`,
    params,
    hideToast: true,
    ...options,
  });

const loadIndicadorTissRealizadas = async (
  params: {
    dataInicial?: string;
    dataFinal?: string;
    idProfissionalSaude?: number;
    idConsultorio?: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<number> =>
  Http.get({
    service: 'adm',
    url: `/dashboard/tiss/realizadas`,
    params,
    hideToast: true,
    ...options,
  });

const loadIndicadorExamesSolicitados = async (
  params: {
    dataInicial?: string;
    dataFinal?: string;
    idProfissionalSaude?: number;
    idConsultorio?: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<number> =>
  Http.get({
    service: 'adm',
    url: `/dashboard/exames-solicitados`,
    params,
    hideToast: true,
    ...options,
  });

const loadIndicadorProntuariosNaoAssinados = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<number> =>
  Http.get({
    service: 'adm',
    url: `/dashboard/prontuarios-nao-assinados`,
    hideToast: true,
    ...options,
  });

const loadIndicadorAtendimentos = async (
  params: {
    dataInicial?: string;
    dataFinal?: string;
    idProfissionalSaude?: number;
    idConsultorio?: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IDashboardAtendimentosResponse> =>
  Http.get({
    service: 'adm',
    url: `/dashboard/atendimentos`,
    hideToast: true,
    params,
    ...options,
  });

const loadIndicadorUsuarios = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IDashboardUsuariosResponse> =>
  Http.get({
    service: 'adm',
    url: `/dashboard/usuarios`,
    hideToast: true,
    ...options,
  });

const loadGraficoFinanceiro = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IDashboardGraficoFinanceiroResponse> =>
  Http.get({
    service: 'adm',
    url: `/dashboard/financeiro`,
    hideToast: true,
    ...options,
  });

const loadGraficoUsuarios = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IDashboardGraficosMesQuantidadeResponse[]> =>
  Http.get({
    service: 'adm',
    url: `/dashboard/usuarios-grafico`,
    hideToast: true,
    ...options,
  });

const loadGraficoTissRealizadas = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IDashboardGraficosMesQuantidadeResponse[]> =>
  Http.get({
    service: 'adm',
    url: `/dashboard/tiss/realizadas-grafico`,
    hideToast: true,
    ...options,
  });

const loadGraficoExamesSolicitados = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IDashboardGraficosMesQuantidadeResponse[]> =>
  Http.get({
    service: 'adm',
    url: `/dashboard/exames-solicitados-grafico`,
    hideToast: true,
    ...options,
  });

const loadGraficoAtendimentos = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IDashboardGraficosMesQuantidadeResponse[]> =>
  Http.get({
    service: 'adm',
    url: `/dashboard/atendimentos-grafico`,
    hideToast: true,
    ...options,
  });

export const DashboardAPI = {
  loadIndicadorTissPendentes,
  loadIndicadorTissRealizadas,
  loadIndicadorExamesSolicitados,
  loadIndicadorProntuariosNaoAssinados,
  loadIndicadorAtendimentos,
  loadIndicadorUsuarios,
  loadGraficoFinanceiro,
  loadGraficoUsuarios,
  loadGraficoTissRealizadas,
  loadGraficoExamesSolicitados,
  loadGraficoAtendimentos,
};
