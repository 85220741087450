import { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router';

import ProfissionalAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';
import TermoConsentimentoPacienteAPI from 'src/APIs/PacienteAPI/TermoConsentimentoPacienteAPI/TermoConsentimentoPacienteAPI';
import { GetProfissionalDTO } from 'src/models/APIs/AdminAPI/Consultorio/ProfissionalDTOs';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import PickList from 'src/components/PickList/PickList';
import PickListSkeleton from 'src/components/PickList/PickListSkeleton/PickListSkeleton';

interface ProfissionaisSaudeAutorizadosProps {
  novoTermo: ITermoConsentimentoItem | null;
  prevStep: () => void;
  reloadList: () => void;
  closeTermoForm: () => void;
  setNovoTermo: (termo: ITermoConsentimentoItem | null) => void;
}

const ProfissionaisSaudeAutorizados = ({
  novoTermo,
  setNovoTermo,
  prevStep,
  reloadList,
  closeTermoForm,
}: ProfissionaisSaudeAutorizadosProps) => {
  const { idPaciente } = useParams();
  const { consultorios } = useAppSelector((state: RootState) => state);
  const [loading, setLoading] = useState(true);
  const [profissionaisList, setProfissionaisList] = useState<
    GetProfissionalDTO[]
  >([]);
  const [submitting, setSubmitting] = useState(false);
  const [profisionaisSelected, setProfissionaisSelected] = useState<any[]>(
    () => {
      if (novoTermo?.profissionaisAutorizados?.length) {
        return (
          novoTermo.profissionaisAutorizados?.map(profissional => ({
            label:
              profissional?.profissionalSaude?.usuario?.nome ??
              'Nome não disponível',
            value: { id: profissional.idProfissionalSaude },
          })) || []
        );
      } else {
        return [];
      }
    },
  );

  const fetchProfissionaisConsultorio = async () => {
    setLoading(true);
    try {
      const response = await ProfissionalAPI.loadProfissionaisByConsultorioId(
        consultorios.ativo.id,
        {
          throwError: true,
        },
      );
      setProfissionaisList(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const profissinaisOptions = useMemo(() => {
    return profissionaisList.map(profissional => ({
      label: profissional.nome,
      value: profissional,
    }));
  }, [profissionaisList]);

  const onSalvarProfissionais = async () => {
    if (!idPaciente) return;
    setSubmitting(true);
    const idsProfissionaisAutorizados = profisionaisSelected.map(profissional =>
      Number(profissional.value.id),
    );
    const { profissionaisAutorizados, ...rest } = novoTermo || {};
    const payload: any = {
      ...rest,
      idsProfissionaisAutorizados,
      idPaciente: Number(idPaciente),
    };

    try {
      await TermoConsentimentoPacienteAPI.createTermoConsentimento(payload);
      closeTermoForm();
      reloadList();
      setNovoTermo(null);
      setProfissionaisSelected([]);
      prevStep();
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchProfissionaisConsultorio();
  }, []);

  return (
    <div>
      <SimpleText fontColor="color_60">
        Na coluna{' '}
        <strong>“Profissionais de saúde com acesso autorizado“</strong>,
        adicione os nomes dos profissionais para os quais deseja conceder acesso
        aos dados do prontuário.
      </SimpleText>
      {loading ? (
        <PickListSkeleton />
      ) : (
        <PickList
          label="Profissionais de saúde disponíveis"
          setSelected={setProfissionaisSelected}
          arraySelected={profisionaisSelected}
          arrayOptions={profissinaisOptions}
          disabled={novoTermo?.id ? true : false}
        />
      )}
      <Button
        className="p-mb-1"
        label="Salvar"
        onClick={() => onSalvarProfissionais()}
        loading={submitting}
        stretch
        disabled={novoTermo?.id ? true : false}
      />
    </div>
  );
};

export default ProfissionaisSaudeAutorizados;
