import { memo, useCallback, useMemo, useState } from 'react';

import { Sidebar } from 'primereact/sidebar';

import useSize from 'src/core/hooks/useSize';

import {
  FILTROS_ATENDIMENTOS_AGENDA,
  FILTROS_PERIODO_AGENDA,
  statusListaEsperaFilter,
} from './utils';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Calendar from 'src/components/Calendar/Calendar';
import Separator from 'src/components/Separator/Separator';

import { useBarraLateralMenuAgenda } from './BarraLateralMenuAgendaContext';
import { BarraLateralMenuAgendaListaEspera } from './BarraLateralMenuAgendaListaEspera';
import { BarraLateralMenuAgendaReagendar } from './BarraLateralMenuAgendaReagendar';

import './BarraLateralMenuAgenda.scss';

type MenuBarraLateral = 'LISTA_ESPERA' | 'REAGENDAMENTOS' | null;

interface BarraLateralMenuAgendaProps {
  selectedDate: Date;
  onChangeSelectedDate(date: Date): void;
  setHorarioDisponivelSelecionado(v: string): void;
  openSelectedHorarioDisponivelModal(paciente: any): void;
}

export const BarraLateralMenuAgenda = (props: BarraLateralMenuAgendaProps) => {
  const { barraLateralDisclosure } = useBarraLateralMenuAgenda();

  return (
    <>
      <div
        className={`open-btn-barra-lateral ${
          !barraLateralDisclosure.isOpen ? 'show-btn' : 'hide-btn'
        }`}
        onClick={() => {
          barraLateralDisclosure.toggle();
        }}
      >
        <i className="pi pi-angle-double-right" />
      </div>

      {barraLateralDisclosure.isOpen && (
        <BarraLateralMenuAgendaSideBar {...props} />
      )}
    </>
  );
};

const BarraLateralMenuAgendaSideBar = ({
  selectedDate,
  onChangeSelectedDate,
  openSelectedHorarioDisponivelModal,
}: BarraLateralMenuAgendaProps) => {
  // const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
  //   funcionalidade: [
  //     'age_lista_de_espera_cadastrar',
  //     'age_lista_de_espera_alterar',
  //     'age_lista_de_espera_consultar',
  //     'age_lista_de_espera_excluir',
  //   ],
  // });

  const { barraLateralDisclosure, reagendamentos } =
    useBarraLateralMenuAgenda();

  const [menu, setMenu] = useState<MenuBarraLateral>(
    barraLateralDisclosure.state?.to || null,
  );
  const [isFilterStatusHidden, setIsFilterStatusHidden] = useState(true);
  const [isFilterPeriodoHidden, setIsFilterPeriodoHidden] = useState(false);
  const [statusFilter, setStatusFilter] = useState<
    { label: string; isActive: boolean; value: FILTROS_ATENDIMENTOS_AGENDA }[]
  >(statusListaEsperaFilter);
  const [periodoFilter, setPeriodoFilter] = useState<
    { label: string; isActive: boolean; value: FILTROS_PERIODO_AGENDA }[]
  >([
    {
      label: 'Manhã',
      isActive: true,
      value: FILTROS_PERIODO_AGENDA.MANHA,
    },
    {
      label: 'Tarde',
      isActive: false,
      value: FILTROS_PERIODO_AGENDA.TARDE,
    },
  ]);

  const { isTablet, windowInnerWidth } = useSize();

  const statusFilters = useCallback(() => {
    return (
      <div className="filter">
        <Button
          btnType="green-link"
          label="Status"
          onClick={() => {
            setIsFilterStatusHidden(prev => !prev);
          }}
          icon={
            isFilterStatusHidden ? 'pi pi-chevron-up' : 'pi pi-chevron-down'
          }
        />
        <div
          className={`filter-content ${isFilterStatusHidden ? 'hide' : 'show'}`}
        >
          {(statusFilter || []).map((v, i) => {
            return (
              <Checkbox
                key={v.value}
                label={v.label}
                value={v.value}
                checked={v.isActive}
                onChange={() => {
                  const aux: any[] = [...statusFilter];
                  aux[i] = { ...aux[i], isActive: !v.isActive };
                  setStatusFilter(aux);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  }, [isFilterStatusHidden, statusFilter]);

  const periodoFilters = useCallback(() => {
    return (
      <div className="filter">
        <Button
          btnType="green-link"
          label="Periodo"
          onClick={() => {
            setIsFilterPeriodoHidden(prev => !prev);
          }}
          icon={
            isFilterPeriodoHidden ? 'pi pi-chevron-up' : 'pi pi-chevron-down'
          }
        />
        <div
          className={`filter-content ${
            isFilterPeriodoHidden ? 'hide' : 'show'
          }`}
        >
          {(periodoFilter || []).map((v, i) => {
            return (
              <Checkbox
                key={v.value}
                label={v.label}
                value={v.value}
                checked={v.isActive}
                onChange={() => {
                  const aux: any[] = [...periodoFilter];
                  aux[i] = { ...aux[i], isActive: !v.isActive };
                  setPeriodoFilter(aux);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  }, [isFilterPeriodoHidden, periodoFilter]);

  const navHeight = document.getElementById('Main-menu')?.clientHeight || 0;
  const isCustomWidth = useMemo(
    () => windowInnerWidth < 940,
    [windowInnerWidth],
  );

  const getMaxAvailableDay = (): Date => {
    const aux = new Date();
    aux.setDate(aux.getDate() * 36);
    return aux;
  };

  const reagendamentoLength = useMemo(() => {
    return reagendamentos.length;
  }, [reagendamentos]);

  return (
    <Sidebar
      onHide={barraLateralDisclosure.close}
      visible={barraLateralDisclosure.isOpen}
      id="BarraLateralMenuAgenda"
      appendTo="self"
      modal={false}
      showCloseIcon={false}
      fullScreen={isTablet}
      baseZIndex={isTablet ? 9999 : 9}
      style={{
        width: isCustomWidth ? '38vw' : '35vw',
        height: `calc(100vh - ${navHeight}px)`,
        marginTop: 'auto',
      }}
    >
      <div className="menu-agenda-content p-d-flex p-flex-column p-jc-between">
        {!menu && (
          <div className="p-d-flex p-flex-column p-gap-3 p-ai-center">
            <Button
              label="Novo agendamento"
              icon="pi pi-plus"
              btnType="pill"
              stretch
              onClick={() => openSelectedHorarioDisponivelModal({})}
            />
            <Button
              label="Ver lista de espera"
              btnType="ghost"
              onClick={() => {
                setMenu('LISTA_ESPERA');
              }}
              stretch
              style={{
                maxWidth: '300px',
              }}
            />
            <Button
              label="Reagendamentos"
              btnType="ghost"
              onClick={() => {
                setMenu('REAGENDAMENTOS');
              }}
              stretch
              badgePos="right"
              badgeClassName="badge-reagendamento"
              style={{
                maxWidth: '300px',
              }}
              {...(!!reagendamentoLength && {
                badge: reagendamentoLength.toString(),
              })}
            />

            <Separator className="p-my-0 p-py-0" />

            <Calendar
              maxDate={getMaxAvailableDay()}
              value={selectedDate}
              onChange={e => {
                if (e.value && !Array.isArray(e.value)) {
                  onChangeSelectedDate(e.value);
                }
              }}
            />

            <Separator />

            <div className="p-d-flex p-flex-column p-col-12">
              <SimpleText>Filtrar agenda por:</SimpleText>
              {statusFilters()}
              {periodoFilters()}
            </div>

            <Separator />
          </div>
        )}

        {menu === 'LISTA_ESPERA' && (
          <BarraLateralMenuAgendaListaEspera
            onClose={() => setMenu(null)}
            openSelectedHorarioDisponivelModal={
              openSelectedHorarioDisponivelModal
            }
          />
        )}
        {menu === 'REAGENDAMENTOS' && (
          <BarraLateralMenuAgendaReagendar close={() => setMenu(null)} />
        )}

        <div className="p-d-flex p-jc-center p-pb-3">
          <Button
            btnType="ghost"
            label="Ocultar painel lateral"
            icon="pi pi-angle-double-left"
            onClick={() => {
              barraLateralDisclosure.close();
            }}
          />
        </div>
      </div>

      {/* {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )} */}
    </Sidebar>
  );
};
