import React, { useEffect, useRef, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import dayjs from 'dayjs';
import TermoConsentimentoPacienteAPI from 'src/APIs/PacienteAPI/TermoConsentimentoPacienteAPI/TermoConsentimentoPacienteAPI';
import FileUploadAPI from 'src/APIs/StorageAPI/FileUpload/FileUploadAPI';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Toast from 'src/components/Basics/Toast/Toast';
import FormInput from 'src/components/FormInput/FormInput';

import { formatData } from '../../../../../helpers';

const situacaoOptions = [
  { label: 'Autorizado', value: 'AUTORIZADO' },
  { label: 'Não autorizado', value: 'NAO_AUTORIZADO' },
  { label: 'Revogado', value: 'REVOGADO' },
];

type FormData = {
  dataOcorrencia: Date | string;
  situacao: 'AUTORIZADO' | 'NAO_AUTORIZADO' | 'REVOGADO';
  respLegal: boolean;
  descricaoDocumento?: string;
  idVinculoFamiliarRespLegal?: number;
  cnsRespLegal?: string;
  cpfRespLegal?: string;
  nomeRespLegal?: string;
};

interface AnexarConsentimentoProps extends DisclosureType {
  state?: ITermoConsentimentoItem;
  setNovoTermo: (termo: ITermoConsentimentoItem) => void;
  nextStep: () => void;
  reloadList: () => void;
}

const AnexarConsentimento = ({
  nextStep,
  setNovoTermo,
  state,
  close,
  reloadList,
}: AnexarConsentimentoProps) => {
  const { idEmpresa } = useAppSelector((state: RootState) => state.user);
  const { idPaciente } = useParams();

  const [arquivoAnexo, setArquivoAnexo] = useState<File | null>(null);
  const [filePath, setFilePath] = useState<string>(state?.documento || '');
  const [loading, setLoading] = useState(false);
  const [vinculoFamiliarOptions, setVinculoFamiliarOptions] = useState<any>([]);

  const fetchVinculoFamiliarData = async () => {
    try {
      const response = await UtilsAPI.getVinculoFamiliar();
      setVinculoFamiliarOptions(formatData(response, 'descricao', 'id'));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchVinculoFamiliarData();
  }, []);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleSelectFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current?.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setArquivoAnexo(file);
      try {
        setLoading(true);

        const dataFile: FileUpload = {
          fileName: file?.name,
          contentType: file?.type,
          contentLength: file?.size,
          resourceType: 'OUTROS',
          resourceId: idEmpresa,
        };
        const responseGoogleStorage = await FileUploadAPI.sendFileUpload(
          dataFile,
          { hideToast: true, throwError: true },
        );
        const urlGoogle = responseGoogleStorage?.data?.uploadUrl;

        const response: any = await axios.put(urlGoogle, file, {
          headers: {
            'Content-Type': file?.type,
          },
        });
        if (response?.status === 200) {
          return setFilePath(responseGoogleStorage?.data?.filePath);
        }
        return toast(<Toast />, {
          data: {
            title: 'Erro ao enviar arquivo',
            message:
              'Ocorreu um erro ao enviar o arquivo para o servidor. Tente novamente.',
            type: 'error',
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleExcluirArquivo = () => {
    setArquivoAnexo(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setFilePath('');
  };

  const validationSchema = Yup.object().shape({
    dataOcorrencia: Yup.date()
      .required('Campo obrigatório')
      .typeError('Data inválida'),
    situacao: Yup.string()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
  });

  const form = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      respLegal: state?.respLegal || false,
      ...state,
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting, errors },
  } = form;

  const watchRespLegal = watch('respLegal');

  useEffect(() => {
    if (!state) return;
    reset({
      ...state,
    });
    setFilePath(state?.documento || '');
  }, [state, reset]);

  const onSubmit = async (data: FormData) => {
    const payload: any = {
      ...state,
      idPaciente: Number(idPaciente),
      nomeDocumento: arquivoAnexo?.name,
      documento: filePath,
      ...data,
    };

    if (payload.id) {
      try {
        await TermoConsentimentoPacienteAPI.updateTermoConsentimento(payload);
        close();
        return reloadList();
      } catch (error) {
        return console.error(error);
      }
    }

    setNovoTermo(payload);
    nextStep();
  };

  return (
    <FormProvider {...form}>
      <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
        <CalendarInputControlled
          name="dataOcorrencia"
          control={control}
          placeholder={
            state?.dataOcorrencia
              ? dayjs(new Date(state.dataOcorrencia)).format('DD/MM/YYYY')
              : 'Selecione a data'
          }
          label="Data da ocorrência"
          className="p-col-12 p-md-6"
          showIcon
          errorMsg={errors.dataOcorrencia?.message}
          disabled={state ? true : false}
        />

        <DropdownControlled
          control={control}
          name="situacao"
          label="Situação"
          options={situacaoOptions}
          className="p-col-12 p-md-6"
          errorMsg={errors.situacao?.message}
          disabled={state?.situacao === 'REVOGADO' ? true : false}
        />

        <TextareaInputControlled
          className="p-col-12"
          control={control}
          name="descricaoDocumento"
          label="Descrição"
          maxLength={255}
          disabled={state ? true : false}
        />

        <div className="p-d-flex p-flex-column p-gap-2 p-px-2 p-col-12">
          <CheckboxControlled
            control={control}
            name="respLegal"
            label="Responsável legal"
            disabled={state ? true : false}
          />
          {watchRespLegal && (
            <div className="p-grid p-col-12 p-px-0">
              <FormInput
                name="nomeRespLegal"
                label="Nome do responsável legal"
                maxLength={100}
                className="p-col-12 p-sm-6 p-md-4"
                disabled={state ? true : false}
              />

              <MaskedInputControlled
                control={control}
                name="cpfRespLegal"
                label="CPF"
                placeholder="000.000.000-00"
                mask={MASK.CPF}
                className="p-col-12 p-sm-6 p-md-4"
                disabled={state ? true : false}
              />

              <FormInput
                type="number"
                name="cnsRespLegal"
                label="CNS"
                maxLength={15}
                className="p-col-12 p-sm-6 p-md-4"
                disabled={state ? true : false}
              />

              <DropdownControlled
                className="p-col-12 p-sm-6 p-md-4"
                control={control}
                name="idVinculoFamiliarRespLegal"
                label="Grau de parentesco"
                options={vinculoFamiliarOptions}
                disabled={state ? true : false}
              />
            </div>
          )}

          <div>
            <Button
              type="button"
              label="Escolher arquivo"
              btnType="tonal"
              onClick={() => handleSelectFile()}
              disabled={state ? true : false}
            />
          </div>

          <AlertBox
            type="WARN"
            text="Nenhum arquivo anexo"
            visible={!arquivoAnexo && !filePath}
          />

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".pdf, image/*"
            onChange={handleFileChange}
          />

          {(arquivoAnexo || filePath) && (
            <div className="p-d-flex p-ai-center p-gap-2 arquivo-anexo">
              <SimpleText
                className=" p-d-flex p-ai-center p-gap-1 border-arquivo"
                fontColor="color_60"
                ellipsisOverflow
              >
                <i className="fas fa-file" />{' '}
                {arquivoAnexo?.name || state?.nomeDocumento || ''}
              </SimpleText>
              <Button
                btnType="danger-link"
                label="Excluir"
                onClick={() => handleExcluirArquivo()}
                disabled={state ? true : false}
              />
            </div>
          )}
        </div>

        <div className="p-grid p-col-12 p-md-6 p-lg-5 p-xl-4  p-px-0">
          <div className="p-col-6">
            <Button
              type="button"
              label="Cancelar"
              btnType="ghost"
              onClick={() => [close()]}
              stretch
            />
          </div>
          <div className="p-col-6">
            <Button
              type="button"
              onClick={handleSubmit(onSubmit)}
              label={state ? 'Atualizar' : 'Avançar'}
              stretch
              disabled={!arquivoAnexo && !filePath}
              loading={loading || isSubmitting}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default AnexarConsentimento;
