import PanelContent from './components/PanelContent';
import PanelHeader from './components/PanelHeader';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import { useSelectedContaPagar } from '../../useSelectedContaPagar';

interface DesktopRowProps {
  item: IContaPagarItem;
  authorizedActions: any;
  chipStatus: string;
}

const DesktopRow = ({
  item,
  authorizedActions,
  chipStatus,
}: DesktopRowProps) => {
  const { selectedContaPagar, setSelectedContaPagar } = useSelectedContaPagar();

  return (
    <div className="p-my-1">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            item={item}
            selectedContaPagar={selectedContaPagar}
            setSelectedContaPagar={setSelectedContaPagar}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            authorizedActions={authorizedActions}
            chipStatus={chipStatus}
          />
        )}
      >
        <PanelContent item={item} />
      </Panel>
    </div>
  );
};

export default DesktopRow;
