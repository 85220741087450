import { useMemo } from 'react';

import { Line } from 'react-chartjs-2';

import { getCssVariable } from 'src/utils/utils';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

type Data = {
  labels: string[];
  values: number[];
};

type IndicadoresLineChartProps = {
  data: Data;
};

export const IndicadoresLineChart = ({ data }: IndicadoresLineChartProps) => {
  const chartColors: { border: string } = {
    border: getCssVariable('--primary-color'),
  };

  const chartMonth = useMemo(() => {
    const firstMonth = data.labels?.[0] || '';
    const lastMonth = data.labels?.[data.labels.length - 1] || '';
    return `${firstMonth} - ${lastMonth}`;
  }, [data.labels]);

  return (
    <div
      className="row content-row p-flex-column white p-w-100 p-gap-2 p-p-2"
      style={{
        height: '280px',
        border: '1px solid var(--neutral-color-grayscale-88)',
      }}
    >
      <SimpleText medium>{chartMonth}</SimpleText>
      <Line
        className="p-mb-4"
        data={{
          labels: data.labels,
          datasets: [
            {
              data: data.values,
              borderColor: chartColors.border,
              backgroundColor: ({ chart: { ctx } }) => {
                const gradient = ctx.createLinearGradient(0, 0, 0, 280);
                gradient.addColorStop(0, '#54A275');
                gradient.addColorStop(1, 'rgba(84, 162, 117, 0)');
                return gradient;
              },
              tension: 0.1,
              fill: true,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          },
          elements: {
            line: {
              fill: true,
            },
          },
        }}
      />
    </div>
  );
};
