import { useState } from 'react';

import { ParametrizacaoAgravoAPI } from 'src/APIs/ReportAPI/RelatorioVigilanciaAPI/ParametrizacaoAgravoAPI/ParametrizacaoAgravoAPI';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import DialogAdicionarAgravo from './components/DialogAdicionarAgravo';
import Header from './components/Header';
import Row from './components/Row';
import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

const ParametrizacaoAgravo = () => {
  const [reload, setReload] = useState(0);
  const adicionarAgravoDialog = useDisclosure({ opened: false });

  const reloadList = () => {
    setReload(prev => prev + 1);
  };

  const fetchApi = async ({ query, ...params }: any): Promise<any> => {
    try {
      const response = await ParametrizacaoAgravoAPI.getAgravoList({
        ...(query && { nome: query }),
        ...params,
      });
      return response || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const renderRow = (item: IParamAgravoItem) => {
    return <Row item={item} adicionarAgravoDialog={adicionarAgravoDialog} />;
  };

  return (
    <SearchList.Root fetchApi={fetchApi}>
      <div className="p-w-100">
        <SearchList.BorderContainer>
          <div className="p-d-flex p-flex-1 p-jc-between p-gap-3 p-p-2">
            <div className="p-d-flex p-flex-column p-w-100">
              <SimpleText medium>Pesquisar</SimpleText>
              <SearchList.SearchInput
                placeholder="Pesquise um agravo"
                className="p-col-12 p-pl-0"
              />
            </div>
            <div className="p-as-center">
              <Button
                label="Adicionar agravo"
                icon="pi pi-plus"
                btnType="pill"
                iconPos="left"
                onClick={() => adicionarAgravoDialog.open()}
                style={{ width: 'max-content' }}
                className="p-mt-2"
                checkPermission={'ADM_PARAMETRIZACAO_AGRAVO_CADASTRAR'}
              />
            </div>
          </div>

          <Header />
          <SearchList.InfiniteScroll
            reload={reload}
            renderRow={renderRow}
            customEmptyText={
              <div className="content-empty p-d-flex p-flex-column p-ai-center p-jc-center p-mt-2 p-py-4">
                <SimpleText
                  className="p-text-center"
                  style={{ width: '400px' }}
                  fontSize="sm"
                  fontColor="color_40"
                >
                  <span className="color-60">
                    Nenhuma informação encontrada!
                  </span>{' '}
                  <br />
                  <br /> Utilize os filtros ou faça uma pesquisa para encontrar
                  os dados da parametrização. Os resultados da pesquisa irão
                  aparecer aqui.
                </SimpleText>
              </div>
            }
          />
        </SearchList.BorderContainer>
      </div>
      {adicionarAgravoDialog.isOpen ? (
        <DialogAdicionarAgravo
          {...adicionarAgravoDialog}
          reloadList={reloadList}
        />
      ) : (
        <></>
      )}
    </SearchList.Root>
  );
};

export default ParametrizacaoAgravo;
