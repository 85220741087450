import React from 'react';

import {
  Skeleton as PrimeSkeleton,
  SkeletonProps as PrimeSkeletonProps,
} from 'primereact/skeleton';

interface SkeletonProps extends PrimeSkeletonProps {
  loading: boolean;
  children?: JSX.Element[] | JSX.Element;
}

function Skeleton({ loading, children, ...props }: SkeletonProps) {
  if (loading) return <PrimeSkeleton {...props} />;

  if (!children) return null;

  const childrenArray = Array.isArray(children) ? children : [children];

  return <>{childrenArray.map(el => el) || null}</>;
}

export default Skeleton;
