import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  dataVencimentoInicio: Yup.date().typeError('Informe uma data válida').nullable(),
  dataVencimentoFim: Yup.date().when('dataInicial', {
    is: (dataInicial: Date) => !!dataInicial,
    then: Yup.date()
      .min(
        Yup.ref('dataInicial'),
        'A data final deve ser maior que a data inicial',
      )
      .required('Informe a data final')
      .nullable(),
    otherwise: Yup.date().nullable(),
  }),
});

export default validationSchema;