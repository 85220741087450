import React, { memo, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import PendenciasAuditoriaAPI from 'src/APIs/AdminAPI/PendenciasAuditoriaAPI/PendenciasAuditoriaAPI';

import { useUser } from 'src/core/hooks/User/User';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { downloadBase64PDF } from 'src/utils/files';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

interface ChatProps {
  mensagens: IMsgPendenciaAuditoriaDTO[];
  loading: boolean;
}

const MensagensChat = ({ mensagens, loading }: ChatProps) => {
  const { user } = useUser();

  const isMoreThan20MinutesApart = (
    dateStr1: string,
    dateStr2: string,
  ): boolean => {
    if (!dateStr1 || !dateStr2) return false;
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);
    const diffInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
    const diffInMinutes = diffInMilliseconds / (1000 * 60);
    return diffInMinutes > 20;
  };

  useEffect(() => {
    const div = document.getElementById('mensagens-chat');
    if (div) {
      div.scrollTop = div.scrollHeight;
    }
  });

  return (
    <div id="mensagens-chat" className="p-h-100 p-d-flex p-flex-column">
      {mensagens?.map((mensagem: IMsgPendenciaAuditoriaDTO, index: number) => (
        <div
          key={index}
          className={`p-d-flex p-flex-column p-gap-1 p-mb-1 
                ${mensagem.nomeUsuario === user?.nome ? 'enviado' : 'recebido'}
              `}
        >
          {((mensagens[index - 1] as any)?.nomeUsuario !==
            mensagem.nomeUsuario ||
            isMoreThan20MinutesApart(
              (mensagens[index - 1] as any)?.dataEnvio,
              mensagem.dataEnvio,
            )) && (
            <SimpleText
              fontSize="xxxs"
              fontColor="color_60"
              className="data p-mt-2 p-px-1"
            >
              {mensagem.dataEnvio
                ? dayjs(new Date(mensagem.dataEnvio)).format('DD MMM,  HH:mm')
                : ''}
            </SimpleText>
          )}
          <SimpleText
            className={`mensagem ${mensagem.arquivo ? 'arquivo' : ''}`}
            fontSize="xs"
            fontColor="color_16"
            onClick={() =>
              mensagem.arquivo
                ? downloadBase64PDF(
                    mensagem.arquivo,
                    mensagem.fileName || 'arquivo',
                  )
                : {}
            }
          >
            {mensagem.fileName || mensagem.mensagem || ''}
          </SimpleText>
        </div>
      ))}
    </div>
  );
};

export default memo(MensagensChat);
