import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AgendaState {
  profissionaisDisponiveis?: GetUsuarioDTO[];
  profissionalAtivo?: GetUsuarioDTO | undefined;
  agendamento?: AtendimentoAgendamento | null;
}

const initialState: AgendaState = {};

export const agendaSlice = createSlice({
  name: 'agenda',
  initialState,
  reducers: {
    setProfissionaisDisponiveis: (
      state: AgendaState,
      action: PayloadAction<GetUsuarioDTO[]>,
    ) => {
      state.profissionaisDisponiveis = action.payload;
    },
    setProfissionalAtivo: (
      state: AgendaState,
      action: PayloadAction<GetUsuarioDTO | undefined>,
    ) => {
      return { ...state, profissionalAtivo: action.payload };
    },
    setAgendamento: (
      state: AgendaState,
      action: PayloadAction<AtendimentoAgendamento | null>,
    ) => {
      state.agendamento = action.payload;
    },
    clearProfissionais: (state: AgendaState) => {
      Object.assign(state, {});
      return {};
    },
  },
});

export const {
  setProfissionaisDisponiveis,
  setProfissionalAtivo,
  setAgendamento,
  clearProfissionais,
} = agendaSlice.actions;

export default agendaSlice.reducer;