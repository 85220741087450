import { useState } from 'react';

import AutorizacaoAPI from 'src/APIs/AgendaAPI/AutorizacaoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import VerificationCodeInput from 'src/components/Basics/VerificationCodeInput/VerificationCodeInput';

import { useAgenda } from '../../AgendaContext';

import { SendTokenBtn } from './SendTokenBtn';

import './TokenSMS.scss';

interface TokenSMSProps {
  idEspecialidade: number;
  idSala: number;
  onHide: () => void;
  cartaoSelected: any;
}

export const TokenSMS = (props: TokenSMSProps) => {
  const { idEspecialidade, idSala, onHide, cartaoSelected } = props;

  const { agendamento } = useAgenda();
  const { user } = useAppSelector((state: RootState) => state);

  const [token, setToken] = useState('');

  const paciente = agendamento?.paciente;

  const handleValidateToken = async () => {
    if (!agendamento?.id) return;
    try {
      const payload = {
        flagsAtendimentos: agendamento?.flagsAtivas,
        status: 'PRESENTE',
        token: token,
        idEmpresa: user.idEmpresa,
        idEspecialidade,
        idSala,
        tipoValidacao: 'SMS',
        numeroCartao: cartaoSelected?.numeroCartao,
        idConvenio: cartaoSelected?.convenio?.id,
        idPaciente: agendamento?.idPaciente,
      };

      await AutorizacaoAPI.realizarPresenca(agendamento?.id, payload, {
        throwError: true,
      });

      onHide();
    } catch (error) {}

    return;
  };

  if (!paciente?.celularParticular)
    return (
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.DANGER}>
        O paciente não possui celular cadastrado.
      </SimpleText>
    );

  return (
    <div
      id="token-sms-container"
      className="p-grid p-align-center p-justify-center"
    >
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_40}>
        Solicite e digite o token que o paciente recebeu no celular com o final{' '}
        <SimpleText medium>{paciente?.celular?.slice(7)}</SimpleText>
      </SimpleText>
      <SendTokenBtn paciente={paciente} />

      <div className="p-col-12 p-sm-8 p-d-flex p-jc-center">
        <VerificationCodeInput
          name="token"
          length={6}
          setFieldValue={(_: any, value: any) => setToken(value)}
          setFieldTouched={handleValidateToken}
        />
      </div>
    </div>
  );
};
