import { useMemo, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectButtonChangeParams } from 'primereact/selectbutton';

import dayjs from 'dayjs';

import { InputText } from 'src/components/Basics/Input';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Calendar, { CalendarProps } from 'src/components/Calendar/Calendar';
import SelectButton from 'src/components/SelectButton/SelectButton';

type SelectButtonWithActionSettings = {
  search?: {
    list: ListItemProps[];
  };
  date?: CalendarProps;
  custom?: ({
    overlayRef,
    onValueChange,
  }: {
    overlayRef: React.RefObject<OverlayPanel>;
    onValueChange: (v: ListItemProps) => void;
  }) => React.ReactNode;
  overlayStyle?: React.CSSProperties;
};

type ListItemProps = {
  label: string;
  value: any;
};

type SelectButtonWithActionProps = {
  onChange?: (e: SelectButtonChangeParams) => void;
  onActionChange?: (e: any) => void;
  type?: 'date' | 'dateRange' | 'search' | 'custom';
  icon?: string;
  settings?: SelectButtonWithActionSettings;
  className?: string;
  options: ListItemProps[];
  value: any;
};

export const SelectButtonWithAction = ({
  type = 'date',
  onChange,
  onActionChange,
  settings,
  className = '',
  options = [],
  value: initialValue,
  ...props
}: SelectButtonWithActionProps) => {
  const [value, setValue] = useState(initialValue);
  const [actionValue, setActionValue] = useState<any>(undefined);

  const ref = useRef<OverlayPanel>(null);

  const handleChange = (e: SelectButtonChangeParams) => {
    setValue(e.value);
    onChange?.(e);

    if (e.value === 'action') {
      ref.current?.toggle(e.originalEvent);
    }
  };

  const getlabel: string = useMemo(() => {
    if (!actionValue) {
      return '';
    }

    if (type === 'search' || type === 'custom') {
      return actionValue.label;
    }

    if (Array.isArray(actionValue)) {
      if (type === 'dateRange' && !actionValue[1]) {
        return dayjs(actionValue[0]).format('DD/MM/YYYY');
      }

      return `${dayjs(actionValue[0]).format('DD/MM/YYYY')} - ${dayjs(
        actionValue[1],
      ).format('DD/MM/YYYY')}`;
    }

    return dayjs(actionValue).format('DD/MM/YYYY');
  }, [actionValue, type]);

  const icon = useMemo(() => {
    if (props.icon) {
      return props.icon;
    }
    if (type === 'date' || type === 'dateRange') {
      return 'fas fa-calendar';
    }
    return 'fas fa-search';
  }, [props.icon, type]);

  const models = [
    ...options,
    {
      label: (
        <>
          <i className={icon} /> &nbsp; {getlabel}
        </>
      ),
      value: 'action',
    },
  ];

  return (
    <>
      <SelectButton
        className={className}
        value={value}
        onChange={handleChange}
        unselectable
        options={models}
      />

      <OverlayPanel
        ref={ref}
        onHide={() => !actionValue && setValue('')}
        style={{
          width: type === 'search' ? '320px' : 'auto',
          maxHeight: type === 'search' ? '400px' : 'unset',
          overflowY: 'auto',
          ...settings?.overlayStyle,
        }}
      >
        {['date', 'dateRange'].includes(type) && (
          <Calendar
            {...settings?.date}
            {...(type === 'dateRange' && { selectionMode: 'range' })}
            value={(actionValue as any) || new Date()}
            onChange={e => [setActionValue(e.value), onActionChange?.(e.value)]}
          />
        )}

        {type === 'search' && (
          <SearhPanel
            list={settings?.search?.list || []}
            setActionValue={v => [
              setActionValue(v),
              onActionChange?.(v.value),
              ref.current?.hide(),
            ]}
            actionValue={actionValue}
          />
        )}

        {type === 'custom' &&
          settings?.custom?.({
            overlayRef: ref,
            onValueChange: setActionValue,
          })}
      </OverlayPanel>
    </>
  );
};

const SearhPanel = ({
  list = [],
  setActionValue,
  actionValue,
}: {
  list: ListItemProps[];
  setActionValue(v: any): void;
  actionValue: any;
}) => {
  const [value, setValue] = useState('');

  const filteredList = useMemo(() => {
    if (!list) {
      return [];
    }

    return list?.filter(item =>
      item.label.toLowerCase().includes(value.toLowerCase()),
    );
  }, [list, value]);

  const handleSelect = (item: any) => {
    setActionValue(item);
  };

  return (
    <div className="p-d-flex p-flex-column">
      <InputText
        className="p-col-12 p-p-0 input-search"
        icon="fas fa-search"
        iconPos="left"
        onChange={e => setValue(e.target.value)}
        value={value}
        placeholder="Digite para pesquisar"
      />

      <div className="p-d-flex p-flex-column p-gap-1">
        {!!list.length &&
          !value &&
          list?.map((item, idx) => (
            <div
              key={idx}
              className="p-col-12 row content-row hover"
              data-checked={actionValue?.value === item.value}
              onClick={() => handleSelect(item)}
            >
              <SimpleText>{item.label}</SimpleText>
            </div>
          ))}

        {!!value &&
          !!filteredList.length &&
          filteredList.map((item, idx) => (
            <div
              key={idx}
              className="p-col-12 row content-row hover"
              data-checked={actionValue?.value === item.value}
              onClick={() => handleSelect(item)}
            >
              <SimpleText>{item.label}</SimpleText>
            </div>
          ))}

        {!list.length ||
          (!filteredList.length && !!value && !!list.length && (
            <div
              className="p-col-12 row content-row p-jc-center p-ai-center p-p-2"
              style={{
                height: '60px',
              }}
            >
              <SimpleText>Nenhum resultado encontrado</SimpleText>
            </div>
          ))}
      </div>
    </div>
  );
};
