import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { useDispatch } from 'react-redux';
import { useSubscription } from 'react-stomp-hooks';

import dayjs from 'dayjs';
import { ChatAPI } from 'src/APIs/ChatAPI/ChatAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import {
  chatUserStatus,
  ChatUserStatus,
  chatUserStatusRequest,
} from '../helpers';

interface ChatContextType {
  messages: IChatMessageItem[];
  addMessage: (message: IChatMessageItem[]) => void;
  userStatus: ChatUserStatus;
  chatOverlay: OverlayPanel;
  isMessageOpened: boolean;
  setIsMessageOpened: (isOpened: boolean) => void;
  inputTextHeight: number;
  setInputTextHeight: (height: number) => void;
  openedMessageUser: IChatUsuario | null;
  setOpenedMessageUser: (user: IChatUsuario | null) => void;
  cleanMessages: () => void;
  handleSendMessage: (message: string) => void;
  handleReloadChatList: () => void;
  reloadChatList: number;
  handleReloadChatMessage: () => void;
  reloadChatMessage: number;
  handleChangeStatus: (status: ChatUserStatus) => void;
  isMessageLoading: boolean;
  setIsMessageLoading: (isLoading: boolean) => void;
  isOverlayOpened: boolean;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

interface ChatProviderProps {
  children: ReactNode;
  chatOverlay: OverlayPanel;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({
  children,
  chatOverlay,
}) => {
  const { idUsuario } = useAppSelector(state => state.user);
  const dispatch = useDispatch();

  const [userStatus, setUserStatus] = useState<ChatUserStatus>('offline');
  const [reloadChatList, setReloadChatList] = useState(0);

  const [isMessageOpened, setIsMessageOpened] = useState(false);
  const [openedMessageUser, setOpenedMessageUser] =
    useState<IChatUsuario | null>(null);
  const [messages, setMessages] = useState<IChatMessageItem[]>([]);
  const [reloadChatMessage, setReloadChatMessage] = useState(0);
  const [isMessageLoading, setIsMessageLoading] = useState(false);

  const [inputTextHeight, setInputTextHeight] = useState(33);

  const isOverlayOpened = useMemo(
    () => chatOverlay?.state?.visible,
    [chatOverlay?.state],
  );

  const handleReloadChatList = () => {
    setReloadChatList(currentReload => currentReload + 1);
  };
  const handleReloadChatMessage = () => {
    setReloadChatMessage(currentReload => currentReload + 1);
  };

  const addMessage = (message: IChatMessageItem[]) => {
    //* Add new messages to the bottom of the list
    setMessages(prevMessages => [...prevMessages, ...message]);
  };
  const cleanMessages = () => {
    setMessages([]);
  };

  const handleChangeStatus = async (status: ChatUserStatus) => {
    setUserStatus(status);

    try {
      await ChatAPI.toggleChatStatus(
        chatUserStatusRequest[status] || 'OFFLINE',
        { throwError: true },
      );
    } catch {
      setUserStatus('offline');
    }
  };

  const handleSendMessage = async (message: string) => {
    if (!openedMessageUser) return;

    const messageIdentifier = Math.floor(Math.random() * 1000);

    const messageData: IChatMessageItem = {
      id: messageIdentifier,
      mensagem: message,
      idUsuarioInclusao: idUsuario,
      idUsuarioEnvio: idUsuario,
      status: 'ENVIADA',
      idUsuarioDestino: openedMessageUser.id,
      dataInclusao: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
    };

    //* Add message to the top of the list
    setMessages(prev => [messageData, ...prev]);

    try {
      await ChatAPI.sendChatMensagem(
        {
          idUsuarioDestino: openedMessageUser.id,
          mensagem: message,
        },
        { throwError: true },
      );

      //* Reload chat list to update the last sended message
      handleReloadChatList();
    } catch {
      //* Undo message if it fails
      setMessages(prevMessages =>
        prevMessages.filter(msg => msg.id !== messageIdentifier),
      );
    }
  };

  const fetchChatStatus = useCallback(async (): Promise<ChatUserStatus> => {
    try {
      const response = await ChatAPI.loadChatStatus({ throwError: true });
      return response.status;
    } catch {
      return 'offline';
    }
  }, []);

  const handleReadMessages = useCallback(async () => {
    if (!openedMessageUser?.id) return;

    await ChatAPI.sendLerMensagem(
      { idUsuarioDestino: openedMessageUser.id },
      { throwError: true },
    );
  }, [openedMessageUser?.id]);

  useEffect(() => {
    fetchChatStatus().then(
      status => status && setUserStatus(chatUserStatus[status] || 'offline'),
    );
  }, [fetchChatStatus]);

  useEffect(() => {
    if (!isMessageOpened && openedMessageUser) {
      setTimeout(() => setOpenedMessageUser(null), 350);
    }
  }, [isMessageOpened, openedMessageUser]);

  useSubscription('/user/topic/notificacao-chat', async (message: any) => {
    const data = JSON.parse(message.body);

    if (data.funcionalidade === 'CHAT') {
      if (
        openedMessageUser?.id === data.chatMensagem?.idUsuarioEnvio &&
        isOverlayOpened
      ) {
        setMessages(prev => [data.chatMensagem, ...prev]);
        await handleReadMessages();
      }

      handleReloadChatList();
      dispatch(setInvalidateQuery({ invalidateNotificacoesChat: true }));
    }
  });

  return (
    <ChatContext.Provider
      value={{
        messages,
        addMessage,
        chatOverlay,
        userStatus,
        isMessageOpened,
        inputTextHeight,
        setInputTextHeight,
        openedMessageUser,
        setOpenedMessageUser,
        setIsMessageOpened,
        cleanMessages,
        handleSendMessage,
        reloadChatList,
        handleReloadChatList,
        handleChangeStatus,
        handleReloadChatMessage,
        reloadChatMessage,
        isMessageLoading,
        setIsMessageLoading,
        isOverlayOpened,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};
