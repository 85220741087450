import React, { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';
import TipoContaReceberAPI from 'src/APIs/FinanceiroAPI/TipoContaReceberAPI/TipoContaReceberAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import ConsultorioMultiSelect from '../components/ConsultorioMultiSelect';
import { Button } from 'src/components/_UI';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import MultiSelect from 'src/components/Basics/MultiSelect/MultiSelect';
import Page from 'src/components/Basics/Page/Page';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import Header from './TipoContaReceberRow/Header';
import Row from './TipoContaReceberRow/Row';

const TipoContaReceber = () => {
  const [reload, setReload] = useState(0);
  const navigate = useNavigate();
  const { isMobile, isTablet } = useSize();

  const useFormMethods = useForm();

  const { getValues } = useFormMethods;

  const fetchApi = async ({ query, ...params }: any): Promise<any> => {
    const idsConsultorio = getValues('idConsultorio') || [];
    const response = await TipoContaReceberAPI.getTipoContaReceber({
      nome: query,
      ...(idsConsultorio.length > 0 &&
        !idsConsultorio.includes('todos') && {
          idsConsultorios: idsConsultorio.join(','),
        }),
      ...params,
    });

    return response || [];
  };

  const reloadList = () => {
    setReload(prev => prev + 1);
  };

  const renderRow = (data: any) => {
    return <Row data={data} reloadList={reloadList} />;
  };

  return (
    <Page white content title="Tipo de conta a Receber" className="p-px-0">
      <>
        <SimpleText className="p-m-0 p-py-0" fontSize={FONT_SIZE.MD} medium>
          Tipo de conta a Receber
        </SimpleText>
        <SearchList.Root fetchApi={fetchApi}>
          <FormProvider {...useFormMethods}>
            <form className="p-grid p-ai-end p-my-2 p-gap-1">
              <SearchList.SearchInput
                placeholder="Pesquise um tipo de conta a Receber"
                className=" p-col-12 p-md-5 p-lg-4 p-mb-1"
              />
              <ConsultorioMultiSelect className="p-col-12 p-md-5 p-lg-4" />
              <div className={`p-col-12 p-sm-3 p-md-2 p-lg-1`}>
                <Button
                  label="Filtrar"
                  btnType="tonal"
                  onClick={() => reloadList()}
                  type="button"
                  stretch
                />
              </div>
            </form>
          </FormProvider>
          <SearchList.BorderContainer>
            <Button
              className="p-mb-2"
              label="Novo tipo de conta a Receber"
              icon="pi pi-plus"
              iconPos="left"
              btnType="pill"
              onClick={() => navigate('/faturamento/tipo-conta-Receber/novo')}
              stretch={isMobile}
            />
            {!isTablet && <Header />}

            <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
          </SearchList.BorderContainer>
        </SearchList.Root>
      </>
    </Page>
  );
};

export default TipoContaReceber;
