import { ReactNode, useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router';

import { ProtocoloAtendimentoAPI } from 'src/APIs/AdminAPI/ProtocoloAtendimentoAPI/ProtocoloAtendimentoAPI';
import AtendimentoAPI from 'src/APIs/AgendaAPI/Atendimento/AtendimentoAPI';
import ComorbidadeAPI from 'src/APIs/ProntuarioAPI/ComorbidadeAPI/ComorbidadeAPI';
import { PrescricaoAPI } from 'src/APIs/ProntuarioAPI/PrescricaoAPI/PrescricaoAPI';
import ProcedimentosSeriadosAPI from 'src/APIs/ProntuarioAPI/ProcedimentosSeriadosAPI/ProcedimentosSeriadosAPI';
import ProntuarioInfoAPI from 'src/APIs/ProntuarioAPI/ProntuarioInfo/ProntuarioInfoAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAppDispatch, useAppSelector } from 'src/core/redux/hooks';
import {
  AtendimentoState,
  setAtendimento,
} from 'src/core/redux/slices/atendimento/AtendimentoSlice';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';
import { TempoAtendimentoProps } from 'src/utils/hooks/useTimer';

import { enumToText } from 'src/utils/utils';

import { checkIsRetroativo } from 'src/pages/Emed/Prontuario/FichaPaciente/helpers';

import { AtendimentoContext } from '../../contexts/Atendimento/Atendimento';

interface AtendimentoProviderProps {
  children: ReactNode;
}

type TeleconsultaState = 'INITIAL' | 'STARTED' | 'FINISHED' | 'IDLE';

export function AtendimentoProvider({ children }: AtendimentoProviderProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sairPEPDialog = useDisclosure({ opened: false });
  const infoProtocoloDialog = useDisclosure({ opened: false });
  const protocoloDialog = useDisclosure({ opened: false });

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const isEvolucaoIndividual = !!state?.isEvolucaoIndividual;

  const {
    agenda: { profissionalAtivo },
    query,
    consultorios,
  } = useAppSelector((state: RootState) => state);

  const { removeStoredValue } = useLocalStorage(
    `tempoAtendimento/${idAtendimento}`,
  );

  const [atendimentoStatus, setAtendimentoStatus] = useState<string>('');
  const [idConvenioAtendimento, setIdConvenioAtendimento] = useState<
    number | null
  >(null);
  const [idConvenioProcedimentos, setIdConvenioProcedimentos] = useState<
    number | null
  >(null);
  const [
    numeroCartaoAtendimentoParticular,
    setNumeroCartaoAtendimentoParticular,
  ] = useState<string | null>(null);

  const [infoLembretes, setInfoLembretes] = useState<any>({});
  const [camposPEP, setCamposPEP] = useState<any>([]);
  const [camposProntuario, setCamposProntuario] = useState([]);
  const [showBarraLateral, setShowBarraLateral] = useState(true);
  const [prontuarioPersistente, setProntuarioPersistente] = useState({});
  const [guiasExecucoes, setGuiasExecucoes] = useState<any[]>([]);
  const [modelos, setModelos] = useState<any[]>([]);
  const [isTeleatendimento, setIsTeleatendimento] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [paciente, setPaciente] = useState<any>();
  const [roomName, setRoomName] = useState('');
  const [teleconsultaState, setTeleconsulta] =
    useState<TeleconsultaState>('INITIAL');
  const [listaProblemas, setListaProblemas] = useState<any>([]);
  const [campos, setCampos] = useState<any[]>([]);
  const [requiredJustificativa, setRequiredJustificativa] =
    useState<boolean>(false);
  const [
    camposObrigatoriosNaoPreenchidos,
    setCamposObrigatoriosNaoPreenchidos,
  ] = useState<any[]>([]);
  const [protocolosAtendimento, setProtocolosAtendimento] = useState<any[]>([]);
  const [protocoloAplicado, setProtocoloAplicado] = useState<boolean>(false);

  const modalAgravo = useDisclosure({ opened: false });

  useEffect(() => {
    const getProtocolos = async () => {
      try {
        const response = await ProtocoloAtendimentoAPI.getProtocoloAtendimentos(
          {
            pageSize: 1000,
            incluiInativos: false,
            idsConsultorios: consultorios.ativo.id,
          },
        );
        setProtocolosAtendimento(response.list);
      } catch (error) {
        console.error(error);
      }
    };
    getProtocolos();
  }, []);

  const [isFormularioDirty, setIsFormularioDirty] = useState(false);

  useEffect(() => {
    const camposProntuario = Array.isArray(camposPEP) ? camposPEP : [camposPEP];

    if (camposProntuario.length < 1 || !campos) return;
    const camposObrigatoriosNaoPreenchidos = campos
      ?.filter(campo => campo.obrigatorio)
      .filter(
        campo =>
          !camposProntuario.some(campoPEP =>
            campoPEP[campo.funcionalidade] != null &&
            campoPEP[campo.funcionalidade]?.trim?.() !== ''
              ? true
              : false,
          ),
      )
      .map(campo => enumToText(campo.funcionalidade));

    setCamposObrigatoriosNaoPreenchidos(camposObrigatoriosNaoPreenchidos);
  }, [campos, camposPEP]);

  const checkRequiredJustificativa = useCallback((data: any) => {
    const retroativo = checkIsRetroativo(data.dataOcorrencia, undefined);

    if (
      retroativo &&
      (!data.justificativaOcorrencia ||
        data.justificativaOcorrencia.trim() === '')
    ) {
      setRequiredJustificativa(true);
    } else {
      setRequiredJustificativa(false);
    }
  }, []);

  const getListaProblemas = useCallback(async () => {
    const response = await ComorbidadeAPI.getComorbidades(
      Number(idAtendimento),
      { pageSize: 999 },
    );
    setListaProblemas(response?.list || []);
  }, [idAtendimento]);

  useEffect(() => {
    dispatch(setAtendimento({ sairPEPDialog }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updatedCamposProntuario = Object.keys(camposPEP).reduce(
      (acc: any, campo) => {
        if (campo === 'ALEITAMENTO_ALIMENTACAO') {
          Object.keys(camposPEP[campo]).forEach(valor => {
            acc.push({
              camposModeloProntuario: campo,
              campoExtra: valor,
              valorCampoProntuario: camposPEP[campo][valor],
            });
          });
        } else {
          acc.push({
            camposModeloProntuario: campo,
            valorCampoProntuario: camposPEP[campo],
          });
        }
        return acc;
      },
      [],
    );

    setCamposProntuario(updatedCamposProntuario);
  }, [camposPEP, setCamposProntuario]);

  useEffect(() => {
    if (
      idAtendimento &&
      (atendimentoStatus === 'ATENDENDO' ||
        atendimentoStatus === 'ATENDIDO' ||
        atendimentoStatus === 'CONTINUAR')
    ) {
      UtilsAPI.getProntuario(Number(idAtendimento)).then(data =>
        dispatch(
          setAtendimento({
            ...data.atendimento,
            particular: data?.particular || false,
            retorno: data?.retorno || false,
            tempoGastoAtendimento: data.tempoGastoAtendimento,
          }),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentoStatus]);

  const iniciarAtendimento = useCallback(
    async (
      idAtendimento: number,
      onSaveDataOcorrencia?: (
        idAtendimento: string | number,
        dataOcorrencia: Date,
      ) => void,
    ) => {
      const response = await AtendimentoAPI.iniciarAtendimento(idAtendimento);

      if (response?.status === 200) {
        setAtendimentoStatus(response.data.status);
        onSaveDataOcorrencia?.(response.data.id, new Date());

        if (idAtendimento !== response.data.id) {
          navigate(`/prontuario`, {
            state: {
              idAtendimento: response.data.id,
            },
          });
        }
      }
    },
    [navigate],
  );

  const getCampos = useCallback(async () => {
    if (!idAtendimento) return;
    const response = await UtilsAPI.getCampos(Number(idAtendimento), {});
    setCampos(response);
  }, [idAtendimento]);

  const pausarAtendimento = async (
    atendimento: AtendimentoState,
    tempoAtendimento: TempoAtendimentoProps,
  ) => {
    await AtendimentoAPI.pausarAtendimento(
      {
        idAtendimento: Number(idAtendimento),
        idPaciente: Number(atendimento.paciente?.id),
        idProfissionalSaude: Number(atendimento.medico?.id),
        camposProntuario: camposProntuario,
        tempoGastoAtendimento: String(`${tempoAtendimento.time}`),
      },
      { throwError: true },
    )
      .then(() => {
        tempoAtendimento.pause();

        UtilsAPI.getProntuario(Number(atendimento.id)).then((data: any) => {
          dispatch(
            setAtendimento({
              ...data.atendimento,
              particular: data?.particular || false,
              retorno: data?.retorno || false,
              tempoGastoAtendimento: data.tempoGastoAtendimento,
            }),
          );
          setAtendimentoStatus(data.atendimento.status || '');
        });
      })
      .catch(() => {});
  };

  const finalizarAtendimento = async (idAtendimento: number, payload: any) => {
    const response = await AtendimentoAPI.finalizarAtendimento(
      Number(idAtendimento),
      payload,
    );

    if (response?.status === 200) {
      removeStoredValue();
      setAtendimentoStatus('ATENDIDO');
    }
  };

  const finalizarTriagem = async (idAtendimento: number) => {
    const response = await AtendimentoAPI.finalizarTriagem(idAtendimento);

    if (response?.status === 200) {
      removeStoredValue();
      setAtendimentoStatus('ATENDIDO');
    }
  };

  const iniciarPrescricaoExecucao = async (idAtendimento: number) => {
    PrescricaoAPI.sendPrescricaoIniciarExecucao(
      idAtendimento,
      profissionalAtivo.id,
      {
        throwError: true,
      },
    )
      .then(() => {
        setAtendimentoStatus('PRESCRICAO_EXECUCAO');
        removeStoredValue();
      })
      .catch(() => {});
  };

  const finalizarPrescricaoExecucao = async (
    idAtendimento: number,
    justificativa?: string,
  ) => {
    PrescricaoAPI.sendPrescricaoFinalizarExecucao(
      idAtendimento,
      { justificativa },
      {
        throwError: true,
      },
    )
      .then(() => {
        setAtendimentoStatus('PRESCRICAO_FINALIZADA');
        removeStoredValue();
        navigate('/agenda', { replace: true });
      })
      .catch(() => {});
  };

  const getInfoLembretes = async (idPaciente: number) => {
    if (!idPaciente) return;
    const response = await ProntuarioInfoAPI.getInfoLembretes({
      idPaciente: idPaciente,
    });
    setInfoLembretes(response);
  };

  const getGuiasExecucoes = useCallback(async () => {
    if (!idAtendimento) return;
    const response = await ProcedimentosSeriadosAPI.getGuiasExecucoes(
      Number(idAtendimento),
    );
    setGuiasExecucoes(response);
  }, [idAtendimento]);

  const { invalidateModelos } = query;

  const getModelos = async () => {
    try {
      const response = await UtilsAPI.getModelos(
        { idConsultorio: consultorios?.ativo?.id },
        {
          throwError: true,
        },
      );
      setModelos(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getGuiasExecucoes();
    getModelos();
  }, [getGuiasExecucoes]);

  useEffectSkipFirst(() => {
    if (invalidateModelos) {
      getModelos();
      dispatch(resetInvalidateQuery());
    }
  }, [invalidateModelos]);

  useEffect(() => {
    getInfoLembretes(Number(paciente?.id));
  }, [paciente]);

  return (
    <AtendimentoContext.Provider
      value={{
        atendimentoStatus,
        setAtendimentoStatus,
        showBarraLateral,
        setShowBarraLateral,
        iniciarAtendimento,
        finalizarAtendimento,
        finalizarTriagem,
        infoLembretes,
        setInfoLembretes,
        getInfoLembretes,
        camposPEP: camposProntuario,
        setCamposPEP,
        sairPEPDialog,
        pausarAtendimento,
        prontuarioPersistente,
        setProntuarioPersistente,
        guiasExecucoes,
        modelos,
        getModelos,
        setModelos,
        getGuiasExecucoes,
        iniciarPrescricaoExecucao,
        finalizarPrescricaoExecucao,
        isTeleatendimento,
        setIsTeleatendimento,
        setTabValue,
        tabValue,
        setTeleconsulta,
        teleconsultaState,
        setRoomName,
        roomName,
        idConvenioAtendimento,
        setIdConvenioAtendimento,
        paciente,
        setPaciente,
        idConvenioProcedimentos,
        setIdConvenioProcedimentos,
        numeroCartaoAtendimentoParticular,
        setNumeroCartaoAtendimentoParticular,
        getListaProblemas,
        listaProblemas,
        campos,
        getCampos,
        checkRequiredJustificativa,
        requiredJustificativa,
        camposObrigatoriosNaoPreenchidos,
        isFormularioDirty,
        setIsFormularioDirty,
        infoProtocoloDialog,
        protocoloDialog,
        protocolosAtendimento,
        protocoloAplicado,
        setProtocoloAplicado,
        idAtendimento,
        modalAgravo,
      }}
    >
      {children}
    </AtendimentoContext.Provider>
  );
}
