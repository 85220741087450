import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';

export const Header = () => {
  const { isMobile } = useSize();

  if (isMobile) return null;

  return (
    <>
      {isMobile === false && (
        <div className="p-grid p-mt-1 p-mb-1 p-ml-1 p-mr-1">
          <div className="p-col-5">
            <SimpleText fontColor="color_60" fontSize="xxs">
              Nome
            </SimpleText>
          </div>

          <div className="p-col-4">
            <SimpleText fontColor="color_60" fontSize="xxs">
              Módulo
            </SimpleText>
          </div>

          <div className="p-col-2">
            <SimpleText fontColor="color_60" fontSize="xxs">
              Status
            </SimpleText>
          </div>

          <Can
            checkPermission={[
              'ADM_FORNECEDOR_TUSS_ALTERAR',
              'ADM_FORNECEDOR_TUSS_EXCLUIR',
            ]}
          >
            <div className="p-col-1">
              <SimpleText
                className="p-pl-3"
                fontColor="color_60"
                fontSize="xxs"
              >
                Ações
              </SimpleText>
            </div>
          </Can>
        </div>
      )}
    </>
  );
};
