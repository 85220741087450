import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ComorbidadeAPI from 'src/APIs/ProntuarioAPI/ComorbidadeAPI/ComorbidadeAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import { Checkbox } from 'src/components/_UI/Checkbox';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import ScrollPanel from 'src/components/ScrollPanel/ScrollPanel';
import Skeleton from 'src/components/Skeleton/Skeleton';

interface ModalFavoritosProps {
  onOpenModal: () => void;
  capitalizeFirstLetter: (text: string) => string;
  getCids: (idAtendimento: string) => void;
  idAtendimento: any;
}

function ModalFavoritos({
  capitalizeFirstLetter,
  getCids,
  idAtendimento,
}: ModalFavoritosProps) {
  const { agenda } = useAppSelector((state: RootState) => state);
  const { modalAgravo } = useAtendimento();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [favoritos, setFavoritos] = useState<any[]>([]);
  const [selectedCids, setSelectedCids] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const loadFavoritos = useCallback(async () => {
    const response = await ComorbidadeAPI.getFavoritos(
      agenda?.profissionalAtivo?.id,
    );

    if (Array.isArray(response) && !!response.length)
      setFavoritos(
        response
          .filter((favorito: any) => favorito.cid)
          .map((favorito: any) => favorito.cid),
      );
  }, [agenda?.profissionalAtivo?.id]);

  const handleFavoritar = async (cid: any) => {
    const response = await ComorbidadeAPI.favoritarComorbidade(
      agenda?.profissionalAtivo?.id,
      { idCid: cid.id },
    );

    if (response?.status === 200) loadFavoritos();
  };

  const handleAdicionar = async (idAtendimento: any) => {
    try {
      const payload = selectedCids.map(cid => {
        return {
          idCid: cid?.id,
          estadoDiagnosticoCid: 'CONFIRMADO',
          nivelDiagnosticoCid: 'PRINCIPAL',
        };
      });
      const response = await UtilsAPI.postAddCid(idAtendimento, payload, {
        throwError: true,
      });
      if (response?.data?.[0]?.existeAgravo) {
        modalAgravo.open();
      }
      getCids(String(idAtendimento));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadFavoritos().then(() => setLoading(false));
  }, [loadFavoritos]);

  const searchFavoritos = useMemo(() => {
    return favoritos.filter(favorito => {
      return favorito?.nome?.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [favoritos, searchQuery]);

  const onCidChange = (e: { value: any; checked: boolean }) => {
    const _selectedCids = [...selectedCids];

    if (e.checked) {
      _selectedCids.push(e.value);
    } else {
      for (let i = 0; i < _selectedCids.length; i++) {
        const selectedCid = _selectedCids[i];

        if (selectedCid.id === e.value.id) {
          _selectedCids.splice(i, 1);
          break;
        }
      }
    }

    setSelectedCids(_selectedCids);
  };

  const item = searchQuery ? searchFavoritos : favoritos;

  return (
    <div id="modal-favoritos" className="p-d-flex p-flex-column modelos p-p-2">
      <div className="p-mb-3">
        <TextInputSearch
          placeholder="Pesquisar modelo"
          value={searchQuery}
          onChange={setSearchQuery}
        />
      </div>
      <div>
        <SimpleText
          bold
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.COLOR_40}
        >
          Favoritos
        </SimpleText>
        <div className="content">
          <ScrollPanel className="custombar1">
            <Skeleton loading={loading}>
              <>
                {item?.length ? (
                  item?.map((cid: any, idx) => {
                    return (
                      <div
                        key={idx}
                        className="p-d-flex p-ai-center p-jc-between"
                      >
                        <Checkbox
                          inputId={cid?.id}
                          label={capitalizeFirstLetter(
                            cid?.nome?.toLowerCase(),
                          )}
                          name="cid"
                          value={cid}
                          onChange={onCidChange}
                          checked={selectedCids?.some(
                            (item: any) => item.id === cid?.id,
                          )}
                        />
                        <div className="p-mt-2 p-mr-2">
                          <i
                            className="fas fa-star favoritar-button favoritado"
                            onClick={() => handleFavoritar(cid)}
                          />
                        </div>
                      </div>
                    );
                  })
                ) : searchQuery ? (
                  <div
                    style={{ height: '100%' }}
                    className="p-d-flex p-flex-1 p-ai-center p-jc-center"
                  >
                    <SimpleText className="label">
                      Nenhum item encontrado para esta busca.
                    </SimpleText>
                  </div>
                ) : (
                  <>
                    <SimpleText className="label">
                      Nenhum favorito adicionado.
                    </SimpleText>
                    <SimpleText className="p-mt-2 label">
                      Você pode fazer a pesquisa e adicionar um item aos seus
                      favoritos.
                    </SimpleText>
                  </>
                )}
              </>
            </Skeleton>
          </ScrollPanel>
        </div>
      </div>
      <div className="p-mt-2">
        <Button
          label="Adicionar selecionados"
          className="adicionar-btn"
          onClick={() => handleAdicionar(idAtendimento)}
          stretch
        />
      </div>
    </div>
  );
}

export default ModalFavoritos;
