import { useState } from 'react';

import { Mention as PrimeMention } from 'primereact/mention';
import { useLocation } from 'react-router';

import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

interface MentionProps {
  valorCampoProntuario: string;
  setValorCampoProntuario: (valor: string) => void;
  handleOnBlur: (data: string) => void;
}

const Mention = ({
  valorCampoProntuario,
  setValorCampoProntuario,
  handleOnBlur,
}: MentionProps) => {
  const { state } = useLocation();

  const { modalAgravo } = useAtendimento();

  const idAtendimento = state?.idAtendimento;

  const [cidsCiapsBusca, setCidsCiapsBusca] = useState<any[]>([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [suggestions, setSuggestions] = useState<any[]>([]);

  const getCidsCiapsBusca = async (query: any) => {
    if (query < 1) return cidsCiapsBusca;
    try {
      const response = await UtilsAPI.getCidsCiapsBusca(
        { chave: query },
        { throwError: true },
      );
      const cidsCiaps = response.list;
      cidsCiaps.forEach((cidCiap: any) => {
        cidCiap.nome = cidCiap.nome.replaceAll(' ', '_').toLowerCase();
      });
      setCidsCiapsBusca(cidsCiaps);
      return cidsCiaps;
    } catch (error) {
      setCidsCiapsBusca([]);
      return [];
    }
  };

  const onSearch = async (event: any) => {
    const query = event.query;
    setSearchQuery(query);
    if (!query) return setSuggestions([]);
    const cidsCiapsBusca = await getCidsCiapsBusca(query);
    const suggestions = formatSuggestions(searchQuery, cidsCiapsBusca);
    setSuggestions(suggestions);
  };

  const formatSuggestions = (searchQuery: string, cidsCiapsBusca: any[]) => {
    const query = searchQuery.toLowerCase();
    const cidsCiaps = cidsCiapsBusca.filter(
      cidCiap =>
        cidCiap.nome.toLowerCase().includes(query) ||
        cidCiap.codigo.toLowerCase().includes(query),
    );
    return [
      ...cidsCiaps.sort((a, b) => {
        const aStarts = a.nome.toLowerCase().startsWith(query);
        const bStarts = b.nome.toLowerCase().startsWith(query);
        if (aStarts && bStarts) return a.nome.localeCompare(b.nome);
        if (aStarts && !bStarts) return -1;
        if (!aStarts && bStarts) return 1;
        return a.nome.localeCompare(b.nome);
      }),
    ];
  };

  const itemTemplate = (suggestion: any) => {
    return <span>{suggestion.nome}</span>;
  };

  const handleSelect = (e: any) => {
    setIsSelecting(true);
    try {
      if (e.suggestion.tipo === 'CID') {
        const response: any = UtilsAPI.postAddCid(Number(idAtendimento), [
          { idCid: e.suggestion.id },
        ]);
        if (response?.data?.[0]?.existeAgravo) {
          modalAgravo.open();
        }
      } else {
        UtilsAPI.postAddCiap(Number(idAtendimento), [e.suggestion.id]);
      }

      const regex = RegExp(searchQuery, 'g');

      const newValorCampoProntuario = valorCampoProntuario.replace(regex, '');

      setValorCampoProntuario(newValorCampoProntuario + e.suggestion.nome);
    } catch (error) {
      return;
    } finally {
      setIsSelecting(false);
    }
  };

  return (
    <PrimeMention
      trigger="#"
      onSearch={onSearch}
      suggestions={suggestions}
      onSelect={e => {
        handleSelect(e);
      }}
      itemTemplate={itemTemplate}
      field="nome"
      value={valorCampoProntuario}
      onChange={e => {
        if (!isSelecting) {
          setValorCampoProntuario((e.target as HTMLInputElement).value);
        }
      }}
      onBlur={e => handleOnBlur(e.target.value)}
      placeholder="Escreva os sintomas do paciente utilizando # + 3 caracteres"
      rows={4}
      delay={250}
    />
  );
};

export default Mention;
