import React, { useEffect, useState } from 'react';

import { PanelToggleParams } from 'primereact/panel';

import PendenciasAuditoriaAPI from 'src/APIs/AdminAPI/PendenciasAuditoriaAPI/PendenciasAuditoriaAPI';

import { getColorStatus, getStatusString } from '../../../utils/utils';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';

interface PanelContentProps {
  data: IPendenciaAuditoriaDTO;
}

const PanelContent = ({ data }: PanelContentProps) => {
  const [procedimentos, setProcedimentos] = useState(data.procedimentos || []);

  return (
    <div
      key={data.numeroGuia}
      className="p-d-flex p-flex-column p-jc-between p-p-3 p-h-100"
    >
      <SimpleText
        fontSize='xs'
        fontColor='color_60'
        medium
      >
        Procedimentos
      </SimpleText>
      <Separator className="p-mt-1" />
      {procedimentos.map((procedimento: any) => (
        <div key={procedimento.codigoTuss} className="p-d-flex p-flex-column p-gap-2">
          <div className="p-d-flex p-jc-between">
            <div className="p-d-flex p-flex-column p-gap-1">
              <SimpleText
                fontSize='xxs'
                fontColor='color_60'
              >
                Código TUSS
              </SimpleText>
              <SimpleText
                fontSize='xs'
                fontColor='color_40'
                medium
              >
                {procedimento.codigoTuss}
              </SimpleText>
            </div>
            <div className="p-d-flex p-flex-column p-gap-1">
              <SimpleText
                fontSize='xxs'
                fontColor='color_60'
              >
                Situação
              </SimpleText>
              <SimpleText
                fontSize='xs'
                medium
                className={`p-py-1 p-px-3 ${getColorStatus(
                  procedimento.statusProcedimento,
                )}`}
              >
                {procedimento.statusProcedimento || ''}
              </SimpleText>
            </div>
            <div className="p-d-flex p-flex-column p-gap-1">
              <SimpleText
                fontSize='xxs'
                fontColor='color_60'
              >
                Qtd. Solicitada
              </SimpleText>
              <SimpleText
                fontSize='xs'
                fontColor='color_40'
                medium
              >
                {procedimento.quantidadeSolicitada}
              </SimpleText>
            </div>
            <div className="p-d-flex p-flex-column p-gap-1">
              <SimpleText
                fontSize='xxs'
                fontColor='color_60'
              >
                Qtd. Aprovada
              </SimpleText>
              <SimpleText
                fontSize='xs'
                fontColor='color_40'
                medium
              >
                {procedimento.quantidadeLiberada}
              </SimpleText>
            </div>
          </div>
          <div className="p-d-flex p-flex-column p-gap-1">
            {' '}
            <SimpleText
              fontSize='xxs'
              fontColor='color_60'
            >
              Descrição
            </SimpleText>
            <SimpleText
              fontSize='xs'
              fontColor='primary'
              medium
            >
              {procedimento.nomeTuss}
            </SimpleText>
          </div>
          <Separator className="p-mt-2" />
        </div>
      ))}
    </div>
  );
};

export default PanelContent;
