import { createDataToName } from './utils';

export const handleOpenPDF = (response: any, name: any) => {
  if (response.status === 200) {
  // Naming the file
  const contentType = response.headers['content-type'];
  const contentDisposition = response.headers['content-disposition'];
  let reportName = name + '_' + createDataToName() + '.pdf';

  if (contentDisposition !== undefined) {
    reportName = contentDisposition.split('filename=')[1];
  }

  // Create new file using a blob
  const file = new File([response.data], 'report.pdf', { type: contentType });

  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(file);
  // link.download = reportName;
  // link.click();

  //Open file
  window.open(link.href, '_blank');

  // Clear cache file after download
  // window.URL.revokeObjectURL(link.href);
}}



export const handleDownloadPDF = (response: any, name: any) => {
  if (response.status === 200) {
    // Naming the file
    const contentType = response.headers['content-type'];
    const contentDisposition = response.headers['content-disposition'];
    let reportName = name + '_' + createDataToName() + '.pdf';

    if (contentDisposition !== undefined) {
      reportName = contentDisposition.split('filename=')[1];
    }

    // Create new file using a blob
    const file = new File([response.data], 'report.pdf', { type: contentType });

    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(file);
    link.download = reportName;
    link.click();

    // Clear cache file after download
    window.URL.revokeObjectURL(link.href);
  }
};

export const handleDownloadXLS = (
  response: any,
  name: any,
  ignoreContentDisposition = false,
) => {
  if (response.status === 200) {
    // Naming the file
    const contentType = response.headers['content-type'];
    const contentDisposition = response.headers['content-disposition'];
    let reportName = name + '_' + createDataToName() + '.xlsx';

    if (contentDisposition !== undefined && !ignoreContentDisposition) {
      reportName = contentDisposition;
    }

    // Create new file using a blob
    const blob = new Blob([response.data], { type: contentType });

    // Create link and make download of file
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = reportName;
    link.click();

    // Clear cache file after download
    window.URL.revokeObjectURL(link.href);
  }
};

export const handleDownloadCSV = (response: any, name: string) => {
  const filename = `${name}_${createDataToName()}.csv`;
  const contentType = 'text/csv;charset=utf-8;';
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const blob = new Blob([response], { type: contentType });
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const handleFileDownload = async (arquivo: any, name: string) => {
  const blob = await fetch(arquivo).then(res => res.blob());
  const extension = blob.type.substring(blob.type.indexOf('/') + 1);

  const reportName = name + '_' + createDataToName() + '.' + extension;

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = reportName;
  link.click();

  window.URL.revokeObjectURL(link.href);
};

export const convertFilePDFToBase64 = (pdfFile: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onloadend = () => {
      const base64String = reader.result as string;
      const base64 = base64String.split(',')[1];
      if (base64 !== undefined) {
        resolve(base64);
      } else {
        reject(new Error('Base64 esta undefined'));
      }
    };
    
    reader.onerror = () => {
      reject(new Error('Falha ao ler o arquivo PDF'));
    };
    
    reader.readAsDataURL(pdfFile);
  });
};

export const convertResponsePDFToBase64 = (
  response: any,
  name: any,
): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    if (response.status === 200) {
      // Naming the file
      const contentType = response.headers['content-type'];
      const contentDisposition = response.headers['content-disposition'];
      let reportName = name + '_' + createDataToName() + '.pdf';

      if (contentDisposition !== undefined) {
        reportName = contentDisposition.split('filename=')[1];
      }

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: contentType });

      // Read the blob contents as a data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const base64 = base64String.replace(
          /^data:application\/pdf;base64,/,
          '',
        );
        resolve(base64);
      };
      reader.onerror = () => {
        reject(new Error('Failed to read PDF file'));
      };
      reader.readAsDataURL(blob);
    } else {
      reject(new Error('PDF request failed'));
    }
  });
};

export const convertImageToBase64 = (imageFile: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onloadend = () => {
      const base64String = reader.result as string;
      resolve(base64String);
    };
    
    reader.onerror = () => {
      reject(new Error('Failed to read image file'));
    };
    
    reader.readAsDataURL(imageFile);
  });
};

export const openBase64PDFInNewTab = (base64String: string) => {
  // Remove data URL prefix
  const base64Data = base64String
    .replace(/^-----BEGIN PKCS7-----/, '')
    .replace(/-----END PKCS7-----$/, '');

  // Convert base64 to Blob
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: 'application/pdf' });

  // Create a data URL for the Blob
  const dataUrl = URL.createObjectURL(blob);

  // Open PDF in a new tab
  window.open(dataUrl, '_blank');
};

export const downloadBase64PDF = (base64String: string, name: string) => {
  const base64Data = base64String
    .replace(/^-----BEGIN PKCS7-----/, '')
    .replace(/-----END PKCS7-----$/, '');

  // Convert base64 to Blob
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: 'application/pdf' });

  // Create a data URL for the Blob
  const dataUrl = URL.createObjectURL(blob);

  // Create link and make download of file
  const link = document.createElement('a');
  link.href = dataUrl;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
