import React from 'react';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

const Header = () => {
  return (
    <div className="p-d-flex p-jc-between">
      <div className="p-grid p-flex-1 p-ml-2">
        <SimpleText fontSize="xxs" fontColor="color_60" className="p-col-3">
          Agravo
        </SimpleText>
        <SimpleText fontSize="xxs" fontColor="color_60" className="p-col-3">
          CID
        </SimpleText>
        <SimpleText fontSize="xxs" fontColor="color_60" className="p-col-2">
          Autoridade de saúde
        </SimpleText>
        <SimpleText fontSize="xxs" fontColor="color_60" className="p-col-2">
          Periodicidade
        </SimpleText>
        <SimpleText
          fontSize="xxs"
          fontColor="color_60"
          className="p-col-2 p-pr-2"
          style={{ textAlign: 'end' }}
        >
          Status
        </SimpleText>
      </div>
      <SimpleText fontSize="xxs" fontColor="color_60" className="p-py-2 p-px-3">
        Ações
      </SimpleText>
    </div>
  );
};

export default Header;
