import { useMemo, useRef, useState } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';
import { MonitoramentoSMSAPI } from 'src/APIs/AgendaAPI/MonitoramentoSMS/MonitoramentoSMSAPI';
import { ReactComponent as JsonFileDownloadIcon } from 'src/assets/icons/json-file-download.svg';
import { ReactComponent as JsonFileUploadIcon } from 'src/assets/icons/json-file-upload.svg';

import useSize from 'src/core/hooks/useSize';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Separator from 'src/components/Separator/Separator';

interface MonitoramentoSMSRowProps {
  item: IMonitoramentoSMSItem;
}

export const MonitoramentoSMSRow = ({ item }: MonitoramentoSMSRowProps) => {
  const [loading, setLoading] = useState(false);
  const { isTablet } = useSize();

  const jsonResponseDisclosure = useDisclosure({ opened: false });
  const jsonRequestDisclosure = useDisclosure({ opened: false });

  const isAgendado = item.statusAgendamento === 'AGENDADO';
  const onConfirmar = async () => {
    try {
      setLoading(true);
      await MonitoramentoSMSAPI.confirmarAgendamentoMonitoramento(
        Number(item.id),
      );
      item.agendamentoConfirmmado = true;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onCancelar = async () => {
    try {
      setLoading(true);
      await MonitoramentoSMSAPI.cancelarAgendamentoMonitoramento(
        Number(item.id),
      );
      item.statusAgendamento = 'CANCELADO';
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const menuItems = [
    {
      label: 'Confirmar',
      command: () => onConfirmar(),
      disabled:
        !(item.token === null) ||
        item.agendamentoConfirmmado === true ||
        loading,
    },
    {
      label: 'Cancelar',
      command: () => onCancelar(),
      disabled:
        !(item.token === null && item.statusAgendamento !== 'CANCELADO') ||
        loading,
    },
  ];

  const commomProps = {
    item,
    menuItems,
    isAgendado,
    jsonRequestDisclosure,
    jsonResponseDisclosure,
  };

  if (isTablet) {
    return <MonitoramentoSMSRowMobile {...commomProps} />;
  }

  return <MonitoramentoSMSRowDesktop {...commomProps} />;
};

interface MonitoramentoSMSRowDesktopProps {
  item: IMonitoramentoSMSItem;
  menuItems: MenuItem[];
  isAgendado: boolean;
  jsonRequestDisclosure: DisclosureType;
  jsonResponseDisclosure: DisclosureType;
}

export const MonitoramentoSMSRowDesktop = ({
  item,
  menuItems,
  isAgendado,
  jsonRequestDisclosure,
  jsonResponseDisclosure,
}: MonitoramentoSMSRowDesktopProps) => {
  const menu = useRef<TieredMenu>(null);
  const { windowInnerWidth } = useSize();

  const isCustomSize = useMemo(
    () => windowInnerWidth < 992,
    [windowInnerWidth],
  );
  return (
    <div className="row content-row p-wb-normal p-col-12 p-flex-wrap p-ai-center p-my-1">
      <SimpleText className="p-lg-1 p-xl-2" fontColor="color_40" fontSize="xs">
        {item.consultorio || ''}
      </SimpleText>

      <SimpleText className="p-col-2 ">{item.paciente || ''}</SimpleText>
      <SimpleText className="p-col-2">{item.profissional || ''}</SimpleText>
      <SimpleText className="p-col-2">{item.dataAgendamento}</SimpleText>

      <SimpleText className="p-col-2 p-lg-1">
        {item.statusEnvio || ''}
      </SimpleText>
      <SimpleText className="p-col-2 p-lg-1">
        {item.statusAgendamento || ''}
      </SimpleText>

      <div
        className="p-col p-xl-2 p-d-flex p-gap-2 p-ai-center p-jc-end"
        {...(isCustomSize && {
          style: {
            order: 1,
          },
        })}
      >
        <Separator
          className="p-p-0 p-m-0"
          layout="vertical"
          style={{
            height: '35px',
            ...(isCustomSize && { display: 'none' }),
          }}
        />

        <Button
          icon={<JsonFileUploadIcon />}
          btnType="gray"
          onClick={() => jsonRequestDisclosure.open()}
          disabled={!item.jsonRequest}
        />
        <Button
          icon={<JsonFileDownloadIcon />}
          btnType="gray"
          onClick={() => jsonResponseDisclosure.open()}
          disabled={!item.jsonResponse}
        />

        <Button
          icon="fas fa-ellipsis"
          btnType="gray"
          onClick={e => menu.current?.toggle(e)}
          disabled={!isAgendado}
        />
      </div>

      <TieredMenu
        ref={menu}
        popup
        model={menuItems}
        style={{
          width: '8rem',
        }}
      />

      <div className="p-col-12 p-px-0 p-py-0 p-d-flex p-flex-wrap">
        <div
          className="p-col-6 p-md-3 p-d-flex p-gap-1"
          data-tooltip={item.token || ''}
        >
          <SimpleText fontColor="color_40">Token:</SimpleText>
          <SimpleText className="p-text-truncate">
            {item.token || ''}
          </SimpleText>
        </div>
        <div className="p-col-6 p-md-3 p-d-flex p-gap-1">
          <SimpleText fontColor="color_40">Callback SMS:</SimpleText>
          <SimpleText
            fontColor={item.callbackSms === true ? 'primary' : 'color_16'}
          >
            {item.callbackSms === true ? 'Confirmado' : ''}
          </SimpleText>
        </div>
        <div className="p-col-12 p-md-5 p-d-flex p-gap-1">
          <SimpleText
            fontColor="color_40"
            style={{
              minWidth: '7rem',
            }}
          >
            Log (Mensagem):
          </SimpleText>
          <SimpleText className="p-text-truncate p-px-0">
            {item.mensagem || ''}
          </SimpleText>
        </div>
      </div>
      {jsonRequestDisclosure.isOpen && (
        <RequestDialog
          json={item.jsonRequest}
          close={jsonRequestDisclosure.close}
          jsonResponseDisclosure={jsonResponseDisclosure}
          disabledToggle={!item.jsonResponse}
        />
      )}

      {jsonResponseDisclosure.isOpen && (
        <ResponseDialog
          json={item.jsonResponse}
          close={jsonResponseDisclosure.close}
          jsonRequestDisclosure={jsonRequestDisclosure}
          disabledToggle={!item.jsonRequest}
        />
      )}
    </div>
  );
};

interface MonitoramentoSMSRowMobileProps {
  item: IMonitoramentoSMSItem;
  menuItems: MenuItem[];
  isAgendado: boolean;
  jsonRequestDisclosure: DisclosureType;
  jsonResponseDisclosure: DisclosureType;
}

export const MonitoramentoSMSRowMobile = ({
  item,
  menuItems,
  isAgendado,
  jsonRequestDisclosure,
  jsonResponseDisclosure,
}: MonitoramentoSMSRowMobileProps) => {
  const menu = useRef<TieredMenu>(null);

  return (
    <div className="row content-row p-ai-start p-flex-wrap p-my-1">
      <div className="p-col-4 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Consultório:
        </SimpleText>
        <SimpleText>{item.consultorio || ''}</SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Paciente:
        </SimpleText>
        <SimpleText>{item.paciente || ''}</SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Profissional:
        </SimpleText>
        <SimpleText>{item.profissional || ''}</SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Data agendamento:
        </SimpleText>
        <SimpleText>{item.dataAgendamento}</SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Status envio:
        </SimpleText>
        <SimpleText>{item.statusEnvio || ''}</SimpleText>
      </div>
      <div className="p-col-4 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Status agendamento:
        </SimpleText>
        <SimpleText>{item.statusAgendamento || ''}</SimpleText>
      </div>
      <div className="p-col-6 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Token:
        </SimpleText>
        <SimpleText>{item.token || ''}</SimpleText>
      </div>
      <div className="p-col-6 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Callback SMS:
        </SimpleText>
        <SimpleText
          fontColor={item.callbackSms === true ? 'primary' : 'color_16'}
        >
          {item.callbackSms === true ? 'Confirmado' : ''}
        </SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_40" fontSize="xxs">
          Log (Mensagem):
        </SimpleText>
        <SimpleText className="p-text-truncate">
          {item.mensagem || ''}
        </SimpleText>
      </div>

      <div className="p-col p-xl-2 p-d-flex p-gap-2 p-ai-center p-jc-end">
        <Button
          icon={<JsonFileUploadIcon />}
          btnType="gray"
          onClick={() => jsonRequestDisclosure.open()}
          disabled={!item.jsonRequest}
        />
        <Button
          icon={<JsonFileDownloadIcon />}
          btnType="gray"
          onClick={() => jsonResponseDisclosure.open()}
          disabled={!item.jsonResponse}
        />

        <Button
          icon="fas fa-ellipsis"
          btnType="gray"
          onClick={e => menu.current?.toggle(e)}
          disabled={!isAgendado}
        />
      </div>

      <TieredMenu
        ref={menu}
        popup
        model={menuItems}
        style={{
          width: '8rem',
        }}
      />

      {jsonRequestDisclosure.isOpen && (
        <RequestDialog
          json={item.jsonRequest}
          close={jsonRequestDisclosure.close}
          jsonResponseDisclosure={jsonResponseDisclosure}
          disabledToggle={!item.jsonResponse}
        />
      )}

      {jsonResponseDisclosure.isOpen && (
        <ResponseDialog
          json={item.jsonResponse}
          close={jsonResponseDisclosure.close}
          jsonRequestDisclosure={jsonRequestDisclosure}
          disabledToggle={!item.jsonRequest}
        />
      )}
    </div>
  );
};

interface RequestDialogProps {
  json: string;
  close: () => void;
  jsonResponseDisclosure: DisclosureType;
  disabledToggle?: boolean;
}

const RequestDialog = ({
  json,
  close,
  jsonResponseDisclosure,
  disabledToggle,
}: RequestDialogProps) => {
  const formattedJson = JSON.stringify(JSON.parse(json), null, 2);
  return (
    <Dialog
      header={
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText medium fontSize="md">
            JSON
          </SimpleText>

          <Button
            icon={<JsonFileDownloadIcon />}
            btnType="tonal"
            onClick={() => [jsonResponseDisclosure.open(), close()]}
            disabled={disabledToggle ?? false}
            label="JSON de resposta"
            iconPos="left"
          />
        </div>
      }
      visible={true}
      onHide={close}
      style={{ width: '450px' }}
      footer={
        <Button
          btnType="outline"
          onClick={() => close()}
          label="Fechar"
          stretch
        />
      }
    >
      <div className="bg-96 br-8px p-d-flex">
        <SimpleText className="p-px-2 p-py-1">
          <pre className="json-pre">
            <code>{formattedJson}</code>
          </pre>
        </SimpleText>
      </div>
    </Dialog>
  );
};

interface ResponseDialogProps {
  json: string;
  close: () => void;
  jsonRequestDisclosure: DisclosureType;
  disabledToggle?: boolean;
}

const ResponseDialog = ({
  json,
  close,
  jsonRequestDisclosure,
  disabledToggle,
}: ResponseDialogProps) => {
  const formattedJson = JSON.stringify(JSON.parse(json), null, 2);
  return (
    <Dialog
      header={
        <div className="p-d-flex p-ai-center p-jc-between">
          <SimpleText medium fontSize="md">
            JSON
          </SimpleText>

          <Button
            icon={<JsonFileDownloadIcon />}
            btnType="tonal"
            onClick={() => [jsonRequestDisclosure.open(), close()]}
            disabled={disabledToggle ?? false}
            label="JSON de resposta"
            iconPos="left"
          />
        </div>
      }
      visible={true}
      onHide={close}
      style={{ width: '450px' }}
      footer={
        <Button
          btnType="outline"
          onClick={() => close()}
          label="Fechar"
          stretch
        />
      }
    >
      <div className="bg-96 br-8px p-d-flex">
        <SimpleText className="p-px-2 p-py-1">
          <pre className="json-pre">
            <code>{formattedJson}</code>
          </pre>
        </SimpleText>
      </div>
    </Dialog>
  );
};
