import { useNavigate } from 'react-router';

import Dialog from 'src/components/Dialog/Dialog';

import EscolhaHorarioLivre from '../../EscolhaHorarioLivre/EscolhaHorarioLivre';

import '../RemarcarAgendamento.scss';

interface EscolhaNovoHorarioProps {
  visible: boolean;
  editAgendamento: AtendimentoAgendamento;
  onHide: () => void;
  type?: string;
}

function EscolhaNovoHorario({
  visible,
  editAgendamento,
  onHide,
  type,
}: EscolhaNovoHorarioProps) {
  const navigate = useNavigate();

  return (
    <Dialog
      className="remarcar-modal"
      visible={visible}
      header="Horários disponíveis"
      onHide={onHide}
      maximizedMobileSize
    >
      <EscolhaHorarioLivre
        onSelect={(agendamento, date) => {
          navigate('/agenda/novo-agendamento', {
            state: {
              date,
              agendamento,
              editAgendamento,
              type,
            },
          });
        }}
        onHide={onHide}
      />
    </Dialog>
  );
}

export default EscolhaNovoHorario;
