import React, { useState } from 'react';

import { Button } from 'src/components/_UI';
import Avatar from 'src/components/Avatar/Avatar';
import MaskedInput, {
  MASK,
} from 'src/components/Basics/MaskedInput/MaskedInput';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import Tooltip from 'src/components/Tooltip/Tooltip';

import './CabecalhoUsuario.scss';

interface CabecalhoUsuarioProps {
  usuario: GetUsuarioDTO | undefined;
}

const CabecalhoUsuario = ({ usuario }: CabecalhoUsuarioProps) => {
  const [emailUsuario, setEmailUsuario] = useState<string>(
    usuario?.emailProfissional || usuario?.emailPessoal || '',
  );
  const [contatoPrincipal, setContatoPrincipal] = useState<string>(() => {
    const priorityOrder = [
      'CELULAR_COMERCIAL',
      'CELULAR_PARTICULAR',
      'FIXO_COMERCIAL',
      'FIXO_RESIDENCIAL',
    ];

    if (usuario?.contatos) {
      for (const tipo of priorityOrder) {
        const contato = usuario.contatos.find(contato => contato.tipo === tipo);
        if (contato) {
          return contato.numero;
        }
      }
    }

    return '';
  });

  const tipo = usuario?.tipo?.toLowerCase() || '';
  const healthProfessionalType =
    usuario?.profissionalSaude?.conselhoTissProfissional?.descricao;

  const userType = healthProfessionalType
    ? healthProfessionalType
    : tipo?.charAt(0).toUpperCase() + tipo?.slice(1);

  const nomesConsultorios =
    usuario?.consultorios?.map(
      (consultorio: GetConsultorioDTO) => consultorio.nome,
    ) || [];

  return (
    <div id="cabecalho-usuario" className="p-d-flex p-flex-column p-gap-3">
      <div className="p-d-flex p-ai-center p-gap-4">
        <Avatar
          label={usuario?.nome || ''}
          size="large"
          style={{ scale: '1.33' }}
        />
        <div className="p-d-flex p-flex-column p-gap-2">
          <SimpleText medium fontSize="md">
            {usuario?.nome}
          </SimpleText>
          <SimpleText fontSize="sm" fontColor="color_60">
            {`${userType || ''} • ${
              usuario?.profissionalSaude?.conselhoTiss?.sigla || ''
            }/${usuario?.profissionalSaude?.estadoRegistro?.sigla || '--'}
          • ${usuario?.profissionalSaude?.numeroRegistro || ''}`}
          </SimpleText>
        </div>
      </div>
      <div className="p-d-flex p-ai-center">
        <SimpleText fontColor="color_60">Cadastrado em:</SimpleText>
        &nbsp;
        <SimpleText id={`vinculo-${usuario?.id}`} fontColor="primary" medium>
          {nomesConsultorios?.length > 1
            ? nomesConsultorios?.slice(0, 4).join(' • ') + '...'
            : nomesConsultorios || ''}
        </SimpleText>
        <Tooltip target={`#vinculo-${usuario?.id}`} position="top">
          {nomesConsultorios?.join(' • ') || ''}
        </Tooltip>
      </div>
      <div className="p-d-flex p-ai-center p-gap-3">
        <div className="p-inputgroup p-mt-3">
          <MaskedInput
            name="whatsapp"
            placeholder="(00) 00000-0000"
            mask={MASK.PHONE}
            value={contatoPrincipal}
            onChange={e => setContatoPrincipal(e.target.value)}
          />
          <Button
            type="button"
            btnType="tonal"
            icon="pi pi-whatsapp"
            label="Enviar"
            onClick={() =>
              window.open(
                `http://wa.me/55${contatoPrincipal?.match(/\d/g)?.join('')}`,
                '_blank',
              )
            }
            disabled={
              contatoPrincipal?.charAt(contatoPrincipal?.length - 1) === '_' ||
              contatoPrincipal === ''
                ? true
                : false
            }
          />
        </div>
        <div className="p-inputgroup p-mt-3">
          <TextInput
            name="email"
            placeholder="Digite o e-mail"
            value={emailUsuario}
            onChange={e => setEmailUsuario(e.target.value)}
          />
          <Button
            type="button"
            btnType="tonal"
            icon="pi pi-envelope"
            label="Enviar"
            onClick={() => window.open(`mailto:${emailUsuario}`, '_blank')}
          />
        </div>
      </div>
    </div>
  );
};

export default CabecalhoUsuario;
