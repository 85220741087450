import { Dispatch, SetStateAction } from 'react';

import { NavigateFunction } from 'react-router-dom';

import dayjs from 'dayjs';
import ComunicadosAPI from 'src/APIs/AdminAPI/ComunicadosAPI/ComunicadosAPI';
import FileUploadAPI from 'src/APIs/StorageAPI/FileUpload/FileUploadAPI';

const serializeDestinatariosCriar = (
  arr: Array<string | number>,
  chave: string,
) => {
  return [...new Set(arr)]?.map(destinatario => ({
    [chave]: destinatario,
  }));
};

const serializeDestinatarios = (
  arr: Array<string | number>,
  idComunicado: number,
  key: string,
  idKey: string,
  values?: EspecialidadeDTO[] | ConsultorioDTO[],
) => {
  const itensSelecionados = [...new Set(arr)];
  return (
    [...values!]
      ?.filter(v => v.id && itensSelecionados.includes(v.id))
      .map(e => ({
        idComunicado,
        [idKey]: e.id,
        [key]: e,
      })) || []
  );
};

export const enviarImagemDoComunicado = async (file: File) => {
  const dataFile: FileUpload = {
    fileName: file?.name,
    contentType: file?.type,
    contentLength: file?.size,
    resourceType: 'OUTROS',
    resourceId: 1,
  };

  const responseGoogleStorage: any = await FileUploadAPI.sendImageComunicado(
    dataFile,
  );

  if (responseGoogleStorage.status !== 200) return null;

  const res = await FileUploadAPI.updateFileUpload(
    responseGoogleStorage.data.uploadUrl,
    file,
  );

  return res?.status === 200 ? responseGoogleStorage.data.filePath : null;
};

export const converterValoresParaEnviar = async (
  valores: any,
  user: User,
  action: string,
  especialidades?: EspecialidadeDTO[],
  consultorios?: ConsultorioDTO[],
) => {
  const dataFim = dayjs(valores?.dataFim).format('YYYY-MM-DD');
  const dataInicio = dayjs(valores?.dataInicio).format('YYYY-MM-DD');

  const imageUrl = valores.imagem
    ? await enviarImagemDoComunicado(valores?.imagem)
    : null;

  if (action === 'criar') {
    return {
      ...valores,
      dataInicio,
      dataFim: dayjs(dataFim).isValid() ? dataFim : null,
      ativo: true,
      comunicadoDestinatarios: serializeDestinatariosCriar(
        valores.comunicadoDestinatarios,
        'tipoUsuario',
      ),
      comunicadoEspecialidades: serializeDestinatariosCriar(
        valores.comunicadoEspecialidades,
        'idEspecialidade',
      ),
      comunicadoConsultorios: serializeDestinatariosCriar(
        valores.comunicadoConsultorios,
        'idConsultorio',
      ),
      imagem: imageUrl || null,
      empresa: { id: user.idEmpresa },
      usuario: { id: user.idUsuario },
    };
  }

  return {
    ...valores,
    dataInicio: dataInicio,
    dataFim: dayjs(dataFim).isValid() ? dataFim : null,
    imagem: imageUrl,

    comunicadoDestinatarios:
      valores?.comunicadoDestinatarios?.map((e: any) => ({
        tipoUsuario: e,
      })) || [],

    comunicadoEspecialidades: serializeDestinatarios(
      valores.comunicadoEspecialidades,
      valores.id,
      'especialidade',
      'idEspecialidade',
      especialidades,
    ),

    comunicadoConsultorios: serializeDestinatarios(
      valores.comunicadoConsultorios,
      valores.id,
      'consultorio',
      'idConsultorio',
      consultorios,
    ),
  };
};

export enum EComunicadoStatus {
  Ativo = 'Ativo',
  Inativo = 'Inativo',
  Agendado = 'Agendado',
  Expirado = 'Expirado',
}

export type ComunicadoStatus = keyof typeof EComunicadoStatus;

export const statusDoComunicado = (
  status: boolean,
  dataEnvio: Date,
): EComunicadoStatus => {
  if (status) {
    const dataAtual = dayjs();
    const dataEnvioDayjs = dayjs(dataEnvio);

    if (dataEnvioDayjs.isAfter(dataAtual, 'day')) {
      return EComunicadoStatus.Agendado;
    }

    if (dataEnvioDayjs.isBefore(dataAtual, 'day')) {
      return EComunicadoStatus.Ativo;
    }
  }
  return EComunicadoStatus.Inativo;
};

export const inativarComunicado = async (
  data: ComunicadoDTO,
  setNeedUpdate: Dispatch<SetStateAction<boolean>>,
) => {
  await ComunicadosAPI.inativarComunicado(data.id);
  setNeedUpdate(prev => !prev);
};

export const editarComunicado = (
  data: ComunicadoDTO,
  navigate: NavigateFunction,
) => {
  navigate(`/cadastros/comunicado/cadastro/${data.id}`, { state: data });
};
export const editarComunicadoPacientes = (
  data: ComunicadoDTO,
  navigate: NavigateFunction,
) => {
  navigate(`/cadastros/marketing-medico/cadastro/${data.id}`, {
    state: data,
  });
};

export const copiarComunicado = (
  data: ComunicadoDTO,
  navigate: NavigateFunction,
) => {
  navigate('/cadastros/merketing-medico/cadastro', { state: data });
};
export const copiarComunicadoPacientes = (
  data: ComunicadoDTO,
  navigate: NavigateFunction,
) => {
  navigate('/cadastros/marketing-medico/cadastro', { state: data });
};

export const criarComunicado = (comunicado: ComunicadoDTO) => {
  return ComunicadosAPI.createComunicado(comunicado);
};

export const criarComunicadoCopiado = (comunicado: ComunicadoDTO) => {
  return ComunicadosAPI.createComunicado({
    ...comunicado,
    ativo: true,
  });
};

export const deletarComunicado = async (
  data: ComunicadoDTO,
  setNeedUpdate: Dispatch<SetStateAction<boolean>>,
) => {
  await ComunicadosAPI.deleteComunicado(data.id);
  setNeedUpdate(prev => !prev);
};

export const enviarComunicado = async (
  data: ComunicadoDTO,
  user: User,
  idParam: string | undefined,
  especialidades: EspecialidadeDTO[],
  consultorios: ConsultorioDTO[],
) => {
  let res: any;

  if (data.id && idParam) {
    const comunicado: any = await converterValoresParaEnviar(
      data,
      user,
      'atualizar',
      especialidades,
      consultorios,
    );

    res = await ComunicadosAPI.updateComunicado(comunicado.id, comunicado);
  } else if (data.id) {
    const comunicado: any = await converterValoresParaEnviar(
      data,
      user,
      'criar',
    );
    res = await criarComunicadoCopiado(comunicado);
  } else {
    const comunicado: any = await converterValoresParaEnviar(
      data,
      user,
      'criar',
    );
    res = await criarComunicado(comunicado);
  }

  return res;
};

export const conteudoModalComunicado = {
  inativar: {
    titulo: 'Inativar Comunicado',
    mensagem: 'Tem centersa que deseja inativar este comunicado?',
    labelBtnConfirmar: 'Sim, inativar',
    labelBtnCancelar: 'Cancelar',
    onConfirmar: inativarComunicado,
  },
  excluir: {
    titulo: 'Excluir Comunicado',
    mensagem: 'Tem centersa que deseja excluir este comunicado?',
    labelBtnConfirmar: 'Sim, excluir',
    labelBtnCancelar: 'Cancelar',
    onConfirmar: deletarComunicado,
  },
};
