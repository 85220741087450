import React, { ChangeEvent, useContext, useState } from 'react';

import {
  InputText as PrimeTextInput,
  InputTextProps as PrimeInputTextProps,
} from 'primereact/inputtext';

import ThemeContext from 'src/core/themes/ThemeContext';

import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './TextInput.scss';

export interface TextInputProps extends PrimeInputTextProps {
  label?: string | undefined;
  errorMsg?: string | any;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  value?: string;
  icon?: string | undefined;
  classNameInput?: string;
  rounded?: boolean;
  maxLengthSpan?: boolean;
  permitirApenasNumeros?: boolean;
  floatOnly?: boolean;
  virgulaDecimal?: boolean;
  duasDecimais?: boolean;
  editOnClick?: boolean;
  onClickIcon?: () => void;
  sublabel?: string;
}

const TextInput = React.forwardRef<any, TextInputProps>(
  (
    {
      label,
      name,
      key,
      errorMsg,
      className,
      onChange,
      disabled,
      icon,
      value = '',
      type,
      classNameInput,
      maxLength,
      required,
      rounded,
      maxLengthSpan,
      permitirApenasNumeros,
      floatOnly,
      virgulaDecimal,
      duasDecimais,
      editOnClick = false,
      onClickIcon,
      max,
      sublabel = '',
      ...rest
    },
    ref,
  ) => {
    const theme = useContext(ThemeContext);

    const [editOn, setEditOn] = useState(false);

    const renderLabel = () => {
      if (label || label === '' || required) {
        return (
          <ComponentsLabel
            name={name}
            label={label}
            sublabel={sublabel}
            required
          />
        );
      }
      return <></>;
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (editOnClick && editOn) {
        setEditOn(false);
      }

      rest?.onBlur?.(e);
    };

    if (editOnClick && !editOn) {
      return (
        <SimpleText
          className={`text-input-edit-only-click ${className}`}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.PRIMARY}
          onClick={() => setEditOn(true)}
        >
          {value}
        </SimpleText>
      );
    }

    return (
      <div
        className={`TextInput ${theme?.theme || ''} ${
          rounded ? 'rounded' : ''
        } ${errorMsg ? 'error' : ''} ${disabled ? 'disabled-text-input' : ''} ${
          className || ''
        }`}
        key={name}
      >
        {renderLabel()}
        <span
          className={`iconHandler ${
            errorMsg || icon ? 'p-input-icon-right' : ''
          }`}
        >
          {errorMsg ? <i className={'pi pi-exclamation-circle'} /> : <></>}
          {!errorMsg && icon ? (
            <i className={`success-icon ${icon}`} onClick={onClickIcon} />
          ) : (
            <></>
          )}
          <PrimeTextInput
            {...rest}
            onBlur={handleBlur}
            type={type}
            maxLength={maxLength}
            id={name}
            ref={ref}
            className={`${errorMsg ? 'p-invalid' : ''} ${classNameInput}`}
            value={value}
            disabled={disabled}
            max={max}
            autoFocus={rest?.autoFocus || editOnClick}
            onKeyPress={e => {
              if (
                type === 'number' &&
                max &&
                value.length >= max?.toLocaleString().length
              ) {
                e.preventDefault();
              }
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (permitirApenasNumeros) {
                e.target.value = e.target.value.replace(/\D/g, '');
              }

              if (floatOnly) {
                e.target.value = e.target.value.replace(/[^0-9\.|,]/g, '');
              }

              if (virgulaDecimal) {
                e.target.value = e.target.value.replace(/,/g, '.');
              }

              if (duasDecimais) {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '')
                  .replace(/(\..*?)\..*/g, '$1')
                  .replace(/(\.\d{2}).+/g, '$1');
              }

              if (onChange) {
                onChange(e);
              }
            }}
          />
        </span>
        {errorMsg ||
          (maxLength && (
            <div className={'p-d-flex p-jc-between p-ai-center p-mt-1'}>
              {errorMsg && (
                <div>
                  <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
                    {errorMsg}
                  </SimpleText>
                </div>
              )}

              {maxLength && maxLengthSpan && (
                <SimpleText
                  className={'p-text-normal'}
                  fontSize={FONT_SIZE.XXS}
                >
                  {`${value.length}/${maxLength}`}
                </SimpleText>
              )}
            </div>
          ))}
      </div>
    );
  },
);

export default TextInput;
