import { useState } from 'react';

import { useNavigate } from 'react-router';

import { ContasPagarAPI } from 'src/APIs/FinanceiroAPI/ContasPagarAPI/ContasPagarAPI';

import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import {
  handleDownloadPDF,
  handleDownloadXLS,
  handleOpenPDF
} from 'src/utils/files';

import Button from 'src/components/Basics/Button/Buttons';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import CheckboxCounter from './components/CheckboxCounter';

import ModalAlterarStatus from '../ModalAlterarStatus';
import { useSelectedContaPagar } from '../useSelectedContaPagar';

import Filter from './Filter';
import ListHeader from './Rows/ListHeader';
import Row from './Rows/Row';

const List = () => {
  const [habilitarBotoesRelatorio, setHabilitarBotoesRelatorio] =
    useState(false);
  const [filtroPesquisa, setFiltroPesquisa] = useState<{
    dataVencimentoInicio?: Date;
    dataVencimentoFim?: Date;
    idsConsultorios?: string[];
    idsCentroCusto?: string[];
  } | null>(null);
  const [loadingOpenPdf, setLoadingOpenPdf] = useState(false);
  const [loadingDownloadPdf, setLoadingDownloadPdf] = useState(false);
  const [loadingDownloadExcel, setLoadingDownloadExcel] = useState(false);

  const navigate = useNavigate();

  const { selectedContaPagar, reload } = useSelectedContaPagar();

  const { isMobile, isTablet } = useSize();

  const modalAlterarStatusDisclosure = useDisclosure({ opened: false });

  const desktopSize = !isMobile && !isTablet;

  const fetchApi = async (params: any) => {
    if (!params?.dataVencimentoInicio || !params?.dataVencimentoFim) {
      return [];
    }

    try {
      const response = await ContasPagarAPI.getContasPagar(params);

      if (response?.list?.length > 0) {
        setHabilitarBotoesRelatorio(true);
        const { query, page, ...rest } = params;
        setFiltroPesquisa(rest);
      }

      return response;
    } catch (error) {
      console.error(error);
      setHabilitarBotoesRelatorio(false);
      return [];
    }
  };

  const renderRow = (item: IContaPagarItem): JSX.Element => {
    return <Row key={item.id} item={item} />;
  };

  const gerarRelatorioPdf = async () => {
    if (!filtroPesquisa) return;

    setLoadingDownloadPdf(true);
    try {
      const response = await ContasPagarAPI.gerarRelatorioPdf(filtroPesquisa);
      handleDownloadPDF(response, 'relatorio-conta-pagar');
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDownloadPdf(false);
    }
  };

  const gerarRelatorioCsv = async () => {
    if (!filtroPesquisa) return;

    setLoadingDownloadExcel(true);
    try {
      const response = await ContasPagarAPI.gerarRelatorioExcel(filtroPesquisa, 'excel');
      handleDownloadXLS(response, 'relatorio-conta-pagar', true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDownloadExcel(false);
    }
  };

  const abrirRelatorioPdf = async () => {
    if (!filtroPesquisa) return;

    setLoadingOpenPdf(true);
    try {
      const response = await ContasPagarAPI.gerarRelatorioPdf(filtroPesquisa);
      handleOpenPDF(response, 'relatorio-conta-pagar');
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOpenPdf(false);
    }
  };

  const gerarRelatorio = async (type?: string) => {
    if (type === 'pdf') {
      await gerarRelatorioPdf();
    } else if (type === 'csv') {
      await gerarRelatorioCsv();
    } else {
      await abrirRelatorioPdf();
    }
  };

  return (
    <div className="p-p-1">
      <SearchList.Root
        fetchApi={fetchApi}
        consultorioRequired
        reloadWhenConsultorioChange
      >
        <Filter setFiltroPesquisa={setFiltroPesquisa} />
        <SearchList.BorderContainer className="p-py-1 p-px-3">
          <div className="p-d-flex p-jc-between p-ai-center p-p-2">
            <Button
              className="p-mb-2"
              label="Nova conta a pagar"
              icon="pi pi-plus"
              iconPos="left"
              btnType="pill"
              onClick={() => navigate('/faturamento/contas-pagar/novo')}
              stretch={isMobile}
              checkPermission="ADM_CONTAS_PAGAR_CADASTRAR"
            />

            <Button
              type="button"
              label="Alterar status geral"
              disabled={selectedContaPagar?.length === 0}
              onClick={() => {
                modalAlterarStatusDisclosure.open();
              }}
              checkPermission={'ADM_CONTAS_PAGAR_ALTERAR'}
            />
          </div>
          <CheckboxCounter />
          {desktopSize && <ListHeader />}
          <SearchList.InfiniteScroll reload={reload} renderRow={renderRow} />
        </SearchList.BorderContainer>
      </SearchList.Root>
      <div className="p-mt-3">
        <div>
          <SimpleText fontColor="color_40">
            Para concluir, gere o relatório optando pelo formato.
          </SimpleText>
        </div>
        <div className="p-d-flex p-mt-1">
          <Button
            className="p-mr-2"
            type="button"
            label="Gerar relatório"
            btnType="tonal"
            onClick={() => {
              gerarRelatorio();
            }}
            disabled={!habilitarBotoesRelatorio}
            loading={loadingOpenPdf}
          />
          <Button
            className="p-mr-2"
            type="button"
            label="Gerar Excel"
            btnType="tonal"
            onClick={() => {
              gerarRelatorio('csv');
            }}
            disabled={!habilitarBotoesRelatorio}
            loading={loadingDownloadExcel}
          />
          <Button
            className="p-mr-2"
            type="button"
            label="Baixar PDF"
            btnType="tonal"
            onClick={() => {
              gerarRelatorio('pdf');
            }}
            disabled={!habilitarBotoesRelatorio}
            loading={loadingDownloadPdf}
          />
        </div>
      </div>
      {modalAlterarStatusDisclosure.isOpen && (
        <ModalAlterarStatus {...modalAlterarStatusDisclosure} />
      )}
    </div>
  );
};

export default List;
