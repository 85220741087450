import React, { useState } from 'react';

import { Button } from 'src/components/_UI/Button';

import PanelContent from './PanelContent/PanelContent';
import PanelHeader from './PanelHeader/PanelHeader';

interface DetalhesGuiaProps {
  data: IPendenciaAuditoriaDTO;
}

const DetalhesGuia = ({ data }: DetalhesGuiaProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onToggle = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div
      className={`Panel p-d-flex p-flex-column ${isExpanded ? 'expanded' : ''}`}
    >
      <PanelHeader data={data} isExpanded={isExpanded} onToggle={onToggle} />

      <div
        className={`panel-content p-d-flex p-flex-column p-jc-between p-flex-1 ${
          isExpanded ? 'showContent' : ''
        } `}
      >
        <PanelContent data={data} />
        {isExpanded && (
          <div className="p-d-flex p-jc-center">
            <Button
              label="Voltar para mensagens"
              icon="fas fa-chevron-up"
              btnType="green-link"
              onClick={() => onToggle()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DetalhesGuia;
