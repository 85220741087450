import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/tipo-conta-pagar';

export const getTipoContaPagar = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IResponseTipoConta[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}`,
    params: params,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const getTipoContaPagarById = (
  id: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IResponseTipoConta> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const postTipoContaPagar = (
  data: IPostTipoConta,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IResponseTipoConta> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}`,
    data,
    throwError: true,
    ...options,
  });
};

export const putTipoContaPagar = (
  id: number | string,
  data: IPostTipoConta,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}`,
    data,
    throwError: true,
    return: true,

    ...options,
  });
};

export const deleteTipoContaPagar = (
  id: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    throwError: true,
    return: true,
    ...options,
  });
};

const TipoContaPagarAPI = {
  getTipoContaPagar,
  getTipoContaPagarById,
  postTipoContaPagar,
  putTipoContaPagar,
  deleteTipoContaPagar,
};

export default TipoContaPagarAPI;
