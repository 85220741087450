import React from 'react';

import dayjs from 'dayjs';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

interface ModalEncaixeRecorrenteProps extends DisclosureType {
  setEncaixeHorario: any;
  horariosIndisponiveis: any;
}

function ModalEncaixeRecorrente({
  close,
  isOpen,
  setEncaixeHorario,
  horariosIndisponiveis,
}: ModalEncaixeRecorrenteProps) {
  const horariosIndisponiveisAgenda = Object.keys(
    horariosIndisponiveis?.semHorariosAgendas || {},
  );

  const horariosIndisponiveisConflito = Object.entries(
    horariosIndisponiveis?.conflitos || {},
  );

  return (
    <Dialog
      visible={isOpen}
      style={{
        width: '100%',
        maxWidth: '400px',
      }}
      onHide={() => close()}
      header="Agendamento recorrente"
    >
      <>
        <div className="p-pl-8 p-grid">
          <SimpleText
            className="p-w-100"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Foram encontrados os seguintes conflitos.
          </SimpleText>

          <div
            className="p-d-flex p-flex-column p-w-100 p-gap-1 p-my-2"
            style={{
              maxHeight: '220px',
              overflowY: 'auto',
            }}
          >
            {horariosIndisponiveisAgenda.map((horario: any, index: number) => (
              <div className="p-grid p-col-12" key={index}>
                <SimpleText
                  className="p-mt-1"
                  fontSize={FONT_SIZE.XXS}
                  fontColor={FONT_COLOR.COLOR_40}
                >
                  {dayjs(horario).format('DD/MM/YYYY HH:mm')} - Horário
                  indisponível na agenda
                </SimpleText>
              </div>
            ))}

            {horariosIndisponiveisConflito.map(
              (horario: any, index: number) => (
                <div className="p-grid p-col-12" key={index}>
                  <SimpleText
                    className="p-mt-1"
                    fontSize={FONT_SIZE.XXS}
                    fontColor={FONT_COLOR.COLOR_40}
                  >
                    {dayjs(horario[0]).format('DD/MM/YYYY HH:mm')} -{' '}
                    {horario[1]}
                  </SimpleText>
                </div>
              ),
            )}
          </div>

          {horariosIndisponiveisConflito.length > 0 ? (
            <SimpleText
              className="p-mt-1"
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
            >
              Deseja agendar como encaixe de horário com um minuto a frente?
            </SimpleText>
          ) : (
            <SimpleText
              className="p-mt-1"
              fontSize={FONT_SIZE.XS}
              fontColor={FONT_COLOR.COLOR_16}
            >
              Deseja agendar como encaixe de horário?
            </SimpleText>
          )}

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button label="Cancelar" btnType="ghost" onClick={() => close()} />
            <Button
              label="Sim, realizar encaixe"
              onClick={() => {
                setEncaixeHorario(true);
                close();
              }}
              stretch
            />
          </div>
        </div>
      </>
    </Dialog>
  );
}

export default ModalEncaixeRecorrente;
