import { MenuItem } from 'primereact/menuitem';

export const itemsNegadoOperadora = ({
  agendamento,
  handleAlterarStatus,
  handleRemarcar,
  handleRemoveFlag,
  handleAddFlag,
  handleAtualizaOperadora,
  handleChangeParticular,
}: any) => {
  const items: MenuItem[] = [
    {
      label: 'Resolver negado operadora',
      items: [
        {
          label: 'Atualizar guia',
          command: handleAtualizaOperadora,
        },
        {
          label: 'Remover puericultura',
          command: () => handleRemoveFlag('PUERICULTURA', 'AGENDADO'),
          disabled: !agendamento?.flagsAtivas?.some(
            (flag: string) => flag === 'PUERICULTURA',
          ),
        },
        {
          label: 'Remover procedimento',
          command: () => handleRemoveFlag('PROCEDIMENTO', 'AGENDADO'),
          disabled: !agendamento?.flagsAtivas?.some(
            (flag: string) => flag === 'PROCEDIMENTO',
          ),
        },
        {
          label: 'Marcar como retorno',
          command: () => handleAddFlag('RETORNO', 'AGENDADO'),
          disabled:
            agendamento.idGrupoAtendimento ||
            agendamento?.flagsAtivas?.some(
              (flag: string) => flag === 'RETORNO',
            ),
        },
        {
          label: 'Alterar para particular',
          command: () => handleChangeParticular(),
        },
      ],
    },
    {
      separator: true,
    },
    {
      label: 'Remarcar paciente',
      icon: 'fas fa-calendar',
      command: () => handleRemarcar(),
    },
    {
      separator: true,
    },
    {
      label: 'Cancelar agendamento',
      icon: 'fas fa-trash',
      command: () => handleAlterarStatus(),
    },
  ];

  return items;
};
