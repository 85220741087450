import { useCallback, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { RadioButton } from 'src/components/_UI/RadioButton';
import RadioButtonControlled from 'src/components/Basics/RadioButtonControlled/RadioButtonControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Separator from 'src/components/Separator/Separator';

import InformativoPergunta from '../../Informativos/InformativoPergunta';

import SubtextoPergunta14 from './SubtextoPergunta14';

interface PerguntasFormularioProps {
  questionario: any;
  setPontuacaoRisco: (pontuacao: number) => void;
  showJustificativaMap: any;
  setShowJustificativaMap: any;
  watchValues: any;
  idPaciente: number | null;
  idadePaciente: number | null;
}

const PerguntasFormulario = ({
  questionario,
  setPontuacaoRisco,
  showJustificativaMap,
  setShowJustificativaMap,
  watchValues,
  idPaciente,
  idadePaciente,
}: PerguntasFormularioProps) => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();

  const getDefaultValue = useCallback(
    (variable: string) => {
      const defaultVariables: any = {
        idadeAnos: idadePaciente,
      };

      if (variable) {
        const variableName: any = variable.match(/\{(.*)\}/)?.[1];
        const defaultValue = defaultVariables[variableName];

        return defaultValue;
      }
    },
    [idadePaciente],
  );

  const perguntas = questionario?.perguntas;

  const flexRespostas =
    questionario?.nome === 'MEEM'
      ? 'p-d-flex p-flex-column p-flex-sm-row p-grid'
      : 'p-d-flex p-flex-column p-ai-start p-jc-start';

  const perguntasQuestionario: JSX.Element = perguntas
    ?.slice()
    .sort((a: any, b: any) => a.id - b.id)
    .map((pergunta: any, index: number) => {
      const fieldName = `pergunta${pergunta.id}`;
      const errorMsg = errors[fieldName]?.message;
      const isDecimal = pergunta.placeholder?.includes('0.0');

      if (showJustificativaMap[pergunta.id] === undefined) {
        showJustificativaMap[pergunta.id] = false;
      }

      return (
        <div key={pergunta.id} className="p-my-2 p-p-3 card-pergunta">
          <div className="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
            <div className="p-d-flex p-ai-center">
              <SimpleText medium>
                {index + 1} -{' '}
                {pergunta.id === 90 || pergunta.id === 20 ? (
                  <span dangerouslySetInnerHTML={{ __html: pergunta.texto }} />
                ) : (
                  pergunta.texto
                )}
              </SimpleText>
              {pergunta.textoAjuda && (
                <InformativoPergunta pergunta={pergunta} />
              )}
            </div>

            {pergunta.opcional && (
              <RadioButton
                onChange={() => {
                  setShowJustificativaMap((prevState: any) => ({
                    ...prevState,
                    [pergunta.id]: !prevState[pergunta.id],
                  }));
                }}
                label="Teste não aplicável"
                checked={showJustificativaMap[pergunta.id]}
              />
            )}
          </div>
          <Separator />
          {pergunta.id === 14 && (
            <>
              <SubtextoPergunta14 idPaciente={idPaciente} />
              <Separator />
            </>
          )}
          {showJustificativaMap[pergunta.id] ? (
            <TextareaInputControlled
              control={control}
              name={`pergunta${pergunta.id}`}
              label=""
              placeholder="Justificativa"
              errorMsg={errors[`pergunta${pergunta.id}`]?.message}
              onBlur={e => {
                watchValues[
                  `pergunta${pergunta.id}`
                ].justificativaNaoAplicacao = e.target.value;
              }}
            />
          ) : (
            <div className={flexRespostas}>
              {pergunta.tipoPergunta === 'CAMPO_NUMERICO' ? (
                <TextInputControlled
                  name={`pergunta${pergunta.id}`}
                  control={control}
                  errorMsg={errors[`pergunta${pergunta.id}`]?.message}
                  placeholder={pergunta.placeholder}
                  onChange={e => {
                    watchValues[`pergunta${pergunta.id}`].textoResposta =
                      e.target.value;
                  }}
                  disabled={pergunta.readOnly || false}
                  defaultValue={getDefaultValue(pergunta.defaultValue)}
                  max={pergunta.limiteResposta || 2147483647}
                  className="campo-numerico"
                  {...(isDecimal && {
                    floatOnly: true,
                    virgulaDecimal: true,
                    duasDecimais: true,
                  })}
                  {...(!isDecimal && {
                    permitirApenasNumeros: true,
                  })}
                />
              ) : (
                <>
                  {pergunta.respostas.map((resposta: any) => {
                    const fieldName = `pergunta${resposta.idAgaPerguntaFormulario}`;
                    const value = watch(fieldName);
                    watchValues[fieldName].idAgaRespostaPerguntaFormulario =
                      value;
                    if (
                      resposta.id ===
                      watchValues[fieldName].idAgaRespostaPerguntaFormulario
                    ) {
                      watchValues[fieldName].pontuacao = resposta.pontuacao;
                    }

                    return (
                      <div
                        key={resposta.id}
                        className={
                          questionario?.nome === 'MEEM' ? 'p-col-2' : ''
                        }
                      >
                        <RadioButtonControlled
                          control={control}
                          name={fieldName}
                          label={resposta.texto}
                          value={resposta.id}
                        />
                      </div>
                    );
                  })}

                  {!!errorMsg && (
                    <SimpleText className="error" fontSize="xxxs">
                      <i className="fas fa-circle-info p-mr-2" />
                      {errorMsg}
                    </SimpleText>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      );
    });

  const pontuacaoFormulario = Object.values(watchValues).map(
    (resposta: any) => {
      return resposta.pontuacao;
    },
  );

  const sumOfValues = pontuacaoFormulario.reduce(
    (acc: number, value: number | null | undefined) => {
      if (value !== null && value !== undefined) {
        acc += value;
      }
      return acc;
    },
    0,
  );

  useEffect(() => {
    if (
      questionario?.mnemonico === 'IVCF 20 - TRIAGEM - FUNCOES E CONDICOES' &&
      setPontuacaoRisco
    ) {
      setPontuacaoRisco(sumOfValues);
    }
  }, [questionario?.mnemonico, setPontuacaoRisco, sumOfValues]);

  return (
    <div>
      {questionario?.instrucoes && (
        <div className="p-py-3">
          <SimpleText medium>{questionario?.instrucoes}</SimpleText>
        </div>
      )}
      {perguntasQuestionario}
    </div>
  );
};

export default PerguntasFormulario;
