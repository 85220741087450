import { useEffect, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';
import * as Yup from 'yup';

import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import MultiSelectControlled from 'src/components/Basics/MultiSelectControlled/MultiSelectControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { FieldCiapMultiselect } from 'src/components/Fields/FieldCiapMultiselect/FieldCiapMultiselect';
import { FieldCidsMultiselect } from 'src/components/Fields/FieldCidsMultiselect/FieldCidsMultiselect';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import { SearchInput } from 'src/components/SearchList/SearchListRoot';

import './ProtocoloAtendimentoFilter.scss';

export const ProtocoloAtendimentoFilter = () => {
  const [usuarioptions, setUsuarioptions] = useState<any[]>([]);
  const [quantidadeCidsCiaps, setQuantidadeCidsCiaps] = useState<number>(0);
  const { isTablet } = useSize();
  const ref = useRef<OverlayPanel>(null);
  const { setFilter } = useSearchList();

  const form = useForm({
    resolver: yupResolver(
      Yup.object({
        inicioCriacao: Yup.date().nullable(),
        dataFimCriacao: Yup.date().when('inicioCriacao', {
          is: (inicioCriacao: Date) => !!inicioCriacao,
          then: Yup.date()
            .min(
              Yup.ref('inicioCriacao'),
              'A data final deve ser maior que a data inicial',
            )
            .required('Informe a data final')
            .nullable(),
          otherwise: Yup.date().nullable(),
        }),
      }),
    ),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const onSubmit = (v: any) => {
    const { cids, ciaps, inicioCriacao, dataFimCriacao, ...rest } = v;
    setFilter({
      ...rest,
      idsCids: cids?.map((c: any) => c.id).join(','),
      idsCiaps: ciaps?.map((c: any) => c.id).join(','),
      ...(inicioCriacao && {
        inicioCriacao: dayjs(new Date(inicioCriacao)).format('YYYY-MM-DD'),
      }),
      ...(dataFimCriacao && {
        dataFimCriacao: dayjs(new Date(dataFimCriacao)).format('YYYY-MM-DD'),
      }),
    });
    ref.current?.hide();
  };

  useEffect(() => {
    (async () => {
      try {
        const usuarios = await UsuarioAPI.loadUsuarios(
          {},
          { hideToast: true, throwError: true },
        );
        setUsuarioptions(usuarios.list);
      } catch {}
    })();
  }, []);

  return (
    <div className="filtros-avancados">
      <Button
        label="Pesquisa avançada"
        icon="fas fa-chevron-down"
        iconPos="right"
        btnType="tonal"
        onClick={e => ref.current?.toggle(e)}
        style={{
          maxHeight: '35px',
        }}
        stretch={isTablet}
      />

      <OverlayPanel
        ref={ref}
        style={{
          width: '400px',
        }}
        className="filtros-avancados"
      >
        <FormProvider {...form}>
          <SimpleText fontColor="color_60">
            Pesquise os protocolos de atendimento de forma detalhada,
            preenchendo um ou mais dos campos abaixo.
          </SimpleText>

          <form onSubmit={form.handleSubmit(onSubmit)} className="p-grid">
            <SearchInput
              label="Pesquisar por nome do protocolo"
              placeholder="Digite uma palavra chave"
              className="p-col-12"
            />

            <FieldCidsMultiselect name="cids" label="CID" />
            <FieldCiapMultiselect name="ciaps" label="CIAP" />

            <DropdownControlled
              control={control}
              name="idUsuarioCriacao"
              label="Criado por"
              className="p-col-12"
              optionValue="id"
              options={usuarioptions}
              optionLabel="nome"
              placeholder="Selecione"
              showClear
            />

            <CheckboxControlled
              control={control}
              name="incluiInativos"
              label="Incluir inativos à pesquisa"
              className="p-col-4 p-px-0"
            />

            <SimpleText fontColor="color_60" className="p-col-12">
              Período de inclusão
            </SimpleText>

            <CalendarInputControlled
              control={control}
              name="inicioCriacao"
              label="Data início"
              placeholder="dd/mm/aaaa"
              className="p-col-6"
              showIcon
              maxDate={new Date()}
              errorMsg={errors.inicioCriacao?.message}
            />

            <CalendarInputControlled
              control={control}
              name="dataFimCriacao"
              label="Data fim"
              placeholder="dd/mm/aaaa"
              className="p-col-6"
              showIcon
              errorMsg={errors.dataFimCriacao?.message}
            />

            <div className="p-col-12 p-px-0 p-gap-2 p-d-flex">
              <Button
                label="Cancelar"
                btnType="outline"
                className="p-col-4"
                onClick={() => ref.current?.hide()}
              />
              <Button label="Pesquisar" className="p-col " type="submit" />
            </div>
          </form>
        </FormProvider>
      </OverlayPanel>
    </div>
  );
};
