import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import AlertBox from 'src/components/AlertBox/AlertBox';
import Page from 'src/components/Basics/Page/Page';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import List from './List/List';

import './RelatoriosFaturamento.scss';

const RelatoriosFaturamento = () => {
  const { consultorios } = useAppSelector((state: RootState) => state);

  return (
    <Page id="relatorio-faturamento-container" white className="p-px-0">
      <>
        <SimpleText className="p-m-0 p-py-0" fontSize="md" medium>
          Relatório de faturamento
        </SimpleText>
        {!consultorios?.ativo?.id ? (
          <AlertBox
            visible={true}
            text="Você precisa selecionar um Consultório para esta funcionalidade!"
          />
        ) : (
          <List />
        )}
      </>
    </Page>
  );
};

export default RelatoriosFaturamento;
