import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { Sidebar } from 'primereact/sidebar';
import { useDispatch } from 'react-redux';

import { ChatAPI } from 'src/APIs/ChatAPI/ChatAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { checkAuth } from 'src/utils/utils';

import { ChatListItem } from './components/ChatListItem/ChatListItem';
import { ChatStatus } from './components/ChatStatus/ChatStatus';
import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { ChatMessage } from './ChatMessage';
import { ChatProvider, useChat } from './hooks/useChat';

import './Chat.scss';

export const Chat = () => {
  const chatOverlay = useRef<OverlayPanel>(null);
  const { authorities } = useAppSelector(state => state.user);
  const { invalidateNotificacoesChat = null } = useAppSelector(
    state => state.query,
  );
  const [notifications, setNotifications] = useState(false);

  const dispatch = useDispatch();

  const hasChat = useMemo(
    () => checkAuth(authorities, 'ADM_CHAT_COMUNICACAO_USUARIO'),
    [authorities],
  );

  const fetchNotifications = useCallback(async () => {
    if (!hasChat) return;

    try {
      const response = await ChatAPI.loadChatNotificacao({ throwError: true });
      const unreadMessages = response.list?.filter(not => !not.lida);

      return setNotifications(unreadMessages.length > 0);
    } catch {
      return setNotifications(false);
    }
  }, [hasChat]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  useEffectSkipFirst(() => {
    if (invalidateNotificacoesChat) {
      fetchNotifications();
      dispatch(resetInvalidateQuery());
    }
  }, [invalidateNotificacoesChat, fetchNotifications, dispatch]);

  if (!hasChat) return null;

  return (
    <ChatProvider chatOverlay={chatOverlay.current!}>
      <div className="relative p-pr-2">
        <Button
          btnType="gray"
          icon="fas fa-message"
          onClick={e => chatOverlay.current?.toggle(e)}
          style={{
            position: 'static',
          }}
          {...(notifications && {
            badgeClassName: 'badge-chat',
            badgePos: 'right',
            badgeVariant: 'warn',
            badge: '•',
          })}
          checkPermission="ADM_CHAT_COMUNICACAO_USUARIO"
        />
      </div>

      <OverlayPanel
        className="chat-overlay"
        ref={chatOverlay}
        onShow={() => {
          document.body.style.overflow = 'hidden';
        }}
        onHide={() => {
          document.body.style.overflow = 'auto';
        }}
      >
        <ChatComponent />
      </OverlayPanel>
    </ChatProvider>
  );
};

const ChatComponent = () => {
  const { ativo } = useAppSelector(state => state.consultorios);
  const {
    userStatus,
    handleChangeStatus,
    setOpenedMessageUser,
    isMessageOpened,
    setIsMessageOpened,
    reloadChatList,
    openedMessageUser,
  } = useChat();

  const fetchAPI = async (params: any) => {
    if (!ativo?.id) return [];

    const response = await ChatAPI.loadChatListagem({
      idConsultorio: ativo.id,
      ...params,
    });

    //* Update opened message user if it's the same user
    if (openedMessageUser && response.list) {
      const user = response.list.find(item => item.id === openedMessageUser.id);

      if (user) {
        setOpenedMessageUser(user);
      }
    }

    if (response?.list) {
      return response;
    }
    return [];
  };

  return (
    <div className="p-d-flex p-w-100 p-flex-column p-gap-2">
      <SimpleText fontSize="md">Chat</SimpleText>

      <SimpleText fontColor="color_40" className="p-line-height-2">
        Abra uma conversa com a equipe do seu consultório e tenha mais agilidade
        no dia-a-dia
      </SimpleText>

      <div className="p-d-flex p-gap-2 p-ai-center">
        <SimpleText medium>Você está</SimpleText>
        <ChatStatus
          initialState={userStatus}
          onChange={v => handleChangeStatus(v)}
        />
      </div>

      <SearchList.Root fetchApi={fetchAPI}>
        <div className="p-d-flex p-flex-column chat-list p-w-100">
          <SearchList.InfiniteScroll
            reload={reloadChatList}
            height={468}
            notLoad={reloadChatList !== 0}
            renderRow={(item: IChatUsuario) => (
              <ChatListItem
                onClick={() => [
                  setOpenedMessageUser(item),
                  setIsMessageOpened(true),
                ]}
                item={item}
              />
            )}
            customEmptyText={
              'Nenhum contato ou conversa encontrada para esse consultório'
            }
          />
        </div>
      </SearchList.Root>

      <Sidebar
        visible={isMessageOpened}
        onHide={() => setIsMessageOpened(false)}
        appendTo={'self'}
        modal={false}
        showCloseIcon={false}
        position="right"
        id="chat-message-drawer"
        maskStyle={{
          position: 'absolute',
        }}
        blockScroll
      >
        <ChatMessage />
      </Sidebar>
    </div>
  );
};
