import { useCallback, useMemo } from 'react';

import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { validationSchema } from './utils';

import { FormContentConsulta } from '../components/Form/FormContentConsulta';
import { FormContentExames } from '../components/Form/FormContentExames';
import { FormContentFormularioDinamico } from '../components/Form/FormContentFormularioDinamico';
import { FormContentPedido } from '../components/Form/FormContentPedido';
import { Button } from 'src/components/_UI';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import './ModalAddAtividade.scss';

type IState = {
  item?: any;
  idx?: number;
  update?: (idx: number, v: any) => void;
};

export const ModalAddAtividade = ({
  isOpen,
  close,
  state = {},
}: DisclosureType<IState>) => {
  const { setValue, watch } = useFormContext();

  const planosValue = watch('planos');
  const convenio: ConvenioDTO = watch('convenio');
  const diasVigencia = watch('diasVigencia') || 0;

  const defaultValues = useMemo(() => {
    const items = state?.item || {};

    return {
      atividade: 'CONSULTA',
      ...items,
      execucao: items?.execucao ?? '',
    };
  }, [state]);

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema(diasVigencia)),
  });

  const typeOfAtividade = form.watch('atividade');

  const renderFormContent: { [key: string]: JSX.Element } = {
    CONSULTA: <FormContentConsulta />,
    PEDIDO_CUIDADO: <FormContentPedido />,
    EXAMES: <FormContentExames isEditing={!!state?.item} convenio={convenio} />,
    FORMULARIO_DINAMICO: <FormContentFormularioDinamico />,
  };

  const onSubmit = useCallback(
    (values: any) => {
      if (!!state?.item && state.update) {
        state.update(state.idx!, values);
        return close();
      }

      if (values.atividade === 'CONSULTA') {
        setValue('planos', [
          ...planosValue,
          {
            atividade: values.atividade,
            idEspecialidade: values.especialidade?.id,
            especialidade: values.especialidade,
            recorrencia: values.recorrencia,
            execucao: values.execucao,
            resumoClinico: values.resumoClinico,
            hipoteseDiagnostica: values.hipoteseDiagnostica,
            questionamento: values.questionamento,
          },
        ]);
      }
      if (values.atividade === 'PEDIDO_CUIDADO') {
        setValue('planos', [
          ...planosValue,
          {
            atividade: values.atividade,
            nome: values.nomeCuidado,
            idTipoCuidado: values.tipo?.id,
            tipo: values.tipo,
            recorrencia: values.recorrencia,
            execucao: values.execucao,
          },
        ]);
      }
      if (values.atividade === 'EXAMES') {
        setValue('planos', [
          ...planosValue,
          {
            atividade: values.atividade,
            idServicoTuss: values.servicoTuss?.id,
            servicoTuss: values.servicoTuss,
            quantidade: values.quantidade,
            recorrencia: values.recorrencia,
            execucao: values.execucao,
          },
        ]);
      }
      if (values.atividade === 'FORMULARIO_DINAMICO') {
        setValue('planos', [
          ...planosValue,
          {
            atividade: values.atividade,
            formularioDinamico: values.formularioDinamico,
            idFormularioDinamico: values.formularioDinamico?.id,
            recorrencia: values.recorrencia,
            execucao: values.execucao,
          },
        ]);
      }

      return close();
    },
    [close, planosValue, setValue, state],
  );

  const isEditing = !!state?.item;

  return (
    <Dialog
      id="modal-novo-plano"
      onHide={close}
      visible={isOpen}
      header={isEditing ? 'Editar atividade' : 'Adicionar atividade'}
      modal={false}
      maximizedMobileSize
    >
      <FormProvider {...form}>
        <form
          onSubmit={e => [form.handleSubmit(onSubmit)(e), e.stopPropagation()]}
          className="p-d-flex p-flex-column p-gap-3"
        >
          <SimpleText fontColor={FONT_COLOR.COLOR_60}>
            Selecione a atividade que deseja inserir para o convenio{' '}
            <strong>{convenio?.nomeFantasia || convenio.razaoSocial}</strong>.
          </SimpleText>

          <DropdownControlled
            control={form.control}
            name="atividade"
            label="Atividade"
            filter={false}
            options={[
              {
                label: 'Consulta',
                value: 'CONSULTA',
              },
              {
                label: 'Cuidados',
                value: 'PEDIDO_CUIDADO',
              },
              {
                label: 'Exames',
                value: 'EXAMES',
              },
              {
                label: 'Formulário Dinâmico',
                value: 'FORMULARIO_DINAMICO',
              },
            ]}
          />

          <div className="border-container">
            {renderFormContent[typeOfAtividade]}
          </div>

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              type="button"
              label="Cancelar"
              btnType="ghost"
              stretch
              onClick={() => close()}
              disabled={form.formState.isSubmitting}
            />
            <Button
              type="submit"
              label="Salvar"
              stretch
              loading={form.formState.isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};
