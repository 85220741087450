import dayjs from 'dayjs';
import { ReactComponent as XmlDownIcon } from 'src/assets/icons/xml-down-small.svg';
import { ReactComponent as XmlUpIcon } from 'src/assets/icons/xml-up-small.svg';

import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { DialogXml } from '../components/DialogXml/DialogXml';
import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

interface RowProps {
  item: any;
  onReenviar: (id: number | string) => void;
  loadingEnvio: boolean;
}

export const MonitoramentoTissRow = ({
  item,
  onReenviar,
  loadingEnvio,
}: RowProps) => {
  const { isTablet } = useSize();

  const dialogXmlEnvio = useDisclosure({ opened: false });

  const timeout = item.tempoResposta === null ? '-' : `${item.tempoResposta} s`;

  return (
    <div className="p-col-12 p-grid p-ai-center content-row">
      <div
        title={'Solicitação de procedimentos SADT'}
        className="p-d-flex p-flex-column p-gap-2 p-col"
      >
        <SimpleText className="p-text-truncate">{item?.servico}</SimpleText>
        <SimpleText className="p-text-truncate" medium>
          {item?.guia}
        </SimpleText>
      </div>
      <SimpleText className="p-col p-py-0" lines={3}>
        {item?.nomeMedico}
      </SimpleText>
      <div className="p-col-4 p-md-2 p-d-flex p-flex-column p-gap-2 ">
        <SimpleText className="p-text-truncate">
          {item?.nomePaciente}
        </SimpleText>
        <SimpleText className="p-text-truncate">
          {item?.cartaoPaciente}
        </SimpleText>
      </div>
      <SimpleText className="p-col-4 p-md-2 p-py-0" lines={3}>
        {item?.resultado}
      </SimpleText>
      {!isTablet && (
        <SimpleText className="p-col-1 p-text-truncate">{timeout}</SimpleText>
      )}

      {isTablet && <Divider layout="horizontal" className="p-col-12" />}

      <div className="p-col p-d-flex p-flex-column p-gap-2 ">
        <SimpleText className="p-text-truncate">
          {item?.dataInicio
            ? dayjs(item.dataInicio).format('DD/MM/YYYY HH:mm')
            : '-'}
        </SimpleText>
        <SimpleText className="p-text-truncate">
          {item?.dataFim ? dayjs(item.dataFim).format('DD/MM/YYYY HH:mm') : '-'}
        </SimpleText>
      </div>

      <div className="p-col p-d-flex p-gap-2 p-jc-end">
        <Button
          label="Reenviar"
          btnType="tonal"
          onClick={() => onReenviar(item.id)}
          disabled={item.servico !== 'ENVIO_GUIAS_COBRANCA'}
          loading={loadingEnvio}
        />
        <Button
          icon={<XmlUpIcon />}
          btnType="gray"
          className={!item.xmlEnvio ? 'btn-disabled' : ''}
          tooltip={!item.xmlEnvio ? 'Não há XML de envio' : ''}
          tooltipOptions={{ position: 'top' }}
          onClick={() =>
            item.xmlEnvio &&
            dialogXmlEnvio.open({
              state: 'ENVIO',
            })
          }
        />
        <Button
          icon={<XmlDownIcon />}
          btnType="gray"
          className={!item.xmlResponse ? 'btn-disabled' : ''}
          tooltip={!item.xmlResponse ? 'Não há XML de respota' : ''}
          tooltipOptions={{ position: 'top' }}
          onClick={() =>
            item.xmlResponse &&
            dialogXmlEnvio.open({
              state: 'RESPOSTA',
            })
          }
        />
      </div>

      {dialogXmlEnvio.isOpen && (
        <DialogXml
          xmlRequest={item?.xmlEnvio}
          xmlResponse={item?.xmlResponse}
          {...dialogXmlEnvio}
        />
      )}
    </div>
  );
};

export const MonitoramentoRowMobile = ({
  item,
  onReenviar,
  loadingEnvio,
}: RowProps) => {
  const dialogXmlEnvio = useDisclosure({ opened: false });

  const timeout = item.tempoResposta === null ? '-' : `${item.tempoResposta} s`;

  return (
    <div className="p-col-12 p-grid  content-row p-gap-1">
      <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60">Serviço / Guia</SimpleText>
        <SimpleText>
          {item?.servico} - {item.guia}
        </SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60">Profissional</SimpleText>
        <SimpleText> {item?.nomeMedico}</SimpleText>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60">Paciente / Cartão</SimpleText>
        <div className="p-d-flex p-flex-column p-gap-1">
          <SimpleText className="p-text-truncate">
            {item?.nomePaciente}
          </SimpleText>
          <SimpleText className="p-text-truncate">
            {item?.cartaoPaciente}
          </SimpleText>
        </div>
      </div>
      <div className="p-col-12 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60">Resultado</SimpleText>
        <SimpleText> {item?.resultado}</SimpleText>
      </div>
      <div className="p-col-3 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60">Resposta</SimpleText>
        <SimpleText>{timeout}</SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60">Inicio / Fim</SimpleText>
        <div className="p-d-flex p-flex-column p-gap-1">
          <SimpleText className="p-text-truncate">
            {item?.dataInicio
              ? dayjs(item.dataInicio).format('DD/MM/YYYY HH:mm')
              : '-'}
          </SimpleText>
          <SimpleText className="p-text-truncate">
            {item?.dataFim
              ? dayjs(item.dataFim).format('DD/MM/YYYY HH:mm')
              : '-'}
          </SimpleText>
        </div>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" className="p-text-right">
          XML
        </SimpleText>
        <div className="p-d-flex p-gap-2 p-jc-end">
          <Button
            label="Reenviar"
            btnType="tonal"
            onClick={() => onReenviar(item.id)}
            disabled={item.servico !== 'ENVIO_GUIAS_COBRANCA'}
            loading={loadingEnvio}
          />
          <Button
            icon={<XmlUpIcon />}
            disabled={!item?.xmlEnvio}
            tooltip={!item.xmlEnvio ? 'Não há XML de envio' : ''}
            tooltipOptions={{ position: 'top' }}
            btnType="gray"
            onClick={() =>
              dialogXmlEnvio.open({
                state: 'ENVIO',
              })
            }
          />
          <Button
            icon={<XmlDownIcon />}
            disabled={!item?.xmlResponse}
            tooltip={!item.xmlResponse ? 'Não há XML de resposta' : ''}
            tooltipOptions={{ position: 'top' }}
            btnType="gray"
            onClick={() =>
              dialogXmlEnvio.open({
                state: 'RESPOSTA',
              })
            }
          />
        </div>
      </div>

      {dialogXmlEnvio.isOpen && (
        <DialogXml
          xmlRequest={item?.xmlEnvio}
          xmlResponse={item?.xmlResponse}
          {...dialogXmlEnvio}
        />
      )}
    </div>
  );
};
