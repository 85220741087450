export const tipoRecorrenciaLabel = (
  watchTipoRecorrencia: string,
  dias?: number,
) => {
  if (!watchTipoRecorrencia) return '';

  let value =
    watchTipoRecorrencia === 'DIARIA'
      ? 'Dias'
      : watchTipoRecorrencia === 'SEMANAL'
      ? 'Semanas'
      : 'Meses';

  if (!!dias) value = `${dias} ${value}`;

  return value;
};


export const transformToGetPacienteDTO = (pacienteAgendamento: any) => {
  const cartaoPrincipal = pacienteAgendamento.cartoes.find(
    (cartao: any) => cartao.principal,
  );

  return {
    id: pacienteAgendamento.id,
    idEmpresa: pacienteAgendamento.idEmpresa,
    acomodacao: pacienteAgendamento.acomodacao,
    cartaoPlano: cartaoPrincipal ? cartaoPrincipal.numeroCartao : null,
    cartaoPrincipal: cartaoPrincipal,
    cpf: pacienteAgendamento.dadosGeraisObrigatorios.cpf,
    cns: pacienteAgendamento.dadosPessoaisDocumentos.cns,
    estado: null,
    dataNascimento: pacienteAgendamento.dadosGeraisObrigatorios.dataNascimento,
    email: pacienteAgendamento.contatos.email,
    estadoCivil: pacienteAgendamento.dadosPessoaisDocumentos.estadoCivil,
    nome: pacienteAgendamento.dadosGeraisObrigatorios.nome,
    nomePai: pacienteAgendamento.dadosGeraisObrigatorios.nomePai,
    nomeSocial: pacienteAgendamento.dadosPessoaisDocumentos.nomeSocial,
    nomeMae: pacienteAgendamento.dadosGeraisObrigatorios.nomeMae,
    nomeResponsavel:
      pacienteAgendamento.dadosPessoaisDocumentos.nomeResponsavelLegal,
    nomeConjuge: pacienteAgendamento.dadosPessoaisDocumentos.nomeConjuge,
    rg: pacienteAgendamento.dadosPessoaisDocumentos.rgNumero,
    nomeContratante:
      pacienteAgendamento.dadosPessoaisDocumentos.nomeContratante,
    profissao: pacienteAgendamento.dadosProfissionais.nomeEmpresa,
    nacionalidade: pacienteAgendamento.dadosPessoaisDocumentos.nacionalidade,
    cnpj: pacienteAgendamento.dadosProfissionais.cnpj,
    peso: pacienteAgendamento.dadosPessoaisDocumentos.peso,
    altura: pacienteAgendamento.dadosPessoaisDocumentos.altura,
    foto: pacienteAgendamento.foto,
    urlFoto: pacienteAgendamento.urlFoto,
    usuarioLogin: null,
    senha: pacienteAgendamento.senha,
    tituloEleitorInscricao: pacienteAgendamento.tituloEleitorInscricao,
    tituloEleitorZona: pacienteAgendamento.tituloEleitorZona,
    tituloEleitorSecao: pacienteAgendamento.tituloEleitorSecao,
    pis: pacienteAgendamento.pis,
    cnh: pacienteAgendamento.cnh,
    certidaoNascimento: pacienteAgendamento.certidaoNascimento,
    passaporte: pacienteAgendamento.dadosPessoaisDocumentos.passaporte,
    idSexo: pacienteAgendamento.dadosGeraisObrigatorios.idSexo,
    sexo: pacienteAgendamento.dadosGeraisObrigatorios.sexo,
    excluido: pacienteAgendamento.excluido,
    idOcupacao: pacienteAgendamento.dadosProfissionais.idOcupacao,
    contatos: pacienteAgendamento.contatos.contatos,
    telefonePrincipal: pacienteAgendamento.contatos.contatos.find(
      (contato: any) => contato.tipo === 'CELULAR_PARTICULAR',
    ).numero,
    enderecos: pacienteAgendamento.enderecos.enderecos,
    enderecoResidencial: pacienteAgendamento.enderecos.enderecos.find(
      (endereco: any) => endereco.tipo === 'RESIDENCIAL',
    ),
    enderecoComercial: pacienteAgendamento.enderecos.enderecos.find(
      (endereco: any) => endereco.tipo === 'COMERCIAL',
    ),
    cartoes: pacienteAgendamento.cartoes,
    idade: pacienteAgendamento.idade,
    estrangeiro: pacienteAgendamento.dadosGeraisObrigatorios.estrangeiro,
    maeDesconhecida:
      pacienteAgendamento.dadosGeraisObrigatorios.maeDesconhecida,
    status: pacienteAgendamento.status,
    tipoPaciente: pacienteAgendamento.tipoPaciente,
    religiao: pacienteAgendamento.dadosPessoaisDocumentos.religiao,
    dataUltimoAtendimento: null,
    cids: null,
    idsAplicacao: null,
  };
};


