import dayjs from 'dayjs';

import { useAppSelector } from 'src/core/redux/hooks';

import Avatar from 'src/components/Avatar/Avatar';
import Badge from 'src/components/Badge/Badge';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { chatUserStatus } from '../../helpers';

import './style.scss';

export const ChatListItem = ({
  onClick,
  item,
}: {
  onClick(): void;
  item: IChatUsuario;
}) => {
  const { idUsuario } = useAppSelector(state => state.user);

  const isSender = item.chatMensagem?.idUsuarioEnvio === idUsuario;

  return (
    <div
      className="chat-list-item p-d-flex p-col-12 p-ai-center p-pl-0 p-pt-0"
      onClick={onClick}
    >
      <div className="relative p-col-2">
        <Avatar
          className="chat-list-item-avatar"
          label={item.nome}
          size="large"
        />
        <span
          className="chat-list-avatar-badge"
          data-status={
            chatUserStatus[item.statusUsuario?.toUpperCase()] || 'offline'
          }
        />
      </div>
      <div className="p-d-flex p-flex-column p-gap-2 p-col-8">
        <SimpleText medium className="p-text-truncate" fontSize="sm">
          {item.nome}
        </SimpleText>

        <SimpleText className="p-text-truncate p-px-0">
          {item.chatMensagem?.mensagem && isSender && (
            <i className="fas fa-check p-mr-1 sended-message-i" />
          )}
          {item.chatMensagem?.mensagem}
        </SimpleText>
      </div>
      <div
        className="p-col-2 p-d-flex p-flex-column p-ai-end p-jc-end p-gap-2"
        style={{
          height: '54px',
        }}
      >
        {item.chatMensagem?.dataInclusao &&
          item.chatMensagem.status === 'ENVIADA' &&
          !isSender && (
            <Badge
              severity="warn"
              value="•"
              size="normal"
              style={{
                width: 'fit-content',
                minWidth: '20px',
              }}
            />
          )}
        {item.chatMensagem?.dataInclusao && (
          <SimpleText fontSize="xxs" fontColor="color_60">
            {dayjs(item.chatMensagem.dataInclusao).format('HH:mm')}
          </SimpleText>
        )}
      </div>
    </div>
  );
};
