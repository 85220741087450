import { useState, useEffect, useCallback, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import TipoMotivoFaltaAPI from 'src/APIs/ConfigAPI/TipoMotivoFalta/TipoMotivoFaltaAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Skeleton from 'src/components/Skeleton/Skeleton';

import './ConfirmarFalta.scss';

interface ConfirmarFaltaProps {
  visible: boolean;
  onConfirm: (idMotivoFalta?: number) => void;
  onHide: () => void;
}

function ConfirmarFalta({ visible, onConfirm, onHide }: ConfirmarFaltaProps) {
  const { consultorios } = useAppSelector((state: RootState) => state);

  const validationSchema = Yup.object().shape({
    ...(consultorios.ativo.exibirMotivoFalta && {
      idMotivoFalta: Yup.string().required('Selecione o motivo da falta.'),
    }),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [motivosFalta, setMotivosFalta] = useState<TipoMotivoFalta[]>([]);
  const [loadingMotivoFalta, setLoadingMotivoFalta] = useState<boolean>(true);

  const loadMotivoFalta = useCallback(async () => {
    setLoadingMotivoFalta(true);

    const response = await TipoMotivoFaltaAPI.getMotivoFaltaByConsultorio(
      consultorios?.ativo?.id,
    );

    if (!('status' in response)) setMotivosFalta(response);

    setLoadingMotivoFalta(false);
  }, [consultorios?.ativo?.id]);

  useEffect(() => {
    loadMotivoFalta();
  }, [loadMotivoFalta]);

  const onSubmit = async ({ idMotivoFalta }: any) => {
    await onConfirm(Number(idMotivoFalta));

    onHide();
  };

  const motivosFaltaOptions = useMemo(
    () =>
      motivosFalta.map((motivoFalta: TipoMotivoFalta) => ({
        label: motivoFalta.descricao,
        value: motivoFalta.id,
      })),
    [motivosFalta],
  );

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        className="p-col-align-center"
        onClick={onHide}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="submit"
        btnType="danger"
        label="Confirmar falta"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        stretch
      />
    </div>
  );

  const modalHeader = consultorios.ativo.exibirMotivoFalta
    ? 'Confirmação de paciente ausente'
    : 'Confirmação de falta do paciente';

  const description = consultorios.ativo.exibirMotivoFalta
    ? `Selecione o motivo da falta para confirmar a ausência do paciente. 
    A falta constará no histórico do paciente.`
    : `Você confirma a falta do paciente? A ausência constará no 
    histórico do paciente.`;

  return (
    <Dialog
      className="confirmar-falta-modal"
      visible={visible}
      header={modalHeader}
      onHide={onHide}
      footer={renderFooter}
    >
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_40}>{description}</SimpleText>

        {consultorios.ativo.exibirMotivoFalta && (
          <form className="p-mt-5" onSubmit={handleSubmit(onSubmit)}>
            <Skeleton
              loading={loadingMotivoFalta}
              height="40px"
              borderRadius="8px"
            >
              <DropdownControlled
                name="idMotivoFalta"
                control={control}
                label="Motivo da falta"
                placeholder="Selecione"
                options={motivosFaltaOptions}
                errorMsg={errors?.idMotivoFalta?.message}
              />
            </Skeleton>
          </form>
        )}
      </div>
    </Dialog>
  );
}

export default ConfirmarFalta;
