import Http, { HttpParams } from 'src/core/http/Http';

const loadChatMensagem = async (
  params: {
    idUsuarioDestino: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IChatMessageItem[]>> =>
  Http.get({
    service: 'adm',
    url: `/chat-mensagem`,
    params,
    hideToast: true,
    ...options,
  });

const sendChatMensagem = async (
  params: IChatMessagePayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `/chat-mensagem/enviar`,
    data: params,
    hideToast: true,
    ...options,
  });

const loadChatListagem = async (
  params: {
    idConsultorio: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IChatUsuario[]>> =>
  Http.get({
    service: 'adm',
    url: `/chat-mensagem/usuarios`,
    params,
    hideToast: true,
    ...options,
  });

const loadChatNotificacao = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IChatNotificacao[]>> =>
  Http.get({
    service: 'adm',
    url: `/notificacoes/chat-mensagem`,
    hideToast: true,
    ...options,
  });

const sendLerMensagem = async (
  params: {
    idUsuarioDestino: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `/chat-mensagem/ler-mensagem/${params.idUsuarioDestino}`,
    hideToast: true,
    errorHideToast: true,
    ...options,
  });

const toggleChatStatus = async (
  status: IChatMessageStatus,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.post({
    service: 'adm',
    url: `/chat-mensagem/alterar-status`,
    data: {
      status,
    },
    hideToast: true,
    ...options,
  });

const loadChatStatus = async (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IChatStatusResponse> =>
  Http.get({
    service: 'adm',
    url: `/chat-mensagem/usuario-logado`,
    hideToast: true,
    ...options,
  });

export const ChatAPI = {
  loadChatMensagem,
  sendChatMensagem,
  loadChatListagem,
  loadChatNotificacao,
  sendLerMensagem,
  toggleChatStatus,
  loadChatStatus,
};
