import { useState } from 'react';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import DialogFamiliar from './ManterFamiliar/components/DialogFamiliar/DialogFamiliar';
import { Button } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import SelectButton from 'src/components/SelectButton/SelectButton';

import BarraLateralProntuario from '../Atendimento/IniciarAtendimento/BarraLateralProntuario/BarraLateralProntuario';
import { Telemedicina } from '../Atendimento/Telemedicina/Telemedicina';

import { Genograma } from './Genograma/Genograma';
import ManterFamiliar from './ManterFamiliar/ManterFamiliar';

import './GenogramaFamiliar.scss';

interface GenogramaFamiliarProps {
  idPaciente: number | null;
}

const GenogramaFamiliar = ({ idPaciente }: GenogramaFamiliarProps) => {
  const { atendimentoStatus } = useAtendimento();
  const [funcionalidade, setFuncionalidade] = useState<
    'FAMILIAR' | 'GENOGRAMA'
  >('FAMILIAR');

  const { showBarraLateral, isTeleatendimento } = useAtendimento();
  const novoFamiliarDialog = useDisclosure({ opened: false });
  const manterFamiliarDialog = useDisclosure({ opened: false });

  return (
    <div id="genograma-familiar" className="p-grid">
      <div
        className={
          showBarraLateral || isTeleatendimento
            ? 'p-col-12 p-sm-9 '
            : 'p-col-12 p-sm-11 '
        }
      >
        {atendimentoStatus !== 'ATENDENDO' ? (
          <AlertBox visible text="Inicie ou continue o atendimento. " />
        ) : (
          <ElevatedCard
            className="bg-neutral-color p-d-flex p-flex-column p-gap-3"
            style={{
              minHeight: 'calc(100vh - 110px)',
            }}
            noMargin
          >
            <div className="p-d-flex p-ai-center p-jc-between ">
              <SimpleText fontSize="md" medium>
                Familiar e Genograma
              </SimpleText>

              <Button
                btnType="tonal"
                icon="fas fa-user-plus"
                label="Novo Familiar"
                onClick={() =>
                  funcionalidade === 'FAMILIAR'
                    ? manterFamiliarDialog.open()
                    : novoFamiliarDialog.open()
                }
              />
            </div>

            <SelectButton
              className="p-m-0"
              options={[
                { label: 'Familiar', value: 'FAMILIAR' },
                { label: 'Genograma', value: 'GENOGRAMA' },
              ]}
              value={funcionalidade}
              unselectable={false}
              onChange={e => setFuncionalidade(e.value)}
            />

            <ManterFamiliar
              idPaciente={idPaciente}
              manterFamiliarDialog={manterFamiliarDialog}
              opened={funcionalidade === 'FAMILIAR'}
            />
            <Genograma
              idPaciente={idPaciente!}
              opened={funcionalidade === 'GENOGRAMA'}
            />
          </ElevatedCard>
        )}
      </div>
      <div
        id="barra-lateral-prontuario"
        className={showBarraLateral || isTeleatendimento ? 'p-sm-3' : 'p-sm-1'}
      >
        {isTeleatendimento && <Telemedicina />}
        <BarraLateralProntuario idPaciente={idPaciente} />
      </div>
      {novoFamiliarDialog.isOpen && (
        <DialogFamiliar {...novoFamiliarDialog} idPaciente={idPaciente} />
      )}
    </div>
  );
};

export default GenogramaFamiliar;
