import Http, { HttpParams } from 'src/core/http/Http';

const baseUrl = '/contas-receber';

const getContasReceber = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IContaReceberItem[]>> => {
  return Http.get({
    service: 'adm',
    url: `${baseUrl}`,
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

const getContaReceberById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IContaReceberItem> => {
  return Http.get({
    service: 'adm',
    url: `${baseUrl}/${id}`,
    hideToast: true,
    throwError: true,
    ...options,
  });
}

const postContaReceber = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IContaReceberPayload> => {
  return Http.post({
    service: 'adm',
    url: `${baseUrl}`,
    data,
    throwError: true,
    ...options,
  });
};

const putContaReceber = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IContaReceberPayload> => {
  return Http.put({
    service: 'adm',
    url: `${baseUrl}`,
    data,
    throwError: true,
    ...options,
  });
}

const putStatusConta = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${baseUrl}/status`,
    data,
    throwError: true,
    ...options,
  });
}

const deleteContaReceber = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${baseUrl}/${id}`,
    throwError: true,
    ...options,
  });
}

const getRelatorioPdf = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'report',
    url: `relatorios/recebiveis/pdf`,
    params,
    hideToast: true,
    responseType: 'arraybuffer',
    throwError: true,
    return: true,
    ...options,
  });
};

export const getRelatorioExcel = async (
  params: any,
  typeExport: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'report',
    url: `relatorios/recebiveis/excel`,
    params,
    responseType: 'blob',
    return: true,
    ...options,
  });
};


export const ContasReceberAPI = {
  getContasReceber,
  getContaReceberById,
  putContaReceber,
  putStatusConta,
  deleteContaReceber,
 postContaReceber,
  getRelatorioPdf,
  getRelatorioExcel,
};
