import axios from 'axios';

import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/upload';

export const sendFileUpload = (
  payload: PostFileUploadDTO | File,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'storage',
    url: `${basePath}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateFileUpload = (
  url: string,
  payload: File,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return axios.put(url, payload, {
    headers: {
      'Content-Type': payload?.type,
    },
    ...options,
  });
};

export const sendImageComunicado = (
  payload: PostFileUploadDTO,
  onUploadProgress?: (e: any) => void,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostFileUploadDTO> => {
  return Http.post({
    service: 'storage',
    url: `${basePath}`,
    data: payload,
    return: true,
    hideToast: true,
    ...(onUploadProgress && { onUploadProgress }),
    ...options,
  });
};

const FileUploadAPI = {
  sendFileUpload,
  updateFileUpload,
  sendImageComunicado,
};

export default FileUploadAPI;
