import dayjs from 'dayjs';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import {
  capitalizeFirstLetter,
  checkAuth,
  enumToText,
  utcToShow,
} from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import { Checkbox } from 'src/components/_UI/Checkbox';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Tooltip from 'src/components/Tooltip/Tooltip';

interface PanelHeaderProps {
  item: IAgravoNotificacao;
  isCollapsed: boolean;
  onToggle: () => void;
  selectedAgravo: IAgravoNotificacao[];
  setSelectedAgravo: (selectedAgravo: any) => void;
  onChangeStatus: () => void;
  loadingStatus: boolean;
}

const PanelHeader = ({
  item,
  isCollapsed,
  onToggle,
  selectedAgravo,
  setSelectedAgravo,
  onChangeStatus,
  loadingStatus,
}: PanelHeaderProps) => {
  const listaCidsModal = useDisclosure({ opened: false });
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);

  const canChangeStatus = checkAuth(
    authorities,
    'ADM_MONITORAMENTO_NOTIFICACAO_ALTERAR',
  );

  return (
    <div className="p-grid p-d-flex p-py-2  p-jc-start bg-96 br-8px">
      <div className="p-col-2 p-d-flex">
        <>
          <Checkbox
            checked={selectedAgravo?.includes(item)}
            onChange={e => {
              if (e.checked) {
                setSelectedAgravo(
                  selectedAgravo.length > 0
                    ? [...selectedAgravo, item]
                    : [item],
                );
              } else {
                setSelectedAgravo(
                  selectedAgravo?.filter((i: any) => i !== item),
                );
              }
            }}
          />
          <div className="p-d-flex p-flex-column p-gap-2 p-ml-1">
            <SimpleText fontSize="xxs" fontColor="color_60">
              Agravo
            </SimpleText>
            <SimpleText fontSize="xs" fontColor="color_16">
              {item?.agravo.nome || ''}
            </SimpleText>
          </div>
        </>
      </div>
      <div className="p-col-2 p-py-1 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          CID
        </SimpleText>
        <div className="p-d-flex p-gap-1">
          {item.agravo?.cids?.slice(0, 3).map((cid: any) => (
            <div key={cid.id}>
              <Tooltip target={`.${cid.codigoCid + cid.id}`} />
              <SimpleText
                className={`comorbidades-ativas ${cid.codigoCid + cid.id}`}
                data-pr-tooltip={cid.nomeCid}
                data-pr-position="top"
              >
                {cid.codigoCid}
              </SimpleText>
            </div>
          ))}
          {item.agravo?.cids && item.agravo?.cids?.length > 3 && (
            <div
              className="cursor-pointer"
              onClick={() => listaCidsModal.open({ state: item.agravo?.cids })}
            >
              <SimpleText fontColor="primary" medium className="cursor-pointer">
                +{item.agravo?.cids?.length - 3}
              </SimpleText>
            </div>
          )}{' '}
        </div>
      </div>
      <div className="p-col p-py-1 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Autoridade de saúde
        </SimpleText>
        <SimpleText fontColor="color_40" fontSize="xs">
          {item.agravo?.autoridadeSaude
            ? enumToText(item.agravo?.autoridadeSaude)
            : ''}
        </SimpleText>
      </div>
      <div className="p-col-1 p-py-1 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Periodicidade
        </SimpleText>
        <SimpleText fontColor="color_40" fontSize="xs">
          {item.agravo?.periodicidade
            ? capitalizeFirstLetter(item.agravo?.periodicidade)
            : ''}
        </SimpleText>
      </div>
      <div className="p-col-1 p-py-1 p-d-flex p-flex-column p-gap-2 p-text-truncate">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Diagnostico
        </SimpleText>

        <SimpleText fontSize="xs" fontColor="color_16">
          {capitalizeFirstLetter(item?.diagnostico || '')}
        </SimpleText>
      </div>
      <div className="p-col p-py-1 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Data ocorrência
        </SimpleText>

        <SimpleText fontSize="xs" fontColor="color_16">
          {item.dataOcorrencia
            ? dayjs(item.dataOcorrencia).format('DD/MM/YYYY HH:mm')
            : ''}
        </SimpleText>
      </div>
      <div className="p-col p-py-1 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Status
        </SimpleText>

        <Dropdown
          className="p-w-100"
          value={item.notificado ? 'NOTIFICADO' : 'NAO_NOTIFICADO'}
          options={[
            { label: 'Notificado', value: 'NOTIFICADO' },
            { label: 'Não notificado', value: 'NAO_NOTIFICADO' },
          ]}
          disabled={loadingStatus || !canChangeStatus}
          filter={false}
          onChange={() => onChangeStatus()}
          type={item.notificado ? 'outline' : 'danger'}
        />
      </div>

      <div className="p-col-1 p-d-flex p-ai-center p-justify-end p-py-0 p-gap-1">
        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="gray"
          onClick={onToggle}
        />
      </div>
      {listaCidsModal.isOpen && <ListaCids {...listaCidsModal} />}
    </div>
  );
};

export default PanelHeader;

export const ListaCids = ({ state, close }: DisclosureType) => {
  return (
    <Dialog
      maximizedTabletSize
      onHide={close}
      visible
      header="Lista de CID"
      maximizedMobileSize
    >
      <>
        <div className="p-d-flex p-flex-column p-gap-2 p-gap-2 br-8px border-88 p-p-2">
          <SimpleText fontColor="color_60">CID</SimpleText>
          {state?.map((cid: any) => (
            <div
              key={cid.id}
              className="p-d-flex p-ai-center br-8px bg-96 p-w-100 p-p-2"
            >
              <SimpleText medium>
                {`(${cid.codigoCid}) ${cid.nomeCid}`}
              </SimpleText>
            </div>
          ))}
        </div>
        <Button
          className="p-mt-2"
          btnType="outline"
          label="Fechar"
          onClick={() => close()}
          stretch
        />
      </>
    </Dialog>
  );
};
