import { useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import ComorbidadeAPI from 'src/APIs/ProntuarioAPI/ComorbidadeAPI/ComorbidadeAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI/Button';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import RadioButtonControlled from 'src/components/Basics/RadioButtonControlled/RadioButtonControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import Toast from 'src/components/Basics/Toast/Toast';
import Dialog from 'src/components/Dialog/Dialog';
import DataOcorrencia from 'src/pages/Emed/Prontuario/components/DataOcorrencia/DataOcorrencia';

import { justificativaOcorrenciaRetroativaValidation } from '../../helpers';

import DropdownComorbidade from './DropdownComorbidade/DropdownComorbidade';

import './ModalNovaComorbidade.scss';
interface ModalNovaComorbidadeProps {
  visible: boolean;
  editComorbidade: Comorbidade | null;
  onHide: () => void;
  onSuccess: () => void;
  changeTabIndex: (target: string) => void;
}

function ModalNovaComorbidade({
  visible,
  editComorbidade,
  onHide,
  onSuccess,
  changeTabIndex,
}: ModalNovaComorbidadeProps) {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const { agenda } = useAppSelector((state: RootState) => state);

  const { atendimentoStatus } = useAtendimento();

  const validatioSchema = Yup.object().shape({
    dataOcorrencia: Yup.date().required('Informe a data da ocorrencia'),
    comorbidade: Yup.object().nullable().required('Selecione uma CID ou CIAP'),
    dataInicio: Yup.date().nullable().required('Informe a data de ínicio'),
    justificativaOcorrenciaRetroativa:
      justificativaOcorrenciaRetroativaValidation(
        editComorbidade?.dataInclusao,
      ),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validatioSchema),
  });

  useEffect(() => {
    reset({
      lembrete: false,
      situacao: 'ATIVO',
      estadoDiagnostico: 'SUSPEITO',
      nivelDiagnostico: 'PRINCIPAL',
    });

    if (editComorbidade) {
      setValue('descricao', editComorbidade?.descricao || '');
      setValue('dataInicio', new Date(editComorbidade.dataInicio));
      setValue('situacao', editComorbidade.situacao);
      setValue('lembrete', editComorbidade.lembrete);
      if (editComorbidade?.ciap?.[0]?.ciap) {
        const { id, ...rest } = editComorbidade.ciap?.[0]?.ciap;
        const value = [
          {
            id,
            ciap: { id, ...rest },
          },
        ];
        setValue('comorbidade', { name: 'ciap', value: value });
      }
      if (editComorbidade?.cid?.[0]?.cid) {
        const { id, ...rest } = editComorbidade.cid?.[0]?.cid;
        const value = [
          {
            id,
            cid: rest,
          },
        ];
        setValue('comorbidade', { name: 'cid', value: value });
      }
      if (editComorbidade?.estadoDiagnostico) {
        setValue('estadoDiagnostico', editComorbidade?.estadoDiagnostico);
      }
      if (editComorbidade?.nivelDiagnostico) {
        setValue('nivelDiagnostico', editComorbidade?.nivelDiagnostico);
      }
      if (editComorbidade?.justificativaOcorrenciaRetroativa) {
        setValue(
          'justificativaOcorrenciaRetroativa',
          editComorbidade.justificativaOcorrenciaRetroativa,
        );
      }
      if (editComorbidade?.dataFim)
        setValue('dataFim', new Date(editComorbidade.dataFim));
    }
  }, [visible, reset, editComorbidade, setValue]);

  const saveAction = useCallback(
    (payload: any) => {
      if (editComorbidade)
        return ComorbidadeAPI.alterarComorbidade(editComorbidade.id, payload);

      return ComorbidadeAPI.adicionarComorbidade(payload);
    },
    [editComorbidade],
  );

  const onSubmit = useCallback(
    async (data: any) => {
      const { comorbidade, ...rest } = data;

      if (comorbidade.value[0]?.id) {
        comorbidade.value[0].idCid = comorbidade.value[0].id;
        delete comorbidade.value[0].id;
      }

      const payload = {
        ...rest,
        [comorbidade.name]: comorbidade.value,
        idProfissionalSaude: agenda?.profissionalAtivo?.id,
        idAtendimento: editComorbidade?.idAtendimento || Number(idAtendimento),
        dataOcorrencia: dayjs(data.dataOcorrencia).format('YYYY-MM-DDTHH:mmZ'),
      };

      const response = await saveAction(payload);

      if (response?.status === 200 || response?.status === 201) {
        onSuccess();
        if (response.existePlanoCuidadoCidCiap) {
          const message = (
            <>
              Existe um plano de cuidado para este{' '}
              {payload.ciap ? 'CIAP' : 'CID'} clique{' '}
              <a
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline dotted',
                }}
                onClick={() => changeTabIndex('Plano de cuidado')}
              >
                aqui
              </a>{' '}
              para abrir a tela de plano de cuidado e adicionar ao paciente!
            </>
          );

          toast(<Toast />, {
            data: {
              type: 'warning',
              message: message,
              title: 'Atenção',
            },
          });
        }
        onHide();
      }
    },
    [
      agenda?.profissionalAtivo?.id,
      changeTabIndex,
      editComorbidade?.idAtendimento,
      idAtendimento,
      onHide,
      onSuccess,
      saveAction,
    ],
  );

  const renderFooter = () => (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button
        btnType="ghost"
        label="Cancelar"
        onClick={onHide}
        loading={isSubmitting}
        stretch
      />
      <Button
        type="submit"
        label="Salvar"
        onClick={handleSubmit(onSubmit)}
        loading={isSubmitting}
        stretch
      />
    </div>
  );

  const watchDataInicio = watch('dataInicio');

  useEffectSkipFirst(() => {
    if (!watchDataInicio) {
      setValue('dataFim', undefined);
    }
  }, [watchDataInicio]);

  const checkDisabledOnEdit = useMemo(() => {
    if (!editComorbidade) return false;

    return !(
      editComorbidade.idAtendimento === Number(idAtendimento) &&
      atendimentoStatus === 'ATENDENDO'
    );
  }, [atendimentoStatus, editComorbidade, idAtendimento]);

  return (
    <Dialog
      id="modal-nova-comorbidade"
      type="modal"
      header={editComorbidade ? 'Editar problema' : 'Novo problema'}
      visible={visible}
      onHide={onHide}
      footer={renderFooter}
      maximizedTabletSize
    >
      <form id="form-comorbidade" className="p-grid">
        <DataOcorrencia
          control={control}
          defaultValue={
            editComorbidade
              ? new Date(editComorbidade.dataOcorrencia)
              : new Date()
          }
          dataInclusao={editComorbidade?.dataInclusao}
          disabled={checkDisabledOnEdit}
        />

        <DropdownComorbidade control={control} disabled={!!editComorbidade} />

        <TextInputControlled
          className="p-col-12"
          control={control}
          name="descricao"
          label="Descrição"
          placeholder="Descrição"
          errorMsg={errors.descricao?.message}
          disabled={checkDisabledOnEdit}
        />

        <CalendarInputControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="dataInicio"
          label="Data de início"
          placeholder="dd/mm/aaaa"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          errorMsg={errors.dataInicio?.message}
          disabled={checkDisabledOnEdit}
          maxDate={new Date()}
        />

        <CalendarInputControlled
          className="p-col-12 p-sm-6"
          control={control}
          name="dataFim"
          label="Data de fim"
          placeholder="dd/mm/aaaa"
          dateFormat="dd/mm/yy"
          mask="99/99/9999"
          minDate={watchDataInicio}
          maxDate={new Date()}
          errorMsg={errors.dataFim?.message}
          disabled={!watchDataInicio}
        />

        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_16} medium>
          Situação
        </SimpleText>

        <div className="p-col-12 p-sm-3">
          <RadioButtonControlled
            control={control}
            name="situacao"
            label="Ativa"
            value="ATIVO"
          />
        </div>
        <div className="p-col-12 p-sm-3">
          <RadioButtonControlled
            control={control}
            name="situacao"
            label="Inativa"
            value="INATIVO"
          />
        </div>
        <div className="p-col-12 p-sm-3">
          <RadioButtonControlled
            control={control}
            name="situacao"
            label="Resolvida"
            value="RESOLVIDO"
          />
        </div>

        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_16} medium>
          Diagnóstico
        </SimpleText>

        <div className="p-col-12 p-sm-4">
          <RadioButtonControlled
            control={control}
            name="estadoDiagnostico"
            label="Suspeito"
            value="Suspeito"
          />
        </div>
        <div className="p-col-12 p-sm-4">
          <RadioButtonControlled
            control={control}
            name="estadoDiagnostico"
            label="Confirmado"
            value="Confirmado"
          />
        </div>

        <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_16} medium>
          Nível
        </SimpleText>

        <div className="p-col-12 p-sm-4">
          <RadioButtonControlled
            control={control}
            name="nivelDiagnostico"
            label="Principal"
            value="Principal"
          />
        </div>
        <div className="p-col-12 p-sm-4">
          <RadioButtonControlled
            control={control}
            name="nivelDiagnostico"
            label="Secundário"
            value="Secundário"
          />
        </div>

        <div className="p-col-12">
          <CheckboxControlled
            className="checkbox-small-nova-comorbidade"
            control={control}
            name="lembrete"
            label='Exibir em "lembretes"'
          />
        </div>
      </form>
    </Dialog>
  );
}

export default ModalNovaComorbidade;
