import React from 'react';

import { useNavigate } from 'react-router';

import dayjs from 'dayjs';

import { capitalizeFirstLetter, formatCPF } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

interface DadosCadastroProps {
  usuario: any;
}

const DadosCadastro = ({ usuario }: DadosCadastroProps) => {
  const navigate = useNavigate();
  return (
    <div className="p-w-100 p-my-5">
      <SimpleText medium fontColor="color_40">
        Dados de cadastro
      </SimpleText>
      <div className="menu-card p-w-100 p-px-2 p-py-3 p-gap-3 p-mt-2 p-d-flex p-flex-column">
        <SimpleText fontColor="color_60">
          Sexo: &nbsp;
          <strong className="color_black">
            {capitalizeFirstLetter(usuario?.dadosPessoais?.sexo?.descricao) ||
              ''}
          </strong>
        </SimpleText>
        <SimpleText fontColor="color_60">
          Nasceu em: &nbsp;
          <strong className="color_black">
            {usuario?.dadosPessoais?.dataNascimento
              ? dayjs(usuario?.dadosPessoais?.dataNascimento).format(
                  'DD/MM/YYYY',
                )
              : ''}
          </strong>
        </SimpleText>
        <SimpleText fontColor="color_60">
          CPF: &nbsp;
          <strong className="color_black">
            {usuario?.dadosPessoais?.documentos?.cpf
              ? formatCPF(usuario?.dadosPessoais?.documentos?.cpf)
              : ''}
          </strong>
        </SimpleText>
        <SimpleText fontColor="color_60">
          Contato: &nbsp;
          <strong className="color_black">
            {usuario?.emailProfissional || ''}
          </strong>
        </SimpleText>
        <div>
          <Button
            className="p-p-0"
            label="Editar cadastro completo"
            btnType="green-link"
            icon="fas fa-arrow-right"
            iconPos="right"
            onClick={() => {
              navigate(`/cadastros/usuario/cadastro/${usuario?.id}`);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DadosCadastro;
