import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/agravo';

const getAgravoList = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: basePath,
    params,
    throwError: true,
    hideToast: true,
    ...options,
  });
};

export const getAgravoById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    throwError: true,
    hideToast: true,
    ...options,
  });
};

export const postAgravo = (
  payload: IParamAgravoItem,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: basePath,
    data: payload,
    throwError: true,
    ...options,
  });
};

export const putAgravo = (
  payload: IParamAgravoItem,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: basePath,
    data: payload,
    throwError: true,
    ...options,
  });
};

export const putAgravoStatus = (
  payload: { id: number; status: 'ATIVO' | 'INATIVO' },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/status`,
    data: payload,
    throwError: true,
    ...options,
  });
};

export const ParametrizacaoAgravoAPI = {
  getAgravoList,
  getAgravoById,
  postAgravo,
  putAgravo,
  putAgravoStatus,
};
