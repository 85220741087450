import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/tipo-conta-receber';

export const getTipoContaReceber = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IResponseTipoConta[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}`,
    params: params,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const getTipoContaReceberById = (
  id: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IResponseTipoConta> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const postTipoContaReceber = (
  data: IPostTipoConta,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}`,
    data,
    throwError: true,
    ...options,
  });
};

export const putTipoContaReceber = (
  id: any,
  data: IPostTipoConta,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}`,
    data,
    throwError: true,
    return: true,

    ...options,
  });
};

export const deleteTipoContaReceber = (
  id: number | string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${basePath}/${id}`,
    throwError: true,
    return: true,
    ...options,
  });
};

const TipoContaReceberAPI = {
  getTipoContaReceber,
  getTipoContaReceberById,
  postTipoContaReceber,
  putTipoContaReceber,
  deleteTipoContaReceber,
};

export default TipoContaReceberAPI;
