import Http, { HttpParams } from 'src/core/http/Http';

const baseUrl = '/contas-pagar';

const getContasPagar = (
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IContaPagarItem[]>> => {
  return Http.get({
    service: 'adm',
    url: `${baseUrl}`,
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

const getContaPagarById = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IContaPagarItem> => {
  return Http.get({
    service: 'adm',
    url: `${baseUrl}/${id}`,
    hideToast: true,
    throwError: true,
    ...options,
  });
}

const postContaPagar = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IContaPagarPayload> => {
  return Http.post({
    service: 'adm',
    url: `${baseUrl}`,
    data,
    throwError: true,
    ...options,
  });
};

const putContaPagar = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IContaPagarPayload> => {
  return Http.put({
    service: 'adm',
    url: `${baseUrl}`,
    data,
    throwError: true,
    ...options,
  });
}

const putStatusConta = (
  data: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${baseUrl}/status`,
    data,
    throwError: true,
    ...options,
  });
}

const deleteContaPagar = (
  id: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.delete({
    service: 'adm',
    url: `${baseUrl}/${id}`,
    throwError: true,
    ...options,
  });
}

const gerarRelatorioPdf = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'report',
    url: `relatorios/contas-pagar/pdf`,
    params,
    hideToast: true,
    responseType: 'arraybuffer',
    return: true,
    throwError: true,
    ...options,

  });
};

export const gerarRelatorioExcel = async (
  params: any,
  typeExport: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'report',
    url: `relatorios/contas-pagar/excel`,
    params,
    responseType: 'blob',
    return: true,
    ...options,
  });
};

export const ContasPagarAPI = {
  getContasPagar,
  getContaPagarById,
  putContaPagar,
  putStatusConta,
  deleteContaPagar,
  postContaPagar,
  gerarRelatorioPdf,
  gerarRelatorioExcel,
};
