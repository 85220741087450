import { useCallback, useEffect, useState } from 'react';

import './ModalAddFornecedorTuss.scss';

import { Controller, FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import FornecedorTussAPI from 'src/APIs/AdminAPI/FornecedorTussAPI/FornecedorTussAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { Button } from 'src/components/_UI/Button';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';

const formFields = {
  nome: '',
  modulo: '',
};

interface ModalAddFornecedorTussProps {
  visible: boolean;
  onHide(): void;
  reload: any;
  fornecedorTussSelecionado: any;
}

const ModalAddFornecedorTuss = ({
  fornecedorTussSelecionado,
  visible,
  onHide,
  reload,
}: ModalAddFornecedorTussProps) => {
  const { user } = useAppSelector((state: RootState) => state);

  const [hasFornecedorTussSelecionado, setHasFornecedorTussSelecionado] =
    useState(false);

  const validationSchema = Yup.object().shape({
    nome: Yup.string().required('Digite um nome para o Fornecedor'),
    modulo: Yup.string().required('Selecione um módulo'),
  });

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      nome: fornecedorTussSelecionado?.nome || '',
      modulo: fornecedorTussSelecionado?.modulo || '',
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = formMethods;

  const loadPage = useCallback(() => {
    if (Object.keys(fornecedorTussSelecionado).length > 0) {
      setValue('nome', fornecedorTussSelecionado?.nome || '');
      setHasFornecedorTussSelecionado(true);
    } else {
      setHasFornecedorTussSelecionado(false);
    }
  }, [fornecedorTussSelecionado, setValue]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  const handleCancel = useCallback(() => {
    reset(formFields);
    onHide();
  }, [onHide, reset]);

  const handleFornecedorTuss = useCallback(
    async (data: any) => {
      try {
        const fornecedorTussEditado = {
          ...fornecedorTussSelecionado,
          nome: data.nome,
          status: true,
          idUsuario: user?.idUsuario,
          idEmpresa: user?.idEmpresa,
          modulo: data.modulo,
        };

        if (hasFornecedorTussSelecionado) {
          await FornecedorTussAPI.updateFornecedorTuss(
            fornecedorTussSelecionado.id!,
            fornecedorTussEditado,
          );
        } else {
          await FornecedorTussAPI.sendFornecedorTuss(fornecedorTussEditado);
        }

        reload();
        handleCancel();
      } catch (error) {
        console.error(error);
      }
    },
    [
      fornecedorTussSelecionado,
      user?.idUsuario,
      user?.idEmpresa,
      hasFornecedorTussSelecionado,
      reload,
      handleCancel,
    ],
  );

  return (
    <Dialog
      className="footer-on"
      type="modal"
      header={
        hasFornecedorTussSelecionado
          ? 'Editar fornecedor TUSS'
          : 'Adicionar fornecedor TUSS'
      }
      visible={visible}
      onHide={handleCancel}
      maximizedMobileSize
    >
      <FormProvider {...formMethods}>
        <form
          className="modal-body-add-fornecedor-tuss"
          onSubmit={handleSubmit(handleFornecedorTuss)}
          defaultValue={''}
          onReset={() => reset(formFields)}
        >
          <div className="p-grid">
            <FormInput
              className="p-col-12 p-sm-8"
              name="nome"
              label="Nome do fornecedor"
              errorMsg={errors.nome?.message}
              maxLength={50}
            />

            <DropdownControlled
              filter={false}
              className="p-col-12 p-sm-4"
              name="modulo"
              label="Módulo"
              control={control}
              options={[
                { label: 'Configuração TUSS', value: 'CONFIGURACAO_TUSS' },
                { label: 'Financeiro', value: 'FINANCEIRO' },
              ]}
            />
          </div>

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              btnType="ghost"
              label="Voltar"
              onClick={handleCancel}
              stretch
              loading={isSubmitting}
            />
            <Button
              label={'Salvar'}
              type="submit"
              onClick={handleSubmit(handleFornecedorTuss)}
              stretch
              loading={isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ModalAddFornecedorTuss;
