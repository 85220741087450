import { IndicadoresLineChart } from '../_components/IndicadoresLineChart';
import { IndicadoresSistema } from '../_components/IndicadoresSistema';
import { IndicadoresTotais } from '../_components/IndicadoresTotais';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { useDashboard } from '../_hooks/useDashboard';

import { DashboardFilter } from './DashboardFilter';

export const DashboardContentMaster = ({}) => {
  const { transacoesTiss, usuariosPendentes } = useDashboard();

  return (
    <div className="p-w-100 p-d-flex p-flex-wrap p-ai-end p-mt-2">
      <div className="p-col-12 p-md-6 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xs" fontColor="color_40" medium>
          Usuários ativos por mês
        </SimpleText>
        <IndicadoresLineChart
          data={{
            labels: usuariosPendentes.map(({ mes }) => mes).reverse(),
            values: usuariosPendentes
              .map(({ quantidade }) => quantidade)
              .reverse(),
          }}
        />
      </div>

      <div className="p-col-12 p-md-6">
        <IndicadoresSistema type={1} />
      </div>
      <div className="p-col-12 p-md-6">
        <IndicadoresSistema type={2} />
      </div>

      <div className="p-col-12 p-md-6 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xs" fontColor="color_40" medium>
          Transações TISS realizadas por mês
        </SimpleText>
        <IndicadoresLineChart
          data={{
            labels: transacoesTiss.map(({ mes }) => mes).reverse(),
            values: transacoesTiss
              .map(({ quantidade }) => quantidade)
              .reverse(),
          }}
        />
      </div>

      <div className="p-col-12 p-md-6 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xs" fontColor="color_40" medium>
          Indicadores totais
        </SimpleText>
        <IndicadoresTotais />
      </div>

      <DashboardFilter />
    </div>
  );
};
