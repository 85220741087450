import React, { useCallback, useEffect, useState } from 'react';

import {
  useForm,
  useFieldArray,
  FormProvider,
  useFormContext,
  UseFieldArrayUpdate,
} from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import PedidosCuidadoAPI from 'src/APIs/ProntuarioAPI/PedidosCuidadoAPI/PedidosCuidadoAPI';
import { PlanoCuidadoPacienteAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoPacienteAPI/PlanoCuidadoPacienteAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import { FieldSearchProfissionalSaude } from 'src/components/Fields/FieldSearchProfissionalSaude/FieldSearchProfissionalSaude';
import Skeleton from 'src/components/Skeleton/Skeleton';

import AgendamentoPedidoCuidado from './AgendamentoPedidoCuidado';

import './Dialog.scss';

const defaultValue = [
  {
    data: new Date(),
    hora: undefined,
  },
];

interface Props extends DisclosureType {
  editPedidoCuidado?: any;
  idx: number;
  handleUpdate: UseFieldArrayUpdate<any>;
}

export function DialogCuidadoPlanoCuidado({
  isOpen,
  close,
  editPedidoCuidado,
  handleUpdate,
  idx,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFieldTipo, setIsLoadingFieldTipo] = useState(false);

  const { consultorios } = useAppSelector((state: RootState) => state);
  const formContext = useFormContext();

  const field = formContext.watch(`planos.${idx}`);
  const formValues = formContext.watch();

  const [listaTiposPedidosCuidado, setListaTiposPedidosCuidado] = useState<
    any[]
  >([]);

  const validationSchema = Yup.object().shape({
    tipo: Yup.object()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
    profissionalSaude: Yup.object()
      .required('Campo obrigatório')
      .typeError('Campo obrigatório'),
    pedidosCuidado: Yup.array()
      .min(1, 'Deve ter pelo menos um agendamento')
      .of(
        Yup.object({
          data: Yup.date()
            .nullable()
            .required('Campo obrigatório')
            .typeError('Campo obrigatório'),
          hora: Yup.date()
            .nullable()
            .required('Campo obrigatório')
            .typeError('Campo obrigatório'),
        }),
      ),
  });

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      tipo: field.tipo ?? { id: field.idTipoCuidado },
      profissionalSaude: field.profissionalSaude,
      pedidosCuidado: !!field.pedidosCuidado?.length
        ? field.pedidosCuidado
        : defaultValue,
    } as any,
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
  } = useFormMethods;

  const {
    fields: agendamentosFields,
    append: appendAgendamentosFields,
    remove: removeAgendamentosFields,
    replace,
  } = useFieldArray({
    control,
    name: 'pedidosCuidado',
    keyName: 'idForm',
  });

  const watchProfissionalSaude = watch('profissionalSaude');

  useEffect(() => {
    const getTiposPedidosCuidado = async () => {
      setIsLoadingFieldTipo(true);

      const response = await PedidosCuidadoAPI.getTiposPedidoCuidado({
        ativo: true,
        idConsultorio: consultorios?.ativo?.id,
      });
      setListaTiposPedidosCuidado(
        Array.isArray(response?.data) ? response?.data : [response?.data],
      );
      setIsLoadingFieldTipo(false);
    };

    getTiposPedidosCuidado();
  }, [consultorios?.ativo?.id]);

  const onSubmit = useCallback(
    (values: any) => {
      handleUpdate(idx, { ...field, ...values });
      close();
    },
    [close, field, handleUpdate, idx],
  );

  useEffectSkipFirst(() => {
    if (!!field.pedidosCuidado?.length || !watchProfissionalSaude) return;

    setIsLoading(true);
    PlanoCuidadoPacienteAPI.generatePlanoCuidadoRecorrencia({
      idPlanoCuidado: formValues.planoCuidado?.id || formValues?.id,
      idPlanoCuidadoPaciente: formValues.idAtendimentoOrigem
        ? formValues.id
        : undefined,
      recorrencia: field.recorrencia,
    })
      .then(d => {
        const datas = d.datasSugeridas.map((date: any) => ({
          data: new Date(date),
        }));
        replace(datas);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, [
    field.pedidosCuidado?.length,
    field.recorrencia,
    formValues.id,
    formValues.idAtendimentoOrigem,
    formValues.planoCuidado?.id,
    replace,
    watchProfissionalSaude,
  ]);

  return (
    <Dialog
      id="modal-add-pedido-cuidado"
      header="Pedido de cuidado"
      modal={false}
      visible={isOpen}
      onHide={() => {
        close();
      }}
    >
      <FormProvider {...useFormMethods}>
        <form
          onSubmit={e => [handleSubmit(onSubmit)(e), e.stopPropagation()]}
          className="p-d-flex p-flex-column"
        >
          <div className="p-d-flex p-w-100 p-my-2">
            <DropdownControlled
              control={control}
              label="Tipo de cuidado"
              options={listaTiposPedidosCuidado}
              optionLabel="descricao"
              name="tipo"
              dataKey="id"
              errorMsg={errors.tipo?.message}
              loading={isLoadingFieldTipo}
              className="p-col-6"
              disabled
            />

            <FieldSearchProfissionalSaude
              label="Profissional responsável"
              name="profissionalSaude"
              className="p-col-6"
            />
          </div>
          {watchProfissionalSaude && (
            <div className="data-hora p-col-12 p-px-2 p-py-1 p-mt-1">
              {isLoading ? (
                <LoadingComp />
              ) : (
                agendamentosFields.map((agendamento: any, index: number) => (
                  <AgendamentoPedidoCuidado
                    key={agendamento?.idForm}
                    agendamento={agendamento}
                    index={index}
                    onRemove={() => removeAgendamentosFields(index)}
                    isEditing={!!editPedidoCuidado}
                  />
                ))
              )}

              <Button
                label="Adicionar dias"
                btnType="green-link"
                type="button"
                onClick={() => {
                  appendAgendamentosFields({
                    data: null,
                    hora: null,
                    encaixe: false,
                  });
                }}
              />
            </div>
          )}

          {errors.pedidosCuidado?.message && (
            <div className="p-col-12">
              <SimpleText fontColor={FONT_COLOR.DANGER}>
                {errors.pedidosCuidado?.message}
              </SimpleText>
            </div>
          )}

          <div className="p-d-flex p-mt-2 p-gap-2">
            <Button
              btnType="ghost"
              label="Cancelar"
              stretch
              onClick={() => {
                close();
              }}
              loading={isSubmitting}
            />
            <Button
              type="submit"
              label="Salvar"
              stretch
              loading={isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
}

const LoadingComp = () => {
  const props = {
    loading: true,
    height: '30px',
    borderRadius: '8px',
  };

  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-col-12">
      <Skeleton {...props} />
      <Skeleton {...props} />
      <Skeleton {...props} />
    </div>
  );
};
