export const getColorStatus = (statusEnum: string) => {
  if (!statusEnum) return '';
  switch (statusEnum) {
    case 'Autorizado':
      return 'pendencias-status-verde';
    case 'Negado':
    case 'Solicitação cancelada':
      return 'pendencias-status-vermelho';
    case 'Autorizado parcialmente':
      return 'pendencias-status-preto';
    default:
      return 'pendencias-status-amarelo';
  }
};

export const getStatusString = (statusEnum: string) => {
  switch (statusEnum) {
    case 'Autorizado':
      return 'Autorizado';
    case 'Autorizado parcialmente':
      return 'Autorizado parcialmente';
    case 'Em análise':
      return 'Em análise';
    case 'Negado':
      return 'Negado';
    case 'Soliciatação cancelada':
      return 'Solicitação cancelada';
    default:
      return 'Invalid status';
  }
};
