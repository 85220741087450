import { useState, useEffect, useCallback } from 'react';

import { Skeleton } from 'primereact/skeleton';

import { CartoesPacienteAPI } from 'src/APIs/AdminAPI/CartoesPacienteAPI/CartoesPacienteAPI';

import Button, { BtnTypes } from 'src/components/Basics/Button/Buttons';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';

import CardPlanoSaude from './CardPlanoSaude';
import ModalPlanoSaude from './ModalPlanoSaude/ModalPlanoSaude';

import './ListaPlanoSaude.scss';

interface ListaPlanoSaudeProps {
  idPaciente: number;
}

const ListaPlanoSaude = ({ idPaciente }: ListaPlanoSaudeProps) => {
  const [cartoes, setCartoes] = useState<any[]>([]);
  const [modalPlanoSaudeVisible, setModalPlanoSaudeVisible] =
    useState<boolean>(false);
  const [editPlanoSaude, setEditPlanoSaude] = useState<any>(null);
  const [idPlanoSaudeExcluir, setIdPlanoSaudeExcluir] = useState<number | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(true);

  const loadPlanoSaudePaciente = useCallback(async () => {
    if (!idPaciente) return;

    setLoading(true);

    const response = await CartoesPacienteAPI.loadCartoesPaciente(idPaciente, {ignorarCartaoResponsavel: true});

    if (Array.isArray(response)) setCartoes(response);

    setLoading(false);
  }, [idPaciente]);

  useEffect(() => {
    loadPlanoSaudePaciente();
  }, [loadPlanoSaudePaciente]);

  const onSwitchStatus = async (status: boolean, idCartao: number) => {
    const { inactiveCartao, activeCartao } = CartoesPacienteAPI;

    const switchStatusCartao = status ? inactiveCartao : activeCartao;

    const response = await switchStatusCartao(idCartao);

    if (response?.status === 200) loadPlanoSaudePaciente();
  };

  const onHideModalPlanoSaude = () => {
    setModalPlanoSaudeVisible(false);
    setEditPlanoSaude(null);
  };

  const onEdit = (cartao: any) => {
    setEditPlanoSaude(cartao);
    setModalPlanoSaudeVisible(true);
  };

  const onRemove = async () => {
    if (!idPlanoSaudeExcluir) return;

    const response = await CartoesPacienteAPI.removerCartao(
      idPlanoSaudeExcluir,
    );

    if (response?.status === 204) {
      loadPlanoSaudePaciente();
      setIdPlanoSaudeExcluir(null);
    }
  };

  const handleSetCartaoPrincipal = (cartao: any) => {
    CartoesPacienteAPI.updateCartao(
      {
        ...cartao,
        principal: true,
      },
      {
        throwError: true,
      },
    )
      .then(() => loadPlanoSaudePaciente())
      .catch(() => {});
  };

  const cartoesLength = cartoes.filter(cartao => !!cartao?.status)?.length;

  const renderCards = () => {
    if (loading)
      return (
        <>
          <Skeleton width="100%" height="108px" borderRadius="8px" />
          <Skeleton
            className="p-my-2"
            width="100%"
            height="108px"
            borderRadius="8px"
          />
          <Skeleton width="100%" height="108px" borderRadius="8px" />
        </>
      );

    if (!cartoes.length)
      return (
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          Nenhum plano de saúde adicionado.
        </SimpleText>
      );

    return cartoes.map(cartao => (
      <CardPlanoSaude
        key={String(cartao.id)}
        cartao={cartao}
        onEdit={onEdit}
        onRemove={setIdPlanoSaudeExcluir}
        onSwitchStatus={onSwitchStatus}
        onSetCartaoPrincipal={() => handleSetCartaoPrincipal(cartao)}
      />
    ));
  };

  return (
    <div className="lista-plano-saude">
      <div className="lista-content">{renderCards()}</div>

      <Button
        className="p-mt-2 add-plano-btn"
        type="button"
        btnType={BtnTypes.TONAL}
        icon="fas fa-plus"
        label="Adicionar"
        onClick={() => setModalPlanoSaudeVisible(true)}
        checkPermission={['ADM_PACIENTE_CADASTRAR', 'ADM_PACIENTE_ALTERAR']}
      />

      {modalPlanoSaudeVisible ? (
        <ModalPlanoSaude
          idPaciente={idPaciente}
          visible={modalPlanoSaudeVisible}
          editPlanoSaude={editPlanoSaude}
          onHide={onHideModalPlanoSaude}
          onSuccess={loadPlanoSaudePaciente}
          cartoesLength={cartoesLength || 0}
        />
      ) : null}

      <ConfirmDialog
        visible={!!idPlanoSaudeExcluir}
        header="Excluir plano de saúde"
        text="O plano de saúde será excluído. Você realmente deseja excluir?"
        confirmText="Sim, excluir"
        onHide={() => setIdPlanoSaudeExcluir(null)}
        onConfirm={onRemove}
      />
    </div>
  );
};

export default ListaPlanoSaude;
