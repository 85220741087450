import React, { useState } from 'react';

import { IFamiliarPaciente } from 'src/APIs/ProntuarioAPI/FamiliarAPI/Familiar';
import { FamiliarAPI } from 'src/APIs/ProntuarioAPI/FamiliarAPI/FamiliarAPI';

import useSize from 'src/core/hooks/useSize';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import DesktopRow from './DesktopRow/DesktopRow';
import MobileRow from './MobileRow/MobileRow';

interface RowProps {
  familiar: IFamiliarPaciente;
  onEdit: (familiar: IFamiliarPaciente) => void;
  handleRelacionamentos: (familiar: IFamiliarPaciente) => void;
  reloadList: () => void;
}

const Row = ({
  familiar,
  onEdit,
  handleRelacionamentos,
  reloadList,
}: RowProps) => {
  const [loading, setLoading] = useState(false);
  const { isTablet } = useSize();

  const listaCidsModal = useDisclosure({ opened: false });

  const onDelete = async () => {
    try {
      setLoading(true);
      await FamiliarAPI.deleteFamiliar(familiar.id);
      reloadList();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const menuItens: any = [
    {
      label: 'Editar familiar',
      command: () => onEdit(familiar),
    },
    // {
    //   label: 'Relacionamentos',
    //   command: () => handleRelacionamentos(familiar),
    // },
    {
      label: 'Excluir',
      command: () => onDelete(),
      className: 'error',
    },
  ];

  const commonProps = {
    familiar,
    menuItens,
    listaCidsModal,
    loading,
  };

  if (isTablet) {
    return <MobileRow {...commonProps} />;
  }

  return <DesktopRow {...commonProps} />;
};

export default Row;

export const ListaCids = ({ state, close }: DisclosureType) => {
  return (
    <Dialog onHide={close} visible header="Lista de CID" maximizedMobileSize>
      <>
        <div className="p-d-flex p-flex-column p-gap-2 br-8px border-88 p-p-2">
          <SimpleText fontColor="color_60">CID</SimpleText>
          {state?.map((cid: any) => (
            <div
              key={cid.id}
              className="p-d-flex p-ai-center br-8px bg-96 p-w-100 p-p-2"
            >
              <SimpleText medium>{`(${cid.codigo}) ${cid.nome}`}</SimpleText>
            </div>
          ))}
        </div>
        <Button
          className="p-mt-2"
          btnType="outline"
          label="Fechar"
          onClick={() => close()}
          stretch
        />
      </>
    </Dialog>
  );
};
