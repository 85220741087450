import { Dispatch, SetStateAction, useMemo, useRef } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { OverlayPanel } from 'primereact/overlaypanel';
import { TieredMenu } from 'primereact/tieredmenu';
import { useNavigate } from 'react-router';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import {
  conteudoModalComunicado,
  copiarComunicadoPacientes,
  editarComunicadoPacientes,
} from '../helpers';

import { IConteudoModal } from './CardComunicado';

interface DesktopRowProps {
  data: ComunicadoDTO;
  setExibirModalComunicado: Dispatch<SetStateAction<boolean>>;
  setConteudoModal: Dispatch<SetStateAction<IConteudoModal>>;
}

export const DesktopRow: React.FC<DesktopRowProps> = ({
  data,
  setExibirModalComunicado,
  setConteudoModal,
}: any) => {
  const comunicadoRef = useRef<OverlayPanel>(null);

  const navigate = useNavigate();

  const itemsOptions = useMemo(
    () =>
      [
        {
          label: 'Copiar',
          command: () => copiarComunicadoPacientes(data, navigate),
          checkPermission: 'ADM_MARKETING_MEDICO_CADASTRAR',
        },
        data.status !== 'ATIVO' && {
          label: 'Editar',
          command: () => editarComunicadoPacientes(data, navigate),
          checkPermission: 'ADM_MARKETING_MEDICO_ALTERAR',
        },
        data.status === 'AGENDADO' && {
          label: 'Inativar',
          command: () => {
            setConteudoModal(conteudoModalComunicado['inativar']);
            setExibirModalComunicado(true);
          },
          checkPermission: 'ADM_MARKETING_MEDICO_ALTERAR',
        },
        data.status !== 'ATIVO' && {
          label: 'Excluir',
          command: () => {
            setConteudoModal(conteudoModalComunicado['excluir']);
            setExibirModalComunicado(true);
          },
          checkPermission: 'ADM_MARKETING_MEDICO_EXCLUIR',
          className: 'error',
        },
      ].filter(Boolean) as MenuItem[],
    [data, navigate, setConteudoModal, setExibirModalComunicado],
  );

  return (
    <>
      <div className="p-col-2">
        <SimpleText
          className={`status ${capitalizeFirstLetter(data.status)}`}
          fontSize={FONT_SIZE.XXS}
        >
          {capitalizeFirstLetter(data.status)}
        </SimpleText>
      </div>
      <div className="p-col-6">
        <div>
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            {data.titulo}
          </SimpleText>
        </div>
        <div>
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_16}
            lines={2}
          >
            {data.mensagem}
          </SimpleText>
        </div>
      </div>
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {data.tipoMensagem ? capitalizeFirstLetter(data.tipoMensagem) : ''}
        </SimpleText>
      </div>

      <div className="p-col-2 p-d-flex p-jc-end">
        <Button
          btnType="gray"
          onClick={e => {
            comunicadoRef.current?.toggle(e);
          }}
          icon="pi pi-ellipsis-h"
        />
        <TieredMenu
          className="tiered-menu"
          model={itemsOptions}
          popup
          ref={comunicadoRef}
        />
      </div>
    </>
  );
};
