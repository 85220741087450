import React, { useContext, useState } from 'react';

import {
  InputNumber as PrimeNumberInput,
  InputNumberChangeParams,
  InputNumberProps as PrimeInputNumberProps,
} from 'primereact/inputnumber';

import ThemeContext from 'src/core/themes/ThemeContext';

import ComponentsLabel from 'src/components/Basics/ComponentsLabel/ComponentsLabel';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import './Input.scss';

export type InputNumberProps = PrimeInputNumberProps & {
  label?: string;
  errorMsg?: string | undefined;
  icon?: string;
  classNameInput?: string;
  rounded?: boolean;
  hideTextErrorSpace?: boolean;
  maxLength?: number;
  maxLengthSpan?: boolean;
  onChange?: (e: any) => void;
  editOnClick?: boolean;
  labelOverflow?: 'visible' | 'hidden';
  sublabel?: string;
  loading?: boolean;
};

export const InputNumber = React.forwardRef<any, InputNumberProps>(
  (
    {
      label,
      name = '',
      errorMsg,
      className,
      onChange,
      disabled = false,
      icon,
      format = false,
      classNameInput,
      required,
      rounded,
      onBlur,
      hideTextErrorSpace,
      currency = 'BRL',
      value = null,
      maxLength,
      maxLengthSpan,
      editOnClick = false,
      labelOverflow = 'visible',
      sublabel = '',
      loading,
      max,
      min,
      ...rest
    },
    ref,
  ) => {
    const theme = useContext(ThemeContext);
    const [editOn, setEditOn] = useState(false);
    const [error, setError] = useState('');

    const renderLabel = () => {
      if (label || label === '' || required) {
        return (
          <ComponentsLabel
            overflowX={labelOverflow}
            name={name}
            label={label}
            sublabel={sublabel}
            required
          />
        );
      }
      return <></>;
    };

    if (editOnClick && !editOn) {
      return (
        <SimpleText
          className={`text-input-edit-only-click ${className}`}
          fontSize={FONT_SIZE.XXS}
          fontColor={FONT_COLOR.PRIMARY}
          onClick={() => !disabled && setEditOn(true)}
        >
          {value}
        </SimpleText>
      );
    }

    const handleChange = (e: InputNumberChangeParams) => {
      if (onChange) {
        onChange({
          ...e.originalEvent,
          target: {
            value: e.value,
          },
        });
      }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (
        max &&
        Number(e.target.value) <= max &&
        min &&
        Number(e.target.value) >= min
      ) {
        setError('');
      }

      if (editOnClick && editOn) {
        setEditOn(false);
      }

      if (onBlur) {
        onBlur({
          ...e,
        });
      }
    };

    const renderIcon = () => {
      if (!errorMsg) {
        if (loading) return <i className={'pi pi-spin pi-spinner'} />;
        else return <i className={`${icon}`} />;
      } else return <i className={'pi pi-exclamation-circle'} />;
    };

    return (
      <div
        className={`NumberInput ${theme?.theme || ''} ${
          rounded ? 'rounded' : ''
        } ${errorMsg ? 'error' : ''} ${disabled ? 'disabled-text-input' : ''} ${
          className || ''
        }`}
        key={name}
      >
        {renderLabel()}

        <span
          className={`iconHandler p-w-100 ${
            errorMsg || icon || loading ? 'p-input-icon-right' : ''
          }`}
        >
          {renderIcon()}

          <PrimeNumberInput
            {...rest}
            ref={ref}
            id={name}
            className={`${errorMsg ? 'p-invalid' : ''} ${classNameInput}`}
            disabled={disabled || !!loading}
            format={format}
            currency={currency}
            value={value}
            onKeyDown={e => {
              const isNumberKey = !isNaN(Number(e.key)) || e.key === '.';
              if (!isNumberKey) return;

              const predictedValue = e.currentTarget.value + e.key;

              const predictedValueFloat = parseFloat(predictedValue);
              if (maxLength && predictedValue.length > maxLength) {
                setError(`Máximo de ${maxLength} caracteres`);
                e.preventDefault();
              } else if (max !== undefined && predictedValueFloat > max) {
                setError(`Valor máximo de ${max}`);
                e.preventDefault();
              } else {
                setError('');
              }

              if (min !== undefined && predictedValueFloat < min) {
                setError(`Valor mínimo de ${min}`);
              }
            }}
            onBlur={e => handleBlur(e)}
            autoFocus={rest?.autoFocus || editOnClick}
            onChange={e => {
              if (max === undefined || Number(e.value) <= max) {
                handleChange(e);
              }
              if (max && Number(e.value) > max) {
                e.value = max;
                handleChange(e);
              }
              if (min && Number(e.value) < min) {
                e.value = min;
                handleChange(e);
              }
            }}
          />
        </span>

        <div
          className={`p-d-flex p-jc-between p-ai-center ${
            hideTextErrorSpace ? 'p-mt-0' : 'p-mt-1'
          }`}
        >
          <div style={{ minHeight: hideTextErrorSpace ? '0' : '14px' }}>
            <SimpleText className={'error'} fontSize={FONT_SIZE.XXXS}>
              {errorMsg || error || ''}
            </SimpleText>
          </div>
        </div>

        {maxLength && maxLengthSpan && (
          <SimpleText className={'p-text-normal'} fontSize={FONT_SIZE.XXS}>
            {`${value?.toString()?.length || 0}/${maxLength}`}
          </SimpleText>
        )}
      </div>
    );
  },
);
