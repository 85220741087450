import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';

import AdminAPI from 'src/APIs/AdminAPI/AdminAPI';
import ListaEsperaAPI from 'src/APIs/AgendaAPI/ListaEsperaAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { formatPhoneNumber, tipoEspera, turno } from './utils';
import { replaceSpecialChars, stringToDate } from 'src/utils/utils';

import { ModalAddListaEspera } from './components/ModalAddListaEspera';
import { PacienteAccordion } from './components/PacienteAccordion';
import { Button } from 'src/components/_UI';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import ChipSelect from 'src/components/ChipSelect/ChipSelect';
import Dialog from 'src/components/Dialog/Dialog';
import Separator from 'src/components/Separator/Separator';

export const BarraLateralMenuAgendaListaEspera = ({
  onClose,
  openSelectedHorarioDisponivelModal,
}: {
  onClose: () => void;
  openSelectedHorarioDisponivelModal: (paciente: any) => void;
}) => {
  const {
    agenda: { profissionalAtivo },
  } = useAppSelector(state => state);

  const modalAddListaEspera = useDisclosure({
    opened: false,
    onClose: () => setPaciente(null),
  });

  const [search, setSearch] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState<number>();
  const [waitlistItem, setWaitListItem] = useState<any>();
  const [waitlist, setWaitList] = useState<any>();
  const [confirmDelete, setConfirmDelete] = useState<any>({ visible: false });
  const [update, setUpdate] = useState(false);
  const [paciente, setPaciente] = useState<any>();

  const [especialidades, setEspecialidades] = useState<any>();
  const [tipoEsperaFilters, setTipoEsperaFilters] = useState<any>([]);
  const [turnoFilters, setTurnoFilters] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>('');

  const [firstWaitlist, setFirstWaitList] = useState<any[]>([]);

  const optionsItems = useCallback((list: any) => {
    const item = [
      {
        label: 'Editar informações',
        command: async () => {
          setWaitListItem(list);
          modalAddListaEspera.open();
          setPaciente(list.paciente);
          setUpdate(true);
        },
      },
      {
        label: 'Excluir da lista de espera',
        command: () => setConfirmDelete({ visible: true, list }),
      },
    ];

    return item;
  }, []);

  const filteredValues = useRef([]);

  const loadPage = useCallback(async () => {
    if (!profissionalAtivo) return;

    try {
      await ListaEsperaAPI.getListaEspera(profissionalAtivo.id).then(
        (res: any) => {
          const list: any = [];

          res.map((e: any) => {
            e.turnoFilter = [e?.turno?.toUpperCase()];

            e.tipoEsperaFilter = [
              e?.tipoEspera?.descricao?.replace(' ', '_').toUpperCase(),
            ];

            e.turno = [e?.turno?.toLowerCase()] || null;
            list.push(e);
          });

          setWaitList(list);
          setFirstWaitList(list);
        },
      );
    } catch (error) {
      console.error(error);
    }

    try {
      const espec = await AdminAPI.EspecialidadeAPI.loadEspecialidades('');

      setEspecialidades(espec);
    } catch (error) {
      console.error(error);
    }

    if (waitlist) {
      const list: any = [];
      waitlist?.map((item: any) => {
        item.data = [
          stringToDate(item?.dataPrefInicial || ''),
          stringToDate(item?.dataPrefFinal || ''),
        ];

        list.push(item);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profissionalAtivo]);

  const waitListFiltered = useMemo(() => {
    if (
      tipoEsperaFilters.includes('TODOS') ||
      (!turnoFilters.length && !tipoEsperaFilters.length)
    ) {
      return firstWaitlist;
    }

    const filtered = firstWaitlist.filter((list: any) => {
      const turno = turnoFilters.some((x: any) => list.turnoFilter.includes(x));
      const tipoEspera = tipoEsperaFilters.some((x: any) =>
        list.tipoEsperaFilter.includes(x),
      );

      if (!!turnoFilters.length && !!tipoEsperaFilters.length) {
        return turno && tipoEspera;
      }

      if (!!turnoFilters.length) {
        return turno;
      }

      return tipoEspera;
    });

    return filtered;
  }, [turnoFilters, tipoEsperaFilters, firstWaitlist]);

  const handleSearch = useCallback(
    (e: any) => {
      const { value } = e.target;
      setSearchValue(value);

      if (waitlist) {
        const results = waitlist?.filter((element: any) => {
          return (
            replaceSpecialChars(element.paciente.nome.toUpperCase()).includes(
              replaceSpecialChars(value.toUpperCase()),
            ) || element.contato === value
          );
        });

        filteredValues.current = results;
      }
    },
    [waitlist],
  );

  const handleChangeTipoEsperaFilter = (values: string[] = []) => {
    if (values.includes('TODOS') && !tipoEsperaFilters.includes('TODOS')) {
      setTurnoFilters([]);
      setTipoEsperaFilters(['TODOS']);
      return;
    }

    const removeTodos = values.filter((value: any) => value !== 'TODOS');

    setTipoEsperaFilters(removeTodos);
  };

  const handleChangeTurnoFilter = (values: string[] = []) => {
    if (tipoEsperaFilters.includes('TODOS')) {
      setTipoEsperaFilters([]);
    }

    setTurnoFilters(values);
  };

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  const items = !searchValue ? waitListFiltered : filteredValues.current;

  return (
    <div className="p-d-flex p-flex-column">
      <Button
        icon="pi pi-arrow-left"
        btnType="ghost"
        label="Voltar"
        className="p-col-3"
        onClick={() => onClose()}
      />
      <div className="p-d-flex p-gap-2 p-ai-center p-my-1">
        <SimpleText className="p-d-flex p-flex-1 p-px-1" fontSize="sm" medium>
          Lista de espera
        </SimpleText>
        <div className="p-d-flex p-gap-2">
          <IconButton
            icon="pi pi-search"
            btnType={search ? IconBtnTypes.GREEN : IconBtnTypes.GREY}
            onClick={() => setSearch(!search)}
          />
          <IconButton
            icon="pi pi-plus"
            btnType={IconBtnTypes.GREEN}
            onClick={() => {
              setPaciente(null);
              setUpdate(false);
              setWaitListItem(undefined);
              modalAddListaEspera.open();
            }}
          />
        </div>
      </div>
      {!search ? (
        <div className="chips">
          <ChipSelect
            value={tipoEsperaFilters}
            options={tipoEspera}
            onChange={handleChangeTipoEsperaFilter}
          />

          <ChipSelect
            value={turnoFilters}
            options={turno}
            onChange={handleChangeTurnoFilter}
          />
        </div>
      ) : (
        <div>
          <TextInput
            icon="pi pi-search"
            placeholder="Pesquisar"
            value={searchValue}
            onChange={e => handleSearch(e)}
          />
        </div>
      )}

      <Separator />

      <Accordion
        activeIndex={activeAccordion}
        onTabChange={e => setActiveAccordion(e.index)}
        expandIcon="pi pi-chevron-up"
      >
        {items ? (
          items?.map((item: any) => {
            const celular = item?.paciente?.contatos?.find(
              (contato: any) => contato.tipo === 'CELULAR_PARTICULAR',
            );
            return (
              <AccordionTab
                key={item.id}
                header={
                  <div className="accordion-header">
                    {item?.paciente?.cartaoPrincipal?.numeroCartao ? (
                      <SimpleText
                        fontColor={FONT_COLOR.COLOR_60}
                        fontSize={FONT_SIZE.XXXS}
                        className="header-simple-text"
                      >
                        {item.paciente?.cartaoPrincipal?.convenio
                          ?.nomeFantasia ??
                          item.paciente?.cartaoPrincipal?.convenio
                            ?.razaoSocial}{' '}
                        • {item.paciente.cartaoPrincipal.numeroCartao}
                      </SimpleText>
                    ) : (
                      <SimpleText
                        fontColor={FONT_COLOR.COLOR_60}
                        fontSize={FONT_SIZE.XXXS}
                      >
                        Cartão não informado
                      </SimpleText>
                    )}
                    <SimpleText
                      className="p-my-1"
                      fontColor={FONT_COLOR.PRIMARY}
                      fontSize={FONT_SIZE.XS}
                    >
                      {item.paciente.nome}
                    </SimpleText>
                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_40}
                      fontSize={FONT_SIZE.XXS}
                      className="header-simple-text p-d-flex p-align-center"
                    >
                      <i className="fas fa-phone p-mr-1 phone-icon-espera" />
                      {formatPhoneNumber(celular?.numero)}
                    </SimpleText>
                  </div>
                }
              >
                <PacienteAccordion
                  item={item}
                  optionsItems={optionsItems}
                  openSelectedHorarioDisponivelModal={
                    openSelectedHorarioDisponivelModal
                  }
                />
              </AccordionTab>
            );
          })
        ) : (
          <SimpleText color={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XXS}>
            Nenhum resultado correspondente
          </SimpleText>
        )}
      </Accordion>
      {items?.length > 0 ? null : (
        <SimpleText color={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XXS}>
          Nenhum resultado correspondente
        </SimpleText>
      )}
      {/* </div> */}
      <Separator />

      {modalAddListaEspera.isOpen && (
        <ModalAddListaEspera
          isUpdate={update}
          profissionalAtivo={profissionalAtivo}
          especialidades={especialidades?.list}
          waitlistItem={waitlistItem}
          loadPage={loadPage}
          paciente={paciente}
          setPaciente={setPaciente}
          {...modalAddListaEspera}
        />
      )}

      <Dialog
        className="footer-on"
        header="Retirar paciente da lista de espera"
        visible={confirmDelete.visible}
        onHide={() => setConfirmDelete({ visible: false })}
        footer={() => (
          <Footer
            onCancel={() => setConfirmDelete({ visible: false })}
            onConfirm={async () => {
              await ListaEsperaAPI.deleteItemListaEspera(confirmDelete.list.id);

              loadPage();

              setConfirmDelete({ visible: false });
            }}
          />
        )}
      >
        <SimpleText>
          Você realmente deseja retirar o paciente da lista de espera deste
          médico?
        </SimpleText>
      </Dialog>
    </div>
  );
};

interface FooterProps {
  onCancel(): void;
  onConfirm(): void;
}

const Footer = memo((props: FooterProps) => {
  const { onCancel, onConfirm } = props;

  return (
    <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
      <Button btnType="ghost" label="Cancelar" onClick={onCancel} stretch />
      <Button
        label="Sim, excluir"
        onClick={onConfirm}
        btnType="danger"
        stretch
      />
    </div>
  );
});
