import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { IFamiliarFiliacao } from 'src/APIs/ProntuarioAPI/FamiliarAPI/Familiar';
import { FamiliarAPI } from 'src/APIs/ProntuarioAPI/FamiliarAPI/FamiliarAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

export const FieldFiliacao = () => {
  const { control, watch } = useFormContext();
  const [options, setOptions] = useState<IFamiliarFiliacao[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const watchParentesco = watch('parentesco');

  const fetchApi = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await FamiliarAPI.loadFiliacao();
      setOptions(res);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const items = useMemo(
    () =>
      options.map(option => ({
        label: option.descricao,
        value: option.codigo,
      })),
    [options],
  );

  useEffect(() => {
    fetchApi();
  }, [fetchApi]);

  return (
    <DropdownControlled
      name="filiacao"
      label="Filiação"
      className="p-col-12 p-md-4"
      filter={false}
      control={control}
      disabled={['Marido', 'Esposa'].includes(watchParentesco?.descricao || '')}
      options={items}
      loading={isLoading}
    />
  );
};
