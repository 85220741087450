import { useMemo } from 'react';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';

import { cn } from 'src/utils/cn';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';

import './FormMovimentacao.scss';

export const FormMovimentacaoHeader = ({
  cabecalhoInfo,
  handleTranferirCarteira,
}: {
  cabecalhoInfo: IMovimentacaoCarteiraAPS;
  handleTranferirCarteira(): void;
}) => {
  const { consultorios } = useAppSelector(state => state);
  const { windowInnerWidth } = useSize();

  const title =
    cabecalhoInfo?.equipeCuidadora?.nome ??
    cabecalhoInfo?.profissionalCuidador?.nome;
  const profissionalSaudeDescricao = `${cabecalhoInfo?.profissionalCuidador?.tipoProfissionalSaude} • ${cabecalhoInfo?.profissionalCuidador?.codigoConselho}/${cabecalhoInfo?.profissionalCuidador?.ufConselho} ${cabecalhoInfo?.profissionalCuidador?.numeroRegistro}`;
  const subtitle =
    cabecalhoInfo?.equipeCuidadora?.descricao ?? profissionalSaudeDescricao;

  const checkCoresTags = (total: number, limite: number) => {
    if (!limite || total === null || total === undefined) return '';

    const porcentagem = (total / limite) * 100;

    const cores =
      porcentagem <= 90 ? 'verde' : porcentagem <= 100 ? 'laranja' : 'vermelho';

    return cores;
  };

  const isTablet = useMemo(() => windowInnerWidth < 900, [windowInnerWidth]);

  return (
    <div
      className={cn(
        'p-d-flex p-gap-1 p-w-100 movimentacao-aps-header',
        isTablet && 'p-mt-3',
      )}
    >
      {!isTablet && <Separator layout="vertical" />}

      <div className="p-d-flex p-flex-wrap p-w-100 p-gap-2 p-ai-center p-w-100">
        <div className="p-d-flex p-flex-column p-gap-1 p-col-12 p-md-4 p-lg-3 p-xl-2 p-pl-0">
          <SimpleText fontSize="xs" medium>
            {title}
          </SimpleText>
          <SimpleText fontSize="xxs" fontColor="color_40">
            {subtitle}
          </SimpleText>
        </div>

        <div className="p-d-flex p-flex-column p-gap-1 p-ai-center">
          <span
            className={`total-pacientes ${checkCoresTags(
              cabecalhoInfo?.totalPacientes,
              consultorios?.ativo?.limitePacientes,
            )} p-py-1 p-px-2`}
          >
            Total de pacientes: {cabecalhoInfo?.totalPacientes}
          </span>
          {!!consultorios?.ativo?.limitePacientes && (
            <SimpleText fontSize="xxxs" fontColor="color_40">
              Total permitido: {consultorios?.ativo?.limitePacientes}
            </SimpleText>
          )}
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-center">
          <span
            className={`total-pontos ${checkCoresTags(
              cabecalhoInfo?.totalPontos,
              consultorios?.ativo?.limitePontos,
            )} p-py-1 p-px-2`}
          >
            Total de pontos: {cabecalhoInfo?.totalPontos}
          </span>
          {!!consultorios?.ativo?.limitePontos && (
            <SimpleText fontSize="xxxs" fontColor="color_40">
              Total permitido: {consultorios?.ativo?.limitePontos}
            </SimpleText>
          )}
        </div>

        <div className={cn('p-col p-px-0', isTablet ? 'p-px-0' : 'p-px-2')}>
          <Button
            label="Transferir carteira"
            btnType="tonal"
            onClick={() => handleTranferirCarteira()}
          />
        </div>
      </div>
    </div>
  );
};
