import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import EnviarSugestao from './EnviarSugestao';

export const FeedbackAgradecimento = ({
  close,
}: Pick<DisclosureType, 'close'>) => {
  const drowerEnviarSugestao = useDisclosure({ opened: false });
  return (
    <div className="p-d-flex p-flex-column p-gap-1">
      <SimpleText
        className="p-line-height-2"
        fontColor="color_40"
        fontSize="xs"
      >
        Sua opinião é essencial para que possamos continuar aprimorando a
        plataforma. Se tiver alguma sugestão de melhoria, fique à vontade para
        nos enviar.
      </SimpleText>

      <Divider layout="horizontal" className="p-p-0 p-m-0" />

      <div className="p-col-12 p-d-flex p-flex-row p-gap-1 p-ai-center">
        <Button
          label="Enviar sugestão"
          type="button"
          btnType="outline"
          icon="fas fa-envelope"
          onClick={() => drowerEnviarSugestao.open()}
          stretch
        />
      </div>

      {drowerEnviarSugestao.isOpen && (
        <EnviarSugestao {...drowerEnviarSugestao} closeFeedback={close} />
      )}
    </div>
  );
};
