import React, { useCallback, useRef, useState } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import { ParametrizacaoAgravoAPI } from 'src/APIs/ReportAPI/RelatorioVigilanciaAPI/ParametrizacaoAgravoAPI/ParametrizacaoAgravoAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter, checkAuth, enumToText } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';
import Tooltip from 'src/components/Tooltip/Tooltip';

interface RowProps {
  item: IParamAgravoItem;
  adicionarAgravoDialog: DisclosureType;
}

const Row = ({ item, adicionarAgravoDialog }: RowProps) => {
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);
  const [loading, setLoading] = useState(false);
  const { isMobile } = useSize();

  const menuRef = useRef<TieredMenu>(null);
  const listaCidsModal = useDisclosure({ opened: false });

  const onInativar = async () => {
    try {
      setLoading(true);
      await ParametrizacaoAgravoAPI.putAgravoStatus({
        id: item.id,
        status: item.status === 'ATIVO' ? 'INATIVO' : 'ATIVO',
      });
      item.status = item.status === 'ATIVO' ? 'INATIVO' : 'ATIVO';
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onEdit = () => {
    adicionarAgravoDialog.open({ state: item });
  };

  const menuItens: any = [
    {
      label: 'Editar',
      command: onEdit,
      checkPermission: 'ADM_PARAMETRIZACAO_AGRAVO_ALTERAR',
    },
    ...(isMobile
      ? [
          {
            label: 'Inativar',
            command: onInativar,
            className: 'error',
            checkPermission: 'ADM_PARAMETRIZACAO_AGRAVO_ALTERAR',
          },
        ]
      : []),
  ];

  const optionsMenu = menuItens.filter((item: any) => {
    return item?.checkPermission
      ? checkAuth(authorities, item?.checkPermission)
      : true;
  });

  if (isMobile) return <MobileRow item={item} optionsMenu={optionsMenu} />;

  return (
    <div className="p-w-100 p-d-flex p-jc-between  p-p-1 br-8px bg-96 p-my-1">
      <div className="p-grid p-flex-1 p-ai-center">
        <SimpleText medium fontSize="xs" className="p-col-3">
          {item.nome}
        </SimpleText>
        <div className="p-col-3 p-d-flex p-gap-1">
          {item.cids?.slice(0, 3).map((cid: any) => (
            <div key={cid.id}>
              <Tooltip target={`.${cid.codigoCid + cid.id}`} />
              <SimpleText
                className={`comorbidades-ativas ${cid.codigoCid + cid.id}`}
                data-pr-tooltip={cid.nomeCid}
                data-pr-position="top"
              >
                {cid.codigoCid}
              </SimpleText>
            </div>
          ))}
          {item.cids && item.cids?.length > 3 && (
            <div
              className="cursor-pointer"
              onClick={() => listaCidsModal.open({ state: item.cids })}
            >
              <SimpleText fontColor="primary" medium className="cursor-pointer">
                +{item.cids?.length - 3}
              </SimpleText>
            </div>
          )}
        </div>
        <SimpleText
          fontColor="color_40"
          fontSize="xs"
          className="p-col-2 p-pl-0"
        >
          {item.autoridadeSaude ? enumToText(item.autoridadeSaude) : ''}
        </SimpleText>
        <SimpleText
          fontColor="color_40"
          fontSize="xs"
          className="p-col-2 p-pl-0"
        >
          {item.periodicidade ? capitalizeFirstLetter(item.periodicidade) : ''}
        </SimpleText>
        <Switch
          checked={item.status === 'ATIVO'}
          onChange={() => onInativar()}
          className="p-d-flex p-jc-end p-col-2"
          showStatus
          uncheckedLabel="Desativado"
          checkedLabel="Ativado"
          loading={loading}
        />
      </div>
      <div className="p-p-1 p-pl-4 separator-left">
        <Button
          btnType="gray"
          icon="fas fa-ellipsis-h"
          onClick={e => menuRef.current?.toggle(e)}
        />

        <TieredMenu
          className="panel-options-paciente"
          ref={menuRef}
          model={optionsMenu}
          popup
        />
      </div>
      {listaCidsModal.isOpen && <ListaCids {...listaCidsModal} />}
    </div>
  );
};

export default Row;

interface MobileRowProps {
  item: IParamAgravoItem;
  optionsMenu: any;
}

const MobileRow = ({ item, optionsMenu }: MobileRowProps) => {
  return (
    <SearchListMobileRow options={optionsMenu}>
      <div className="p-d-flex p-flex-column p-gap-3 p-flex">
        <div className="p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Agravo
          </SimpleText>
          <SimpleText medium fontSize="xs">
            {item.nome}
          </SimpleText>
        </div>

        <div className="p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor="color_60" fontSize="xxs">
            CID
          </SimpleText>
          <div className=" p-d-flex p-gap-1">
            {item.cids?.map((cid: any) => (
              <div key={cid.id}>
                <Tooltip target={`.${cid.codigoCid + cid.id}`} />
                <SimpleText
                  className={`comorbidades-ativas ${cid.codigoCid + cid.id}`}
                  data-pr-tooltip={cid.nomeCid}
                  data-pr-position="top"
                >
                  {cid.codigoCid}
                </SimpleText>
              </div>
            ))}
          </div>
        </div>

        <div className="p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Autoridade de saúde
          </SimpleText>
          <SimpleText fontColor="color_40" fontSize="xs">
            {item.autoridadeSaude ? enumToText(item.autoridadeSaude) : ''}
          </SimpleText>
        </div>

        <div className="p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Periodicidade
          </SimpleText>
          <SimpleText fontColor="color_40" fontSize="xs">
            {item.periodicidade
              ? capitalizeFirstLetter(item.periodicidade)
              : ''}
          </SimpleText>
        </div>

        <div className="p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Status
          </SimpleText>
          <SimpleText fontColor="color_40" fontSize="xs">
            {item.status === 'ATIVO' ? 'Ativado' : 'Desativado'}
          </SimpleText>
        </div>
      </div>
    </SearchListMobileRow>
  );
};

const ListaCids = ({ state, close }: DisclosureType) => {
  return (
    <Dialog onHide={close} visible header="Lista de CID" maximizedMobileSize>
      <>
        <div className="p-d-flex p-flex-column p-gap-2 br-8px border-88 p-p-2">
          <SimpleText fontColor="color_60">CID</SimpleText>
          {state?.map((cid: any) => (
            <div
              key={cid.id}
              className="p-d-flex p-ai-center br-8px bg-96 p-w-100 p-p-2"
            >
              <SimpleText medium>
                {`(${cid.codigoCid}) ${cid.nomeCid}`}
              </SimpleText>
            </div>
          ))}
        </div>
        <Button
          className="p-mt-2"
          btnType="outline"
          label="Fechar"
          onClick={() => close()}
          stretch
        />
      </>
    </Dialog>
  );
};
