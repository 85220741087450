import Http, { HttpParams } from 'src/core/http/Http';

const baseUrl = 'monitoramento-sms';

export const listMonitoramentoSMS = async (params: {
    params?: {
        paciente?: string;
        idProfissional?: number;
        statusEnvio?: 'SUCESSO' | 'ERRO';
        inicioPeriodo?: string;
        fimPeriodo?: string;
        page?: number;
    }
}, options?: Omit<HttpParams, 'service' | 'url'>): Promise<Paginator<IMonitoramentoSMSItem>> => {
    return Http.get({
        service: 'agenda',
        url: baseUrl,
        hideToast: true,
        params,
        ...options,
    });
    };

export const confirmarAgendamentoMonitoramento = async (id: number, options?: Omit<HttpParams, 'service' | 'url'>): Promise<void> => {
    return Http.put({
        service: 'agenda',
        url: `${baseUrl}/${id}/confirmar-agendamento`,
        throwError: true,
        ...options,
    });
}

export const cancelarAgendamentoMonitoramento = async (id: number, options?: Omit<HttpParams, 'service' | 'url'>): Promise<void> => {
    return Http.put({
        service: 'agenda',
        url: `${baseUrl}/${id}/cancelar-agendamento`,
        throwError: true,
        ...options,
    });
}



export const MonitoramentoSMSAPI = {
    listMonitoramentoSMS,
    confirmarAgendamentoMonitoramento,
    cancelarAgendamentoMonitoramento,
}