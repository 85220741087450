import { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { ChatAPI } from 'src/APIs/ChatAPI/ChatAPI';

import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { ChatMessageHeader } from './components/ChatMessage/ChatMessageHeader';
import { ChatMessageInput } from './components/ChatMessage/ChatMessageInput';
import { ChatMessageLoading } from './components/ChatMessage/ChatMessageLoading';
import { ChatMessageText } from './components/ChatMessage/ChatMessageText';
import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { verifyIfShowMessageDate } from './helpers';
import { useChat } from './hooks/useChat';

export const ChatMessage = () => {
  const { openedMessageUser, addMessage, cleanMessages, setIsMessageLoading } =
    useChat();

  const fetchApi = async (params: any) => {
    if (!openedMessageUser?.id) return [];
    if (params.page === 0) {
      cleanMessages();
    }

    setIsMessageLoading(true);

    const response = await ChatAPI.loadChatMensagem({
      idUsuarioDestino: openedMessageUser.id,
      ...params,
      pageSize: 15,
    });

    addMessage(response.list);
    setIsMessageLoading(false);

    if (response?.list) {
      return response;
    }
    return [];
  };

  return (
    <SearchList.Root reloadWhenConsultorioChange fetchApi={fetchApi}>
      <ChatMessageComponent />
    </SearchList.Root>
  );
};

export const ChatMessageComponent = () => {
  const { setListItems, listItems } = useSearchList();
  const {
    setIsMessageOpened,
    openedMessageUser,
    messages,
    handleSendMessage,
    isMessageLoading,
    reloadChatMessage,
    handleReloadChatList,
    isOverlayOpened,
  } = useChat();

  const dispatch = useDispatch();

  const hasUnreadMessages = useMemo(
    () => messages.some(msg => msg.status === 'ENVIADA'),
    [messages],
  );

  const handleReadMessages = useCallback(async () => {
    if (!openedMessageUser?.id || !hasUnreadMessages || !isOverlayOpened)
      return;

    try {
      await ChatAPI.sendLerMensagem(
        { idUsuarioDestino: openedMessageUser.id },
        { throwError: true },
      );

      handleReloadChatList();
      dispatch(setInvalidateQuery({ invalidateNotificacoesChat: true }));
    } catch {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hasUnreadMessages, openedMessageUser?.id]);

  useEffectSkipFirst(() => {
    handleReadMessages();
  }, [handleReadMessages]);

  useEffectSkipFirst(() => {
    if (messages.length && listItems?.length !== messages.length) {
      setListItems(messages);
    }
  }, [messages.length, listItems?.length]);

  return (
    <div className="p-d-flex p-w-100 p-flex-column p-gap-2 h-full">
      <SimpleText fontSize="md">Chat</SimpleText>

      <div className="p-col-12 p-px-0 p-pb-0">
        <Button
          label="Voltar"
          icon="fas fa-arrow-left"
          btnType="ghost"
          onClick={() => setIsMessageOpened(false)}
        />
      </div>

      <ChatMessageHeader
        user={openedMessageUser!}
        isLoading={isMessageLoading}
      />

      <SearchList.InfiniteScroll
        height={340}
        initialScrollY={340}
        inverse
        customEmptyText="Você ainda não possui mensagens com este usuário."
        loader={<ChatMessageLoading />}
        reload={reloadChatMessage}
        renderRows={(items: IChatMessageItem[]) =>
          items.map((item, index) => {
            return (
              <ChatMessageText
                key={index}
                item={item}
                showDate={verifyIfShowMessageDate(item, index, messages)}
              />
            );
          })
        }
        className="p-d-flex p-flex-column-reverse p-gap-1 p-pr-1 p-pb-0"
      />

      <ChatMessageInput onSend={handleSendMessage} />
    </div>
  );
};
