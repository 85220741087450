import { memo, useCallback, useEffect, useMemo } from 'react';

import { useLocation, useParams } from 'react-router';

import dayjs from 'dayjs';
import { TelemedicinaSalaAtendimentoAPI } from 'src/APIs/ConfigAPI/TelemedicinaSalaAtendimento/TelemedicinaSalaAtendimento';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';

import './Telemedicina.scss';

export const Telemedicina = memo(() => {
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const { setRoomName, setTeleconsulta, roomName, teleconsultaState } =
    useAtendimento();

  useEffect(() => {
    TelemedicinaSalaAtendimentoAPI.getSalaAtendimento({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      idAtendimento: idAtendimento!,
    }).then(salaURL => {
      if (!!salaURL) setRoomName(salaURL.link.split('/')[3] || '');
    });
  }, [idAtendimento, setRoomName]);

  const handleHabilitarSalaPaciente = useCallback(() => {
    setTeleconsulta('STARTED');

    TelemedicinaSalaAtendimentoAPI.sendSalaAtendimento({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      idAtendimento: idAtendimento!,
    });
  }, [idAtendimento, setTeleconsulta]);

  const contentComp: { [key: string]: any } = useMemo(() => {
    return {
      INITIAL: () => (
        <Button
          icon="fas fa-video"
          btnType="tonal"
          label="Iniciar Teleconsulta"
          onClick={() => handleHabilitarSalaPaciente()}
          disabled={!roomName}
          stretch
        />
      ),
      STARTED: () => <TelemedicinaIFrame />,
      FINISHED: () => (
        <TelemedicinaFinishedFeedback
          handleHabilitarSalaPaciente={handleHabilitarSalaPaciente}
        />
      ),
    };
  }, [handleHabilitarSalaPaciente, roomName]);

  const ContentComponent = contentComp[teleconsultaState];

  return (
    <div
      className={`telemedicina-container ${
        teleconsultaState !== 'STARTED' ? 'comp' : ''
      }`}
    >
      <ContentComponent />
    </div>
  );
});

const TelemedicinaIFrame = () => {
  return <div className="teleconsulta-frame-atendimento" />;
};

const TelemedicinaFinishedFeedback = ({ handleHabilitarSalaPaciente }: any) => {
  const { roomName, atendimentoStatus } = useAtendimento();
  return (
    <>
      <div className="p-d-flex p-gap-3 p-ai-center p-jc-center p-mb-2">
        <div className="icon-finished">
          <i className="fas fa-phone" />
        </div>
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          Chamada encerrada às {dayjs().format('HH:mm')}
        </SimpleText>
      </div>
      {atendimentoStatus === 'ATENDENDO' && (
        <Button
          icon="fas fa-video"
          btnType="tonal"
          label="Retomar Teleconsulta"
          onClick={() => handleHabilitarSalaPaciente()}
          disabled={!roomName}
          stretch
        />
      )}
    </>
  );
};
