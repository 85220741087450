import { ReactNode, useState, useEffect, useCallback, useMemo } from 'react';

import { MemedAPI } from 'src/APIs/ProntuarioAPI/MemedAPI/MemedAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { MemedContext } from '../../contexts/Atendimento/Memed';

interface MemedProviderProps {
  children: ReactNode;
}

export function MemedProvider({ children }: MemedProviderProps) {
  const { atendimento } = useAppSelector((state: RootState) => state);

  const [memedStatus, setMemedStatus] = useState({
    isLoading: false,
    isLoaded: false,
    hasError: false,
  });
  const [memedImpressos, setMemedImpressos] = useState<any>(null);
  const [isLoadingMemedImpressos, setIsLoadingMemedImpressos] = useState(true);

  const script = useMemo(() => document.createElement('script'), []);

  // event listner para informar o status de carregado
  const onScriptLoad = useCallback(() => {
    window.MdSinapsePrescricao.event.add(
      'core:moduleInit',
      function moduleInitHandler(module: any) {
        if (module.name === 'plataforma.prescricao') {
          console.info('LOAD MEMED MODULE');
          setMemedStatus({
            isLoaded: true,
            hasError: false,
            isLoading: false,
          });
        }
      },
    );

    window.MdSinapsePrescricao.event.add(
      'core:moduleHide',
      async function moduloFechado(modulo: any) {
        if (modulo.moduleName === 'plataforma.prescricao') {
          console.info('MEMED MODULE HIDE');

          await window.MdHub.command.send('plataforma.prescricao', 'reset');
          await window.MdHub.event.remove('prescricaoImpressa');
        }
      },
    );
  }, []);

  // event listner para informar o status de erro
  const onScriptError = useCallback(() => {
    console.info('MEMED ERROR');
    script.remove();
    setMemedStatus({
      isLoaded: false,
      hasError: true,
      isLoading: false,
    });
  }, [script]);

  useEffect(() => {
    if (
      !!atendimento?.memed?.script &&
      !memedStatus.isLoading &&
      !memedStatus.isLoaded &&
      !memedStatus.hasError
    ) {
      if (!atendimento?.memed?.tokenMedico) {
        // eslint-disable-next-line no-console
        console.error(
          'Memed tokenMedico não é válido ->',
          atendimento?.memed?.tokenMedico,
        );
        return setMemedStatus({
          isLoaded: false,
          hasError: true,
          isLoading: false,
        });
      }

      const scriptMemed = document.querySelectorAll(
        'script[data-token], script[data-container]',
      );

      if (!!scriptMemed.length) {
        scriptMemed[0]?.remove();
      }

      if (window?.MdHub) {
        delete window.MdHub;
        delete window.MdSinapsePrescricao;
      }

      setMemedStatus({
        isLoaded: false,
        hasError: false,
        isLoading: true,
      });

      const attrs = {
        'data-color': '#54a275',
        'data-token': atendimento.memed.tokenMedico,
      };

      // cria um elemento script
      script.src = atendimento.memed.script;
      script.async = true;

      Object.entries(attrs).forEach(([attr, value]) => {
        if (!['src', 'async'].includes(attr)) {
          script.setAttribute(attr, value);
        }
      });

      // adiciona os event listners
      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      // adiciona o script no document body
      document.body.appendChild(script);
    }
  }, [atendimento?.memed, memedStatus, onScriptError, onScriptLoad, script]);

  useEffect(() => {
    return () => {
      window?.MdHub?.command?.send?.('plataforma.sdk', 'logout');
      window?.MdHub?.module?.hide?.('plataforma.prescricao');
      window?.MdHub?.server?.unbindEvents?.();

      // remove memed script
      const scriptMemed = document.querySelectorAll(
        'script[data-token], script[data-container]',
      );
      scriptMemed[0]?.remove?.();

      // remove os event listners
      script.removeEventListener('load', onScriptLoad);
      script.removeEventListener('error', onScriptError);
    };
  }, [onScriptError, onScriptLoad, script]);

  useEffect(() => {
    if (!!atendimento?.paciente?.id) {
      setIsLoadingMemedImpressos(true);
      MemedAPI.getPrepararReceitaOculosOrImpressoPaciente(
        {
          idPaciente: atendimento?.paciente?.id,
          impressoOrReceitaOculos: true,
        },
        { throwError: true },
      )
        .then(res => {
          setMemedImpressos(res);
        })
        .catch(() => setMemedStatus(prev => ({ ...prev, hasError: true })))
        .finally(() => setIsLoadingMemedImpressos(false));
    }
  }, [atendimento?.paciente?.id]);

  return (
    <MemedContext.Provider
      value={{
        memedStatus,
        memedImpressos,
        isLoadingMemedImpressos,
      }}
    >
      {children}
    </MemedContext.Provider>
  );
}
