import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { FieldProfissionalSaudeByCliente } from 'src/components/Fields/FieldProfissionalSaude/FieldProfissionalSaude';
import FormInput from 'src/components/FormInput/FormInput';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

type FormData = {
  paciente: string;
  profissionalSaude: { id: number } | null;
  statusEnvio: 'SUCESSO' | 'ERRO' | null;
  dataInicial: Date | null;
  dataFinal: Date | null;
};

interface MonitoramentoSMSFiltersProps {
  loading: boolean;
}

export const MonitoramentoSMSFilters = ({
  loading,
}: MonitoramentoSMSFiltersProps) => {
  const form = useForm<FormData>({
    defaultValues: {
      paciente: '',
      profissionalSaude: null,
      statusEnvio: null,
      dataInicial: null,
      dataFinal: null,
    },
  });

  const { setFilter } = useSearchList();

  const {
    formState: { errors, isDirty },
    watch,
    setError,
  } = form;

  const watchDataInicial = watch('dataInicial');
  const watchDataFinal = watch('dataFinal');

  const disabled =
    watchDataInicial && watchDataFinal
      ? new Date(watchDataInicial) > new Date(watchDataFinal)
      : false;

  useEffect(() => {
    if (disabled) {
      setError('dataFinal', {
        type: 'min',
        message: 'A data final deve ser maior que a data inicial',
      });
    }
  }, [disabled, setError]);

  const onSubmit = (data: FormData) => {
    const { profissionalSaude, dataInicial, dataFinal, ...rest } = data;
    const filter: any = {
      ...rest,
      ...(profissionalSaude && { idProfissional: profissionalSaude.id }),
      ...(dataInicial && {
        inicioPeriodo: dayjs(new Date(dataInicial)).format('YYYY-MM-DD'),
      }),
      ...(dataFinal && {
        fimPeriodo: dayjs(new Date(dataFinal)).format('YYYY-MM-DD'),
      }),
    };
    setFilter(filter);
  };

  return (
    <FormProvider {...form}>
      <form
        className="p-col-12 p-px-0 p-d-flex p-flex-wrap p-ai-center"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormInput
          name="paciente"
          label="Paciente"
          placeholder="Pesquisar"
          className="p-col-6 input-search p-pt-1"
          icon="fas fa-search"
          iconPos="left"
          hideTextErrorSpace
        />

        <FieldProfissionalSaudeByCliente
          name="profissionalSaude"
          label="Profissional"
          placeholder="Selecione"
          showClear
          className="p-col-6"
        />

        <DropdownControlled
          control={form.control}
          label="Status do envio"
          name="statusEnvio"
          options={[
            { label: 'Sucesso', value: 'SUCESSO' },
            { label: 'Erro', value: 'ERRO' },
          ]}
          className="p-col-6 p-md-4 p-lg-3"
          placeholder="Selecione"
          filter={false}
        />

        <CalendarInputControlled
          control={form.control}
          name="dataInicial"
          label="Periodo de"
          className="p-col-6 p-md-4 p-lg-2"
          placeholder="dd/mm/aaaa"
          showIcon
          errorMsg={errors.dataInicial?.message}
        />

        <CalendarInputControlled
          control={form.control}
          name="dataFinal"
          label="Até"
          className="p-col-6 p-md-4 p-lg-2"
          placeholder="dd/mm/aaaa"
          showIcon
          errorMsg={errors.dataFinal?.message}
        />

        <div className="p-col p-pt-4">
          <Button
            type="submit"
            label="Pesquisar"
            btnType="tonal"
            loading={loading}
            disabled={disabled || !isDirty}
          />
        </div>
      </form>
    </FormProvider>
  );
};
