import { useState } from 'react';

import dayjs from 'dayjs';
import { PlanoCuidadoAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoAPI/PlanoCuidadoAPI';
import { LoadPlanoCuidadoByIdProps } from 'src/models/APIs/ProntuarioAPI/PlanoCuidado/PlanoCuidado';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import Divider from 'src/components/Basics/Divider/Divider';
import IconButton from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { DialogDelete } from 'src/components/DialogDelete/DialogDelete';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Switch from 'src/components/Switch/Switch';
import Tooltip from 'src/components/Tooltip/Tooltip';

import { AddPlanoCuidado } from '../AddPlanoCuidado/AddPlanoCuidado';

import './ContentRow.scss';

type Props = {
  item: LoadPlanoCuidadoByIdProps;
  refetch: () => void;
};

type IState = {
  isEditable: boolean;
  item: LoadPlanoCuidadoByIdProps;
  refetch: () => void;
};

export const ContentRow = ({ item, refetch }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const { isTablet, isMobile } = useSize();
  const {
    user: { tipoUsuario },
  } = useAppSelector((state: RootState) => state);

  const modalDelecao = useDisclosure({ opened: false });
  const modalEdicao = useDisclosure({ opened: false });

  const isInativo = item.status === 'INATIVO';
  const canInativar =
    tipoUsuario === 'MASTER' || tipoUsuario === 'ADMINISTRADOR';

  const onToggleChange = () => {
    if (isInativo) {
      return handleAtivar();
    }
    return handleInativar();
  };

  const handleAtivar = async () => {
    try {
      setIsLoading(true);
      await PlanoCuidadoAPI.ativarPlanoCuidado(
        { id: Number(item?.id) },
        {
          throwError: true,
        },
      );
      refetch();
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const handleInativar = async () => {
    try {
      setIsLoading(true);
      await PlanoCuidadoAPI.inativarPlanoCuidado(
        { id: Number(item?.id) },
        {
          throwError: true,
        },
      );
      refetch();
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = () => {
    PlanoCuidadoAPI.deletePlanoCuidado({ id: item.id }, { throwError: true })
      .then(() => refetch())
      .catch(() => {});
  };

  return (
    <div
      id="plano-cuidado-content-row"
      className={`p-grid p-col-12 p-ai-center p-my-1 row ${
        isInativo ? 'row-inativo' : ''
      }`}
    >
      <SimpleText className="p-col-2 p-md-1">{item.id}</SimpleText>
      <SimpleText className="p-col-5 p-md-2" medium>
        {item.nome}
      </SimpleText>
      {!isTablet && (
        <SimpleText className="p-col-2" medium>
          {item.usuarioAtualizacao?.nome || '-'}
        </SimpleText>
      )}
      {!isTablet && (
        <SimpleText className="p-col-2">{item.diasVigencia} dias</SimpleText>
      )}

      {!isMobile && (
        <div className="p-col-2">
          {isLoading ? (
            <SpinnerLoading full size="sm" className="p-jc-start" />
          ) : (
            <Switch
              checked={!isInativo}
              onChange={onToggleChange}
              showStatus={!isTablet}
              disabled={!canInativar}
              uncheckedLabel="Inativo"
            />
          )}
        </div>
      )}

      <div className="p-d-flex p-gap-2 p-col p-jc-end">
        <IconButton
          icon="fas fa-edit"
          type="button"
          onClick={() =>
            modalEdicao.open({
              state: {
                id: item.id,
              },
            })
          }
        />
        <IconButton
          icon="fas fa-trash"
          type="button"
          onClick={() => modalDelecao.open()}
          disabled={isInativo}
        />
      </div>

      <Divider className="p-col-12" layout="horizontal" />

      <div className="p-col-12 p-md-2">
        <SimpleText fontColor="color_60">Cuidado: </SimpleText>
        <SimpleText medium>{item.planoCuidado || '-'}</SimpleText>
      </div>

      <div
        style={{ overflowX: 'scroll' }}
        className="p-d-flex p-ai-center p-col-12 p-md-5 p-py-0"
      >
        <SimpleText
          className="p-w-auto p-col-2 p-lg-1"
          fontColor={FONT_COLOR.COLOR_60}
        >
          CID
        </SimpleText>

        <div className="p-d-flex p-gap-1">
          {!!item.cids?.length &&
            item.cids.map(cid => (
              <span key={cid.codigo} className="tag tag-cid">
                <Tooltip target="#tag-cid" />
                <SimpleText
                  id="tag-cid"
                  className="actv"
                  data-pr-tooltip={cid.nome || '-'}
                  data-pr-position="top"
                >
                  {cid.codigo}
                </SimpleText>
              </span>
            ))}
        </div>
      </div>
      <div
        style={{ overflowX: 'scroll' }}
        className="p-d-flex p-ai-center p-col-12 p-md-5 p-py-0"
      >
        <SimpleText
          className="p-w-auto p-col-2 p-lg-1"
          fontColor={FONT_COLOR.COLOR_60}
        >
          CIAP
        </SimpleText>

        <div className="p-d-flex p-gap-1">
          {!!item.ciaps?.length &&
            item.ciaps.map(ciap => (
              <span key={ciap.codigo} className="tag tag-cid">
                <Tooltip target="#tag" />
                <SimpleText
                  id="tag"
                  className="actv"
                  data-pr-tooltip={ciap.nome || '-'}
                  data-pr-position="top"
                >
                  {ciap.codigo}
                </SimpleText>
              </span>
            ))}
        </div>
      </div>

      {isInativo && (
        <>
          <Divider className="p-col-12" layout="horizontal" />

          <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_60}
              className="actv"
            >
              Justificativa
            </SimpleText>
            <SimpleText className="actv">
              {item.justificativa || '-'}
            </SimpleText>

            <div className="p-d-flex p-gap-2 p-ai-center">
              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_60}
                className="actv"
              >
                Inativado por <b>{item.usuarioAtualizacao?.nome}</b> em{' '}
                {dayjs(item.dataAtualizacao).format('DD [de] MMM [de] YYYY')}{' '}
              </SimpleText>
              <i className="fas fa-lock-open lock-green" />
            </div>
          </div>
        </>
      )}

      {modalDelecao.isOpen && (
        <DialogDelete
          onConfirm={handleDelete}
          subtitle="Você está excluindo um plano de cuidado."
          {...modalDelecao}
        />
      )}
      {modalEdicao.isOpen && (
        <AddPlanoCuidado refetch={refetch} {...modalEdicao} />
      )}
    </div>
  );
};
