import Skeleton from 'src/components/Skeleton/Skeleton';

export const ChatMessageLoading = () => {
  const getRandomPercentage = () => {
    return `${Math.floor(Math.random() * (80 - 20 + 1)) + 20}%`;
  };

  return (
    <div className="p-d-flex p-flex-column p-gap-2">
      <Skeleton width="15%" height="6px" loading className="p-ml-1" />
      <Skeleton
        width={getRandomPercentage()}
        height="27px"
        borderRadius="12px"
        loading
      />
      <Skeleton
        width={getRandomPercentage()}
        height="27px"
        borderRadius="12px"
        loading
      />

      <Skeleton width="15%" height="6px" loading className="p-ml-auto p-mr-1" />
      <Skeleton
        width={getRandomPercentage()}
        height="27px"
        borderRadius="12px"
        loading
        className="p-ml-auto"
      />
      <Skeleton
        width={getRandomPercentage()}
        height="27px"
        borderRadius="12px"
        loading
        className="p-ml-auto"
      />
      <Skeleton
        width={getRandomPercentage()}
        height="27px"
        borderRadius="12px"
        loading
        className="p-ml-auto"
      />
      <Skeleton
        width={getRandomPercentage()}
        height="27px"
        borderRadius="12px"
        loading
        className="p-ml-auto"
      />

      <Skeleton width="15%" height="6px" loading className="p-ml-1" />
      <Skeleton
        width={getRandomPercentage()}
        height="27px"
        borderRadius="12px"
        loading
      />

      <Skeleton width="15%" height="6px" loading className="p-ml-auto p-mr-1" />
      <Skeleton
        width={getRandomPercentage()}
        height="27px"
        borderRadius="12px"
        loading
        className="p-ml-auto"
      />
    </div>
  );
};
