import React, { useState } from 'react';

import { Button } from 'src/components/_UI';
import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

const periodos = [
  { label: 'Últimas 24 horas', value: 'ULTIMAS_24H' },
  { label: 'Últimos 7 dias', value: 'ULTIMOS_7_DIAS' },
];

const statusOptions = [
  { label: 'Notificado', value: 'NOTIFICADO' },
  { label: 'Não notificado', value: 'NAO_NOTIFICADO' },
  { label: 'Todos', value: 'TODOS' },
];

interface FilterProps {
  setSelectedAgravo: (agravos: any) => void;
}

const Filter = ({ setSelectedAgravo }: FilterProps) => {
  const [selectedPeriodo, setSelectedPeriodo] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const { setFilter } = useSearchList();

  const onSubmit = () => {
    const payload = {
      ...((selectedStatus === 'NOTIFICADO' ||
        selectedStatus === 'NAO_NOTIFICADO') && {
        notificado: selectedStatus === 'NOTIFICADO',
      }),
      ...(selectedPeriodo && { periodoTempo: selectedPeriodo }),
    };
    setFilter(payload);
    setSelectedAgravo([]);
  };
  return (
    <div className="p-grid p-ai-end">
      <div className="p-col-12 p-sm-7 p-d-flex p-flex-column p-gap-2">
        <SimpleText medium>Pesquisar</SimpleText>
        <SearchList.SearchInput
          placeholder="Pesquise um agravo"
          className="p-w-100 p-mb-1"
        />
      </div>

      <Dropdown
        label="Período de tempo"
        placeholder="Periodicidade"
        options={periodos}
        className="p-col-5 p-sm-2 p-mb-1"
        value={selectedPeriodo}
        onChange={e => setSelectedPeriodo(e.value)}
        filter={false}
        showClear
      />

      <Dropdown
        label="Status"
        placeholder="Status"
        options={statusOptions}
        className="p-col-5 p-sm-2 p-mb-1"
        value={selectedStatus}
        onChange={e => setSelectedStatus(e.value)}
        filter={false}
        showClear
      />

      <div className="p-col-2 p-sm-1">
        <Button
          label="Pesquisar"
          type="button"
          onClick={() => onSubmit()}
          className="p-mb-2"
          stretch
        />
      </div>
    </div>
  );
};

export default Filter;
