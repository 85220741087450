import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';
import { ToggleButton } from 'primereact/togglebutton';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import PacienteAPI from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';
import { AtendimentoEncaminhamentoAPI } from 'src/APIs/ProntuarioAPI/AtendimentoEncaminhamentoAPI/AtendimentoEncaminhamentoAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';
import ReportAPI from 'src/APIs/ReportAPI/ReportAPI';
import { GetEncaminhamentos } from 'src/models/APIs/ProntuarioAPI/AtendimentoEncaminhamentoAPI/AtendimentoEncaminhamentoAPI';
import * as Yup from 'yup';

import { useAssinatura } from 'src/core/hooks/Assinatura/useAssinatura';
import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { convertFilePDFToBase64, handleDownloadPDF } from 'src/utils/files';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';
import { DialogAssinarDigitalmente } from 'src/pages/Emed/Admin/AssinaturasProntuario/components/DialogAssinarDigitalmente';

import { ModalContrarreferencia } from './ModalContrarreferencia/ModalContrarreferencia';
import { ModalEditContrarreferencia } from './ModalContrarreferencia/ModalEditContrarreferencia';
import { ModalGeracaoGuia } from './ModalGeracaoGuia/ModalGeracaoGuia';
import { ModalNovoEncaminhamento } from './ModalNovoEncaminhamento/ModalNovoEncaminhamento';
import { ModalResumoGuia } from './ModalResumoGuia/ModalResumoGuia';

import './EncaminhamentoContrarreferencia.scss';

type ModalEncaminhamentoDisclosureState = {
  gerarGuia: boolean;
};

type EmailDisclosureStateType = {
  item: GetEncaminhamentos;
};

type EncaminhamentoContrarreferenciaRowProps = {
  onOpenEncaminhamento: (state?: any) => void;
  refetch: () => void;
  item: GetEncaminhamentos;
};

export const EncaminhamentoContrarreferencia = ({
  campos,
  reloadCampos,
}: any) => {
  const { agenda } = useAppSelector((state: RootState) => state);
  const { state } = useLocation();

  const { dialogAssinarDigitalmente } = useAssinatura();

  const idAtendimento = state?.idAtendimento;
  const { isMobile } = useSize();

  const [items, setItems] = useState<GetEncaminhamentos[]>([]);
  const [campo, setCampo] = useState<any>([]);
  const [checked, setChecked] = useState(
    campo.exibicaoCamposModelo === 'FIXO' ? true : false,
  );

  const modalEncaminhamento: DisclosureType<ModalEncaminhamentoDisclosureState> =
    useDisclosure({ opened: false });
  const modalGeracaoGuia = useDisclosure({ opened: false });
  const resumoGuiaTiss = useDisclosure({ opened: false });
  const dialogImpressaoAssinatura = useDisclosure({ opened: false });

  useEffect(() => {
    setCampo(
      campos.filter(
        (item: any) =>
          item.funcionalidade === 'ENCAMINHAMENTO_CONTRARREFERENCIA',
      )[0] || [],
    );
  }, [campos]);

  const fetch = useCallback(() => {
    if (idAtendimento) {
      AtendimentoEncaminhamentoAPI.loadEncaminhamentos(idAtendimento, {
        throwError: true,
      })
        .then(data => setItems(data || []))
        .catch(() => {});
    }
  }, [idAtendimento]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffectSkipFirst(() => {
    if (modalEncaminhamento.state?.gerarGuia) {
      modalGeracaoGuia.open();
      modalEncaminhamento.state.gerarGuia = false;
    }
  }, [modalEncaminhamento.state]);

  const handleFixar = async () => {
    if (campo?.obrigatorio) {
      toast.error('Não é possível desfixar um campo obrigatório');
      return;
    }

    const exibixaoCampo =
      campo?.exibicaoCamposModelo === 'FIXO' ? 'NENHUM' : 'FIXO';
    const response = await UtilsAPI.putTipoExibicaoCampos(
      Number(agenda?.profissionalAtivo?.id),
      String(campo.funcionalidade),
      exibixaoCampo,
    );
    if (response?.status === 200) {
      reloadCampos();
    }
  };

  return (
    <>
      <div className="encaminhamento-container">
        <div className="p-d-flex p-jc-between p-ai-center">
          <SimpleText
            className="p-ml-2"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Encaminhamento e Contrarreferência
          </SimpleText>
          <div className="p-d-flex p-flex-column p-flex-sm-row p-jc-end p-ai-end p-gap-2">
            <Button
              label={isMobile ? 'Novo' : 'Novo encaminhamento'}
              style={{ height: '36px' }}
              btnType="tonal"
              onClick={() => modalEncaminhamento.open()}
              stretch
            />
            <ToggleButton
              checked={checked}
              className="p-px-4 pin"
              onLabel={''}
              offLabel={''}
              onChange={e => {
                handleFixar();
                setChecked(e.value);
              }}
              onIcon="fa fa-thumbtack"
              offIcon="fa fa-thumbtack"
            />
          </div>
        </div>

        <div className="content-row">
          {!!items.length ? (
            items.map((item, idx) => (
              <EncaminhamentoContrarreferenciaRow
                key={idx}
                item={item}
                refetch={fetch}
                onOpenEncaminhamento={modalEncaminhamento.open}
              />
            ))
          ) : (
            <SimpleText style={{ textAlign: 'center' }} className="feedback">
              Nenhum Encaminhamento e Contrarreferência encontrado. <br />
              Você pode criar um novo encaminhamento no botão acima.
            </SimpleText>
          )}
        </div>
      </div>

      {modalEncaminhamento.isOpen && (
        <ModalNovoEncaminhamento reload={fetch} {...modalEncaminhamento} />
      )}
      {modalGeracaoGuia.isOpen && (
        <ModalGeracaoGuia
          onOpenResumoGuia={() => resumoGuiaTiss.open()}
          {...modalGeracaoGuia}
        />
      )}
      {resumoGuiaTiss.isOpen && <ModalResumoGuia {...resumoGuiaTiss} />}
      {dialogImpressaoAssinatura.isOpen && (
        <DialogImpressaoAssinatura {...dialogImpressaoAssinatura} />
      )}
      {dialogAssinarDigitalmente.isOpen && (
        <DialogAssinarDigitalmente {...dialogAssinarDigitalmente} />
      )}
    </>
  );
};

const EncaminhamentoContrarreferenciaRow = ({
  item,
  refetch,
  onOpenEncaminhamento,
}: EncaminhamentoContrarreferenciaRowProps) => {
  const { state } = useLocation();

  const {
    webSigner: { handleSign, isSuccess, log },
    isHsm,
    handleSignHSM,
  } = useAssinatura();
  const idAtendimento = state?.idAtendimento;
  const {
    user: { idUsuario },
  } = useAppSelector((state: RootState) => state);
  const {
    relatorioAtendimentoContrarreferencia,
    relatorioAtendimentoEncaminhamento,
  } = ReportAPI;
  const [printing, setPrinting] = useState(false);
  const [loading, setLoading] = useState(false);

  const isMedicoAtual = idUsuario === item.idMedico;

  const { isMobile, windowInnerWidth } = useSize();
  const menuProntuario = useRef<any>(null);

  const modalContrarreferenciaEdit = useDisclosure({ opened: false });
  const modalNovaContrarreferencia = useDisclosure({ opened: false });
  const dialogEnviarPorEmail = useDisclosure({ opened: false });
  const removerDisclosure = useDisclosure({ opened: false });

  const isEncaminhamento = item.tipo?.includes('Encaminhamento');

  const queryReport = isEncaminhamento
    ? relatorioAtendimentoEncaminhamento
    : relatorioAtendimentoContrarreferencia;

  const isXSmall = useMemo(() => {
    return windowInnerWidth < 470;
  }, [windowInnerWidth]);

  const isTablet = useMemo(() => windowInnerWidth < 1200, [windowInnerWidth]);

  const handleDelete = async () => {
    setLoading(true);
    if (isEncaminhamento) {
      const response = await AtendimentoEncaminhamentoAPI.deleteEncaminhamento(
        item.id,
      );
      if (response && 'status' in response) {
        setLoading(false);
        return;
      }
    } else {
      const response =
        await AtendimentoEncaminhamentoAPI.deleteContrarreferencia(item.id);
      if (response && 'status' in response) {
        setLoading(false);
        return;
      }
    }
    setLoading(false);
    removerDisclosure.close();
    refetch();
  };

  const handleDownload = async () => {
    if (!isSuccess && !isHsm) {
      const data = {
        title: log ? 'Verifique sua assinatura digital' : 'Erro',
        message:
          log ||
          'Falha ao baixar as guias, verifique sua assinatura digital e tente novamente.',
        type: 'error',
      };
      return toast(<Toast />, { data });
    }

    try {
      setPrinting(true);

      const formData = new FormData();
      formData.append('idAtendimentoEncaminhamento', item.id.toString());

      const response = await queryReport(
        formData,
        { somenteAssinado: true },
        {
          throwError: true,
          hideToast: true,
        },
      );
      const blob = new Blob([response.data], {
        type: 'application/pdf',
      });
      const file = new File([blob], '', { type: 'application/pdf' });

      const base64 = await convertFilePDFToBase64(file);

      const documents = {
        documents: [
          {
            content: base64,
            contentType: 'BASE64',
          },
        ],
      };

      const assinatura: any = isHsm
        ? await handleSignHSM(base64)
        : await handleSign(documents);

      const reportName = `rel_${item.tipo.toLowerCase()}_${dayjs().format(
        'DD/MM/YYYY',
      )}`;

      const pdfAssinado = await ReportAPI.postDocumentoAssinadoPDF(item.id, {
        base64,
        base64Assinado: !isHsm
          ? assinatura[0].data[0].signature
          : assinatura.data.documents[0].signatures[0].value,
      });

      handleDownloadPDF(pdfAssinado, reportName);
    } catch (error) {
      console.error(error);
    } finally {
      return setPrinting(false);
    }
  };

  const handlePrint = async () => {
    try {
      setPrinting(true);

      const formData = new FormData();
      formData.append('idAtendimentoEncaminhamento', item.id.toString());

      const response = await queryReport(
        formData,
        {},
        {
          throwError: true,
          hideToast: true,
        },
      );
      const blob = new Blob([response.data], {
        type: 'application/pdf',
      });
      const fileURL = URL.createObjectURL(blob);

      return window.open(fileURL);
    } catch (error) {
      console.error(error);
    } finally {
      return setPrinting(false);
    }
  };

  const menuProntuarioItems = useMemo(() => {
    if (isEncaminhamento) {
      return [
        {
          label: 'Editar',
          command: () =>
            onOpenEncaminhamento({ state: { encaminhamento: item } }),
          disabled: item.idAtendimento !== Number(idAtendimento) ? true : false,
        },
        {
          label: 'Apagar',
          command: () => removerDisclosure.open(),
          disabled: item.idAtendimento !== Number(idAtendimento) ? true : false,
        },
        {
          label: 'Enviar por e-mail',
          command: () => dialogEnviarPorEmail.open({ state: { item } }),
        },
        {
          label: 'Imprimir PDF',
          command: () => handlePrint(),
          disabled: printing,
        },
        { label: 'Baixar PDF', command: handleDownload, disabled: printing },
      ];
    }

    if (isMobile) {
      return [
        {
          label: 'Editar',
          command: () => modalContrarreferenciaEdit.open(),
          disabled: item.idAtendimento !== Number(idAtendimento),
        },
        {
          label: 'Apagar',
          command: () => removerDisclosure.open(),
          disabled: item.idAtendimento !== Number(idAtendimento),
        },
        {
          label: 'Enviar por e-mail',
          command: () => dialogEnviarPorEmail.open({ state: { item } }),
        },
        { label: 'Imprimir PDF', command: handlePrint, disabled: printing },
        { label: 'Baixar PDF', command: handleDownload, disabled: printing },
      ];
    }

    return [
      {
        label: 'Enviar por e-mail',
        command: () => dialogEnviarPorEmail.open({ state: { item } }),
      },
      { label: 'Imprimir PDF', command: handlePrint, disabled: printing },
      { label: 'Baixar PDF', command: handleDownload, disabled: printing },
    ];
    // eslint-disable-next-line
  }, [isMobile, isEncaminhamento, printing]);

  const canAnswerEncaminhamento = useMemo(() => {
    return !item.respondido && (isMedicoAtual || !item.idMedico);
  }, [isMedicoAtual, item]);

  // Row contrarreferência
  if (!isEncaminhamento) {
    return (
      <div className="encaminhamento-row">
        <div className="tag-container p-flex-1 p-text-truncate">
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            className={`tag tag-c p-text-truncate`}
            bold
          >
            {isMobile ? 'C' : 'Contrarreferência'}
          </SimpleText>
        </div>

        {!isMobile && (
          <div className="p-col-3 p-md-2 p-d-flex p-flex-column p-gap-2">
            <SimpleText
              className="p-text-truncate"
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Tipo encaminhamento
            </SimpleText>
            <SimpleText className="p-text-truncate">
              {item.descricaoTipoEncaminhamento}
            </SimpleText>
          </div>
        )}

        <div className="p-col-4 p-md-2 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Especialidade
          </SimpleText>
          <SimpleText>{item.nomeEspecialidade}</SimpleText>
        </div>

        {!isXSmall && (
          <div className="p-col-4 p-md-3 p-d-flex p-flex-column p-gap-2">
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Encaminhado em:
            </SimpleText>
            <div className="p-d-flex p-gap-2">
              <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                <i className="fa fa-calendar-days" />
              </SimpleText>
              <SimpleText>
                {dayjs(item.dataEncaminhamento).format('DD/MM/YYYY')}
              </SimpleText>
            </div>
          </div>
        )}

        <div className="p-col-2 p-col-3 p-d-flex p-jc-end p-ai-center p-gap-1">
          {item.idAtendimento === Number(idAtendimento) && !isMobile && (
            <>
              <Button
                icon="fa fa-edit"
                btnType="gray"
                onClick={() => modalContrarreferenciaEdit.open()}
                loading={loading || printing}
              />
              <Button
                icon="fa fa-trash"
                btnType="gray"
                onClick={() => removerDisclosure.open()}
                loading={loading || printing}
              />
            </>
          )}
          <Button
            icon="fas fa-ellipsis-h"
            btnType="gray"
            onClick={event => menuProntuario?.current?.toggle(event)}
            aria-controls="opcoes-menu"
            aria-haspopup
            loading={loading || printing}
          />

          <TieredMenu
            id="opcoes-menu"
            model={menuProntuarioItems}
            popup
            ref={menuProntuario}
          />
        </div>

        {modalContrarreferenciaEdit.isOpen && (
          <ModalEditContrarreferencia
            reload={refetch}
            item={item}
            {...modalContrarreferenciaEdit}
          />
        )}
        {dialogEnviarPorEmail.isOpen && (
          <DialogEnviarPorEmail {...dialogEnviarPorEmail} />
        )}

        <ConfirmDialog
          visible={removerDisclosure.isOpen}
          header={`Deseja remover o encaminhamento?`}
          text={`Se você realmente deseja remover o encaminhamento, clique em sim para continuar.`}
          confirmText="Sim, remover"
          onHide={removerDisclosure.close}
          onConfirm={handleDelete}
        />
      </div>
    );
  }

  return (
    <div className="encaminhamento-row">
      <div className="tag-container p-col-2 p-text-truncate">
        <SimpleText
          className={`tag tag-e p-text-truncate`}
          fontSize={FONT_SIZE.XXS}
          bold
        >
          {isMobile ? 'E' : 'Encaminhamento'}
        </SimpleText>
      </div>

      {!isMobile && (
        <div className="p-col-2 p-d-flex p-flex-column p-gap-2">
          <SimpleText
            className="p-text-truncate"
            fontSize={FONT_SIZE.XXXS}
            fontColor={FONT_COLOR.COLOR_60}
          >
            Tipo encaminhamento
          </SimpleText>
          <SimpleText className="p-text-truncate">
            {item?.descricaoTipoEncaminhamento}
          </SimpleText>
        </div>
      )}

      <div className="p-col-6 p-sm-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
          Especialidade
        </SimpleText>
        <SimpleText>{item?.nomeEspecialidade}</SimpleText>
      </div>

      {!isMobile && (
        <div className="p-col-2 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Médico
          </SimpleText>
          <SimpleText className="p-text-climp-2">
            {item?.nomeMedico || '-'}
          </SimpleText>
        </div>
      )}

      {!isMobile && (
        <div className="p-col-2 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontSize={FONT_SIZE.XXXS} fontColor={FONT_COLOR.COLOR_60}>
            Encaminhado em:
          </SimpleText>
          <div className="p-d-flex p-gap-2">
            <SimpleText fontColor={FONT_COLOR.COLOR_60}>
              <i className="fa fa-calendar-days" />
            </SimpleText>
            <SimpleText>
              {dayjs(item?.dataEncaminhamento).format('DD/MM/YYYY')}
            </SimpleText>
          </div>
        </div>
      )}

      <div className="p-col-3 p-md-2 p-d-flex p-jc-end p-ai-center p-gap-2">
        {canAnswerEncaminhamento && (
          <>
            {isTablet ? (
              <Button
                icon="fa-solid fa-circle-plus"
                btnType="tonal"
                btnSize="medium"
                onClick={() => modalNovaContrarreferencia.open()}
              />
            ) : (
              <Button
                className="btn-icon-responder"
                btnType="tonal"
                label="Responder"
                icon="fa-solid fa-circle-plus"
                onClick={() => modalNovaContrarreferencia.open()}
              />
            )}
          </>
        )}

        <Button
          icon="fas fa-ellipsis-h"
          btnType="gray"
          onClick={event => menuProntuario?.current?.toggle(event)}
          aria-controls="opcoes-menu"
          aria-haspopup
        />

        <TieredMenu
          id="opcoes-menu"
          model={menuProntuarioItems}
          popup
          ref={menuProntuario}
        />
      </div>

      {modalNovaContrarreferencia.isOpen && (
        <ModalContrarreferencia
          item={item}
          reload={refetch}
          {...modalNovaContrarreferencia}
        />
      )}
      {dialogEnviarPorEmail.isOpen && (
        <DialogEnviarPorEmail {...dialogEnviarPorEmail} />
      )}
      <ConfirmDialog
        visible={removerDisclosure.isOpen}
        header={`Deseja remover o encaminhamento?`}
        text={`Se você realmente deseja remover o encaminhamento, clique em sim para continuar.`}
        confirmText="Sim, remover"
        onHide={removerDisclosure.close}
        onConfirm={handleDelete}
      />
    </div>
  );
};

const DialogImpressaoAssinatura = ({ isOpen, close }: DisclosureType) => {
  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      style={{ width: '528px' }}
      header="Assinatura digital"
    >
      <div className="p-d-flex p-flex-column p-gap-4">
        <SimpleText>
          Para fazer o download da guia você precisa assinar digitalmente.
        </SimpleText>
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            label="Cancelar"
            stretch
            btnType="ghost"
            onClick={() => close()}
          />
          <Button label="Assinar" stretch onClick={() => close()} />
        </div>
      </div>
    </Dialog>
  );
};

const DialogEnviarPorEmail = ({
  isOpen,
  close,
  state,
}: DisclosureType<EmailDisclosureStateType>) => {
  const {
    atendimento: { paciente },
  } = useAppSelector((state: RootState) => state);
  const {
    webSigner: { handleSign, isSuccess, log },
    isHsm,
    handleSignHSM,
  } = useAssinatura();

  const [pacienteData, setPaciente] = useState<Paciente>({});

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('E-mail não é valido')
      .required('E-mail é obrigatório'),
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (paciente?.id) {
      PacienteAPI.loadPacienteById(paciente?.id).then(data => [
        form.setValue('email', data?.email || ''),
        setPaciente(data),
      ]);
    }
  }, [form, paciente?.id]);

  const handleAtualizarEmail = useCallback(
    (email: string): Promise<any> => {
      const payload = {
        ...pacienteData,
        email,
      };

      return PacienteAPI.editarDadosPaciente(paciente.id, payload, {
        hideToast: true,
      });
    },
    [paciente.id, pacienteData],
  );

  const onSubmit = useCallback(
    async (values: any) => {
      if (!isSuccess && !isHsm) {
        const data = {
          title: log ? 'Verifique sua assinatura digital' : 'Erro',
          message:
            log ||
            'Falha ao baixar as guias, verifique sua assinatura digital e tente novamente.',
          type: 'error',
        };
        return toast(<Toast />, { data });
      }
      try {
        const query =
          state?.item.tipo === 'Encaminhamento'
            ? ReportAPI.relatorioAtendimentoEncaminhamentoPorEmail
            : ReportAPI.relatorioAtendimentoContrarreferenciaPorEmail;

        const formData = new FormData();
        formData.append(
          'idAtendimentoEncaminhamento',
          state?.item.id.toString() || '',
        );
        formData.append('emailDestinatario', values.email);
        formData.append('nomePaciente', paciente?.nome);

        const response = await query(formData, {assinar: true}, {
          throwError: true,
          hideToast: true,
        });
       
        const base64 = response.data.base64;

        const sequenciaAssinatura = response.data.sequenciaAssinatura
        
        const documents = {
          documents: [
            {
              content: base64,
              contentType: 'BASE64',
            },
          ],
        };

        const assinatura: any = isHsm
          ? await handleSignHSM(base64)
          : await handleSign(documents);

        await ReportAPI.enviarPorEmailAssinado(Number(state?.item.id), {
          base64Assinado: !isHsm
            ? assinatura[0].data[0].signature
            : assinatura.data.documents[0].signatures[0].value,
          emailDestinatario: values.email,
          sequenciaAssinatura,
        });

        handleAtualizarEmail(values.email);
      } catch (error) {
        console.error(error);
      } finally {
        return close();
      }
    },
    [
      close,
      handleAtualizarEmail,
      handleSign,
      handleSignHSM,
      isHsm,
      isSuccess,
      log,
      paciente?.nome,
      state?.item.id,
      state?.item.tipo,
    ],
  );

  return (
    <Dialog
      visible={isOpen}
      onHide={close}
      style={{ width: '528px' }}
      header="Enviar por e-mail"
    >
      <div className="p-d-flex p-flex-column">
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormInput
              name="email"
              autoFocus
              className="p-col-12 p-p-0"
              label="E-mail do paciente"
            />
            <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
              <Button
                label="Cancelar"
                stretch
                btnType="ghost"
                onClick={() => close()}
                loading={form.formState.isSubmitting}
              />
              <Button
                label="Enviar"
                stretch
                type="submit"
                loading={form.formState.isSubmitting}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </Dialog>
  );
};
