import { useCallback, useEffect, useState } from 'react';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { PlanoCuidadoAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoAPI/PlanoCuidadoAPI';
import * as Yup from 'yup';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import {
  PlanoCuidadoConsultaItem,
  PlanoCuidadoExamesItem,
  PlanoCuidadoPedidoCuidadoItem,
} from './components';
import { PlanoCuidadoFormularioDinamico } from './components/PlanoCuidadoFormularioDinamico';
import { Button } from 'src/components/_UI/Button';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import { FieldCiapMultiselect } from 'src/components/Fields/FieldCiapMultiselect/FieldCiapMultiselect';
import { FieldCidsMultiselect } from 'src/components/Fields/FieldCidsMultiselect/FieldCidsMultiselect';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import FormInput from 'src/components/FormInput/FormInput';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { ModalAddAtividade } from './ModalAddAtividade/ModalAddAtividade';

import './AddPlanoCuidado.scss';

type Props = DisclosureType & {
  refetch: () => void;
};

export const AddPlanoCuidado = ({ isOpen, close, state, refetch }: Props) => {
  const [loading, setLoading] = useState(false);

  const modalAdd = useDisclosure({ opened: false });

  const form = useForm({
    defaultValues: { convenio: null, planos: [] as any[] },
    resolver: yupResolver(
      Yup.object({
        nome: Yup.string().required('Campo obrigatório'),
        convenio: Yup.object().required('Campo obrigatório'),
        cids: Yup.array().notRequired().nullable(),
        ciaps: Yup.array().notRequired().nullable(),
        planoCuidado: Yup.string().required('Campo obrigatório').nullable(),
        diasVigencia: Yup.number()
          .required('Campo obrigatório')
          .typeError('Campo obrigatório'),
        planos: Yup.array().required('Campo obrigatório'),
      }),
    ),
  });

  useEffect(() => {
    if (state?.id) {
      setLoading(true);

      PlanoCuidadoAPI.loadPlanoCuidadoById(state?.id, { throwError: true })
        .then(data => {
          form.reset({
            ...data,
            planos: [
              ...data?.planoCuidadoAtividadeConsultas?.map(item => ({
                ...item,
                atividade: 'CONSULTA',
              })),
              ...data?.planoCuidadoAtividadeCuidados?.map(item => ({
                ...item,
                nomeCuidado: item.nome,
                atividade: 'PEDIDO_CUIDADO',
              })),
              ...data?.planoCuidadoAtividadeExames?.map(item => ({
                ...item,
                atividade: 'EXAMES',
              })),
              ...data?.planoCuidadoAtividadeFormularioDinamicos?.map(item => ({
                ...item,
                atividade: 'FORMULARIO_DINAMICO',
              })),
            ] as any[],
          });
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }
  }, [form, state?.id]);

  const {
    fields: planos,
    remove,
    update,
  } = useFieldArray({
    control: form.control,
    name: 'planos',
    keyName: 'id_x',
  });

  const handleDelete = useCallback(
    (idx: number) => {
      remove(idx);
    },
    [remove],
  );

  const handleSubmit = useCallback(
    (data): Promise<void> => {
      if (state?.id) {
        return PlanoCuidadoAPI.updatePlanoCuidadoById(
          { id: state?.id },
          { ...data, id: state?.id },
          {
            throwError: true,
          },
        )
          .then(() => {
            close();
            refetch();
          })
          .catch(() => {});
      }

      return PlanoCuidadoAPI.createPlanoCuidado(data, { throwError: true })
        .then(() => {
          close();
          refetch();
        })
        .catch(() => {});
    },
    [close, refetch, state?.id],
  );

  const onSubmit = useCallback(
    (values: any) => {
      const idsCids = values.cids?.map((cid: Cids) => cid.id) || [];
      const idsCiaps = values.ciaps?.map((ciap: any) => ciap.id) || [];

      const atividades = values.planos.reduce(
        (acc: any, plano: any) => {
          switch (plano.atividade) {
            case 'CONSULTA':
              return {
                ...acc,
                planoCuidadoAtividadeConsultas: [
                  ...acc.planoCuidadoAtividadeConsultas,
                  {
                    ...plano,
                    idEspecialidade: plano.especialidade?.id,
                  },
                ],
              };
            case 'PEDIDO_CUIDADO':
              return {
                ...acc,
                planoCuidadoAtividadeCuidados: [
                  ...acc.planoCuidadoAtividadeCuidados,
                  {
                    ...plano,
                    idplanoCuidado: plano?.tipo?.id,
                  },
                ],
              };
            case 'EXAMES':
              return {
                ...acc,
                planoCuidadoAtividadeExames: [
                  ...acc.planoCuidadoAtividadeExames,
                  {
                    ...plano,
                    idServicoTuss: plano?.servicoTuss?.id,
                  },
                ],
              };
            case 'FORMULARIO_DINAMICO':
              return {
                ...acc,
                planoCuidadoAtividadeFormularioDinamicos: [
                  ...acc.planoCuidadoAtividadeExames,
                  {
                    id: plano?.id || undefined,
                    idFormularioDinamico: plano?.formularioDinamico?.id,
                    recorrencia: plano?.recorrencia,
                    execucao: plano?.execucao,
                  },
                ],
              };
            default:
              return acc;
          }
        },
        {
          planoCuidadoAtividadeConsultas: [],
          planoCuidadoAtividadeCuidados: [],
          planoCuidadoAtividadeExames: [],
          planoCuidadoAtividadeFormularioDinamicos: [],
        },
      );

      return handleSubmit({
        nome: values.nome,
        diasVigencia: values.diasVigencia,
        idsCids,
        idsCiaps,
        idConvenio: values.convenio?.id,
        planoCuidado: values.planoCuidado,
        ...atividades,
      });
    },
    [handleSubmit],
  );

  const planoComponent = useCallback(
    (item: any, idx: number) => {
      if (item.atividade === 'CONSULTA')
        return (
          <PlanoCuidadoConsultaItem
            key={idx}
            item={item}
            handleDelete={() => handleDelete(idx)}
            onEdit={() => modalAdd.open({ state: { item, idx, update } })}
          />
        );
      if (item.atividade === 'PEDIDO_CUIDADO')
        return (
          <PlanoCuidadoPedidoCuidadoItem
            key={idx}
            item={item}
            handleDelete={() => handleDelete(idx)}
            onEdit={() => modalAdd.open({ state: { item, idx, update } })}
          />
        );
      if (item.atividade === 'EXAMES')
        return (
          <PlanoCuidadoExamesItem
            key={idx}
            item={item}
            handleDelete={() => handleDelete(idx)}
            onEdit={() => modalAdd.open({ state: { item, idx, update } })}
          />
        );
      if (item.atividade === 'FORMULARIO_DINAMICO')
        return (
          <PlanoCuidadoFormularioDinamico
            key={idx}
            item={item}
            handleDelete={() => handleDelete(idx)}
            onEdit={() => modalAdd.open({ state: { item, idx, update } })}
          />
        );

      return null;
    },
    [handleDelete, modalAdd, update],
  );

  const convenio = form.watch('convenio');

  return (
    <Dialog
      style={{ width: '1012px' }}
      header={!!state?.id ? 'Editar plano de cuidado' : 'Novo plano de cuidado'}
      onHide={close}
      visible={isOpen}
      className="dialog-add-plano-cuidado"
      maximizedMobileSize
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="p-grid p-ai-center"
        >
          <FieldConvenio
            name="convenio"
            label="Convenio"
            className="p-col-12"
            disabled={!!planos.length}
            loading={loading}
          />
          <FormInput
            label="Nome do plano"
            name="nome"
            className="p-col-12 p-md-9"
            hideTextErrorSpace
            loading={loading}
            maxLength={100}
          />
          <DropdownControlled
            control={form.control}
            name="planoCuidado"
            label="Cuidado"
            filter={false}
            className="p-col-12 p-md-3"
            options={[
              { label: 'Normal', value: 'NORMAL' },
              { label: 'Preventivo', value: 'PREVENTIVO' },
            ]}
          />

          <FieldCidsMultiselect
            label="CID"
            name="cids"
            className="p-sm-6 p-md-5 p-col-12"
            loading={loading}
          />
          <FieldCiapMultiselect
            label="CIAP"
            name="ciaps"
            className="p-sm-6 p-md-4 p-col-12"
            loading={loading}
          />

          <div className="p-md-3 p-col-6">
            <FormInput
              name="diasVigencia"
              label="Período de vigência"
              sublabel="(dias)"
              placeholder="Período em dias"
              type="number"
              min={1}
              max={2147483647}
              hideTextErrorSpace
              loading={loading}
            />
          </div>

          <div className="p-col-12 p-d-flex p-ai-center p-jc-between p-mt-3">
            <SimpleText medium>Atividades do plano</SimpleText>
            <Button
              icon="fas fa-plus"
              type="button"
              label="Adicionar atividade"
              onClick={() => modalAdd.open()}
              disabled={!convenio}
            />
          </div>

          <div className="content">
            {loading ? (
              <div className="p-d-flex p-flex-column p-gap-1 p-p-2">
                <Skeleton loading height="40px" />
                <Skeleton loading height="40px" />
                <Skeleton loading height="40px" />
              </div>
            ) : (
              <>
                {planos.length ? (
                  planos.map((item, idx) => planoComponent(item, idx))
                ) : (
                  <div
                    style={{ height: '100px' }}
                    className="p-d-flex p-ai-center p-jc-center"
                  >
                    <SimpleText>Sem planos cadastrados</SimpleText>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              btnType="ghost"
              label="Cancelar"
              type="button"
              onClick={() => close()}
              stretch
              disabled={form.formState.isSubmitting}
            />
            <Button
              label="Salvar"
              type="submit"
              stretch
              disabled={!planos.length}
              loading={form.formState.isSubmitting}
            />
          </div>

          {modalAdd.isOpen && <ModalAddAtividade {...modalAdd} />}
        </form>
      </FormProvider>
    </Dialog>
  );
};
