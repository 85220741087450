import { useMemo } from 'react';

import useSize from 'src/core/hooks/useSize';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

export const MonitoramentoSMSHeader = () => {
  const { windowInnerWidth } = useSize();

  const isCustomSize = useMemo(
    () => windowInnerWidth < 992,
    [windowInnerWidth],
  );

  return (
    <div className="p-col-12 p-d-flex p-ai-center p-text-truncate">
      <SimpleText
        medium
        className="p-lg-1 p-xl-2"
        fontColor="color_40"
        fontSize="xxs"
      >
        Consultório
      </SimpleText>

      <SimpleText
        medium
        className="p-col-2"
        fontSize="xxs"
        fontColor="color_40"
      >
        Paciente
      </SimpleText>
      <SimpleText
        medium
        className="p-col-2"
        fontSize="xxs"
        fontColor="color_40"
      >
        Profissional
      </SimpleText>
      <SimpleText
        medium
        className="p-col-2"
        fontSize="xxs"
        fontColor="color_40"
      >
        Data agendamento
      </SimpleText>

      <SimpleText
        medium
        className="p-col-2 p-lg-1"
        fontSize="xxs"
        fontColor="color_40"
      >
        Status envio
      </SimpleText>
      <SimpleText
        medium
        className="p-col-2 p-lg-2"
        fontSize="xxs"
        fontColor="color_40"
      >
        Status agendamento
      </SimpleText>

      {!isCustomSize && (
        <SimpleText
          medium
          className="p-col p-xl-1 p-text-center"
          fontSize="xxs"
          fontColor="color_40"
        >
          Ações
        </SimpleText>
      )}
    </div>
  );
};
