import { memo, useState } from 'react';

import Dropzone, { Accept } from 'react-dropzone';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import PacienteAPI from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import SelectButtonControlled from 'src/components/Basics/SelectButtonControlled/SelectButtonControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import FormInput from 'src/components/FormInput/FormInput';

import { useAgenda } from '../../../AgendaContext';
import { formatarDados } from '../helpers';

import { validationSchema } from './validation';
import './FormDadosPessoais.scss';

const accept: Accept = {
  'image/jpeg': ['.jpeg', '.png'],
};

const FormDadosPessoais = ({ paciente, handleCancel, foto, setFoto }: any) => {
  const [exibirModalPacienteSemelhante, setExibirModalPacienteSemelhante] =
    useState(false);
  const [pacientesSemelhantes, setPacientesSemelhantes] = useState<any[]>([]);
  const [pacientesDuplicados, setPacientesDuplicados] = useState<any[]>([]);
  const [dadosPaciente, setDadosPaciente] = useState<any>({});
  const [showAlertBox, setShowAlertBox] = useState<boolean>(true);

  const useFormMethods = useForm({
    defaultValues: {
      ...paciente,
      sexoDescricao: paciente?.sexo?.descricao,
      dataNascimento: dayjs(paciente?.dataNascimento).toDate(),
      telefoneCelular: paciente?.contatos?.find(
        (c: any) => c.tipo === 'CELULAR_PARTICULAR',
      )?.numero,
      telefoneRecado: paciente?.contatos?.find((c: any) => c.tipo === 'RECADO')
        ?.numero,
      telefoneFixo: paciente?.contatos?.find(
        (c: any) => c.tipo === 'FIXO_RESIDENCIAL',
      )?.numero,
      maeDesconhecida: paciente?.nomeMae === 'Mãe desconhecida',
    },
    resolver: yupResolver(validationSchema),
  });

  const { editPacienteSemReload } = useAgenda();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
    reset,
  } = useFormMethods;

  const watchMaeDesconhecida = watch('maeDesconhecida');
  const watchEstrangeiro = watch('estrangeiro');

  const CustomSimpleText = ({ text, separator = true }: any) => {
    return (
      <>
        {text && (
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            className={`${FONT_COLOR.COLOR_60} p-mr-1`}
          >
            {separator && '•'} {text}
          </SimpleText>
        )}
      </>
    );
  };

  const onSubmit = async (data: any) => {
    try {
      const dadosPaciente = await formatarDados({
        ...paciente,
        ...data,
        foto: foto || paciente?.foto,
      });

      dadosPaciente.cpf === '' && (dadosPaciente.cpf = null);
      dadosPaciente.maeDesconhecida && (dadosPaciente.nomeMae = null);

      const semelhantes = await PacienteAPI.pacientesSemelhantes(
        dadosPaciente,
        {
          throwError: true,
        },
      );

      if (semelhantes?.pacientesDuplicados?.length > 0) {
        setPacientesDuplicados(semelhantes.pacientesDuplicados);
        setExibirModalPacienteSemelhante(true);
      } else if (semelhantes?.pacientesSemelhantes?.length > 0) {
        setDadosPaciente(dadosPaciente);
        setExibirModalPacienteSemelhante(true);
        setPacientesSemelhantes(semelhantes.pacientesSemelhantes);
      } else if (semelhantes?.pacientesSemelhantes) {
        const response = await PacienteAPI.editarDadosPaciente(
          paciente.id,
          dadosPaciente,
          {
            throwError: true,
          },
        );

        editPacienteSemReload(response);

        reset({
          ...response,
          sexoDescricao: response.sexo?.descricao.toUpperCase(),
          dataNascimento: dayjs(response?.dataNascimento).toDate(),
          telefoneCelular:
            response?.contatos?.find(
              (c: any) => c.tipo === 'CELULAR_PARTICULAR',
            )?.numero || '',
          telefoneRecado:
            response?.contatos?.find((c: any) => c.tipo === 'RECADO')?.numero ||
            '',
          telefoneFixo:
            response?.contatos?.find((c: any) => c.tipo === 'FIXO_RESIDENCIAL')
              ?.numero || '',
        });
      }
    } catch {}
  };

  return (
    <div id="form-dados-pessoais">
      <FormProvider {...useFormMethods}>
        <form onSubmit={handleSubmit(onSubmit)} className="p-grid">
          <SimpleText className={`title p-mb-4 ${FONT_COLOR.COLOR_40}`}>
            Básicas
          </SimpleText>

          <div className="foto-paciente p-col-12">
            <Dropzone
              accept={accept}
              onDrop={(f: any) => {
                setFoto(f[0]);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dropzone p-mb-4">
                    <img
                      style={{ objectFit: 'cover' }}
                      src={
                        foto?.path
                          ? URL.createObjectURL(foto)
                          : `${
                              paciente.urlFoto
                                ? paciente.urlFoto
                                : 'https://www.treeage.com/wp-content/uploads/2020/02/camera.jpg'
                            }`
                      }
                      alt="Foto do paciente"
                    />
                  </div>
                </div>
              )}
            </Dropzone>
          </div>

          <FormInput
            name="nome"
            label="Nome completo"
            placeholder="Nome completo do paciente"
            className="p-col-12 p-md-8"
          />

          <SelectButtonControlled
            control={control}
            name="sexoDescricao"
            label="Sexo"
            options={[
              { label: 'Masculino', value: 'MASCULINO' },
              { label: 'Feminino', value: 'FEMININO' },
            ]}
            className="p-col-12 p-md-4"
            style={{
              width: 'max-content',
            }}
          />

          <MaskedInputControlled
            control={control}
            name="cpf"
            label="CPF"
            mask={MASK.CPF}
            placeholder="CPF do paciente"
            className="p-col-12 p-md-4"
            errorMsg={errors.cpf?.message}
          />

          <FormInput
            name="cns"
            label="CNS"
            placeholder="CNS do paciente"
            className="p-col-12 p-md-4"
          />

          <CalendarInputControlled
            control={control}
            name="dataNascimento"
            label="Data de nascimento"
            className="p-col-12 p-md-4"
            errorMsg={errors.dataNascimento?.message}
          />

          {!watchMaeDesconhecida && (
            <FormInput
              name="nomeMae"
              label="Nome da mãe"
              placeholder="Nome da mãe do paciente"
              className="p-col-12"
            />
          )}

          <CheckboxControlled
            control={control}
            name="maeDesconhecida"
            label="Mãe desconhecida"
            className="p-col-12"
          />

          <CheckboxControlled
            control={control}
            name="estrangeiro"
            label="Estrangeiro"
            className="p-col-12"
          />

          {watchEstrangeiro && (
            <FormInput
              name="passaporte"
              label="Passaporte"
              placeholder="Passaporte do paciente"
              className="p-col-12 p-md-4"
              maxLength={9}
            />
          )}

          <SimpleText
            className={`title p-my-2 p-col-12 ${FONT_COLOR.COLOR_40}`}
          >
            Contato
          </SimpleText>

          <FormInput
            name="email"
            label="E-mail"
            placeholder="E-mail do paciente"
            className="p-col-12"
          />

          <MaskedInputControlled
            control={control}
            name="telefoneCelular"
            label="Telefone celular"
            mask={MASK.PHONE}
            placeholder="99 99999-9999"
            className="p-col-12 p-md-4"
            errorMsg={errors['']?.message}
          />

          <MaskedInputControlled
            control={control}
            name="telefoneRecado"
            label="Telefone contato"
            mask={MASK.PHONE}
            placeholder="99 99999-9999"
            className="p-col-12 p-md-4"
            errorMsg={errors['']?.message}
          />

          <MaskedInputControlled
            control={control}
            name="telefoneFixo"
            label="Telefone fixo"
            mask={MASK.TELEPHONE}
            placeholder="99 9999-9999"
            className="p-col-12 p-md-4"
            errorMsg={errors['']?.message}
          />

          <SimpleText
            className="title p-my-2 p-col-12"
            fontColor={FONT_COLOR.COLOR_40}
          >
            Outros
          </SimpleText>

          <FormInput
            name="religiao"
            label="Religião"
            placeholder="Religião do paciente"
            className="p-col-12"
          />

          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              label="Cancelar"
              btnType="ghost"
              onClick={handleCancel}
              stretch
            />

            <Button
              label="Salvar"
              type="submit"
              stretch
              loading={isSubmitting}
            />
          </div>
        </form>
      </FormProvider>

      <Dialog
        style={{ width: '750px' }}
        header="Paciente duplicado"
        visible={exibirModalPacienteSemelhante}
        onHide={() =>
          setExibirModalPacienteSemelhante((prev: boolean) => !prev)
        }
      >
        <>
          <SimpleText className={`${FONT_COLOR.COLOR_40}`}>
            Já existe um paciente com as mesmas informações registradas:
          </SimpleText>
          <div className=" p-mt-4 p-mb-2">
            <SimpleText className={`${FONT_COLOR.COLOR_16}`}>
              Lista de pacientes
            </SimpleText>
          </div>
          <div className="pacientes-semelhantes">
            {(pacientesDuplicados.length > 0
              ? pacientesDuplicados
              : pacientesSemelhantes
            )?.map(paciente => (
              <div className="card-paciente__semelhante" key={paciente.id}>
                <div className="paciente-foto">
                  <img
                    src={
                      paciente?.urlFoto ||
                      'https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg'
                    }
                    alt={paciente.nome}
                  />
                </div>
                <div className="paciente-infos">
                  <CustomSimpleText
                    separator={false}
                    text={`Registro no. ${paciente.id}`}
                  />
                  <SimpleText
                    className="nome-paciente"
                    fontSize={FONT_SIZE.XXS}
                  >
                    {paciente.nome}
                  </SimpleText>
                  <div className="paciente-dados">
                    <CustomSimpleText separator={false} text={paciente.cpf} />
                    <CustomSimpleText
                      text={paciente.idSexo === 1 ? 'Feminino' : 'Masculino'}
                    />
                    <CustomSimpleText text={paciente.dataNascimento} />
                  </div>
                  <div className="paciente-dados">
                    <CustomSimpleText separator={false} text={paciente.cns} />
                    <CustomSimpleText text={paciente.passaporte} />
                    <CustomSimpleText text={paciente.nomeMae} />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {!!pacientesDuplicados.length && (
            <div className="p-mt-5">
              <AlertBox
                visible={showAlertBox}
                onHide={() => setShowAlertBox(false)}
                text="Para unificar os registros: cancele o agendamento, realize um novo e solicite a migração dos prontuarios."
              />
            </div>
          )}
          <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
            <Button
              label="Cancelar"
              btnType="ghost"
              onClick={() =>
                setExibirModalPacienteSemelhante((prev: boolean) => !prev)
              }
              stretch
            />
            <Button
              label="Salvar"
              onClick={async () => {
                await PacienteAPI.editarDadosPaciente(
                  paciente.id,
                  dadosPaciente,
                );
                setExibirModalPacienteSemelhante(false);
              }}
              disabled={!!pacientesDuplicados.length}
              stretch
            />
          </div>
        </>
      </Dialog>
    </div>
  );
};

export default memo(FormDadosPessoais);
