import { useContext, useEffect } from 'react';

import {
  Sidebar as PrimeSidebar,
  SidebarProps as PrimeSidebarProps,
} from 'primereact/sidebar';

import ThemeContext from 'src/core/themes/ThemeContext';

import SpinnerLoading from '../SpinnerLoading/SpinnerLoading';

import './Sidebar.scss';

interface SidebarProps extends PrimeSidebarProps {
  children: JSX.Element | JSX.Element[];
  header?: JSX.Element;
  loading?: boolean;
}

const Sidebar = ({
  header,
  loading,
  children,
  className,
  ...rest
}: SidebarProps) => {
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    document.body.style.overflowY = 'clip';
    return () => {
      document.body.style.overflowY = '';
    };
  }, []);

  return (
    <PrimeSidebar className={`Sidebar-comp ${className} ${theme}`} {...rest}>
      {header}

      {!!loading ? <SpinnerLoading full /> : children}
    </PrimeSidebar>
  );
};

export default Sidebar;
