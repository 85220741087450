import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import AtendimentoAPI from 'src/APIs/AgendaAPI/Atendimento/AtendimentoAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { setAtendimento } from 'src/core/redux/slices/atendimento/AtendimentoSlice';

import { convertFieldKeyToCamelCase } from '../Atendimento/IniciarAtendimento/utils';

import DataOcorrencia from '../components/DataOcorrencia/DataOcorrencia';
import { MotivoAtendimentoGrupo } from './_components/MotivoAtendimentoGrupo/MotivoAtendimentoGrupo';
import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import Anamnese from '../Atendimento/IniciarAtendimento/Anamnese/Anamnese';
import Cid from '../Atendimento/IniciarAtendimento/Cid/Cid';
import DecisaoTerapeutica from '../Atendimento/IniciarAtendimento/DecisaoTerapeutica/DecisaoTerapeutica';
import PatologiaDetectada from '../Atendimento/IniciarAtendimento/PatologiaDetectada/PatologiaDetectada';
import { justificativaOcorrenciaRetroativaValidation } from '../FichaPaciente/helpers';

import './AtendimentoGrupo.scss';

type Props = {
  atendimento: any;
  pacientesGrupo: PacienteProntuario[];
};

export const AtendimentoGrupoContent = ({ atendimento }: Props) => {
  const [loadingProntuario, setLoadingProntuario] = useState(false);

  const [prontuario, setProntuario] = useState<any>({});
  const [campos, setCampos] = useState([]);

  const { setCamposPEP, iniciarAtendimento, atendimentoStatus } =
    useAtendimento();

  const dispatch = useDispatch();

  const isAtendendo = atendimentoStatus === 'ATENDENDO';
  const idAtendimentoGrupo = atendimento?.id;

  const form = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        dataOcorrencia: Yup.date().required('Informe a data da ocorrencia'),
        justificativaOcorrenciaRetroativa:
          justificativaOcorrenciaRetroativaValidation(null),
      }),
    ),
  });

  const watchDataOcorrencia = form.watch('dataOcorrencia');

  const handleOcorrenciaRetroativa = useCallback(async () => {
    const data = {
      dataOcorrencia: watchDataOcorrencia,
      justificativaOcorrencia: form.getValues('justificativaOcorrencia'),
    };

    await AtendimentoAPI.blurOcorrenciaRetroativa(
      data,
      Number(idAtendimentoGrupo),
    );
  }, [form, idAtendimentoGrupo, watchDataOcorrencia]);

  const getProntuario = useCallback(async () => {
    if (!idAtendimentoGrupo) return;

    try {
      setLoadingProntuario(true);

      const response = await UtilsAPI.getProntuario(
        Number(idAtendimentoGrupo),
        {
          throwError: true,
        },
      );
      setProntuario(response);

      dispatch(
        setAtendimento({
          ...response.atendimento,
          particular: response?.particular || false,
          retorno: response?.retorno || false,
          tempoGastoAtendimento: response.tempoGastoAtendimento,
        }),
      );
    } catch (error) {
      setProntuario({});
    }
  }, [dispatch, idAtendimentoGrupo]);

  const getCampos = useCallback(async () => {
    if (!idAtendimentoGrupo) return;
    const response = await UtilsAPI.getCampos(Number(idAtendimentoGrupo), {});
    setCampos(response);
  }, [idAtendimentoGrupo]);

  const updateCamposProntuario = (novosCampos: any) => {
    setCamposPEP((prevState: any) => ({
      ...prevState,
      ...novosCampos,
    }));

    const fieldWithKeyCamelCase = convertFieldKeyToCamelCase(novosCampos);
    if (fieldWithKeyCamelCase)
      setProntuario((prevState: any) => ({
        ...prevState,
        ...fieldWithKeyCamelCase,
      }));
  };

  const btnAtendimentoLabel =
    atendimento.status === 'CONTINUAR'
      ? 'Continuar atendimento'
      : 'Iniciar atendimento';

  useEffect(() => {
    if (isAtendendo) {
      Promise.all([getCampos(), getProntuario()]).finally(() => {
        setLoadingProntuario(false);
      });
    }
  }, [getCampos, getProntuario, isAtendendo]);

  const handleChangeDataOcorrencia = async (
    atendimentoId: number | string,
    dataOcorrencia: Date | Date[] | undefined,
  ) => {
    if (!!dataOcorrencia && !Array.isArray(dataOcorrencia) && !!atendimentoId) {
      const data = {
        dataOcorrencia,
        justificativaOcorrencia: form.getValues('justificativaOcorrencia'),
      };

      AtendimentoAPI.blurOcorrenciaRetroativa(data, Number(atendimentoId));
    }
  };

  if (loadingProntuario) {
    return <SpinnerLoading full className="p-py-4" />;
  }

  return (
    <ElevatedCard className="p-col-12 p-md-9 p-py-3" type="white" noMargin>
      {!isAtendendo ? (
        <Button
          btnType="pill"
          label={btnAtendimentoLabel}
          onClick={() =>
            iniciarAtendimento(idAtendimentoGrupo, handleChangeDataOcorrencia)
          }
          stretch
        />
      ) : (
        <div className="Prontuario-grupo p-d-flex p-flex-column">
          <SimpleText fontSize="md" className="p-col-12 p-py-0" medium>
            Novo atendimento em grupo
          </SimpleText>

          <DataOcorrencia
            control={form.control}
            defaultValue={new Date()}
            nameDataOcorrencia="dataOcorrencia"
            nameJustificativaRetroativa="justificativaOcorrencia"
            onBlurJustificativa={() => handleOcorrenciaRetroativa()}
          />

          <div className="aba">
            <SimpleText fontSize="sm" fontColor="color_16" medium>
              Subjetivo
            </SimpleText>
            <Anamnese
              prontuario={prontuario}
              campos={campos}
              getCampos={getCampos}
              updateCamposProntuario={updateCamposProntuario}
              selectedSubjetivos={['ANAMNESE']}
              canPin={false}
              doNotValidate
            />
          </div>

          <div className="aba">
            <SimpleText fontSize="sm" fontColor="color_16" medium>
              Avaliação
            </SimpleText>
            <PatologiaDetectada
              prontuario={prontuario}
              campos={campos}
              getCampos={getCampos}
              updateCamposProntuario={updateCamposProntuario}
              canPin={false}
              doNotValidate
            />
            <Cid
              campos={campos}
              updateCamposProntuario={updateCamposProntuario}
              canPin={false}
              doNotValidate
            />
          </div>

          <div className="aba">
            <SimpleText fontSize="sm" fontColor="color_16" medium>
              Motivo Atendimento
            </SimpleText>
            <MotivoAtendimentoGrupo
              editMotivo={prontuario?.motivoAtendimentos?.[0]}
              idAtendimentoGrupo={idAtendimentoGrupo}
            />
          </div>

          <div className="aba">
            <SimpleText fontSize="sm" fontColor="color_16" medium>
              Plano
            </SimpleText>
            <DecisaoTerapeutica
              prontuario={prontuario}
              campos={campos}
              getCampos={getCampos}
              updateCamposProntuario={updateCamposProntuario}
              canPin={false}
              doNotValidate
            />
          </div>
        </div>
      )}
    </ElevatedCard>
  );
};
