import { useCallback } from 'react';

import { useNavigate } from 'react-router';

import PendenciasAuditoriaAPI from 'src/APIs/AdminAPI/PendenciasAuditoriaAPI/PendenciasAuditoriaAPI';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import Filter from './Filter/Filter';
import List from './List/List';

import './PendenciasAuditoria.scss';

const mockPendenciasAuditoria = [
  {
    numeroGuia: 7001,
    nomeConvenio: 'Unimed curitiba',
    codigoPrestador: 3001,
    nomePrestador: 'Doutor Henrique',
    compartilharSecretaria: true,
    nomePaciente: 'Maria',
    numeroCartao: '123456',
    dataEnvio: '2024-01-15',
    statusTiss: 'Em análise',
    visualizada: false,
    procedimentos: [
      {
        codigoTuss: 2001,
        nomeTuss: 'Hemograma',
        statusProcedimento: 'Em análise',
        quantidadeSolicitada: 2,
        quantidadeLiberada: 3,
      },
      {
        codigoTuss: 2002,
        nomeTuss: 'Hemograma',
        statusProcedimento: 'Em análise',
        quantidadeSolicitada: 2,
        quantidadeLiberada: 3,
      },
    ],
  },
  {
    numeroGuia: 7002,
    nomeConvenio: 'Unimed curitiba',
    codigoPrestador: 3002,
    nomePrestador: 'Doutor Henrique',
    compartilharSecretaria: true,
    nomePaciente: 'Joao',
    numeroCartao: '87773',
    dataEnvio: '2024-02-20',
    statusTiss: 'Autorizado',
    visualizada: false,
    procedimentos: [
      {
        codigoTuss: 2002,
        nomeTuss: 'Hemograma',
        statusProcedimento: 'Em análise',
        quantidadeSolicitada: 2,
        quantidadeLiberada: 3,
      },
    ],
  },
  {
    numeroGuia: 7003,
    nomeConvenio: 'Unimed curitiba',
    codigoPrestador: 3003,
    nomePrestador: 'Doutor Henrique',
    compartilharSecretaria: true,
    nomePaciente: 'Betina',
    numeroCartao: '29985',
    dataEnvio: '2024-03-25',
    statusTiss: 'Negado',
    visualizada: false,
    procedimentos: [
      {
        codigoTuss: 2003,
        nomeTuss: 'Hemograma',
        statusProcedimento: 'Em análise',
        quantidadeSolicitada: 2,
        quantidadeLiberada: 3,
      },
    ],
  },
];

const PendenciasAuditoria = () => {
  const fetchAPI = async ({ param, query, ...rest }: any): Promise<any> => {
    if (!rest.convenio || !rest.profissionalSaude) return;
    try {
      const response = await PendenciasAuditoriaAPI.getPendenciasAuditoria({
        param,
        ...rest,
      });
      return response;
    } catch (error) {
      return [];
    }
  };

  return (
    <Page white id="pendencias-auditoria">
      <>
        <PageHeader
          title="Pendências de auditoria"
          subtitle="Lista de mensagens enviadas para a auditoria."
        />

        <Page white content>
          <SearchList.Root fetchApi={fetchAPI}>
            <Filter />
            <List />
          </SearchList.Root>
        </Page>
      </>
    </Page>
  );
};

export default PendenciasAuditoria;
