import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import AgendamentosAPI from 'src/APIs/AgendaAPI/Agendamentos/AgendamentosAPI';
import GrupoAtendimentoAPI from 'src/APIs/AgendaAPI/GrupoAtendimento/GrupoAtendimentoAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { DetalhesTeleconsulta } from './components/DetalhesTeleconsulta';
import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import Dialog from 'src/components/Dialog/Dialog';
import Form from 'src/components/Form/Form';
import Separator from 'src/components/Separator/Separator';
import Tab from 'src/components/Tab/Tab';

import { useAgenda } from '../../AgendaContext';
import ModalSigilo from '../../Sigilo/ModalSigilo/ModalSigilo';

import ModalHistoricoAgendamento from './ModalHistoricoAgendamento/ModalHistoricoAgendamento';
import './DetalhesAgendamento.scss';

interface DetalhesAgendamentoProps extends DisclosureType {
  status: () => JSX.Element;
  agendamento: AtendimentoAgendamento;
}

const DetalhesAgendamento = ({
  agendamento,
  status,
  close,
}: DetalhesAgendamentoProps) => {
  const { dialogRemarcarAgendamento } = useAgenda();

  const { agenda } = useAppSelector((state: RootState) => state);
  const { refetchAgenda, reloadList, agendaView, registros, agendaDiaItems } =
    useAgenda();
  const navigate = useNavigate();

  const hasProximoFlag = useCallback(() => {
    let agendamentosToCheck = [];

    if (agendaView === 'dia') {
      agendamentosToCheck = agendaDiaItems;
    } else {
      const registro = registros.find(
        registro =>
          dayjs(registro.data, 'YYYYMMDD').format('YYYY-MM-DD') ===
          dayjs(agendamento.dataAgendamento).format('YYYY-MM-DD'),
      );
      if (registro) {
        agendamentosToCheck = registro.atendimentos;
      }
    }
    return agendamentosToCheck.some((atendimento: any) =>
      atendimento.flagsAtivas?.includes('PROXIMO'),
    );
  }, [agendaDiaItems, agendaView, agendamento.dataAgendamento, registros]);

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alerta, setAlerta] = useState(false);

  const [loading, setLoading] = useState(false);

  const [observacao, setObservacao] = useState<string>(
    agendamento.observacao || '',
  );
  const [infoProfissional, setInfoProfissional] = useState<string>(
    agendamento.informacoesProfissional || '',
  );
  const [infoPaciente, setInfoPaciente] = useState<string>(
    agendamento.informacoesPaciente || '',
  );

  const [expandHistorico, setExpandHistorico] = useState(false);

  const caracteristicasRef = useRef<any>();
  const flagsRef = useRef<any>();

  const menuOptions = useRef<any>();
  const [grupo, setGrupo] = useState<GrupoAtendimentoGerenciar>();

  const [paciente, setPaciente] = useState({
    id: null,
    sigiloExists: false,
    sigilo: null,
    idAtendimento: null,
  });

  const modalHistoricoAgendamento = useDisclosure({ opened: false });

  const modalSigilo = useDisclosure({ opened: false });

  const getGrupo = useCallback(async () => {
    const response = await GrupoAtendimentoAPI.loadGrupoAtendimentoDetalhe(
      agendamento.id,
    );
    setGrupo(response);
  }, [agendamento.id]);

  useEffect(() => {
    agendamento.idGrupoAtendimento && getGrupo();
  }, [agenda.profissionalAtivo.id, agendamento, getGrupo]);

  const onSigiloSuccess = useCallback(() => {
    setPaciente({
      id: null,
      sigiloExists: false,
      sigilo: null,
      idAtendimento: null,
    });

    modalSigilo.close();
  }, [modalSigilo]);

  const onCancel = () => {
    setPaciente({
      id: null,
      sigiloExists: false,
      sigilo: null,
      idAtendimento: null,
    });

    modalSigilo.close();
  };

  const isTeleconsulta =
    agendamento?.flagsAtivas?.includes('TELECONSULTA') || false;

  const handleSigilo = useCallback(
    atendimento => {
      const newPaciente = {
        ...paciente,
        paciente: atendimento.paciente,
        id: atendimento.idPaciente,
        sigilo: atendimento.sigilo,
        sigiloExists: atendimento.nivelSigilo !== 0 ? true : false,
      };
      setPaciente({ ...newPaciente });
      modalSigilo.open();
    },
    [modalSigilo, paciente],
  );

  const handleConfirmar = async () => {
    const payload: any = {
      id: agendamento.id,
      status: agendamento.status,
      observacao: observacao,
      informacoesProfissional: infoProfissional,
      informacoesPaciente: infoPaciente,
      flagsAtendimentos: [...agendamento.flagsAtivas, 'CONFIRMADO'],
    };

    const response = await AgendamentosAPI.editarAgendamento(payload);

    if (response.status === 200) {
      refetchAgenda();
      reloadList();
    }
  };

  const handleAlterarStatus = useCallback(
    async (status = 'CANCELADO') => {
      let response;
      try {
        setLoading(true);

        response = await AgendamentosAPI.alterarStatusAgenda({
          ...agendamento,
          status,
        });

        if (response?.status === 200) {
          refetchAgenda();
          reloadList();
          close();
        }
      } catch {
      } finally {
        setLoading(false);
      }
    },
    [agendamento, close, refetchAgenda, reloadList],
  );

  const handleAtualizaGuiaOperadora = useCallback(async () => {
    try {
      setLoading(true);

      await AgendamentosAPI.updateAgendamentosStatusGuia(
        { idAgendamento: agendamento?.id },
        { idConvenio: agendamento?.idConvenio, status: 'PRESENTE' },
        { throwError: true },
      );
      refetchAgenda();
      reloadList();
    } catch {
    } finally {
      setLoading(false);
    }
  }, [agendamento?.id, agendamento?.idConvenio, refetchAgenda, reloadList]);

  const itemsNegadoOperadora = [
    {
      label: 'Atualizar guia',
      command: handleAtualizaGuiaOperadora,
      loading,
    },
    {
      separator: true,
    },
    {
      label: 'Remarcar paciente',
      icon: 'fas fa-calendar',
      command: () => dialogRemarcarAgendamento.open({ state: agendamento }),
      disabled: loading,
    },
    {
      separator: true,
    },
    {
      label: 'Cancelar agendamento',
      icon: 'fas fa-trash',
      command: () => handleAlterarStatus(),
      disabled: loading,
    },
  ];

  const itemsReserva = [
    {
      label: 'Desfazer cancelamento',
      disabled:
        agendamento.status !== 'CANCELADO' && agendamento.status !== 'FALTOU',
      command: () => handleAlterarStatus('AGENDADO'),
    },
    {
      separator: true,
    },
    {
      label: 'Cancelar agendamento',
      icon: 'fas fa-trash',
      disabled: agendamento.status !== 'AGENDADO',
      command: () => handleAlterarStatus('CANCELADO'),
    },
  ];

  const items = [
    {
      label: 'Liberar sigilo',
      disabled:
        agendamento.status !== 'AGENDADO' || agendamento.idGrupoAtendimento,
      command: () => handleSigilo(agendamento),
    },

    {
      label: 'Confirmar agendamento',
      disabled: agendamento.status !== 'AGENDADO',
      command: handleConfirmar,
    },

    {
      label: 'Desfazer falta/cancelamento',
      disabled:
        agendamento.status !== 'CANCELADO' && agendamento.status !== 'FALTOU',
      command: () => handleAlterarStatus('AGENDADO'),
    },
    {
      separator: true,
    },
    {
      label: 'Cancelar agendamento',
      icon: 'fas fa-trash',
      disabled: agendamento.status !== 'AGENDADO',
      command: () => handleAlterarStatus('CANCELADO'),
    },
  ];

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const chips = flagsRef?.current?.getValues();
      const chipsSelect = caracteristicasRef?.current?.getValues();

      if (!chips.tags) {
        chips.tags = [];
      }

      if (!chipsSelect.tags) {
        chipsSelect.tags = [];
      }

      const todosChips = [...chips.tags, ...chipsSelect.tags];

      const payload: any = {
        id: agendamento.id,
        status: agendamento.status,
        observacao: observacao,
        informacoesProfissional: infoProfissional,
        informacoesPaciente: infoPaciente,
        flagsAtendimentos: todosChips,
      };

      await AgendamentosAPI.editarAgendamento(payload, {
        throwError: true,
      });

      refetchAgenda();
      reloadList();
      close();

      setAlerta(false);
      setIsEditing(false);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditar = useCallback(() => {
    setAlerta(true);
    setIsEditing(true);
  }, []);

  const renderChips = useCallback(() => {
    const initialValue: any = [];

    agendamento?.flagsAtivas?.forEach(flag => {
      if (flag === 'PROXIMO' || flag === 'ATRASADO' || flag === 'REMARCAR') {
        initialValue.push(flag);
      }
    });

    const options = [
      {
        label: 'Proximo',
        value: 'PROXIMO',
        hide:
          (agendamento.status !== 'PRESENTE' &&
            agendamento.status !== 'TRIAGEM_FINALIZADA' &&
            agendamento.status !== 'PRESCRICAO_OK') ||
          hasProximoFlag(),
      },
      {
        label: 'Atrasado',
        value: 'ATRASADO',
        className: 'red',
        hide:
          agendamento.status !== 'AGENDADO' ||
          !!agendamento?.agendamentoRapidoNome,
      },

      {
        label: 'Remarcar',
        value: 'REMARCAR',
        className: 'yellow',
        hide:
          agendamento.status !== 'FALTOU' && agendamento.status !== 'CANCELADO',
      },
      {
        label: 'Reserva de horário',
        value: 'RESERVA_HORARIO',
        className: 'p-ml-5 outlined',
        hide: true,
      },
    ];

    return (
      <div className="chips-container">
        <Form
          ref={flagsRef}
          initialValues={{ tags: initialValue }}
          fields={[
            [
              {
                name: 'tags',
                type: 'ChipSelect',
                options: options,
                customOnChange: handleEditar,
              },
            ],
          ]}
        />
      </div>
    );
  }, [
    agendamento?.flagsAtivas,
    agendamento.status,
    handleEditar,
    agendamento?.agendamentoRapidoNome,
    hasProximoFlag,
  ]);

  const renderChipsSelect = useCallback(() => {
    const initialValue: any = [];

    agendamento?.flagsAtivas?.forEach(flag => {
      if (flag !== 'PROXIMO' && flag !== 'ATRASADO' && flag !== 'REMARCAR') {
        initialValue.push(flag);
      }
    });

    const options = [
      {
        label: 'Internet',
        value: 'INTERNET',
        hide: true,
      },
      {
        label: 'Retorno',
        value: 'RETORNO',
        hide:
          agendamento.idGrupoAtendimento ||
          (agendamento.status !== 'AGENDADO' &&
            agendamento.status !== 'NEGADO_OPERADORA'),
      },
      {
        label: 'Teleconsulta',
        value: 'TELECONSULTA',
        hide:
          agendamento.idGrupoAtendimento ||
          (agendamento.status !== 'AGENDADO' &&
            agendamento.status !== 'NEGADO_OPERADORA'),
      },
      {
        label: 'Procedimento',
        value: 'PROCEDIMENTO',
        hide:
          agendamento.idGrupoAtendimento ||
          (agendamento.status !== 'AGENDADO' &&
            agendamento.status !== 'NEGADO_OPERADORA'),
      },
      {
        label: 'Puericultura',
        value: 'PUERICULTURA',
        hide:
          agendamento.idGrupoAtendimento ||
          (agendamento.status !== 'AGENDADO' &&
            agendamento.status !== 'NEGADO_OPERADORA'),
      },
      {
        label: 'Atend. Particular',
        value: 'PARTICULAR',
        hide:
          agendamento.idGrupoAtendimento ||
          (agendamento.status !== 'AGENDADO' &&
            agendamento.status !== 'NEGADO_OPERADORA') ||
          agendamento?.paciente?.tipoPaciente === 'PARTICULAR',
      },
      {
        label: 'Recém-nascido',
        value: 'RECEM_NASCIDO',
        hide:
          agendamento.idGrupoAtendimento ||
          (agendamento.status !== 'AGENDADO' &&
            agendamento.status !== 'NEGADO_OPERADORA'),
      },
      {
        label: 'Central de agendamento',
        value: 'CENTRAL_AGENDAMENTO',
        hide: true,
      },
      {
        label: 'Encaixe',
        value: 'ENCAIXE',
        hide: true,
      },
    ];

    return (
      <div className="chips-select">
        <Form
          ref={caracteristicasRef}
          initialValues={{ tags: initialValue }}
          fields={[
            [
              {
                name: 'tags',
                type: 'ChipSelect',
                options: options,
                customOnChange: handleEditar,
              },
            ],
          ]}
        />
      </div>
    );
  }, [
    agendamento?.flagsAtivas,
    agendamento.idGrupoAtendimento,
    agendamento?.paciente?.tipoPaciente,
    agendamento.status,
    handleEditar,
  ]);

  const renderPacienteInfos = useCallback(
    (paciente: GrupoAtendimentoPacienteDTO) => {
      const handleText = (text?: string) => {
        return text ? text : 'Não informado';
      };

      const phone =
        paciente.celularComercial ||
        paciente.celularParticular ||
        paciente.fixoComercial ||
        paciente.fixoResidencial;

      return (
        <div className="paciente-infos">
          <div className="card-infos p-my-2">
            <div className="p-d-flex p-ai-center p-jc-between">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XS}
                medium
              >
                Nome do paciente
              </SimpleText>

              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
              >
                {handleText(paciente.nome)}
              </SimpleText>
            </div>

            <div className="p-d-flex p-ai-center p-jc-between p-my-3">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XS}
                medium
              >
                Telefone
              </SimpleText>

              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
              >
                {handleText(phone)}
              </SimpleText>
            </div>

            <div className="p-d-flex p-ai-center p-jc-between">
              <SimpleText
                fontColor={FONT_COLOR.COLOR_60}
                fontSize={FONT_SIZE.XS}
                medium
              >
                E-mail
              </SimpleText>

              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
              >
                {handleText(paciente.email)}
              </SimpleText>
            </div>
          </div>
        </div>
      );
    },
    [],
  );

  const estilizarStringUsuarioAtualizacao = (string: string) => {
    if (!string) return;
    const [inicio, , nome, , data] = string.split(/(por|em)/);
    return (
      <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XS}>
        {inicio}
        por
        <strong>{nome}</strong>
        em
        {data}
      </SimpleText>
    );
  };

  const toggleExpandHistorico = () => {
    setExpandHistorico(!expandHistorico);
  };

  const formatTelephone = useMemo(() => {
    const telefone =
      agendamento?.paciente?.telefonePrincipal ||
      agendamento?.agendamentoRapidoTelefone ||
      'Celular não informado';

    if (telefone.length !== 11) return telefone;

    return telefone.substring(0, 2) + ' ' + telefone.substring(2, 10);
  }, [
    agendamento?.agendamentoRapidoTelefone,
    agendamento?.paciente?.telefonePrincipal,
  ]);

  const getElipsisOptions = () => {
    if (agendamento.status === 'NEGADO_OPERADORA') return itemsNegadoOperadora;
    if (!!agendamento?.agendamentoRapidoNome) return itemsReserva;

    return items;
  };

  return (
    <>
      <Dialog
        className="footer-on"
        type="modal"
        header="Detalhes agendamento"
        visible
        onHide={close}
        modal={false}
        footer={() => <div />}
        maximizedTabletSize
      >
        <div className="p-d-flex p-flex-column DetalhesAgendamento">
          {renderChips()}

          <div className="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-sm-row p-mb-3 p-mt-4">
            <div className="p-d-flex p-gap-2 p-ai-center p-mb-3 p-mb-sm-0">
              <Avatar icon="fa fa-calendar" />

              <div className="p-d-flex p-flex-column">
                <SimpleText
                  className="p-pb-2"
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XS}
                  medium
                >
                  {agendamento.dataAgendamento
                    ? `${dayjs(new Date(agendamento.dataAgendamento)).format(
                        'DD/MM/YYYY [às] HH:mm',
                      )}`
                    : '-'}
                </SimpleText>

                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XXS}
                >
                  {agendamento?.medico?.nome}
                </SimpleText>
              </div>
            </div>

            <div>{status()}</div>
          </div>

          {!agendamento?.agendamentoRapidoNome && (
            <>
              <SimpleText
                className="p-ml-0 p-mt-2"
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                bold
              >
                Características do agendamento
              </SimpleText>

              {renderChipsSelect()}
            </>
          )}

          {/* {!grupo?.pacienteDtos && <GridListLoading />} */}

          {/* Grupo */}
          {agendamento.idGrupoAtendimento && (
            <>
              <div className="p-d-flex p-ai-center p-jc-between p-mt-3">
                <SimpleText
                  className="p-pb-2"
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XS}
                  medium
                >
                  Dados do paciente do grupo
                </SimpleText>
              </div>

              {grupo?.pacienteDtos.map(paciente => (
                <div key={paciente.id}>{renderPacienteInfos(paciente)}</div>
              ))}
            </>
          )}

          {!agendamento.idGrupoAtendimento ? (
            <div>
              <Separator />

              <div className="p-d-flex p-flex-column p-flex-sm-row p-ai-center p-jc-between p-my-2">
                <div className="p-d-flex p-ai-center">
                  <Avatar
                    label={
                      agendamento?.paciente?.nome ??
                      agendamento?.agendamentoRapidoNome
                    }
                    image={agendamento?.paciente?.fotoUrl || ''}
                  />

                  <div className="p-d-flex p-flex-column p-ml-2">
                    <SimpleText
                      className="p-pb-2"
                      fontColor={FONT_COLOR.COLOR_40}
                      fontSize={FONT_SIZE.XS}
                      medium
                    >
                      {agendamento?.paciente?.nome ??
                        agendamento?.agendamentoRapidoNome}
                    </SimpleText>

                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_40}
                      fontSize={FONT_SIZE.XXS}
                    >
                      {''} • {formatTelephone}
                    </SimpleText>
                  </div>
                </div>

                {!agendamento.agendamentoRapidoNome && (
                  <div className="p-d-flex p-ai-center p-mt-3 p-mt-sm-0">
                    <SimpleText
                      className="p-mr-3 cursor-pointer"
                      fontColor={FONT_COLOR.PRIMARY}
                      fontSize={FONT_SIZE.XS}
                      bold={true}
                      onClick={() => {
                        modalHistoricoAgendamento.open({ state: agendamento });
                      }}
                    >
                      Ver histórico
                    </SimpleText>
                  </div>
                )}
              </div>

              <Separator />

              <Tab
                className="p-pl-0"
                initialTabIndex={!!agendamento.agendamentoRapidoNome ? 0 : 1}
                clean
                values={[
                  {
                    label: 'Teleconsulta',
                    content: <DetalhesTeleconsulta agendamento={agendamento} />,
                    hide: !isTeleconsulta,
                  },
                  {
                    label: 'Observações',
                    content: (
                      <TextareaInput
                        value={observacao}
                        maxLength={4000}
                        maxLengthSpan
                        onChange={e => {
                          setObservacao(e.target.value);
                          handleEditar();
                        }}
                      />
                    ),
                  },
                  {
                    label: 'Informações paciente',
                    content: (
                      <TextareaInput
                        value={infoPaciente}
                        maxLength={300}
                        maxLengthSpan
                        onChange={e => {
                          setInfoPaciente(e.target.value);
                          handleEditar();
                        }}
                      />
                    ),
                    hide: !!agendamento.agendamentoRapidoNome,
                  },
                  {
                    label: 'Informações profissional',
                    hide:
                      agendamento.medico.nome !==
                        agenda.profissionalAtivo.nome ||
                      !!agendamento.agendamentoRapidoNome,
                    content: (
                      <TextareaInput
                        value={infoProfissional}
                        maxLength={300}
                        maxLengthSpan
                        onChange={e => {
                          setInfoProfissional(e.target.value);
                          handleEditar();
                        }}
                      />
                    ),
                  },
                ]}
              />
            </div>
          ) : (
            <Tab
              className="p-pl-0"
              initialTabIndex={1}
              clean
              values={[
                {
                  label: 'Observações',
                  content: (
                    <TextareaInput
                      value={observacao}
                      maxLength={4000}
                      maxLengthSpan
                      onChange={e => {
                        setObservacao(e.target.value);
                        handleEditar();
                      }}
                    />
                  ),
                },
                {
                  label: 'Informações paciente',
                  content: (
                    <TextareaInput
                      value={infoPaciente}
                      maxLength={300}
                      maxLengthSpan
                      onChange={e => {
                        setInfoPaciente(e.target.value);
                        handleEditar();
                      }}
                    />
                  ),
                },
                {
                  label: 'Informações profissional',
                  hide:
                    agendamento.medico.nome !== agenda.profissionalAtivo.nome,
                  content: (
                    <TextareaInput
                      value={infoProfissional}
                      maxLength={300}
                      maxLengthSpan
                      onChange={e => {
                        setInfoProfissional(e.target.value);
                        handleEditar();
                      }}
                    />
                  ),
                },
              ]}
            />
          )}

          <AlertBox
            onHide={() => setAlerta(false)}
            visible={alerta}
            text="As altereções ainda não foram salvas."
          />

          {isEditing && (
            <div className="p-d-flex p-ai-center p-jc-between p-mt-3">
              <Button
                className="p-mt-2"
                onClick={() => close()}
                btnType="ghost"
                label="Cancelar"
                disabled={isLoading}
                stretch
              />

              <Button
                label="Salvar alterações"
                className="btn-salvar"
                onClick={handleSubmit}
                loading={isLoading}
                stretch
              />
            </div>
          )}

          {!isEditing && (
            <div className="p-d-flex p-ai-center p-jc-between p-mt-3">
              <div className="p-d-flex p-ai-center">
                <Button
                  btnType="tonal"
                  label="Editar agendamento"
                  onClick={() =>
                    navigate('/agenda/novo-agendamento', {
                      state: { editAgendamento: agendamento },
                    })
                  }
                  disabled={agendamento.status !== 'AGENDADO'}
                />

                <TieredMenu
                  model={getElipsisOptions()}
                  popup
                  ref={menuOptions}
                />

                <Button
                  id="options"
                  className="p-ml-3"
                  onClick={event => menuOptions.current.toggle(event)}
                  icon={'pi pi-ellipsis-h'}
                  btnType="green-link"
                />
              </div>
            </div>
          )}

          <div className="p-d-flex p-ai-end p-mt-2">
            {expandHistorico ? (
              <div className="p-d-flex p-flex-column">
                {agendamento?.historicoAtendimento?.historicos?.map(
                  (historico: string, index: any) => (
                    <div key={index}>
                      {estilizarStringUsuarioAtualizacao(historico)}
                    </div>
                  ),
                )}
              </div>
            ) : (
              <div className="p-d-flex p-flex-column p-mr-3">
                {estilizarStringUsuarioAtualizacao(
                  agendamento?.historicoAtendimento?.ultimoHistorico,
                )}
              </div>
            )}
            {agendamento?.historicoAtendimento?.historicos?.length > 1 && (
              <SimpleText
                onClick={toggleExpandHistorico}
                className="p-ml-2 p-p-0 cursor-pointer"
                medium
                fontColor={FONT_COLOR.PRIMARY}
              >
                <strong>{expandHistorico ? 'Menos' : 'Ver mais'}</strong>
              </SimpleText>
            )}
          </div>

          {modalSigilo.isOpen && (
            <ModalSigilo
              paciente={paciente}
              setPaciente={setPaciente}
              visible={true}
              onSuccess={onSigiloSuccess}
              onCancel={onCancel}
            />
          )}

          {modalHistoricoAgendamento.isOpen && (
            <ModalHistoricoAgendamento {...modalHistoricoAgendamento} />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default DetalhesAgendamento;
