import React from 'react';

import dayjs from 'dayjs';

import useSize from 'src/core/hooks/useSize';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { getColorStatus, getStatusString } from './utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';

import ModalChat from './ModalChat/ModalChat';

interface RowProps {
  data: IPendenciaAuditoriaDTO;
}

const Row = ({ data }: RowProps) => {
  const { windowInnerWidth } = useSize();
  const chatDisclosure = useDisclosure({ opened: false });

  const {
    dataEnvio,
    nomePaciente,
    numeroCartao,
    numeroGuia,
    statusTiss,
    visualizada,
  } = data;
  return (
    <div className="p-grid p-ai-center p-p-1 p-my-1 row">
      <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Paciente
        </SimpleText>
        <SimpleText fontColor="color_16" fontSize="xs">
          {nomePaciente || ''}
        </SimpleText>
      </div>
      <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Número da carteirinha
        </SimpleText>
        <SimpleText fontColor="color_16" fontSize="xs">
          {numeroCartao || ''}
        </SimpleText>
      </div>
      <div className="p-col-6 p-sm-4 p-md-2 p-lg-2 p-d-flex p-flex-column p-gap-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Número da guia
        </SimpleText>
        <SimpleText fontColor="color_16" fontSize="xs">
          {numeroGuia || ''}
        </SimpleText>
      </div>
      <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-d-flex p-flex-column p-gap-1 p-order-md-1">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Data de envio
        </SimpleText>
        <SimpleText fontColor="color_16" fontSize="xs">
          {dataEnvio
            ? dayjs(new Date(dataEnvio)).format('DD/MM/YYYY HH:mm')
            : ''}
        </SimpleText>
      </div>
      <div className="p-col-6 p-sm-4 p-md-3 p-lg-2 p-d-flex p-flex-column p-gap-1 p-order-md-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Status
        </SimpleText>
        <SimpleText
          fontSize="xs"
          medium
          className={`p-py-1 p-px-3 ${getColorStatus(statusTiss)}`}
        >
          {getStatusString(statusTiss) || ''}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-1 p-order-md-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Visualizada
        </SimpleText>
        <SimpleText fontColor="color_16" fontSize="xs">
          {visualizada ? 'Sim' : 'Não'}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-jc-end p-jc-md-start  p-ai-center p-gap-1 p-order-md-1 p-order-lg-5">
        {windowInnerWidth > 1010 && <Separator layout="vertical" />}
        <Button
          icon="fas fa-comment"
          btnType="gray"
          onClick={() => chatDisclosure.open()}
        />
      </div>
      {chatDisclosure.isOpen && <ModalChat {...chatDisclosure} data={data} />}
    </div>
  );
};

export default Row;
