import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownProps } from 'primereact/dropdown';
import { useFormContext, useController } from 'react-hook-form';

import ProfissionalAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ProfissionalAPI';
import { GetProfissionalDTO } from 'src/models/APIs/AdminAPI/Consultorio/ProfissionalDTOs';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

type FieldConsultoriosProps = DropdownProps & {
  name?: string;
  label: string;
};

export const FieldProfissionalSaudeByCliente = ({
  name = 'profissionalSaude',
  label,
  ...rest
}: FieldConsultoriosProps) => {
  const { control } = useFormContext();

  const [profissionalsaude, setProfissionalSaude] = useState<
    GetProfissionalDTO[]
  >([]);
  const [query, setQuery] = useState('');

  const handleQuery = useThrottle(setQuery, 500);

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const loadPage = useCallback(async () => {
    try {
      const res = await ProfissionalAPI.loadProfissionaisSaudeBySearch(
        {
          nome: query,
        },
        { throwError: true },
      );

      setProfissionalSaude(res.list);
    } catch {}
  }, [query]);

  useEffect(() => {
    loadPage();
  }, [loadPage]);

  const options = useMemo(() => {
    const data = profissionalsaude.map(prof => ({
      label: prof?.nome,
      value: prof,
    }));

    return data;
  }, [profissionalsaude]);

  return (
    <DropdownControlled
      control={control}
      className="p-col-12"
      panelClassName="panel-convenio"
      name={name}
      dataKey="id"
      label={label}
      onFilter={e => handleQuery(e.filter)}
      options={options}
      errorMsg={error?.message}
      {...rest}
    />
  );
};
