import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import { FieldSearchProfissionalSaude } from 'src/components/Fields/FieldSearchProfissionalSaude/FieldSearchProfissionalSaude';
import FormInput from 'src/components/FormInput/FormInput';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

import { validationSchema } from './validationSchema';

const statusOptions = [
  { label: 'Autorizado', value: 'Autorizado' },
  { label: 'Autorizado parcialmente', value: 'Autorizado parcialmente' },
  { label: 'Em análise', value: 'Em análise' },
  { label: 'Negado', value: 'Negado' },
  { label: 'Solicitação cancelada', value: 'Solicitação cancelada' },
];

const Filter = () => {
  const { setFilter } = useSearchList();

  const useFormMethods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormMethods;

  const onSubmit = (data: any) => {
    const {
      convenio,
      numeroCartao,
      numeroGuia,
      profissionalSaude,
      periodoDe,
      periodoAte,
      status,
    } = data;

    const payload = {
      ...(convenio && { convenio: convenio.id }),
      ...(numeroCartao && { numeroCartao }),
      ...(numeroGuia && { numeroGuia }),
      ...(profissionalSaude && { profissionalSaude: profissionalSaude.id }),
      ...(periodoDe && { periodoDe: dayjs(periodoDe).format('YYYY-MM-DD') }),
      ...(periodoAte && { periodoAte: dayjs(periodoAte).format('YYYY-MM-DD') }),
      ...(status && { status }),
    };

    setFilter(payload);
  };

  return (
    <FormProvider {...useFormMethods}>
      <form className="p-grid" onSubmit={handleSubmit(onSubmit)}>
        <FieldConvenio
          className="p-col-12 p-sm-6 p-md-4"
          name="convenio"
          label="Convênio"
          errorMsg={errors.convenio?.message}
        />

        <FormInput
          name="numeroCartao"
          label="Número da carteirinha"
          className="p-col-12 p-sm-6 p-md-4"
          permitirApenasNumeros
        />

        <FormInput
          name="numeroGuia"
          label="Número da guia"
          className="p-col-12 p-sm-6 p-md-4"
          placeholder="0000000000"
          permitirApenasNumeros
        />

        <FieldSearchProfissionalSaude
          name="profissionalSaude"
          label="Profissional de saúde"
          className="p-col-12 p-sm-6 p-md-4"
        />

        <CalendarInputControlled
          control={control}
          name="periodoDe"
          label="Período de"
          className="p-col-12 p-sm-6 p-md-4 p-lg-2"
          dateFormat="dd/mm/yyyy"
          placeholder="dd/mm/aaaa"
          showIcon
          hideOnDateTimeSelect
          errorMsg={errors.periodoDe?.message}
        />

        <CalendarInputControlled
          control={control}
          name="periodoAte"
          label="Até"
          className="p-col-12 p-sm-6 p-md-4 p-lg-2"
          dateFormat="dd/mm/yyyy"
          placeholder="dd/mm/aaaa"
          showIcon
          hideOnDateTimeSelect
          errorMsg={errors.periodoAte?.message}
        />

        <div className="p-grid p-col-12 p-sm-6  p-lg-4 p-p-0 p-ai-end">
          <DropdownControlled
            className="p-col-9"
            control={control}
            name="status"
            label="Status"
            options={statusOptions}
            placeholder="Selecione"
            showClear
          />
          <div className="p-col-3 p-mb-1">
            <Button label="Pesquisar" type="submit" btnType="tonal" stretch />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default Filter;
