import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { PlanoCuidadoPacienteAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoPacienteAPI/PlanoCuidadoPacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { useThrottle } from 'src/utils/hooks/useThrottle';

import { formatCPF } from 'src/utils/utils';

import Divider from 'src/components/Basics/Divider/Divider';
import IconButton from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import Toast from 'src/components/Basics/Toast/Toast';
import SelectButton from 'src/components/SelectButton/SelectButton';
import Skeleton from 'src/components/Skeleton/Skeleton';

import { usePlanosPacientes } from '../../hooks/PlanosPacientesContext';

export const PacientesSearch = () => {
  const { ativo } = useAppSelector(state => state.consultorios);
  const navigate = useNavigate();

  const [status, setStatus] = useState('TODOS');
  const [cuidado, setCuidado] = useState('TODOS');
  const [nome, setNome] = useState('');

  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [loadingBtnProntuarios, setLoadingBtnProntuarios] = useState(false);

  const { selectedPaciente, selectedPlano, setSelectedPaciente } =
    usePlanosPacientes();

  const fetchApi = useCallback(async () => {
    try {
      setLoading(true);

      const data =
        await PlanoCuidadoPacienteAPI.loadPlanoCuidadoPacientePacientes({
          idConsultorio: ativo.id,
          idPlanoCuidado: selectedPlano || undefined,
          emAtraso: status === 'EM_ATRASO',
          emDia: status === 'EM_DIA',
          tipoCuidado: cuidado,
          nome,
        });

      if (!data.length) return setLoading(false);

      return [setItems(data), setLoading(false)];
    } catch {
      setLoading(false);
    }
  }, [ativo.id, cuidado, nome, selectedPlano, status]);

  useEffect(() => {
    fetchApi();
  }, [fetchApi, selectedPlano]);

  const handleInput = useThrottle(setNome, 150);

  const handleProntuario = (item: any) => {
    if (!selectedPlano) {
      return toast(<Toast />, {
        data: {
          title: 'Erro ao gerar complemento',
          message:
            'Um plano deve ser selecionado para realizar o complemento de prontuário.',
          type: 'error',
        },
      });
    }

    setLoadingBtnProntuarios(true);

    return PlanoCuidadoPacienteAPI.loadProntuarioComplemento({
      idPaciente: item.idPaciente,
      idPlanoCuidado: selectedPlano,
    })
      .then((d: any) =>
        navigate(`/prontuario`, {
          state: {
            idAtendimento: d.id,
            from: 'PLANO_CUIDADO_REDIRECT',
          },
        }),
      )
      .catch(() => {})
      .finally(() => setLoadingBtnProntuarios(false));
  };

  return (
    <div
      id="plano-search"
      className="p-col-12 p-md-6 p-pl-0 p-d-flex p-flex-column"
    >
      <SimpleText className="p-col-12" medium fontSize={FONT_SIZE.SM}>
        Pacientes atrelados ao plano de cuidado
      </SimpleText>
      <SimpleText className="p-col-12" fontColor={FONT_COLOR.COLOR_60}>
        Liste os pacientes que fazem parte de um plano de cuidado
      </SimpleText>

      <div className="p-col-12 p-mb-2">
        <TextInputSearch
          onChange={handleInput}
          placeholder="Pesquise pelo nome do paciente"
        />
      </div>

      <div className="p-w-100 p-py-2 p-d-flex p-flex-column p-ai-center p-gap-2">
        <div className="p-d-flex p-w-100 p-jc-center p-flex-wrap">
          <SelectButton
            onChange={e => setStatus(e.value)}
            unselectable
            value={status}
            style={{
              minWidth: 'fit-content',
            }}
            options={[
              {
                label: 'Em atraso',
                value: 'EM_ATRASO',
              },
              {
                label: 'Em dia',
                value: 'EM_DIA',
              },
              {
                label: 'Todos',
                value: 'TODOS',
              },
            ]}
          />
          <SelectButton
            onChange={e => setCuidado(e.value)}
            unselectable
            value={cuidado}
            style={{
              minWidth: 'fit-content',
            }}
            options={[
              {
                label: 'Preventivo',
                value: 'PREVENTIVO',
              },
              {
                label: 'Normal',
                value: 'NORMAL',
              },
              {
                label: 'Todos',
                value: 'TODOS',
              },
            ]}
          />
        </div>
        <SimpleText
          fontColor={FONT_COLOR.COLOR_60}
          className="p-text-end p-wordbreak-normal"
        >
          Exibindo {items?.length || 0} registros de {items?.length || 0}
        </SimpleText>
      </div>

      <div className="plano-border-container p-px-2">
        <div className="p-d-flex p-col-12">
          <SimpleText
            className="p-col-5"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Nome
          </SimpleText>
          <SimpleText
            className="p-col-5"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Numero da carteirinha
          </SimpleText>
          <SimpleText
            className="p-col-2 p-text-right"
            fontColor={FONT_COLOR.COLOR_60}
            fontSize={FONT_SIZE.XXS}
          >
            Ação
          </SimpleText>
        </div>

        <div className="content-items p-d-flex p-flex-column p-gap-2 p-pb-2">
          {isLoading ? (
            <LoadingComponent />
          ) : !!items.length ? (
            items.map(item => (
              <div
                onClick={() => setSelectedPaciente(item.idPaciente)}
                key={item.idPaciente}
                className={`item p-d-flex p-col-12 ${
                  selectedPaciente === item.idPaciente ? 'selected' : ''
                }`}
              >
                <div className="p-col-5 p-d-flex p-flex-column p-gap-2">
                  <SimpleText>{item.nome}</SimpleText>
                  <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                    {formatCPF(item?.cpf || '')}
                  </SimpleText>
                </div>
                <div className="p-col-5 p-d-flex p-flex-column p-gap-2">
                  <SimpleText>0 00 000000000000 0</SimpleText>
                  {/* <SimpleText fontColor={FONT_COLOR.COLOR_60}>
                    Unimed
                  </SimpleText> */}
                </div>
                <div className="p-col-2 p-d-flex p-ai-center p-jc-end">
                  <Divider layout="vertical" style={{ height: '20px' }} />
                  <IconButton
                    icon="fas fa-eye"
                    type="button"
                    onClick={() => handleProntuario(item)}
                    loading={loadingBtnProntuarios}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="p-d-flex p-flex-column p-ai-center p-jc-center p-py-2">
              <SimpleText>
                Nenhum paciente para o plano foi encontrado.
              </SimpleText>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const LoadingComponent = () => {
  const props = {
    loading: true,
    height: '40px',
    borderRadius: '8px',
  };

  return (
    <div className="p-d-flex p-flex-column p-gap-2">
      <Skeleton {...props} />
      <Skeleton {...props} />
      <Skeleton {...props} />
    </div>
  );
};
