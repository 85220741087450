import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface MobileRowProps {
  usuario: any;
  vinculosNames: string[];
  userStatus: string;
  onBlock: (usuario: any) => void;
  onEdit: (usuario: any) => void;
  onDelete: (usuario: any) => void;
  onChangeStatus: (usuario: any) => void;
  onVisualizar: (usuario: any) => void;
}

export const MobileRow = (props: MobileRowProps) => {
  const { user } = useAppSelector((state: RootState) => state);

  const {
    usuario,
    onBlock,
    onEdit,
    onVisualizar,
    onDelete,
    onChangeStatus,
    vinculosNames,
    userStatus,
  } = props;

  const options = [
    {
      label: 'Editar',
      command: () => onEdit(usuario),
      checkPermission: 'ADM_USUARIO_ALTERAR',
    },
    {
      label: 'Visualizar',
      command: () => onVisualizar(usuario),
      checkPermission: 'ADM_USUARIO_CONSULTAR',
    },
    {
      label: 'Excluir',
      className: 'warning-color',
      command: () => onDelete(usuario),
      checkPermission: 'ADM_USUARIO_EXCLUIR',
    },
    {
      label: `${usuario?.status === true ? 'Inativar' : 'Ativar'}`,
      command: () => onChangeStatus(usuario),
      disabled: usuario?.status
        ? user.tipoUsuario !== 'MASTER' && user.tipoUsuario !== 'ADMINISTRADOR'
        : user.tipoUsuario !== 'MASTER',
    },
  ];

  return (
    <SearchListMobileRow options={options}>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Nome/ Tipo de usuário e Conselho
        </SimpleText>
        <br />
        <SimpleText
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.XXS}
          medium
        >
          {usuario?.name}
        </SimpleText>
      </div>
      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Tipo de usuário/Conselho
        </SimpleText>
        <br />
        <SimpleText fontColor={FONT_COLOR.COLOR_16} fontSize={FONT_SIZE.XXS}>
          {usuario?.user_type}
        </SimpleText>
      </div>

      {vinculosNames.length > 0 ? (
        <>
          <div className="p-col-12">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              Vinculos
            </SimpleText>
            <br />

            <SimpleText
              id={`vinculo-${usuario.id}`}
              fontColor={FONT_COLOR.PRIMARY}
              medium
            >
              {vinculosNames.join(' • ') || ''}
            </SimpleText>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="p-col-12">
        <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
          Login e status
        </SimpleText>
        <br />
        <div className="p-d-flex p-ai-center p-gap-3 ">
          <SimpleText
            className="p-mt-1"
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XXS}
          >
            {usuario?.login}
          </SimpleText>
          <div className={`status-${userStatus}`}>
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={userStatus ? FONT_COLOR.COLOR_16 : FONT_COLOR.COLOR_0}
              medium
            >
              {userStatus}
            </SimpleText>
          </div>
        </div>
        <div className="p-mt-3">
          {' '}
          <Switch
            checked={!usuario?.statusValue}
            onChange={() => onBlock(usuario)}
            showStatus
            checkPermission="ADM_USUARIO_ALTERAR"
            uncheckedLabel="Bloqueado"
            checkedLabel="Ativo"
          />
        </div>
      </div>
    </SearchListMobileRow>
  );
};
