import Avatar from 'src/components/Avatar/Avatar';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import './style.scss';
import { chatUserStatus } from '../../helpers';

export const ChatMessageHeader = ({
  isLoading = false,
  user,
}: {
  isLoading: boolean;
  user: IChatUsuario;
}) => {
  if (!user) return null;

  return (
    <div className="p-col-12 p-px-0 p-d-flex p-ai-center p-flex-wrap p-pt-0">
      <div className="relative p-col-2">
        <Avatar label={user.nome} size="large" />
        <span
          className="chat-message-header-avatar-badge"
          data-status={
            chatUserStatus[user.statusUsuario?.toUpperCase()] || 'offline'
          }
        />
      </div>
      <div className="p-col-5 p-pl-1 p-pr-0 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="sm" className="p-text-truncate" medium>
          {user.nome}
        </SimpleText>
        <SimpleText fontColor="color_40">{user.tipoDescricao}</SimpleText>
      </div>

      <div className="p-col-5 p-d-flex p-ai-center p-jc-end p-gap-2">
        {isLoading && (
          <>
            <SpinnerLoading size="xs" />
            <SimpleText fontSize="xxxs" fontColor="color_60">
              Atualizando mensagens
            </SimpleText>
          </>
        )}
      </div>

      <Divider className="p-p-0 p-m-0 p-w-100" layout="horizontal" />
    </div>
  );
};
