/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';
import './FornecedorTussGlobal.scss';

import FornecedorTussAPI from 'src/APIs/AdminAPI/FornecedorTussAPI/FornecedorTussAPI';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';
import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import { useSearchList } from 'src/components/SearchList/SearchListContext';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import Filter from './Filter';
import { Row } from './FornecedorGrid/Row';
import { Header } from './Header';
import ModalAddFornecedorTuss from './ModalAddFornecedorTuss/ModalAddFornecedorTuss';
import ModalExcluirFornecedorTuss from './ModalExcluirFornecedorTuss/ModalExcluirFornecedorTuss';

const FornecedorTussGlobal = () => {
  const [fornecedorTuss, setFornecedorTuss] = useState<any>({});
  const [reload, setReload] = useState<number>(0);
  const [items, setItems] = useState<any[]>([]);

  const confirmExcluirDisclosure = useDisclosure({ opened: false });

  const fornecedorDisclosure = useDisclosure({ opened: false });

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: [
      'adm_fornecedor_tuss_cadastrar',
      'adm_fornecedor_tuss_alterar',
      'adm_fornecedor_tuss_consultar',
      'adm_fornecedor_tuss_excluir',
    ],
  });

  const reloadList = () => {
    setReload(currentReload => currentReload + 1);
  };

  const handleModalAddFornecedorTuss = useCallback(() => {
    fornecedorDisclosure.open();
  }, []);

  const handleModalAddFornecedorTussClose = useCallback(() => {
    fornecedorDisclosure.close();
    setFornecedorTuss({});
  }, []);

  const handleModalEditFornecedorTuss = useCallback((item: any) => {
    setFornecedorTuss(item);
    fornecedorDisclosure.open();
  }, []);

  const fetchApi = async ({ query, ...rest }: any) => {
    return await FornecedorTussAPI.loadFornecedorTuss({
      nome: query,
      ...rest,
    });
  };

  const handleModalExcluirFornecedorTussClose = useCallback(() => {
    confirmExcluirDisclosure.close();
    setFornecedorTuss({});
  }, []);

  const handleExcluirFornecedorTuss = useCallback((item: any) => {
    setFornecedorTuss(item);
    confirmExcluirDisclosure.open();
  }, []);

  const handleIsActive = useCallback(
    async (item: any) => {
      try {
        const fornecedorEditado = {
          ...item,
          status: !item.status,
        };

        const response: any =
          await FornecedorTussAPI.updateFornecedorTussStatus(
            item?.id,
            fornecedorEditado,
          );

        if (response?.status === 200) {
          setItems([...items]);
          reloadList();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [items],
  );

  const renderRow = (data: any): JSX.Element => (
    <Row
      data={data}
      onDelete={handleExcluirFornecedorTuss}
      onEdit={handleModalEditFornecedorTuss}
      onSwitchStatus={handleIsActive}
    />
  );

  return (
    <div id="FornecedorTussGlobal" className="p-px-4">
      <SearchList.Root
        fetchApi={fetchApi}
        empresaRequired
        reloadWhenEmpresaChange
      >
        <SearchList.BorderContainer>
          <Filter handleModalAddFornecedorTuss={handleModalAddFornecedorTuss} />

          <SearchList.Header>
            <Header />
          </SearchList.Header>

          <SearchList.InfiniteScroll renderRow={renderRow} reload={reload} />
        </SearchList.BorderContainer>
      </SearchList.Root>

      {fornecedorDisclosure.isOpen && (
        <ModalAddFornecedorTuss
          fornecedorTussSelecionado={fornecedorTuss}
          visible={true}
          reload={reloadList}
          onHide={handleModalAddFornecedorTussClose}
        />
      )}

      {confirmExcluirDisclosure.isOpen && (
        <ModalExcluirFornecedorTuss
          fornecedorTuss={fornecedorTuss}
          reload={reloadList}
          visible={true}
          onHide={handleModalExcluirFornecedorTussClose}
          fornecedorTussList={items}
          setFornecedorTussList={setItems}
        />
      )}

      {assistenteVirtualDialog.isOpen && (
        <AssistenteVirtualDialog
          onClose={handleClose}
          canHide
          {...assistenteVirtualDialog}
        />
      )}
    </div>
  );
};

export default FornecedorTussGlobal;
