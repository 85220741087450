import PanelContent from './components/PanelContent';
import PanelHeader from './components/PanelHeader';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import { useSelectedContaReceber } from '../../useSelectedContaReceber';

interface DesktopRowProps {
  item: IContaReceberItem;
  authorizedActions: any;
  chipStatus: string;
}

const DesktopRow = ({
  item,
  authorizedActions,
  chipStatus,
}: DesktopRowProps) => {
  const { selectedContaReceber, setSelectedContaReceber } =
    useSelectedContaReceber();

  return (
    <div className="p-my-1">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            item={item}
            selectedContaReceber={selectedContaReceber}
            setSelectedContaReceber={setSelectedContaReceber}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            authorizedActions={authorizedActions}
            chipStatus={chipStatus}
          />
        )}
      >
        <PanelContent item={item} />
      </Panel>
    </div>
  );
};

export default DesktopRow;
