import { useCallback, useEffect, useState } from 'react';

import { DashboardAPI } from 'src/APIs/AdminAPI/DashboardAPI/DashboardAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';

import { FinanceiroChart } from '../_components/FinanceiroChart';
import { IndicadoresLineChart } from '../_components/IndicadoresLineChart';
import { IndicadoresTotais } from '../_components/IndicadoresTotais';
import { ProntuariosSearch } from '../_components/ProntuariosSearch';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { DashboardFilter } from './DashboardFilter';

export const DashboardContentProfissionalSaude = ({}) => {
  const { tipoProfissionalSaude } = useAppSelector(state => state.user);
  const { isTablet } = useSize();
  const [exames, setExames] = useState<
    IDashboardGraficosMesQuantidadeResponse[]
  >([]);

  const hasFinanceiroChart = tipoProfissionalSaude === 'MEDICO';

  const fetchExamesChart = useCallback(async () => {
    try {
      const response = await DashboardAPI.loadGraficoExamesSolicitados({
        throwError: true,
      });

      setExames(response);
    } catch {}
  }, []);

  useEffect(() => {
    fetchExamesChart();
  }, [fetchExamesChart]);

  return (
    <div className="p-w-100 p-d-flex p-flex-wrap p-ai-end p-mt-2">
      <div
        className="p-col-12 p-md-6 p-d-flex p-flex-column p-gap-2"
        style={{
          height: '317px',
        }}
      >
        <SimpleText fontSize="xs" fontColor="color_40" medium>
          Indicadores totais
        </SimpleText>
        <IndicadoresTotais />
      </div>

      <div className="p-col-12 p-md-6 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xs" fontColor="color_40" medium>
          Transações TISS realizadas por mês
        </SimpleText>
        <IndicadoresLineChart
          data={{
            labels: exames.map(({ mes }) => mes).reverse(),
            values: exames.map(({ quantidade }) => quantidade).reverse(),
          }}
        />
      </div>

      {hasFinanceiroChart && (
        <div className="p-col-12 p-md-6 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontSize="xs" fontColor="color_40" medium>
            Financeiro dos últimos 30 dias
          </SimpleText>
          <FinanceiroChart className="p-w-100" />
        </div>
      )}

      <div
        className="p-col-12 p-md-6 p-d-flex p-flex-column p-ai-start p-gap-2"
        style={{
          height: hasFinanceiroChart && !isTablet ? '317px' : 'auto',
        }}
      >
        <SimpleText fontSize="xs" fontColor="color_40" medium>
          Prontuário
        </SimpleText>

        <ProntuariosSearch className="p-w-100" />
      </div>

      <DashboardFilter />
    </div>
  );
};
