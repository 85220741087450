import { PacienteFamiliarPayload } from 'src/models/APIs/ProntuarioAPI/PacienteFamiliarAPI/PacienteFamiliarAPI';
import { LoadPacienteFamiliares } from 'src/models/APIs/ProntuarioAPI/PacienteFamiliaresAPI/PacienteFamiliares';

import Http, { HttpParams } from 'src/core/http/Http';

import { IFamiliarPaciente } from '../FamiliarAPI/Familiar';


const basepath = '/familiar-paciente';

const loadPacienteFamiliares = async (
  idPaciente: string | number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IFamiliarPaciente[]>> => {
  return Http.get({
    service: 'pep',
    url: `${basepath}/${idPaciente}`,
    hideToast: true,
    ...options,
  });
};

const createPacienteFamiliares = async (
  idPaciente: string | number,
  payload: PacienteFamiliarPayload,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basepath}/${idPaciente}`,
    data: payload,
    ...options,
  });
};

export const PacienteFamiliaresAPI = {
  loadPacienteFamiliares,
  createPacienteFamiliares,
};
