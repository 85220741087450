import { useCallback, useState } from 'react';

import { MonitoramentoNotificacoesAPI } from 'src/APIs/ReportAPI/RelatorioVigilanciaAPI/MonitoramentoNotificacoesAPI/MonitoramentoNotificacoesAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { handleDownloadPDF } from 'src/utils/files';

import CheckboxCounter from './components/CheckboxCounter';
import Filter from './components/Filter';
import { Button } from 'src/components/_UI';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import Row from './Row/Row';

const MonitoramentoNotificacoes = () => {
  const { consultorios } = useAppSelector((state: RootState) => state);
  const [selectedAgravo, setSelectedAgravo] = useState([]);
  const [reload, setReload] = useState(0);
  const [loadingDownloadPdf, setLoadingDownloadPdf] = useState(false);
  const [filtroPesquisa, setFiltroPesquisa] = useState<{
    periodoTempo?: string;
    notificado?: boolean;
    nomeAgravo?: string;
  }>({});

  const reloadList = useCallback(() => {
    setReload(currentReload => currentReload + 1);
  }, []);

  const fetchApi = async ({ query, ...params }: any): Promise<any> => {
    try {
      const response =
        await MonitoramentoNotificacoesAPI.getMonitoramentoNotificacoes({
          ...(query && { nomeAgravo: query }),
          ...params,
          idConsultorio: consultorios?.ativo?.id,
        });

      const { page, periodoTempo, notificado } = params;

      setFiltroPesquisa(prev => ({
        ...(periodoTempo && { periodoTempo }),
        ...(notificado !== undefined && { notificado }),
        ...(query && { nomeAgravo: query }),
      }));

      return response || [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const gerarRelatorio = async () => {
    if (!filtroPesquisa) return;

    setLoadingDownloadPdf(true);
    try {
      const response = await MonitoramentoNotificacoesAPI.gerarRelatorioPdf({
        ...filtroPesquisa,
        idConsultorio: consultorios?.ativo?.id,
      });
      handleDownloadPDF(response, 'relatorio-conta-pagar');
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDownloadPdf(false);
    }
  };

  const renderRow = (item: IAgravoNotificacao): JSX.Element => {
    return (
      <Row
        item={item}
        selectedAgravo={selectedAgravo}
        setSelectedAgravo={setSelectedAgravo}
      />
    );
  };

  return (
    <div className="p-p-1">
      <SearchList.Root
        fetchApi={fetchApi}
        consultorioRequired
        reloadWhenConsultorioChange
      >
        <Filter setSelectedAgravo={setSelectedAgravo} />
        <SearchList.BorderContainer className="p-py-1 p-px-3">
          <div className="p-d-flex p-jc-between">
            <CheckboxCounter
              selectedAgravo={selectedAgravo}
              setSelectedAgravo={setSelectedAgravo}
              reloadList={reloadList}
            />
            <div className="p-d-flex p-ai-center">
              <Button
                label="Exportar para PDF"
                icon="fas fa-print"
                btnType="tonal"
                iconPos="left"
                onClick={() => gerarRelatorio()}
                loading={loadingDownloadPdf}
                stretch
              />
            </div>
          </div>

          <SearchList.InfiniteScroll reload={reload} renderRow={renderRow} />
        </SearchList.BorderContainer>
      </SearchList.Root>
    </div>
  );
};

export default MonitoramentoNotificacoes;
