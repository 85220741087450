import { useState } from 'react';

import { InputTextarea } from 'primereact/inputtextarea';

import { Button } from 'src/components/_UI';

import './style.scss';

type Props = {
  onChange?: (text: string) => void;
  onSend: (message: string) => void;
};

export const ChatMessageInput = ({ onChange, onSend }: Props) => {
  const [text, setText] = useState('');

  const handleSend = () => {
    if (!!text.trim()) {
      onSend(text);
      setText('');
    }
  };

  return (
    <div className="chat-message-input-container p-w-100">
      <InputTextarea
        className="chat-message-input p-pr-0"
        placeholder="Escreva uma mensagem"
        value={text}
        rows={1}
        autoResize
        onChange={e => {
          setText(e.target.value);
          onChange?.(e.target.value);
        }}
        onKeyDown={e => {
          if (e.code === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
          }
        }}
      />

      <Button
        icon="fas fa-paper-plane"
        btnType="tonal"
        className="p-col-2 p-mr-1"
        onClick={handleSend}
      />
    </div>
  );
};
