import { useRef, useState } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';
import TermoConsentimentoPacienteAPI from 'src/APIs/PacienteAPI/TermoConsentimentoPacienteAPI/TermoConsentimentoPacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { DisclosureType, StateType } from 'src/utils/hooks/useDisclosure';

import { checkAuth, enumToText } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

interface RowProps extends DisclosureType {
  termo: ITermoConsentimentoItem;
  reloadList: () => void;
  setNovoTermo: (termo: ITermoConsentimentoItem) => void;
}

const Row = ({ termo, open, reloadList, setNovoTermo }: RowProps) => {
  return (
    <div className="p-my-2 row-termo">
      <Panel
        header={(isCollapsed: boolean, onToggle: () => void) => (
          <PanelHeader
            termo={termo}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            open={open}
            reloadList={reloadList}
            setNovoTermo={setNovoTermo}
          />
        )}
      >
        <PanelContent termo={termo} />
      </Panel>
    </div>
  );
};

export default Row;

interface PanelHeaderProps {
  termo: any;
  isCollapsed: boolean;
  onToggle: () => void;
  open: (state?: StateType<ITermoConsentimentoItem>) => void;
  reloadList: () => void;
  setNovoTermo: (termo: ITermoConsentimentoItem) => void;
}

const PanelHeader = ({
  termo,
  isCollapsed,
  onToggle,
  open,
  reloadList,
  setNovoTermo,
}: PanelHeaderProps) => {
  const [loading, setLoading] = useState(false);
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);
  const {
    dataOcorrencia,
    dataInclusao,
    situacaoAnterior,
    situacao,
    respLegal,
    nomeDocumento,
  } = termo;
  const ref = useRef<OverlayPanel>(null);

  const onEdit = () => {
    open({ state: termo });
    setNovoTermo(termo);
    ref.current?.hide();
  };

  const onRevogar = async () => {
    try {
      await TermoConsentimentoPacienteAPI.revogarTermoConsentimento(termo.id);
      reloadList();
    } catch (error) {
      console.error(error);
    }
  };

  const actionItems = [
    {
      label: termo.situacao !== 'REVOGADO' ? 'Editar' : 'Visualizar',
      command: onEdit,
      checkPermission: 'ADM_PACIENTE_ALTERAR',
    },
    ...(termo.situacao !== 'REVOGADO'
      ? [
          {
            label: 'Revogar',
            command: onRevogar,
            checkPermission: 'ADM_PACIENTE_ALTERAR',
          },
        ]
      : []),
  ];

  const onDownloadTermo = async (url: string) => {
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          responseType: 'arraybuffer',
        },
      });

      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = nomeDocumento || 'documento.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const authorizedActions = actionItems.filter(item => {
    return item?.checkPermission
      ? checkAuth(authorities, item?.checkPermission)
      : true;
  });

  return (
    <div className="p-grid p-ai-start p-p-2 bg-96">
      <div className="p-col p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Data da ocorrência:
        </SimpleText>
        <SimpleText fontSize="xs">
          {dataOcorrencia
            ? dayjs(new Date(dataOcorrencia)).format('DD/MM/YYYY')
            : ''}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Data da inclusão:
        </SimpleText>
        <SimpleText fontSize="xs">
          {dataInclusao
            ? dayjs(new Date(dataInclusao)).format('DD/MM/YYYY HH:mm')
            : ''}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Situação anterior:
        </SimpleText>
        <SimpleText fontSize="xs">
          {situacaoAnterior ? enumToText(situacaoAnterior) : ''}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Situação atual:
        </SimpleText>
        <SimpleText fontSize="xs">{enumToText(situacao)}</SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Responsável legal:
        </SimpleText>
        <SimpleText fontSize="xs">{respLegal ? 'Sim' : 'Não'}</SimpleText>
      </div>
      <div className="p-col p-d-flex p-gap-2">
        <Button
          btnType="gray"
          icon="fas fa-ellipsis"
          onClick={e => ref.current?.toggle(e)}
        />
        <TieredMenu ref={ref} popup model={authorizedActions} />

        <Button
          btnType="gray"
          icon="fas fa-file-download"
          onClick={() => onDownloadTermo(termo.linkDocumento)}
          loading={loading}
        />

        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="ghost"
          onClick={onToggle}
          disabled={!termo.respLegal}
        />
      </div>
    </div>
  );
};

interface PanelContentProps {
  termo: ITermoConsentimentoItem;
}

const PanelContent = ({ termo }: PanelContentProps) => {
  const {
    nomeRespLegal,
    cpfRespLegal,
    cnsRespLegal,
    vinculoFamiliar,
    profissionaisAutorizados,
  } = termo;
  return (
    <div className="p-grid p-p-2 bg-96">
      <div className="p-col p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Responsável:
        </SimpleText>
        <SimpleText fontSize="xs">{nomeRespLegal || ''}</SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          CPF:
        </SimpleText>
        <SimpleText fontSize="xs">{cpfRespLegal}</SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          CNS:
        </SimpleText>
        <SimpleText fontSize="xs">{cnsRespLegal}</SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Grau de parentesco:
        </SimpleText>
        <SimpleText fontSize="xs">
          {vinculoFamiliar?.descricao || ''}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-2 p-ai-start p-jc-start">
        <SimpleText fontColor="color_60" fontSize="xxs">
          Profissionais:
        </SimpleText>
        <Button
          className="p-p-0"
          label={`${profissionaisAutorizados?.length || 0} Profissionais`}
          btnType="green-link"
          overlay={
            <div
              className="p-d-flex p-flex-column p-gap-1"
              style={{ maxHeight: '200px', overflowY: 'auto' }}
            >
              <SimpleText medium className="p-mb-2 p-px-2">
                Profissionais autrizados
              </SimpleText>
              {profissionaisAutorizados?.map((profissional: any) => (
                <div key={profissional.id} className="bg-96 br-8px p-p-2">
                  {profissional.profissionalSaude.usuario.nome || ''}
                </div>
              ))}
            </div>
          }
        />
      </div>
    </div>
  );
};
