import React from 'react';

import { useAGA } from 'src/core/hooks/AGA/useAGA';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';

import Separator from 'src/components/Separator/Separator';

import BarraLateralProntuario from '../Atendimento/IniciarAtendimento/BarraLateralProntuario/BarraLateralProntuario';
import { Telemedicina } from '../Atendimento/Telemedicina/Telemedicina';

import DiagnosticoFuncionalGlobal from './DiagnostivoFuncionalGlobal/DiagnosticoFuncionalGlobal';
import HistoricoAGA from './HistoricoAGA/HistoricoAGA';
import QuestionarioAGA from './QuestionarioAGA/QuestionarioAGA';


import './AGA.scss';

interface AGAProps {
  idPaciente: number | null;
  idadePaciente: number | null;
  usuario: GetUsuarioDTO | null;
}

const AGA = ({ idPaciente, idadePaciente }: AGAProps) => {
  const { showBarraLateral, isTeleatendimento } = useAtendimento();
  const { diagnosticoFuncionalDisclosure } = useAGA();

  return (
    <div id="idoso-bem-cuidado" className="p-grid">
      <div
        className={
          showBarraLateral || isTeleatendimento
            ? 'p-col-12 p-sm-9 atendimento-e-historico'
            : 'p-col-12 p-sm-11 atendimento-e-historico'
        }
      >
        <QuestionarioAGA
          idPaciente={idPaciente}
          idadePaciente={idadePaciente}
        />
        <Separator layout="horizontal" />
        <HistoricoAGA idPaciente={idPaciente} />
      </div>
      <div
        id="barra-lateral-prontuario"
        className={showBarraLateral || isTeleatendimento ? 'p-sm-3' : 'p-sm-1'}
      >
        {isTeleatendimento && <Telemedicina />}
        <BarraLateralProntuario idPaciente={idPaciente} />
      </div>
      {diagnosticoFuncionalDisclosure.isOpen && (
        <DiagnosticoFuncionalGlobal {...diagnosticoFuncionalDisclosure} />
      )}
    </div>
  );
};

export default AGA;
