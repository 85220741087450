import { useState } from 'react';

import Dropdown from 'src/components/Basics/Dropdown/Dropdown';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import Row from './Row';

const List = () => {
  const [reload, setReload] = useState(0);

  const renderRow = (data: IPendenciaAuditoriaDTO) => {
    return <Row data={data} />;
  };

  return (
    <SearchList.BorderContainer>
      <SearchList.NonPageableScrollArea reload={reload} renderRow={renderRow} />
    </SearchList.BorderContainer>
  );
};

export default List;
