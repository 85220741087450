import Http, { HttpParams } from 'src/core/http/Http';

const loadGenogramaData = async (
  idPaciente: string | number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IGenoragramaResponse> => {
  return Http.get({
    service: 'pep',
    url: `genograma/${idPaciente}`,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const GenogramaAPI = {
  loadGenogramaData,
};
