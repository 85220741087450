import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/pendencias-auditoria';

export const getPendenciasAuditoria = async (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IPendenciaAuditoriaDTO>> =>
  Http.get({
    service: 'pep',
    url: basePath,
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });

export const getProcedimentosPendencia = async (
  params: { idGuia: number; idPaciente: number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: 'paciente-guia-tiss',
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });

export const getMensagensPendencia = async (
  params: { numeroGuia: number; anoGuia: string },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IMsgPendenciaAuditoriaDTO[]> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/mensagens`,
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });

export const postMensagemPendencia = async (
  data: IMsgPendenciaAuditoriaDTO,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<IMsgPendenciaAuditoriaDTO> =>
  Http.post({
    service: 'pep',
    url: `${basePath}/${data.mensagem ? 'mensagens' : 'arquivos'}`,
    data,
    hideToast: true,
    throwError: true,
    ...options,
  });


export const pendenciasAuditoriaNotificacao = async (
  params: { profissionalSaude: number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/notificacao`,
    params,
    hideToast: true,
    throwError: true,
    errorHideToast: true,
    ...options,
  });

const PendenciasAuditoriaAPI = {
  getPendenciasAuditoria,
  getProcedimentosPendencia,
  getMensagensPendencia,
  postMensagemPendencia,
  pendenciasAuditoriaNotificacao,
};

export default PendenciasAuditoriaAPI;
