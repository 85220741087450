import { memo, useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { AvaliacaoFormularioDinamicoAPI } from 'src/APIs/ProntuarioAPI/AvaliacaoFormularioDinamicoAPI/AvaliacaoFormularioDinamicoAPI';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { mergeAnswersEvolucao } from './components/utils';
import {
  convertFormDataToSubmit,
  convertResponsesToInitialValues,
  createValidationSchema,
} from './utils';

import { Button } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';

import { FormulariosContentHeader } from './FormulariosContentHeader';
import { FormulariosContentQuestion } from './FormulariosContentQuestion';
import { useFormulario } from './useFormulariosContext';

import './Formularios.scss';

export type FormularioPerguntasItem = Omit<
  IPerguntaFormularioItem,
  'perguntaVinculada'
> & {
  idPerguntaFormularioDinamico: number;
  idFormularioDinamico: number;
  idFormularioPerguntaVinculada: number | null;
  idOpcaoPerguntaVinculada: number | null;
  idPerguntaVinculada: number | null;
  perguntaVinculada: boolean;
};

export const FormulariosContent = memo(
  ({
    aplicacaoFormulario,
  }: {
    aplicacaoFormulario: IAplicacaoFormularioDinamicoItem;
  }) => {
    const [isError, setIsError] = useState(false);

    const { profissionalAtivo } = useAppSelector(state => state.agenda);
    const { setIsFormularioDirty, atendimentoStatus } = useAtendimento();
    const {
      prontuario,
      handleRefetch,
      avaliacaoEdit,
      copyFormData,
      setCopyFormData,
    } = useFormulario();

    const formulario = aplicacaoFormulario.formularioDinamico;
    const idAplicacao = aplicacaoFormulario.id;
    const isAtivo = aplicacaoFormulario.situacao === 'ATIVO';

    const orderedQuestions = useMemo(
      () =>
        formulario?.perguntas?.sort?.((a, b) => {
          return a.ordem - b.ordem;
        }) || [],
      [formulario?.perguntas],
    );

    const perguntas = useMemo(
      () =>
        orderedQuestions?.map(perg => ({
          ...perg.perguntaFormularioDinamico,
          idFormularioDinamico: perg.idFormularioDinamico,
          idPerguntaFormularioDinamico: perg.idPerguntaFormularioDinamico,
          idFormularioPerguntaVinculada: perg.idFormularioPerguntaVinculada,
          idOpcaoPerguntaVinculada: perg.idOpcaoPerguntaVinculada,
          idPerguntaVinculada: perg.idPerguntaVinculada,
          perguntaVinculada: perg.perguntaVinculada,
        })) || [],
      [orderedQuestions],
    );

    const defaultValues = useMemo(() => {
      const respostas = copyFormData?.respostas || avaliacaoEdit?.respostas;

      if (!respostas) return { respostas: {} };

      const res = mergeAnswersEvolucao(respostas);
      return { respostas: convertResponsesToInitialValues(res) };
    }, [avaliacaoEdit?.respostas, copyFormData?.respostas]);

    const validateSchema = createValidationSchema(
      perguntas as FormularioPerguntasItem[],
    );

    const form = useForm({
      defaultValues,
      resolver: yupResolver(validateSchema),
    });

    const hasParecer = !!avaliacaoEdit && !!avaliacaoEdit.pontuacao && isAtivo;
    const isParecerNull =
      !!avaliacaoEdit && isAtivo && avaliacaoEdit.pontuacao === null;
    const pBottom = hasParecer ? '252px' : isParecerNull ? '128px' : '8px';

    const onSubmit = async (v: any) => {
      console.log("🚀 ~ onSubmit ~ prontuario?.atendimento:", prontuario?.atendimento)

      if (!prontuario?.atendimento) return;

      const payload = {
        idAplicacaoFormularioDinamico: idAplicacao,
        idProfissionalSaude: profissionalAtivo.id,
        idPaciente: prontuario?.atendimento.paciente.id,
        idAtendimento: prontuario?.atendimento.id,
        respostas: convertFormDataToSubmit(v.respostas),
        ...(avaliacaoEdit && { id: avaliacaoEdit.id }),
      };

      try {
        if (avaliacaoEdit) {
          await AvaliacaoFormularioDinamicoAPI.updateAvaliacoesFormularioDinamico(
            { ...payload, id: avaliacaoEdit.id },
            { throwError: true },
          );
        } else {
          await AvaliacaoFormularioDinamicoAPI.createAvaliacoesFormularioDinamico(
            payload,
            { throwError: true },
          );
        }

        copyFormData && setCopyFormData(null);
        isError && setIsError(false);
        handleRefetch();
      } catch {
        setIsError(true);
      }
      return;
    };

    useEffect(() => {
      form.reset(defaultValues, {
        keepIsSubmitted: true,
      });
    }, [defaultValues, form]);

    useEffectSkipFirst(() => {
      setIsFormularioDirty(form.formState.isDirty);
    }, [form.formState.isDirty]);

    const formHasError = Object.keys(form.formState.errors)?.length;

    // Keep this console.log for debugging purposes
    console.log('🚀 ~ form.formState.errors:', form.formState.errors);

    return (
      <div className="FormularioContent p-d-flex p-flex-column p-gap-2 p-w-100">
        <FormulariosContentHeader
          isInativo={!isAtivo}
          formulario={formulario}
        />

        {isAtivo && (
          <FormProvider {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="p-mt-2 p-d-flex p-flex-column p-gap-2"
            >
              {perguntas.map(field => {
                return (
                  <FormulariosContentQuestion field={field} key={field.id} />
                );
              })}

              <div
                className="row content-row white p-p-1 p-gap-1 btn-form-footer p-flex-column p-jc-center"
                style={{
                  bottom: pBottom,
                  height: '78px',
                }}
              >
                <Button
                  type="submit"
                  label="Salvar"
                  loading={form.formState.isSubmitting}
                  disabled={atendimentoStatus !== 'ATENDENDO'}
                  stretch
                />
                {form.formState.isSubmitted &&
                  !!avaliacaoEdit &&
                  !isError &&
                  !formHasError && (
                    <AlertBox
                      visible
                      text="Você concluiu o preenchimento do formulário e suas respostas foram salvas com sucesso."
                      type="SUCCESS"
                      className="p-mt-0"
                    />
                  )}
              </div>
            </form>
          </FormProvider>
        )}
      </div>
    );
  },
);
