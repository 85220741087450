import {
  CreatePlanoCuidadoPaciente,
  ExecucaoPlanoCuidadoPayload,
  GeneratePlanoCuidadoRecorrencia,
} from 'src/models/APIs/ProntuarioAPI/PlanoCuidadoPaciente/PlanoCuidadoPaciente';

import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/plano-cuidado-paciente';

const loadPlanoCuidadoPaciente = (
  params: {
    idPaciente: number;
    idConsultorio: number;
    idAtendimento?: number | string;
    idConvenio?: number;
    nome?: string;
    ativo?: boolean | undefined;
    sortBy?: string;
    sortDirection?: string;
    idProfissionalResponsavel?: number;
    historico?: boolean;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}`,
    ...options,
    hideToast: true,
    throwError: true,
    params,
  });
};

const loadPlanoCuidadoPacienteById = (
  idPlanoCuidado: string | number,
  params?: { idAtendimento?: string | number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/${idPlanoCuidado}`,
    ...options,
    hideToast: true,
    throwError: true,
    params,
  });
};

const loadPlanoCuidadoPacienteDadosPlanos = (
  params: {
    nome?: string;
    emAtraso?: boolean;
    emDia?: boolean;
    tipoCuidado?: string;
    idConsultorio: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/dados-planos`,
    ...options,
    hideToast: true,
    throwError: true,
    params,
  });
};

const loadPlanoCuidadoPacientePacientes = (
  params: {
    idConsultorio: number;
    idPlanoCuidado?: number | undefined;
    nome?: string;
    emAtraso?: boolean;
    emDia?: boolean;
    tipoCuidado?: string;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/pacientes`,
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

const createPlanoCuidadoPaciente = (
  payload: CreatePlanoCuidadoPaciente,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basePath}`,
    ...options,
    data: payload,
  });
};

const updatePlanoCuidadoPaciente = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GeneratePlanoCuidadoRecorrencia> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/${payload.id}`,
    ...options,
    data: payload,
    hideToast: true,
    throwError: true,
  });
};

const inativarPlanoCuidadoPaciente = (
  params: any,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GeneratePlanoCuidadoRecorrencia> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/${params.id}/inativar`,
    ...options,
    data: payload,
    hideToast: true,
    throwError: true,
  });
};

const deletarPlanoCuidadoPaciente = (
  params: { id: string | number; idAtendimento: number | string },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GeneratePlanoCuidadoRecorrencia> => {
  return Http.delete({
    service: 'pep',
    url: `${basePath}/${params.id}/atendimento/${params.idAtendimento}`,
    ...options,
    throwError: true,
  });
};

const generatePlanoCuidadoRecorrencia = (
  payload: Omit<GeneratePlanoCuidadoRecorrencia, 'datasSugeridas'>,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GeneratePlanoCuidadoRecorrencia> => {
  return Http.post({
    service: 'pep',
    url: `${basePath}/recorrencias`,
    ...options,
    data: payload,
    hideToast: true,
    throwError: true,
  });
};

const executarPlanoCuidadoPaciente = (
  params: ExecucaoPlanoCuidadoPayload,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GeneratePlanoCuidadoRecorrencia> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/${params.id}/atividade/${params.idAtividade}/execucao/${params.idExecucao}`,
    ...options,
    data: payload,
    throwError: true,
  });
};

const loadProfissionaisSugeridos = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/profissionais-sugeridos`,
    hideToast: true,
    params,
    ...options,
  });
};

const verifyPlanoCuidadoPacienteMesmoCid = (
  params: { idPaciente: number; idPlanoCuidado: number },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/possui-plano-vinculado-com-mesmo-cid`,
    hideToast: true,
    params,
    ...options,
  });
};

const loadProntuarioComplemento = (
  params: { idPaciente: string | number; idPlanoCuidado: number | string },
  options?: Omit<HttpParams, 'service' | 'url'>,
) => {
  return Http.post({
    service: 'pep',
    url: `prontuario/iniciar-complemento/plano-cuidado/${params.idPlanoCuidado}/paciente/${params.idPaciente}`,
    throwError: true,
    ...options,
  });
};

export const PlanoCuidadoPacienteAPI = {
  loadPlanoCuidadoPaciente,
  loadPlanoCuidadoPacienteById,
  createPlanoCuidadoPaciente,
  updatePlanoCuidadoPaciente,
  inativarPlanoCuidadoPaciente,
  generatePlanoCuidadoRecorrencia,
  deletarPlanoCuidadoPaciente,
  executarPlanoCuidadoPaciente,
  loadPlanoCuidadoPacienteDadosPlanos,
  loadPlanoCuidadoPacientePacientes,
  loadProfissionaisSugeridos,
  verifyPlanoCuidadoPacienteMesmoCid,
  loadProntuarioComplemento,
};
