import { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSubscription } from 'react-stomp-hooks';

import dayjs from 'dayjs';
import { TelemedicinaSalaAtendimentoAPI } from 'src/APIs/ConfigAPI/TelemedicinaSalaAtendimento/TelemedicinaSalaAtendimento';

import { useTelemedicina } from 'src/core/hooks/Telemedicina/useTelemedicina';
import { useAppSelector } from 'src/core/redux/hooks';
import { setTelemedicina } from 'src/core/redux/slices/telemedicina/TelemecinaSlice';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { validateMessageTelemedicina } from '../utils';
import { capitalizeFirstLetter } from 'src/utils/utils';

import { CardInfo } from '../components/CardInfo/CardInfo';
import { Feedback } from '../components/Feedback/Feedback';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import './SalaEspera.scss';
type SalaTelemedicinaProps = {
  conteudo: string;
};

export const SalaEsperaTelemedicina = () => {
  const {
    user: { access_token },
  } = useAppSelector((state: RootState) => state);
  const { state } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { codEmpresa, idAtendimento, idAtendimento1 } = useParams();
  const { handleSetAtendimento, atendimentoData, setRoomURL } =
    useTelemedicina();

  const dialogFeedback = useDisclosure({
    opened: !!state && !atendimentoData?.pesquisaResponsida,
  });

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useSubscription('/user/topic/notificacao-telemedicina', message => {
    const data: SalaTelemedicinaProps = JSON.parse(message.body);
    const url = data.conteudo?.split('/')?.[3] || '';

    setRoomURL(url);
  });

  const validateTermo = useCallback(
    (item: any, idAtendimento: string) => {
      if (item.status === 'PENDENTE') {
        navigate(`/t/termo/${item.id}/atendimento/${idAtendimento}`, {
          state: codEmpresa,
        });
      }
    },
    [codEmpresa, navigate],
  );

  useEffectSkipFirst(() => {
    if (
      atendimentoData?.statusAtendimento === 'ATENDIDO' &&
      !atendimentoData?.pesquisaResponsida
    ) {
      dialogFeedback.open();
    }
  }, [atendimentoData]);

  useEffect(() => {
    // TODO: REMOVER VALIDAÇÃO DE COMPARAÇAO DE IDS DPS DO REFACTOR
    if (error || idAtendimento !== idAtendimento1)
      navigate(`/t/erro/${idAtendimento}`);
  }, [error, idAtendimento, idAtendimento1, navigate]);

  useEffect(() => {
    if (!access_token) return;
    const atendimentoId = atendimentoData?.idAtendimento || idAtendimento;

    if (codEmpresa && atendimentoId) {
      TelemedicinaSalaAtendimentoAPI.getInformacoes(
        {
          codigoEmpresa: codEmpresa,
          hashProfissionalSaude: atendimentoId,
          hashPaciente: atendimentoId,
        },
        { throwError: true },
      )
        .then(item => [
          handleSetAtendimento(item),
          dispatch(setTelemedicina({ idAtendimento: item.idAtendimento })),
          item.telemedicinaLogTermo &&
            validateTermo(item.telemedicinaLogTermo, item.idAtendimento),
        ])
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  }, [
    atendimentoData?.idAtendimento,
    codEmpresa,
    dispatch,
    handleSetAtendimento,
    idAtendimento,
    validateTermo,
    access_token,
  ]);

  useEffect(() => {
    if (atendimentoData?.idAtendimento) {
      const timer = setInterval(async () => {
        const { idAtendimento } = atendimentoData;
        await TelemedicinaSalaAtendimentoAPI.sendPacienteOnline({
          idAtendimento,
        });
      }, 10000);

      return () => clearInterval(timer);
    }

    return () => {};
  }, [atendimentoData]);

  return (
    <div className="p-d-flex p-flex-column">
      {loading ? (
        <SpinnerLoading full />
      ) : (
        <>
          <CardInfo
            text={`Tele consulta - ${atendimentoData?.nomeConvenio || ''}`}
          />

          <div className="content-tele p-grid p-col-12">
            <div className="p-md-6 p-col-12">
              <ElevatedCard className="card-tele p-d-flex p-flex-column p-gap-3">
                <SimpleText fontSize={FONT_SIZE.MD} bold>
                  Informações da teleconsulta
                </SimpleText>

                <div className="p-d-flex p-flex-column p-gap-2">
                  <div className="p-d-flex">
                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_60}
                      className="p-col-6"
                    >
                      Médico
                    </SimpleText>
                    <SimpleText className="p-col-6 p-text-right">
                      {atendimentoData?.nomeMedico || '-'}
                    </SimpleText>
                  </div>
                  <div className="p-d-flex">
                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_60}
                      className="p-col-4"
                    >
                      Data e Hora
                    </SimpleText>
                    <SimpleText className="p-col-8 p-text-right">
                      {dayjs(atendimentoData?.dataAgendamento).format(
                        'dddd[,] DD [de] MMM[. de] YYYY[, às] HH:mm',
                      )}
                    </SimpleText>
                  </div>
                  <div className="p-d-flex">
                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_60}
                      className="p-col-6"
                    >
                      Local
                    </SimpleText>
                    <SimpleText className="p-col-6 p-text-right">
                      Teleconsulta
                    </SimpleText>
                  </div>
                </div>

                <Divider layout="horizontal" />

                <SimpleText fontSize={FONT_SIZE.SM} bold>
                  Informações paciente
                </SimpleText>

                <div className="p-d-flex p-flex-column p-gap-2">
                  <div className="p-d-flex">
                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_60}
                      className="p-col-6"
                    >
                      Nome
                    </SimpleText>
                    <SimpleText className="p-col-6 p-text-right">
                      {atendimentoData?.nomePaciente || '-'}
                    </SimpleText>
                  </div>
                  <div className="p-d-flex">
                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_60}
                      className="p-col-6"
                    >
                      Contato
                    </SimpleText>
                    <SimpleText className="p-col-6 p-text-right">
                      {atendimentoData?.contatos?.[0]?.numero || '-'}
                    </SimpleText>
                  </div>
                </div>

                <Divider layout="horizontal" />

                <SimpleText fontSize={FONT_SIZE.SM} bold>
                  Outras informações
                </SimpleText>

                <div className="p-d-flex p-flex-column p-gap-2">
                  <ul className="list-tele p-d-flex p-flex-column p-gap-2">
                    {atendimentoData?.flagsAtendimento?.map((item: string) => (
                      <li key={item}>
                        <SimpleText>
                          {capitalizeFirstLetter(item || '')}
                        </SimpleText>
                      </li>
                    ))}
                  </ul>
                  <div className="p-d-flex">
                    <SimpleText
                      fontColor={FONT_COLOR.COLOR_60}
                      className="p-col-6"
                    >
                      Especialidade
                    </SimpleText>
                    <SimpleText className="p-col-6 p-text-right">
                      {atendimentoData?.nomeEspecialidade || '-'}
                    </SimpleText>
                  </div>
                </div>
              </ElevatedCard>
            </div>

            <div className="p-md-6 p-col-12 p-d-flex p-flex-column p-jc-center p-ai-center p-gap-4 p-px-6 p-text-center">
              {!!atendimentoData &&
                validateMessageTelemedicina(atendimentoData, !!state)}
            </div>
          </div>

          {dialogFeedback.isOpen && <Feedback {...dialogFeedback} />}
        </>
      )}
    </div>
  );
};
