import { useNavigate, useParams } from 'react-router';

import { Button } from 'src/components/_UI/Button';
import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';

import ContasReceberCreateForm from './ContasReceberCreateForm';

const ManterContasReceber = () => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <Page className={''}>
      <>
        <PageHeader
          title={params.id ? 'Editar conta a receber' : 'Nova conta a receber'}
          subtitle={'Preencha os campo para completar o cadastro'}
        />
        <Page content white className="p-pt-2">
          <>
            <Button
              className="btn-voltar p-mb-2 p-ml-2"
              btnType="ghost"
              id="back-button"
              label="Voltar"
              icon="fa fa-arrow-left"
              onClick={() => navigate('/faturamento/contas-receber')}
            />
            <ContasReceberCreateForm />
          </>
        </Page>
      </>
    </Page>
  );
};

export default ManterContasReceber;
