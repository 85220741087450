import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    dataVencimento: Yup.date().required('Data de vencimento é obrigatória').typeError('Campo obrigatório'),
    credor: Yup.string().required('Credor é obrigatório').typeError('Campo obrigatório'),
    idConsultorio: Yup.number().required('Consultório é obrigatório').typeError('Campo obrigatório'),
    idCentroCusto: Yup.number().required('Centro de custo é obrigatório').typeError('Campo obrigatório'),
    idTipoContaReceber: Yup.number().required('Tipo é obrigatório').typeError('Campo obrigatório'),
    valor: Yup.number().min(0.1, 'O valor deve ser maior que 0').required('Valor é obrigatório').typeError('Campo obrigatório'),
    status: Yup.string().required('Status é obrigatório').typeError('Campo obrigatório'),
    valorRecebido: Yup.number()
    .when('status', {
      is: 'PARCIALMENTE_RECEBIDO',
      then: Yup.number()
        .lessThan(Yup.ref('valor'), 'Para a opção "Parcialmente recebido", o valor do campo "Valor recebido" deve ser menor que o valor do campo "Valor".')
        .required('Valor recebido é obrigatório para status "Parcialmente recebido"'),
      otherwise: Yup.number().nullable(),
    })
    .when(['dataRecebimento', 'dataVencimento'], {
      is: (dataRecebimento: any, dataVencimento: any) => dataRecebimento <= dataVencimento,
      then: Yup.number().max(Yup.ref('valor'), 'O valor recebido não pode ser maior que o valor a não ser que a data de recebimento seja maior que a data de vencimento'),
      otherwise: Yup.number().nullable(),
    })
});

export default validationSchema;

