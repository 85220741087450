import { useCallback, useRef, useState } from 'react';

import { Menu } from 'primereact/menu';
import {
  Control,
  useController,
  useForm,
  useFormContext,
} from 'react-hook-form';

import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import './Template.scss';

interface TemplateProps {
  title: string;
  control: Control;
  resetField?: any;
  setValue?: any;
  name?: string;
  disabled?: boolean;
  readOnly?: boolean | undefined;
  defaultValue?: boolean;
  children: JSX.Element;
}

const Template = ({
  children,
  title,
  name = '',
  readOnly,
  control,
}: TemplateProps) => {
  const { setValue } = useFormContext();

  const handleResetField = useCallback(
    (name: string) => {
      return setValue(name, '');
    },
    [setValue],
  );

  const menuOptions = useRef<any>();

  const resetForm = useCallback(() => {
    if (name === 'viciosNaoSeAplica') {
      handleResetField('fumante');

      handleResetField('fumanteDomicilio');
      handleResetField('bebidaAlcoolica');
      handleResetField('atividadeFisica');
      handleResetField('medicamentoControlado');

      handleResetField('viciosNaoSeAplica');
    }

    if (name === 'dorNaoSeAplica') {
      handleResetField('dorCostasFrequente');
      handleResetField('dorCabecaFrequente');
      handleResetField('dorRelacionadaTrabalho');
      handleResetField('intensidadeDores');

      handleResetField('dorNaoSeAplica');
    }

    if (name === 'mentalNaoSeAplica') {
      handleResetField('transtornoMental');
      handleResetField('suicidio');
      handleResetField('depressao');
      handleResetField('ansiedade');

      handleResetField('mentalNaoSeAplica');
    }

    if (name === 'HistoricoNaoSeAplica') {
      handleResetField('mama');
      handleResetField('intestino');
      handleResetField('prostata');
      handleResetField('outros');
      handleResetField('descricaoOutros');

      handleResetField('HistoricoNaoSeAplica');
    }

    if (name === 'perfilNaoSeAplica') {
      handleResetField('satisfacaoSaude');
      handleResetField('diabetes');
      handleResetField('hipertensao');
      handleResetField('colesterolOuTriglicerides');
      handleResetField('doencasCerebrovasculares');
      handleResetField('doencasCardiovasculares');
      handleResetField('doencasRespiratorias');
      handleResetField('insuficienciaRenal');
      handleResetField('doencasNeurologicas');

      handleResetField('equilibrada');
      handleResetField('excessoGorduras');
      handleResetField('excessoSal');
      handleResetField('excessoCarboidratosAcucares');

      handleResetField('cardiaca');
      handleResetField('cancer');
      handleResetField('amputacaoPernaPe');
      handleResetField('bariatrica');
      handleResetField('outra');
      handleResetField('cirurgiasNaoSeAplica');
      handleResetField('naoRealizou');

      handleResetField('cirurgiaColunaProgramada');
      handleResetField('usuarioDrogas');
      handleResetField('calendarioVacinal');
      handleResetField('tomouVacinaGripe');
      handleResetField('gestante');

      handleResetField('colunaLombar');
      handleResetField('femurTotal');
      handleResetField('coloFemural');
      handleResetField('naoRealizouDensitometria');

      handleResetField('perfilNaoSeAplica');
      handleResetField('alimentacaoNaoSeAplica');
      handleResetField('alimentacao');

      handleResetField('cirurgiasNaoSeAplica');
      handleResetField('cirurgias');

      handleResetField('densitometriaNaoSeAplica');
      handleResetField('densitometria');
    }

    if (name === 'PesquisaNaoSeAplica') {
      handleResetField('realizouMamografia');
      handleResetField('realizouPapanicolau');
      handleResetField('realizouColonoscopia');
      handleResetField('realizouSangueOcultoFezes');

      handleResetField('PesquisaNaoSeAplica');
    }
  }, [name, handleResetField]);

  const items = [
    {
      label: 'Limpar as respostas da seção do formulário',
      command: resetForm,
    },
  ];

  return (
    <div className="container-template">
      <div className="p-d-flex p-ai-center p-jc-between">
        <SimpleText
          fontColor={FONT_COLOR.COLOR_16}
          fontSize={FONT_SIZE.MD}
          medium
        >
          {title}
        </SimpleText>

        {!readOnly && (
          <div className="p-d-flex p-ai-center">
            <CheckboxControlled
              name={name}
              control={control}
              label="Não se aplica"
              className="nao-se-aplica-template"
            />

            <Menu model={items} popup ref={menuOptions} />
            <div
              className="btn-limpar"
              onClick={event => menuOptions.current.toggle(event)}
            >
              <i className="pi pi-ellipsis-h" />
            </div>
          </div>
        )}
      </div>

      {children}
    </div>
  );
};

export default Template;
