import Http, { HttpParams } from 'src/core/http/Http';

const loadFormulariosAplicacoes = async (
  params: {
    status?: string | undefined;
    nome?: string;
    sortBy?: string;
    sortDirection?: string;
    idsPaciente?: number[];
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IGestaoMonitoramentoFormulariosAplicacaoesItem[]>> =>
  Http.get({
    service: 'adm',
    url: `/gestao-formulario-dinamico/pesquisar-formulario`,
    params: {
      ...params,
      idsPaciente: params.idsPaciente?.join(','),
    },
    hideToast: true,
    ...options,
  });
const loadPacientesFormularioAplicacao = async (
  params: {
    excluido?: boolean | undefined;
    idsAplicacao?: number[];
    nome?: string;
    sortBy?: string;
    sortDirection?: string; 
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<any[]>> =>
  Http.get({
    service: 'adm',
    url: `/gestao-formulario-dinamico/pesquisar-paciente`,
    params: {
      ...params,
      idsAplicacao: params.idsAplicacao?.join(','),
    },
    hideToast: true,
    ...options,
  });
const loadAvaliacoes = async (
  params: {
    idsAplicacao?: number[];
    idsPaciente?: number[];
    nomeFormulario?: string;
    idConvenio?: number;
    idConsultorio?: number;
    idEspecialidade?: number;
    idProfissionalSaude?: number;
    idSexoPaciente?: number;
    idadePaciente?: number;
    totalPontuacao?: number;
    parametroPontuacao?: string;
    dataInicio?: string;
    dataFim?: string;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IGestaoMonitoramentoItems[]>> => {
  return Http.get({
    service: 'adm',
    url: `/gestao-formulario-dinamico/pesquisar-avaliacoes`,
    params: {
      ...params,
      idsAplicacao: params.idsAplicacao?.join(','),
      idsPaciente: params.idsPaciente?.join(','),
    },
    hideToast: true,
    ...options,
  });
};

export const GestaoMonitoramentoFormularioAPI = {
  loadFormulariosAplicacoes,
  loadPacientesFormularioAplicacao,
  loadAvaliacoes,
};
