import { Dispatch, SetStateAction, useMemo } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { useNavigate } from 'react-router';

import { capitalizeFirstLetter } from 'src/utils/utils';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

import {
  conteudoModalComunicado,
  copiarComunicadoPacientes,
  editarComunicadoPacientes,
} from '../helpers';

import { IConteudoModal } from './CardComunicado';

interface MobileRowProps {
  data: ComunicadoDTO;
  setExibirModalComunicado: Dispatch<SetStateAction<boolean>>;
  setConteudoModal: Dispatch<SetStateAction<IConteudoModal>>;
}

export const MobileRow: React.FC<MobileRowProps> = ({
  data,
  setExibirModalComunicado,
  setConteudoModal,
}: any): JSX.Element => {
  const navigate = useNavigate();

  const options = useMemo(
    () =>
      [
        {
          label: 'Copiar',
          command: () => copiarComunicadoPacientes(data, navigate),
          checkPermission: 'ADM_MARKETING_MEDICO_CADASTRAR',
        },
        data.status !== 'ATIVO' && {
          label: 'Editar',
          command: () => editarComunicadoPacientes(data, navigate),
          checkPermission: 'ADM_MARKETING_MEDICO_ALTERAR',
        },
        data.status === 'AGENDADO' && {
          label: 'Inativar',
          command: () => {
            setConteudoModal(conteudoModalComunicado['inativar']);
            setExibirModalComunicado(true);
          },
          checkPermission: 'ADM_MARKETING_MEDICO_ALTERAR',
        },
        data.status !== 'ATIVO' && {
          label: 'Excluir',
          command: () => {
            setConteudoModal(conteudoModalComunicado['excluir']);
            setExibirModalComunicado(true);
          },
          checkPermission: 'ADM_MARKETING_MEDICO_EXCLUIR',
          className: 'error',
        },
      ].filter(Boolean) as MenuItem[],
    [data, navigate, setConteudoModal, setExibirModalComunicado],
  );

  return (
    <SearchListMobileRow options={options}>
      <div className="column">
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>Status</SimpleText>
        <div className="p-mt-2">
          <SimpleText
            className={`status ${capitalizeFirstLetter(data.status)}`}
          >
            {capitalizeFirstLetter(data.status)}
          </SimpleText>
        </div>
      </div>
      <div className="column p-mt-4">
        <SimpleText fontColor={FONT_COLOR.COLOR_60}>
          Título / Mensagem
        </SimpleText>
        <SimpleText className="p-mt-1" fontColor={FONT_COLOR.COLOR_16} medium>
          {data.titulo}
        </SimpleText>
        <SimpleText fontColor={FONT_COLOR.COLOR_16}>{data.mensagem}</SimpleText>
      </div>
      <div className="column p-mt-2 p-d-flex p-flex-col p-gap-1">
        <SimpleText fontColor="color_60">Tipo de mensagem</SimpleText>
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_16}>
          {data.tipoMensagem ? capitalizeFirstLetter(data.tipoMensagem) : ''}
        </SimpleText>{' '}
      </div>
    </SearchListMobileRow>
  );
};
