import { useCallback, useEffect, useState, useRef } from 'react';

import dayjs from 'dayjs';
import PacienteAPI from 'src/APIs/AdminAPI/PacienteAPI/PacienteAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import useDebounce from 'src/utils/useDebounce';
import { capitalizeFirstLetter, formatCnpj, formatCPF } from 'src/utils/utils';

import Avatar from 'src/components/Avatar/Avatar';
import Card, { CARD_TYPES } from 'src/components/Basics/Card/Card';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextInput from 'src/components/Basics/TextInput/TextInput';
import ElevatedCard from 'src/components/ElevatedCard/ElevatedCard';
import Separator from 'src/components/Separator/Separator';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';

import './SearchPaciente.scss';

interface SeatchPacienteProps {
  value?: GetPacienteDTO | undefined;
  label: string;
  disabled?: boolean;
  idConsultorio?: number;
  paramsBusca?: any;
  idPaciente: number | null;
  manterFamiliarDialog: DisclosureType;
}
const SearchPaciente = ({
  value,
  label,
  idConsultorio,
  paramsBusca,
  idPaciente,
  manterFamiliarDialog,
}: SeatchPacienteProps) => {
  const searchPacientesContentRef = useRef<HTMLDivElement>(null);

  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [pacientes, setPacientes] = useState<GetPacienteDTO[]>();
  const [current, setCurrent] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const debouncedPesquisaPaciente = useDebounce(current, 350);

  const onSelect = useCallback(
    async (paciente: GetPacienteDTO) => {
      if (!idPaciente) return;
      const { id, ...familiar } = paciente;
      try {
        manterFamiliarDialog.open({ state: familiar });

        setShowOverlay(false);
        setCurrent('');
        setPacientes([]);
      } catch (e) {
        console.error(e);
      }
    },
    [idPaciente, manterFamiliarDialog],
  );

  const loadPacientes = useCallback(
    async value => {
      setLoading(true);
      try {
        const params = {
          chave: value,
          ...(idConsultorio && { idConsultorio }),
          ...(paramsBusca && { ...paramsBusca }),
        };

        const aux = await PacienteAPI.loadPacienteByNome(params);
        if (aux) {
          setPacientes(aux.list);
        }
      } catch (e) {
        setPacientes([]);
      } finally {
        setLoading(false);
      }
    },
    [idConsultorio, paramsBusca],
  );

  useEffect(() => {
    if (debouncedPesquisaPaciente && current.length >= 3) {
      loadPacientes(debouncedPesquisaPaciente);
    } else {
      setPacientes([]);
    }
  }, [loadPacientes, debouncedPesquisaPaciente, current.length]);

  useEffect(() => {
    function handleClickOutsidePacientesContent(event: any) {
      if (
        searchPacientesContentRef.current &&
        !searchPacientesContentRef.current.contains(event.target)
      ) {
        setShowOverlay(false);
        setCurrent('');
        setPacientes([]);
      }
    }

    document.addEventListener('mousedown', handleClickOutsidePacientesContent);

    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsidePacientesContent,
      );
    };
  }, [searchPacientesContentRef]);

  const renderPacienteCard = useCallback(
    (paciente: GetPacienteDTO) => {
      return <PacientesRow onSelect={onSelect} paciente={paciente} />;
    },
    [onSelect],
  );

  const renderOverlay = useCallback(() => {
    return (
      <div className="search-paciente-overlay-content">
        {!!pacientes?.length ? (
          <div
            className="p-d-flex p-flex-column p-gap-2"
            style={{
              maxHeight: '380px',
              overflowY: 'auto',
            }}
          >
            {(pacientes || []).map((p: GetPacienteDTO) =>
              renderPacienteCard(p),
            )}
          </div>
        ) : (
          <div className="p-d-flex p-flex-column p-ai-center p-jc-center">
            {loading && <SpinnerLoading />}
            {!loading && current.length < 3 && (
              <SimpleText>O resultado da busca será exibido aqui</SimpleText>
            )}
            {!loading && current.length >= 3 && !pacientes?.length && (
              <SimpleText>Nenhum resultado encontrado</SimpleText>
            )}
            <Separator spaceOnly vertical />
          </div>
        )}
      </div>
    );
  }, [pacientes, loading, current.length, renderPacienteCard]);

  return (
    <div
      ref={searchPacientesContentRef}
      className="SearchPaciente p-col-12 p-px-0"
    >
      <TextInput
        label={label}
        rounded
        icon={'pi pi-search'}
        value={!value || showOverlay ? current : value?.nome}
        placeholder="Digite o nome, CPF ou a carteirinha"
        onChange={v => {
          setCurrent(v.target.value);
        }}
        onFocus={e => {
          setShowOverlay(true);
          setCurrent('');
        }}
      />

      <div className={`OverlayPanel ${showOverlay ? 'show' : 'hidden'}`}>
        <ElevatedCard type={'white'} stretch noMargin>
          {renderOverlay()}
        </ElevatedCard>
      </div>
    </div>
  );
};

export default SearchPaciente;

interface PacientesRowProps {
  onSelect(v: GetPacienteDTO): void;
  paciente: GetPacienteDTO;
}

const PacientesRow = ({ onSelect, paciente }: PacientesRowProps) => {
  const cpf = paciente?.cpf ? formatCPF(paciente.cpf) : undefined;
  const cnpj = paciente?.cnpj ? formatCnpj(paciente.cnpj) : undefined;

  return (
    <Card
      className="search-paciente-card-container row p-col-12 p-ai-center p-py-0"
      onClick={() => onSelect(paciente)}
      type={CARD_TYPES.GRAY}
    >
      <div className="p-col-4 p-sm-6 p-d-flex p-gap-2 p-ai-center">
        <Avatar label={paciente?.nome} className="avatar" />
        <div className="p-d-flex p-flex-column p-gap-1">
          <SimpleText>{paciente.nome}</SimpleText>
          <SimpleText fontSize="xxs" fontColor="color_40">
            {capitalizeFirstLetter(paciente.sexo.descricao)}
          </SimpleText>
        </div>
      </div>
      <div className="p-col-4 p-sm-3 p-d-flex p-flex-column p-gap-1">
        <SimpleText>{cpf || cnpj || '-'}</SimpleText>
        <SimpleText
          fontSize="xxs"
          className="p-text-climp-3"
          fontColor="color_40"
        >
          {paciente.cartaoPrincipal?.numeroCartao} -{' '}
          {paciente.cartaoPrincipal?.convenio.nomeFantasia ||
            paciente.cartaoPrincipal?.convenio.razaoSocial}
        </SimpleText>
      </div>
      <div className="p-col-4 p-sm-3 p-d-flex p-flex-column p-gap-1">
        <SimpleText>Ultimo atendimento</SimpleText>
        <SimpleText fontSize="xxs" fontColor="color_40">
          {paciente?.dataUltimoAtendimento
            ? dayjs(paciente?.dataUltimoAtendimento).format(
                'DD/MM/YYYY [às] HH:mm',
              )
            : '-'}
        </SimpleText>
      </div>
    </Card>
  );
};
