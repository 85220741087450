import {
  Avatar as PrimeAvatar,
  AvatarProps as PrimeAvatarProps,
} from 'primereact/avatar';
import './Avatar.scss';

type AvatarProps = Omit<PrimeAvatarProps, 'size'> & {
  size?: 'small' | 'normal' | 'large';
};

const Avatar = (props: AvatarProps) => {
  const { image, label, icon, ...otherProps } = props;

  let avatarProps = {};

  if (image) {
    avatarProps = { image, ...otherProps };
  } else if (label) {
    avatarProps = { label: label.slice(0, 2), ...otherProps };
  }

  return (
    <PrimeAvatar
      shape="circle"
      {...avatarProps}
      className={`${props.className || ''} ${props.size || 'normal'}`}
      {...(!image && { icon })}
    />
  );
};

export default Avatar;
