import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';

import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { GenogramaAPI } from 'src/APIs/ProntuarioAPI/GenogramaAPI/GenogramaAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import Toast from 'src/components/Basics/Toast/Toast';

interface GenogramaContextProps {
  panelLegend: DisclosureType;
  viewMode: 'nomes' | 'parentesco';
  setViewMode: (mode: 'nomes' | 'parentesco') => void;
  data: IGenoragramaResponse | null;
  isLoading: boolean;
}

const GenogramaContext = createContext<GenogramaContextProps | undefined>(
  undefined,
);

export const GenogramaProvider = ({
  children,
  idPaciente,
}: {
  children: ReactNode;
  idPaciente: number;
}) => {
  const [viewMode, setViewMode] = useState<'nomes' | 'parentesco'>('nomes');
  const [data, setData] = useState<IGenoragramaResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const panelLegend = useDisclosure({ opened: true });

  const { invalidateGenograma } = useAppSelector(state => state.query);
  const dispatch = useDispatch();

  const fetchApi = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await GenogramaAPI.loadGenogramaData(idPaciente);
      setData(response);

      if (!!response.mensagens?.length) {
        toast(<Toast />, {
          data: {
            title: 'Atenção',
            message: response.mensagens.slice(0, 2),
            type: 'warning',
          },
        });
      }
    } catch (e) {
      console.error('Erro ao carregar dados do genograma', e);
      setData(null);
    } finally {
      setIsLoading(false);
    }
  }, [idPaciente]);

  useEffect(() => {
    fetchApi();
  }, [fetchApi]);

  useEffect(() => {
    if (invalidateGenograma) {
      fetchApi();
      dispatch(resetInvalidateQuery());
    }
  }, [dispatch, fetchApi, invalidateGenograma]);

  return (
    <GenogramaContext.Provider
      value={{ panelLegend, viewMode, setViewMode, data, isLoading }}
    >
      {children}
    </GenogramaContext.Provider>
  );
};

export const useGenograma = () => {
  const context = useContext(GenogramaContext);
  if (context === undefined) {
    throw new Error('useGenograma must be used within a GenogramaProvider');
  }
  return context;
};
