import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/personalizar-prontuario-medico';

export const getModelosByCampo = (
  params: {campo: string, idConsultorio: number},
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<ModeloCampoProntuario[]> => {
  return Http.get({
    service: 'pep',
    url: `${basePath}/modelos/pesquisa`,
    params,
    hideToast: true,
    throwError: true,
    ...options,
  });
};

export const limparPadrao = (
  campo: string,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/modelo/limpar-padrao/campo/${campo}`,
    hideToast: true,
    ...options,
  });
};

export const definirModeloPadrao = (
  idModelo: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'pep',
    url: `${basePath}/modelo/${idModelo}/padronizar`,
    hideToast: true,
    ...options,
  });
};

export const alterarModelo = (
  idModelo: number,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/modelo/${idModelo}`,
    data: payload,
    return: true,
    ...options,
  });

export const addNewModelo = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'pep',
    url: `${basePath}/modelo`,
    data: payload,
    return: true,
    ...options,
  });
};

export const getCamposProntuario = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/camposProntuario`,
    return: true,
    hideToast: true,
    ...options,
  });

export const removerModelo = (
  idModelo: number,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.delete({
    service: 'pep',
    url: `${basePath}/modelo/${idModelo}`,
    return: true,
    ...options,
  });

export const getCampos = (
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.get({
    service: 'pep',
    url: `${basePath}/campos`,
    return: true,
    hideToast: true,
    ...options,
  });

export const atualizarExibicaoCampo = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> =>
  Http.put({
    service: 'pep',
    url: `${basePath}/campos/atualiza-exibicao`,
    data: payload,
    hideToast: true,
    return: true,
    ...options,
  });

const PersonalizarProntuarioAPI = {
  getModelosByCampo,
  definirModeloPadrao,
  addNewModelo,
  getCamposProntuario,
  alterarModelo,
  removerModelo,
  getCampos,
  atualizarExibicaoCampo,
  limparPadrao,
};

export default PersonalizarProntuarioAPI;
