import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  convenio: Yup.object().required('Convenio é obrigatório'),
  periodoDe: Yup.date()
    .required('Periodo inicial é obrigatório')
    .typeError('Periodo inicial é obrigatório'),
  periodoAte: Yup.date()
    .required('Periodo final é obrigatório')
    .typeError('Periodo final é obrigatório'),
  profissionalSaude: Yup.object()
    .required('Profissional de saúde é obrigatório')
    .typeError('Profissional de saúde é obrigatório'),
});
