import React, { useRef } from 'react';

import { Node, NodeProps } from '@xyflow/react';
import { OverlayPanel } from 'primereact/overlaypanel';

import dayjs from 'dayjs';

import { NodeVariant } from '../../utils';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import './style.scss';

export type CustomNodeDataProps = {
  variant?: NodeVariant;
  idFamiliar: string;
  idIrmaoGemeo?: string | null;
  idMae?: string | null;
  idPai?: string | null;
  label?: string;
  isPatient?: boolean;
  handlers?: JSX.Element[];
  filiacao?: string;
  dates?: {
    birth: string;
    death?: string | null;
  };
  cids?: any[];
};

type CustomNodeProps = Node<CustomNodeDataProps, 'props'>;

export const NodePaciente = (props: NodeProps<CustomNodeProps>) => {
  const { label, variant, isPatient, dates, handlers, cids = [] } = props.data;

  const modalCid = useRef<OverlayPanel>(null);

  const containerLabelType = isPatient ? 'paciente' : 'familiar';

  return (
    <>
      {handlers?.length &&
        handlers.map((handler, index) => (
          <React.Fragment key={index}>{handler}</React.Fragment>
        ))}

      <div className="node-container">
        <span data-type={variant} data-main={!!isPatient} />

        <div className="node-content-info p-d-flex p-gap-1 p-ai-center p-jc-center">
          <i className="fas fa-star" />
          <p>{dates?.birth ? dayjs(dates.birth).format('YYYY') : '...'} -</p>
          <p>{dates?.death ? dayjs(dates.death).format('YYYY') : '...'}</p>
          <i className="fas fa-cross" />
        </div>

        <div
          className="tag-genograma-container"
          data-type={containerLabelType}
          data-cid={!!cids?.length}
          onClick={e => (!!cids?.length ? modalCid.current?.toggle(e) : null)}
        >
          <p>{label}</p>
        </div>
      </div>

      <OverlayPanel ref={modalCid} id="modal-cid-genograma">
        <div className="p-d-flex p-flex-column p-gap-1">
          <SimpleText className="p-w-100 p-pb-2" medium>
            CID
          </SimpleText>

          {!!cids?.length &&
            cids.map((cid, index) => (
              <div
                className="row content-row p-col-12 p-gap-2 p-ai-center"
                key={index}
              >
                <span className="tag-cid">{cid.codigo}</span>
                <SimpleText className="p-text-truncate">{cid.nome}</SimpleText>
              </div>
            ))}
        </div>
      </OverlayPanel>
    </>
  );
};

//* Gestation Node
export const NodeConnection = (props: NodeProps<CustomNodeProps>) => {
  const { handlers } = props.data;

  return (
    <>
      {handlers?.length &&
        handlers.map((handler, index) => (
          <React.Fragment key={index}>{handler}</React.Fragment>
        ))}

      <div
        style={{
          width: '1px',
          height: '1px',
          borderRadius: '50%',
          backgroundColor: '#D4D4D4',
        }}
      ></div>
    </>
  );
};
