import { useState, useCallback, useRef, useEffect, useMemo } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { Control, useController } from 'react-hook-form';

import ComorbidadeAPI from 'src/APIs/ProntuarioAPI/ComorbidadeAPI/ComorbidadeAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextInputControlled from 'src/components/Basics/TextInputControlled/TextInputControlled';
import TextInputSearch from 'src/components/Basics/TextInputSearch/TextInputSearch';
import InfiniteScroll from 'src/pages/Emed/Prontuario/components/InfiniteScroll/InfiniteScroll';

import './DropdownCid.scss';
import Row from './Row';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

interface DropdownCidProps {
  control: Control;
  name: string;
  handleAddCids: (cid: any) => void;
  label?: string;
  className?: string;
  searchInput?: boolean;
}

const DropdownCid = ({
  control,
  name,
  handleAddCids,
  label,
  className,
  searchInput = false,
}: DropdownCidProps) => {
  const searchPanelRef = useRef<OverlayPanel>(null);
  const searchInputContainerRef = useRef<HTMLDivElement>(null);
  const searchPanelContainerRef = useRef<HTMLDivElement>(null);

  const { agenda } = useAppSelector((state: RootState) => state);

  const {
    field: { value },
    formState: { errors },
  } = useController({
    name,
    control,
  });

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [cidSelecionada, setCidSelecionada] = useState<any>(null);
  const [favoritos, setFavoritos] = useState<any[]>([]);
  const [cidsList, setCidsList] = useState<any[]>([]);

  useEffect(() => {
    function handleClickOutsideSearchInput(event: any) {
      const clickedOnInput =
        searchInputContainerRef.current &&
        searchInputContainerRef.current.contains(event.target);

      const clickedOnPanel =
        searchPanelContainerRef.current &&
        searchPanelContainerRef.current.contains(event.target);

      if (!clickedOnInput && !clickedOnPanel) {
        searchPanelRef.current?.hide();
      }
    }

    document.addEventListener('mousedown', handleClickOutsideSearchInput);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchInput);
    };
  }, [searchPanelRef]);

  const loadFavoritos = useCallback(async () => {
    const response = await ComorbidadeAPI.getFavoritos(
      agenda?.profissionalAtivo?.id,
    );

    if (Array.isArray(response) && !!response.length) setFavoritos(response);
  }, [agenda?.profissionalAtivo?.id]);

  useEffect(() => {
    loadFavoritos();
  }, [loadFavoritos]);

  const loadCids = useCallback(
    async (params: any) => {
      return await UtilsAPI.getCids({
        nome: searchQuery,
        codigo: searchQuery,
        ...params,
      });
    },
    [searchQuery],
  );

  const fetchAPI = useCallback(
    (params: any) => {
      if (searchQuery?.length > 0) return loadCids(params);

      return { list: [] };
    },
    [loadCids, searchQuery],
  );

  const capitalizeFirstLetter = (text: string) => {
    return text?.charAt(0).toUpperCase() + text?.slice(1);
  };

  const onSelectValue = async (cid: any) => {
    handleAddCids(cid);
  };

  const handleFavoritar = async (cid: any) => {
    const response = await ComorbidadeAPI.favoritarComorbidade(
      agenda?.profissionalAtivo?.id,
      { idCid: cid.id },
    );

    if (response?.status === 200) loadFavoritos();
  };

  useEffect(() => {
    setCidSelecionada(value?.value?.[0]?.[value.name] || null);
  }, [value]);

  const cidsFavoritos = useMemo(
    () =>
      favoritos
        .filter(favorito => !!favorito?.idCid)
        .map(favorito => favorito.cid.nome),
    [favoritos],
  );

  const renderRow = (cid: any) => {
    const favoritado = cidsFavoritos.includes(cid.nome);

    return (
      <Row
        favoritado={favoritado}
        onSelectValue={onSelectValue}
        cid={cid}
        handleFavoritar={handleFavoritar}
      />
    );
  };

  if (!searchInput) {
    return (
      <DropdownControlled
        control={control}
        name={name || 'cid'}
        label={label || 'CID'}
        options={cidsList || []}
        placeholder="Selecione o CID"
        className={className || ''}
      />
    );
  }

  return (
    <div className="p-w-100">
      <div ref={searchInputContainerRef} className="p-grid">
        <div className="p-col-11 p-pl-0 p-pr-3">
          <TextInputSearch
            placeholder="Pesquisar CID"
            value={searchQuery}
            updateValue={cidSelecionada?.nome || ''}
            onChange={setSearchQuery}
            onKeyPress={e => {
              if (e.key === 'Enter' && searchQuery.length > 1)
                searchPanelRef.current?.show(e, null);
            }}
          />
        </div>
        <div className="p-col-1 p-pr-0 p-pl-3 p-d-flex p-jc-end">
          <IconButton
            btnType={IconBtnTypes.GREEN}
            icon="pi pi-search"
            className="p-button-rounded p-button-outlined search-btn"
            type="button"
            onClick={e => {
              if (searchQuery.length > 1) searchPanelRef.current?.show(e, null);
            }}
          />
        </div>
      </div>

      <TextInputControlled control={control} name="cid" hidden />

      {errors?.cid?.message && (
        <SimpleText className="error p-col-12" fontSize={FONT_SIZE.XXXS}>
          {errors?.cid?.message}
        </SimpleText>
      )}

      <OverlayPanel
        className="cid-search-panel"
        ref={searchPanelRef}
        dismissable={false}
      >
        <div className="p-grid" ref={searchPanelContainerRef}>
          <div id="search-results-container" className="p-col-12">
            <InfiniteScroll fetchAPI={fetchAPI} renderRow={renderRow} />
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default DropdownCid;
