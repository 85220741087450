import { useState } from 'react';

import { MonitoramentoNotificacoesAPI } from 'src/APIs/ReportAPI/RelatorioVigilanciaAPI/MonitoramentoNotificacoesAPI/MonitoramentoNotificacoesAPI';

import { Button } from 'src/components/_UI';
import { Checkbox } from 'src/components/_UI/Checkbox';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

interface CheckboxCounterProps {
  selectedAgravo: IAgravoNotificacao[];
  setSelectedAgravo: (selectedAgravo: any) => void;
  reloadList: () => void;
}

const CheckboxCounter = ({
  selectedAgravo,
  setSelectedAgravo,
  reloadList,
}: CheckboxCounterProps) => {
  const [loading, setLoading] = useState(false);
  const { listItems } = useSearchList();

  const onNotificarSelecionados = async () => {
    try {
      setLoading(true);
      const ids = selectedAgravo.map(item => item.id);
      await MonitoramentoNotificacoesAPI.putMonitoramentoNotificacoes(
        ids,
        true,
      );
      reloadList();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setSelectedAgravo([]);
    }
  };

  return (
    <div className="p-d-flex p-gap-3 p-ai-center p-p-2">
      <Checkbox
        label={
          selectedAgravo?.length === listItems.length
            ? 'Todos marcados'
            : `${selectedAgravo?.length || 'Nenhum'} marcado${
                selectedAgravo?.length > 1 ? 's' : ''
              }`
        }
        checked={selectedAgravo?.length > 0}
        onChange={() => {
          if (selectedAgravo?.length > 0) {
            setSelectedAgravo([]);
          }
        }}
        disabled={selectedAgravo?.length === 0}
      />
      <Button
        type="button"
        label={`Notificar ${
          selectedAgravo?.length === listItems.length
            ? 'Todos'
            : selectedAgravo?.length || 0
        }`}
        disabled={selectedAgravo?.length === 0}
        onClick={() => onNotificarSelecionados()}
        checkPermission={''}
        icon="pi pi-check"
        loading={loading}
      />
    </div>
  );
};

export default CheckboxCounter;
