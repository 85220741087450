import { Handle, Position } from '@xyflow/react';

import { capitalizeFirstLetter } from 'src/utils/utils';

export type NodeVariant =
  | 'MEN'
  | 'WOMEN'
  | 'PREGNANT'
  | 'ABORTION'
  | 'DEATH-MEN'
  | 'DEATH-ADOPTED-IN-MEN'
  | 'DEATH-ADOPTED-OUT-MEN'
  | 'DEATH-WOMEN'
  | 'DEATH-ADOPTED-IN-WOMEN'
  | 'DEATH-ADOPTED-OUT-WOMEN'
  | 'DEATH'
  | 'ADOPTED-IN-MEN'
  | 'ADOPTED-OUT-MEN'
  | 'ADOPTED-IN-WOMEN'
  | 'ADOPTED-OUT-WOMEN'
  | 'EXTERNAL';

export const layersResponse: { [key: string]: string } = {
  '2': 'A',
  '1': 'B',
  '0': 'C',
  '-1': 'D',
  '-2': 'E',
};

const genderEnum: { [key: string]: string } = {
  1: 'WOMEN',
  3: 'MEN',
};

export const calculateConnectorY = (layer: string) => {
  switch (layer) {
    case 'A':
      return 0;
    case 'B': // 300
      return 50 * 6;
    case 'C': // 600
      return 50 * 12;
    case 'D': // 900
      return 50 * 18;
    case 'E': // 1200
      return 50 * 24;
    default:
      return -150;
  }
};

export const calculateConnectorYGestation = (layer: string) => {
  switch (layer) {
    case 'A': //* Assuming there will be no gestation in layer "A"
      return -150;
    case 'B': // 300
      return 50 * 3;
    case 'C': // 600
      return 50 * 9;
    case 'D': // 900
      return 50 * 15;
    case 'E': // 1200
      return 50 * 21;
    default:
      return -150;
  }
};

export const calculateConnectorX = (
  totalConnectors: number,
  index: number,
  previousArrFamilyLength: number,
) => {
  let nodeX = 0;

  if (totalConnectors <= 2) {
    nodeX = 240;
    return index === 0 ? nodeX : nodeX * 2;
  }

  //* 180 refers to the gap between nodes
  nodeX = index * 180;

  return nodeX;
};

export const getHandlers = (
  item: IGenogramaFamiliar,
  layer: string,
  nodes: IGenogramaFamiliar[],
) => {
  const isTwins = item?.idIrmaoGemeo;
  const hasTwin =
    item.idFamiliar && nodes.find(n => n.idIrmaoGemeo === item.idFamiliar);

  let handlers = [
    <Handle position={Position.Top} id="top" type="source" key="top" />,
    <Handle
      position={Position.Bottom}
      id="bottom"
      type="source"
      key="bottom"
    />,
  ];

  if (isTwins) {
    handlers.push(
      <Handle position={Position.Right} id="right" type="source" key="right" />,
    );
  }
  if (hasTwin) {
    handlers.push(
      <Handle position={Position.Left} id="left" type="target" key="left" />,
    );
  }

  if (layer === 'A') {
    handlers = handlers.filter(h => h.props.id !== 'top');
  }

  return { handlers };
};

export const getCustomNodeVariant = (item: any): NodeVariant => {
  const { gestacao, aborto, dataObito, filiacao, sexo } = item || {};
  const gender = sexo && genderEnum[sexo.id];
  const adoptedIn = filiacao === 'ADOTADO_DENTRO';
  const adoptedOut = filiacao === 'ADOTADO_FORA';

  if (aborto) return 'ABORTION';
  if (gestacao) return 'PREGNANT';

  if (dataObito) {
    if (gender === 'MEN') {
      if (adoptedIn) return 'DEATH-ADOPTED-IN-MEN';
      if (adoptedOut) return 'DEATH-ADOPTED-OUT-MEN';
      return 'DEATH-MEN';
    }
    if (gender === 'WOMEN') {
      if (adoptedIn) return 'DEATH-ADOPTED-IN-WOMEN';
      if (adoptedOut) return 'DEATH-ADOPTED-OUT-WOMEN';
      return 'DEATH-WOMEN';
    }
    return 'DEATH';
  }

  if (adoptedIn)
    return gender === 'MEN' ? 'ADOPTED-IN-MEN' : 'ADOPTED-IN-WOMEN';
  if (adoptedOut)
    return gender === 'MEN' ? 'ADOPTED-OUT-MEN' : 'ADOPTED-OUT-WOMEN';

  if (gender === 'MEN') return 'MEN';
  if (gender === 'WOMEN') return 'WOMEN';

  return 'EXTERNAL';
};

export const formatVinculoFamiliar = (vinculo: string | null) => {
  if (!vinculo) return '';

  return vinculo
    .split('_')
    .map((word: string) => capitalizeFirstLetter(word))
    .join(' ');
};

export const formatFamiliarNome = (name: string) => {
  const nameParts = name.split(' ');

  if (nameParts.length > 3) {
    return `${nameParts[0]} ... ${nameParts[nameParts.length - 1]}`;
  }

  return name;
};