import { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI';

import { useGenograma } from '../../_hooks/useGenograma';

import './style.scss';

export const PanelLegend = () => {
  const {
    panelLegend: { isOpen, toggle },
    viewMode,
    setViewMode,
  } = useGenograma();

  const popup = useRef<any>(null);

  return (
    <div className="genograma-panel p-d-flex" data-expanded={isOpen}>
      <div className="panel-content p-ai-center" data-expanded={isOpen}>
        <Button
          label="Legenda"
          icon={isOpen ? 'fa-solid fa-angles-right' : 'fa-solid fa-angles-left'}
          iconPos={isOpen ? 'right' : 'left'}
          btnType="ghost"
          onClick={() => toggle()}
        />

        <Button
          btnType="tonal-success"
          label={capitalizeFirstLetter(viewMode)}
          icon="fas fa-chevron-down"
          iconPos="right"
          onClick={e => popup.current?.toggle(e)}
        />

        <TieredMenu
          ref={popup}
          popup
          style={{
            width: 'fit-content',
          }}
          model={[
            {
              label: 'Nomes',
              command: () => [setViewMode('nomes'), popup.current?.hide()],
            },
            {
              label: 'Parentesco',
              command: () => [setViewMode('parentesco'), popup.current?.hide()],
            },
          ]}
        />

        {isOpen && (
          <>
            <span className="tag-info-success">Sem CID</span>
            <span className="tag-info-success cid">Com CID</span>

            <span className="leg leg-paciente">Paciente</span>
            <span className="leg leg-masculino">Masculino</span>
            <span className="leg leg-feminino">Feminino</span>
            <span className="leg leg-gravidez">Gravidez</span>
            <span className="leg leg-aborto">Aborto</span>
            <span className="leg leg-morte">Morte</span>
            <span className="leg leg-adotado-out">Adotado fora</span>
            <span className="leg leg-adotado-in">Adotado dentro</span>
          </>
        )}
      </div>
    </div>
  );
};
