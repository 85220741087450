import { useMemo } from 'react';

import { useNavigate } from 'react-router';

import { ContasPagarAPI } from 'src/APIs/FinanceiroAPI/ContasPagarAPI/ContasPagarAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { checkAuth } from 'src/utils/utils';

import { useSelectedContaPagar } from '../../useSelectedContaPagar';

import DesktopRow from './DesktopRow';
import MobileRow from './MobileRow';

interface Row {
  item: IContaPagarItem;
}

const Row = ({ item }: Row) => {
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);
  const { reloadList } = useSelectedContaPagar();

  const navigate = useNavigate();

  const { windowInnerWidth } = useSize();

  const renderMobile = windowInnerWidth <= 1024;

  const onEdit = () => {
    navigate(`/faturamento/contas-pagar/${item.id}`);
  };

  const onDelete = async () => {
    try {
      await ContasPagarAPI.deleteContaPagar(item.id);
      reloadList();
    } catch (error) {
      console.error(error);
    }
  };

  const actionItems = [
    {
      label: 'Editar',
      command: onEdit,
      checkPermission: 'ADM_CONTAS_PAGAR_ALTERAR',
    },
    {
      label: 'Excluir',
      command: onDelete,
      checkPermission: 'ADM_CONTAS_PAGAR_EXCLUIR',
      className: 'error',
    },
  ];

  const authorizedActions = actionItems.filter(item => {
    return item?.checkPermission
      ? checkAuth(authorities, item?.checkPermission)
      : true;
  });

  const chipStatus =
    item.status === 'PAGA'
      ? 'Paga'
      : item.status === 'A_PAGAR'
      ? 'A Pagar'
      : 'Parc. Paga';

  const commonProps = {
    item,
    authorizedActions,
    chipStatus,
  };

  if (renderMobile) {
    return <MobileRow {...commonProps} />;
  }

  return <DesktopRow {...commonProps} />;
};

export default Row;
