import { useEffect, useState } from 'react';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { ParametrizacaoAgravoAPI } from 'src/APIs/ReportAPI/RelatorioVigilanciaAPI/ParametrizacaoAgravoAPI/ParametrizacaoAgravoAPI';
import * as Yup from 'yup';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import FieldArrayCidSelect from 'src/components/Fields/FieldArrayCidSelect/FieldarrayCidSelect';
import FormInput from 'src/components/FormInput/FormInput';
import Skeleton from 'src/components/Skeleton/Skeleton';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

const autoridadesSaude = [
  { label: 'Ministério da Saúde', value: 'MINISTERIO_SAUDE' },
  {
    label: 'Secretaria estadual de saúde',
    value: 'SECRETARIA_ESTADUAL',
  },
  {
    label: 'Secretaria municipal de saúde',
    value: 'SECRETARIA_MUNICIPAL',
  },
];

const periodicidades = [
  { label: 'IMEDIATA', value: 'IMEDIATA' },
  { label: 'SEMANAL', value: 'SEMANAL' },
];

interface DialogAdicionarAgravoProps extends DisclosureType {
  reloadList: () => void;
}

const DialogAdicionarAgravo = ({
  state,
  close,
  reloadList,
}: DialogAdicionarAgravoProps) => {
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    nome: Yup.string().required('Nome do agravo é obrigatório'),
    autoridadeSaude: Yup.string().required('Autoridade de saúde é obrigatória'),
    periodicidade: Yup.string().required('Periodicidade é obrigatória'),
    cids: Yup.array().min(1, 'Selecione ao menos um CID'),
  });

  const form = useForm<IParamAgravoItem>({
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'cids',
  });

  const onAddCid = (cid: any) => {
    if (!cid || fields.find((field: any) => field.codigo === cid.codigo))
      return;
    append(cid);
  };

  const onRemoveCid = (index: number) => {
    remove(index);
  };

  const onSubmit = async (data: IParamAgravoItem) => {
    const { cids, status, ...rest } = data;

    const cidsIds = cids.map((cid: any) => ({
      idCid: cid.idCid || cid.id,
    }));

    const payload = {
      ...(state?.id && { id: state?.id }),
      ...rest,
      status: status === 'ATIVO' ? 'ATIVO' : 'INATIVO',
      cids: cidsIds,
    };
    try {
      state?.id
        ? await ParametrizacaoAgravoAPI.putAgravo(payload)
        : await ParametrizacaoAgravoAPI.postAgravo(payload);
      reloadList();
      close();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchAgravo = async (id: number) => {
      try {
        setLoading(true);
        const response = await ParametrizacaoAgravoAPI.getAgravoById(id);
        reset({
          nome: response.nome || '',
          autoridadeSaude: response.autoridadeSaude || '',
          periodicidade: response.periodicidade || '',
          status: response.status === 'ATIVO' ? 'ATIVO' : 'INATIVO',
          cids: response.cids || [],
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (state && state?.id) {
      fetchAgravo(state?.id);
    }
  }, [reset, state]);

  return (
    <Dialog
      onHide={close}
      visible
      header={state ? 'Editar agravo' : 'Adicionar agravo'}
      style={{ width: '950px' }}
      maximizedMobileSize
    >
      <FormProvider {...form}>
        {loading ? (
          <Skeleton height="400px" width="950px" loading />
        ) : (
          <form
            className="br-8px border-88 p-grid p-py-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormInput
              name="nome"
              label="Nome do agravo"
              placeholder="Digite o nome do agravo"
              className="p-col-12"
              errorMsg={errors.nome?.message}
            />

            <DropdownControlled
              control={control}
              name="autoridadeSaude"
              label="Autoridade de saúde"
              options={autoridadesSaude}
              placeholder="Selecione a autoridade de saúde"
              className="p-col-12 p-sm-6"
              errorMsg={errors.autoridadeSaude?.message}
            />

            <DropdownControlled
              control={control}
              name="periodicidade"
              label="Periodicidade"
              options={periodicidades}
              placeholder="Selecione a periodicidade"
              className="p-col-12 p-sm-6"
              errorMsg={errors.periodicidade?.message}
            />

            <FieldArrayCidSelect onSelectCid={onAddCid} selected={fields} />

            <div className="p-d-flex p-flex-column p-gap-2 p-w-100 p-px-2">
              {fields?.map((field: any, index: number) => (
                <div
                  key={field.id}
                  className="p-d-flex p-ai-center p-jc-between br-8px bg-96 p-w-100 p-p-2"
                >
                  <SimpleText medium>
                    {`(${field.codigo || field.codigoCid}) ${
                      field.nome || field.nomeCid
                    }`}
                  </SimpleText>
                  <Button
                    icon="fas fa-trash"
                    btnType="gray"
                    onClick={() => onRemoveCid(index)}
                  />
                </div>
              ))}
              {fields?.length === 0 && (
                <AlertBox text="Selecione ao menos um CID" visible={true} />
              )}
            </div>
            <div className="p-d-flex p-flex-column p-col-12 p-gap-2">
              <SimpleText medium>Status</SimpleText>
              <SwitchControlled
                control={control}
                name="status"
                className="p-col-12"
                checkedLabel="Ativado"
                uncheckedLabel="Desativado"
                trueValue={'ATIVO'}
                showStatus
              />
            </div>

            <div className="p-d-flex p-col-12 p-gap-2">
              <Button
                label="Cancelar"
                btnType="ghost"
                onClick={() => close()}
              />
              <Button
                label="Salvar"
                type="submit"
                stretch
                loading={isSubmitting}
                disabled={fields?.length === 0}
              />
            </div>
          </form>
        )}
      </FormProvider>
    </Dialog>
  );
};

export default DialogAdicionarAgravo;
