import Page from 'src/components/Basics/Page/Page';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import ValoresServicoContent from './ValoresServicoContent/ValoresServicoContent';
import './ValoresServico.scss';

const ValoresServico = () => {
  return (
    <Page white className={'ValoresServico p-px-0'}>
      <>
        <SimpleText className="p-m-0 p-py-0" fontSize="md" medium>
          Valores de serviço
        </SimpleText>
        <ValoresServicoContent />
      </>
    </Page>
  );
};

export default ValoresServico;
