export function formatPhoneNumber(number?: string) {
  if (!number) return 'Não informado';
  const cleanedNumber = number.replace(/\D/g, '');
  if (cleanedNumber.length === 11) {
    return cleanedNumber.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }
  if (cleanedNumber.length === 10) {
    return cleanedNumber.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }
  return cleanedNumber;
}

export enum FILTROS_ATENDIMENTOS_AGENDA {
  AGENDADO = 'AGENDADO',
  AGENDADO_INTERNET = 'AGENDADO_INTERNET',
  ATENDENDO = 'ATENDENDO',
  ATENDIDO = 'ATENDIDO',
  ATRASADO = 'ATRASADO',
  ATUALIZAR_GUIA = 'ATUALIZAR_GUIA',
  ATUALIZAR = 'ATUALIZAR',
  AUTORIZAR = 'AUTORIZAR',
  BLOQUEADO_AGENDA = 'BLOQUEADO_AGENDA',
  BLOQUEADO_PACIENTE = 'BLOQUEADO_PACIENTE',
  CANCELADO_SMS = 'CANCELADO_SMS',
  CANCELOU = 'CANCELOU',
  COMPROMISSO = 'COMPROMISSO',
  CONFIRMADO = 'CONFIRMADO',
}

export enum FILTROS_PERIODO_AGENDA {
  MANHA = 'MANHA',
  TARDE = 'TARDE',
}

export const turno = [
  { value: 'MANHA', label: 'Manhã' },
  { value: 'TARDE', label: 'Tarde' },
  { value: 'NOITE', label: 'Noite' },
];

export const tipoEspera = [
  { value: 'TODOS', label: 'Todos' },
  { value: 'PRIMEIRA_CONSULTA', label: 'Primeira consulta' },
  { value: 'SEGUIMENTO', label: 'Seguimento' },
];

export const statusListaEsperaFilter = [
  {
    label: 'Agendado',
    isActive: true,
    value: FILTROS_ATENDIMENTOS_AGENDA.AGENDADO,
  },
  {
    label: 'Agendado Internet',
    isActive: true,
    value: FILTROS_ATENDIMENTOS_AGENDA.AGENDADO_INTERNET,
  },
  {
    label: 'Atendendo',
    isActive: true,
    value: FILTROS_ATENDIMENTOS_AGENDA.ATENDENDO,
  },
  {
    label: 'Atrasado',
    isActive: true,
    value: FILTROS_ATENDIMENTOS_AGENDA.ATRASADO,
  },
  {
    label: 'Atualizar Guia',
    isActive: true,
    value: FILTROS_ATENDIMENTOS_AGENDA.ATUALIZAR_GUIA,
  },
  {
    label: 'Atualizar',
    isActive: true,
    value: FILTROS_ATENDIMENTOS_AGENDA.ATUALIZAR,
  },
  {
    label: 'Autorizar',
    isActive: true,
    value: FILTROS_ATENDIMENTOS_AGENDA.AUTORIZAR,
  },
  {
    label: 'Bloqueado Agenda',
    isActive: true,
    value: FILTROS_ATENDIMENTOS_AGENDA.BLOQUEADO_AGENDA,
  },
  {
    label: 'Bloqueado Paciente',
    isActive: true,
    value: FILTROS_ATENDIMENTOS_AGENDA.BLOQUEADO_PACIENTE,
  },
  {
    label: 'Cancelado SMS',
    isActive: true,
    value: FILTROS_ATENDIMENTOS_AGENDA.CANCELADO_SMS,
  },
  {
    label: 'Cancelou',
    isActive: true,
    value: FILTROS_ATENDIMENTOS_AGENDA.CANCELOU,
  },
  {
    label: 'Compromisso',
    isActive: true,
    value: FILTROS_ATENDIMENTOS_AGENDA.COMPROMISSO,
  },
  {
    label: 'Confirmado',
    isActive: true,
    value: FILTROS_ATENDIMENTOS_AGENDA.CONFIRMADO,
  },
];
