import React, { useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import Skeleton from 'src/components/Skeleton/Skeleton';

interface Props {
  className?: string;
  idConsultorioEdit?: number | undefined;
}

const ConsultorioDropdown = ({ className, idConsultorioEdit }: Props) => {
  const [consultorios, setConsultorios] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    (async () => {
      setLoading(true);
      await ConsultorioAPI.loadConsultorios({
        nome: '',
        pageSize: 9999,
      })
        .then((response: any) => {
          setConsultorios(response.list);
          if (response.list.length === 1) {
            setValue('idConsultorio', response.list[0].id);
          }
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [setValue]);

  useEffect(() => {
    if (!loading && idConsultorioEdit) {
      setValue('idConsultorio', idConsultorioEdit);
    }
  }, [idConsultorioEdit, loading, setValue]);

  return (
    <Skeleton height="30px" loading={loading}>
      <DropdownControlled
        control={control}
        label="Consultório"
        name="idConsultorio"
        options={consultorios}
        placeholder="Selecione"
        optionLabel="nome"
        optionValue="id"
        errorMsg={errors.idConsultorio?.message}
        className={className || ''}
      />
    </Skeleton>
  );
};

export default ConsultorioDropdown;
