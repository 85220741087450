import { useCallback, useEffect, useState } from 'react';

import { DashboardAPI } from 'src/APIs/AdminAPI/DashboardAPI/DashboardAPI';

import { cn } from 'src/utils/cn';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

export const IndicadoresSistema = ({
  className,
  type = 1,
}: {
  className?: string;
  type: 1 | 2;
}) => {
  const [indicador, setIndicador] = useState<IDashboardUsuariosResponse>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchIndicadores = useCallback(async () => {
    try {
      const response = await DashboardAPI.loadIndicadorUsuarios({
        throwError: true,
      });
      return setIndicador(response);
    } catch {
      return null;
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchIndicadores();
  }, [fetchIndicadores]);

  return (
    <div
      className={cn(
        'row content-row p-flex-column white p-p-3 p-jc-between',
        className,
      )}
      style={{
        height: '280px',
        border: '1px solid var(--neutral-color-grayscale-88)',
      }}
    >
      {type === 1 && (
        <div
          className="p-col-12 row content-row p-jc-center p-ai-center p-gap-2 p-p-4"
          style={{
            height: '48%',
          }}
        >
          <Skeleton loading={isLoading} width="40px" height="30px">
            <SimpleText fontColor="color_60" fontSize="xxxl" medium>
              {indicador?.administradores}
            </SimpleText>
          </Skeleton>

          <div className="p-d-flex p-flex-column p-gap-1">
            <SimpleText fontSize="sm" medium>
              Administradores
            </SimpleText>
            <SimpleText fontColor="color_40">
              Na plataforma <strong>eMed</strong>
            </SimpleText>
          </div>
        </div>
      )}

      {type === 1 && (
        <div
          className="p-col-12 row content-row p-jc-center p-ai-center p-gap-2 p-p-4"
          style={{
            height: '48%',
          }}
        >
          <Skeleton loading={isLoading} width="40px" height="30px">
            <SimpleText fontColor="color_60" fontSize="xxxl" medium>
              {indicador?.profissionaisSaude}
            </SimpleText>
          </Skeleton>
          <div className="p-d-flex p-flex-column p-gap-1">
            <SimpleText fontSize="sm" medium>
              Profissionais de saúde
            </SimpleText>
            <SimpleText fontColor="color_40">
              Na plataforma <strong>eMed</strong>
            </SimpleText>
          </div>
        </div>
      )}

      {type === 2 && (
        <div
          className="p-col-12 row content-row p-jc-center p-ai-center p-gap-2 p-p-4"
          style={{
            height: '48%',
          }}
        >
          <Skeleton loading={isLoading} width="40px" height="30px">
            <SimpleText fontColor="color_60" fontSize="xxxl" medium>
              {indicador?.secretariados}
            </SimpleText>
          </Skeleton>
          <div className="p-d-flex p-flex-column p-gap-1">
            <SimpleText fontSize="sm" medium>
              Secretariados
            </SimpleText>
            <SimpleText fontColor="color_40">
              Na plataforma <strong>eMed</strong>
            </SimpleText>
          </div>
        </div>
      )}

      {type === 2 && (
        <div
          className="p-col-12 row content-row p-jc-center p-ai-center p-gap-2 p-p-4"
          style={{
            height: '48%',
          }}
        >
          <Skeleton loading={isLoading} width="40px" height="30px">
            <SimpleText fontColor="color_60" fontSize="xxxl" medium>
              {indicador?.ativosGeral}
            </SimpleText>
          </Skeleton>
          <div className="p-d-flex p-flex-column p-gap-1">
            <SimpleText fontSize="sm" medium>
              Usuários ativos
            </SimpleText>
            <SimpleText fontColor="color_40">
              Na plataforma <strong>eMed</strong>
            </SimpleText>
          </div>
        </div>
      )}
    </div>
  );
};
