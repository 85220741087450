import {
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useLocation, useParams } from 'react-router';


import ThemeContext from 'src/core/themes/ThemeContext';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Timeline, {
  TimelineImperativeAPI,
  TimelineValue,
} from 'src/components/Timeline/Timeline';

import FormMessage from './FormsComunicado/FormMessage/FormMessage';
import FormRecipient from './FormsComunicado/FormRecipient/FormRecipient';

import './Comunicado.scss';

export const ComunicadosMarketingMedico = () => {
  const { theme } = useContext(ThemeContext);
  const { id } = useParams();

  const timelineRef = useRef<TimelineImperativeAPI>(null);
  const comunicado = useLocation().state;

  const [step1, setStep1] = useState<any>({
    titulo: comunicado?.titulo,
    mensagem: comunicado?.mensagem,
    dataInicio: comunicado?.dataInicio,
    dataFim: comunicado?.dataFim,
    linkExterno: comunicado?.linkExterno,
    textoLink: comunicado?.textoLink,
    flagLinkImagem: comunicado?.flagLinkImagem,
  });

  const handleBack = () => {
    if (timelineRef && timelineRef.current && timelineRef.current.previous)
      timelineRef.current?.previous();
  };

  const steps = useMemo(
    (): TimelineValue[] => [
      {
        title: 'Mensagem',
        component: (
          <FormMessage
            values={{ ...step1 }}
            onSubmit={(values: any) => {
              setStep1(values);
              if (
                timelineRef &&
                timelineRef.current &&
                timelineRef.current.next
              ) {
                timelineRef.current?.next();
              }
            }}
          />
        ),
      },
      {
        title: 'Destinatário',
        disabled: !step1,
        component: (
          <FormRecipient
            handleBack={handleBack}
            comunicado={comunicado}
            values={{ ...step1 }}
          />
        ),
      },
    ],
    [comunicado, step1],
  );


  return (
    <Page className={`CadastroComunicado ${theme}`}>
      <>
        <PageHeader
          title={id ? 'Editar comunicado' : 'Novo comunicado'}
          subtitle={
            id
              ? 'Edite os campos desejados'
              : 'Envie uma mensagem para os pacientes'
          }
        />

        <Page white content>
          <div className={'p-xl-7 p-lg-9 p-md-11 p-col-12'}>
            <Timeline values={steps} ref={timelineRef} />

         
          </div>
        </Page>
      </>
    </Page>
  );
};
