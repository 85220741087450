import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    dataVencimento: Yup.date().required('Data de vencimento é obrigatória').typeError('Campo obrigatório'),
    idFornecedor: Yup.string().required('Credor é obrigatório').typeError('Campo obrigatório'),
    idConsultorio: Yup.number().required('Consultório é obrigatório').typeError('Campo obrigatório'),
    idCentroCusto: Yup.number().required('Centro de custo é obrigatório').typeError('Campo obrigatório'),
    idTipoContaPagar: Yup.number().required('Tipo é obrigatório').typeError('Campo obrigatório'),
    valor: Yup.number().min(0.1, 'O valor deve ser maior que 0').required('Valor é obrigatório').typeError('Campo obrigatório'),
    status: Yup.string().required('Status é obrigatório').typeError('Campo obrigatório'),
    valorPago: Yup.number()
    .when('status', {
      is: 'PARCIALMENTE_PAGA',
      then: Yup.number()
        .lessThan(Yup.ref('valor'), 'Para a opção "Parcialmente pago", o valor do campo "Valor pago" deve ser menor que o valor do campo "Valor".')
        .required('Valor pago é obrigatório para status "Parcialmente pago"'),
      otherwise: Yup.number().nullable(),
    }),
});

export default validationSchema;
