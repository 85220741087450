import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Switch from 'src/components/Switch/Switch';

interface DesktopRowProps {
  data: any;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
  onSwitchStatus: (item: any) => void;
}

export const DesktopRow = ({
  data,
  onEdit,
  onDelete,
  onSwitchStatus,
}: DesktopRowProps) => {
  return (
    <SearchList.CardItem>
      <div className="p-col-5">
        <SimpleText
          className="p-d-block p-mb-1"
          fontColor="color_16"
          fontSize="xxs"
        >
          {data?.codigo}
        </SimpleText>

        <SimpleText fontColor="color_16" fontSize="xxs" medium>
          {data?.nome}
        </SimpleText>
      </div>

      <div className="p-col-4">
        <SimpleText
          className="p-d-block p-mb-1"
          fontColor="color_16"
          fontSize="xxs"
        >
          {data?.modulo}
        </SimpleText>
      </div>

      <div className="p-col-2 p-px-0">
        <Switch
          checkPermission="ADM_FORNECEDOR_TUSS_ALTERAR"
          checked={data?.status}
          onChange={() => onSwitchStatus(data)}
        />
      </div>

      <div className="p-col-1 p-d-flex">
        <Button
          checkPermission="ADM_FORNECEDOR_TUSS_ALTERAR"
          className="p-mr-3"
          btnType="gray"
          icon="pi pi-pencil"
          onClick={() => onEdit(data)}
        />

        <Button
          checkPermission="ADM_FORNECEDOR_TUSS_EXCLUIR"
          icon="pi pi-trash"
          btnType="gray"
          onClick={() => onDelete(data)}
        />
      </div>
    </SearchList.CardItem>
  );
};
