import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router';

import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import Skeleton from 'src/components/Skeleton/Skeleton';

import CabecalhoUsuario from './CabecalhoUsuario/CabecalhoUsuario';
import DadosAcesso from './DadosAcesso/DadosAcesso';
import DadosCadastro from './DadosCadastro/DadosCadastro';
import HistoricoEdicoes from './HistoricoEdicoes/HistoricoEdicoes';

const VisualizarUsuario = () => {
  const [usuario, setUsuario] = useState<GetUsuarioDTO>();
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<number>(0);

  const reloadList = () => {
    setReload(prev => prev + 1);
  };

  const params = useParams();
  const navigate = useNavigate();

  const fetchUser = useCallback(async () => {
    try {
      setLoading(true);
      const response = await UsuarioAPI.loadUsuarioById(params.id);
      setUsuario(response);
    } catch (error) {
      console.error('Erro ao buscar usuario', error);
      navigate('/cadastros/usuario');
    } finally {
      setLoading(false);
    }
  }, [navigate, params.id]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <Page>
      {loading ? (
        <Skeleton height="90vh" loading={loading} />
      ) : (
        <>
          <PageHeader title="" subtitle="" clean>
            <CabecalhoUsuario usuario={usuario} />
          </PageHeader>
          <Page className="page-content " content>
            <div className="p-grid">
              <div className="p-col-12 p-md-4">
                <DadosAcesso usuario={usuario} reloadList={reloadList} />
                <DadosCadastro usuario={usuario} />
              </div>
              <div className="p-col-12 p-md-8">
                <HistoricoEdicoes usuario={usuario} reload={reload} />
              </div>
            </div>
          </Page>
        </>
      )}
    </Page>
  );
};

export default VisualizarUsuario;
