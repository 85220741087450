import { useEffect, useMemo } from 'react';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { FamiliarAPI } from 'src/APIs/ProntuarioAPI/FamiliarAPI/FamiliarAPI';
import { getRegistroObitoByIdPaciente } from 'src/APIs/ProntuarioAPI/RegistroObito/RegistroObitoAPI';
import * as Yup from 'yup';

import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI/Button';
import AlertBox from 'src/components/AlertBox/AlertBox';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SelectButtonControlled from 'src/components/Basics/SelectButtonControlled/SelectButtonControlled';
import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import FieldArrayCidSelect from 'src/components/Fields/FieldArrayCidSelect/FieldarrayCidSelect';
import { FieldParentesco } from 'src/components/Fields/FieldGrauParentesco/FieldGrauParentesco';
import FormInput from 'src/components/FormInput/FormInput';

import { FieldFiliacao } from '../FieldFiliacao/FieldFiliacao';
import { FieldVinculoFamiliar } from '../FieldVinculoFamiliar/FieldVinculoFamiliar';

type FormData = {
  nome?: string;
  sexo?: { id: number; descricao: string };
  grauParentesco?: { id: number; descricao: string };
  dataNascimento?: Date | undefined;
  dataObito?: Date | null | undefined;
  causaObito?: string;
  filiacao?: string;
  gestacao?: boolean;
  observacoes?: string;
  cids?: any[];
  idFamiliarAscendente?: number;
};

interface DialogFamiliarProps extends DisclosureType {
  idPaciente: number | null;
  funcionalidade?: 'FAMILIAR' | 'GENOGRAMA';
}

const parentescosVinculo = [
  'Primo',
  'Prima',
  'Neto',
  'Neta',
  'Sobrinho',
  'Sobrinha',
];

const DialogFamiliar = ({
  isOpen,
  close,
  state,
  idPaciente,
}: DialogFamiliarProps) => {
  const dispatch = useDispatch();

  const validateSchema = Yup.object({
    nome: Yup.string().required('Campo obrigatório'),
    sexo: Yup.object().when('gestacao', {
      is: false,
      then: Yup.object()
        .required('Sexo é obrigatório')
        .typeError('Sexo é obrigatório'),
      otherwise: Yup.object().nullable(),
    }),
    dataNascimento: Yup.date().when(['gestacao', 'dataObito'], {
      is: (values: FormData) => values?.gestacao === true && !values?.dataObito,
      then: Yup.date().nullable(),
      otherwise: Yup.date().required('Data de nascimento é obrigatória'),
    }),
    gestacao: Yup.string().required('Gestação é obrigatória'),
    grauParentesco: Yup.object().required('Grau de parentesco é obrigatório'),
    filiacao: Yup.string().when('grauParentesco', {
      is: (value: any) => ['Marido', 'Esposa'].includes(value?.descricao),
      then: Yup.string().nullable(),
      otherwise: Yup.string().required('Filiação é obrigatória'),
    }),
    idFamiliarAscendente: Yup.number().when('grauParentesco', {
      is: (value: any) => parentescosVinculo.includes(value?.descricao),
      then: Yup.number().required('Vínculo é obrigatório'),
      otherwise: Yup.number().nullable(),
    }),
  });

  const form = useForm<FormData>({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      nome: state?.nome ?? undefined,
      sexo: state?.sexo ?? undefined,
      grauParentesco:
        state?.grauParentesco ??
        (!!state && state?.idVinculoFamiliar !== null
          ? {
              id: state?.idVinculoFamiliar,
              descricao: state?.descricaoVinculoFamiliar,
            }
          : undefined),
      dataNascimento: state?.dataNascimento
        ? dayjs(state.dataNascimento).toDate()
        : (undefined as Date | undefined),
      dataObito: state?.dataObito
        ? dayjs(state.dataObito).toDate()
        : (null as Date | null),
      causaObito: state?.causaObito ?? undefined,
      filiacao: state?.filiacao ?? undefined,
      gestacao: state?.gestacao ?? undefined,
      observacoes: state?.observacoes ?? undefined,
      cids: state?.cids ?? [],
      idFamiliarAscendente: state?.idFamiliarAscendente ?? undefined,
    },
  });

  const sexoOptions = [
    { label: 'Masculino', value: { id: 3, descricao: 'MASCULINO' } },
    { label: 'Feminino', value: { id: 1, descricao: 'FEMININO' } },
  ];

  const handleRefetchGenograma = () => {
    dispatch(
      setInvalidateQuery({
        invalidateGenograma: true,
        invalidateManterFamiliar: true,
      }),
    );
  };

  const handleAdd = async (values: any) => {
    await FamiliarAPI.createPacienteFamiliares(values);

    handleRefetchGenograma();
    return close({ state: undefined });
  };

  const handleEdit = async (values: any) => {
    await FamiliarAPI.editFamiliar(state?.id, values);
    handleRefetchGenograma();

    return close({ state: undefined });
  };

  const onSubmit = (values: any) => {
    const { sexo, grauParentesco, ...data } = values;

    const formatCid = values.cids?.map?.((cid: any) => {
      if (cid.idCid) {
        return cid;
      }

      return {
        codigo: cid.codigo,
        nome: cid.nome,
        idCid: cid.id,
      };
    });

    const payload = {
      ...data,
      idSexo: sexo?.id,
      idVinculoFamiliar: grauParentesco?.id,
      idPaciente,
      cids: formatCid,
    };

    try {
      if (state?.id) {
        return handleEdit(payload);
      } else {
        return handleAdd(payload);
      }
    } catch (error) {
      console.error('Erro ao salvar familiar', error);
    }

    return;
  };

  const handleChangeDataNascimento = (e: any) => {
    if (e.value > (watchDataObito ?? 0)) {
      form.setValue('dataObito', null);
    }
  };

  const watchDataObito = form.watch('dataObito');
  const watchDataNascimento = form.watch('dataNascimento');
  const watchParentesco = form.watch('grauParentesco');
  const watchVinculo = form.watch('idFamiliarAscendente');

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'cids',
  });

  const alertText = useMemo(() => {
    if (!watchParentesco?.descricao) return '';

    const alertMap = {
      Neto: 'Filho(a)',
      Neta: 'Filho(a)',
      Sobrinho: 'Irmã(o)',
      Sobrinha: 'Irmã(o)',
      Primo: 'Tio(a)',
      Prima: 'Tio(a)',
      Filho: 'Pai/Mãe',
      Filha: 'Pai/Mãe',
    };

    return `Para cadastrar um(a) ${
      watchParentesco.descricao
    } é necessário que o paciente já tenha um cadastro de ${
      alertMap[watchParentesco?.descricao as keyof typeof alertMap]
    }`;
  }, [watchParentesco]);

  const showVinculo = parentescosVinculo.includes(
    watchParentesco?.descricao ?? '',
  );

  const showAlert = useMemo(() => {
    return !!watchParentesco?.descricao && showVinculo && !watchVinculo;
  }, [watchParentesco?.descricao, showVinculo, watchVinculo]);

  const onAddCid = (cid: any) => {
    if (!cid || fields.find((field: any) => field.codigo === cid.codigo))
      return;
    append(cid);
  };

  const onRemoveCid = (index: number) => {
    remove(index);
  };

  useEffect(() => {
    if (state?.id && !state?.dataObito)
      getRegistroObitoByIdPaciente(state?.id).then(
        data =>
          !!data && [
            form.setValue('cids', [data.cid]),
            form.setValue('dataObito', new Date(data.dataObito)),
            form.setValue('causaObito', data.causaObito),
          ],
      );
  }, [form, state?.dataObito, state?.id]);

  useEffectSkipFirst(() => {
    if (!watchParentesco || !showVinculo) return;
    form.setValue('idFamiliarAscendente', undefined);
  }, [watchParentesco, showVinculo, form]);

  return (
    <Dialog
      className="dialog-add-familiares dialog-create-familiares"
      header={!!state?.id ? 'Editar familiar' : 'Adicionar familiar'}
      maximizedTabletSize
      visible={isOpen}
      onHide={() => close({ state: undefined })}
    >
      <FormProvider {...form}>
        <form
          onSubmit={e => [form.handleSubmit(onSubmit)(e), e.stopPropagation()]}
          className="dialog-content p-col-12"
        >
          <SimpleText fontColor={FONT_COLOR.COLOR_40}>
            {!!state?.id ? 'Editar familiar' : 'Novo familiar'}
          </SimpleText>

          <div className="p-grid p-w-100">
            <FormInput
              name="nome"
              label="Nome completo"
              className="p-col-12 p-md-9"
            />

            <SelectButtonControlled
              className="p-col-12 p-md-3 p-ml-0 p-mb-0"
              control={form.control}
              name="sexo"
              label="Sexo"
              dataKey="id"
              options={sexoOptions}
            />

            <FieldParentesco
              name="grauParentesco"
              label="Grau de parentesco"
              className="p-col-12 p-md-4"
            />

            <FieldFiliacao />

            <DropdownControlled
              name="gestacao"
              label="Gestação"
              className="p-col-12 p-md-4"
              control={form.control}
              filter={false}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />

            {showVinculo && idPaciente && (
              <FieldVinculoFamiliar idPaciente={idPaciente} />
            )}

            {showAlert && (
              <AlertBox text={alertText} className="p-col-12" visible />
            )}

            <CalendarInputControlled
              name="dataNascimento"
              label="Data de nascimento"
              className="p-col-12 p-md-6"
              control={form.control}
              maxDate={new Date()}
              customOnChange={handleChangeDataNascimento}
              showIcon
            />

            <CalendarInputControlled
              name="dataObito"
              label="Óbito"
              className="p-col-12 p-md-6"
              control={form.control}
              maxDate={new Date()}
              minDate={watchDataNascimento ?? new Date(0)}
              showIcon
            />

            <TextareaInputControlled
              name="causaObito"
              label="Causa Óbito"
              className="p-col-12"
              control={form.control}
              disabled={!watchDataObito}
            />

            <TextareaInputControlled
              name="observacoes"
              label="Observações"
              className="p-col-12"
              control={form.control}
            />

            <FieldArrayCidSelect onSelectCid={onAddCid} selected={fields} />

            <div className="p-d-flex p-flex-column p-gap-2 p-w-100 p-px-2">
              {fields?.map((field: any, index: number) => (
                <div
                  key={field.id}
                  className="p-d-flex p-ai-center p-jc-between br-8px bg-96 p-w-100 p-p-2"
                >
                  <SimpleText medium>
                    {`(${field.codigo || field.codigoCid}) ${
                      field.nome || field.nomeCid
                    }`}
                  </SimpleText>
                  <Button
                    icon="fas fa-trash"
                    btnType="gray"
                    onClick={() => onRemoveCid(index)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="p-w-100 p-grid p-ai-center p-mt-1">
            <div className="p-col-6 p-md-3">
              <Button
                stretch
                label="Cancelar"
                btnType="ghost"
                type="button"
                onClick={() => close({ state: undefined })}
                disabled={!!form.formState?.isSubmitting}
              />
            </div>
            <div className="p-col-6 p-md-9">
              <Button
                loading={!!form.formState?.isSubmitting}
                stretch
                type="submit"
                label={!!state?.id ? 'Salvar alterações' : 'Adicionar familiar'}
                disabled={showAlert}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default DialogFamiliar;
