import { useState } from 'react';

import { FeedbackAPI } from 'src/APIs/AdminAPI/FeedbackAPI/FeedbackAPI';

import { Button } from 'src/components/_UI';
import Divider from 'src/components/Basics/Divider/Divider';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInput from 'src/components/Basics/TextareaInput/TextareaInput';
import Sidebar from 'src/components/Sidebar/Sidebar';

import './EnviarSugestao.scss';

interface EnviarSugestaoProps {
  closeFeedback: () => void;
  close: () => void;
}

const EnviarSugestao = ({ close, closeFeedback }: EnviarSugestaoProps) => {
  const [sugestao, setSugestao] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const onClose = () => {
    close();
    closeFeedback();
  };

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      await FeedbackAPI.sendAvaliacaoEmed({ sugestao }, { hideToast: false });
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Sidebar
      position="right"
      visible
      onHide={() => onClose()}
      className="drawer-feedback"
    >
      <form className="p-d-flex p-flex-column p-ai-start">
        <Button
          label="Cancelar e fechar"
          type="button"
          btnType="green-link"
          onClick={() => onClose()}
          icon="fas fa-arrow-left"
          iconPos="left"
          className="p-mb-2"
        />

        <SimpleText
          fontColor="color_16"
          fontSize="lg"
          medium
          className="p-my-4"
        >
          Enviar sugestão
        </SimpleText>

        <SimpleText fontColor="color_60" fontSize="sm" className="p-my-4">
          Contribua para o aprimoramento do sistema eMed enviando sua sugestão
          de melhoria.
        </SimpleText>

        <TextareaInput
          label="Comentário"
          placeholder="Digite aqui sua sugestão"
          className="p-my-3 p-w-100"
          value={sugestao}
          onChange={e => setSugestao(e.target.value)}
          rows={5}
          maxLengthSpan={true}
          maxLength={400}
        />

        <Divider layout="horizontal" className="p-p-0 p-m-0" />

        <Button
          label="Enviar"
          type="button"
          onClick={() => onSubmit()}
          stretch
          loading={submitting}
        />
      </form>
    </Sidebar>
  );
};

export default EnviarSugestao;
