import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { DashboardAPI } from 'src/APIs/AdminAPI/DashboardAPI/DashboardAPI';

import { useAppSelector } from 'src/core/redux/hooks';

import { cn } from 'src/utils/cn';

import { useDashboard } from '../_hooks/useDashboard';

import { Card } from './_card';

type Indicadores = {
  tissPendentes: number | null;
  tissRealizadas: number | null;
  examesSolicitados: number | null;
  atendimentos: number | null;
};

export const IndicadoresFiltered = memo(
  ({ className }: { className?: string }) => {
    const { consultorios, user } = useAppSelector(state => state);
    const { filters } = useDashboard();

    const [isLoading, setIsLoading] = useState(true);
    const [indicadores, setIndicadores] = useState<Indicadores>({
      tissPendentes: null,
      tissRealizadas: null,
      examesSolicitados: null,
      atendimentos: null,
    });

    const params = useMemo(
      () => ({
        idProfissionalSaude: filters?.idProfissional,
        dataInicial: filters?.periodo?.dataInicial,
        dataFinal: filters?.periodo?.dataFinal,
        ...(consultorios?.ativo?.id && {
          idConsultorio: consultorios?.ativo?.id,
        }),
        ...(filters?.idConsultorio && {
          idConsultorio: filters?.idConsultorio,
        }),
        ...(user.tipoUsuario === 'PROFISSIONAL_SAUDE' && {
          idProfissionalSaude: user.id,
        }),
      }),
      [
        consultorios?.ativo?.id,
        filters?.idConsultorio,
        filters?.idProfissional,
        filters?.periodo?.dataFinal,
        filters?.periodo?.dataInicial,
        user.id,
        user.tipoUsuario,
      ],
    );

    const fetchTissPendentes = useCallback(async () => {
      try {
        const response = await DashboardAPI.loadIndicadorTissPendentes(params, {
          throwError: true,
        });

        return response;
      } catch {
        return null;
      }
    }, [params]);

    const fetchTissRealizadas = useCallback(async () => {
      try {
        const response = await DashboardAPI.loadIndicadorTissRealizadas(
          params,
          { throwError: true },
        );

        return response;
      } catch {
        return null;
      }
    }, [params]);

    const fetchExamesSolicitados = useCallback(async () => {
      try {
        const response = await DashboardAPI.loadIndicadorExamesSolicitados(
          params,
          { throwError: true },
        );

        return response;
      } catch {
        return null;
      }
    }, [params]);

    const fetchAtendimentos = useCallback(async () => {
      if (user.tipoUsuario !== 'PROFISSIONAL_SAUDE') {
        return null;
      }

      try {
        const response = await DashboardAPI.loadIndicadorAtendimentos(params, {
          throwError: true,
        });

        return response.atendidos;
      } catch {
        return null;
      }
    }, [params, user.tipoUsuario]);

    const isAdmOrMaster = ['MASTER', 'ADMINISTRADOR'].includes(
      user?.tipoUsuario,
    );

    useEffect(() => {
      setIsLoading(true);
      Promise.allSettled([
        fetchTissPendentes(),
        fetchTissRealizadas(),
        fetchExamesSolicitados(),
        fetchAtendimentos(),
      ]).then(([tissPendentes, tissRealizadas, exanes, atendimentos]) => {
        setIsLoading(false);
        setIndicadores({
          tissPendentes:
            tissPendentes.status === 'fulfilled' ? tissPendentes.value : null,
          tissRealizadas:
            tissRealizadas.status === 'fulfilled' ? tissRealizadas.value : null,
          examesSolicitados:
            exanes.status === 'fulfilled' ? exanes.value : null,
          atendimentos:
            atendimentos.status === 'fulfilled'
              ? atendimentos.value || null
              : null,
        });
      });
    }, [
      fetchAtendimentos,
      fetchExamesSolicitados,
      fetchTissPendentes,
      fetchTissRealizadas,
    ]);

    return (
      <div
        className={cn('row content-row white p-p-3 p-gap-2', className)}
        style={{
          overflowX: 'auto',
          border: '1px solid var(--neutral-color-grayscale-88)',
        }}
      >
        {isAdmOrMaster && (
          <Card
            quantidade={indicadores.tissRealizadas}
            route="/monitoramento/transacoesTiss"
            title="Transações TISS realizadas"
            isLoading={isLoading}
          />
        )}
        {isAdmOrMaster && (
          <Card
            quantidade={indicadores.tissPendentes}
            route="/monitoramento/transacoesTiss"
            state={{
              tab: 1,
            }}
            title="Transações TISS pendentes"
            isLoading={isLoading}
          />
        )}
        {user.tipoUsuario === 'PROFISSIONAL_SAUDE' && (
          <Card
            quantidade={indicadores.atendimentos ?? 0}
            title="Atendimentos"
            isLoading={isLoading}
          />
        )}
        <Card
          quantidade={indicadores.examesSolicitados}
          title="Exames solicitados"
          isLoading={isLoading}
        />
      </div>
    );
  },
);
