import React, { useContext, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router';

import dayjs from 'dayjs';
import ComunicadosAPI from 'src/APIs/AdminAPI/ComunicadosAPI/ComunicadosAPI';
import EmpresasAPI from 'src/APIs/EmpresasAPI/EmpresasAPI';
import UnimedAvatar from 'src/assets/imgs/unimed_avatar.png';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';
import ThemeContext from 'src/core/themes/ThemeContext';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter, formatCPF } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import Avatar from 'src/components/Avatar/Avatar';
import CustomImage from 'src/components/Basics/CustomImage/CustomImage';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';
import Tooltip from 'src/components/Tooltip/Tooltip';

import { enviarImagemDoComunicado } from '../../helpers';

const DialogPreviewComunicado = ({ close, state }: DisclosureType) => {
  const { id } = useParams();
  const { user } = useAppSelector((state: RootState) => state);
  const { theme } = useContext(ThemeContext);
  const [enviando, setEnviando] = useState<boolean>(false);
  const [logoEmpresa, setLogoEmpresa] = useState<string>();

  const navigate = useNavigate();

  const onSubmit = async () => {
    const { list, imagem, ...rest } = state;

    try {
      setEnviando(true);
      let linkImagem;
      if (imagem) {
        linkImagem = await enviarImagemDoComunicado(imagem);
      }
      const payload = {
        ...(linkImagem && { imagem: linkImagem }),
        ...rest,
      };
      if (id) {
        await ComunicadosAPI.updateComunicado(Number(id), payload);
      } else {
        await ComunicadosAPI.createComunicado(payload);
      }
      close();
      navigate('/cadastros/marketing-medico');
    } catch (error) {
      console.error(error);
    } finally {
      setEnviando(false);
    }
  };

  useEffect(() => {
    if (!user.idEmpresa) return;
    (async () => {
      try {
        const empresa = await EmpresasAPI.loadEmpresaById(user.idEmpresa, {
          return: false,
          throwError: true,
        });
        setLogoEmpresa(empresa?.logoUrl);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [user.idEmpresa]);

  const urlImagem = state?.imagem ? URL.createObjectURL(state.imagem) : '';
  return (
    <Dialog
      header="Pré-visualizar comunicado"
      visible={true}
      onHide={() => close()}
      maximizedTabletSize
      style={{ minWidth: '600px', width: '95vw', height: '100%' }}
    >
      <div className="p-grid p-h-100 p-gap-2">
        <div className="p-col-7 br-8px bg-88">
          <div
            id="email-preview"
            className="br-8px bg-white"
            style={{ position: 'relative' }}
          >
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '16px',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                left: 0,
                top: 0,
                background:
                  'linear-gradient(89.98deg, #003F1A 10.94%, #54A275 92.72%)',
              }}
            ></div>
            <div
              style={{
                margin: 0,
                padding: 0,
                fontFamily: 'Roboto, sans-serif',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  padding: '27px 56px',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '24px',
                }}
              >
                <img
                  style={{ maxHeight: '75px', width: 'fit-content' }}
                  alt={'Logo da Unimed'}
                  src={logoEmpresa || UnimedAvatar}
                />
                <h1
                  style={{
                    textAlign: 'start',
                    margin: 0,
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '32px',
                    lineHeight: '120%',
                    letterSpacing: '-0.01em',
                    color: '#1A1D22',
                  }}
                >
                  {state?.titulo}
                </h1>
                <p
                  style={{
                    textAlign: 'left',
                    color: '#5D6066',
                    margin: 0,
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.0015em',
                    textUnderlinePosition: 'from-font',
                    textDecorationSkipInk: 'none',
                  }}
                >
                  {state?.mensagem}
                </p>
                {urlImagem &&
                  (state?.flagLinkImagem ? (
                    <a
                      href={state?.linkExterno}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="Imagem da notificação"
                        src={urlImagem}
                        style={{
                          width: '100%',
                          objectFit: 'contain',
                          cursor: 'pointer',
                        }}
                      />
                    </a>
                  ) : (
                    <img
                      alt="Imagem da notificação"
                      src={urlImagem}
                      style={{
                        width: '100%',
                        objectFit: 'contain',
                        cursor: 'default',
                      }}
                    />
                  ))}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    alignItems: 'start',
                    justifyContent: 'start',
                  }}
                >
                  {' '}
                  {!state?.flagLinkImagem && (
                    <>
                      <p
                        style={{
                          textAlign: 'left',
                          color: '#5D6066',
                          margin: 0,
                          fontFamily: 'Roboto',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '24px',
                          letterSpacing: '0.0015em',
                          textUnderlinePosition: 'from-font',
                          textDecorationSkipInk: 'none',
                        }}
                      >
                        Clique no botão abaixo para acessar ou{' '}
                        <a
                          href={state?.linkExterno}
                          style={{
                            color: '#54A275',
                            textDecoration: 'none',
                            cursor: 'pointer',
                          }}
                        >
                          clique aqui
                        </a>
                        .
                      </p>
                      <a
                        href={state?.linkExterno}
                        style={{ textDecoration: 'none' }}
                      >
                        <button
                          style={{
                            background: '#144027',
                            color: '#fff',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            margin: 0,
                            fontWeight: 700,
                            transition: 'background 0.3s',
                          }}
                          onMouseOver={e =>
                            (e.currentTarget.style.background = '#54A275')
                          }
                          onMouseOut={e =>
                            (e.currentTarget.style.background = '#144027')
                          }
                        >
                          {state?.textoLink || 'Acessar'}
                        </button>
                      </a>
                    </>
                  )}
                </div>
              </div>

              <footer
                style={{
                  background: '#F4F4F4',
                  padding: '20px',
                  textAlign: 'start',
                  minHeight: '136px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '11px',
                  justifyContent: 'center',
                }}
              >
                <CustomImage
                  src={
                    theme === 'light-theme'
                      ? 'emed_logo_mini_light.svg'
                      : 'emed_logo_mini_dark.svg'
                  }
                  alt="LOGO_EMED"
                  height="60px"
                />
                <p
                  style={{
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    color: '#8F9299',
                    margin: 0,
                  }}
                >
                  &copy; {new Date().getFullYear()}, eMed
                </p>
              </footer>
            </div>
          </div>
        </div>

        <div
          className="p-col br-8px border-88  p-d-flex p-flex-column p-gap-2"
          style={{ maxHeight: '-webkit-fill-available', overflowY: 'auto' }}
        >
          <SimpleText medium fontSize="md">
            Lista de pacientes comiunicados
          </SimpleText>
          <SimpleText>{state?.list?.length || 0} pacientes</SimpleText>
          <div className="p-d-flex p-flex-column p-gap-2">
            <SimpleText fontColor="color_60">Pacientes</SimpleText>
            {state?.list?.map((value: any) => (
              <div
                key={value.paciente.id}
                className="br-8px bg-96 p-col-12 p-py-0 p-d-flex p-ai-center"
              >
                <Avatar
                  label={value.paciente.nome}
                  image={value.paciente.urlFoto}
                  className="p-col-2"
                />
                <div className="p-col p-d-flex p-flex-column p-gap-2">
                  <SimpleText>{value.paciente.nome}</SimpleText>

                  <SimpleText fontColor="color_60">
                    {dayjs(new Date()).diff(
                      value.paciente.dataNascimento,
                      'years',
                    )}{' '}
                    ano(s) •{' '}
                    {value?.paciente?.idSexo === 3 ? 'Masculino' : 'Feminino'} •{' '}
                    {formatCPF(value.paciente.cpf)} •{' '}
                    {value.paciente.cartoes.length > 0
                      ? value.paciente.cartoes[0].convenio.nomeFantasia
                      : ''}{' '}
                    <SimpleText fontColor="primary">
                      {value.paciente.cartoes.length > 1
                        ? `+${value.paciente.cartoes.length - 1}`
                        : ''}
                    </SimpleText>
                  </SimpleText>
                  <div className="p-d-flex p-gap-2">
                    {value.problemas &&
                      value.problemas
                        .slice(0, 3)
                        .map((problema: any) =>
                          problema.cid && problema.cid.length > 0
                            ? problema.cid[0]?.cid
                            : problema.ciap && problema.ciap.length > 0
                            ? problema.ciap[0]?.ciap
                            : null,
                        )
                        .filter((item: any) => item !== null)
                        .sort((a: any, b: any) => {
                          if (a.cid && b.ciap) return -1;
                          if (a.ciap && b.cid) return 1;
                          return 0;
                        })
                        .map((item: any, idx: number) => {
                          return (
                            <div key={idx}>
                              <SimpleText
                                className={`comorbidades-ativas
                                  ${item.codigo + item.id}
                                  `}
                                data-pr-tooltip={item.nome}
                                data-pr-position="top"
                              >
                                {item.codigo}
                              </SimpleText>
                            </div>
                          );
                        })}
                    {(value.problemas && value.problemas.length > 0) ||
                    (value.alergias && value.alergias.length > 0) ? (
                      <SimpleText fontColor="color_60">
                        e outros{' '}
                        <SimpleText fontColor="primary">
                          {`${
                            value.problemas.length > 3
                              ? value.problemas.length - 3
                              : 0
                          } problemas`}
                        </SimpleText>{' '}
                        e{' '}
                        <SimpleText fontColor="primary">
                          {`${
                            value.alergias ? value.alergias.length : ''
                          } alergias`}
                        </SimpleText>
                      </SimpleText>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            btnType="ghost"
            label="Cancelar"
            type="button"
            onClick={() => close()}
            stretch
            loading={enviando}
          />
          <Button
            type="button"
            label="Enviar"
            onClick={() => onSubmit()}
            stretch
            loading={enviando}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DialogPreviewComunicado;
