import useSize from 'src/core/hooks/useSize';

import SimpleText, {
  FONT_SIZE,
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Can from 'src/components/Can/Can';

export const Header = () => {
  const { isMobile } = useSize();

  if (isMobile) {
    return <></>;
  }

  return (
    <div className="p-grid p-mt-1 p-mb-1">
      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Status
        </SimpleText>
      </div>
      <div className="p-col-6">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Título / Mensagem
        </SimpleText>
      </div>

      <div className="p-col-2">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Tipo de mensagem
        </SimpleText>
      </div>
      <div className="p-col-2 p-d-flex p-jc-end">
        <Can
          checkPermission={[
            'ADM_MARKETING_MEDICO_ALTERAR',
            'ADM_MARKETING_MEDICO_CADASTRAR',
            'ADM_MARKETING_MEDICO_EXCLUIR',
          ]}
        >
          <SimpleText
            fontSize={FONT_SIZE.XXS}
            fontColor={FONT_COLOR.COLOR_60}
            className="p-mr-2"
          >
            Ações
          </SimpleText>
        </Can>
      </div>
    </div>
  );
};
