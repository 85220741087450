import { useCallback, useState } from 'react';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import Dialog from 'src/components/Dialog/Dialog';
import Tab from 'src/components/Tab/Tab';

import DadosConsentimento from './DadosConsentimento/DadosConsentimento';
import ProfissionaisSaudeAutorizados from './ProfissionaisSaudeAutorizados/ProfissionaisSaudeAutorizados';

import './GerenciarConsentimentoDialog.scss';

const GerenciarConsentimentoDialog = ({
  ...consentimentoDisclosure
}: DisclosureType) => {
  const [novoTermo, setNovoTermo] = useState<ITermoConsentimentoItem | null>(
    null,
  );
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [reload, setReload] = useState(0);

  const formConsentimento = useDisclosure({ opened: false });

  const reloadList = useCallback(() => {
    setReload(prev => prev + 1);
  }, []);

  const nextStep = () => {
    setTabIndex(prev => prev + 1);
  };

  const prevStep = () => {
    setTabIndex(prev => prev - 1);
  };

  const closeTermoForm = () => {
    formConsentimento.close();
  };

  const tabs = [
    {
      label: 'Dados e consentimento',
      content: (
        <DadosConsentimento
          setNovoTermo={setNovoTermo}
          nextStep={nextStep}
          reload={reload}
          reloadList={reloadList}
          formConsentimento={formConsentimento}
        />
      ),
    },
    {
      label: 'Profissionais de saúde autorizados',
      content: (
        <ProfissionaisSaudeAutorizados
          novoTermo={novoTermo}
          setNovoTermo={setNovoTermo}
          prevStep={prevStep}
          reloadList={reloadList}
          closeTermoForm={closeTermoForm}
        />
      ),
      disabled: !novoTermo,
    },
  ];

  return (
    <Dialog
      id="gerenciar-consentimento-dialog"
      header="Consentimento do Paciente"
      onHide={consentimentoDisclosure.close}
      visible={true}
      maximizedMobileSize
      style={{ width: '80%' }}
    >
      <>
        <Tab
          clean
          values={tabs}
          initialTabIndex={0}
          setActiveIndex={tabIndex}
          onTabChange={e => setTabIndex(e.index)}
          activeIndex={tabIndex}
        />
        <Button
          label="Fechar"
          onClick={() => consentimentoDisclosure.close()}
          btnType="outline"
          stretch
        />
      </>
    </Dialog>
  );
};

export default GerenciarConsentimentoDialog;
