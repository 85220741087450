import { useMemo, useRef, useState } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';
import { useNavigate } from 'react-router';

import dayjs from 'dayjs';
import { ProtocoloAtendimentoAPI } from 'src/APIs/AdminAPI/ProtocoloAtendimentoAPI/ProtocoloAtendimentoAPI';

import useSize from 'src/core/hooks/useSize';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { checkAuth } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Separator from 'src/components/Separator/Separator';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Switch from 'src/components/Switch/Switch';
import Tooltip from 'src/components/Tooltip/Tooltip';

import ModalVinculos from './ModalVinculos';

import './ProtocoloAtendimentoRow.scss';

export const ProtocoloAtendimentoRow = ({ item, reloadList }: any) => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const {
    user: { authorities },
  } = useAppSelector((state: RootState) => state);
  const [isActive, setIsActive] = useState<boolean>(item.ativo);
  const { windowInnerWidth } = useSize();

  const navigate = useNavigate();
  const ref = useRef<TieredMenu>(null);

  const isTablet = useMemo(() => windowInnerWidth <= 999, [windowInnerWidth]);

  const modalVinculosDisclosure = useDisclosure({ opened: false });

  const handleInativar = async () => {
    try {
      setLoadingStatus(true);
      await ProtocoloAtendimentoAPI.updateProtocoloStatus({
        id: item.id,
        ativo: !isActive,
      });

      setIsActive(!isActive);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleDelete = async () => {
    try {
      await ProtocoloAtendimentoAPI.deleteProtocoloAtendimento(item.id, {
        throwError: true,
      });
      reloadList();
    } catch {}
  };

  const menuItens = [
    {
      label: 'Editar',
      command: () => navigate(`/cadastros/protocolo-atendimento/${item.id}`),
      checkPermission: 'ADM_PROTOCOLO_ATENDIMENTO_ALTERAR',
    },
    {
      label: 'Excluir',
      command: handleDelete,
      className: 'error',
      checkPermission: 'ADM_PROTOCOLO_ATENDIMENTO_EXCLUIR',
    },
  ];

  const menuItensFiltered = menuItens.filter(item => {
    return item?.checkPermission
      ? checkAuth(authorities, item?.checkPermission)
      : true;
  });

  return (
    <div className="row-component p-d-flex p-flex-column p-my-2">
      <div className="p-grid p-col-12 p-ai-center">
        <div className="p-col-6 p-lg-3  p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Protocolo
          </SimpleText>
          <SimpleText className="p-text-climp-2" fontColor="color_40" medium>
            {item.nome}
          </SimpleText>
        </div>
        <div className="p-col-6 p-lg-1 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Vínculos
          </SimpleText>
          <SimpleText
            fontColor="primary"
            className="p-text-climp-2 cursor-pointer"
            onClick={() => modalVinculosDisclosure.open()}
          >
            {`${item.vinculos?.totalizador || 0} Vínculos`}
          </SimpleText>
        </div>
        <div className="p-col-6 p-md-3 p-lg-1 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Criado por
          </SimpleText>
          <SimpleText className="p-text-climp-2" fontColor="color_40" medium>
            {item.criadoPor?.nome || '-'}
          </SimpleText>
        </div>
        <div className="p-col-6 p-md-3 p-lg-2 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Data da inclusão
          </SimpleText>
          <SimpleText className="p-text-climp-2" fontColor="color_40">
            {item.dataCriacao
              ? dayjs(item.dataCriacao).format('DD/MM/YYYY')
              : '-'}
          </SimpleText>
        </div>
        <div className="p-col-6 p-md-3 p-lg-1 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Alterado por
          </SimpleText>
          <SimpleText className="p-text-climp-2" fontColor="color_40" medium>
            {item.alteradoPor?.nome || '-'}
          </SimpleText>
        </div>
        <div className="p-col-6 p-md-3 p-lg-2 p-d-flex p-flex-column p-gap-1">
          <SimpleText fontColor="color_60" fontSize="xxs">
            Data da alteração
          </SimpleText>
          <SimpleText className="p-text-climp-2" fontColor="color_40">
            {item.dataAlteracao
              ? dayjs(item.dataAlteracao).format('DD/MM/YYYY')
              : '-'}
          </SimpleText>
        </div>
        <div
          className={`"p-col-6 p-lg-1 p-d-flex " ${!isTablet && 'p-jc-end'}`}
        >
          {loadingStatus ? (
            <SpinnerLoading size="sm" />
          ) : (
            <Switch
              checked={isActive}
              onChange={() => handleInativar()}
              checkPermission="ADM_PROTOCOLO_ATENDIMENTO_ALTERAR"
              showStatus
            />
          )}
        </div>
        <div className="p-col-1 p-d-flex p-ai-center p-jc-end">
          {!isTablet && <Separator layout="vertical" />}
          {!isTablet ? (
            <Button
              btnType="gray"
              icon="fas fa-ellipsis"
              onClick={e => ref.current?.toggle(e)}
              disabled={!menuItensFiltered.length}
            />
          ) : (
            <Button
              className="button-row"
              btnType="gray"
              icon="fas fa-ellipsis"
              onClick={e => ref.current?.toggle(e)}
              disabled={!menuItensFiltered.length}
            />
          )}
        </div>

        <TieredMenu ref={ref} popup model={menuItensFiltered} />
      </div>
      <div
        className={`p-d-flex cids p-col-12 p-py-0 ${
          isTablet && 'p-flex-column'
        }`}
      >
        <div className="p-d-flex p-col-12 p-lg-5 p-ai-center p-gap-4">
          <SimpleText fontColor="color_60" fontSize="xs">
            CID
          </SimpleText>
          <div className="p-d-flex p-gap-2 p-ml-2 lista-cids">
            {item.cids?.map((cid: any, idx: number) => (
              <div key={idx}>
                <Tooltip target={`.${cid.codigo + item.id}`} />
                <SimpleText
                  className={`comorbidades-ativas ${cid.codigo + item.id}`}
                  data-pr-tooltip={cid.nome}
                  data-pr-position="top"
                >
                  {cid.codigo}
                </SimpleText>
              </div>
            ))}
          </div>
        </div>
        <div className="p-d-flex p-col-12 p-lg-5 p-ai-center p-gap-4">
          <SimpleText fontColor="color_60" fontSize="xs">
            CIAP
          </SimpleText>
          <div className="p-d-flex p-gap-2 lista-cids">
            {item.ciaps?.map((ciap: any, idx: number) => (
              <div key={idx}>
                <Tooltip target={`.${ciap.codigo + item.id}`} />
                <SimpleText
                  className={`comorbidades-ativas arritmia ${
                    ciap.codigo + item.id
                  }`}
                  data-pr-tooltip={ciap.nome}
                  data-pr-position="top"
                >
                  {ciap.codigo}
                </SimpleText>
              </div>
            ))}
          </div>
        </div>
      </div>
      {modalVinculosDisclosure.isOpen && (
        <ModalVinculos {...modalVinculosDisclosure} idProtocolo={item.id} />
      )}
    </div>
  );
};
