import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import PerfisAPI from 'src/APIs/PerfilAPI/PerfilAPI';
import ReportAPI from 'src/APIs/ReportAPI/ReportAPI';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import MultipleCheckboxControlled from 'src/components/Basics/Checkbox/MultipleCheckboxControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';

import './RelatoriosAcesso.scss';
import { handleDownloadXLS } from 'src/utils/files';

const RelatoriosAcesso = () => {
  const [permissionsOptions, setPermissionsOptions] = useState<any[]>([]);
  const [loadingList, setLoadingList] = useState<boolean>(true);
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
  const [loadingXLS, setLoadingXLS] = useState<boolean>(false);

  useEffect(() => {
    const getPerfis = async () => {
      try {
        const response = await PerfisAPI.loadPerfis();
        const formatResponse = response.list?.map((item: any) => ({
          label: capitalizeFirstLetter(item.descricao),
          value: item.id,
        }));
        setPermissionsOptions(formatResponse);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingList(false);
      }
    };
    getPerfis();
  }, []);

  const form = useForm<{ idPerfilList: number[] }>({});

  const { watch } = form;

  const idPerfilList = watch('idPerfilList');

  const onSubmit = async (
    data: { idPerfilList: number[] },
    type: 'pdf' | 'excel',
  ) => {
    const { idPerfilList } = data;
    if (type === 'pdf') {
      setLoadingPDF(true);
    } else {
      setLoadingXLS(true);
    }
    try {
      const response = await ReportAPI.relatorioPermissoesAcesso(
        {
          idPerfilList,
        },
        type,
      );

      if (type === 'excel') {
        return handleDownloadXLS(response, 'Relatório de Acesso');
      }

      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, '_blank');
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPDF(false);
      setLoadingXLS(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        id="form-relatorios-acesso"
        className="p-grid p-flex-column p-gap-2 p-col-12 p-lg-5 p-pb-4"
      >
        <SimpleText medium fontSize="md" className="p-col-12">
          Perfil
        </SimpleText>
        <SimpleText fontColor="color_60" className="p-col-12">
          Filtre o perfil dos usuários que serão exibidos no documento
        </SimpleText>
        {loadingList ? (
          <Skeleton height="350px" loading={true} />
        ) : (
          <div>
            <MultipleCheckboxControlled
              name="idPerfilList"
              options={permissionsOptions}
              required
              classNameContainer="checkbox-options-container p-p-2"
            />
          </div>
        )}
        <div className="p-d-flex p-flex-column p-gap-3 p-px-2 p-mt-2">
          <SimpleText fontColor="color_40">
            Para concluir, gere o relatório
          </SimpleText>
          <div className="p-d-flex p-gap-2">
            <Button
              type="button"
              label="Abrir em PDF"
              btnType="tonal"
              loading={loadingPDF}
              disabled={!idPerfilList?.length}
              onClick={() => onSubmit({ idPerfilList }, 'pdf')}
              stretch
            />
            <Button
              type="button"
              label="Baixar XLS (Excel)"
              btnType="tonal"
              loading={loadingXLS}
              disabled={!idPerfilList?.length}
              onClick={() => onSubmit({ idPerfilList }, 'excel')}
              stretch
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default RelatoriosAcesso;
