import { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router';

import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';
import PacienteAPI from 'src/APIs/PacienteAPI/PacienteAPI';

import { formatPhoneNumber } from '../BarraLateralMenuAgenda/utils';
import { formatAddress } from 'src/utils/address';
import { formatarData } from 'src/utils/date';
import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import './ModalCardPaciente.scss';

interface ModalCardPacienteProps {
  paciente: any;
  convenio: ConvenioDTO | null;
  setExibirModal?: (prev: boolean) => void;
}

const ModalCardPaciente = ({
  paciente,
  convenio,
  setExibirModal,
}: ModalCardPacienteProps) => {
  const [enderecoPaciente, setEnderecoPaciente] = useState(
    paciente?.enderecos?.[0] || null,
  );

  const navigate = useNavigate();

  const birthDate = useMemo(
    () =>
      paciente.dataNascimento
        ? formatarData(String(paciente.dataNascimento))
        : '',
    [paciente?.dataNascimento],
  );

  const handleText = (text?: string) => {
    return text ? text.toLowerCase() : 'Não informado';
  };

  const handleVerPerfil = () => {
    return navigate(`/pacientes/${paciente.id}`);
  };

  useEffect(() => {
    PacienteAPI.getPacienteEnderecoCompleto(paciente.id, {
      throwError: true,
    })
      .then(
        res => res.enderecos?.length && setEnderecoPaciente(res.enderecos[0]),
      )
      .catch(() => {});
  }, [paciente.id]);

  return (
    <div className="p-d-flex p-flex-column ModalCardPaciente">
      <div className="p-d-flex p-ai-center p-mb-2 p-gap-1 p-w-100">
        <Avatar
          icon="fa fa-user-alt"
          image={paciente?.fotoUrl || ''}
          label={paciente?.nome}
          className="p-col-1"
        />

        <div className="p-d-flex p-flex-column p-col-8 p-gap-1">
          <SimpleText
            fontColor={FONT_COLOR.COLOR_40}
            fontSize={FONT_SIZE.XS}
            medium
          >
            {handleText(paciente?.nome)}
          </SimpleText>
          {!!convenio && (
            <SimpleText
              fontColor={FONT_COLOR.COLOR_60}
              fontSize={FONT_SIZE.XXS}
            >
              {convenio?.nomeFantasia || convenio.razaoSocial}
            </SimpleText>
          )}

          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            {formatPhoneNumber(
              paciente?.celularParticular || paciente?.telefonePrincipal,
            )}
          </SimpleText>
        </div>

        <div className="p-d-flex p-ai-center p-jc-end p-gap-1 p-mb-2 p-col-3">
          <Button
            icon="pi pi-whatsapp"
            btnType="tonal"
            onClick={() =>
              window.open(
                `http://wa.me/55${paciente?.celularParticular
                  ?.match(/\d/g)
                  ?.join('')}`,
                '_blank',
              )
            }
            disabled={!paciente?.celularParticular}
          />

          <Button
            icon="fas fa-envelope"
            btnType="tonal"
            onClick={() => (window.location.href = `mailto:${paciente?.email}`)}
            disabled={!paciente?.email}
          />
        </div>
      </div>

      <div className="modal-card-paciente-body p-mb-4">
        <div className="p-d-flex p-mb-3">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XS}>
            Sexo:
          </SimpleText>

          <SimpleText
            className="p-ml-2"
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XS}
            medium
          >
            {paciente?.sexoDescricao
              ? paciente?.sexoDescricao
              : capitalizeFirstLetter(paciente?.sexo?.descricao)}
          </SimpleText>
        </div>

        <div className="p-d-flex p-mb-3">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XS}>
            Nasceu em:
          </SimpleText>

          <SimpleText
            className="p-ml-2"
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XS}
            medium
          >
            {handleText(birthDate)}
          </SimpleText>
        </div>

        <div className="p-d-flex p-mb-3">
          <div
            style={{
              width: '80px',
            }}
          >
            <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XS}>
              Mora em:
            </SimpleText>
          </div>

          <SimpleText
            className="p-line-height-2"
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XS}
            medium
          >
            {!!enderecoPaciente &&
              formatAddress({
                logradouro: enderecoPaciente.logradouro,
                numLogradouro: enderecoPaciente.numero,
                bairro: enderecoPaciente.bairro,
                cidade: enderecoPaciente.cidade?.nome,
                cep: enderecoPaciente.cep,
                estado: enderecoPaciente.estado,
                complemento: enderecoPaciente.complemento,
              })}
          </SimpleText>
        </div>

        <div className="p-d-flex p-mb-3">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XS}>
            CPF:
          </SimpleText>

          <SimpleText
            className="p-ml-2"
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XS}
            medium
          >
            {handleText(paciente?.cpf)}
          </SimpleText>
        </div>

        <div className="p-d-flex p-mb-3">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XS}>
            Mãe:
          </SimpleText>

          <SimpleText
            className="p-ml-2"
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XS}
            medium
          >
            {handleText(paciente?.nomeMae)}
          </SimpleText>
        </div>
      </div>
      <Button
        label="Editar informações"
        className="mb-1 btn-edit"
        btnType="tonal"
        onClick={() => setExibirModal && setExibirModal(true)}
      />
      <Button
        label="Ver perfil completo"
        btnType="outline"
        onClick={handleVerPerfil}
        stretch
      />
    </div>
  );
};

export default ModalCardPaciente;
