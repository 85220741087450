import useSize from 'src/core/hooks/useSize';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

const ListHeader = () => {
  const { windowInnerWidth } = useSize();
  const desktopSize = windowInnerWidth > 1200;
  return (
    <>
      {desktopSize && (
        <div className="p-grid p-d-flex p-ai-center">
          <div className="p-col p-d-flex p-jc-center">
            <SimpleText fontSize="xxs" fontColor="color_60">
              Vencimento
            </SimpleText>
          </div>
          <div className="p-col p-d-flex">
            <SimpleText fontSize="xxs" fontColor="color_60">
              Recebimento
            </SimpleText>
          </div>
          <div className="p-col p-d-flex">
            <SimpleText fontSize="xxs" fontColor="color_60">
              Credor
            </SimpleText>
          </div>
          <div className="p-col p-d-flex">
            <SimpleText fontSize="xxs" fontColor="color_60">
              Consultorio
            </SimpleText>
          </div>
          <div className="p-col p-d-flex">
            <SimpleText fontSize="xxs" fontColor="color_60">
              Centro de custo
            </SimpleText>
          </div>
          <div className="p-col p-d-flex">
            <SimpleText fontSize="xxs" fontColor="color_60">
              Tipo
            </SimpleText>
          </div>
          <div className="p-col p-d-flex">
            <SimpleText fontSize="xxs" fontColor="color_60">
              Valor
            </SimpleText>
          </div>
          <div className="p-col p-d-flex">
            <SimpleText fontSize="xxs" fontColor="color_60">
              Valor recebido
            </SimpleText>
          </div>
          <div className="p-col p-d-flex">
            <SimpleText fontSize="xxs" fontColor="color_60">
              Origem
            </SimpleText>
          </div>
          <div className="p-col p-d-flex" />
          <div className="p-col p-d-flex p-ai-center p-justify-end p-py-0 p-gap-1">
            <Button
              icon={'fas fa-chevron-down'}
              btnType="gray"
              style={{ opacity: 0 }}
            />
            <Button
              icon={'fas fa-chevron-down'}
              btnType="gray"
              style={{ opacity: 0 }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ListHeader;
