import useSize from 'src/core/hooks/useSize';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';

import Chat from './Chat/Chat';
import DetalhesGuia from './DetalhesGuia/DetalhesGuia';

interface ModalChatProps extends DisclosureType {
  data: IPendenciaAuditoriaDTO;
}

const ModalChat = ({ data, close }: ModalChatProps) => {
  const { isMobile } = useSize();
  return (
    <div
      id="chat-pendencias"
      className={`p-d-flex p-flex-column p-gap-1 p-p-3 ${
        isMobile ? 'mobile-full' : ''
      }`}
    >
      <div className="p-d-flex p-jc-between p-ai-center">
        <SimpleText fontSize='lg' medium>
          Mensagens auditoria
        </SimpleText>
        <Button
          btnType="green-link"
          icon="pi pi-times"
          onClick={() => close()}
        />
      </div>
      <DetalhesGuia data={data} />
      <Chat data={data} />
    </div>
  );
};

export default ModalChat;
