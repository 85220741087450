import React, { useRef } from 'react';

import { TieredMenu } from 'primereact/tieredmenu';

import dayjs from 'dayjs';
import { IFamiliarPaciente } from 'src/APIs/ProntuarioAPI/FamiliarAPI/Familiar';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { capitalizeFirstLetter } from 'src/utils/utils';

import { Button } from 'src/components/_UI/Button';
import Avatar from 'src/components/Avatar/Avatar';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Tooltip from 'src/components/Tooltip/Tooltip';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import { ListaCids } from '../Row';

import './DesktopRow.scss';

interface DesktopRowProps {
  familiar: IFamiliarPaciente;
  menuItens: any[];
  listaCidsModal: DisclosureType;
  loading: boolean;
}

const DesktopRow = ({
  familiar,
  menuItens,
  listaCidsModal,
  loading,
}: DesktopRowProps) => {
  return (
    <div className="p-my-1 panel-lista-familiares p-pb-1 p-pr-1">
      <Panel
        header={(isCollapsed, onToggle) => (
          <PanelHeader
            familiar={familiar}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            loading={loading}
            menuItens={menuItens}
            listaCidsModal={listaCidsModal}
          />
        )}
      >
        <PanelContent familiar={familiar} />
      </Panel>
    </div>
  );
};

export default DesktopRow;

interface PanelHeaderProps {
  familiar: IFamiliarPaciente;
  isCollapsed: boolean;
  onToggle: () => void;
  loading: boolean;
  menuItens: any[];
  listaCidsModal: DisclosureType;
}

const PanelHeader = ({
  familiar,
  isCollapsed,
  onToggle,
  loading,
  listaCidsModal,
  menuItens,
}: PanelHeaderProps) => {
  const menuRef = useRef<TieredMenu>(null);

  return (
    <div className="p-d-flex p-w-100 p-jc-between bg-96 br-8px p-p-2">
      <div className="p-grid p-w-100">
        <div className="p-col-12 p-md-8 p-lg-4 p-d-flex p-gap-2 p-ai-center p-pl-0">
          <Avatar
            label={familiar?.nome}
            className="avatar"
            image={familiar.urlFoto || ''}
          />
          <div className="p-d-flex p-flex-column p-gap-1">
            <SimpleText fontColor="color_60" fontSize="xs">
              Nome
            </SimpleText>
            <SimpleText fontSize="xs">
              {familiar.nome}
              <span className="color_60">{familiar.planoSaude}</span>
            </SimpleText>
            <div className="p-d-flex p-gap-1 p-mt-1">
              {familiar.cids &&
                familiar.cids.length > 0 &&
                familiar.cids.slice(0, 3).map((cid: any) =>
                  cid.codigo && cid.nome && cid.id ? (
                    <div key={cid.id} className="tag">
                      <Tooltip target={`.${cid.codigo + cid.id}`} />
                      <SimpleText
                        className={`comorbidades-ativas tag ${
                          cid.codigo + cid.id
                        }`}
                        data-pr-tooltip={cid.nome}
                        data-pr-position="top"
                      >
                        {cid.codigo}
                      </SimpleText>
                    </div>
                  ) : null,
                )}
              {familiar.cids && familiar.cids?.length > 3 && (
                <div
                  className="cursor-pointer"
                  onClick={() => listaCidsModal.open({ state: familiar.cids })}
                >
                  <SimpleText
                    fontColor="primary"
                    medium
                    className="cursor-pointer"
                  >
                    +{familiar.cids?.length - 3}
                  </SimpleText>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="p-col">
          <div className="p-d-flex p-flex-column p-gap-1 p-jc-start">
            <SimpleText fontColor="color_60" fontSize="xs">
              Sexo
            </SimpleText>
            <SimpleText fontSize="xs">
              {capitalizeFirstLetter(familiar.sexo?.descricao || '')}
            </SimpleText>
          </div>
        </div>
        <div className="p-col">
          <div className="p-d-flex p-flex-column p-gap-1 p-jc-start">
            <SimpleText fontColor="color_60" fontSize="xs">
              Data de nascimento
            </SimpleText>
            <SimpleText fontSize="xs">
              {familiar.dataNascimento
                ? dayjs(familiar.dataNascimento).format('DD/MM/YYYY')
                : '-'}{' '}
            </SimpleText>
          </div>
        </div>
        <div className="p-col">
          <div className="p-d-flex p-flex-column p-gap-1 p-jc-start">
            <SimpleText fontColor="color_60" fontSize="xs">
              Data de óbito
            </SimpleText>
            <SimpleText fontSize="xs">
              {familiar.dataObito
                ? dayjs(familiar.dataObito).format('DD/MM/YYYY')
                : ''}{' '}
            </SimpleText>
          </div>
        </div>
        <div className="p-col">
          <div className="p-d-flex p-flex-column p-gap-1 p-jc-start">
            <SimpleText fontColor="color_60" fontSize="xs">
              Parentesco
            </SimpleText>
            <SimpleText fontSize="xs">
              {familiar.descricaoVinculoFamiliar || ''}
            </SimpleText>
          </div>
        </div>
      </div>
      <div className="p-d-flex p-gap-1 p-ai-center">
        <Button
          icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
          btnType="gray"
          onClick={onToggle}
        />

        <Button
          btnType="gray"
          icon="fas fa-ellipsis-h"
          onClick={e => menuRef.current?.toggle(e)}
          loading={loading}
        />

        <TieredMenu
          className="panel-options-paciente"
          ref={menuRef}
          model={menuItens}
          popup
        />
      </div>
      {listaCidsModal.isOpen ? <ListaCids {...listaCidsModal} /> : <></>}
    </div>
  );
};

interface PanelContentProps {
  familiar: IFamiliarPaciente;
}

const PanelContent = ({ familiar }: PanelContentProps) => {
  return (
    <div className="p-d-flex p-flex-column p-gap-2  br-8px">
      <div className="p-grid p-w-100">
        <div className="p-d-flex p-flex-column p-gap-1 p-jc-start p-col-6 p-md-3">
          <SimpleText fontColor="color_60" fontSize="xs">
            Filiação
          </SimpleText>
          <SimpleText fontSize="xs">
            {familiar.filiacao ? capitalizeFirstLetter(familiar.filiacao) : ''}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-jc-start p-col-6 p-md-3">
          <SimpleText fontColor="color_60" fontSize="xs">
            Gestação
          </SimpleText>
          <SimpleText fontSize="xs">
            {familiar.gestacao ? 'Sim' : 'Não'}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-jc-start p-col-12 p-md-6">
          <SimpleText fontColor="color_60" fontSize="xs">
            Observações
          </SimpleText>
          <SimpleText fontSize="xs">{familiar.observacoes || ''}</SimpleText>
        </div>
      </div>
      {familiar?.dataAtualizacao && (
        <div className="row p-p-2 bg-neutral-color">
          <SimpleText>
            Alterado por{' '}
            <b className="color_60">
              {familiar.usuarioAtualizacao?.nome || '-'}
            </b>{' '}
            em{' '}
            {dayjs(familiar?.dataAtualizacao).format('DD/MM/YYYY [às] HH:mm')}
          </SimpleText>
        </div>
      )}
      <div className="row p-p-2 bg-neutral-color">
        <SimpleText>
          Incluido por{' '}
          <b className="color_60">{familiar?.usuarioInclusao?.nome || '-'}</b>{' '}
          em {dayjs(familiar?.dataInclusao).format('DD/MM/YYYY [às] HH:mm')}
        </SimpleText>
      </div>
    </div>
  );
};
