import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import {
  IFamiliarFiliacao,
  IFamiliarPayload,
} from 'src/APIs/ProntuarioAPI/FamiliarAPI/Familiar';
import { FamiliarAPI } from 'src/APIs/ProntuarioAPI/FamiliarAPI/FamiliarAPI';

import { stringToEnum } from 'src/utils/utils';

import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';

export const FieldVinculoFamiliar = ({
  idPaciente,
}: {
  idPaciente: number;
}) => {
  const { control, watch } = useFormContext();
  const [options, setOptions] = useState<IFamiliarPayload[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const watchParentesco = watch('grauParentesco');

  const fetchApi = useCallback(async () => {
    if (!watchParentesco?.descricao) return;

    setIsLoading(true);

    try {
      const res = await FamiliarAPI.loadVinculoAscendente(
        idPaciente,
        stringToEnum(watchParentesco.descricao),
      );
      setOptions(res);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [idPaciente, watchParentesco?.descricao]);

  const items = useMemo(
    () =>
      options.map(option => ({
        label: option.nome,
        value: option.id!,
      })),
    [options],
  );

  useEffect(() => {
    fetchApi();
  }, [fetchApi]);

  return (
    <DropdownControlled
      name="idFamiliarAscendente"
      label="Vínculo"
      className="p-col-12"
      filter={false}
      control={control}
      options={items}
      loading={isLoading}
    />
  );
};
