import Http, { HttpParams } from 'src/core/http/Http';

const basePath = '/agravo/monitoramento';

const getMonitoramentoNotificacoes = (
  params: { periodoTempo?: string; notificado?: boolean; nomeAgravo?: string },
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}`,
    params,
    throwError: true,
    hideToast: true,
    ...options,
  });
};

const putMonitoramentoNotificacoes = (
  ids: number[],
  notificado: boolean,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}`,
    data: { ids, notificado },
    throwError: true,
    ...options,
  });
};

const gerarRelatorioPdf = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'report',
    url: `relatorios${basePath}/pdf`,
    params,
    hideToast: true,
    responseType: 'arraybuffer',
    return: true,
    throwError: true,
    ...options,
  });
};

export const MonitoramentoNotificacoesAPI = {
  getMonitoramentoNotificacoes,
  putMonitoramentoNotificacoes,
  gerarRelatorioPdf,
};