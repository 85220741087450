export const handleResetForm = (formReset: any, data: any) => {
  const atividadesConsultas =
    data.planoCuidadoAtividadeConsultas || data?.atividadesConsultas;
  const atividadesCuidado =
    data.planoCuidadoAtividadeCuidados || data?.atividadesCuidado;
  const atividadesExame =
    data.planoCuidadoAtividadeExames || data?.atividadesExame;
  const atividadesFormularioDinamicos =
    data.planoCuidadoAtividadeFormularioDinamicos ||
    data?.atividadesFormularioDinamico;

  const planos = [
    ...atividadesConsultas.map((item: any) => ({
      ...formatAtividade('CONSULTA', item),
      atividade: 'CONSULTA',
    })),
    ...atividadesCuidado?.map((item: any) => ({
      ...formatAtividade('PEDIDO_CUIDADO', item),
      nomeCuidado: item.nome,
      atividade: 'PEDIDO_CUIDADO',
    })),
    ...atividadesExame?.map((item: any) => ({
      ...formatAtividade('EXAMES', item),
      atividade: 'EXAMES',
    })),
    ...atividadesFormularioDinamicos?.map((item: any) => ({
      ...formatAtividade('FORMULARIO_DINAMICO', item),
      atividade: 'FORMULARIO_DINAMICO',
    })),
  ];

  return formReset({
    ...data,
    nome: data.nome || data.planoCuidado.nome,
    cids: data.cids || data.planoCuidado.cids,
    ciaps: data.ciaps || data.planoCuidado.ciaps,
    diasVigencia: data.diasVigencia || data.planoCuidado.diasVigencia,
    tipoCuidado: data.planoCuidado?.planoCuidado || data.planoCuidado,
    planos,
  });
};

export const formatAtividade = (atividade: string, data: any) => {
  if (atividade === 'CONSULTA') {
    return {
      id: data.id,
      atividade: data.atividade,
      idEspecialidade: data.especialidade?.id,
      especialidade: data.especialidade,
      recorrencia: data.recorrencia,
      execucao: data.execucao,
      resumoClinico: data.resumoClinico,
      hipoteseDiagnostica: data.hipoteseDiagnostica,
      questionamento: data.questionamento,
      idAtendimentoOrigem: data.idAtendimento,
      consultas: formatarDatasPrevistas(data.consultas),
    };
  }
  if (atividade === 'PEDIDO_CUIDADO') {
    return {
      id: data.id,
      atividade: data.atividade,
      nome: data.nome,
      idTipoCuidado: data.tipo?.id ?? data.idTipoCuidado,
      tipo: data.tipo,
      recorrencia: data.recorrencia,
      execucao: data.execucao,
      idAtendimentoOrigem: data.idAtendimento,
      pedidosCuidado: formatarDatasPrevistas(data.pedidosCuidado),
    };
  }
  if (atividade === 'EXAMES') {
    return {
      id: data.id,
      atividade: data.atividade,
      idServicoTuss: data.servicoTuss?.id,
      servicoTuss: data.servicoTuss,
      quantidade: data.quantidade,
      recorrencia: data.recorrencia,
      execucao: data.execucao,
      idAtendimentoOrigem: data.idAtendimento,
      exames: formatarDatasPrevistas(data.exames),
    };
  }
  if (atividade === 'FORMULARIO_DINAMICO') {
    return {
      id: data.id,
      atividade: data.atividade,
      recorrencia: data.recorrencia,
      execucao: data.execucao,
      idAtendimentoOrigem: data.idAtendimento,
      idAtendimento: data.idAtendimento,
      formularioDinamico: data.formularioDinamico,
      formularios: formatarDatasPrevistas(data.formularios),
    };
  }

  return {};
};

const formatarDatasPrevistas = (datasPrevistas: any[]) => {
  if (!datasPrevistas) return [];

  const hasDataPrevisaRealizacao = datasPrevistas.some(
    item => item.dataPrevistaRealizacao,
  );

  if (!hasDataPrevisaRealizacao) return datasPrevistas;

  return datasPrevistas.map(item => ({
    id: item.id,
    data: new Date(item.dataPrevistaRealizacao),
    hora: new Date(item.dataPrevistaRealizacao),
    statusRealizacao: item.statusRealizacao,
  }));
};
