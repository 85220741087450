import React from 'react';

import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';

import useSize from 'src/core/hooks/useSize';

import { utcToGMTString } from 'src/utils/utils';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import * as SearchList from 'src/components/SearchList/SearchListRoot';
import Separator from 'src/components/Separator/Separator';

import './HistoricoEdicoes.scss';

interface HistoricoEdicoesProps {
  usuario: GetUsuarioDTO | undefined;
  reload: number;
}

const HistoricoEdicoes = ({ usuario, reload }: HistoricoEdicoesProps) => {
  const { isMobile, isTablet } = useSize();

  const fetchApi = async ({ ...params }: any) => {
    try {
      const response = await UsuarioAPI.loadHistoricoEdicoes(
        usuario?.id,
        params,
      );
      return response;
    } catch (error) {
      return console.error('Erro ao buscar historico de edições', error);
    }
  };

  const renderRow = (item: IHistoricoUsuarioItem) => {
    if (isTablet) {
      return <MobileRow item={item} />;
    }
    return (
      <div className="p-w-100 p-grid">
        <SimpleText className="p-col-4 p-py-0">
          {item.dataAtualizacao
            ? utcToGMTString(new Date(item.dataAtualizacao))
            : ''}
        </SimpleText>
        <SimpleText className="p-col-2 p-py-0">{item.tipoOperacao}</SimpleText>
        <SimpleText className="p-col-2 p-py-0">{item.nomeColuna}</SimpleText>
        <SimpleText className="p-col-2 p-py-0">{item.valorAnterior}</SimpleText>
        <SimpleText className="p-col-2 p-py-0">{item.novoValor}</SimpleText>
        <Separator />
      </div>
    );
  };

  return (
    <div id="historico-edicoes" className="p-w-100">
      <SimpleText medium fontColor="color_40">
        Histórico de edições
      </SimpleText>
      <div className="menu-card p-w-100 p-grid p-px-2 p-py-3 p-mt-2">
        {!isTablet && (
          <div className="header-historico-edicoes p-w-100 p-grid p-mb-3">
            <SimpleText
              className="p-col-4 p-pb-1"
              fontColor="color_60"
              fontSize="xxs"
            >
              Data
            </SimpleText>
            <SimpleText
              className="p-col-2 p-pb-1"
              fontColor="color_60"
              fontSize="xxs"
              style={{ wordBreak: 'break-word' }}
            >
              Tipo de operação
            </SimpleText>
            <SimpleText
              className="p-col-2 p-pb-1"
              fontColor="color_60"
              fontSize="xxs"
            >
              Campo
            </SimpleText>
            <SimpleText
              className="p-col-2 p-pb-1"
              fontColor="color_60"
              fontSize="xxs"
            >
              Valor anterior
            </SimpleText>
            <SimpleText
              className="p-col-2 p-pb-1"
              fontColor="color_60"
              fontSize="xxs"
            >
              Valor novo
            </SimpleText>
          </div>
        )}

        <SearchList.Root fetchApi={fetchApi}>
          <SearchList.InfiniteScroll
            reload={reload}
            renderRow={renderRow}
            autoHeight={false}
            height={320}
          />
        </SearchList.Root>
      </div>
    </div>
  );
};

export default HistoricoEdicoes;

interface MobileRowProps {
  item: IHistoricoUsuarioItem;
}

const MobileRow = ({ item }: MobileRowProps) => {
  return (
    <div className="p-w-100 p-grid">
      <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60">Data atualização:</SimpleText>
        <SimpleText>
          {item.dataAtualizacao
            ? utcToGMTString(new Date(item.dataAtualizacao))
            : ''}
        </SimpleText>
      </div>
      <div className="p-col-12 p-sm-6 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60">Tipo operação:</SimpleText>
        <SimpleText>{item.tipoOperacao}</SimpleText>
      </div>
      <div className="p-col-12 p-sm-6 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60">Campo:</SimpleText>
        <SimpleText>{item.nomeColuna}</SimpleText>
      </div>
      <div className="p-col-12 p-sm-6 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60">Valor anterior:</SimpleText>
        <SimpleText className="p-text-truncate">
          {item.valorAnterior}
        </SimpleText>
      </div>
      <div className="p-col-12 p-sm-6 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontColor="color_60">Valor novo:</SimpleText>
        <SimpleText className="p-text-truncate">{item.novoValor}</SimpleText>
      </div>
      <Separator />
    </div>
  );
};
