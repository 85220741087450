import { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { debounce } from 'lodash';
import ComunicadosAPI from 'src/APIs/AdminAPI/ComunicadosAPI/ComunicadosAPI';
import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';
import MedicoAPI from 'src/APIs/AdminAPI/MedicoAPI/MedicoAPI';
import * as Yup from 'yup';

import { useUser } from 'src/core/hooks/User/User';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import ConsultorioMultiSelect from '../../../Faturamento/components/ConsultorioMultiSelect';
import FieldProfissionais from '../../../Faturamento/components/FieldProfissionais';
import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { FieldCiapMultiselect } from 'src/components/Fields/FieldCiapMultiselect/FieldCiapMultiselect';
import { FieldCidsMultiselect } from 'src/components/Fields/FieldCidsMultiselect/FieldCidsMultiselect';
import FormInput from 'src/components/FormInput/FormInput';

import DialogPreviewComunicado from './DialogPreviewComunicado';

import './FormRecipient.scss';
import { yupResolver } from '@hookform/resolvers/yup';
const sexoOptions = [
  {
    label: 'Masculino',
    value: 3,
  },
  {
    label: 'Feminino',
    value: 1,
  },
];

const consultaOptions = [
  { label: '3 meses', value: 'TRIMESTRAL' },
  { label: '6 meses', value: 'SEMESTRAL' },
  { label: '1 ano', value: 'ANUAL' },
];

interface FormRecipientProps {
  values: any;
  handleBack: () => void;
  comunicado: any;
}

const FormRecipient = ({
  values,
  handleBack,
  comunicado,
}: FormRecipientProps) => {
  const { user } = useUser();

  const [consultoriosList, setConsultoriosList] = useState<GetConsultorioDTO[]>(
    [],
  );
  const [loadingMedicos, setLoadingMedicos] = useState(false);
  const [medicosOptions, setMedicosOptions] = useState<GetMedicoDTO[]>([]);

  const isMedico = user?.tipo !== 'SECRETARIO';

  const dialogPreview = useDisclosure({ opened: false });

  const validationSchema = Yup.object().shape({
    tipoMensagem: Yup.string()
      .required('O campo é obrigatório')
      .typeError('O campo é obrigatório'),
    dataEnvio: Yup.date()
      .required('Data é obrigatória')
      .typeError('Data é obrigatória'),
  });
  const useFormMethods = useForm<any>({
    defaultValues: {
      ...comunicado,
      dataEnvio: comunicado?.dataEnvio ? new Date(comunicado.dataEnvio) : null,
      idsMedicos: comunicado?.idsMedicos
        ? comunicado?.idsMedicos
        : isMedico
        ? [user?.id]
        : undefined,
      idsConsultorios: comunicado?.idsConsultorios
        ? comunicado?.idsConsultorios
        : [],
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    watch,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useFormMethods;

  const tipoMensagem = watch('tipoMensagem');
  const consultorios = watch('idsConsultorios');

  useEffect(() => {
    const loadUserConsultorios = async () => {
      try {
        const response = await ConsultorioAPI.loadUserConsultorios();
        setConsultoriosList(response);
      } catch (error) {
        console.error(error);
      }
    };
    loadUserConsultorios();
  }, []);

  const fetchMedicos = useCallback(async () => {
    const params = {
      idsConsultorios:
        consultorios?.includes('todos') || consultorios?.length === 0
          ? consultoriosList.map(consultorio => consultorio.id).join(',')
          : consultorios.join(','),
    };
    setLoadingMedicos(true);
    try {
      const response: any = await MedicoAPI.loadMedicos(params, {
        throwError: true,
      });
      const medicosArray: any = [];

      Object.keys(response).forEach((key: any) => {
        response[key].forEach((medico: any) => {
          if (
            !medicosArray.some(
              (existingMedico: any) => existingMedico.id === medico.id,
            )
          ) {
            medicosArray.push(medico);
          }
        });
      });

      setMedicosOptions(medicosArray);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMedicos(false);
    }
  }, [consultorios, consultoriosList]);

  const debouncedFetchMedicos = useCallback(debounce(fetchMedicos, 500), [
    fetchMedicos,
  ]);

  useEffectSkipFirst(() => {
    debouncedFetchMedicos();
    return () => {
      debouncedFetchMedicos.cancel();
    };
  }, [consultorios, debouncedFetchMedicos]);

  const onSubmit = async (data: any) => {
    const { idsConsultorios, idsCids, idsCiaps, dataEnvio, ...rest } = data;
    const { imagem, ...restValues } = values;
    const payload = {
      ...rest,
      ...restValues,
      idsConsultorios:
        idsConsultorios?.includes('todos') || idsConsultorios?.length === 0
          ? consultoriosList.map(consultorio => consultorio.id)
          : idsConsultorios,
      idsCids: idsCids?.map((cid: any) => cid.id),
      idsCiaps: idsCiaps?.map((ciap: any) => ciap.id),
      dataEnvio: new Date(dataEnvio).toISOString(),
    };

    try {
      const response = await ComunicadosAPI.loadPacientesComunicado(payload, {
        params: { pageSize: 9999 },
        hideToast: true,
      });

      const { list } = response;

      dialogPreview.open({
        state: { ...payload, imagem, list, ativo: true },
      });
    } catch (error) {
      console.error(error);
    }
    return;
  };

  return (
    <FormProvider {...useFormMethods}>
      <form
        id="form-recipient-marketing-medico"
        onSubmit={handleSubmit(onSubmit)}
        className="p-w-100 p-grid"
        noValidate
      >
        <DropdownControlled
          control={control}
          className="p-col-12 p-md-6 p-py-2 p-mb-1 border-mensagem"
          name="tipoMensagem"
          label="Tipo de mensagem"
          options={[
            { label: 'Campanha', value: 'CAMPANHA' },
            { label: 'Consulta', value: 'CONSULTAS' },
          ]}
          filter={false}
          errorMsg={errors.tipoMensagem?.message}
        />

        <SimpleText className="p-col-12" fontColor="color_60">
          Defina as opções de envio do comunicado
        </SimpleText>

        <ConsultorioMultiSelect
          className="p-col-12"
          name="idsConsultorios"
          somenteUsuario
          display="chip"
        />

        {!isMedico && (
          <FieldProfissionais
            name="idsMedicos"
            label="Médicos"
            className="p-col-12"
            multiple
            loading={loadingMedicos}
            options={medicosOptions}
            isMedico={isMedico}
          />
        )}

        <SimpleText className="p-col-12">
          O e-mail será enviado na data:
        </SimpleText>

        <CalendarInputControlled
          control={control}
          name="dataEnvio"
          label="Data e hora"
          placeholder="dd/mm/aaaa  hh:mm"
          className={`p-col-12 p-md-6 ${
            tipoMensagem === 'CONSULTA' ? 'border-mensagem' : ''
          }`}
          showIcon
          showTime
          errorMsg={errors.dataEnvio?.message}
          minDate={new Date()}
        />

        {tipoMensagem === 'CAMPANHA' ? <CampanhaFields /> : <ConsultaFields />}

        <div className="p-d-flex p-col-12 p-gap-2 p-mt-2">
          <Button
            label="Voltar"
            type="button"
            onClick={() => handleBack()}
            btnType="ghost"
            stretch
            loading={isSubmitting}
          />
          <Button
            label="Pré-visualizar"
            type="submit"
            stretch
            loading={isSubmitting}
          />
        </div>
      </form>
      {dialogPreview.isOpen && <DialogPreviewComunicado {...dialogPreview} />}
    </FormProvider>
  );
};

export default FormRecipient;

const CampanhaFields = () => {
  const { control } = useFormContext();
  return (
    <>
      <SimpleText className="p-col-12">
        O e-mail será enviado para todos os pacientes:
      </SimpleText>

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="idSexo"
        label="Sexo"
        placeholder="Selecione"
        options={sexoOptions}
      />

      <SimpleText className="p-col-12" medium>
        Faixa etária:
      </SimpleText>

      <FormInput
        name="faixaEtariaInicio"
        placeholder="Entre"
        type="number"
        className="p-col-6 p-md-3 border-mensagem"
        hideTextErrorSpace
      />

      <FormInput
        name="faixaEtariaFim"
        placeholder="Até"
        type="number"
        className="p-col-6 p-md-3"
        hideTextErrorSpace
      />

      <SimpleText className="p-col-12 p-mt-1" fontColor="color_60">
        Defina envio do e-mail por CID/CIAP{' '}
      </SimpleText>

      <FieldCidsMultiselect
        className="p-col-12 cid-ciap"
        name="idsCids"
        label="CID"
      />
      <FieldCiapMultiselect
        className="p-col-12 cid-ciap"
        name="idsCiaps"
        label="CIAP"
      />
    </>
  );
};

const ConsultaFields = () => {
  const { control } = useFormContext();
  return (
    <>
      <SimpleText className="p-col-12" fontColor="color_60">
        O e-mail será enviado para todos os pacientes que realizaram a ultima
        consulta à mais de:
      </SimpleText>

      <DropdownControlled
        className="p-col-12 p-md-6"
        control={control}
        name="ultimaConsulta"
        label="Ultima de consulta"
        placeholder="Selecione"
        options={consultaOptions}
      />
    </>
  );
};
