import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

interface PanelContentProps {
  item: IAgravoNotificacao;
}

const PanelContent = ({ item }: PanelContentProps) => {
  return (
    <div className="p-grid p-mt-1 p-p-2 bg-neutral-color br-8px">
      <div className="p-col-3 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Paciente
        </SimpleText>
        <SimpleText fontSize="xs" fontColor="color_16" className="p-js-center">
          {item.atendimento?.nomePaciente || ''}
        </SimpleText>
      </div>
      <div className="p-col-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          CPF
        </SimpleText>
        <SimpleText fontSize="xs" fontColor="color_16" className="p-js-center">
          {item.atendimento?.cpf || ''}
        </SimpleText>
      </div>
      <div className="p-col-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          CNS
        </SimpleText>
        <SimpleText fontSize="xs" fontColor="color_16" className="p-js-center">
          {item.atendimento?.cns || ''}
        </SimpleText>
      </div>
      <div className="p-col-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          Médico
        </SimpleText>
        <SimpleText fontSize="xs" fontColor="color_16" className="p-js-center">
          {item.atendimento.nomeMedico || ''}
        </SimpleText>
      </div>
      <div className="p-col p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          CRM
        </SimpleText>
        <SimpleText fontSize="xs" fontColor="color_16" className="p-js-center">
          {item.atendimento.cfmUf || ''}
        </SimpleText>
      </div>
      <div className="p-col-2 p-d-flex p-flex-column p-gap-2">
        <SimpleText fontSize="xxs" fontColor="color_60">
          CNES
        </SimpleText>
        <SimpleText fontSize="xs" fontColor="color_16" className="p-js-center">
          {item.atendimento.cnes || ''}
        </SimpleText>
      </div>
    </div>
  );
};

export default PanelContent;
