import { useState } from 'react';

import { MonitoramentoSMSAPI } from 'src/APIs/AgendaAPI/MonitoramentoSMS/MonitoramentoSMSAPI';

import useSize from 'src/core/hooks/useSize';

import Page from 'src/components/Basics/Page/Page';
import PageHeader from 'src/components/PageHeader/PageHeader';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { MonitoramentoSMSFilters } from './MonitoramentoSMSFilters';
import { MonitoramentoSMSHeader } from './MonitoramentoSMSHeader';
import { MonitoramentoSMSRow } from './MonitoramentoSMSRow';

import './MonitoramentoSMS.scss';

export const MonitoramentoSMS = () => {
  const [loading, setLoading] = useState(false);
  const { isTablet } = useSize();

  const fetchApi = async ({ ...params }: any) => {
    try {
      setLoading(true);
      const response = await MonitoramentoSMSAPI.listMonitoramentoSMS(
        params || {},
      );
      return response;
    } catch (error) {
      return console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page>
      <PageHeader
        title="Monitoramento SMS"
        subtitle="Gerencie SMS enviados pelo sistema"
      />

      <Page content white style={{ height: '100%' }}>
        <SearchList.Root fetchApi={fetchApi}>
          <MonitoramentoSMSFilters loading={loading} />

          <SearchList.BorderContainer>
            <div className="p-d-flex p-w-100 p-jc-end">
              <SearchList.TotalCount />
            </div>

            {!isTablet && <MonitoramentoSMSHeader />}

            <SearchList.InfiniteScroll
              renderRow={(item: IMonitoramentoSMSItem) => (
                <MonitoramentoSMSRow item={item} />
              )}
            />
          </SearchList.BorderContainer>
        </SearchList.Root>
      </Page>
    </Page>
  );
};
