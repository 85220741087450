import React, { useState } from 'react';

import { ContasReceberAPI } from 'src/APIs/FinanceiroAPI/ContasReceberAPI/ContasReceberAPI';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import { Button, RadioButton } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import Dialog from 'src/components/Dialog/Dialog';

import { useSelectedContaReceber } from './useSelectedContaReceber';

interface ModalAlterarStatusProps extends DisclosureType {
  isOpen: boolean;
  close: () => void;
}

const ModalAlterarStatus = ({ isOpen, close }: ModalAlterarStatusProps) => {
  const [status, setStatus] = useState('PENDENTE');

  const { selectedContaReceber, setReload, setSelectedContaReceber } =
    useSelectedContaReceber();

  const statusOptions = [
    { label: 'A receber', value: 'A_RECEBER' },
    { label: 'Recebido', value: 'RECEBIDO' },
  ];

  const handleChangeStatusGeral = async (novoStatus: string) => {
    try {
      const idsContas = selectedContaReceber.map((conta: any) => conta.id);
      await ContasReceberAPI.putStatusConta({
        idsContaAReceber: idsContas,
        status: novoStatus,
      });
      setReload((prev: any) => prev + 1);
      setSelectedContaReceber([]);
      return close();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      type="modal"
      onHide={() => close()}
      visible={isOpen}
      header="Alterar status geral"
      maximizedMobileSize
      className="modal-alterar-status"
    >
      <div className="p-grid">
        <div className="p-col-12">
          <SimpleText>Alterar o status de todos os registros para:</SimpleText>
        </div>
        <div className="p-col-12 p-py-0">
          {statusOptions.map(({ value, label }) => (
            <RadioButton
              key={value}
              value={value}
              checked={status === value}
              onChange={() => setStatus(value)}
              label={label}
            />
          ))}
        </div>
        <div className="p-col-12 p-d-flex p-gap-2 p-ai-center p-mt-1">
          <Button
            label="Cancelar"
            btnType="ghost"
            onClick={() => close()}
            stretch
          />
          <Button
            label="Salvar"
            onClick={() => {
              handleChangeStatusGeral(status);
            }}
            stretch
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ModalAlterarStatus;
