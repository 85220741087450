import { useCallback, useEffect, useState, useRef } from 'react';

import { ToggleButton } from 'primereact/togglebutton';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';
import * as Yup from 'yup';

import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import Mention from '../../../components/Mention/Mention';
import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import ModalModelo from 'src/pages/Emed/Prontuario/components/ModalModelo/ModalModelo';
import { ModalNovoModelo } from 'src/pages/Emed/Prontuario/components/ModalNovoModelo/ModalNovoModelo';

import './Anamnese.scss';

interface AnamneseProps {
  prontuario: any;
  campos: any[];
  getCampos: any;
  updateCamposProntuario: (campos: any) => void;
  ndnAnamnese?: any;
  selectedSubjetivos: any;
  canPin?: boolean;
  doNotValidate?: boolean;
}

function Anamnese({
  campos,
  prontuario,
  updateCamposProntuario,
  ndnAnamnese,
  selectedSubjetivos,
  canPin = true,
  doNotValidate = false,
}: AnamneseProps) {
  const { state } = useLocation();

  const hasLoaded = useRef(false);
  const idAtendimento = state?.idAtendimento;
  const { agenda } = useAppSelector((state: RootState) => state);
  const { modelos, getModelos, setModelos } = useAtendimento();

  const validationSchema = Yup.object().shape({
    valorCampoProntuario: Yup.string(),
  });

  const {
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [valorCampoProntuario, setValorCampoProntuario] = useState<string>('');
  const [campoAnamnese, setCampoAnamnese] = useState<any>({});
  const [modelosAnamnese, setModelosAnamnese] = useState<any[]>([]);
  const [checked, setChecked] = useState<boolean>(
    campoAnamnese?.exibicaoCamposModelo === 'FIXO' ? true : false,
  );

  const modalDisclosure = useDisclosure({ opened: false });

  const handleOnBlur = useCallback(
    async (data: any) => {
      const saveAction = (payload: any) => {
        return UtilsAPI.putCampos(Number(idAtendimento), payload);
      };

      const payload = {
        idAtendimento: Number(idAtendimento),
        camposModeloProntuario: 'ANAMNESE',
        valorCampoProntuario: data,
        idPaciente: agenda?.idPaciente,
        idProfissionalSaude: agenda.profissionalAtivo?.id,
      };

      updateCamposProntuario({ ANAMNESE: data });

      await saveAction(payload);
    },
    [
      agenda?.idPaciente,
      agenda.profissionalAtivo?.id,
      idAtendimento,
      updateCamposProntuario,
    ],
  );

  const loadModeloPadrao = useCallback(() => {
    if (prontuario.anamnese?.length > 0) {
      setValorCampoProntuario(prontuario.anamnese);
    } else {
      modelos?.forEach(modelo => {
        if (modelo.campo === 'ANAMNESE' && modelo.padrao) {
          setValorCampoProntuario(modelo.texto + '\n');
          handleOnBlur(modelo.texto + '\n');
        }
      });
    }
  }, [modelos, prontuario.anamnese, handleOnBlur]);

  const loadModelosAnamnese = useCallback(() => {
    const modelosCampo: any[] = [];
    modelos?.forEach(modelo => {
      if (modelo.campo === 'ANAMNESE') {
        modelosCampo.push(modelo);
      }
    });
    setModelosAnamnese(modelosCampo);
  }, [modelos]);

  const passData = useCallback(
    (data: any) => {
      setValorCampoProntuario(prev => prev + data.texto + '\n');
      handleOnBlur(valorCampoProntuario + data.texto + '\n');
    },
    [handleOnBlur, valorCampoProntuario],
  );

  useEffect(() => {
    if (ndnAnamnese) {
      const ndn = () => {
        if (
          (valorCampoProntuario?.length < 1 &&
            selectedSubjetivos.includes('ANAMNESE')) ||
          (valorCampoProntuario === null &&
            selectedSubjetivos.includes('ANAMNESE'))
        ) {
          setValorCampoProntuario('Nada digno de nota');
          handleOnBlur('Nada digno de nota');
        }
      };

      ndnAnamnese.current = ndn;
    }
  }, [handleOnBlur, ndnAnamnese, selectedSubjetivos, valorCampoProntuario]);

  useEffect(() => {
    setCampoAnamnese(
      campos.filter(campo => campo.funcionalidade === 'ANAMNESE')[0],
    );
  }, [campos]);

  useEffect(() => {
    if (!hasLoaded.current) {
      loadModeloPadrao();
      hasLoaded.current = true;
    }
  }, [loadModeloPadrao]);

  useEffect(() => {
    setChecked(campoAnamnese?.exibicaoCamposModelo === 'FIXO' ? true : false);
    loadModelosAnamnese();
  }, [campoAnamnese, getModelos, loadModelosAnamnese]);

  const reloadCampos = () => {
    if (campoAnamnese.exibicaoCamposModelo === 'FIXO') {
      campoAnamnese.exibicaoCamposModelo = 'NENHUM';
    } else {
      campoAnamnese.exibicaoCamposModelo = 'FIXO';
    }
  };

  const handleFixar = async () => {
    if (campoAnamnese.obrigatorio) {
      toast.error('Não é possível desfixar um campo obrigatório');
      return;
    }
    const exibixaoCampo =
      campoAnamnese.exibicaoCamposModelo === 'FIXO' ? 'NENHUM' : 'FIXO';
    const response = await UtilsAPI.putTipoExibicaoCampos(
      Number(agenda?.profissionalAtivo?.id),
      String(campoAnamnese.funcionalidade),
      exibixaoCampo,
    );
    if (response?.status === 200) {
      reloadCampos();
    }
  };

  const isRequired = !!campoAnamnese?.obrigatorio;

  return (
    <>
      <div className="p-d-flex p-jc-between">
        <div className="p-as-end">
          <SimpleText
            className="p-ml-2"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Anamnese
          </SimpleText>
          {isRequired && !doNotValidate && (
            <SimpleText
              className="p-ml-2 texto-obrigatorio"
              fontSize={FONT_SIZE.XXXS}
            >
              (Obrigatório)
            </SimpleText>
          )}
        </div>
        <div className="p-d-flex modelos">
          <Button
            btnType="green-link"
            label="Modelo"
            iconPos="right"
            icon="pi pi-chevron-down"
            overlay={
              <ModalModelo
                onOpenModal={modalDisclosure.open}
                modelos={modelosAnamnese}
                passData={passData}
                valorCampoProntuario={valorCampoProntuario}
                setValorCampoProntuario={setValorCampoProntuario}
              />
            }
          />

          {canPin && (
            <ToggleButton
              checked={checked}
              className={`p-px-4 p-py-1 pin ${
                isRequired ? 'pin-disabled' : ''
              }`}
              onLabel={''}
              offLabel={''}
              onChange={e =>
                !isRequired ? [handleFixar(), setChecked(e.value)] : null
              }
              onIcon="fa fa-thumbtack"
              offIcon="fa fa-thumbtack"
            />
          )}
        </div>
      </div>
      <div className="text-area p-pb-1">
        <form id="form-anamnese" className="p-mt-3">
          <Mention
            valorCampoProntuario={valorCampoProntuario}
            setValorCampoProntuario={setValorCampoProntuario}
            handleOnBlur={handleOnBlur}
          />
        </form>
      </div>

      {modalDisclosure.isOpen && (
        <ModalNovoModelo
          valorCampoProntuario={valorCampoProntuario}
          setValorCampoProntuario={setValorCampoProntuario}
          tipoCampo="ANAMNESE"
          {...modalDisclosure}
        />
      )}
    </>
  );
}

export default Anamnese;
