import { useMemo } from 'react';

import { Handle, Node, Position } from '@xyflow/react';

import {
  calculateConnectorX,
  calculateConnectorY,
  calculateConnectorYGestation,
  formatFamiliarNome,
  formatVinculoFamiliar,
  getCustomNodeVariant,
  getHandlers,
  layersResponse,
} from '../utils';

import { CustomNodeDataProps } from '../_components/nodes';

import { useGenograma } from './useGenograma';

export const useInitialNodes = (): Node<CustomNodeDataProps>[] => {
  const { data, viewMode } = useGenograma();

  const geracoes = useMemo(() => data?.geracoes || [], [data]);

  const initialNodesByLayer = useMemo(() => {
    return geracoes.map((camada, indexCamadas, arrCamadas) => {
      //* Grouping gestations based on the same parents (idMae & idPai)
      const gestacao = camada.familiares
        .map(el => el)
        .reduce((acc: any, familiar: IGenogramaFamiliar) => {
          if (familiar.idPai && familiar.idMae) {
            // Check if gestation already exists
            const exists = acc.find(
              (fam: any) =>
                fam.idPai === familiar.idPai && fam.idMae === familiar.idMae,
            );
            if (exists) {
              // if exists, add the familiar to the existing gestation
              exists.idFamiliar.push(familiar.idFamiliar);
            } else {
              // if not, create a new gestation
              acc.push({
                idGestacao: `${familiar.idMae}_${familiar.idPai}`,
                idFamiliar: [familiar.idFamiliar],
                idMae: familiar.idMae,
                idPai: familiar.idPai,
              });
            }
          }
          return acc;
        }, []);

      const nodes = camada.familiares.map((familiar, index, arr) => {
        const layer = layersResponse[camada.nivel.toString()]!;
        // Calculate the total of nodes in the previous layer
        const previousArrFamilyLength = indexCamadas
          ? arrCamadas
              .filter(cam => cam.nivel === camada.nivel + 1)
              .reduce((acc, cam) => acc + cam.familiares.length, 0)
          : 0;

        const y = calculateConnectorY(layer);
        const x = calculateConnectorX(
          arr.length,
          index,
          previousArrFamilyLength,
        );

        const { handlers } = getHandlers(familiar, layer, arr);
        const label =
          viewMode === 'nomes'
            ? formatFamiliarNome(familiar.nome)
            : formatVinculoFamiliar(familiar.vinculoFamiliar) || 'Paciente';

        return {
          id: familiar.idFamiliar,
          type: 'customNode',
          data: {
            idFamiliar: familiar.idFamiliar,
            idIrmaoGemeo: familiar.idIrmaoGemeo,
            label,
            isPatient: familiar.idFamiliar === '0',
            variant: getCustomNodeVariant(familiar),
            handlers: handlers,
            cids: familiar.cids,
            filiacao: familiar.filiacao,
            dates: {
              birth: familiar.dataNascimento!,
              death: familiar.dataObito,
            },
            x,
            y,
          },
          position: { x, y },
        } as Node<CustomNodeDataProps>;
      });

      return {
        nivel: camada.nivel,
        layer: layersResponse[camada.nivel.toString()]!,
        gestacao: gestacao,
        nodes: nodes,
      };
    });
  }, [geracoes, viewMode]);

  const parentNodePositions = useMemo(() => {
    return initialNodesByLayer.reduce(
      (acc: Record<string, { x: number; y: number }>, layer) => {
        layer.nodes.forEach((node: Node<CustomNodeDataProps>) => {
          acc[node.data.idFamiliar] = node.position;
        });
        return acc;
      },
      {},
    );
  }, [initialNodesByLayer]);

  const nodes = useMemo(() => {
    return initialNodesByLayer.flatMap(layer => {
      const gestationNodes = layer.gestacao.map((gestation: any) => {
        const parentXPositions = [
          parentNodePositions[gestation.idMae]?.x || 0,
          parentNodePositions[gestation.idPai]?.x || 0,
        ].filter(x => x !== undefined);

        const x = parentXPositions.length
          ? parentXPositions.reduce((acc: number, x: number) => acc + x) / 2 +
            50
          : 0;

        const y = calculateConnectorYGestation(layer.layer);

        return {
          id: gestation.idGestacao,
          type: 'gestation',
          position: { x, y },
          data: {
            idFamiliar: gestation.idFamiliar,
            idMae: gestation.idMae,
            idPai: gestation.idPai,
            handlers: [
              <Handle
                position={Position.Left}
                id="left"
                type="target"
                key="left"
              />,
              <Handle
                position={Position.Right}
                id="right"
                type="target"
                key="right"
              />,
              <Handle
                position={Position.Bottom}
                id="bottom"
                type="target"
                key="bottom"
              />,
            ],
          },
        } as Node<CustomNodeDataProps>;
      });

      return [...layer.nodes, ...gestationNodes];
    });
  }, [initialNodesByLayer, parentNodePositions]);

  return nodes;
};
