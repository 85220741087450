import { useEffect, useState, useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';
import FornecedorTussAPI from 'src/APIs/AdminAPI/FornecedorTussAPI/FornecedorTussAPI';
import CentroCustoAPI from 'src/APIs/FinanceiroAPI/CentroCustoAPI/CentroCustoAPI';
import TipoContaPagarAPI from 'src/APIs/FinanceiroAPI/TipoContaPagarAPI/TipoContaPagarAPI';

import ConsultorioMultiSelect from '../../../../components/ConsultorioMultiSelect';
import { Button } from 'src/components/_UI';
import CalendarInputControlled from 'src/components/Basics/CalendarInputControlled/CalendarInputControlled';
import MultipleCheckboxControlled from 'src/components/Basics/Checkbox/MultipleCheckboxControlled';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { useSearchList } from 'src/components/SearchList/SearchListContext';

const optionsStatus = [
  { label: 'A pagar', value: 'A_PAGAR' },
  { label: 'Paga', value: 'PAGA' },
  { label: 'Parcialmente paga', value: 'PARCIALMENTE_PAGA' },
];

interface PesquisaAvancadaProps {
  onSuccess?: () => void;
  consultoriosList?: any[];
  optionsCentroCusto: any[];
  setFiltroPesquisa: (filtroPesquisa: any) => void;
}

export const PesquisaAvancada = ({
  onSuccess,
  consultoriosList,
  optionsCentroCusto,
  setFiltroPesquisa,
}: PesquisaAvancadaProps) => {
  const [tipoContaOptions, setTipoContaOptions] = useState<any[]>([]);
  const [optionsFornecedores, setoptionsFornecedores] = useState<
    { label: string; value: number }[]
  >([]);

  const { setFilter } = useSearchList();
  const form = useFormContext();
  const { control, handleSubmit, watch, getValues } = form;

  const watchConsultorios = watch('idsConsultorios');

  const fetchTipoConta = useCallback(async () => {
    if (!watchConsultorios) {
      return setTipoContaOptions([]);
    }
    try {
      const response = await TipoContaPagarAPI.getTipoContaPagar({
        ...(watchConsultorios.length > 0 &&
          !watchConsultorios.includes('todos') && {
            idsConsultorios: watchConsultorios.join(','),
          }),
        ativo: true,
      });
      setTipoContaOptions(response.list);
    } catch (error) {
      console.error(error);
      setTipoContaOptions([]);
    }
  }, [watchConsultorios]);

  const fetchFornecedores = useCallback(async () => {
    try {
      const response = await FornecedorTussAPI.loadFornecedorTuss({});
      const formatedData = response.map((f: any) => ({
        label: f.nome,
        value: f.id,
      }));
      setoptionsFornecedores(formatedData);
    } catch (error) {
      setoptionsFornecedores([]);
    }
  }, []);

  const onSubmit = () => {
    const v = getValues();
    const {
      dataVencimentoInicio,
      dataVencimentoFim,
      idsConsultorios,
      dataPagamento,
      status,
      ...rest
    } = v;
    const params = {
      ...rest,
      ...(dataPagamento && {
        dataPagamento: dayjs(dataPagamento).format('YYYY-MM-DD'),
      }),
      dataVencimentoInicio: dataVencimentoInicio
        ? dayjs(dataVencimentoInicio).format('YYYY-MM-DD')
        : undefined,
      dataVencimentoFim: dataVencimentoFim
        ? dayjs(dataVencimentoFim).format('YYYY-MM-DD')
        : undefined,
      idsConsultorios: idsConsultorios?.includes('todos')
        ? consultoriosList?.map((c: any) => c.id).join(',')
        : idsConsultorios?.join(','),
      status: status?.join(','),
    };
    setFilter(params);
    setFiltroPesquisa(params);
    onSuccess?.();
  };

  useEffect(() => {
    fetchTipoConta();
  }, [fetchTipoConta]);

  useEffect(() => {
    fetchFornecedores();
  }, [fetchFornecedores]);

  return (
    <div
      className="p-p-1"
      style={{
        minWidth: '350px',
        maxWidth: '500px',
        borderRadius: '18px',
      }}
    >
      <div className="p-grid">
        <SimpleText fontColor="color_40" className="p-my-2">
          Pesquise de forma detalhada, preenchendo um ou mais dos campos abaixo.
        </SimpleText>

        <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
          <DropdownControlled
            className="p-w-100"
            control={control}
            name="idsFornecedores"
            label="Fornecedor"
            options={optionsFornecedores}
          />
        </div>

        <div className="p-grid p-col-12 p-px-0  ">
          <ConsultorioMultiSelect
            className="p-col-6 p-d-flex p-flex-column p-gap-1 p-my-1 p-pl-0 p-pr-1"
            name="idsConsultorios"
          />

          <div className="p-col-6 p-mt-1 p-d-flex p-pr-0 p-pl-1">
            <DropdownControlled
              control={control}
              name="idsCentroCusto"
              label="Centro de custo"
              options={optionsCentroCusto || []}
              optionLabel="nome"
              optionValue="id"
              className="p-w-100"
              disabled={!watchConsultorios?.length || !optionsCentroCusto}
              panelClassName="p-text-truncate"
            />
          </div>
        </div>

        <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
          <DropdownControlled
            control={control}
            name="idsTipoContaPagar"
            label="Tipo de conta a pagar"
            options={tipoContaOptions || []}
            optionLabel="tipo"
            optionValue="id"
            className="p-w-100"
            disabled={!watchConsultorios?.length || !tipoContaOptions}
            panelClassName="p-text-truncate"
          />
        </div>

        <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
          <CalendarInputControlled
            className="p-w-100"
            control={control}
            name="dataVencimentoInicio"
            label="Data vencimento"
            showIcon
          />
          <CalendarInputControlled
            className="p-w-100"
            control={control}
            name="dataPagamento"
            label="Data pagamento"
            showIcon
          />
        </div>

        <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
          <MultipleCheckboxControlled
            className="p-w-100 p-ml-0"
            name="status"
            label="Status"
            options={optionsStatus}
            classNameContainer="p-d-flex p-gap-2 p-ml-0"
          />
        </div>

        <div className="p-d-flex p-gap-2 p-w-100">
          <Button
            label="Cancelar"
            btnType="outline"
            stretch
            onClick={onSuccess}
          />
          <Button
            type="button"
            label="Pesquisar"
            onClick={() => handleSubmit(onSubmit)()}
            stretch
          />
        </div>
      </div>
    </div>
  );
};
