import { useState } from 'react';

import { ChatAPI } from 'src/APIs/ChatAPI/ChatAPI';

import { useAppDispatch } from 'src/core/redux/hooks';
import { clearProfissionais } from 'src/core/redux/slices/agenda/AgendaSlice';
import { clearUser } from 'src/core/redux/slices/system/UserSlice';
import { clearConsultorios } from 'src/core/redux/slices/user/ConsultoriosSlice';

import { Button } from 'src/components/_UI/Button';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

const LogoutConfigSubMenu = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleChangeChatStatus = async () => {
    setLoading(true);
    return ChatAPI.toggleChatStatus('OFFLINE', {
      errorHideToast: true,
    }).finally(() => setLoading(false));
  };

  return (
    <div
      className={'ItemsConfigSubMenu'}
      onClick={async () => {
        await handleChangeChatStatus();
        dispatch(clearUser());
        dispatch(clearProfissionais());
        dispatch(clearConsultorios());
      }}
    >
      <Button icon={'pi pi-sign-out'} btnType="gray" loading={loading} />
      <div className={'content'}>
        <SimpleText bold>Sair do eMed</SimpleText>
      </div>
    </div>
  );
};

export default LogoutConfigSubMenu;
