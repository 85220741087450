import { useFieldArray, useFormContext } from 'react-hook-form';

import { generateUniqueId } from 'src/utils/utils';

import { Button } from 'src/components/_UI';
import CheckboxControlled from 'src/components/Basics/CheckboxControlled/CheckboxControlled';
import { FieldServicoTuss } from 'src/components/Fields/FieldServicoTuss/FieldServicoTuss';
import FormInput from 'src/components/FormInput/FormInput';
import Separator from 'src/components/Separator/Separator';

const ServicosTuss = () => {
  const { control } = useFormContext();

  const {
    fields: servicoFields,
    append: appendServicoField,
    remove: removeServicoField,
  } = useFieldArray({
    control,
    name: 'servicos',
  });

  const addServico = () => {
    appendServicoField({
      servicoTuss: null,
      guia: '',
      quantidade: 0,
      valor: 0,
      valorGlosa: 0,
      autogerado: false,
    });
  };

  return (
    <div className="Servicos">
      {servicoFields?.map((servico: any, index: any) => (
        <div key={servico.id || generateUniqueId()}>
          <FieldServicoTuss
            name={`servicos.${index}.servicoTuss`}
            label="Serviço TUSS"
            placeholder="Selecione"
            returnType="all"
            className="p-w-100 p-my-2"
            params={{ status: true }}
            showCodigo
          />

          <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
            <FormInput
              name={`servicos.${index}.guia`}
              label="Guia"
              className="p-w-100"
              maxLength={255}
            />
            <FormInput
              name={`servicos.${index}.quantidade`}
              label="Quantidade"
              type="number"
              className="p-w-100"
              maxLength={255}
            />
          </div>
          <div className="p-my-1 p-d-flex p-gap-2 p-w-100">
            <FormInput
              name={`servicos.${index}.valor`}
              label="Valor a receber"
              type="number"
              className="p-w-100"
              placeholder="R$"
              mode="currency"
            />
            <FormInput
              name={`servicos.${index}.valorGlosa`}
              label="Valor recebido"
              type="number"
              className="p-w-100"
              placeholder="R$"
              mode="currency"
            />
          </div>

          <CheckboxControlled
            name={`servicos.${index}.autogerado`}
            control={control}
            label="Serviço autogerado"
            checked={servico.autogerado}
          />

          <Button
            label="Remover"
            icon="fas fa-minus"
            type="button"
            btnType="danger-link"
            onClick={() => removeServicoField(index)}
          />
          <Separator />
        </div>
      ))}
      <Button
        label="Adicionar serviços"
        icon="fas fa-plus"
        type="button"
        btnType="green-link"
        onClick={addServico}
      />
    </div>
  );
};

export default ServicosTuss;
