import { useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router';

import UsuarioAPI from 'src/APIs/AdminAPI/UsuarioAPI/UsuarioAPI';
import ProntuarioAPI from 'src/APIs/ProntuarioAPI/ProntuarioAPI';
import RegistroObitoAPI from 'src/APIs/ProntuarioAPI/RegistroObito/RegistroObitoAPI';

import { AGAProvider } from 'src/core/containers/AGA/AGAProvider';
import { AtendimentoProvider } from 'src/core/containers/Atendimento/AtendimentoProvider';
import { HistoricoAtendimentoProvider } from 'src/core/containers/Atendimento/HistoricoAtendimentoProvider';
import { MemedProvider } from 'src/core/containers/Atendimento/MemedProvider';
import { PreNatalProvider } from 'src/core/containers/Atendimento/PreNatalProvider';
import { useAGA } from 'src/core/hooks/AGA/useAGA';
import { useAtendimento } from 'src/core/hooks/Atendimento/useAtendimento';
import { usePreNatal } from 'src/core/hooks/Atendimento/usePreNatal';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { TelemedicinaIFrame } from './Atendimento/Telemedicina/components/IFrameTelemedicina';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Tab, { TabItem } from 'src/components/Tab/Tab';

import AGA from './AGA/AGA';
import Atendimento from './Atendimento/Atendimento';
import { HistoricoAtendimentos } from './Atendimento/HistoricoAtendimentos/HistoricoAtendimentos';
import { AtendimentoGrupo } from './AtendimentoGrupo/AtendimentoGrupo';
import Cabecalho from './Cabecalho/Cabecalho';
import FichaPaciente from './FichaPaciente/FichaPaciente';
import { Formularios } from './Formularios/Formularios';
import { PlanoDeCuidado } from './PlanoDeCuidado/PlanoDeCuidado';
import PreNatal from './PreNatal/PreNatal';
import Puericultura from './Puericultura/Puericultura';
import { SolicitacaoExameCirurgia } from './SolicitacaoExameCirurgia/SolicitacaoExameCirurgia';

import './Prontuario.scss';
import { checkAuth } from 'src/utils/utils';

import GenogramaFamiliar from './GenogramaFamiliar/GenogramaFamiliar';

function Prontuario() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;

  const {
    setAtendimentoStatus,
    atendimentoStatus,
    setIsTeleatendimento,
    setTabValue,
    tabValue,
    teleconsultaState,
    setIdConvenioAtendimento,
    setIdConvenioProcedimentos,
    setNumeroCartaoAtendimentoParticular,
    setPaciente,
    paciente,
    isFormularioDirty,
    campos,
    modalAgravo,
  } = useAtendimento();
  const { loadPreNatal } = usePreNatal();

  const {
    user,
    agenda,
    user: { authorities },
  } = useAppSelector((state: RootState) => state);

  const { isEdit, scrollToTopAGA } = useAGA();

  const [dadosCabecalho, setDadosCabecalho] = useState<Prontuario>();
  const [dadosRegistroObito, setDadosRegistroObito] =
    useState<GetRegistroObito | null>(null);
  const [usuario, setUsuario] = useState<GetUsuarioDTO | null>(null);
  const [carregandoDados, setCarregandoDados] = useState<boolean>(true);
  const [isAtendimentoGrupo, setIsAtendimentoGrupo] = useState<boolean>(false);

  const confirmDialogAGADisclosure = useDisclosure({ opened: false });
  const confirmDialogFormularioDisclosure = useDisclosure({ opened: false });

  const fetch = useCallback(
    async (idAtendimento, idUsuario) => {
      const [responseCabecalho, responseUsuario] = await Promise.all([
        ProntuarioAPI.getCabecalhoProntuario(idAtendimento),
        UsuarioAPI.loadUsuarioById(idUsuario),
      ]);

      if (
        responseCabecalho?.pacientesGrupo?.length ||
        state?.isEvolucaoIndividual
      ) {
        setAtendimentoStatus(responseCabecalho.atendimento.status);
        setDadosCabecalho(responseCabecalho);
        setIsAtendimentoGrupo(true);

        setCarregandoDados(false);
        return;
      }

      if (!responseCabecalho?.paciente?.id) {
        return navigate('/agenda');
      }
      setAtendimentoStatus(responseCabecalho.atendimento.status);
      setIdConvenioAtendimento(responseCabecalho.atendimento.idConvenio);
      setIdConvenioProcedimentos(
        responseCabecalho.atendimento.idConvenioProcedimentos,
      );
      setNumeroCartaoAtendimentoParticular(
        responseCabecalho?.atendimento?.cartaoPlanoProcedimentos ?? null,
      );
      setDadosCabecalho(responseCabecalho);
      setUsuario(responseUsuario);

      if (responseCabecalho?.paciente?.id) {
        setAtendimentoStatus(responseCabecalho.atendimento.status);
        setIdConvenioAtendimento(responseCabecalho.atendimento.idConvenio);
        setIdConvenioProcedimentos(
          responseCabecalho.atendimento.idConvenioProcedimentos,
        );
        setDadosCabecalho(responseCabecalho);
        setUsuario(responseUsuario);

        !paciente && setPaciente(responseCabecalho?.paciente);

        if (responseCabecalho?.atendimento?.telemedicina) {
          setIsTeleatendimento(true);
        }

        const responseRegistroObito =
          await RegistroObitoAPI.getRegistroObitoByIdPaciente(
            responseCabecalho.paciente.id,
          );

        if (responseRegistroObito && !('status' in responseRegistroObito)) {
          setDadosRegistroObito(responseRegistroObito);
        }

        setCarregandoDados(false);
        return;
      }

      return navigate('/agenda');
    },
    [
      navigate,
      paciente,
      setAtendimentoStatus,
      setIdConvenioAtendimento,
      setIdConvenioProcedimentos,
      setIsTeleatendimento,
      setNumeroCartaoAtendimentoParticular,
      setPaciente,
      state?.isEvolucaoIndividual,
    ],
  );

  useEffect(() => {
    if (!state?.idAtendimento || !user?.idUsuario) return navigate('/agenda');

    fetch(Number(idAtendimento), user?.idUsuario);
  }, [idAtendimento, user?.idUsuario, fetch, navigate, state?.idAtendimento]);

  useEffect(() => {
    if (
      agenda?.profissionalAtivo?.profissionalSaude?.atendeObstetricia &&
      dadosCabecalho?.paciente?.id
    ) {
      loadPreNatal(dadosCabecalho?.paciente?.id);
    }
  }, [
    agenda?.profissionalAtivo?.profissionalSaude?.atendeObstetricia,
    dadosCabecalho?.paciente?.id,
    loadPreNatal,
  ]);

  function onSuccessRegistroObito(novosDadosRegistroObito: GetRegistroObito) {
    setDadosRegistroObito(novosDadosRegistroObito);
  }

  const changeTabIndex = (target: string) => {
    const targetTabIndex = tabs.findIndex(tab => tab.label === target);
    setTabValue(targetTabIndex === -1 ? 0 : targetTabIndex);
  };

  const tabsPrescricao = [
    {
      label: 'Ficha do paciente',
      content: (
        <FichaPaciente
          idPaciente={dadosCabecalho?.paciente?.id || null}
          paciente={dadosCabecalho?.paciente || null}
          usuario={usuario}
          changeTabIndex={changeTabIndex}
          isPrescricao
        />
      ),
    },
  ];

  const tabs = [
    {
      label: 'Atendimento',
      content: (
        <Atendimento
          usuario={usuario}
          pacienteObito={!!dadosRegistroObito}
          carregandoDados={carregandoDados}
          idPaciente={dadosCabecalho?.paciente?.id || null}
        />
      ),
      funcionalidade: 'ATENDIMENTO',
    },
    {
      label: 'Ficha do paciente',
      content: (
        <FichaPaciente
          idPaciente={dadosCabecalho?.paciente?.id || null}
          paciente={dadosCabecalho?.paciente || null}
          usuario={usuario}
          changeTabIndex={changeTabIndex}
        />
      ),
      funcionalidade: 'FICHA_PACIENTE',
    },
    {
      label: 'Idoso bem cuidado',
      content: (
        <AGA
          idPaciente={dadosCabecalho?.paciente?.id || null}
          idadePaciente={dadosCabecalho?.paciente?.idadeAnos || null}
          usuario={usuario}
        />
      ),
      funcionalidade: 'IDOSO_BEM_CUIDADO_AGA',
    },
    {
      label: 'Puericultura',
      content: (
        <Puericultura
          paciente={dadosCabecalho?.paciente || null}
          idAtendimento={idAtendimento}
          idPaciente={dadosCabecalho?.paciente?.id || null}
          usuario={usuario}
        />
      ),
      funcionalidade: 'PUERICULTURA',
    },
    {
      label: 'Solicitação de exames e cirurgias',
      content: (
        <>
          <SolicitacaoExameCirurgia />
          <HistoricoAtendimentoProvider>
            <HistoricoAtendimentos
              usuario={usuario}
              idPaciente={dadosCabecalho?.paciente?.id || null}
            />
          </HistoricoAtendimentoProvider>
        </>
      ),
      funcionalidade: 'SOLICITACAO_EXAMES_CIRURGIAS',
    },
    {
      label: 'Plano de cuidado',
      content: (
        <>
          <PlanoDeCuidado idPaciente={dadosCabecalho?.paciente?.id || null} />

          <HistoricoAtendimentoProvider>
            <HistoricoAtendimentos
              usuario={usuario}
              idPaciente={dadosCabecalho?.paciente?.id || null}
            />
          </HistoricoAtendimentoProvider>
        </>
      ),
      funcionalidade: 'PLANO_DE_CUIDADO',
    },
    {
      label: 'Formulários',
      content: (
        <Formularios idPaciente={dadosCabecalho?.paciente?.id || null} />
      ),
      checkPermission: 'PRO_PREENCHER_FORMULARIO_DINAMICO_PRONTUARIO',
    },
    {
      label: 'Familiar e Genograma',
      content: (
        <GenogramaFamiliar idPaciente={dadosCabecalho?.paciente?.id || null} />
      ),
      // TODO: colocar permissao e funcionalidade correta
      // checkPermission: 'PRO_PREENCHER_FORMULARIO_DINAMICO_PRONTUARIO',
      // funcionalidade: 'FAMILIAR_GENOGRAMA',
    },
  ];

  const handleGoSolicitacaoExamesTab = () => {
    const tabSolicitacaoExames = tabs.findIndex(
      tab => tab.label === 'Solicitação de exames e cirurgias',
    );
    if (tabSolicitacaoExames) setTabValue(tabSolicitacaoExames);
  };

  const tabAGA = tabs.findIndex(tab => tab.label === 'Idoso bem cuidado');
  const tabFormulario = tabs.findIndex(tab => tab.label === 'Formulários');

  const handleTabChange = (value: number) => {
    if (
      tabValue - 1 === tabAGA &&
      atendimentoStatus === 'ATENDENDO' &&
      isEdit !== null
    ) {
      return confirmDialogAGADisclosure.open({
        state: {
          ...confirmDialogAGADisclosure,
          target: value,
        },
      });
    }
    if (
      tabValue === tabFormulario &&
      atendimentoStatus === 'ATENDENDO' &&
      isFormularioDirty
    ) {
      return confirmDialogFormularioDisclosure.open({
        state: {
          ...confirmDialogFormularioDisclosure,
          target: value,
        },
      });
    }
    return setTabValue(value);
  };

  if (
    agenda?.profissionalAtivo?.profissionalSaude?.atendeObstetricia &&
    dadosCabecalho?.paciente?.idSexo === 1
  ) {
    const tabPreNatal = {
      label: 'Pré-natal gestante',
      content: (
        <PreNatal
          idPaciente={dadosCabecalho?.paciente?.id || null}
          usuario={usuario}
        />
      ),
      funcionalidade: 'PRE_NATAL_GESTANTE',
    };

    tabs.splice(2, 0, tabPreNatal);
  }

  const isPrescricao =
    atendimentoStatus.includes('PRESCRICAO') &&
    atendimentoStatus !== 'PRESCRICAO_FINALIZADA';

  if (isAtendimentoGrupo) {
    return (
      <div id="prontuario-container">
        <AtendimentoGrupo dadosCabecalho={dadosCabecalho} />
      </div>
    );
  }

  const tabsFiltered = tabs.filter(tab => {
    if (
      tab.funcionalidade === 'ATENDIMENTO' ||
      tab.funcionalidade === 'FICHA_PACIENTE'
    )
      return true;
    const campoTab = campos?.find(
      campo => campo.funcionalidade === tab?.funcionalidade,
    );
    return campoTab?.exibicaoCamposModelo !== 'OCULTO';
  });

  const activeIndex =
    state?.from === 'PLANO_CUIDADO_REDIRECT'
      ? tabsFiltered.findIndex(tab => tab.label === 'Plano de cuidado')
      : 0;

  return (
    <div id="prontuario-container">
      <Cabecalho
        dadosCabecalho={dadosCabecalho}
        dadosRegistroObito={dadosRegistroObito}
        carregandoDados={carregandoDados}
        onSuccessRegistroObito={onSuccessRegistroObito}
        onGoSolicitacaoExamesTab={handleGoSolicitacaoExamesTab}
      />
      {teleconsultaState === 'STARTED' && <TelemedicinaIFrame />}

      {!atendimentoStatus && carregandoDados ? (
        <SpinnerLoading full />
      ) : (
        <>
          <Tab
            key={`tabs-${activeIndex}`}
            underHeader
            onChange={v => handleTabChange(v)}
            values={isPrescricao ? tabsPrescricao : tabsFiltered}
            setActiveIndex={tabValue}
            initialTabIndex={activeIndex}
            returnOnChange
          />
          {confirmDialogAGADisclosure.isOpen && (
            <ConfirmDialog
              header="Confirmação"
              onConfirm={() => {
                setTabValue(confirmDialogAGADisclosure.state.target);
                scrollToTopAGA();
                confirmDialogAGADisclosure.close();
              }}
              text={
                'Existem dados que não foram salvos. Deseja deixar a aba mesmo assim?'
              }
              onHide={() => confirmDialogAGADisclosure.close()}
              visible
            />
          )}
          {confirmDialogFormularioDisclosure.isOpen && (
            <ConfirmDialog
              header="Confirmação"
              onConfirm={() => {
                setTabValue(confirmDialogFormularioDisclosure.state.target);
                confirmDialogFormularioDisclosure.close();
              }}
              text={
                'Existem dados que não foram salvos. Deseja deixar a aba mesmo assim?'
              }
              onHide={() => confirmDialogFormularioDisclosure.close()}
              visible
            />
          )}
        </>
      )}

      {modalAgravo.isOpen && (
        <ConfirmDialog
          header="CID adicionado possui agravo vinculado"
          onConfirm={() => {
            modalAgravo.close();
          }}
          text="Foi adicionado um CID a este atendimento, que está vinculado a um agravo. De acordo com a portaria nº 264, de 17 de fevereiro de 2020, todo agravo deve ser notificado obrigatoriamente."
          onHide={() => modalAgravo.close()}
          visible
          confirmBtnType={'outline'}
          confirmText="Fechar"
          hideCancel
        />
      )}
    </div>
  );
}

const ProntuarioContainer = () => (
  <AtendimentoProvider>
    <MemedProvider>
      <PreNatalProvider>
        <AGAProvider>
          <Prontuario />
        </AGAProvider>
      </PreNatalProvider>
    </MemedProvider>
  </AtendimentoProvider>
);

export default ProntuarioContainer;
