import { createContext, useCallback, useContext, useState } from 'react';

const ContaPagarContext = createContext({} as any);

export function SelectedContaPagarProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [selectedContaPagar, setSelectedContaPagar] = useState([]);

  const [reload, setReload] = useState(0);

  const reloadList = useCallback(() => {
    setReload(currentReload => currentReload + 1);
  }, []);

  return (
    <ContaPagarContext.Provider
      value={{
        selectedContaPagar,
        setSelectedContaPagar,
        reload,
        setReload,
        reloadList,
      }}
    >
      {children}
    </ContaPagarContext.Provider>
  );
}

export function useSelectedContaPagar() {
  const context = useContext(ContaPagarContext);

  return context;
}
