import React from 'react';

import dayjs from 'dayjs';

import { cn } from 'src/utils/cn';
import { capitalizeFirstLetter } from 'src/utils/utils';

import { Checkbox } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import { MobileRow as SearchListMobileRow } from 'src/components/SearchList/SearchListRoot';

import { useSelectedContaPagar } from '../../useSelectedContaPagar';

interface MobileRowProps {
  item: IContaPagarItem;
  authorizedActions: any;
  chipStatus: string;
}

const MobileRow = ({ item, authorizedActions, chipStatus }: MobileRowProps) => {
  const { selectedContaPagar, setSelectedContaPagar } = useSelectedContaPagar();

  return (
    <SearchListMobileRow options={authorizedActions}>
      <div className="p-grid p-ai-start p-jc-start">
        <Checkbox
          className="p-col-12 p-p-0"
          checked={selectedContaPagar?.includes(item)}
          onChange={e => {
            if (e.checked) {
              setSelectedContaPagar(
                selectedContaPagar.length > 0
                  ? [...selectedContaPagar, item]
                  : [item],
              );
            } else {
              setSelectedContaPagar(
                selectedContaPagar?.filter((i: any) => i !== item),
              );
            }
          }}
        />
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <div className="p-d-flex p-flex-column">
            <SimpleText fontSize="xxs" fontColor="color_60">
              Data de vencimento:
            </SimpleText>
            <SimpleText fontSize="xs" fontColor="color_16">
              {item?.dataVencimento
                ? dayjs(new Date(item.dataVencimento)).format('DD/MM/YYYY')
                : 'Não informado'}
            </SimpleText>
          </div>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <SimpleText fontSize="xxs" fontColor="color_60">
            Pagamento:
          </SimpleText>
          <SimpleText fontSize="xs" fontColor="color_16">
            {item?.dataPagamento
              ? dayjs(new Date(item.dataPagamento)).format('DD/MM/YYYY')
              : ''}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <SimpleText fontSize="xxs" fontColor="color_60">
            Fornecedor:
          </SimpleText>
          <SimpleText fontSize="xs" fontColor="color_16">
            {item?.fornecedor?.nome || ''}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <SimpleText fontSize="xxs" fontColor="color_60">
            Consultório:
          </SimpleText>
          <SimpleText fontSize="xs" fontColor="color_16">
            {item?.consultorio.nome &&
              capitalizeFirstLetter(item?.consultorio.nome)}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <SimpleText fontSize="xxs" fontColor="color_60">
            Centro de custo:
          </SimpleText>
          <SimpleText fontSize="xs" fontColor="color_16">
            {capitalizeFirstLetter(item.centroCusto.nome)}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <SimpleText fontSize="xxs" fontColor="color_60">
            Tipo:
          </SimpleText>
          <SimpleText fontSize="xs" fontColor="color_16">
            {item.tipoContaPagar.tipo || ''}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <SimpleText fontSize="xxs" fontColor="color_60">
            Valor:
          </SimpleText>
          <SimpleText fontSize="xs" fontColor="color_16">
            {item.valor.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-6 p-md-4 p-lg-3">
          <SimpleText fontSize="xxs" fontColor="color_60">
            Valor pago:
          </SimpleText>
          <SimpleText fontSize="xs" fontColor="color_16">
            {(item.valorPago || 0).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </SimpleText>
        </div>

        <div
          className={cn(
            'p-col-6 p-py-1 p-md-order-2 p-d-flex p-flex-column p-md-ai-end p-jc-center',
            chipStatus === 'Paga'
              ? 'chip-success-light'
              : chipStatus === 'A Pagar'
              ? 'chip-yellow'
              : 'chip-feedback-warning ',
          )}
        >
          <SimpleText medium fontSize="xxs" fontColor="color_16">
            {chipStatus}
          </SimpleText>
        </div>
        <div className="p-d-flex p-flex-column p-gap-1 p-ai-start p-jc-start p-col-12 p-md-6">
          <SimpleText fontSize="xxs" fontColor="color_60">
            Descrição:
          </SimpleText>
          <SimpleText fontSize="xs" fontColor="color_16">
            {item.descricao}
          </SimpleText>
        </div>
      </div>
    </SearchListMobileRow>
  );
};

export default MobileRow;
