import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { DisclosureType } from 'src/utils/hooks/useDisclosure';

import Dialog from 'src/components/Dialog/Dialog';

const RelacionamentoDialog = ({ close, state }: DisclosureType) => {
  const form = useForm({});

  const onSubmit = () => {};

  return (
    <Dialog
      className="dialog-add-familiares dialog-create-familiares"
      header="Relacionamentos familiares"
      maximizedTabletSize
      visible
      onHide={() => close({ state: undefined })}
    >
      <FormProvider {...form}>
        <form
          onSubmit={e => [form.handleSubmit(onSubmit)(e), e.stopPropagation()]}
          className="dialog-content p-col-12"
        ></form>
      </FormProvider>
    </Dialog>
  );
};

export default RelacionamentoDialog;
