import React from 'react';

import { useFormContext } from 'react-hook-form';

import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import { FieldTiposPedidoCuidado } from 'src/components/Fields/FieldTiposPedidoCuidado/FieldTiposPedidoCuidado';
import FormInput from 'src/components/FormInput/FormInput';

export const FormContentPedido = () => {
  const form = useFormContext();

  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-p-2">
      <FormInput
        name="nomeCuidado"
        label="Nome do cuidado"
        hideTextErrorSpace
      />
      <FieldTiposPedidoCuidado
        label="Tipo de cuidado"
        name="tipo"
        hideTextErrorSpace
        className="p-p-0"
      />
      <FormInput
        name="recorrencia"
        label="Recorrência"
        sublabel="(dias)"
        type="number"
        min={1}
        hideTextErrorSpace
      />
      <TextareaInputControlled
        control={form.control}
        name="execucao"
        label="Execução"
        maxLengthSpan
        maxLength={2500}
      />
    </div>
  );
};
