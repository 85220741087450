import Http, { HttpParams } from 'src/core/http/Http';

export const verifyElegibilidade = (
  data: {
    idPaciente: number;
    idProfissionalSaude: number;
    idCartaoPaciente: number;
    idConsultorio: number;
  },
  options?: Omit<HttpParams, 'service' | 'url'>,
): any => {
  return Http.post({
    service: 'agenda',
    url: '/elegibilidade',
    data: data,
    hideToast: true,
    ...options,
  });
};

export const ElegibilidadeAPI = {
  verifyElegibilidade,
};
