import { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useSubscription } from 'react-stomp-hooks';

import { yupResolver } from '@hookform/resolvers/yup';
import { ElegibilidadeAPI } from 'src/APIs/AgendaAPI/ElegibilidadeAPI';
import ParametroAPI from 'src/APIs/ConfigAPI/Parametro/ParametroAPI';
import * as Yup from 'yup';

import { useAppSelector } from 'src/core/redux/hooks';

import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { DialogErroComunicacao } from './components/DialogsTiss/DialogErroComunicacao';
import { DialogComunicacao } from './components/DialogsTiss/DialogLoadingComunicacao';
import { DialogPacienteNaoElegivel } from './components/DialogsTiss/DialogPacienteNaoElegivel';
import { FieldConsultorio } from './components/FieldConsultorio';
import FieldProfissional from './components/FieldProfissional';
import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import { useNovoAgendamento } from './hooks/NovoAgendamentoContext';

export const NovoAgendamentoConsultorioProfissionalForm = ({
  handleSetData,
  values,
  handleGoBack,
}: {
  handleSetData(v: any): void;
  handleGoBack(): void;
  values: any;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isProfissionalFirst, setIsProfissionalFirt] = useState(false);

  const { idPaciente } = useAppSelector(state => state.user);
  const { isTimerRunning, handleElegibilidadeObservacao } =
    useNovoAgendamento();

  const dialogTissErroOperadora = useDisclosure({ opened: false });
  const dialogTissPacienteNaoElegivel = useDisclosure({ opened: false });
  const dialogComunicacaoTiss = useDisclosure({ opened: false });

  const validationSchema = Yup.object({
    profissional: Yup.object().required('Campo é obrigatório'),
    consultorio: Yup.object().required('Campo é obrigatório'),
  });

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...values,
    } as any,
  });

  const watchConsultorio = form.watch('consultorio');
  const watchProfissional = form.watch('profissional');

  useEffectSkipFirst(() => {
    if (watchConsultorio && !!watchProfissional) {
      form.setValue('profissional', null);
    }
  }, [watchConsultorio]);

  useEffect(() => {
    ParametroAPI.loadParametrosByCodigo(
      {
        codigo: 'ORDEM_BUSCA_AO',
      },
      {
        errorHideToast: true,
      },
    ).then(d => d.valor === 'M' && setIsProfissionalFirt(true));
  }, []);

  const onSubmit = useCallback(
    (v: typeof validationSchema.__outputType) => {
      handleSetData(v);
    },
    [handleSetData, validationSchema],
  );

  const handleVerifyElegibilidade = useCallback(() => {
    setIsSubmitting(true);

    if (
      !!watchProfissional?.naoRealizaTransacaoTiss ||
      !values?.numCarteirinha ||
      !values?.convenioUtilizaTiss
    )
      return onSubmit(form.getValues());

    return ElegibilidadeAPI.verifyElegibilidade(
      {
        idPaciente,
        idProfissionalSaude: watchProfissional?.id,
        idCartaoPaciente: values.idCartaoPaciente,
        idConsultorio: watchConsultorio?.id,
      },
      {
        throwError: true,
      },
    )
      .then(() => dialogComunicacaoTiss.open())
      .catch(() => dialogTissErroOperadora.open())
      .finally(() => setIsSubmitting(false));
  }, [
    dialogComunicacaoTiss,
    dialogTissErroOperadora,
    form,
    idPaciente,
    onSubmit,
    values?.convenioUtilizaTiss,
    values.idCartaoPaciente,
    values?.numCarteirinha,
    watchProfissional?.id,
    watchProfissional?.naoRealizaTransacaoTiss,
    watchConsultorio?.id,
  ]);

  // * Elegibilidade TISS
  useSubscription('/user/topic/notificacao', (message: any) => {
    const data = JSON.parse(message.body);

    if (
      data.tipoNotificacao === 'TISS' &&
      data?.dadosComplementares?.transacao === 'ELEGIBILIDADE' &&
      isTimerRunning
    ) {
      dialogComunicacaoTiss.close();

      if (data.dadosComplementares?.sucesso === 'false')
        return [
          dialogTissErroOperadora.open(),
          handleElegibilidadeObservacao(
            data.dadosComplementares?.motivoNegativa,
          ),
        ];

      if (data.dadosComplementares.elegivel === 'S' && !isSubmitting) {
        return onSubmit(form.getValues());
      }

      if (data.dadosComplementares.elegivel === 'N') {
        return [
          dialogTissPacienteNaoElegivel.open(),
          handleElegibilidadeObservacao(
            data.dadosComplementares?.motivoNegativa,
          ),
        ];
      }
    }
    return;
  });

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="p-col-12 p-md-6 p-d-flex p-flex-column p-gap-2 p-px-3"
      >
        <SimpleText fontColor="color_60">
          Selecione as informações para o agendamento
        </SimpleText>

        {isProfissionalFirst ? (
          <>
            <FieldProfissional
              label="Profissional"
              placeholder="Selecione um profissional"
              className="p-px-0"
            />

            <FieldConsultorio
              label="Consultório"
              className="p-px-0"
              placeholder="Selecione um consultório"
              disabled={!watchProfissional}
              params={{
                idProfissional: watchProfissional?.id,
                idPaciente,
                isEquipeCuidadora:
                  watchConsultorio?.tipoMonitoramentoCarteiraAps === 'EQUIPE',
              }}
            />
          </>
        ) : (
          <>
            <FieldConsultorio
              label="Consultório"
              className="p-px-0"
              placeholder="Selecione um consultório"
              params={{
                idPaciente,
                isEquipeCuidadora:
                  watchConsultorio?.tipoMonitoramentoCarteiraAps === 'EQUIPE',
              }}
            />
            <FieldProfissional
              label="Profissional"
              placeholder="Selecione um profissional"
              className="p-px-0"
              disabled={!watchConsultorio}
              params={{
                idConsultorio: watchConsultorio?.id,
                idPaciente,
                isEquipe:
                  watchConsultorio?.tipoMonitoramentoCarteiraAps === 'EQUIPE',
              }}
            />
          </>
        )}

        <div className="p-d-flex p-gap-2 p-mt-3">
          <Button
            label="Voltar"
            btnType="ghost"
            className="p-col-3"
            onClick={handleGoBack}
            disabled={isSubmitting}
          />
          <Button
            type="submit"
            label="Salvar e avançar"
            loading={isSubmitting}
            stretch
          />
        </div>

        {dialogTissErroOperadora.isOpen && (
          <DialogErroComunicacao
            onRetry={handleVerifyElegibilidade}
            {...dialogTissErroOperadora}
          />
        )}
        {dialogTissPacienteNaoElegivel.isOpen && (
          <DialogPacienteNaoElegivel
            handleAgendar={() => onSubmit(form.getValues())}
            idConvenio={values?.idConvenio}
            {...dialogTissPacienteNaoElegivel}
          />
        )}
        {dialogComunicacaoTiss.isOpen && (
          <DialogComunicacao
            onTimeout={() => dialogTissErroOperadora.open()}
            {...dialogComunicacaoTiss}
          />
        )}
      </form>
    </FormProvider>
  );
};
