import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { PlanoCuidadoPacienteAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoPacienteAPI/PlanoCuidadoPacienteAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { resetInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import SimpleText, {
  FONT_COLOR,
} from 'src/components/Basics/SimpleText/SimpleText';
import Skeleton from 'src/components/Skeleton/Skeleton';
import Tooltip from 'src/components/Tooltip/Tooltip';
import { AccConsulta } from 'src/pages/Emed/Pacientes/PlanoCuidado/Configuracoes/PlanosPacientes/components/accAtividades/accConsulta';
import { AccCuidadoProgramado } from 'src/pages/Emed/Pacientes/PlanoCuidado/Configuracoes/PlanosPacientes/components/accAtividades/accCuidadoProgramado';
import { AccExames } from 'src/pages/Emed/Pacientes/PlanoCuidado/Configuracoes/PlanosPacientes/components/accAtividades/accExames';

import { AccFormularioDinamico } from '../accAtividades/accFormularioDinamico';

export const PlanosPacientesRowContent = ({
  item,
  type,
  setPercentual,
}: any) => {
  const [data, setData] = useState(item);
  const [loading, setLoading] = useState(false);

  const { invalidatePlano } = useAppSelector(state => state.query);
  const dispatch = useDispatch();

  const fetchApi = useCallback(() => {
    setLoading(true);

    PlanoCuidadoPacienteAPI.loadPlanoCuidadoPacienteById(item.id, {
      ...(!!item.idAtendimentoOrigem && {
        idAtendimento: item.idAtendimentoOrigem,
      }),
    })
      .then(response => [
        setData(response),
        setPercentual(response.percentualConclusao ?? 0),
      ])
      .catch(() => {})
      .finally(() => [setLoading(false)]);
  }, [item, setPercentual]);

  useEffect(() => {
    fetchApi();
  }, [fetchApi]);

  useEffectSkipFirst(() => {
    if (invalidatePlano) {
      fetchApi();
      dispatch(resetInvalidateQuery());
    }
  }, [fetchApi, invalidatePlano]);

  const planoCuidadoOrigem = data.planoCuidado;
  const profissionais: any[] = data.profissionaisResponsaveis || [];

  return (
    <div className="p-w-100 p-d-flex p-flex-column">
      <div
        className="p-w-100 p-d-flex p-ai-center"
        style={{ overflowX: 'scroll', overflowY: 'hidden' }}
      >
        <SimpleText className="p-col-4 p-py-0 p-wordbreak-normal">
          <Skeleton loading={loading}>
            {!!profissionais.length ? (
              <>
                <SimpleText fontColor="color_60">
                  Profissional responsável:
                </SimpleText>{' '}
                <strong>{profissionais[0].nome}</strong>
                {profissionais.length > 1 && (
                  <span
                    className="profissional-info"
                    data-pr-tooltip={profissionais
                      .slice(1, profissionais.length)
                      .map(prof => prof.nome)
                      .join(', ')}
                    data-pr-position="bottom"
                  >
                    , +{profissionais.length - 1}
                  </span>
                )}
                <Tooltip target={`.profissional-info`} />
              </>
            ) : (
              <></>
            )}
          </Skeleton>
        </SimpleText>

        <Skeleton className="p-mr-2" loading={loading}>
          <div className="p-d-flex p-ai-center p-px-2">
            <SimpleText
              className="p-w-auto p-col-2 p-lg-1"
              fontColor={FONT_COLOR.COLOR_60}
            >
              CID:
            </SimpleText>

            <div className="p-d-flex p-gap-1">
              {!!planoCuidadoOrigem?.cids?.length &&
                planoCuidadoOrigem.cids.map(
                  (cid: Cids, idx: number, arr: Cids[]) => {
                    if (idx > 3) return;
                    if (idx === 3)
                      return <CidsCiapComp idx={idx} arr={arr} type="CID" />;

                    return (
                      <span className="tag tag-cid" key={cid.id}>
                        <Tooltip target={`.tag-cid-prontuario-${cid.id}`} />
                        <SimpleText
                          className={`actv tag-cid-prontuario-${cid.id}`}
                          data-pr-tooltip={cid.nome}
                          data-pr-position="top"
                        >
                          {cid.codigo}
                        </SimpleText>
                      </span>
                    );
                  },
                )}
            </div>
          </div>
        </Skeleton>

        <Skeleton loading={loading}>
          <div className="p-d-flex p-ai-center p-px-2">
            <SimpleText
              className="p-w-auto p-col-2 p-lg-1"
              fontColor={FONT_COLOR.COLOR_60}
            >
              CIAP:
            </SimpleText>

            <div className="p-d-flex p-gap-1">
              {!!planoCuidadoOrigem?.ciaps?.length &&
                planoCuidadoOrigem.ciaps.map(
                  (ciap: Ciap, idx: number, arr: Ciap[]) => {
                    if (idx > 3) return;
                    if (idx === 3)
                      return <CidsCiapComp idx={idx} arr={arr} type="CID" />;

                    return (
                      <span className="tag tag-cid" key={ciap.id}>
                        <Tooltip target={`.tag-ciap-prontuario-${ciap.id}`} />
                        <SimpleText
                          className={`actv tag-ciap-prontuario-${ciap.id}`}
                          data-pr-tooltip={ciap.nome}
                          data-pr-position="top"
                        >
                          {ciap.codigo}
                        </SimpleText>
                      </span>
                    );
                  },
                )}
            </div>
          </div>
        </Skeleton>
      </div>

      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {!!data.atividadesConsultas?.length &&
            data.atividadesConsultas.map((atividade: any) => (
              <AccConsulta
                item={atividade}
                idAtendimentoOrigem={item.idAtendimentoOrigem}
                key={atividade.id}
                disableExecucao={type === 'HISTORICO'}
              />
            ))}
          {!!data.atividadesCuidado?.length &&
            data.atividadesCuidado.map((atividade: any) => (
              <AccCuidadoProgramado
                item={atividade}
                idAtendimentoOrigem={item.idAtendimentoOrigem}
                key={atividade.id}
                disableExecucao={type === 'HISTORICO'}
              />
            ))}

          {!!data.atividadesExame?.length &&
            data.atividadesExame.map((atividade: any) => (
              <AccExames
                item={atividade}
                idAtendimentoOrigem={item.idAtendimentoOrigem}
                key={atividade.id}
                disableExecucao={type === 'HISTORICO'}
              />
            ))}
          {!!data.atividadesFormularioDinamico?.length &&
            data.atividadesFormularioDinamico.map((atividade: any) => (
              <AccFormularioDinamico
                item={atividade}
                idAtendimentoOrigem={item.idAtendimentoOrigem}
                key={atividade.id}
                disableExecucao={type === 'HISTORICO'}
              />
            ))}
        </>
      )}
    </div>
  );
};

const LoadingComponent = () => {
  const props = {
    loading: true,
    height: '50px',
  };

  return (
    <div className="p-d-flex p-flex-column p-gap-2 p-m-2">
      <Skeleton {...props} />
      <Skeleton {...props} />
      <Skeleton {...props} />
    </div>
  );
};

const CidsCiapComp = ({ idx, arr, type }: any) => {
  return (
    <React.Fragment key={idx}>
      <span
        data-pr-tooltip={arr
          .slice(3, arr.length)
          .map((arr: any) => arr.codigo)
          .join(', ')}
        data-pr-position="bottom"
        className="profissional-info p-mt-1"
      >
        + {arr.length - 3}
      </span>
      <Tooltip target={`.${type}-info`} />
    </React.Fragment>
  );
};
