import { createContext, useCallback, useContext, useState } from 'react';

const ContaReceberContext = createContext({} as any);

export function SelectedContaReceberProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [selectedContaReceber, setSelectedContaReceber] = useState([]);
  const [reload, setReload] = useState(0);

  const reloadList = useCallback(() => {
    setReload(currentReload => currentReload + 1);
  }, []);

  return (
    <ContaReceberContext.Provider
      value={{
        selectedContaReceber,
        setSelectedContaReceber,
        reload,
        setReload,
        reloadList,
      }}
    >
      {children}
    </ContaReceberContext.Provider>
  );
}

export function useSelectedContaReceber() {
  const context = useContext(ContaReceberContext);

  return context;
}
