import { useRef, useState } from 'react';

import userEvent from '@testing-library/user-event';
import PendenciasAuditoriaAPI from 'src/APIs/AdminAPI/PendenciasAuditoriaAPI/PendenciasAuditoriaAPI';

import { useUser } from 'src/core/hooks/User/User';

import { Button } from 'src/components/_UI';
import { InputText } from 'src/components/Basics/Input';

interface InputChatProps {
  data: IPendenciaAuditoriaDTO;
  getMensagens: () => void;
  setMensagens: (msg: any) => void;
}

const InputChat = ({ data, getMensagens, setMensagens }: InputChatProps) => {
  const { user } = useUser();
  const [inputValue, setInputValue] = useState('');
  const [arquivo, setArquivo] = useState<any>();
  const [base64, setBase64] = useState('');
  const [loading, setLoading] = useState(false);

  const fileInput = useRef<HTMLInputElement>(null);

  const onClickInsideButton = () => {
    if (arquivo) {
      setInputValue('');
      setArquivo(undefined);
    } else {
      setInputValue('');
      fileInput?.current?.click();
    }
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setArquivo(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        let base64String = reader.result as string;
        base64String = base64String.replace(
          /^data:(application\/pdf|image\/jpeg|image\/jpg);base64,/,
          '',
        );
        setBase64(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSendMessage = async () => {
    if (!inputValue && !arquivo) return;

    const payload: IMsgPendenciaAuditoriaDTO = {
      numeroGuia: data.numeroGuia,
      ...(inputValue.length && { mensagem: inputValue }),
      nomeUsuario: user.nome,
      dataEnvio: new Date().toISOString(),
      ...(arquivo?.name && { fileName: arquivo?.name }),
      ...(arquivo?.type && { fileType: arquivo?.type }),
      ...(arquivo && { arquivo: base64 }),
    };

    setLoading(true);

    await PendenciasAuditoriaAPI.postMensagemPendencia(payload)
      .then(() => {
        setInputValue('');
        setArquivo(undefined);
        setBase64('');
      })
      .catch(() => {
        console.error('Erro ao enviar mensagem');
      })
      .finally(() => {
        setLoading(false);
        getMensagens();
      });
  };

  const formatArquivoString = (nomeArquivo: string) => {
    if (nomeArquivo.length > 12) {
      nomeArquivo = nomeArquivo.slice(0, 10) + '...';
    }
    return nomeArquivo;
  };

  return (
    <div className="p-d-flex p-jc-between p-ai-center input">
      <input
        type="file"
        ref={fileInput}
        style={{ display: 'none' }}
        onChange={onFileChange}
        accept=".pdf,.jpeg,.jpg"
      />
      <InputText
        className="input-mensagem"
        placeholder={
          arquivo
            ? `Arquivo ${formatArquivoString(arquivo?.name)} anexado`
            : 'Digite uma mensagem'
        }
        maxLength={500}
        insideButton={arquivo ? 'fas fa-trash' : 'fas fa-paperclip'}
        insideButtonType="gray"
        onClickInsideButton={onClickInsideButton}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            handleSendMessage();
          }
        }}
        onChange={e => setInputValue(e.target.value)}
        value={inputValue}
        hideTextErrorSpace
        disabled={arquivo !== undefined}
      />
      <div>
        <Button
          icon="fas fa-paper-plane"
          btnType="tonal"
          onClick={() => handleSendMessage()}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default InputChat;
