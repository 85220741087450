import Http, { HttpParams } from 'src/core/http/Http';

const baseUrl = 'feedback-avaliacao'

export const sendAvaliacaoEmed = async(
    data: { sugestao: string } | { nota: number },
    options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
    return Http.post({
        service: 'adm',
        url: `${baseUrl}`,
        data,
        hideToast: true,
        throwError: true,
        ...options,
    });
}

export const checkUserFeedback = async(
    idUsuario: number,
    options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
    return Http.get({
        service: 'adm',
        url: `${baseUrl}/existe-avalicao`,
        hideToast: true,
        throwError: true,
        ...options,
    });
}

export const FeedbackAPI = {
    sendAvaliacaoEmed,
    checkUserFeedback,
};
