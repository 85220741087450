import { useEffect, useCallback, useState, useRef } from 'react';

import { OverlayPanel } from 'primereact/overlaypanel';
import { ToggleButton } from 'primereact/togglebutton';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import ReceitaAPI from 'src/APIs/ProntuarioAPI/ReceitaAPI/ReceitaAPI';
import UtilsAPI from 'src/APIs/ProntuarioAPI/UtilsAPI/UtilsAPI';
import {
  MedicamentoMemedProps,
  ReceitaMemedProps,
} from 'src/models/APIs/ProntuarioAPI/Receita/ReceitaDTOs';

import { useMemed } from 'src/core/hooks/Atendimento/useMemed';
import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useCopyClipboard } from 'src/utils/hooks/useCopyClipboard';
import { useDisclosure } from 'src/utils/hooks/useDisclosure';
import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

import { Button } from 'src/components/_UI/Button';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import Toast from 'src/components/Basics/Toast/Toast';
import Separator from 'src/components/Separator/Separator';
import Panel from 'src/pages/Emed/Prontuario/components/Panel/Panel';

import ReceitaMemed from '../ReceitaMemed/ReceitaMemed';

import './ReceitaHistorico.scss';
import dayjs from 'dayjs';

export default function ReceitaHistorico({ campos }: any) {
  const { state } = useLocation();
  const { memedStatus } = useMemed();

  const idAtendimento = state?.idAtendimento;
  const { isLoading, isLoaded } = memedStatus;

  const [receitas, setReceitas] = useState<any>([]);
  const [campoReceita, setCampoReceita] = useState<any>({});
  const [checked, setChecked] = useState<boolean>(false);

  const { agenda } = useAppSelector((state: RootState) => state);

  const modalMemed = useDisclosure({ opened: false });

  const reloadCampos = () => {
    if (campoReceita.exibicaoCamposModelo === 'FIXO') {
      campoReceita.exibicaoCamposModelo = 'NENHUM';
    } else {
      campoReceita.exibicaoCamposModelo = 'FIXO';
    }
  };

  useEffect(() => {
    setCampoReceita(
      campos.filter((campo: any) => campo.funcionalidade === 'RECEITA')[0],
    );
  }, [campos]);

  const handleFixar = async () => {
    if (campoReceita.obrigatorio) {
      toast.error('Não é possível desfixar um campo obrigatório');
      return;
    }

    const exibixaoCampo =
      campoReceita.exibicaoCamposModelo === 'FIXO' ? 'NENHUM' : 'FIXO';

    const response = await UtilsAPI.putTipoExibicaoCampos(
      Number(agenda?.profissionalAtivo?.id),
      String(campoReceita.funcionalidade),
      exibixaoCampo,
    );
    if (response?.status === 200) {
      setChecked(exibixaoCampo === 'FIXO' ? true : false);
      reloadCampos();
    }
  };

  useEffectSkipFirst(() => {
    if (campoReceita) {
      setChecked(campoReceita.exibicaoCamposModelo === 'FIXO' ? true : false);
    }
  }, [campoReceita]);

  const loadReceitas = useCallback(async () => {
    ReceitaAPI.getReceitasAtendimento(
      { idAtendimento },
      {},
      { somenteAtendimento: true, pageSize: 9999 },
    ).then(data => data?.list && setReceitas(data.list));
  }, [idAtendimento]);

  const handleDelete = async (idReceita: number) => {
    try {
      await ReceitaAPI.deleteReceita(idReceita, idAtendimento, {
        throwError: true,
      });
      loadReceitas();
    } catch (error) {}
  };

  useEffect(() => {
    loadReceitas();
  }, [loadReceitas]);

  return (
    <>
      <div className="historico-container">
        <div className="p-d-flex p-jc-between p-ai-center">
          <SimpleText
            className="p-ml-2"
            fontSize={FONT_SIZE.XS}
            fontColor={FONT_COLOR.COLOR_16}
            medium
          >
            Receita
          </SimpleText>

          <div className="p-d-flex p-jc-end p-gap-2">
            <Button
              label="Nova receita"
              btnType="tonal"
              onClick={() => modalMemed.open()}
              stretch
              loading={isLoading}
              disabled={!isLoaded}
            />
            <ToggleButton
              checked={checked}
              className="p-px-4 pin"
              onLabel={''}
              offLabel={''}
              onChange={e => {
                handleFixar();
                setChecked(e.value);
              }}
              onIcon="fa fa-thumbtack"
              offIcon="fa fa-thumbtack"
            />
          </div>
        </div>

        <div className="receita-content p-mt-3">
          {!!receitas.length ? (
            <>
              {receitas.map((item: ReceitaMemedProps, idx: number) => {
                return (
                  <div className={idx !== 0 ? 'p-mt-3' : ''} key={idx}>
                    <Header item={item} onDelete={handleDelete} />
                    {item.medicamentos.map((medicamento, idx) => (
                      <ReceitaContent
                        key={idx}
                        item={receitas}
                        medicamento={medicamento}
                      />
                    ))}
                  </div>
                );
              })}
            </>
          ) : (
            <SimpleText>
              Não existe receitas cadastradas para esse atendimento
            </SimpleText>
          )}
        </div>
        {modalMemed.isOpen && (
          <ReceitaMemed {...modalMemed} loadReceitas={loadReceitas} />
        )}
      </div>
    </>
  );
}

const Header = ({
  item,
  onDelete,
}: {
  item: ReceitaMemedProps;
  onDelete: (idReceita: number) => void;
}) => {
  const overlayRef = useRef<OverlayPanel>(null);
  const { onCopy, hasCopied } = useCopyClipboard();

  const handleCopiarLink = () => {
    onCopy(item.linkReceitaDigital);
  };

  useEffectSkipFirst(() => {
    if (hasCopied) {
      toast(<Toast />, {
        data: {
          title: 'Sucesso!',
          message: 'Link copiado com sucesso!',
          type: 'success',
        },
      });
    }
  }, [hasCopied]);

  return (
    <div className="p-mb-2 p-d-flex p-jc-end p-gap-1">
      <Button
        icon="fa-regular fa-copy"
        btnType="gray"
        onClick={handleCopiarLink}
      />
      <Button
        icon="fa-solid fa-trash"
        btnType="gray"
        onClick={e => overlayRef.current?.toggle(e)}
      />
      <OverlayPanel ref={overlayRef}>
        <div className="p-grid">
          <div className="p-col-12 p-d-flex p-jc-center">
            <SimpleText
              fontColor={FONT_COLOR.COLOR_16}
              fontSize={FONT_SIZE.XS}
              medium
            >
              Você tem certeza que deseja excluir a receita?
            </SimpleText>
          </div>
          <div className="p-col-12">
            <Button
              label="Sim"
              btnType="danger"
              onClick={() => onDelete(item.id)}
              stretch
            />
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

function ReceitaContent({
  item,
  medicamento,
}: {
  item: ReceitaMemedProps;
  medicamento: MedicamentoMemedProps;
}) {
  const { medicamento: medicamentoNome } = medicamento;

  const localDate = dayjs(item.data).format('DD/MM/YYYY');

  return (
    <Panel
      style={{ marginBottom: '0.5rem' }}
      header={(isCollapsed, onToggle) => (
        <div className="panel-header-receita p-grid p-col-12 p-align-center">
          <div className={'p-col-1 p-d-flex p-flex-column p-gap-1 p-mr-4'}>
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Tipo
            </SimpleText>

            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Receita
            </SimpleText>
          </div>

          <Separator layout="vertical" />

          <div className="p-col-4 p-d-flex p-flex-column p-gap-1 p-mr-4">
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Medicamento
            </SimpleText>
            <SimpleText
              className="p-text-truncate"
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {medicamentoNome}
            </SimpleText>
          </div>
          <div className="p-col-3 p-d-flex p-flex-column p-gap-1">
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Data
            </SimpleText>
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {localDate || '-'}
            </SimpleText>
          </div>
          <div className="p-d-flex p-flex-1 p-jc-end">
            <Button
              icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
              btnType="ghost"
              onClick={onToggle}
            />
          </div>
        </div>
      )}
    >
      <div className="panel-content-receita">
        <div className="content-receita">
          <div>
            <div className="w-10 p-d-flex p-flex-column p-gap-1 ">
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Via
              </SimpleText>

              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                {medicamento.viaMedicamento?.descricao}
              </SimpleText>
            </div>
            <div className="p-d-flex p-flex-1 p-flex-column p-gap-1 ">
              <SimpleText
                fontSize={FONT_SIZE.XXXS}
                fontColor={FONT_COLOR.COLOR_60}
              >
                Quantidade
              </SimpleText>

              <SimpleText
                fontSize={FONT_SIZE.XXS}
                fontColor={FONT_COLOR.COLOR_16}
                medium
              >
                {medicamento.quantidade !== '0' && !!medicamento.quantidade
                  ? `${medicamento.quantidade} embalagem`
                  : 'Uso contínuo'}
              </SimpleText>
            </div>
          </div>
          <div className="p-d-flex p-flex-1 p-flex-column p-gap-1">
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Posologia
            </SimpleText>

            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              {medicamento.posologia}
            </SimpleText>
          </div>
        </div>
      </div>
    </Panel>
  );
}

// Validar se terá essa sessão de impresso -->
// eslint-disable-next-line
function ImpressoContent() {
  return (
    <Panel
      header={(isCollapsed, onToggle) => (
        <div className="panel-header-receita p-grid p-col-12 p-align-center">
          <div className={'p-col-1 p-d-flex p-flex-column p-gap-1 p-mr-4'}>
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Tipo
            </SimpleText>

            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Impresso
            </SimpleText>
          </div>

          <Separator layout="vertical" />

          <div className="p-col-4 p-d-flex p-flex-column p-gap-1">
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Data
            </SimpleText>
            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              06/07/2010 11:15
            </SimpleText>
          </div>
          <div className="p-d-flex p-flex-1 p-jc-end">
            <Button
              icon={isCollapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
              btnType="ghost"
              onClick={onToggle}
            />
          </div>
        </div>
      )}
    >
      <div className="panel-content-receita">
        <div className="content-receita">
          <div className="p-d-flex p-flex-1 p-flex-column p-gap-1">
            <SimpleText
              fontSize={FONT_SIZE.XXXS}
              fontColor={FONT_COLOR.COLOR_60}
            >
              Atestado
            </SimpleText>

            <SimpleText
              fontSize={FONT_SIZE.XXS}
              fontColor={FONT_COLOR.COLOR_16}
              medium
            >
              Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
              consectetur, adipisci velit...
            </SimpleText>
          </div>
        </div>
      </div>
    </Panel>
  );
}
