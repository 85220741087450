import { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router';

import ConsultorioAPI from 'src/APIs/AdminAPI/ConsultorioAPI/ConsultorioAPI';
import EspecialidadeAPI from 'src/APIs/AdminAPI/EspecialidadeAPI/EspecialidadeAPI';
import PersonalizarProntuarioAPI from 'src/APIs/ProntuarioAPI/PersonalizarProntuarioAPI/PersonalizarProntuarioAPI';

import { useAppSelector } from 'src/core/redux/hooks';
import { RootState } from 'src/core/redux/store';

import { useAssistenciaVirtual } from 'src/utils/hooks/useAssistenteVirtual';

import { Button } from 'src/components/_UI/Button';
import { AssistenteVirtualDialog } from 'src/components/AssistenteVirtualDialog/AssistenteVirtualDialog';
import Page from 'src/components/Basics/Page/Page';
import ConfirmDialog from 'src/components/Dialog/ConfirmationDialog';
import PageHeader from 'src/components/PageHeader/PageHeader';
import * as SearchList from 'src/components/SearchList/SearchListRoot';

import { Header } from './Header';
import ModalLista from './ModalLista/ModalLista';
import { Row } from './PersonalizacaoGrid/Row';

import './PersonalizacaoProntuario.scss';

function PersonalizacaoProntuario() {
  const { user, consultorios } = useAppSelector((state: RootState) => state);
  const prevUserIdEmpresa = useRef(user.idEmpresa);
  const navigate = useNavigate();

  const [modalLista, setModalLista] = useState<any>(null);
  const [reload, setReload] = useState<number>(0);
  const [confirmDeleteId, setConfirmDeleteId] = useState<number | null>(null);
  const [especialidadesLength, setEspecialidadesLength] = useState<number>(0);
  const [consultoriosLength, setConsultoriosLength] = useState<number>(0);

  const { assistenteVirtualDialog, handleClose } = useAssistenciaVirtual({
    funcionalidade: 'pro_personalizar_prontuario',
  });

  useEffect(() => {
    if (prevUserIdEmpresa.current !== user.idEmpresa) {
      prevUserIdEmpresa.current = user.idEmpresa;
      if (user.idEmpresa !== null) getConsultorios();
      reloadList();
    }
  }, [user.idEmpresa]);

  const getEspecialidades = async () => {
    return new Promise(async (resolve, reject) => {
      const response = await EspecialidadeAPI.loadEspecialidades({
        pageSize: 1000,
      });

      if (response && !('status' in response)) {
        setEspecialidadesLength(response.list.length);
        resolve(response.list);
      }

      reject(response);
    });
  };

  const getConsultorios = async () => {
    return new Promise(async (resolve, reject) => {
      const response: any = await ConsultorioAPI.loadConsultorios({
        pageSize: 1000,
      });

      if (response && !('status' in response)) {
        setConsultoriosLength(response.list.length);
        resolve(response.list);
      }

      reject(response);
    });
  };

  useEffect(() => {
    getEspecialidades();
    getConsultorios();
  }, []);

  const fetchAPI = async ({ query, ...rest }: any) => {
    try {
      const response =
        await PersonalizarProntuarioAPI.getPersonalizarProntuario(
          {
            nomeModelo: query,
            idConsultorio: consultorios?.ativo?.id,
            ...rest,
          },
          { throwError: true },
        );
      return response;
    } catch (error) {
      return console.error('Error fetching data:', error);
    }
  };

  function handleHideModalLista() {
    setModalLista(null);
  }

  function reloadList() {
    setReload(currentReload => currentReload + 1);
  }

  async function handleChangeModeloStatus(data: any) {
    const action = data.status ? 'inativar' : 'ativar';

    await PersonalizarProntuarioAPI.putAlterarStatus(action, data.id);
    reloadList();
  }

  function handleHideConfirmDelete() {
    setConfirmDeleteId(null);
  }

  async function handleDeleteModelo(idModelo: number) {
    await PersonalizarProntuarioAPI.deletePersonalizarProntuario(idModelo);
    handleHideConfirmDelete();
    reloadList();
  }

  const onConsultorios = (data: any) => {
    setModalLista({
      title: `Lista de consultórios (${data?.consultorios?.length || 0})`,
      subtitle: 'Nome do consultório',
      data: data?.consultorios || [],
    });
  };

  const onEspecialidades = (data: any) => {
    setModalLista({
      title: `Lista de especialidades (${data?.especialidades?.length || 0})`,
      subtitle: 'Nome da especialidade',
      data: data?.especialidades || [],
    });
  };

  const renderRow = (data: any) => (
    <Row
      data={data}
      onDelete={setConfirmDeleteId}
      onConsultorios={onConsultorios}
      onEspecialidades={onEspecialidades}
      consultoriosLength={consultoriosLength}
      especialidadesLength={especialidadesLength}
      onSwitchStatus={handleChangeModeloStatus}
    />
  );

  return (
    <Page>
      <>
        <PageHeader
          title="Personalização de Prontuário"
          subtitle="Configure as abas do prontuário profissional de saúde"
        />

        <div id="personalizar-prontuario" className="p-px-4">
          <SearchList.Root fetchApi={fetchAPI} reloadWhenEmpresaChange>
            <SearchList.BorderContainer>
              <div className="p-d-flex p-flex-wrap p-jc-between p-ai-center">
                <SearchList.SearchInput placeholder="Nome do modelo" />

                <Button
                  btnType="pill"
                  className="p-mb-3"
                  label="Novo modelo"
                  icon="fas fa-plus"
                  onClick={() =>
                    navigate('/config/personalizacao-prontuarios/novo')
                  }
                  checkPermission="PRO_MODELO_PRONTUARIO_CADASTRAR"
                />
              </div>

              <Header />

              <SearchList.InfiniteScroll
                renderRow={renderRow}
                reload={reload}
              />
            </SearchList.BorderContainer>
          </SearchList.Root>
        </div>

        <ModalLista modalLista={modalLista} onHide={handleHideModalLista} />

        <ConfirmDialog
          visible={!!confirmDeleteId}
          header="Excluir modelo de prontuário"
          text="Você confirma a exclusão do modelo de prontuário? Usuários afetados voltarão a utilizar o modelo padrão"
          confirmText="Sim, excluir"
          onHide={handleHideConfirmDelete}
          onConfirm={() => handleDeleteModelo(confirmDeleteId as number)}
        />

        {assistenteVirtualDialog.isOpen && (
          <AssistenteVirtualDialog
            onClose={handleClose}
            canHide
            {...assistenteVirtualDialog}
          />
        )}
      </>
    </Page>
  );
}

export default PersonalizacaoProntuario;
