import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { PlanoCuidadoPacienteAPI } from 'src/APIs/ProntuarioAPI/PlanoCuidadoPacienteAPI/PlanoCuidadoPacienteAPI';
import * as Yup from 'yup';

import { setInvalidateQuery } from 'src/core/redux/slices/query/QuerySlice';

import { DisclosureType, useDisclosure } from 'src/utils/hooks/useDisclosure';

import { orderDatasProgramadas } from './utils';

import { Button } from 'src/components/_UI';
import { ButtonMenu } from 'src/components/Basics/ButtonMenu/ButtonMenu';
import Divider from 'src/components/Basics/Divider/Divider';
import IconButton, {
  IconBtnTypes,
} from 'src/components/Basics/IconButton/IconButton';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import TextareaInputControlled from 'src/components/Basics/TextareaInputControlled/TextareaInputControlled';
import Dialog from 'src/components/Dialog/Dialog';
import DataOcorrencia from 'src/pages/Emed/Prontuario/components/DataOcorrencia/DataOcorrencia';

import './accStyles.scss';

export const AccFormularioDinamico = ({
  item,
  idAtendimentoOrigem,
  disableExecucao,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const { state } = useLocation();

  const idAtendimento = state?.idAtendimento;
  const dialogExecucao = useDisclosure({ opened: false });

  const descricoes: any[] = orderDatasProgramadas(
    item.exames?.filter?.((exame: any) => !!exame.descricaoExecucao) || [],
    'dataExecucao',
  );

  const datasProg: any[] = orderDatasProgramadas(
    item.formularios || [],
    'dataPrevistaRealizacao',
  );

  const statusRealizacaoColor: { [key: string]: any } = {
    AGENDADO: 'warning',
    EM_ATRASO: 'light-danger',
    REALIZADO: 'light-success',
    INATIVO: 'ghost',
  };

  const statusRealizacaLabel: { [key: string]: string } = {
    AGENDADO: 'Agendado',
    EM_ATRASO: 'Em atraso',
    REALIZADO: 'Realizado',
    INATIVO: 'ghost',
  };

  const isAtendimentoOrigem = idAtendimentoOrigem === Number(idAtendimento);

  const statusRealizacao = (date: any) => {
    if (
      date.statusRealizacao === 'AGENDADO' &&
      dayjs(date.dataPrevistaRealizacao).isBefore(new Date())
    )
      return 'EM_ATRASO';
    return date.statusRealizacao;
  };

  return (
    <div className={`acc-cuidado content-item ${!isOpen ? 'closed' : ''}`}>
      <div className="content-item-header p-d-flex p-ai-center p-jc-between">
        <div className="p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Atividade
          </SimpleText>
          <SimpleText medium>Formulário Dinâmico</SimpleText>
        </div>

        <div className="p-d-flex p-gap-2">
          <IconButton
            icon={isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}
            onClick={() => setIsOpen(old => !old)}
            btnType={IconBtnTypes.GHOST}
            type="button"
          />
        </div>
      </div>

      <div className="p-grid content-item-body" aria-expanded={!isOpen}>
        <SimpleText className="p-col-12" medium>
          Formulário Programado
        </SimpleText>
        <div className="p-col-8 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Nome do formulário
          </SimpleText>
          <SimpleText>{item.formularioDinamico?.nome || '-'}</SimpleText>
        </div>
        <div className="p-col-2 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Recorrência
          </SimpleText>
          <SimpleText>{item.recorrencia || '-'} dias</SimpleText>
        </div>
        <div className="p-col-12 p-d-flex p-flex-column p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Execução
          </SimpleText>
          <SimpleText>{item.execucao}</SimpleText>

          {!!datasProg.length && (
            <>
              <Divider className="p-col-12" layout="horizontal" />

              <SimpleText medium>Data programada</SimpleText>

              <div className="p-col-12 p-grid p-gap-4">
                {datasProg.map((date, idx) => (
                  <div
                    key={idx}
                    className="data-programada p-d-flex p-flex-column p-gap-2"
                  >
                    <SimpleText>
                      <i className="fas fa-calendar p-mr-2" />
                      {dayjs(date.dataPrevistaRealizacao).format('DD/MM/YYYY')}
                    </SimpleText>
                    <SimpleText>
                      <i className="fas fa-clock p-mr-2" />
                      {dayjs(date.dataPrevistaRealizacao).format('HH:mm')}
                    </SimpleText>

                    <ButtonMenu
                      btnType={statusRealizacaoColor[statusRealizacao(date)]!}
                      label={statusRealizacaLabel[statusRealizacao(date)]!}
                      readOnly={
                        date.statusRealizacao === 'REALIZADO' ||
                        isAtendimentoOrigem ||
                        disableExecucao
                      }
                      model={[
                        {
                          label: 'Realizado',
                          command: () =>
                            dialogExecucao.open({
                              state: {
                                id: item.idPlanoCuidado,
                                idAtividade: item.id,
                                idExecucao: date.id,
                                idAtendimento: idAtendimento,
                                item,
                              },
                            }),
                        },
                      ]}
                    />
                  </div>
                ))}
              </div>
            </>
          )}

          <Divider className="p-col-12" layout="horizontal" />

          {descricoes.length &&
            descricoes.map((desc, idx) => (
              <DescricaoContent item={desc} key={idx} />
            ))}
        </div>
      </div>

      {dialogExecucao.isOpen && <DialogExecucao {...dialogExecucao} />}
    </div>
  );
};

const DescricaoContent = ({ item }: any) => {
  const isRetroativo = !!item.justificativaOcorrenciaRetroativa;

  return (
    <React.Fragment>
      <div className="p-w-100 p-d-flex p-ai-center">
        <div className="p-col-8 p-d-flex p-flex-column p-px-0 p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Descrição
          </SimpleText>
          <SimpleText>{item.descricaoExecucao}</SimpleText>
        </div>

        {isRetroativo && (
          <span className="tag-retroativo p-text-center">
            <SimpleText fontColor={FONT_COLOR.COLOR_40}>Retroativo</SimpleText>
          </span>
        )}

        <div className="p-col p-d-flex p-flex-column p-ai-end p-gap-2">
          <SimpleText fontColor={FONT_COLOR.COLOR_60} fontSize={FONT_SIZE.XXS}>
            Data da ocorrência
          </SimpleText>
          <SimpleText>
            {dayjs(item.dataExecucao).format('DD/MM/YYYY HH:mm')}
          </SimpleText>
        </div>
      </div>

      <div className="p-d-flex p-gap-2 p-ai-center">
        <SimpleText fontSize={FONT_SIZE.XXS} fontColor={FONT_COLOR.COLOR_60}>
          Adicionado por <b>{item.usuarioAtualizacao?.nome || '-'}</b> em{' '}
          {dayjs(item.dataAtualizacao).format('DD [de] MMM [de] YYYY')}{' '}
        </SimpleText>
        <i className="fas fa-lock-open lock-green" />
      </div>

      <Divider className="p-col-12" layout="horizontal" />
    </React.Fragment>
  );
};

const DialogExecucao = ({ close, isOpen, state }: DisclosureType) => {
  const dispatch = useDispatch();

  const form = useForm({
    resolver: yupResolver(
      Yup.object({
        dataExecucao: Yup.date().required('O campo é obrigatório'),
        descricao: Yup.string().required('O campo é obrigatório'),
        justificativaOcorrenciaRetroativa: Yup.string().when('dataExecucao', {
          is: (dataExecucao: any) =>
            new Date(dataExecucao).getUTCDate() !== new Date().getUTCDate(),
          then: Yup.string().required('Campo obrigatório'),
        }),
      }),
    ),
  });

  const { id, idAtividade, idExecucao, idAtendimento } = state;

  const onSubmit = (values: any) => {
    PlanoCuidadoPacienteAPI.executarPlanoCuidadoPaciente(
      {
        id,
        idAtividade,
        idExecucao,
      },
      {
        tipoAtividade: 'FORMULARIO_DINAMICO',
        descricaoExecucao: values.descricao,
        dataExecucao: values.dataExecucao,
        justificativaOcorrenciaRetroativa:
          values.justificativaOcorrenciaRetroativa,
        idAtendimento,
      },
    )
      .then(() => [
        close(),
        dispatch(setInvalidateQuery({ invalidatePlano: true })),
      ])
      .catch(() => {});
  };

  return (
    <Dialog
      header={`Execução do Formulário Dinâmico`}
      onHide={close}
      visible={isOpen}
      style={{ width: '490px' }}
      className="dialog-execucao"
    >
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="p-w-100 p-d-flex p-flex-column "
      >
        <DataOcorrencia
          nameDataOcorrencia="dataExecucao"
          control={form.control}
        />

        <TextareaInputControlled
          control={form.control}
          name="descricao"
          label="Descrição"
          className="p-col-12 p-py-0"
        />

        <div className="p-col-12 p-d-flex">
          <Button
            label="Cancelar"
            type="button"
            btnType="green-link"
            onClick={() => close()}
            disabled={form.formState.isSubmitting}
            stretch
          />
          <Button
            label="Salvar"
            type="submit"
            stretch
            loading={form.formState.isSubmitting}
          />
        </div>
      </form>
    </Dialog>
  );
};
