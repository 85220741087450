import { AnyAction } from '@reduxjs/toolkit';
import EmpresasAPI from 'src/APIs/EmpresasAPI/EmpresasAPI';
import PerfisAPI from 'src/APIs/PerfilAPI/PerfilAPI';

import Http, { HttpParams } from 'src/core/http/Http';
import { loadAuxiliar } from 'src/core/redux/slices/system/AuxiliarsSlice';

export enum TIPO_USUARIO {
  PROFISSIONAL_SAUDE = 'PROFISSIONAL_SAUDE',
  SECRETARIO = 'SECRETARIO',
  ADMIN = 'ADMIN',
  MASTER = 'MASTER',
}

const basePath = '/usuarios';

export const loadUsuarios = (
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<GetUsuarioDTO[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/pesquisa`,
    params: params,
    hideToast: true,
    ...options,
  });
};

export const loadUsuarioById = (
  id: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetUsuarioDTO> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}`,
    hideToast: true,
    ...options,
  });
};

export const postUsuario = (
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.post({
    service: 'adm',
    url: `${basePath}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const updateUsuario = (
  route: string,
  id: any,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostUsuarioDTO> => {
  let routeToUse = 'usuario';

  if (route === '') {
    routeToUse = 'usuarios';
  }

  return Http.put({
    service: 'adm',
    url: `/${routeToUse}/${id}/${route}`,
    data: payload,
    return: true,
    // errorHideToast: true
    ...options,
  });
};

export const createUsuarioOpcionais = (
  route: string,
  id: any,
  payload: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostUsuarioDTO> => {
  let routeToUse = 'usuario';

  if (route === '') {
    routeToUse = 'usuarios';
  }

  return Http.post({
    service: 'adm',
    url: `/${routeToUse}/${id}/${route}`,
    data: payload,
    return: true,
    ...options,
  });
};

export const getUser = (
  id: any,
  route: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  return Http.get({
    service: 'adm',
    url: `/usuario/${id}/${route}`,
    hideToast: true,
    ...options,
  });
};

export const handleBlockChange = (
  id: any,
  route: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostUsuarioDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/${route}`,
    ...options,
  });
};
export const handleStatusChange = (
  id: any,
  route: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<PostUsuarioDTO> => {
  return Http.put({
    service: 'adm',
    url: `${basePath}/${id}/${route}`,
    ...options,
  });
};

export const removeUsuarioById = (
  id: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetUsuarioDTO> => {
  return Http.delete({ service: 'adm', url: `${basePath}/${id}`, ...options });
};

export const loadProfissionaisByUserConsultorio = (
  idConsultorio: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<GetUsuarioDTO[]> => {
  return Http.get({
    service: 'adm',
    url: `/medicos/por-consultorio/${idConsultorio}`,
    hideToast: true,
    ...options,
  });
};

export const loadUtils = (): AnyAction => {
  return loadAuxiliar();
};

export const loadSensitiveUtils = async (
  user: User,
  params?: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<any> => {
  const isMaster = user.tipoUsuario === TIPO_USUARIO.MASTER ? true : false;
  const [perfis, empresas] = await Promise.allSettled([
    PerfisAPI.loadPerfis({ nome: '' }),

    isMaster ? EmpresasAPI.GetAll(params) : undefined,
  ]);

  const response: any = {};

  if (perfis.status === 'fulfilled') {
    response.perfis = perfis.value;
  }
  if (isMaster && empresas?.status === 'fulfilled') {
    response.empresas = empresas.value;
  }
  return response;
};

export const loadHistoricoEdicoes = (
  id: any,
  params: any,
  options?: Omit<HttpParams, 'service' | 'url'>,
): Promise<Paginator<IHistoricoUsuarioItem[]>> => {
  return Http.get({
    service: 'adm',
    url: `${basePath}/${id}/historico-alteracoes`,
    params: params,
    hideToast: true,
    throwError: true,
    ...options,
  });
}

const UsuarioAPI = {
  loadUsuarios,
  loadUsuarioById,
  postUsuario,
  removeUsuarioById,
  updateUsuario,
  loadUtils,
  loadSensitiveUtils,
  loadProfissionaisByUserConsultorio,
  handleBlockChange,
  handleStatusChange,
  getUser,
  createUsuarioOpcionais,
  loadHistoricoEdicoes,
};

export default UsuarioAPI;
