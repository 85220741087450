import { useState } from 'react';

import TipoContaReceberAPI from 'src/APIs/FinanceiroAPI/TipoContaReceberAPI/TipoContaReceberAPI';

import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Switch from 'src/components/Switch/Switch';

interface StatusTipoContaReceberProps {
  tipoContaReceber: any;
}

export const StatusTipoContaReceber = (props: StatusTipoContaReceberProps) => {
  const { tipoContaReceber } = props;
  const [ativo, setAtivo] = useState(tipoContaReceber.status);

  const [loading, setLoading] = useState(false);

  const onSwitchStatus = async () => {
    const {
      consultorio,
      usuarioAtualizacao,
      idEmpresa,
      ...restoTipoContaReceber
    } = tipoContaReceber;
    const payload = {
      ...restoTipoContaReceber,
      status: !ativo,
      idConsultorio: consultorio.id,
    };

    setLoading(true);
    try {
      const response = await TipoContaReceberAPI.putTipoContaReceber(
        tipoContaReceber.id,
        payload,
      );

      if (response?.status === 200) {
        setAtivo(response.data.status);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className="p-mr-4">
        <SpinnerLoading size="sm"></SpinnerLoading>
      </div>
    );

  return <Switch checked={ativo} showStatus onChange={onSwitchStatus} />;
};
