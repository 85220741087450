import useSize from 'src/core/hooks/useSize';
import DesktopRow from './DesktopRow';
import MobileRow from './MobileRow';
import { useState } from 'react';
import { MonitoramentoNotificacoesAPI } from 'src/APIs/ReportAPI/RelatorioVigilanciaAPI/MonitoramentoNotificacoesAPI/MonitoramentoNotificacoesAPI';

interface Row {
  item: IAgravoNotificacao;
  selectedAgravo: IAgravoNotificacao[];
  setSelectedAgravo: (selectedAgravo: any) => void;
}

const Row = ({ item, selectedAgravo, setSelectedAgravo }: Row) => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const { windowInnerWidth } = useSize();

  const renderMobile = windowInnerWidth <= 1125;

  const onChangeStatus = async () => {
    try {
      setLoadingStatus(true);
      await MonitoramentoNotificacoesAPI.putMonitoramentoNotificacoes(
        [item.id],
        !item.notificado,
      );
      item.notificado = !item.notificado;
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const commonProps = {
    item,
    selectedAgravo,
    setSelectedAgravo,
    loadingStatus,
    onChangeStatus,
  };

  if (renderMobile) {
    return <MobileRow {...commonProps} />;
  }

  return <DesktopRow {...commonProps} />;
};

export default Row;
