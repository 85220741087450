import crypto from 'crypto';
import dayjs from 'dayjs';
import ParametroAPI from 'src/APIs/ConfigAPI/Parametro/ParametroAPI';
import { v4 } from 'uuid';

export const generateUniqueId = () => {
  return v4();
};

export const dateToRequest = (v?: Date): string | undefined => {
  if (v) {
    return v.toISOString().split('.')[0] + 'Z';
  }
  return undefined;
};

export const utcToGMT = (v?: Date): string | undefined => {
  if (v) {
    v = new Date(v.setHours(v.getHours() - 3));

    return v.toISOString().split('.')[0] + '-03:00';
  }
  return undefined;
};

export const utcToGMTString = (date: Date): string => {
    //Esta retirando 3 horas por causa do GMT-3
    date = new Date(date.setHours(date.getHours() - 3));
    
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    return `${day}/${month}/${year}; ${hours}:${minutes} GMT-3`;
}

export const createDataToName = (): string | undefined => {
  return dayjs().format('DD.MM.YYYY_HH[h]mm[m]');
};

export const utcToShow = (v?: Date): string | undefined => {
  if (v) {
    const datetime = {
      day: ('0' + v.getDate()).slice(-2),
      month: ('0' + (v.getUTCMonth() + 1)).slice(-2),
      year: v.getFullYear(),
      hour: ('0' + v.getHours()).slice(-2),
      minutes: ('0' + v.getMinutes()).slice(-2),
    };

    return `${datetime.day}/${datetime.month}/${datetime.year} ás ${datetime.hour}:${datetime.minutes}`;
  }
  return undefined;
};

export const dateToOutFormatString = (
  date: Date,
  initial?: boolean,
  final?: boolean,
): string => {
  let day: number | string = date.getDate();
  let month: number | string = date.getMonth() + 1;
  const year: number | string = date.getFullYear();
  let hours: number | string = date.getHours();
  let minutes: number | string = date.getMinutes();

  if (day < 10) {
    day = `0${day}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  if (hours < 10) {
    hours = `0${hours}`;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  if (initial) {
    hours = '00';
    minutes = '00';
  }

  if (final) {
    hours = '23';
    minutes = '59';
  }

  return day + '-' + month + '-' + year + ' ' + hours + ':' + minutes;
};

export const toFirstCharUpperCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const stringDateToShow = (str: string): any => {
  const datetime = str?.split('T');

  const time = datetime[1]?.split('+')[0];

  const year = datetime[0]?.split('-')[0];
  const month = datetime[0]?.split('-')[1];
  const day = datetime[0]?.split('-')[2];

  return day + '/' + month + '/' + year + ' ' + time;
};

export const stringToDate = (str: string): any => {
  const datetime = str?.split(' ');

  const date = datetime[0]?.split('-').map(Number);
  const time = datetime[1]?.split(':').map(Number);

  const year = date?.[2];
  const month = date?.[1];
  const day = date?.[0];
  const hours = time?.[0];
  const minutes = time?.[0];

  if (
    year !== undefined &&
    month !== undefined &&
    day !== undefined &&
    hours !== undefined &&
    minutes !== undefined
  ) {
    return new Date(year, month - 1, day, hours, minutes, 0);
  }
};

export const stringToDateYMD = (str: string): any => {
  return new Date(str + 'T00:00:00');
};

export const dateToDayMonthStrinng = (date: Date): string => {
  let day: number | string = date.getDate();
  let month: number | string = date.getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return day + '/' + month;
};

export const dateToStringRequest = (date: Date): string => {
  let day: number | string = date.getDate();
  let month: number | string = date.getMonth() + 1;
  const year: number | string = date.getFullYear();

  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return year + '-' + month + '-' + day;
};

export const dateToHourMinutsString = (date: Date): string => {
  let hour: number | string = date.getHours();
  let minuts: number | string = date.getMinutes();
  if (hour < 10) {
    hour = `0${hour}`;
  }
  if (minuts < 10) {
    minuts = `0${minuts}`;
  }
  return hour + ':' + minuts;
};

export function isValidCPF(cpf: string): boolean {
  // Se não for string, o CPF é inválido
  if (typeof cpf !== 'string') return false;

  // Remove todos os caracteres que não sejam números
  cpf = cpf.replace(/[^\d]+/g, '');

  // Se o CPF não tem 11 dígitos ou todos os dígitos são repetidos, o CPF é inválido
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;

  // Transforma de string para number[] com cada dígito sendo um número no array
  const cpfArray: number[] = cpf.split('').map(el => +el);

  // Cria uma função interna que calcula o dígito verificador do CPF atual:
  const rest = (count: number): number =>
    // Pega os primeiros count dígitos
    ((cpfArray
      .slice(0, count - 12)
      // e calcula o dígito verificador de acordo com a fórmula da Receita Federal
      .reduce((soma, el, index) => soma + el * (count - index), 0) *
      10) %
      11) %
    10;

  // O CPF é válido se, e somente se, os dígitos verificadores estão corretos
  return rest(10) === cpfArray[9] && rest(11) === cpfArray[10];
}

export function verificarParametos(n: number) {
  if (n < 10 && n > 0) {
    return `0${n}`;
  }
  if (n === 0) {
    return 'Não';
  }
  return n;
}

export function formatarNumeros(n: number) {
  if (n < 10 && n > 0) {
    return `0${n}`;
  }
  if (n === 0) {
    return '00';
  }
  return n;
}

export function capitalizeFirstLetter(text: string) {
  if (typeof text !== 'string') return '';

  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function formatCnpj(cnpj: string) {
  return cnpj
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1 $2 $3/$4-$5');
}

export function formatCPF(cpf: string) {
  if (!cpf) return '';
  cpf = cpf.replace(/[^\d]/g, '');

  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export function formatCarteirinhaMask(carteirinha: string, mask: string) {
  if (!carteirinha) return '';
  if (!mask) return carteirinha;
  let result = '';
  let i = 0;
  for (let x = 0; x < mask.length; x++) {
    if (mask[x] === '9' && carteirinha[i] !== undefined) {
      result += carteirinha[i];
      i++;
    } else {
      result += mask[x];
    }
  }
  return result;
}

type GroupBy = {
  [key: string]: any[];
};

export const groupBy = (items: any[], key: string): GroupBy =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

export const preventDefaultAndStopPropagation = (
  e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>,
) => {
  return [e.preventDefault(), e.stopPropagation()];
};

export const replaceSpecialChars = (str: string) => {
  str = str.replace(/[ÀÁÂÃÄÅ]/, 'A');
  str = str.replace(/[àáâãäå]/, 'a');
  str = str.replace(/[ÈÉÊË]/, 'E');
  str = str.replace(/[Ç]/, 'C');
  str = str.replace(/[ç]/, 'c');

  // o resto

  return str;
};
export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const encodeStringToBase64 = (str: string) => {
  const base64 = Buffer.from(str).toString('base64');
  return base64;
};

export const fetchParametro = async (codigoParametro: string) => {
    try {
      const response = await ParametroAPI.loadParametros(
        {
          codigoParametro,
        },
        { throwError: true },
      );
      return response?.list[0];
    } catch (error) {
      console.error(error);
      return null;
    }
};

export const convertCurrencyToNumber = (currencyString: string) => {

  // Remove the currency symbol and any non-numeric characters except for comma and dot
  const numericString = currencyString.replace(/[^\d,.-]/g, '');

  // Replace the comma with a dot for decimal conversion
  const numericValue = numericString.replace(/\./g, '').replace(',', '.');

  // Parse the string to a float number
  const numberValue = parseFloat(numericValue);

  return numberValue;
};

export const validateSiglaIdade = (
  idadeAnos: number,
  idadeMeses: number,
  idadeDias?: number,
) => {
  if (!isNaN(idadeAnos) && !isNaN(idadeMeses)) {
    return `${idadeAnos} ano${
      idadeAnos > 1 || idadeAnos === 0 ? 's' : ''
    }, ${idadeMeses} ${
      idadeMeses === 0 || idadeMeses > 1 ? 'meses' : 'mês'
    }`.concat(
      ` ${idadeDias ? `e ${idadeDias} dia${idadeDias > 1 ? 's' : ''}` : ''}`,
    );
  } else {
    return null;
  }
};

export function printComponent(componentId: string) {
  const node = document.getElementById(componentId);
  if (node) {
    const html = `
      <html>
        <head>
          <link rel="stylesheet" type="text/css" href="styles.css">
        </head>
        <body>
          ${node.outerHTML}
        </body>
      </html>
    `;
    const printWindow = window.open('', '_blank');
    if (printWindow) {
      printWindow.document.write(html);
      printWindow.document.close();
      printWindow.print();
    }
  }
}

export function convertYoutubeURLtoEmbed(url: string) {
  // Verifica se a URL do YouTube é válida
  if (/^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/i.test(url)) {
    // Extrai o ID do vídeo da URL
    const videoID = url.match(
      /(youtube.com\/watch\?v=|youtu.be\/|youtube.com\/embed\/)([^#\&\?]{11})/,
    );

    // Verifica se o ID do vídeo foi encontrado
    if (videoID && videoID.length == 3) {
      // Cria a URL embed
      const embedURL = 'https://www.youtube.com/embed/' + videoID[2];
      return embedURL;
    }
  }

  // Retorna null se a URL do YouTube não for válida
  return undefined;
}

export function checkAuth(
  authorities: string[],
  checkPermission: string | string[],
): boolean {
  const checkPermissionArray = Array.isArray(checkPermission)
    ? checkPermission
    : [checkPermission];

  return checkPermissionArray.some(permission =>
    authorities?.includes(permission),
  );
}

export const stringToEnum = (str: string) => {
  str = str.replace(/[ÀÁÂÃÄÅ]/, 'A');
  str = str.replace(/[àáâãäå]/, 'a');
  str = str.replace(/[ÈÉÊË]/, 'E');
  str = str.replace(/[èéêë]/, 'e');
  str = str.replace(/[Ç]/, 'C');
  str = str.replace(/[ç]/, 'c');
  str = str.replace(/[ÌÍÎÏ]/, 'I');
  str = str.replace(/[ìíîï]/, 'i');
  str = str.replace(/[ÒÓÔÕÖ]/, 'O');
  str = str.replace(/[òóôõö]/, 'o');
  str = str.replace(/[ÙÚÛÜ]/, 'U');
  str = str.replace(/[ùúûü]/, 'u');
  str = str.replace(/[Ñ]/, 'N');
  str = str.replace(/[ñ]/, 'n');
  str = str.replace(/[ÝŸ]/, 'Y');
  str = str.replace(/[ýÿ]/, 'y');
  str = str.replaceAll(' ', '_');
  str = str.toUpperCase();

  return str;
};

export const validateEmail = (str: string): boolean => {
  const regex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

  return regex.test(str);
};

export const enumToText = (enumValue: any) => {
  const palavras = enumValue.split('_');

  const text = palavras
    .map(
      (palavra: string) =>
        palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase(),
    )
    .join(' ');

  return text;
};

export const cleanObject = (obj: any) => {
  if (!obj) return obj;
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      cleanObject(obj[key]); // Recursively clean the object
      if (Object.keys(obj[key]).length === 0) {
        // After cleaning, if the object is empty, delete it
        delete obj[key];
      }
    } else if (obj[key] === undefined || obj[key] === null) {
      delete obj[key]; // Delete if value is undefined or null
    }
  });
  return obj;
};

export const snakeToCamel = (s: string) => {
  return (s ?? '')
    .toLowerCase()
    .replace(/(_\w)/g, k => (k[1] ?? '').toUpperCase());
};

export const toScreamingSnakeCase = (str: string) =>
  str.replace(/([A-Z])/g, '_$1').toUpperCase();

export const blobToJson = (blob: Blob): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      try {
        const result = JSON.parse(reader.result as string);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsText(blob);
  });
};

export const scrollAllDivsToTop = () => {
  const divs = document.querySelectorAll('div');
  divs.forEach(div => {
    div.scrollTop = 0;
  });
};

export const generateRandomString = (): string => {
  // Numero aleatório entre 1000 e 99999999
  const num = Math.floor(Math.random() * (99999999 - 1000 + 1)) + 1000;

  const numStr = num.toString();

  const hash = crypto.createHash('sha256').update(numStr).digest('hex');

  // Converte o hash para uma string de letras
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = 0; i < hash.length; i += 2) {
    const hexPair = hash.substring(i, i + 2);
    const decimalValue = parseInt(hexPair, 16);
    result += letters[decimalValue % letters.length];
  }

  return result;
};

export const getCssVariable = (variable: string) => {
  const value = getComputedStyle(document.documentElement).getPropertyValue(
    variable,
  );
  return value.trim();
};
