import dayjs from 'dayjs';

import { useAppSelector } from 'src/core/redux/hooks';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

type Props = {
  showDate: boolean;
  item: IChatMessageItem;
};

export const ChatMessageText = ({ item, showDate }: Props) => {
  const { idUsuario } = useAppSelector(state => state.user);

  const type = item.idUsuarioEnvio === idUsuario ? 'out' : 'in';

  return (
    <div className="chat-message-text-container p-w-100" data-type={type}>
      {showDate && (
        <SimpleText fontSize="xxs" fontColor="color_60" className="p-mt-1">
          {dayjs(item.dataInclusao).format('DD/MM[, ] HH:mm')}
        </SimpleText>
      )}

      <div className="chat-message-text-item">
        <SimpleText>{item.mensagem}</SimpleText>
      </div>
    </div>
  );
};
