import { useDisclosure } from 'src/utils/hooks/useDisclosure';

import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

import GerenciarConsentimentoDialog from './GerenciarConsentimento/GerenciarConsentimentoDialog';

import './ConsentimentoPaciente.scss';

const ConsentimentoPaciente = () => {
  const consentimentoDisclosure = useDisclosure({ opened: false });

  return (
    <div
      id="consentimento-paciente"
      className={`p-d-flex p-flex-column p-col-12 p-lg-6 p-xl-4 br-8px p-gap-2 p-py-3 p-px-2 termo-container`}
    >
      <SimpleText medium>Concentimento do paciente</SimpleText>

      <Button
        type="button"
        label="Gerenciar termos de consentimento"
        btnType="tonal"
        icon="fas fa-cog"
        iconPos="left"
        onClick={() => consentimentoDisclosure.open()}
      />

      {consentimentoDisclosure.isOpen && (
        <GerenciarConsentimentoDialog {...consentimentoDisclosure} />
      )}
    </div>
  );
};

export default ConsentimentoPaciente;
