import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useEffectSkipFirst } from 'src/utils/hooks/useEffectSkipFirst';

type Filters = {
  idsAplicacao: number[];
  idsPaciente: number[];
};

type IContextProps = {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  selectedForms: IGestaoMonitoramentoFormulariosAplicacaoesItem[];
  setSelectedForms: React.Dispatch<
    React.SetStateAction<IGestaoMonitoramentoFormulariosAplicacaoesItem[]>
  >;
  selectedAllForms: boolean;
  setSelectedAllForms: (v: boolean) => void;
  selectedAllPacientes: boolean;
  setSelectedAllPacientes: (v: boolean) => void;
  selectedPacientes: number[];
  setSelectedPacientes: (e: boolean, id: number) => void;
  handleClearPacientes: () => void;
};

const GestaoMonitoramentoContext = createContext({} as IContextProps);

export const GestaoMonitoramentoProvider = (props: {
  children: React.ReactChild;
}) => {
  const [filters, setFilters] = useState<Filters>({
    idsAplicacao: [],
    idsPaciente: [],
  });
  const [selectedAllForms, setSelectedAllForms] = useState(false);
  const [selectedForms, setSelectedForms] = useState<
    IGestaoMonitoramentoFormulariosAplicacaoesItem[]
  >([]);

  const [selectedAllPacientes, setSelectedAllPacientes] = useState(false);

  const selectedPacientes = useMemo(() => filters.idsPaciente, [filters]);
  const setSelectedPacientes = (e: boolean, id: number) => {
    if (e) {
      setFilters(old => ({ ...old, idsPaciente: [...old.idsPaciente, id] }));
    } else {
      setFilters(old => ({
        ...old,
        idsPaciente: old.idsPaciente.filter(i => i !== id),
      }));
    }
  };
  const handleClearPacientes = useCallback(() => {
    setFilters(old => ({ ...old, idsPaciente: [] }));
  }, []);

  const handleFilterByAplicacoes = useCallback(() => {
    const aplicacoesFiltered = selectedForms
      .map(({ aplicacoes }) =>
        aplicacoes?.length ? aplicacoes.map(({ id }) => id) : [],
      )
      .flat();

    setFilters(old => ({ ...old, idsAplicacao: aplicacoesFiltered }));
  }, [selectedForms]);

  useEffectSkipFirst(() => {
    if (selectedAllForms) setSelectedForms([]);
  }, [selectedAllForms]);

  useEffectSkipFirst(() => {
    if (selectedAllPacientes) handleClearPacientes();
  }, [selectedAllPacientes]);

  useEffectSkipFirst(() => {
    handleFilterByAplicacoes();
  }, [selectedForms]);

  return (
    <GestaoMonitoramentoContext.Provider
      value={{
        filters,
        setFilters,
        selectedForms,
        setSelectedForms,
        selectedAllForms,
        setSelectedAllForms,
        selectedAllPacientes,
        setSelectedAllPacientes,
        selectedPacientes,
        setSelectedPacientes,
        handleClearPacientes,
      }}
    >
      {props.children}
    </GestaoMonitoramentoContext.Provider>
  );
};

export const useGestaoMonitoramento = () =>
  useContext(GestaoMonitoramentoContext);
