import { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import UtilsAPI from 'src/APIs/UtilsAPI/UtilsAPI';
import * as Yup from 'yup';

import { cities, states } from 'src/utils/address';

import { Button } from 'src/components/_UI';
import AlertBox from 'src/components/AlertBox/AlertBox';
import DropdownControlled from 'src/components/Basics/DropdownControlled/DropdownControlled';
import { MASK } from 'src/components/Basics/MaskedInput/MaskedInput';
import MaskedInputControlled from 'src/components/Basics/MaskedInputControlled/MaskedInputControlled';
import PasswordInputControlled from 'src/components/Basics/PasswordInputControlled/PasswordInputControlled';
import SimpleText, {
  FONT_COLOR,
  FONT_SIZE,
} from 'src/components/Basics/SimpleText/SimpleText';
import FieldConvenio from 'src/components/Fields/FieldConvenio/FieldConvenio';
import FormInput from 'src/components/FormInput/FormInput';

import './FormRequiredData.scss';

interface FormRequiredDataProps {
  isUpdate: boolean;
  onSubmit: any;
  values: Partial<
    EmpresaDTO & Partial<EnderecoDTO> & Partial<EstadoDTO> & Partial<CidadeDTO>
  >;
}

const FormRequiredData = ({
  isUpdate,
  onSubmit,
  values,
}: FormRequiredDataProps) => {
  const navigate = useNavigate();

  const [stateSelected, setStateSelected] = useState('');
  const [visible, setVisible] = useState(true);

  const formValidation = useCallback((): any => {
    return Yup.object().shape({
      cnpj: Yup.string().required('O campo é obrigatório.'),
      razaoSocial: Yup.string().required('O campo é obrigatório.'),
      codigo: Yup.string().required('O campo é obrigatório.'),
      email: Yup.string().email('O e-mail informado é inválido'),
      senhaCliente: Yup.string()
        .min(8, 'A senha deve contar no minímo 8 caracteres')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          'A senha deve conter variedades de letras maiúsculas e minúsculas, números e caracteres especiais',
        )
        .when([], {
          is: () => !isUpdate,
          then: field => field.required('Campo obrigatório'),
          otherwise: field =>
            field
              .nullable()
              .transform((currentValue, originalValue) =>
                originalValue === '' ? null : currentValue,
              ),
        }),
    });
  }, [isUpdate]);

  const useFormMethods = useForm<any>({
    defaultValues: {
      nome: values?.nome || '',
      cnpj: values?.cnpj || '',
      razaoSocial: values?.razaoSocial || '',
      site: values?.site || '',
      email: values?.email || '',
      telefone1: values?.telefone1 || '',
      telefone2: values?.telefone2 || '',
      convenio: values?.convenio || '',
      codigo: values?.codigo || '',
      cep: values?.endereco?.cep || '',
      estado: values?.endereco?.cidade?.estado?.sigla || '',
      idPais: values?.endereco?.cidade?.estado?.idPais || '',
      cidade: values?.endereco?.cidade?.nome || '',
      idCidade: values?.endereco?.cidade?.id || '',
      logradouro: values?.endereco?.logradouro || '',
      complemento: values?.endereco?.complemento || '',
      numero: values?.endereco?.numero || '',
      bairro: values?.endereco?.bairro || '',
    },
    resolver: yupResolver(formValidation()),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
    getValues,
    watch,
  } = useFormMethods;

  const watchSenhaCliente = watch('senhaCliente');

  const isStrongPassword = useCallback(() => {
    if (watchSenhaCliente) {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
      return regex.test(watchSenhaCliente);
    }
    return false;
  }, [watchSenhaCliente]);

  useEffect(() => {
    async function loadCep() {
      const cep = getValues('cep');
      if (cep && cep.length === 9) {
        const resp = await UtilsAPI.GetAddressByCep(cep);

        if (resp !== undefined) {
          const { logradouro, bairro, nomeCidade, uf, idCidade }: any = resp;
          setValue('logradouro', logradouro);
          setValue('bairro', bairro);
          setStateSelected(uf);
          setValue('estado', uf);
          setValue('cidade', nomeCidade);
          setValue('idCidade', idCidade);
        }
      }
    }

    loadCep();
  }, [getValues, setValue]);

  const searchAddressByZipCode = async () => {
    const cep = getValues('cep');

    if (cep && cep.length === 9) {
      const resp = await UtilsAPI.GetAddressByCep(cep);

      if (resp !== undefined) {
        setValue('logradouro', resp.logradouro);
        setValue('bairro', resp.bairro);
        setValue('complemento', resp.complemento);
        setStateSelected(resp.uf);
        setValue('estado', resp.uf);
        setValue('cidade', resp.nomeCidade);
        setValue('idCidade', resp.idCidade);
        setValue('cep', resp.cep);
      }
    }
  };

  const handleEmpresa = useCallback(
    (data: any) => {
      const { senhaCliente, ...rest } = data;
      const payload = {
        ...rest,
        idConvenio: data?.convenio?.id || null,
        ...(senhaCliente !== null && { senhaCliente }),
      };
      onSubmit(payload);
    },
    [onSubmit],
  );

  const handleCancel = useCallback(() => {
    reset();
    navigate(-1);
  }, [navigate, reset]);

  return (
    <div className="FormRequiredData p-grid p-col-12 p-lg-8 p-xl-6">
      <div className="p-mb-2">
        <SimpleText
          className="p-pl-2"
          fontSize={FONT_SIZE.XS}
          fontColor={FONT_COLOR.COLOR_40}
          medium
        >
          Dados cadastrais
        </SimpleText>
      </div>
      <FormProvider {...useFormMethods}>
        <form
          className="p-grid p-col-12 p-px-0 p-pb-4"
          onSubmit={handleSubmit(handleEmpresa)}
        >
          <FormInput
            hideTextErrorSpace
            className="p-col-12"
            errorMsg={errors.nome?.message}
            name="nome"
            label="Nome fantasia"
            maxLength={100}
          />
          <FormInput
            hideTextErrorSpace
            className="p-col-12"
            errorMsg={errors.razaoSocial?.message}
            name="razaoSocial"
            label="Razão social"
            maxLength={100}
          />
          <MaskedInputControlled
            className="p-col-12"
            errorMsg={errors.cnpj?.message}
            name="cnpj"
            control={control}
            label="CNPJ"
            mask={MASK.CNPJ}
          />
          <FormInput
            hideTextErrorSpace
            className="p-col-12"
            errorMsg={errors.site?.message}
            name="site"
            label="Site"
            maxLength={50}
          />
          <FormInput
            hideTextErrorSpace
            className="p-col-12"
            errorMsg={errors.email?.message}
            name="email"
            type="email"
            label="E-mail institucional"
            maxLength={50}
          />
          <MaskedInputControlled
            className="p-col-12 p-sm-6"
            errorMsg={errors.telefone1?.message}
            name="telefone1"
            control={control}
            label="Telefone"
            mask={MASK.PHONE}
            autoClear={false}
          />
          <MaskedInputControlled
            className="p-col-12 p-sm-6"
            errorMsg={errors.telefone2?.message}
            name="telefone2"
            control={control}
            label="Telefone"
            mask={MASK.PHONE}
            autoClear={false}
          />

          <SimpleText
            className="p-col-12"
            fontColor={FONT_COLOR.COLOR_40}
            fontSize={FONT_SIZE.XS}
          >
            Exclusivo para operadoras
          </SimpleText>

          <FieldConvenio name="convenio" label="Convênio" showClear />

          <div className="p-col-12 codigoCliente">
            <div>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                medium
              >
                Código cliente
              </SimpleText>
              <div className={'p-pl-0 p-col-12'}>
                <SimpleText
                  fontColor={FONT_COLOR.COLOR_40}
                  fontSize={FONT_SIZE.XS}
                >
                  Insira o prefixo do cliente no sistema.
                </SimpleText>
              </div>
              <FormInput
                hideTextErrorSpace
                className="p-pl-0 p-col-12 p-sm-6 p-pb-0"
                placeholder="Ex. a00"
                errorMsg={errors.codigo?.message}
                name="codigo"
                maxLength={20}
              />
              <AlertBox
                text="Atenção, este prefixo será usado como chave no login do usuário"
                onHide={() => setVisible(false)}
                visible={visible}
              />
            </div>
            <div>
              <SimpleText
                fontColor={FONT_COLOR.COLOR_16}
                fontSize={FONT_SIZE.XS}
                medium
              >
                Senha atual
              </SimpleText>
              <PasswordInputControlled
                name="senhaCliente"
                className={`p-pl-0 p-col-12 p-sm-6 p-pb-0 ${
                  isUpdate ? 'hidden-placeholder' : ''
                }`}
                type="password"
                toggleMask={isUpdate ? false : true}
                placeholder={
                  isUpdate ? '• • • • • • • • ' : 'Insira a sua senha'
                }
                control={control}
                errorMsg={errors.senhaCliente?.message}
              />

              {!isStrongPassword() && (
                <AlertBox
                  text="A senha deve ter no mínimo 8 caracteres, variedade de letras maiúsculas e minúsculas, números e caractere especial"
                  visible={visible}
                />
              )}
            </div>
          </div>

          <SimpleText
            className="p-col-12"
            fontColor={FONT_COLOR.COLOR_40}
            fontSize={FONT_SIZE.XS}
            medium
          >
            Endereço
          </SimpleText>

          <SimpleText
            className="p-col-12"
            fontColor={FONT_COLOR.COLOR_16}
            fontSize={FONT_SIZE.XS}
          >
            Digite o CEP para preencher automaticamente os campos
          </SimpleText>

          <div className="p-col-12 p-grid p-ai-center">
            <div className="p-col-8 p-pl-0">
              <MaskedInputControlled
                errorMsg={errors.cep?.message}
                name="cep"
                control={control}
                label="CEP"
                className="p-mb-2"
                mask={MASK.CEP}
              />
            </div>
            <div className="p-col-4 p-pb-0 p-mt-2 p-pr-0">
              <Button
                onClick={() => searchAddressByZipCode()}
                label="Pesquisar"
                btnType="tonal"
                style={{ height: 38.74 }}
                className="p-col-12"
                type="button"
              />
            </div>
          </div>

          <DropdownControlled
            className="p-col-12 p-sm-6"
            errorMsg={errors.estado?.message}
            name="estado"
            control={control}
            label="Estado"
            options={states}
          />
          <DropdownControlled
            className="p-col-12 p-sm-6"
            errorMsg={errors.cidade?.message}
            name="cidade"
            control={control}
            label="Cidade"
            options={cities(stateSelected)}
          />

          <FormInput
            hideTextErrorSpace
            className="p-col-12"
            errorMsg={errors.bairro?.message}
            name="bairro"
            label="Bairro"
            maxLength={100}
          />
          <FormInput
            hideTextErrorSpace
            className="p-col-12"
            errorMsg={errors.logradouro?.message}
            name="logradouro"
            label="Logradouro"
            maxLength={255}
          />
          <FormInput
            hideTextErrorSpace
            className="p-col-12 p-sm-6"
            errorMsg={errors.complemento?.message}
            name="complemento"
            label="Complemento"
            maxLength={255}
          />
          <FormInput
            hideTextErrorSpace
            className="p-col-12 p-sm-6"
            errorMsg={errors.numero?.message}
            name="numero"
            label="Número"
            maxLength={10}
          />

          <div className="p-col-12 p-sm-4">
            <Button
              btnType="ghost"
              label="Cancelar"
              type="button"
              onClick={handleCancel}
              stretch
            />
          </div>
          <div className="p-col-12 p-sm-8">
            <Button
              btnType="tonal"
              label="Próximo"
              type="submit"
              stretch
              loading={isSubmitting}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FormRequiredData;
