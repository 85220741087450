import { useState } from 'react';

import TipoContaPagarAPI from 'src/APIs/FinanceiroAPI/TipoContaPagarAPI/TipoContaPagarAPI';

import SpinnerLoading from 'src/components/SpinnerLoading/SpinnerLoading';
import Switch from 'src/components/Switch/Switch';

interface StatusTipoContaPagarProps {
  tipoContaPagar: any;
}

export const StatusTipoContaPagar = (props: StatusTipoContaPagarProps) => {
  const { tipoContaPagar } = props;
  const [ativo, setAtivo] = useState(tipoContaPagar.status);

  const [loading, setLoading] = useState(false);

  const onSwitchStatus = async () => {
    const {
      consultorio,
      usuarioAtualizacao,
      idEmpresa,
      ...restoTipoContaPagar
    } = tipoContaPagar;
    const payload = {
      ...restoTipoContaPagar,
      status: !ativo,
      idConsultorio: consultorio.id,
    };

    setLoading(true);
    try {
      const response = await TipoContaPagarAPI.putTipoContaPagar(
        tipoContaPagar.id,
        payload,
      );

      if (response?.status === 200) {
        setAtivo(response.data.status);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className="p-mr-4">
        <SpinnerLoading size="sm"></SpinnerLoading>
      </div>
    );

  return <Switch checked={ativo} showStatus onChange={onSwitchStatus} />;
};
