import './style.scss';
import { useRef, useState } from 'react';

import { MenuItem } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';

import SimpleText from 'src/components/Basics/SimpleText/SimpleText';

type UserStatus = 'online' | 'busy' | 'idle' | 'offline';

type Props = {
  onChange?: (status: UserStatus) => void;
  initialState?: UserStatus;
};

const statusLabel = {
  online: 'Online',
  busy: 'Ocupado',
  idle: 'Ausente',
  offline: 'Offline',
};

export const ChatStatus = ({ onChange, initialState }: Props) => {
  const [status, setStatus] = useState<UserStatus>(initialState || 'offline');
  const ref = useRef<any>(null);

  const items: MenuItem[] = [
    { label: 'Online', command: () => handleStatusChange('online') },
    { label: 'Ocupado', command: () => handleStatusChange('busy') },
    { label: 'Ausente', command: () => handleStatusChange('idle') },
    { label: 'Offline', command: () => handleStatusChange('offline') },
  ];

  const handleStatusChange = (status: UserStatus) => {
    setStatus(status);
    onChange?.(status);

    ref.current?.hide();
  };

  return (
    <div className="statusChat-container p-d-flex p-gap-2 p-ai-center">
      <span className="statusChat-chip" data-status={status}>
        <SimpleText medium>{statusLabel[status]}</SimpleText>
      </span>
      <button
        className="statusChat-button"
        onClick={e => ref.current?.toggle(e)}
      >
        <i className="fas fa-chevron-down" />
      </button>

      <TieredMenu
        ref={ref}
        popup
        model={items}
        style={{
          width: '100px',
        }}
      />
    </div>
  );
};
