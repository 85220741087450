import React, { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';

import { yupResolver } from '@hookform/resolvers/yup';
import CentroCustoAPI from 'src/APIs/FinanceiroAPI/CentroCustoAPI/CentroCustoAPI';
import * as Yup from 'yup';

import ConsultorioDropdown from '../../../components/ConsultorioDropdown';
import { Button } from 'src/components/_UI';
import SimpleText from 'src/components/Basics/SimpleText/SimpleText';
import FormInput from 'src/components/FormInput/FormInput';
import GridListLoading from 'src/components/GridList/GridListLoading';
import Separator from 'src/components/Separator/Separator';
import SwitchControlled from 'src/components/Switch/SwitchControlled';

import './FormCentroCusto.scss';

const FormCentroCusto = () => {
  const [loading, setLoading] = useState(true);
  const [editCentroCusto, setEditCentroCusto] = useState<any>();
  const params = useParams();
  const navigate = useNavigate();

  const useFormMethods = useForm({
    defaultValues: {
      status: true,
      nome: '',
      descricao: '',
    },
    resolver: yupResolver(
      Yup.object({
        nome: Yup.string().required('O nome do centro de custo é obrigatório'),
        idConsultorio: Yup.number().required('O consultório é obrigatório'),
      }),
    ),
  });

  const {
    control,
    formState: { isSubmitting, isDirty, errors },
    handleSubmit,
    reset,
  } = useFormMethods;

  const loadPage = useCallback(async () => {
    setLoading(true);
    try {
      const response = await CentroCustoAPI.getCentroCustoById(params.id);
      setEditCentroCusto(response);
      reset({
        idConsultorio: response.consultorio?.id,
        ...response,
      });
    } catch (error) {
      console.log(error);
      navigate('/faturamento/tipo-conta-pagar');
    } finally {
      setLoading(false);
    }
  }, [navigate, params.id, reset]);

  useEffect(() => {
    if (params.id) {
      loadPage();
    } else {
      setLoading(false);
    }
  }, [loadPage, params.id]);

  const onSubmit = async (data: any) => {
    try {
      if (editCentroCusto) {
        await CentroCustoAPI.putCentroCusto(editCentroCusto.id, {
          ...data,
          id: editCentroCusto.id,
          status: data.status || false,
        });
      } else {
        await CentroCustoAPI.postCentroCusto(data);
      }
      navigate('/faturamento/centro-custo');
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) return <GridListLoading />;

  return (
    <FormProvider {...useFormMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="form-centro-custo"
        className="p-d-grid p-flex-column"
      >
        <ConsultorioDropdown
          className="p-col-12 p-md-7 p-lg-6 p-xl-5"
          idConsultorioEdit={editCentroCusto?.idConsultorio}
        />
        <FormInput
          name="nome"
          label="Centro de custo"
          hideTextErrorSpace
          className="p-col-12 p-md-7 p-lg-6 p-xl-5"
          maxLength={250}
          errorMsg={errors.nome?.message}
        />
        <FormInput
          name="descricao"
          label="Descrição"
          hideTextErrorSpace
          className="p-col-12 p-md-7 p-lg-6 p-xl-5 p-pb-0"
          maxLength={250}
          errorMsg={errors.descricao?.message}
        />
        <div className="p-col-12 p-md-7 p-lg-6 p-xl-5 p-py-0">
          <Separator layout="horizontal" />
        </div>
        <div className="p-d-flex p-jc-between p-ai-center p-p-2 border p-col-12 p-md-7 p-lg-6 p-xl-5">
          <SimpleText medium>Status</SimpleText>
          <SwitchControlled control={control} name="status" showStatus />
        </div>
        <div className="p-col-12 p-md-5 p-lg-4 p-xl-3 p-my-2">
          <Button
            type="submit"
            label="Salvar"
            disabled={!isDirty}
            loading={isSubmitting}
            stretch
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default FormCentroCusto;
