import * as Yup from 'yup';

export const formValidation = Yup.object().shape({
  titulo: Yup.string()
    .typeError('O campo título é obrigatório.')
    .required('O campo título é obrigatório.'),

  mensagem: Yup.string()
    .required('O campo mensagem é obrigatório.')
    .typeError('O campo mensagem é obrigatório.'),

  linkExterno: Yup.string().when('textoLink', {
    is: (textoLink: string) => !!textoLink,
    then: Yup.string()
      .required(
        'O campo link é obrigatório quando o campo textoLink está preenchido.',
      )
      .typeError(
        'O campo link é obrigatório quando o campo textoLink está preenchido.',
      ),
    otherwise: Yup.string().nullable(),
  }),
});
